/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, injectIntl } from 'react-intl';

import Signals from '../../../signals/Signals';

import InvoiceConceptModel from '../../../stores/models/InvoiceConceptModel';
import Company from '../../../stores/models/Company';
import { Routes } from '../../../data/Routes';
import InvoiceConceptStatus from '../../../data/InvoiceConceptStatus';

import Tag from '../Tag/Tag';
import PropertiesController from '../../../controllers/PropertiesController';
import { getRelativeDays } from '../../../utils/dateUtils';

/**
 *
 */
class InvoiceEntry extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.route = Routes.COMPANY_INVOICES_INVOICE.getPath({
			id: this.props.company.id,
			invoiceId: this.props.invoiceConceptModel.id
		});
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const status = this.props.invoiceConceptModel.status.toLowerCase();
		const name = this.props.invoiceConceptModel.companyCustomer
			? this.props.invoiceConceptModel.companyCustomer.getCustomerName()
			: '-';

		return (
			<div
				className={`invoice-entry border--bottom ${this.props.className}`}
				onClick={this.onClick}>
				<div className="invoice-entry__content-wrapper grid">
					<div className="invoice-entry__client">
						<div className="invoice-entry__companyname">{name}</div>
						<div className="invoice-entry__invoicenr">
							{this.props.invoiceConceptModel.invoiceNr}
						</div>
					</div>
					<div className="invoice-entry__amount invoice-entry__column">
						<div className="text--overflow-ellipsis">
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={this.props.invoiceConceptModel.getTotal()}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</div>
					{PropertiesController.getProperty(PropertiesController.FEATURE_DEMO) ? null : (
						<div
							className="invoice-entry__time invoice-entry__column"
							title={this._getStatusDateValue()}>
							<div className="text--overflow-ellipsis">{this._getStatusDateValue()}</div>
						</div>
					)}
					<div className="invoice-entry__status invoice-entry__column">
						<Tag labelId={`tag-label.${status}`} type={status} />
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onClick() {
		Signals.RequestRoute.dispatch(this.route);
	}

	/**
	 *
	 * @return {string}
	 * @private
	 */
	_getStatusDateValue() {
		let value = '';

		const settings = {
			unit: 'day',
			options: {
				style: 'long',
				localeMatcher: 'best fit'
			}
		};

		switch (this.props.invoiceConceptModel.status) {
			case InvoiceConceptStatus.CREATED:
			case InvoiceConceptStatus.PAID:
			case InvoiceConceptStatus.DELETED:
				value = null;
				break;
			case InvoiceConceptStatus.SENT_REMINDER_KDB:
			case InvoiceConceptStatus.LATE:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.invoiceConceptModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'invoices.statusdate.late' }, { date: value });
				break;
			case InvoiceConceptStatus.SENT_MANUALLY:
			case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.invoiceConceptModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'invoices.statusdate.send' }, { date: value });
				break;
			default:
		}

		return value;
	}
}

InvoiceEntry.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	company: PropTypes.instanceOf(Company).isRequired,
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel).isRequired
};

InvoiceEntry.defaultProps = {
	className: ''
};

export default injectIntl(InvoiceEntry);
