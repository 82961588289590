export function formatBytes(bytes, decimals) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 *
 * @param name
 * @param maxLength
 * @return {string}
 */
export function getShortName(name, maxLength = 1) {
	if (!name) {
		return '';
	}
	let parts = name.split(' ');
	let letters = '';

	// Filter out unwanted characters for each part
	parts = parts.map((part) => {
		return part.split('').filter((char) => /[a-zA-Z]/.test(char));
	});

	// Filter out empty arrays
	parts = parts.filter((part) => part.length > 0);

	// Take first character from each part array
	parts.forEach((part) => {
		letters += part[0];
	});

	// Return the desired amount of characters (maxLength) of the abbreviated name
	return letters.slice(0, maxLength);
}

/**
 * Truncate a string to a certain length
 * @param {*} str
 * @param {*} n
 * @param {*} useWordBoundary
 * @returns
 */
export function truncate(str, n, useWordBoundary) {
	if (str) {
		if (str.length <= n) {
			return str;
		}
		const subString = str.substr(0, n - 1); // the original check
		return useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString;
	}
	return '';
}

/**
 * Return trimmed spaces from a string
 * @param {*} str
 * @returns
 */
export function replaceMultipleSpacesWithSingleSpace(str) {
	if (str) {
		return str.replace(/\s+/g, ' ').trim();
	}
	return '';
}
