import request from 'superagent';
import Methods from '../data/Methods';
import RequestController from '../controllers/RequestController';

/**
 * This doesn't work for all requests, just for most
 *
 * @param {String} method
 * @param {String} url
 * @param {Object} [parameters]
 * @param {Object} [headers]
 * @param {Boolean} [withCredentials]
 * @param {Boolean} [blockUI]
 * @param {Boolean} [ignorePrefix]
 * @param {Boolean} [ignoreResponseTimeout]
 * @param {Boolean} [ignoreTimeouts]
 *
 * @returns {Promise}
 */
export default (
	method,
	url,
	parameters = null,
	headers = null,
	withCredentials = false,
	blockUI = true,
	ignorePrefix = false,
	ignoreResponseTimeout = false,
	ignoreTimeouts = false
) =>
	new Promise((resolve, reject) => {
		// console.log('fetch', url, parameters);

		const apiCall = request(method, !ignorePrefix ? window.config.apiPrefix + url : url).set(
			'Accept',
			'application/json'
		);
		if (!ignoreTimeouts) {
			if (!ignoreResponseTimeout) {
				apiCall.timeout({
					response: 60000, // Wait 60 seconds for the server to start sending,
					deadline: 120000 // but allow 2 minutes for the request to finish.
				});
			} else {
				apiCall.timeout({
					deadline: 120000 // but allow 2 minutes for the request to finish.
				});
			}
		}

		// Add to blocking queue
		if (blockUI) {
			RequestController.addRequest(apiCall);
		}

		// Set optional headers
		if (headers) {
			for (const key in headers) {
				if (headers.hasOwnProperty(key)) {
					apiCall.set(key, headers[key]);

					if (key === 'Accept' && headers[key] === 'application/pdf') {
						apiCall.responseType('blob');
					}
				}
			}
		}

		// Use credentials
		if (withCredentials) {
			apiCall.withCredentials();
		}

		// Add parameters and do request
		if (parameters) {
			// eslint-disable-next-line no-unused-expressions
			method === Methods.GET ? apiCall.query(parameters) : apiCall.send(parameters);
		}

		// Handle result or error
		apiCall.end((error, result) => {
			// remove from blocking queue
			if (blockUI) {
				RequestController.removeRequest(apiCall);
			}

			// handle response
			if (error) {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject({ error, response: result });
			} else {
				resolve(result.body);
			}
		});
	});
