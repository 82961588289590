export default {
	CREATED: 'CREATED',
	SENT: 'SENT_BY_EMAIL_KDB',
	SENT_MANUALLY: 'SENT_MANUALLY',
	SIGNED: 'SIGNED',
	DECLINED: 'DECLINED',
	SENT_REMINDER: 'SENT_REMINDER',
	LATE: 'LATE',
	DELETED: 'DELETED',
	ARCHIVED: 'ARCHIVED',
	INVOICED: 'INVOICED'
};
