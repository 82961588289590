/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';

import Page from '../../Page';
import SearchField from '../../../components/ui/SearchField/SearchField';
import OffersRow from './components/OffersRow';

import { ApplicationContext } from '../../../ApplicationContext';
import GetOffersCommand from '../../../commands/offers/GetOffersCommand';
import OffersStore from '../../../stores/OffersStore';
import TableWrapper from '../../../components/ui/TableWrapper/TableWrapper';

/**
 *
 */
@observer
class OffersArchive extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.addNew = this.addNew.bind(this);
		this.onSortClick = this.onSortClick.bind(this);

		this.fieldHeaderSettings = [
			{
				id: 'status',
				label: this.props.intl.formatMessage({ id: 'offers.header.status' }),
				isDefault: true,
				minimal: true
			},
			// {id: 'expirationDate', label: this.props.intl.formatMessage({id:'offers.header.expires'}), minimal:true},
			{
				id: 'date',
				label: this.props.intl.formatMessage({ id: 'offers.header.date' }),
				minimal: true
			},
			{
				id: 'offerNr',
				label: this.props.intl.formatMessage({ id: 'offers.header.offernr' }),
				minimal: true
			},
			{ id: 'companyName', label: this.props.intl.formatMessage({ id: 'offers.header.client' }) },
			{ id: 'subject', label: this.props.intl.formatMessage({ id: 'offers.header.subject' }) },
			{
				id: 'amount',
				label: this.props.intl.formatMessage({ id: 'offers.header.amount' }),
				right: true,
				minimal: true
			},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'offers.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];
	}

	/**
	 *
	 */
	componentWillMount() {
		console.log('OffersArchive.componentWillMount');
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		console.log('OffersArchive.componentWillReceiveProps');
		this._initialize();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		console.log('OffersArchive.render');

		const company = this.context.applicationStore.getSelectedCompany();
		if (!company) {
			return null;
		}

		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		if (!financialYear) {
			return null;
		}

		// Create invoices rows
		const rows = this.props.offersArchiveStore.filteredAndSortedOffers.map((offer) => {
			// Ignore if not an 'open' invoice
			if (this.props.showOpenOnly && !offer.isOpen()) {
				return null;
			}
			return <OffersRow key={`i-${offer.id}-${offer.offerNr}`} offerModel={offer} />;
		});

		return (
			<Page pageName="invoices">
				<div className="offers__wrapper border border--dark">
					<div className="offers__options grid grid--spread">
						<SearchField
							name="search"
							// eslint-disable-next-line react/jsx-no-bind
							onChange={this.onSearch.bind(this)}
							placeholder={this.props.intl.formatMessage({ id: 'offers.search' })}
							value={
								this.props.offersArchiveStore.searchFilter
									? this.props.offersArchiveStore.searchFilter
									: undefined
							}
						/>
						<div className="grid offers__options-buttons" />
					</div>

					<TableWrapper scrollableX>
						<table className="table offers__rows">
							<thead className="table__headers">
								<tr>{this._getTableHeaders()}</tr>
							</thead>
							<tbody className="table__body">{rows}</tbody>
						</table>
					</TableWrapper>

					<div className="table__footer options-footer grid grid--right border--top" />
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.props.offersArchiveStore.searchFilter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.props.offersArchiveStore.sortBy === field) {
			this.props.offersArchiveStore.sortDirection = -this.props.offersArchiveStore.sortDirection;
		} else {
			this.props.offersArchiveStore.sortBy = field;
			this.props.offersArchiveStore.sortDirection = 1;
		}
	}

	/**
	 *
	 */
	addNew() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_OFFERS_EDIT.getPath({ id: company.id, offerId: 'new' })
			);
		}
	}

	/**
	 *
	 * @return {any[]}
	 * @private
	 */
	_getTableHeaders() {
		return this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`offers__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!this.props.offersArchiveStore.sortBy && fieldData.isDefault,
						'table__header--sorted': this.props.offersArchiveStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							this.props.offersArchiveStore.sortBy === fieldData.id &&
							this.props.offersArchiveStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}

	/**
	 *
	 * @private
	 */
	_initialize() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const getOffersCommand = new GetOffersCommand(this.props.offersArchiveStore, company.id);
			getOffersCommand.execute();
		}
	}
}

OffersArchive.contextType = ApplicationContext;

OffersArchive.propTypes = {
	intl: PropTypes.object,
	offersArchiveStore: PropTypes.instanceOf(OffersStore).isRequired,
	showOpenOnly: PropTypes.bool
};

OffersArchive.defaultProps = {
	showOpenOnly: false
};

export default injectIntl(OffersArchive);
