import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import dateLocaleNL from 'date-fns/locale/nl';
import dateLocaleEN from 'date-fns/locale/en-US';

import { _formatDate, _formatStringToDate } from '../../../utils/objectToFormData';

import Breakpoints from '../../../data/Breakpoints';
import { ApplicationContext } from '../../../ApplicationContext';

registerLocale('nl', dateLocaleNL);
registerLocale('en', dateLocaleEN);

/**
 *
 */
export default class DateInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onResize = this.onResize.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const date = this.props.value; // this.props.value ? this.props.value : new Date();
		const { openToDate } = this.props;

		// eslint-disable-next-line no-undef
		if (window.innerWidth < Breakpoints.TABLET && Modernizr && Modernizr.inputtypes.date) {
			return (
				<div className="date-input__mobile-input">
					<input
						name={this.props.name}
						className={this.props.className}
						disabled={this.props.disabled}
						type="date"
						value={date ? _formatDate(date, true) : null}
						autoComplete={this.props.autoComplete}
						placeholder={this.props.placeholderText}
						required={this.props.required}
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
					/>
				</div>
			);
		}

		return (
			<DatePicker
				name={this.props.name}
				className={this.props.className}
				disabled={this.props.disabled}
				disabledKeyboardNavigation
				selected={this._processDatePickerValue(date)}
				locale={
					this.context.applicationStore.user ? this.context.applicationStore.user.language : 'nl'
				}
				dateFormat="dd-MM-yyyy"
				dropdownMode="scroll"
				minDate={this.props.minDate}
				placeholderText={this.props.placeholderText}
				autoComplete={this.props.autoComplete}
				required={this.props.required}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onChange={this.onDateChange}
				onChangeRaw={this.onChange}
				openToDate={openToDate}
				previousMonthButtonLabel={this.context.intl.formatMessage({
					id: 'datepicker.month.previous'
				})}
				nextMonthButtonLabel={this.context.intl.formatMessage({ id: 'datepicker.month.next' })}
			/>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		if (this.props.onChange) {
			let reverse = true;

			console.log('DateInput.onChange', e.target.value);

			// check if year is leading, if so don't reverse date string in _formatStringToDate
			try {
				reverse = e.target.value.split('-')[0].length !== 4;
			} catch (exception) {
				// Ignore
			}

			const date = _formatStringToDate(e.target.value, reverse);
			this.props.onChange(e, date);
		}
	}

	/**
	 *
	 * @param date
	 * @param e
	 */
	onDateChange(date, e) {
		console.log(date, e);
		if (date && this.props.onChange) {
			date.setHours(12); // Offset half a day to prevent time zone shenanigans
			this.props.onChange(e, date);
		} else {
			console.log('ongeldige datum', date);
		}
	}

	/**
	 *
	 * @param e
	 */
	onFocus(e) {
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	/**
	 *
	 * @param e
	 */
	onBlur(e) {
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	/**
	 *
	 * @param date
	 * @return {*}
	 * @private
	 */
	_processDatePickerValue(date) {
		if (date instanceof Date && isNaN(date.getTime())) {
			return null;
		}

		return date; // ? moment(date) : null;
	}
}

DateInput.contextType = ApplicationContext;

DateInput.propTypes = {
	disabled: PropTypes.bool,
	className: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.instanceOf(Date),
	openToDate: PropTypes.instanceOf(Date),
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	autoComplete: PropTypes.string,
	placeholderText: PropTypes.string,
	minDate: PropTypes.instanceOf(Date)
};

DateInput.defaultProps = {
	placeholderText: 'DD-MM-YYYY',
	openToDate: null
};
