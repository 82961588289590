/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import { getStateValue } from '../../../../../utils/ReactUtils';

import CompanyCustomer from '../../../../../stores/models/CompanyCustomer';

import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import DropDown from '../../../../../components/ui/DropDown/DropDown';
import BTWInput from '../../../../../components/ui/BTWInput/BTWInput';
import RadioButton from '../../../../../components/ui/RadioButton/RadioButton';

import ModalConfirm from '../../../../../components/ui/Modal/ModalConfirm';

import Signals from '../../../../../signals/Signals';
import { ApplicationContext } from '../../../../../ApplicationContext';
import { FormErrorContext } from '../../../../../FormErrorContext';
import GetCountriesCommand from '../../../../../commands/GetCountriesCommand';
import UpdateCompanyCustomerCommand from '../../../../../commands/companyCustomers/UpdateCompanyCustomerCommand';
import CreateCompanyCustomerCommand from '../../../../../commands/companyCustomers/CreateCompanyCustomerCommand';

@observer
/**
 *
 */
class ClientInput extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		// Save companyCustomerData on load
		this.el = null;

		this.state = {
			errors: [],
			submitting: false
		};

		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		if (this.context.applicationStore.isLoggedIn) {
			const company = this.context.applicationStore.getSelectedCompany();
			const command = new GetCountriesCommand(company, this.context.applicationStore.countryStore);
			command.execute();
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.KeyDown.add(this.onKeyDown);
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 */
	render() {
		// Check if logged in
		if (!this.context.applicationStore.isLoggedIn) {
			return null;
		}

		const { applicationStore } = this.context;
		const { countryStore } = applicationStore;
		const { companyCustomer, updateLocalOnly, intl } = this.props;
		const { submitting, errors } = this.state;
		const isNew = !updateLocalOnly && companyCustomer.id == null;

		// Country options
		const lang = applicationStore.user.language;
		const countries = countryStore.getCountriesSortedByName(lang);
		const countryOptions = countries.map((country) => {
			return (
				<option key={country.iso} value={country.iso}>
					{country.getName(lang)}
				</option>
			);
		});

		//
		const selectedCountry = countryStore.getCountryByISO(
			this.state.countryCode ? this.state.countryCode : companyCustomer.countryCode
		);
		if (!selectedCountry) {
			return null;
		}

		//
		const isPrivatePerson = getStateValue(this.state.privatePerson, companyCustomer.privatePerson);

		// Render
		const formClasses = classNames({ 'form--submitting': submitting });
		return (
			<div
				ref={(el) => {
					this.el = el;
				}}
				className="client-input box-shadow-heavy">
				<FormErrorContext.Provider value={{ errors }}>
					<form className={formClasses} onSubmit={this.onSubmit} onChange={this.onInputChange}>
						<div className="client-input__header padding border--bottom border--dark grid grid--spread">
							<h2 className="pad-left">
								{isNew ? (
									<FormattedMessage id="client.input.new.title" />
								) : (
									<FormattedMessage id="client.input.edit.title" />
								)}
							</h2>
							<div
								className="client-input__close icon icon icon--close-black"
								onClick={this.onCancel}
							/>
						</div>

						<div className="client-input__content padding-small">
							{/* Common settings for each row */}
							<FormGroup>
								<FormField className="col--12 form-field--no-bottom-padding form-field--no-bottom-margin">
									<label>
										<FormattedMessage id="client.input.clienttype" />
									</label>
								</FormField>
								<FormField className="col--6">
									<RadioButton
										className="col--12 radio-button--border"
										value="false"
										name="privatePerson"
										label={intl.formatMessage({ id: 'client.input.clienttype.business' })}
										checked={!isPrivatePerson}
									/>
								</FormField>
								<FormField className="col--6">
									<RadioButton
										className="col--12 radio-button--border"
										value="true"
										name="privatePerson"
										label={intl.formatMessage({ id: 'client.input.clienttype.privateperson' })}
										checked={isPrivatePerson}
									/>
								</FormField>

								{/* Hide company name if private person */}
								{!isPrivatePerson ? (
									<FormField className="col--12">
										<label>
											<FormattedMessage id="client.input.companyname" />
										</label>
										<input
											name="companyName"
											type="text"
											maxLength="255"
											required
											value={getStateValue(this.state.companyName, companyCustomer.companyName)}
										/>
									</FormField>
								) : null}

								<FormField className="col--6">
									<label>
										<FormattedMessage id="client.input.contactpersonemail" />
									</label>
									<input
										name="contactPersonEmail"
										type="email"
										required="required"
										maxLength="255"
										value={getStateValue(
											this.state.contactPersonEmail,
											companyCustomer.contactPersonEmail
										)}
									/>
								</FormField>
								<FormField className="col--6">
									<label>
										<FormattedMessage id="client.input.contactpersonname" />
									</label>
									<input
										name="contactPersonName"
										type="text"
										required="required"
										maxLength="255"
										value={getStateValue(
											this.state.contactPersonName,
											companyCustomer.contactPersonName
										)}
									/>
								</FormField>

								<FormField className="col--5">
									<label>
										<FormattedMessage id="client.input.address" />
									</label>
									<input
										name="address"
										type="text"
										required="required"
										maxLength="255"
										value={getStateValue(this.state.address, companyCustomer.address)}
									/>
								</FormField>

								<FormField className="col--3">
									<label>
										<FormattedMessage id="client.input.postalcode" />
									</label>
									<input
										name="postalCode"
										type="text"
										required="required"
										maxLength="255"
										value={getStateValue(this.state.postalCode, companyCustomer.postalCode)}
										onChange={this.onInputChange}
									/>
								</FormField>

								<FormField className="col--4">
									<label>
										<FormattedMessage id="client.input.city" />
									</label>
									<input
										name="city"
										type="text"
										required="required"
										maxLength="255"
										value={getStateValue(this.state.city, companyCustomer.city)}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="client.input.country" />
									</label>
									<DropDown
										className="col--12"
										name="countryCode"
										value={getStateValue(this.state.countryCode, companyCustomer.countryCode)}>
										{countryOptions}
									</DropDown>
								</FormField>

								{/* Hide btw if private person */}
								{!isPrivatePerson && selectedCountry.isEU ? (
									<FormField className="col--6">
										<label>
											<FormattedMessage id="client.input.btw" />
										</label>
										<BTWInput
											name="btwNr"
											useLabel
											label={countryStore
												.getCountryByISO(
													getStateValue(this.state.countryCode, companyCustomer.countryCode)
												)
												.vatCode.toUpperCase()}
											value={getStateValue(this.state.btwNr, companyCustomer.btwNr)}
											placeholder={selectedCountry.vatExample}
											onChange={this.onInputChange}
										/>
									</FormField>
								) : null}
							</FormGroup>

							<FormGroup>
								<FormField className="col--12">
									<label>
										<FormattedMessage id="client.input.info" />
									</label>
									<textarea
										name="info"
										maxLength="2048"
										value={getStateValue(this.state.info, companyCustomer.info)}
										placeholder={intl.formatMessage({ id: 'client.input.info.placeholder' })}
									/>
								</FormField>
							</FormGroup>
						</div>

						<div className="options-footer border--top border--dark grid grid--spread">
							<button onClick={this.onCancel} className="button" type="button">
								<FormattedMessage id="label.cancel" />
							</button>
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</FormErrorContext.Provider>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'privatePerson':
				const isPrivatePerson = e.target.value === 'true';
				this.state[e.target.name] = isPrivatePerson;
				if (isPrivatePerson) {
					this.state.btwNr = null;
				}
				break;
			case 'countryCode':
				// Remove ISO code from store BTW value and prepend country vatCode code when company changes
				const iso = e.target.value;
				const { companyCustomer } = this.props;
				const { applicationStore } = this.context;
				const { countryStore } = applicationStore;

				const btwNr = this.state.btwNr ? this.state.btwNr : companyCustomer.btwNr;
				if (btwNr) {
					const country = countryStore.getCountryByISO(iso);
					this.state.btwNr = country.vatCode.toUpperCase() + btwNr.substr(2);
				}

				this.state[e.target.name] = iso;
				break;
			case 'email':
				this.state[e.target.name] = `${e.target.value}`.trim();
				break;
			case 'btwNr':
				this.state[e.target.name] = e.target.value;
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 27:
				this.onCancel();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Modify data
		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'errors':
					case 'submitting':
						// Ignore
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		this.setState({ errors: null, submitting: true });
		this._cleanBTW();

		// Apply update fields to clone of CompanyCustomer
		const { applicationStore } = this.context;
		const { companyCustomer, updateLocalOnly, onUpdate } = this.props;
		const company = applicationStore.getSelectedCompany();
		const updatedCompanyCustomer = companyCustomer.clone();
		updatedCompanyCustomer.update(data);

		// Update supplied companycustomer
		if (updateLocalOnly) {
			companyCustomer.update(data);

			if (onUpdate) {
				onUpdate(companyCustomer);
			}

			Signals.HideOverlay.dispatch();
			// Update existing, or create new
		} else if (companyCustomer.id) {
			const command = new UpdateCompanyCustomerCommand(company, updatedCompanyCustomer);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
			// Create new companycustomer
		} else {
			const command = new CreateCompanyCustomerCommand(company, updatedCompanyCustomer);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
		}
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		const { applicationStore } = this.context;
		const { companyCustomerStore } = applicationStore;
		const { companyCustomer, onUpdate } = this.props;
		const isNew = !companyCustomer.id;

		// Update props model
		let updatedCompanyCustomer = null;
		if (isNew) {
			companyCustomer.update(response.data);
			companyCustomerStore.add(companyCustomer);
			Signals.CompanyCustomerAdded.dispatch(response.data.id);
			updatedCompanyCustomer = companyCustomer;

			// Save in store
		} else {
			updatedCompanyCustomer = companyCustomerStore.find(companyCustomer.id).update(this.state);
			companyCustomerStore.update();
		}

		//
		if (onUpdate) {
			onUpdate(updatedCompanyCustomer);
		}

		if (isNew) {
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage
					id="client.added.message"
					values={{ companyName: response.data.companyName }}
				/>
			);
		} else {
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage
					id="client.edited.message"
					values={{ companyName: companyCustomer.companyName }}
				/>
			);
		}

		// Reset state
		this.state = {
			errors: null
		};

		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param e
	 */
	onCancel(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.hasChanged()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'client.input.discard.alert' })}
					yesLabel={this.props.intl.formatMessage({ id: 'label.yes.discard' })}
					noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
					onConfirm={() => this.close()}
				/>
			);
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasChanged() {
		const { companyCustomer } = this.props;
		const editedCustomer = new CompanyCustomer(companyCustomer);
		editedCustomer.update(this.state);
		return !companyCustomer.isEqual(editedCustomer);
	}

	/**
	 *
	 * @private
	 */
	_cleanBTW() {
		const { companyCustomer } = this.props;
		let btw = this.state.btwNr ? this.state.btwNr : companyCustomer.btwNr;
		if (btw) {
			// If btw value only consists of iso code, remove it completeley
			if (btw.length <= 2) {
				btw = null;
			}
		}
		this.state.btwNr = btw;
	}
}

ClientInput.contextType = ApplicationContext;

ClientInput.propTypes = {
	intl: PropTypes.object,
	companyCustomer: PropTypes.instanceOf(CompanyCustomer),
	onUpdate: PropTypes.func,
	updateLocalOnly: PropTypes.bool // Only update supplied CompanyCustomer object
};

export default injectIntl(ClientInput);
