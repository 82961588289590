/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedDate, FormattedNumber, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../../signals/Signals';
import { defaultDateFormatOptions } from '../../../../data/Settings';
import { Routes } from '../../../../data/Routes';
import InvoiceConceptStatus from '../../../../data/InvoiceConceptStatus';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';

import { ApplicationContext } from '../../../../ApplicationContext';
import PeriodicInvoiceConceptModel from '../../../../stores/models/PeriodicInvoiceConceptModel';
import DeletePeriodicInvoiceConceptCommand from '../../../../commands/periodicInvoiceConcepts/DeletePeriodicInvoiceConceptCommand';
import ActionsToolTip from '../../../../components/ui/ToolTip/tooltips/ActionsToolTip';
import ActionModel from '../../../../stores/models/ActionModel';
import Tag from '../../../../components/ui/Tag/Tag';

/**
 *
 */
@observer
class PeriodicInvoicesRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.periodicInvoicesConceptStore = this.context.applicationStore.periodicInvoicesConceptStore;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { periodicSchedule } = this.props.periodicInvoiceConceptModel;

		const sortBy = this.periodicInvoicesConceptStore.sortBy;
		const sortByClasses = sortBy
			? `periodic-invoices-row--sorted-by--${`${sortBy}`.toLowerCase()}`
			: '';

		const classes = classNames({
			'table__row--can-edit table-row--can-edit': this.props.periodicInvoiceConceptModel.canEdit()
		});

		return (
			<tr className={`table-row ${sortByClasses} periodic-invoices-row-- ${classes}`}>
				<td className="table-row__column periodic-invoices-row__status">
					<Tag
						labelId={`periodicschedulestatus.${periodicSchedule.enabled ? 'ENABLED' : 'DISABLED'}`}
						type={periodicSchedule.statusTagType}
					/>
				</td>
				<td className="table-row__column periodic-invoices-row__lastattempt text--center">
					<FormattedMessage id={`periodicschedulestatus.${periodicSchedule.lastAttemptStatus}`} />
				</td>
				<td className="table-row__column periodic-invoices-row__period">
					<div className="">
						<FormattedMessage id="periodicscheduleinterval.each" />{' '}
						<FormattedMessage
							id={`periodicscheduleinterval.${periodicSchedule.interval}.count`}
							values={{ count: periodicSchedule.amount }}
						/>
					</div>
				</td>
				<td
					className="table-row__column table-row__column--editable periodic-invoices-row__next text--right"
					onClick={() => this.onFieldClick('next')}>
					{periodicSchedule.nextDate ? (
						<FormattedDate value={periodicSchedule.nextDate} {...defaultDateFormatOptions} />
					) : (
						'-'
					)}
				</td>
				<td
					className="table-row__column table-row__column--editable periodic-invoices-row__client"
					onClick={() => this.onFieldClick('companyCustomer')}>
					<Truncate>
						{this.props.periodicInvoiceConceptModel.companyCustomer
							? this.props.periodicInvoiceConceptModel.companyCustomer.getCustomerName()
							: '-'}
					</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable periodic-invoices-row__subject"
					onClick={() => this.onFieldClick('subject')}>
					<Truncate>{this.props.periodicInvoiceConceptModel.subject}</Truncate>
				</td>
				<td
					className={`table-row__column table-row__column--editable periodic-invoices-row__send ${
						this.props.periodicInvoiceConceptModel.sendInvoiceToCustomerAutomatically
							? 'icon icon--check-smoke'
							: ''
					}`}
					onClick={() => this.onFieldClick('send')}
				/>
				<td
					className="table-row__column table-row__column--editable invoices-row__amount text--right"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.periodicInvoiceConceptModel.getTotal()}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column text--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();
		const { intl } = this.props;

		Signals.ShowToolTip.dispatch(
			<ActionsToolTip
				onSelect={this.onActionSelect}
				options={[
					new ActionModel({
						type: 'edit',
						icon: 'edit',
						label: intl.formatMessage({ id: 'label.edit' })
					}),
					new ActionModel({
						type: 'prepare',
						icon: 'upload',
						label: intl.formatMessage({ id: 'label.prepare' })
					}),
					new ActionModel({
						type: 'remove',
						icon: 'delete',
						label: intl.formatMessage({ id: 'label.remove' })
					})
				]}
			/>,
			e.currentTarget,
			-16
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'prepare':
				this._prepare();
				break;
			case 'remove':
				this._remove();
				break;
			case 'edit':
				this._edit();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param field
	 * @param event
	 */
	onFieldClick(_field, _event) {
		this._prepare();
	}

	/**
	 *
	 * @private
	 */
	_remove() {
		const title = this.props.intl.formatMessage(
			{ id: 'invoice.remove.alert.title' },
			{
				description: this.props.periodicInvoiceConceptModel.description,
				customer: this.props.periodicInvoiceConceptModel.companyCustomer
					? this.props.periodicInvoiceConceptModel.companyCustomer.companyName
					: '-'
			}
		);

		let body = null;
		if (this.props.periodicInvoiceConceptModel.status !== InvoiceConceptStatus.CREATED) {
			body = this.props.intl.formatMessage({ id: 'invoice.remove.alert.body' });
		}

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				// eslint-disable-next-line react/jsx-no-bind
				onConfirm={this._doRemove.bind(this)}
				body={body}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeletePeriodicInvoiceConceptCommand(
				this.context.applicationStore.periodicInvoicesConceptStore,
				this.props.periodicInvoiceConceptModel,
				company
			);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_prepare() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_PERIODIC_INVOICE.getPath({
					id: company.id,
					invoiceId: this.props.periodicInvoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @param field
	 * @private
	 */
	_edit(_field = null) {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// Jump to send screen
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_PERIODIC_EDIT.getPath({
					id: company.id,
					invoiceId: this.props.periodicInvoiceConceptModel.id
				})
			);
		}
	}
}

PeriodicInvoicesRow.contextType = ApplicationContext;

PeriodicInvoicesRow.propTypes = {
	periodicInvoiceConceptModel: PropTypes.instanceOf(PeriodicInvoiceConceptModel).isRequired,
	isArchive: PropTypes.bool,
	intl: PropTypes.object
};

export default injectIntl(PeriodicInvoicesRow);
