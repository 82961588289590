/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { buildPublicAssetURL } from '../../../utils/assetUtils';
import { ApplicationContext } from '../../../ApplicationContext';

import Company from '../../../stores/models/Company';
import OfferModel from '../../../stores/models/OfferModel';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';

/**
 *
 */
@observer
class SignedOffer extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { company, offerModel } = this.props;
		if (!offerModel.signData) {
			return null;
		}

		const { applicationStore } = this.context;
		const uuid = applicationStore.currentRouteParams.uuid;
		const signatureUrl = uuid
			? buildPublicAssetURL(offerModel.publicAccessId, offerModel.signData.signatureAssetId)
			: buildCompanyAssetURL(company.id, offerModel.signData.signatureAssetId);

		return (
			<div className="signed-offer signed-offer__panel panel grid col--12 box-shadow border border--dark">
				<div className="signed-offer__header padding grid grid--spread border--bottom">
					<h2>
						<FormattedMessage id="offer.signed.title" />
					</h2>
				</div>

				<div className="signed_offer__content">
					<div className="signed-offer__name">{offerModel.signData.fullName}</div>
					<div className="signed-offer__company">{offerModel.signData.companyName}</div>
					<div className="signed-offer__email">{offerModel.signData.email}</div>

					<label>
						<FormattedMessage id="public.offer.accept.signature" />
					</label>

					<img
						className="signature border border--dark"
						src={signatureUrl}
						alt="signature"
						title="signature"
					/>
				</div>
			</div>
		);
	}
}

SignedOffer.contextType = ApplicationContext;

SignedOffer.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object,
	offerModel: PropTypes.instanceOf(OfferModel).isRequired,
	company: PropTypes.instanceOf(Company).isRequired
};

export default injectIntl(SignedOffer);
