import { observable } from 'mobx';

import CommunityCategorie from './models/CommunityCategorie';

import Signals from '../signals/Signals';

import communityCategories from '../requests/communityCategories';
/**
 *
 */
export default class CommunityCategoriesStore {
	@observable categories = [];

	@observable fetched = false;

	@observable fetching = false;

	fetch(force = false, clearBefore = true) {
		// Only fetch when first time, or when forced
		if (!this.fetched || force) {
			this.fetching = true;

			if (clearBefore) {
				this.categories = [];
			}

			communityCategories()
				.then((response) => {
					if (!clearBefore) {
						this.categories = [];
					}

					const result = response.data.categories;
					for (const id in result) {
						if (result.hasOwnProperty(id)) {
							this.categories.push(new CommunityCategorie(id, result[id].name));
						}
					}

					this.fetched = true;
					this.fetching = false;

					Signals.CommunityCategoriesFetched.dispatch();
				})
				.catch((error) => {
					this.fetching = false;
					Signals.Error.dispatch(error);
				});

			// Force change triggers
		} else {
			this.categories = this.categories.concat([]);
			Signals.CommunityCategoriesFetched.dispatch();
		}
	}

	getCategoryById(categoryId) {
		return this.categories.find((category) => {
			return category && category.id === categoryId;
		});
	}
}
