import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId, invoiceId) => {
	const headers = { Accept: 'application/pdf' };
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_INVOICE_CONCEPT_ID_PDF.replace(':id', companyId).replace(
			':invoiceconceptid',
			invoiceId
		),
		null,
		headers,
		true
	);
};

export function buildCompanyInvoicePDFURL(companyId, invoiceId) {
	if (!companyId || !invoiceId) {
		return null;
	}

	return (
		window.config.apiPrefix +
		EndPoints.COMPANIES_INVOICE_CONCEPT_ID_PDF.replace(':id', companyId).replace(
			':invoiceconceptid',
			invoiceId
		)
	);
}
