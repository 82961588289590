import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 *
 * @param username
 * @param password
 * @return {Promise}
 */
export default (username, password) => {
	let params = null;
	const withCredentials = true;
	const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	if (username && password) {
		params = { username, password };
	}

	return fetch(Methods.POST, EndPoints.LOGIN, params, headers, withCredentials);
};

/**
 *
 * @return {Promise}
 */
export function applicationData() {
	const withCredentials = true;
	return fetch(Methods.GET, EndPoints.APPLICATION_DATA, null, null, withCredentials);
}
