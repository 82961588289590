import AbstractCommand from './AbstractCommand';
import login from '../requests/login';
import PropertiesController from '../controllers/PropertiesController';
import User from '../stores/models/User';
import SessionController from '../controllers/SessionController';

/**
 *
 */
export default class LoginCommand extends AbstractCommand {
	/**
	 *
	 * @param applicationStore ApplicationStore
	 * @param userName string
	 * @param password string
	 */
	constructor(applicationStore, userName, password) {
		super();

		this.applicationStore = applicationStore;
		this.userName = userName;
		this.password = password;
	}

	/**
	 *
	 * @param success
	 * @param error
	 * @return {Promise<any | never>}
	 */
	execute(success, error) {
		return login(this.userName, this.password)
			.then((response) => {
				PropertiesController.setAdditionalProperties(response.data.additionalProperties);
				this.applicationStore.user = User.parseUserData(response.data);
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				this.applicationStore.isLoggedIn = false;
				SessionController.disable();

				if (error) {
					error(err);
				}
			});
	}
}
