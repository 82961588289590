import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';

import companyExpensesGet from '../requests/companyExpensesGet';

import ExpenseModel from '../stores/models/ExpenseModel';
import { AutocompleteTypes } from '../data/AutocompleteSearchObject';

/**
 *
 */
export default class GetCompanyExpensesCommand extends AbstractCommand {
	/**
	 * @param autocompleteStore AutocompleteStore
	 * @param companyId Number
	 */
	constructor(autocompleteStore, companyId) {
		super();

		this.companyId = companyId;
		this.autocompleteStore = autocompleteStore;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetCompanyExpensesCommand.execute');

		companyExpensesGet(this.companyId)
			.then((response) => {
				this._parseResponse(response);
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				} else {
					Signals.Error.dispatch(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResponse(response) {
		const result = [];

		response.list.forEach((expense) => {
			result.push(ExpenseModel.parseExpense(expense));
		});

		this.autocompleteStore.expenses = result;
		this.autocompleteStore.sort(AutocompleteTypes.TYPE_EXPENSES);
	}
}
