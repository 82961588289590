import React from 'react';
import PropTypes from 'prop-types';

export function SourceIcon(props) {
	const { type } = props;
	return <div className={`source-icon source-icon--${type.toLowerCase()}`} {...props} />;
}

SourceIcon.propTypes = {
	type: PropTypes.string.isRequired
};
