import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const upperCaseRegExp = /(?=.*[A-Z])/;
const lowerCaseRegExp = /(?=.*[a-z])/;
const symbolsRegExp = /(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
const digitsRegExp = /(?=.*?[0-9])/;

const MIN_PASSWORD_LENGTH = 8;

/**
 *
 * @param value
 * @return {boolean}
 */
export function validPassword(value) {
	return (
		value &&
		value.length >= MIN_PASSWORD_LENGTH &&
		upperCaseRegExp.test(value) &&
		lowerCaseRegExp.test(value) &&
		symbolsRegExp.test(value) &&
		digitsRegExp.test(value)
	);
}

/**
 *
 */
export default function PasswordValidator(props) {
	const { value } = props;

	const classes = classNames({
		'password-validator': true,
		'password-validator--show': value && value.length > 0,
		'password-validator--hide': !(value && value.length > 0)
	});

	let key = 0;
	return (
		<div className={classes}>
			<PasswordValidatorRule key={key++} isValid={value.length >= MIN_PASSWORD_LENGTH}>
				<span>Minimaal {MIN_PASSWORD_LENGTH} tekens</span>
				<div className="icon icon--check-success" />
			</PasswordValidatorRule>
			<PasswordValidatorRule key={key++} isValid={upperCaseRegExp.test(value)}>
				<span>Hoofdletters</span>
				<div className="icon icon--check-success" />
			</PasswordValidatorRule>
			<PasswordValidatorRule key={key++} isValid={lowerCaseRegExp.test(value)}>
				<span>Kleine letters</span>
				<div className="icon icon--check-success" />
			</PasswordValidatorRule>
			<PasswordValidatorRule key={key++} isValid={symbolsRegExp.test(value)}>
				<span>Symbolen</span>
				<div className="icon icon--check-success" />
			</PasswordValidatorRule>
			<PasswordValidatorRule key={key++} isValid={digitsRegExp.test(value)}>
				<span>Cijfers</span>
				<div className="icon icon--check-success" />
			</PasswordValidatorRule>
		</div>
	);
}

PasswordValidator.propTypes = {
	value: PropTypes.string
};

PasswordValidator.defaultProps = {
	value: ''
};

function PasswordValidatorRule(props) {
	const classes = classNames({
		'password-validator__rule': true,
		'password-validator__rule--validated': props.isValid
	});
	return <div className={classes}>{props.children}</div>;
}

PasswordValidatorRule.propTypes = {
	children: PropTypes.any,
	isValid: PropTypes.bool
};

PasswordValidator.defaultProps = {
	value: ''
};
