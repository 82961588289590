import React from 'react';
import PropTypes from 'prop-types';
import { IoMdSync } from 'react-icons/io';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import FormattedMessageWithClass from '../../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';

function RefreshTransactions({
	onRefresh,
	disabled,
	onSettingsPageRoute,
	toBeProcessedTransactionCount,
	intl
}) {
	const message =
		toBeProcessedTransactionCount > 0
			? intl.formatMessage(
					{
						id: 'page.transactions.description'
					},
					{
						transactions: intl.formatMessage(
							{ id: 'page.transactions.amount.transaction' },
							{
								count: toBeProcessedTransactionCount
							}
						)
					}
			  )
			: intl.formatMessage({ id: 'page.transactions.description.no_transactions' });

	return (
		<>
			<FormattedMessageWithClass className="text-2xl font-semibold" id="page.transactions.title" />
			{disabled ? (
				<span>
					<FormattedMessageWithClass
						className="text-base"
						id="page.transactions.no.account.enabled"
					/>{' '}
					<span
						className="text-secondary-darker cursor-pointer hover:underline"
						onClick={onSettingsPageRoute}>
						<FormattedMessageWithClass className="text-base" id="page.transactions.settings.link" />
					</span>
				</span>
			) : (
				<span className="text-base">{message}</span>
			)}
			<button type="button" onClick={onRefresh} disabled={disabled}>
				<div className="flex flex-row justify-center space-x-2">
					<IoMdSync
						className={classNames('text-color-black', {
							'text-zinc-300': disabled
						})}
					/>
					<FormattedMessage id="company.bankbook.transactions.sync" />
				</div>
			</button>
		</>
	);
}

RefreshTransactions.propTypes = {
	onRefresh: PropTypes.func.isRequired,
	onSettingsPageRoute: PropTypes.func.isRequired,
	toBeProcessedTransactionCount: PropTypes.number.isRequired,
	intl: PropTypes.object.isRequired,
	disabled: PropTypes.bool
};

RefreshTransactions.defaultProps = {
	disabled: false
};

export default injectIntl(RefreshTransactions);
