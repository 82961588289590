import Signal from 'signals';

/**
 *
 */
export default class FetchOptions {
	parameters = null;

	headers = null;

	withCredentials = false;

	blockUI = true;

	ignorePrefix = false;

	ignoreResponseTimeout = false;

	// Stores the request object for additional puposes like cancelling the request or tracking progress
	_request = null;

	progressSignal = new Signal();

	/**
	 *
	 * @param req
	 */
	setRequest(req) {
		this._request = req;
		req.addEventListener('progress', (event) => {
			this.onProgress(event);
		});
	}

	/**
	 *
	 * @param event
	 */
	onProgress(event) {
		this.progressSignal.dispatch(event);
	}
}
