/* eslint-disable no-case-declarations */
import { observable, computed, action } from 'mobx';
import financialYearExpenses from '../requests/financialYearExpenses';
import LedgerCodes from '../data/LedgerCodes';

import Signals from '../signals/Signals';

import { getBTWPercentages, getTotalAmountInclBTW, getTotalAmountExclBTW } from '../utils/btwUtils';

import ExpenseModel from './models/ExpenseModel';

/**
 *
 */
export default class ExpenseStore {
	@observable expenses = [];

	@observable filter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable fetched = false;

	// List of ids to ignore when filtering. Those items will not returned in the filtered list.
	@observable ignoreIds = [];

	/**
	 *
	 */
	constructor() {
		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);
	}

	/**
	 ** @returns {ExpenseModel | null}
	 */
	findById(id) {
		return this.expenses.find((expenseModel) => {
			return expenseModel.id === id;
		});
	}

	/**
	 * Retrieves all Expenses from the server for the specified financialYear
	 * and replaces any current expenses in the expenses array.
	 *
	 * @param financialYearId
	 * @returns {Promise.<T>}
	 */
	fetch(financialYearId) {
		return financialYearExpenses(financialYearId)
			.then((response) => {
				// Null results, problaby loggedout
				if (response.list == null && response.data == null) {
					const error = new Error();
					error.message = 'no results';
					error.status = 401;
					Signals.Error.dispatch(error);
					return;
				}

				const expenses = [];

				response.list.forEach((expense) => {
					expenses.push(ExpenseModel.parseExpense(expense));
				});

				this.expenses = expenses;
				this.resetFilterAndSort();
				this.fetched = true;
			})
			.catch((err) => {
				Signals.Error.dispatch(err);
			});
	}

	/**
	 *
	 */
	@action reset() {
		this.expenses = [];

		this.filter = null;
		this.sortBy = null;
		this.sortDirection = 1;
		this.fetched = false;
		this.ignoreIds = [];
	}

	/**
	 * Filter and sorted Expenses
	 *
	 * @returns {Array.<ExpenseModel>}
	 */
	@computed get sortedExpensesById() {
		return this.expenses.sort((a, b) => {
			return b.id - a.id;
		});
	}

	/**
	 * Filter Expense
	 *
	 * @returns {Array.<ExpenseModel>}
	 */
	@computed get filtered() {
		return this.expenses.filter((expense) => {
			return this._filter(expense);
		});
	}

	/**
	 * Filter and sorted Expenses
	 *
	 * @returns {Array.<ExpenseModel>}
	 */
	@computed get filteredAndSortedExpenses() {
		return this.expenses
			.filter((expense) => {
				return this._filter(expense);
			})
			.sort(this._sort);
	}

	@computed get totalAmountInclBTW() {
		return this.filteredAndSortedExpenses.reduce((total, expense) => {
			return total + expense.totalAmountInclBTW;
		}, 0);
	}

	@computed get totalAmountExclBTW() {
		return this.filteredAndSortedExpenses.reduce((total, expense) => {
			return total + expense.totalAmountExclBTW;
		}, 0);
	}

	/**
	 *
	 * @param id
	 */
	removeExpense(id) {
		const expense = this.expenses.find((i) => {
			return i.id === id;
		});

		this.expenses.remove(expense);
		Signals.ExpenseTableUpdated.dispatch();
	}

	/**
	 *
	 * @param expenseData
	 * @return {ExpenseModel}
	 */
	addExpense(expenseData) {
		const expense = ExpenseModel.parseExpense(expenseData);
		this.expenses.push(expense);
		Signals.ExpenseTableUpdated.dispatch();

		return expense;
	}

	/**
	 *
	 * @param expenseData
	 * @return {null|*}
	 */
	@action updateExpense(expenseData) {
		const expense = this.expenses.find((i) => {
			return i.id === expenseData.id;
		});

		if (expense) {
			ExpenseModel.updateExpense(expenseData, expense);
			Signals.ExpenseTableUpdated.dispatch();

			// Force update
			this.removeExpense(expense.id);
			this.expenses.push(expense);

			return expense;
		}
		console.error('expense not found');

		return null;
	}

	@action updateIgnoreIds(ids) {
		this.ignoreIds = ids;
	}

	/**
	 * Returns the next expenseNr for the selected financialYear
	 *
	 * @returns {number}
	 */
	getNextExpenseNr() {
		let highestNr = -1;
		this.expenses.forEach((expense) => {
			if (expense.expenseNr > highestNr) {
				highestNr = expense.expenseNr;
			}
		});

		return highestNr + 1;
	}

	/**
	 *
	 */
	resetFilterAndSort() {
		this.filter = null;
		this.sortBy = null;
	}

	/**
	 *
	 * @param expense
	 * @returns {boolean}
	 * @private
	 */
	_filter(expense) {
		if (expense && this.ignoreIds.indexOf(expense.id) !== -1) {
			return false;
		}

		if (!this.filter || !expense) {
			return true;
		}

		return expense.searchString.indexOf(this.filter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'expenseNr':
				result = a.expenseNr - b.expenseNr;
				break;
			case 'state':
				result = a.state.localeCompare(b.state);
				break;
			case 'customerName':
				result = a.customerName.localeCompare(b.customerName);
				break;
			case 'description':
				result = a.description.localeCompare(b.description);
				break;
			case 'projectcode':
				a = a.projectcode ? a.projectcode : '';
				b = b.projectcode ? b.projectcode : '';
				result = a.localeCompare(b);
				break;
			case 'fileName':
				if (!a.fileName && !b.fileName) {
					result = 0;
				} else if (a.fileName && !b.fileName) {
					result = 1;
				} else if (!a.fileName && b.fileName) {
					result = -1;
				} else {
					result = a.fileName.localeCompare(b.fileName);
				}
				break;
			case 'ledgerCode':
				const lca = LedgerCodes.getObjectByCode(a.getLedgerCode());
				const lcb = LedgerCodes.getObjectByCode(b.getLedgerCode());
				result = lca.name.localeCompare(lcb.name);
				break;
			case 'btw':
				const btwA = `${getBTWPercentages(a.expenseRows, a.date)}`;
				const btwB = `${getBTWPercentages(b.expenseRows, b.date)}`;
				result = btwA.localeCompare(btwB);
				break;
			case 'inclBTW':
				result = getTotalAmountInclBTW(a.expenseRows) - getTotalAmountInclBTW(b.expenseRows);
				break;
			case 'exclBTW':
				result =
					getTotalAmountExclBTW(a.expenseRows, a.date) -
					getTotalAmountExclBTW(b.expenseRows, b.date);
				break;
			case 'date':
			default:
				// eslint-disable-next-line no-nested-ternary
				result = a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}
}
