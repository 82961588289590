import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import InvoiceConceptModel from '../../../../stores/models/InvoiceConceptModel';
import InvoiceAttachmentRow from './InvoiceAttachmentRow';
import { ApplicationContext } from '../../../../ApplicationContext';
import Company from '../../../../stores/models/Company';

/**
 *
 */
class InvoiceAttachments extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.props.invoiceConceptModel.assetAttachments.length <= 0) {
			return null;
		}

		return (
			<div className="invoice-attachments invoice__panel panel grid col--12 box-shadow border border--dark">
				<div className="invoice-attachments__header padding grid grid--spread">
					<h2>
						<FormattedMessage id="invoice.attachments.title" />
					</h2>
				</div>
				<div className="invoice-attachments__rows grid">{this._getRows()}</div>
			</div>
		);
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getRows() {
		return this.props.invoiceConceptModel.assetAttachments.map((fileAsset, index) => {
			return (
				<InvoiceAttachmentRow
					key={`inv-att-${index}`}
					company={this.props.company}
					fileAsset={fileAsset}
				/>
			);
		});
	}
}

InvoiceAttachments.contextType = ApplicationContext;

InvoiceAttachments.propTypes = {
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel),
	company: PropTypes.instanceOf(Company),
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(InvoiceAttachments);
