import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (password, uuid) => {
	const params = { password };
	// const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

	return fetch(Methods.POST, EndPoints.PUBLIC_RESET_PASSWORD.replace(':uuid', uuid), params);
};
