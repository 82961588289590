import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// Models
import { IntlProvider } from 'react-intl';
import ApplicationStore from './stores/ApplicationStore';

// Views
import Views from './Views';

// Translations
import { getTranslations } from './utils/Translations';
import Signals from './signals/Signals';
import { RouteUtil } from './data/Routes';

@observer
/**
 *
 */
export default class Application extends Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.dragging = false;
		this.dragTimeout = -1;
	}

	/**
	 *
	 */
	componentWillMount() {
		// Set default timezone
		moment.tz.setDefault('Europe/Amsterdam');

		if (!window.config) {
			throw new Error('Missing config.js!');
		}

		// Drag & Drop handling
		document.addEventListener('dragover', (event) => {
			event.preventDefault();

			this.dragging = true;

			Signals.DragOver.dispatch(event);
		});

		document.addEventListener('dragenter', (event) => {
			event.preventDefault();

			this.dragging = true;

			document.body.classList.add('dragging');
			Signals.DragEnter.dispatch(event);
		});

		document.addEventListener('dragleave', (event) => {
			event.preventDefault();

			this.dragging = false;
			clearTimeout(this.dragTimeout);
			this.dragTimeout = setTimeout(() => {
				if (!this.dragging) {
					document.body.classList.remove('dragging');
				}
			}, 200);

			Signals.DragLeave.dispatch(event);
		});

		document.addEventListener('drop', (event) => {
			event.preventDefault(); // never replace window with files

			this.dragging = false;
			clearTimeout(this.dragTimeout);
			document.body.classList.remove('dragging');

			Signals.Drop.dispatch(event);
		});
	}

	/**
	 *
	 */
	componentDidMount() {
		console.log('Application.componentDidMount');

		const currentPath = window.config.useApplicationContext
			? window.location.pathname.replace(`/${window.config.applicationContext}`, '')
			: window.location.pathname;
		const currentRoute = RouteUtil.getRoute(currentPath);

		// Public url
		if (currentRoute && currentRoute.isPublic) {
			const { applicationStore } = this.props;
			applicationStore.isInitialized = true;
			return;
		}

		// Do normal startup sequence
		if (!this.props.applicationStore.isInitialized) {
			this.props.applicationStore.start();
		}
	}

	/**
	 *
	 * @param error
	 * @param errorInfo
	 */
	componentDidCatch(error, errorInfo) {
		console.log('Application.componentDidCatch', error, errorInfo);

		if (!window.config.debug) {
			this.setState({ error });
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Load localised translations
		const { applicationStore } = this.props;
		const locale =
			applicationStore.user && applicationStore.user.language
				? applicationStore.user.language
				: window.config.defaultLocale;

		const translations = getTranslations(locale);
		moment.locale(locale);

		// Render app
		return (
			<IntlProvider locale={locale} messages={translations} textComponent="span">
				<BrowserRouter>
					{applicationStore.isInitialized ? (
						<Views applicationStore={this.props.applicationStore} />
					) : null}
				</BrowserRouter>
			</IntlProvider>
		);
	}
}

Application.propTypes = {
	applicationStore: PropTypes.object
};

Application.defaultProps = {
	applicationStore: new ApplicationStore()
};
