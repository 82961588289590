import { observable } from 'mobx';

/**
 *
 */
export default class MollieProfile {
	@observable id = null;

	@observable name = null;

	@observable status = null;

	@observable mode = null;

	@observable canPayOnline = null;

	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		if (data) {
			this.parseData(data);
		}
	}

	/**
	 *
	 * @param data
	 */
	parseData(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}
}
