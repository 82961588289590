import React from 'react';
import PropTypes from 'prop-types';

export default class FormGroup extends React.Component {
	render() {
		const { children } = this.props;
		const errors = this.props.errors;
		const errorMessage = this.props.error ? this.props.error : '';

		// Loop through children, scan for FormFields and pass errors prop
		const childrenWithErrors = React.Children.map(children, (child) => {
			if (child && child.type && child.type.displayName === 'FormField') {
				return React.cloneElement(child, { errors });
			}
			return child;
		});

		// Create error div when error exists
		const error =
			errorMessage.length > 0 ? <div className="form-field__error">{`${errorMessage}`}</div> : null;

		return (
			<div className={`form-group grid ${this.props.className ? this.props.className : ''}`}>
				{childrenWithErrors}
				{error}
			</div>
		);
	}
}

FormGroup.propTypes = {
	error: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
	errors: PropTypes.array,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
