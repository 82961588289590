/* eslint-disable react/prop-types */
import React from 'react';
import { Observer } from 'mobx-react';
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../Page';
import FormattedMessageWithClass from '../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import DisconnectPonto from './DisconnectPonto';
import useCompany from '../../../utils/useCompany';
import useFiscalYear from '../../../utils/useFiscalYear';
import { Routes } from '../../../data/Routes';
import Signals from '../../../signals/Signals';
import BankAccounts from './BankAccounts';

function BankBookSettings({ intl }) {
	const company = useCompany();
	const fiscalYear = useFiscalYear();

	const handleGoTotransacties = () => {
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_BANK.getPath({
				id: company.id,
				year: fiscalYear.id
			})
		);
	};

	return (
		<Observer>
			{() => {
				return (
					<Page pageName="bankbook-settings">
						<div className="flex flex-col w-full h-full max-w-2xl">
							<div className="flex-1 justify-start content-center">
								<button
									type="button"
									className="flex flex-row space-x-2 content-center bg-transparent border-none shadow-none"
									onClick={handleGoTotransacties}>
									<FaArrowLeft />
									<FormattedMessage id="bankbook.settings.transactions" default="Transacties" />
								</button>
							</div>
							<div className="flex flex-col flex-1 content-center bg-white rounded-t-lg divide-y-2">
								<div className="p-8 text-xl border-solid border-0 border-b-2 border-gray-100">
									{intl.formatMessage({ id: 'bankbook.settings' })}
								</div>
								<div className="flex flex-col px-8 pt-8">
									<FormattedMessageWithClass
										className="text-xl font-bold"
										id="bankbook.settings.accounts"
									/>
									<DisconnectPonto />
									<div className="my-8">
										<FormattedHTMLMessage
											className="text-xl font-bold"
											id="bankbook.settings.accounts.description"
										/>
									</div>
								</div>
							</div>
							<BankAccounts />
							<div className="h-[60px] rounded-b-lg bg-[#F9F9FA]" />
						</div>
					</Page>
				);
			}}
		</Observer>
	);
}

export default injectIntl(BankBookSettings);
