import AbstractCommand from '../AbstractCommand';
import OfferModel from '../../stores/models/OfferModel';
import publicOfferGet from '../../requests/offers/publicOfferGet';

/**
 *
 */
export default class GetPublicOfferCommand extends AbstractCommand {
	/**
	 *
	 * @param offerModel OfferModel
	 * @param uuid string
	 */
	constructor(offerModel, uuid) {
		super();

		this.offerModel = offerModel;
		this.uuid = uuid;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return publicOfferGet(this.uuid)
			.then((response) => {
				OfferModel.updateOfferModel(response.data, this.offerModel);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
