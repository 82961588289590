/**
 *
 */
export default class OfferInvoiceSettings {
	/**
	 *
	 * @param company
	 */
	setDefaults(company) {
		// Set defaults
		this.senderEmail = company.email;
		this.remindCompanyUserInDays = 0;
		this.remindCompanyCustomerInDays = 30;

		// Dutch
		this.reminderMessage = { id: 'offer.settings.remindermessage.default.nl' };
		this.reminderSubject = { id: 'offer.settings.remindersubject.default.nl' };
		this.defaultOfferEmailMessage = { id: 'offer.settings.emailmessage.default.nl' };
		this.defaultOfferEmailSubject = { id: 'offer.settings.emailsubject.default.nl' };
		this.defaultNotes = { id: 'offer.settings.notes.default.nl', defaultMessage: ' ' };

		// English
		this.reminderMessageEN = { id: 'offer.settings.remindermessage.default.en' };
		this.reminderSubjectEN = { id: 'offer.settings.remindersubject.default.en' };
		this.defaultOfferEmailMessageEN = { id: 'offer.settings.emailmessage.default.en' };
		this.defaultOfferEmailSubjectEN = { id: 'offer.settings.emailsubject.default.en' };
		this.defaultNotesEN = { id: 'offer.settings.notes.default.en', defaultMessage: ' ' };

		//
		this.logoAssetUuid = null;
		this.bccRecipientEmail = '';
	}

	/**
	 * @param company
	 * @param data
	 */
	update(company, data) {
		this.setDefaults(company);

		//

		// Update values
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 *
	 * @param defaults
	 * @param ignoreFields
	 * @return {string}
	 */
	toJSON(defaults = {}, ignoreFields = {}) {
		const data = {};
		for (const key in this) {
			if (this.hasOwnProperty(key) && !(ignoreFields && ignoreFields[key])) {
				let value = this[key];

				// Try to set defaults when value is not set
				if (value === null || value === undefined) {
					value = defaults[key] ? defaults[key] : null;
				}

				// Force format of specific types and objects (like `Date`)
				data[key] = value;
			}
		}

		return JSON.stringify(data);
	}

	/**
	 *
	 * @return {any}
	 */
	toObject() {
		return JSON.parse(this.toJSON());
	}
}
