import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ApplicationContext } from '../../../ApplicationContext';
import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';

import WalkthroughPopup from './WalkthroughPopup';
import WalkthroughStartScreen from './WalkthroughStartScreen';
import WalkthroughPointer from './WalkthroughPointer';
import WalkthroughEndScreen from './WalkthroughEndScreen';
import { WALKTHROUGH_ARROW } from '../../../stores/models/WalkthroughStep';

/**
 *
 */
@observer
class Walkthrough extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onRouteChanged = this.onRouteChanged.bind(this);
		this.updateState = this.updateState.bind(this);
		this.onWalkthroughClick = this.onWalkthroughClick.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const data = [
			{
				route: Routes.COMPANY_DASHBOARD,
				titleId: 'walkthrough.intro.title',
				bodyId: 'walkthrough.intro.body'
			},
			{
				route: Routes.COMPANY_DASHBOARD,
				titleId: 'walkthrough.dashboard.title',
				bodyId: 'walkthrough.dashboard.body',
				arrow: WALKTHROUGH_ARROW.RIGHT,
				targetOffsetY: -150
			},
			{
				route: Routes.COMPANY_DASHBOARD,
				targetSelector: '.walkthrough-step-bookkeeping',
				titleId: 'walkthrough.bookkeeping.title',
				bodyId: 'walkthrough.bookkeeping.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_RESULTS,
				targetPreScript:
					"document.querySelector('.results-graph-panel.walkthrough-step-results').click()",
				targetPostScript:
					"document.querySelector('.results-graph-panel.walkthrough-step-results').click()",
				targetSelector: '.walkthrough-step-results',
				titleId: 'walkthrough.results.title',
				bodyId: 'walkthrough.results.body',
				arrow: WALKTHROUGH_ARROW.RIGHT,
				targetOffsetX: -150,
				targetOffsetY: -320
			},
			{
				route: Routes.COMPANY_RESULTS,
				targetSelector: '.walkthrough-step-revenues',
				titleId: 'walkthrough.income.title',
				bodyId: 'walkthrough.income.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_REVENUES,
				targetSelector: '.walkthrough-step-income',
				titleId: 'walkthrough.incomeoverview.title',
				bodyId: 'walkthrough.incomeoverview.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				scroll: 280,
				targetOffsetX: 100,
				targetOffsetY: 100
			},
			{
				route: Routes.COMPANY_REVENUES,
				targetSelector: '.walkthrough-step-expenses',
				titleId: 'walkthrough.expenses.title',
				bodyId: 'walkthrough.expenses.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_EXPENSES,
				targetSelector: '.walkthrough-step-expense',
				titleId: 'walkthrough.expensesoverview.title',
				bodyId: 'walkthrough.expensesoverview.body',
				arrow: WALKTHROUGH_ARROW.NORMAL,
				targetOffsetX: 200,
				targetOffsetY: -80
			},
			{
				route: Routes.COMPANY_EXPENSES,
				targetSelector: '.walkthrough-step-deadlines',
				titleId: 'walkthrough.deadlines.title',
				bodyId: 'walkthrough.deadlines.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_DEADLINES,
				titleId: 'walkthrough.deadlinesubmits.title',
				bodyId: 'walkthrough.deadlinesubmits.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				targetOffsetX: 200,
				targetOffsetY: -100
			},
			{
				route: Routes.COMPANY_DEADLINES,
				targetSelector: '.walkthrough-step-invoices',
				titleId: 'walkthrough.invoices.title',
				bodyId: 'walkthrough.invoices.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_INVOICES_ALL,
				targetSelector: '.walkthrough-step-invoices-all',
				titleId: 'walkthrough.invoicessend.title',
				bodyId: 'walkthrough.invoicessend.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				targetOffsetX: 100,
				targetOffsetY: 100
			},
			{
				route: Routes.COMPANY_INVOICES_ALL,
				targetSelector: '.walkthrough-step-offers',
				titleId: 'walkthrough.offers.title',
				bodyId: 'walkthrough.offers.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_OFFERS_ALL,
				targetSelector: '.walkthrough-step-offers-all',
				titleId: 'walkthrough.offerssend.title',
				bodyId: 'walkthrough.offerssend.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				targetOffsetX: 100,
				targetOffsetY: 100
			},
			{
				route: Routes.COMPANY_OFFERS_ALL,
				targetSelector: '.walkthrough-step-documents',
				titleId: 'walkthrough.documents.title',
				bodyId: 'walkthrough.documents.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_DOCUMENTS,
				titleId: 'walkthrough.companydocuments.title',
				bodyId: 'walkthrough.companydocuments.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				targetOffsetX: 150,
				targetOffsetY: -100
			},
			{
				route: Routes.COMPANY_DOCUMENTS,
				targetPreScript: "document.querySelector('.header__profile-item').click()",
				titleId: 'walkthrough.personalaccount.title',
				bodyId: 'walkthrough.personalaccount.body',
				targetSelector: '.tooltip__content-wrapper .profiles-item--user',
				clickable: true,
				arrow: WALKTHROUGH_ARROW.RIGHT,
				targetOffsetX: -150,
				targetOffsetY: -20
			},
			{
				route: Routes.USER_DOCUMENTS,
				titleId: 'walkthrough.personaldocuments.title',
				bodyId: 'walkthrough.personaldocuments.body',
				arrow: WALKTHROUGH_ARROW.LEFT,
				targetOffsetX: 150,
				targetOffsetY: -100
			},
			{
				route: Routes.USER_DOCUMENTS,
				targetSelector: '.walkthrough-step-dashboard',
				titleId: 'walkthrough.dashboardback.title',
				bodyId: 'walkthrough.dashboardback.body',
				clickable: true
			},
			{
				route: Routes.COMPANY_DASHBOARD,
				titleId: 'walkthrough.endtour.title',
				bodyId: 'walkthrough.endtour.body',
				hideArrow: true
			}
		];

		// Add step values
		data.forEach((data, index) => {
			data.index = index;
			data.step = index;
		});

		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;
		walkthroughStore.parseData(data);

		//
		Signals.WalkthroughUpdate.add(this.updateState);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.WalkthroughUpdate.remove(this.updateState);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		const currentStep = walkthroughStore.currentStep;
		if (!currentStep || applicationStore.currentRoute !== currentStep.route) {
			return null;
		}

		const classes = classNames({
			walkthrough: true,
			'walkthrough--playing': walkthroughStore.playing,
			'walkthrough--paused': !walkthroughStore.playing && !walkthroughStore.stopped,
			'walkthrough--stopped': walkthroughStore.stopped
		});

		return (
			<div className={classes} onClick={this.onWalkthroughClick}>
				{currentStep && walkthroughStore.current > 0 ? (
					<WalkthroughPointer walkthroughStore={walkthroughStore} />
				) : null}
				{currentStep &&
				walkthroughStore.current > 0 &&
				walkthroughStore.current < walkthroughStore.steps.length - 1 ? (
					<WalkthroughPopup
						key={`wts-${currentStep.step}`}
						walkthroughStep={currentStep}
						walkthroughStore={walkthroughStore}
						targetSelector={currentStep.targetSelector}
						onUpdate={() => {
							this.updateState();
						}}
					/>
				) : null}
				,
				<WalkthroughStartScreen walkthroughStore={walkthroughStore} />
				<WalkthroughEndScreen walkthroughStore={walkthroughStore} />
			</div>
		);
	}

	/**
	 *
	 */
	onWalkthroughClick(event) {
		console.log('onWalkthroughClick', event);
		event.preventDefault();
		event.stopPropagation();
	}

	/**
	 *
	 */
	onRouteChanged() {
		this.updateStep();
	}

	/**
	 *
	 */
	updateState() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		if (applicationStore && applicationStore.isLoggedIn) {
			const company = applicationStore.getSelectedCompany();
			const financialYear = applicationStore.getSelectedFinancialYear();
			const params = { id: company.id, year: financialYear.id };
			const currentStep = walkthroughStore.currentStep;

			if (currentStep) {
				if (currentStep && currentStep.route === applicationStore.currentRoute) {
					this.forceUpdate();
				} else {
					const route = currentStep.route.getPath(params);
					Signals.RequestRoute.dispatch(route);
				}
			}
		}
	}

	/**
	 *
	 */
	updateStep() {
		console.log('updateStep');

		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;
		const currentStep = walkthroughStore.currentStep;

		if (currentStep && currentStep.route === applicationStore.currentRoute) {
			this.forceUpdate();
		}
	}
}

Walkthrough.contextType = ApplicationContext;

Walkthrough.propTypes = {};

Walkthrough.defaultProps = {};

export default injectIntl(Walkthrough);
