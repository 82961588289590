import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 *
 */
export default class OfferActions extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.options = [
			{ type: 'edit', icon: 'edit', label: 'label.edit' },
			{ type: 'prepare', icon: 'upload', label: 'label.prepare' },
			{ type: 'view', icon: 'eye2', label: 'label.view' },
			{ type: 'duplicate', icon: 'file', label: 'label.duplicate' },
			{ type: 'invoice', icon: 'file2', label: 'label.invoice' },
			{ type: 'archive', icon: 'folder2', label: 'label.archive' },
			{ type: 'unarchive', icon: 'folder2', label: 'label.unarchive' },
			{ type: 'remove', icon: 'delete', label: 'label.remove' }
		];

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const rows = this.options.map((option, index) => {
			if (this.props.hideTypes.indexOf(option.type) >= 0) {
				return null;
			}

			return (
				<div
					key={`oa-${index}`}
					className="actions__action"
					data-type={option.type}
					onClick={this.onClick}>
					<i className={`icon icon--${option.icon}-black`} />
					<FormattedMessage id={option.label} />
				</div>
			);
		});

		return <div className="offer-actions actions">{rows}</div>;
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.type;
		this.props.onSelect(action);
	}
}

OfferActions.propTypes = {
	onSelect: PropTypes.func.isRequired,
	hideTypes: PropTypes.array
};

OfferActions.defaultProps = {
	hideTypes: []
};
