/**
 * Created by hugo on 10/10/17.
 */
export default {
	LOGIN: '/client/login',
	APPLICATION_DATA: '/client/applicationData',
	LOGOUT: '/client/logout',
	FORGOT_PASSWORD: '/client/forgotPassword',
	RESET_PASSWORD: '/client/resetPassword',
	HEARTBEAT: '/client/heartbeat',

	PUBLIC_RESET_PASSWORD: '/public/:uuid/resetPassword',

	USER: '/client/user',
	USER_DOCUMENT: '/client/user/document',
	USER_DOCUMENT_VIEW: '/client/user/document/view/:id',
	USER_DOCUMENT_TYPE: '/client/user/document/:type',

	POLICY_TERMS: '/client/policyTerms',

	DOCUMENTS_USER: '/client/user/documents',
	DOCUMENT_USER: '/client/user/documents/:documentId',

	DOCUMENTS_COMPANY: '/client/companies/:id/documents/',
	DOCUMENT_COMPANY_NEW: '/client/companies/:id/financialYear/:year/documents',
	DOCUMENT_COMPANY: '/client/companies/:id/financialYear/:year/documents/:documentId',

	COUNTRIES: '/client/countries',

	TODO: '/client/todo',

	FINANCIAL_YEAR_INCOME: '/client/financialYear/:year/invoices',

	USER_MESSAGES: '/client/messages',
	USER_MESSAGES_UNSEEN: '/client/messages/unseen',
	USER_MESSAGE_SEEN: '/client/messages/:messageId/seen',
	USER_MESSAGE_UNSEEN: '/client/messages/:messageId/unseen',

	INCOME_UPDATE: '/client/invoice/:id',
	INCOME_DELETE: '/client/invoice/:id',
	INCOME_FILE: '/client/invoice/:id/file',

	EXPENSE_UPDATE: '/client/expense/:id',
	EXPENSE_DELETE: '/client/expense/:id',
	EXPENSE_FILE: '/client/expense/:id/file',

	FINANCIAL_YEAR_EXPENSE: '/client/financialYear/:year/expenses',
	FINANCIAL_YEAR_DEADLINES: '/client/financialYear/:year/deadlines',

	FINANCIAL_YEAR_FINCANCIAL_STATEMENT: '/client/financialYear/:year/financialStatement',
	FINANCIAL_STATEMENT: '/client/financialStatement',
	FINANCIAL_STATEMENT_FILES: '/client/financialStatement/files/:fileids',
	FINANCIAL_STATEMENT_FILE: '/client/financialStatement/file/:fileid',

	ANNUAL_STATEMENT: '/client/annualStatement',
	ANNUAL_STATEMENT_FILES: '/client/annualStatement/files/:fileids',
	ANNUAL_STATEMENT_FILE: '/client/annualStatement/file/:fileid',

	// This enpdpoint is used to get general config for the application so use full path.
	FEATURES: '/restWeb/client/features',

	FINANCIAL_STATEMENT_INFO: '/client/financialStatementInfo/:year',

	FINANCIAL_YEAR_RESULT: '/client/financialYear/:year/yearResult',
	FINANCIAL_YEAR_VAT: '/client/financialYear/:year/vat',

	COMPANIES_INVOICES_SETTINGS: '/client/companies/:id/invoiceSettings',
	COMPANIES_INVOICES_SETTINGS_REFRESH_PSP: '/client/companies/:id/invoiceSettings/refreshPSP',
	COMPANIES_INVOICE_CONCEPTS: '/client/companies/:id/invoiceConcepts',
	COMPANIES_INVOICE_CONCEPTS_ARCHIVE: '/client/companies/:id/invoiceConcepts/archive',
	COMPANIES_INVOICE_CONCEPT_ID: '/client/companies/:id/invoiceConcepts/:invoiceconceptid',
	COMPANIES_INVOICE_CONCEPT_ID_TOGGLE_PAID:
		'/client/companies/:id/invoiceConcepts/:invoiceconceptid/togglePaid',
	COMPANIES_INVOICE_CONCEPT_ID_ARCHIVE:
		'/client/companies/:id/invoiceConcepts/:invoiceconceptid/archive',
	COMPANIES_INVOICE_CONCEPT_ID_UNARCHIVE:
		'/client/companies/:id/invoiceConcepts/:invoiceconceptid/unarchive',
	COMPANIES_INVOICE_CONCEPT_ID_REMINDER:
		'/client/companies/:id/invoiceConcepts/:invoiceconceptid/sendReminder',
	COMPANIES_INVOICE_CONCEPT_ID_PDF: '/client/companies/:id/invoiceConcepts/:invoiceconceptid/pdf',

	COMPANIES_PERIODIC_INVOICE_CONCEPTS: '/client/companies/:id/periodic/invoiceConcepts',
	COMPANIES_PERIODIC_INVOICE_CONCEPTS_ID:
		'/client/companies/:id/periodic/invoiceConcepts/:invoiceConceptId',

	PUBLIC_INVOICE: '/public/:uuid/invoice',

	COMPANIES_OFFERS_SETTINGS: '/client/companies/:id/offerSettings',
	COMPANIES_OFFERS: '/client/companies/:companyId/offers',
	COMPANIES_OFFER: '/client/companies/:companyId/offers/:offerId',
	COMPANIES_OFFER_ARCHIVE: '/client/companies/:companyId/offers/:offerId/archive',
	COMPANIES_OFFER_UNARCHIVE: '/client/companies/:companyId/offers/:offerId/unarchive',
	COMPANIES_OFFER_SIGN: '/client/companies/:companyId/offers/:offerId/sign',
	COMPANIES_OFFER_SEND_REMINDER: '/client/companies/:companyId/offers/:offerId/sendReminder',
	COMPANIES_OFFER_CHANGE_STATUS:
		'/client/companies/:companyId/offers/:offerId/changeStatus/:status',
	COMPANIES_OFFER_PDF: '/client/companies/:companyId/quotes/:offerId/pdf',

	COMPANIES_BANK_RECORDS: '/client/companies/:companyId/bankbook/bankrecords?year=:year',
	COMPANIES_BANK_RECORDS_MATCH:
		'/client/companies/:companyId/bankbook/bankrecords/:bankRecordId/match',
	COMPANIES_BANK_RECORDS_MATCH_ID:
		'/client/companies/:companyId/bankbook/bankrecords/:bankRecordId/match/:bankRecordMatchId',
	COMPANIES_BANK_RECORDS_MATCH_ID_APPROVE:
		'/client/companies/:companyId/bankbook/bankrecords/match/approve?:bankRecordMatchIds',
	COMPANIES_BANK_RECORDS_TRY_AUTOMATCHING:
		'/client/companies/:companyId/bankbook/bankrecords/tryAutoMatching?year=:year',
	COMPANIES_BANK_RECORDS_START_AUTOMATCHING:
		'/client/companies/:companyId/bankbook/automatch?year=:year',
	COMPANIES_BANK_RECORDS_AUTOMATCHING_STATUS:
		'/client/companies/:companyId/bankbook/automatch/status?year=:year',
	COMPANIES_BANK_RECORDS_IMPORT: '/client/companies/:companyId/bankbook/import',
	COMPANIES_BANK_RECORDS_IMPORT_ID: '/client/companies/:companyId/bankbook/import/:importId',

	COMPANIES_PONTO_SETTINGS: '/ponto/v1/onboarding/info',
	COMPANIES_PONTO_STATUS: '/ponto/v1/onboarding/status',
	COMPANIES_PONTO_ACCEPT_AGREEMENT: '/ponto/v1/onboarding/status/agreement',
	COMPANIES_PONTO_AUTH: '/ponto/v1/onboarding/auth',
	COMPANIES_PONTO_GET_TRANSACTIONS: '/ponto/v1/transactions',
	COMPANIES_PONTO_GET_ACCOUNTS: '/ponto/v1/accounts',
	COMPANIES_PONTO_ACCOUNT_DETAIL: '/ponto/v1/accounts/:accountId',
	COMPANIES_BANKBOOK_GET_TRANSACTIONS: '/bankbook/v1/transactions',
	COMPANIES_BANKBOOK_MATCH_TRANSACTION: '/bankbook/v1/transactions/:transactionId',
	COMPANIES_BANKBOOK_UNMATCH_TRANSACTION: '/bankbook/v1/transactions/:transactionId/match',
	COMPANIES_BANKBOOK_CATEGORIES: '/ponto/v1/categories',

	COMPANIES_BANK_RECORDS_REPORT: '/client/companies/:companyId/reports/bankbook?year=:year',

	PUBLIC_OFFER: '/public/:uuid/offer',
	PUBLIC_OFFER_SIGN: '/public/:uuid/offer/sign',
	PUBLIC_OFFER_DECLINE: '/public/:uuid/offer/decline',

	COMPANY: '/client/company/:id',

	COMPANY_LATEST_INVOICE_NRS: '/client/companies/:companyId/latestInvoiceNrs',
	COMPANIES_OFFER_LATEST_OFFER_NR: '/client/companies/:companyId/latestOfferNrs',

	COMPANY_CUSTOMERS: '/client/companies/:id/customers',
	COMPANY_CUSTOMERS_ID: '/client/companies/:id/customers/:customerId',

	COMPANY_BRANDS: '/client/companies/:companyId/brands',
	COMPANY_BRANDS_ID: '/client/companies/:companyId/brands/:id',

	COMPANY_ASSETS: '/client/companies/:id/assets',
	COMPANY_ASSETS_UUID: '/client/companies/:id/assets/:uuid',
	COMPANY_ASSETS_UUID_ROTATE: '/client/companies/:id/assets/:uuid/rotate',
	COMPANY_EXPENSES: '/client/companies/:companyId/expenses', // For autocomplete
	COMPANY_INVOICES: '/client/companies/:companyId/invoices', // For autocomplete

	ASSETS_UUID: '/client/assets/:uuid',
	URL_ASSETS_DATA: '/client/assets/:uuid/data',
	URL_ASSETS_ZIP: '/client/assets/zip?uuids=:uuids&fileName=:fileName',

	PUBLIC_ASSETS_UUID: '/public/:uuid/assets/:assetUuid',
	PUBLIC_ASSET_UUID: '/public/:uuid/assets',
	PUBLIC_OFFER_PDF: '/public/:uuid/quote/pdf',
	PUBLIC_INVOICE_PDF: '/public/:uuid/invoice/pdf',
	PUBLIC_INVOICE_PAYMENT: '/public/:uuid/invoice/payment',
	PUBLIC_INVOICE_PAYMENT_STATUS: '/public/:uuid/invoice/payment/status',
	// GET /public/:uuid/assets/:assetUuid
	// POST: /public/:uuid/assets`

	COMMUNITY_COMPANY_PROFILE: '/client/companies/:id/profile', // POST GET company profile of user
	COMMUNITY_PROFILES: '/client/network/profile', // '/client/community/companies/:id/search',
	COMMUNITY_PROFILE: '/client/network/profile/:profileId', // get profile with id :profileId
	COMMUNITY_CATEGORIES: '/client/network/categories', // Get available categories

	COMPANY_DRAFTS: '/client/companies/:companyId/drafts',
	COMPANY_DRAFT: '/client/companies/:companyId/drafts/:draftId',
	USER_DRAFTS: '/client/users/:userId/drafts',
	USER_DRAFT: '/client/users/:userId/drafts/:draftId',
	MOVE_DRAFTS_TO_YEAR: '/client/drafts/financialYear',

	AUTHORIZE_COMPANY_APPLICATION: '/companies/:companyId/oauth/authorize?app=:application',
	REVOKE_COMPANY_APPLICATION: '/companies/:companyId/oauth/revoke?app=:application',
	AUTHORIZATIONS_COMPANY: '/companies/:companyId/oauth/authorizations',
	MOLLIE_PROFILES: '/companies/:companyId/payments/mollie/profiles'
};
