import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

/**
 *
 * @param companyId
 * @param offerId
 * @param status
 * @return {Promise}
 */
export default (companyId, offerId, status) => {
	return fetch(
		Methods.POST,
		EndPoints.COMPANIES_OFFER_CHANGE_STATUS.replace(':companyId', companyId)
			.replace(':offerId', offerId)
			.replace(':status', status),
		null,
		null,
		true
	);
};
