import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationNumber from '../NotificationNumber/NotificationNumber';

/**
 *
 */
export default class CollapsablePanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.el = null;
		this.state = {
			isCollapsed: props.collapsed,
			canCollapse: props.canCollapse,
			isComplete: props.isComplete
		};

		this.onToggle = this.onToggle.bind(this);
		this.onResize = this.onResize.bind(this);
	}

	/**
	 *
	 */
	componentWillReceiveProps(nextProps) {
		this.setState(
			{
				isCollapsed: nextProps.collapsed,
				canCollapse: nextProps.canCollapse,
				isComplete: nextProps.isComplete
			},
			() => {
				this._updateHeight();
			}
		);
	}

	/**
	 *
	 */
	componentDidMount() {
		this._updateHeight();
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const classes = classNames({
			'collapsable-panel--collapsed': this.state.isCollapsed,
			'collapsable-panel--can-collapse': this.state.canCollapse,
			'collapsable-panel--has-pretitle': this.props.preTitle
		});

		return (
			<div
				ref={(ref) => {
					this.el = ref;
				}}
				className={`collapsable-panel panel border border--dark grid ${classes} ${this.props.className}`}>
				<div
					className="collapsable-panel__header grid grid--spread padding col--12"
					onClick={this.state.canCollapse ? this.onToggle : () => {}}>
					<h2 className="pad-left">
						{this._getIcon()}
						<span className="collapsable-panel__pre-title">{this.props.preTitle}</span>
						<span className="collapsable-panel__title">{this.props.title}</span>
					</h2>

					<div className="collapsable-panel__header-right grid">
						{this._getHover()}
						{this._getTodo()}
					</div>
				</div>
				<div className="collapsable-panel__content-wrapper col--12 grid">
					<div className="collapsable-panel__content col--12">{this.props.children}</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onToggle() {
		this.state.isCollapsed = !this.state.isCollapsed;
		this.forceUpdate();
		this._updateHeight();
	}

	/**
	 *
	 */
	onResize() {
		this.forceUpdate();
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getIcon() {
		if (this.props.iconName) {
			return <span className={`header__icon icon icon--${this.props.iconName}-smoke`} />;
		}
	}

	/**
	 *
	 * @private
	 */
	_updateHeight() {
		if (this.el) {
			const contentWrapper = this.el.querySelector('.collapsable-panel__content-wrapper');
			const content = this.el.querySelector('.collapsable-panel__content');

			clearTimeout(this._heightTimeout);

			if (this.state.isCollapsed) {
				contentWrapper.style.height = `${content.scrollHeight}px`;
				this._heightTimeout = setTimeout(() => {
					contentWrapper.style.height = 0;
				}, 1);
			} else {
				contentWrapper.style.height = `${content.scrollHeight}px`;
				this._heightTimeout = setTimeout(() => {
					contentWrapper.style.height = 'auto';
				}, 300);
			}
		}
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getHover() {
		if (!this.state.canCollapse) {
			return null;
		}
		if (this.state.isCollapsed) {
			return <div className="collapsable-panel__state">SHOW</div>;
		}
		return <div className="collapsable-panel__state">HIDE</div>;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getTodo() {
		if (!this.props.alwaysShowTodo && this.state.canCollapse) {
			if (this.state.isComplete) {
				return <div className="collapsable-panel__completed-icon icon icon--check-black" />;
			}

			return null;
		}

		return <NotificationNumber count={this.props.todoCount} />;
	}
}

CollapsablePanel.propTypes = {
	className: PropTypes.string,
	iconName: PropTypes.string,
	preTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	collapsed: PropTypes.bool,
	canCollapse: PropTypes.bool,
	isComplete: PropTypes.bool,
	todoCount: PropTypes.number,
	alwaysShowTodo: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
};

CollapsablePanel.defaultProps = {
	className: '',
	preTitle: null,
	collapsed: false,
	canCollapse: true,
	isComplete: false,
	todoCount: 0,
	alwaysShowTodo: false,
	children: null
};
