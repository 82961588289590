import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Routes } from '../../../data/Routes';

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function MainMenuCompanySettings(props) {
	const { menuItemWrapperClasses, menuItemClasses, params } = props;
	return (
		<ul className={`main-menu__item-wrapper ${menuItemWrapperClasses}`}>
			<MenuItem className={menuItemClasses} route={Routes.COMPANY_RESULTS.getPath(params)}>
				&larr; <FormattedMessage id="mainmenu.company.settings.back" />
			</MenuItem>
			<MenuItem
				className={menuItemClasses}
				route={Routes.COMPANY_SETTINGS_INFO.getPath(params)}
				routeToMatch={Routes.COMPANY_SETTINGS}>
				<FormattedMessage id="mainmenu.company.settings.settings" />
			</MenuItem>
		</ul>
	);
}

MainMenuCompanySettings.propTypes = {
	menuItemWrapperClasses: PropTypes.string,
	menuItemClasses: PropTypes.string,
	params: PropTypes.object
};
