import { observable } from 'mobx';

import Address from './Address';

import { buildAssetURL, buildCompanyAssetURL } from '../../requests/companyAssetGet';
import communityCompanyProfileUpdate from '../../requests/communityCompanyProfileUpdate';

const MAX_PHOTOS = 5;

export default class CommunityProfile {
	@observable id;

	@observable active;

	@observable name;

	@observable description;

	@observable logoAssetUuid;

	@observable coverAssetUuid;

	@observable address = null;

	@observable phone = null;

	@observable email = null;

	@observable website = null;

	@observable facebookUrl = null;

	@observable instagramUrl = null;

	@observable twitterUrl = null;

	@observable linkedinUrl = null;

	@observable category = null;

	@observable relatedProfiles = [];

	photos = [];

	subtitle = ' '; // not used for now

	intro = ' '; // not used for now

	constructor() {
		this._updatePhotos();
	}

	clone() {
		const profile = new CommunityProfile();
		const json = this.toJSON();
		const obj = JSON.parse(json);

		profile.update(obj);

		return profile;
	}

	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'address':
						this[key] = Address.parseAddress(data[key]);
						break;
					case 'intro':
					case 'subtitle':
						// ignore
						break;
					case 'relatedProfiles':
						this.relatedProfiles = [];

						data[key].forEach((profileData) => {
							this.relatedProfiles.push(this.addRelated(profileData));
						});
						break;
					case 'photos':
						this.photos = data[key] ? data[key] : []; // cannot be null
						break;
					default:
						this[key] = data[key];
				}
			}
		}

		this._updatePhotos();
	}

	addRelated(profileData) {
		const profile = new CommunityProfile();
		profile.update(profileData);
		return profile;
	}

	save(onSuccess, onError) {
		communityCompanyProfileUpdate(this.id, this.toJSON())
			.then((result) => {
				this.update(result.data);
				if (onSuccess) {
					onSuccess(result);
				}
			})
			.catch((error) => {
				if (onError) {
					onError(error);
				}
			});
	}

	hasSocialLinks() {
		return this.facebookUrl || this.twitterUrl || this.instagramUrl || this.linkedinUrl;
	}

	canActivate() {
		return (
			this.logoAssetUuid &&
			this.coverAssetUuid &&
			this.category &&
			this.name &&
			this.name.trim().length > 0 &&
			this.description &&
			this.description.trim().length > 0
		);
	}

	toJSON(defaults = {}) {
		const obj = {};
		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				obj[key] = this[key];

				// Set default if necessary
				if (defaults[key] != null && this[key] === null) {
					obj[key] = defaults[key];
				}
			}
		}

		return JSON.stringify(obj);
	}

	_updatePhotos() {
		for (let i = 0; i < MAX_PHOTOS; i++) {
			this.photos[i] = this.photos[i] ? this.photos[i] : null;
		}
	}
}

/**
 *
 * @param company Company
 * @param uuid string
 * @param width Number
 * @param height Number
 *
 * @returns {string|null}
 */
// eslint-disable-next-line func-names
CommunityProfile.buildAsset = function (company, uuid, width = null, height = null) {
	if (!uuid) {
		const w = width || 1600;
		const h = height || 1080;
		const url = `https://picsum.photos/g/${w}/${h}`;
		return url;
	}

	if (company) {
		return buildCompanyAssetURL(company.id, uuid);
	}
	return buildAssetURL(uuid, width, height);
};
