import { observable } from 'mobx';
import assetDataGet from '../../requests/assets/assetDataGet';
import Asset from './Asset';

/**
 *
 */
export default class Document {
	@observable id;

	@observable type;

	@observable year;

	@observable modified;

	@observable fileName;

	@observable metadata;

	//
	@observable assetUuid;

	@observable owner;

	/**
	 *
	 * @param data
	 */
	constructor(data) {
		this.update(data);
	}

	/**
	 *
	 * @return {Document}
	 */
	clone() {
		return new Document(this);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 *
	 */
	getModifiedAsDate() {
		return new Date(this.modified);
	}

	/**
	 *
	 */
	toJSON() {
		JSON.stringify(this);
	}

	/**
	 *
	 * @param draft
	 */
	static createNewDocumentFromDraft(draft) {
		const document = new Document({});
		const { assetUuid } = draft;

		document.assetUuid = draft.assetUuid;
		document.modified = draft.createdAt;

		assetDataGet(assetUuid).then(
			(response) => {
				const asset = new Asset(response.data);
				document.fileName = asset.filename;
			},
			(err) => {
				console.log(err);
			}
		);

		return document;
	}
}
