import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class OverlayPanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	render() {
		const { className } = this.props;
		const classes = classNames({
			'overlay-panel': true,
			' box-shadow-heavy': true
		});
		return <div className={`${classes} ${className}`}>{this.props.children}</div>;
	}
}

OverlayPanel.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

OverlayPanel.defaultProps = {
	className: ''
};
