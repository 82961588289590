import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';
import userDocumentsType from '../requests/userDocumentsType';
import DocumentTypes from '../data/DocumentTypes';

/**
 *
 */
export default class GetUserDocumentTypeCommand extends AbstractCommand {
	/**
	 *
	 * @param user User
	 * @param type string
	 */
	constructor(user, type = DocumentTypes.PASPOORT.name) {
		super();

		this.user = user;
		this.documentType = type;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetUserDocumentTypeCommand.execute');

		return userDocumentsType(this.documentType)
			.then((response) => {
				const files = response.list;
				if (files) {
					files.sort((a, b) => {
						return b.modified - a.modified;
					});

					this.user.passportFile = files.length > 0 ? files[0] : null;
				}

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}

				Signals.Error.dispatch(error);
			});
	}
}
