import { observable, computed, action } from 'mobx';
import _ from 'lodash';

import Model from './models/Model';
import {
	compareNumbers,
	compareStrings,
	compareDates,
	compareBooleans
} from '../utils/compareUtils';

/**
 *
 */
export default class Store {
	modelType;

	@observable list = [];

	@observable sortField = 'id';

	@observable sortDirection = 1;

	/**
	 *
	 * @param modelType
	 */
	constructor(modelType = Model) {
		this.modelType = modelType;

		this._sort = this._sort.bind(this);
	}

	/**
	 *
	 * @param fieldName
	 * @param value
	 * @return {*}
	 */
	find(fieldName, value) {
		return this.list.find((model) => {
			return model[fieldName] === value;
		});
	}

	/**
	 *
	 * @param array
	 * @param reset
	 */
	addAll(array, reset = false) {
		if (reset) {
			this.reset();
		}
		const models = [];
		array.forEach((data) => {
			let model;
			if (!(data instanceof this.modelType)) {
				// eslint-disable-next-line new-cap
				model = new this.modelType(data);
			} else {
				model = data;
			}
			models.push(model);
		});
		this.list = this.list.concat(models);
		this.update();
	}

	/**
	 *
	 * @param data
	 * @param forceUpdate
	 */
	add(data, forceUpdate = true) {
		let model;
		if (!(data instanceof this.modelType)) {
			// eslint-disable-next-line new-cap
			model = new this.modelType(data);
		} else {
			model = data;
		}

		this.list.push(model);

		if (forceUpdate) {
			this.update();
		}
	}

	/**
	 *
	 * @param data
	 */
	remove(data) {
		_.remove(this.list, (item) => {
			return data === item;
		});

		// Force trigger mobx event
		this.list = this.list.concat([]);
	}

	/**
	 *
	 */
	reset() {
		this.list = [];
	}

	/**
	 *
	 */
	update() {
		this.list = this.list.concat([]);
	}

	getByIndex(index) {
		return this.list[index];
	}

	@computed get length() {
		return this.list.length;
	}

	/**
	 *
	 * @return {*}
	 */
	@computed get filteredAndSorted() {
		return this.filtered.sort(this._sort);
	}

	/**
	 *
	 */
	@computed get filtered() {
		if (this.filter) {
			return this.list.filter((object) => {
				return this.filter.apply(object);
			});
		}

		return this.list;
	}

	/**
	 *
	 * @return {this}
	 */
	@computed get sorted() {
		return this.list.sort(this._sort);
	}

	/**
	 *
	 * @param field
	 */
	@action sort(field) {
		if (this.sortField === field) {
			this.sortDirection *= -1;
		}
		this.sortField = field;
	}

	/**
	 *
	 * @param modelA
	 * @param modelB
	 * @private
	 */
	_sort(modelA, modelB) {
		const modelType = modelA[this.sortField]
			? typeof modelA[this.sortField]
			: typeof modelB[this.sortField];
		// const typeB = typeof modelB[this.sortField];

		let compareFunction = compareNumbers;

		switch (modelType) {
			case 'number':
				compareFunction = compareNumbers;
				break;
			case 'string':
				compareFunction = compareStrings;
				break;
			case 'boolean':
				compareFunction = compareBooleans;
				break;
			case 'object':
				compareFunction = compareDates;
				break;
			default:
				console.log("can't compare for type", modelType);
				return 0;
		}

		return compareFunction(modelA[this.sortField], modelB[this.sortField]) * this.sortDirection;
	}
}
