import generalConfigGet from '@/requests/generalConfigGet';

export const fetchConfig = (appContext) => {
	return generalConfigGet().then((response) => {
		const { data } = response;
		const rootUrl = data.reactJsUrlRoot;
		const periodicInvoiceConceptsFeatureToggle = data.periodicInvoiceConcepts;
		const mollieFeatureToggle = data.mollie;
		const demoModeToggle = data.demoMode;
		const loonadministratieToggle = data.loonadministratie;
		const applicationContext = data.tomcatPath;
		const debug =
			!(rootUrl.indexOf('http') >= 0) ||
			rootUrl.indexOf('//test') >= 0 ||
			rootUrl.indexOf('//staging') >= 0 ||
			rootUrl.indexOf('localhost') >= 0;

		return {
			debug,
			defaultLocale: 'nl',
			applicationContext,
			useApplicationContext: applicationContext !== '',

			imageRoot: `/images/images_js/${appContext}/`,
			imageIncome: `${rootUrl}/mijn/inkomsten/bekijkInkomsteBon?id=`,
			imageExpense: `${rootUrl}/mijn/uitgaven/bekijkUitgaveBon?id=`,
			privateDocument: `${rootUrl}/mijn/uitgaven/bekijkUitgaveBon?id=`,
			apiPrefix: `${rootUrl}/restWeb`,

			periodicInvoiceConcepts: periodicInvoiceConceptsFeatureToggle,
			mollie: mollieFeatureToggle,
			demoMode: demoModeToggle,
			payrollAdministration: loonadministratieToggle
		};
	});
};
