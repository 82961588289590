import React from 'react';
import PropTypes from 'prop-types';

function CompanyCustomerOption(props) {
	const { innerProps, data, isDisabled } = props;
	return !isDisabled ? (
		<div className="company-customer-option" {...innerProps}>
			<span className="company-customer-option__customer-name">{data.customerName}</span>
			<span className="company-customer-option__customer-email">{data.customerEmail}</span>
		</div>
	) : null;
}

CompanyCustomerOption.propTypes = {
	innerProps: PropTypes.any,
	data: PropTypes.any,
	isDisabled: PropTypes.bool
};

export default CompanyCustomerOption;
