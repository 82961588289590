import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';

import { applicationData } from '../requests/login';

import User from '../stores/models/User';

import PropertiesController from '../controllers/PropertiesController';
import SessionController from '../controllers/SessionController';

/**
 *
 */
export default class GetApplicationDataCommand extends AbstractCommand {
	/**
	 * @param applicationStore ApplicationStore
	 */
	constructor(applicationStore) {
		super();

		this.applicationStore = applicationStore;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetApplicationDataCommand.execute');

		applicationData()
			.then((response) => {
				if (response.data != null) {
					PropertiesController.setAdditionalProperties(response.data.additionalProperties);
					this.applicationStore.user = User.parseUserData(response.data);
				} else {
					this.applicationStore.user = null;
					this.applicationStore.isLoggedIn = false;
					this.applicationStore.isInitialized = true;

					SessionController.disable();
				}

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				console.log('ApplicationStore.start error', err);

				this.applicationStore.user = null;
				this.applicationStore.isLoggedIn = false;
				this.applicationStore.isInitialized = true;

				SessionController.disable();

				// Ignore admin login error
				if (err.error && err.error.rawResponse !== 'User cannot be null') {
					Signals.Error.dispatch(err);
				}

				if (error) {
					error(err);
				}
			});
	}
}
