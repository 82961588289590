export const getRelativeDays = (startDate, endDate) => {
	const start = new Date(startDate);
	const end = new Date(endDate);

	const diff = end.getTime() - start.getTime();
	const days = diff / (1000 * 60 * 60 * 24);

	return Math.round(days);
};

/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {number}
 */
export const getAbsoluteDays = (startDate, endDate) => {
	return Math.abs(getRelativeDays(startDate, endDate));
};
