import { _formatStringToDate } from './objectToFormData';

/**
 *
 * @param document
 * @return {number}
 */
export function getPayrollDocumentMonth(document) {
	const runRanAtDate = _formatStringToDate(document.metadata.runRanAt, true);
	let month = runRanAtDate.getMonth();

	// Try to override with period month as it is more representative of the actual document month
	try {
		month = document.metadata.runPeriod.split('-')[1];
	} catch (e) {
		console.log('Error getting payroll document month', e);
	}

	return month;
}

/**
 *
 * @param document
 * @return {null|Date}
 */
export function getPayrollDocumentRunPeriodDate(document) {
	if (document.metadata && document.metadata.runPeriod) {
		const parts = document.metadata.runPeriod.split('-');
		const year = parseInt(parts[0], 10);
		const month = parseInt(parts[1], 10) - 1;

		return new Date(year, month, 1);
	}

	return null;
}

/**
 *
 * @param document
 * @return {null|Date}
 */
export function getPayrollDocumentRunRanAtDate(document) {
	if (document.metadata && document.metadata.runRanAt) {
		return _formatStringToDate(document.metadata.runRanAt, true);
	}

	return null;
}

/**
 *
 * @param documents Array
 * @return {Date}
 */
export function getPayrollDocumentsTitle(documents, intl) {
	if (documents.length > 0) {
		let date = getPayrollDocumentRunPeriodDate(documents[0]);
		if (!date) {
			date = getPayrollDocumentRunRanAtDate(documents[0]);
		}

		// Try to get title from runPeriod, otherwise use runRanAtDate
		return intl.formatDate(date, { month: 'long' });
	}

	return null;
}
