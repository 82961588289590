import { action, observable } from 'mobx';
import GetBankbookStatusCommand from '../commands/bank/GetBankbookStatusCommand';

export default class BankbookStatusStore {
	@observable connected = false;

	@observable agreement = {
		accepted: false,
		termsOfService: null
	};

	@observable fetched = false;

	@observable organizationName = null;

	fetching = false;

	constructor() {
		this.connected = false;
		this.agreement = {
			accepted: false,
			termsOfService: null
		};
		this.organizationName = null;
		this.fetched = false;
	}

	@action fetch({ companyId }) {
		if (!this.fetching && !this.fetched) {
			console.log('BankbookStatusStore.fetch');
			const command = new GetBankbookStatusCommand();
			this.fetched = false;
			this.fetching = true;
			return command.execute(companyId).then((response) => {
				if (response) {
					this.connected = response.connected;
					this.agreement = response.agreement;
					this.organizationName = response.organizationName;
					this.fetched = true;
					this.fetching = false;
				} else {
					this.fetching = false;
					this.reset();
				}
			});
		}
		return Promise.resolve();
	}

	// Do not reset aggrement because it is one time agreement
	@action reset() {
		this.connected = false;
		this.organizationName = null;
		this.fetched = false;
	}
}
