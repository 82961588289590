import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { BankRecordType } from '../../../../../data/BankRecordType';
import TableItem from './TableItem';
// eslint-disable-next-line no-unused-vars
import IncomeStore from '../../../../../stores/IncomeStore';
// eslint-disable-next-line no-unused-vars
import ExpenseStore from '../../../../../stores/ExpenseStore';
// eslint-disable-next-line no-unused-vars
import BankRecordMatchStore from '../../../../../stores/BankRecordMatchStore';
// eslint-disable-next-line no-unused-vars
import FinancialYear from '../../../../../stores/models/FinancialYear';
import useIncomeStore from '../../../../../utils/useIncomeStore';
import useExpenseStore from '../../../../../utils/useExpenseStore';

function EmptyRow({ intl }) {
	return (
		<div className="flex justify-center items-center p-4 bg-tableBackground text-zinc-500">
			{intl.formatMessage({ id: 'no.results' })}
		</div>
	);
}

EmptyRow.propTypes = {
	intl: PropTypes.object.isRequired
};

/**
 * @param {IncomeStore|ExpenseStore} store
 * @param {string} search
 * @param {BankRecordMatchStore} bankRecordMatchStore
 * @param {FinancialYear} fiscalYear
 * @param {any} intl
 */
function IncomeExpenseTable({ search, bankRecordMatchStore, fiscalYear, intl }) {
	const incomeStore = useIncomeStore();
	const expenseStore = useExpenseStore();

	useEffect(() => {
		if (!bankRecordMatchStore.bankRecordInEdit) return false;
		if (bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.INCOME) {
			incomeStore.filter = search;
		} else if (bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.EXPENSE) {
			expenseStore.filter = search;
		}
	}, [search]);

	return (
		<Observer>
			{() => {
				if (!bankRecordMatchStore.bankRecordInEdit) return null;
				return (
					<div className="flex-grow overflow-y-auto mb-16 relative">
						<div className="flex flex-row bg-zinc-100 top-0 z-10 sticky">
							<div className="basis-1/12 table__header min-w-fit max-w-[88px] hover:cursor-default hidden md:flex">
								{' '}
								{intl.formatMessage({ id: 'expenses.header.expensenr' })}{' '}
							</div>
							<div className="sm:basis-2/12 table__header max-w-[110px] flex-1 hover:cursor-default">
								{' '}
								{intl.formatMessage({ id: 'transactions.header.date' })}{' '}
							</div>
							<div className="md:basis-3/12 table__header hidden sm:block sm:flex-1 hover:cursor-default">
								{' '}
								{intl.formatMessage({ id: 'expenses.header.company' })}{' '}
							</div>
							<div className="basis-3/12 table__header hidden md:block hover:cursor-default">
								{' '}
								{intl.formatMessage({ id: 'transactions.header.description' })}{' '}
							</div>
							<div className="sm:basis-2/12 table__header min-w-[80px] flex-1 hover:cursor-default">
								{' '}
								{intl.formatMessage({ id: 'expenses.header.incl.btw' })}{' '}
							</div>
							<div className="sm:basis-1/12 table__header hover:cursor-default">
								{' '}
								{intl.formatMessage({ id: 'transactions.header.status' })}{' '}
							</div>
						</div>
						<div>
							{bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.INCOME &&
								(incomeStore.filteredAndSortedIncome.length > 0 ? (
									incomeStore.filteredAndSortedIncome.map((resource) => (
										<TableItem
											type={BankRecordType.INCOME}
											key={resource.id}
											item={resource}
											bankRecordMatchStore={bankRecordMatchStore}
											fiscalYear={fiscalYear}
										/>
									))
								) : (
									<EmptyRow intl={intl} />
								))}
							{bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.EXPENSE &&
								(expenseStore.filteredAndSortedExpenses.length > 0 ? (
									expenseStore.filteredAndSortedExpenses.map((resource) => (
										<TableItem
											type={BankRecordType.EXPENSE}
											key={resource.id}
											item={resource}
											bankRecordMatchStore={bankRecordMatchStore}
											fiscalYear={fiscalYear}
										/>
									))
								) : (
									<EmptyRow intl={intl} />
								))}
						</div>
					</div>
				);
			}}
		</Observer>
	);
}

IncomeExpenseTable.propTypes = {
	search: PropTypes.string.isRequired,
	bankRecordMatchStore: PropTypes.object.isRequired,
	fiscalYear: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired
};

export default injectIntl(IncomeExpenseTable);
