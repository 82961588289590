import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Routes } from '../../../../data/Routes';
import MenuItem from '../../MenuItem/MenuItem';
import { ApplicationContext } from '../../../../ApplicationContext';
import PropertiesController from '../../../../controllers/PropertiesController';

/**
 *
 */
class InvoicesSubMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;
	}

	/**
	 *
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company || !financialYear) return null;

		const params = {
			id: company ? company.id : null,
			year: financialYear ? financialYear.id : null
		};

		return (
			<ul className="sub-menu__item-wrapper">
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_INVOICES_ALL.getPath(params)}
					routeToMatch={Routes.COMPANY_INVOICES_ALL}
					iconName="folder">
					<FormattedMessage id="submenu.invoices.all" />
				</MenuItem>
				{PropertiesController.getProperty(
					PropertiesController.FEATURE_PERIODIC_INVOICE_CONCEPTS
				) ? (
					<MenuItem
						className="submenu-item"
						route={Routes.COMPANY_INVOICES_PERIODIC_ALL.getPath(params)}
						routeToMatch={Routes.COMPANY_INVOICES_PERIODIC_ALL}
						iconName="folder">
						<FormattedMessage id="submenu.invoices.periodic" />
					</MenuItem>
				) : null}
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_INVOICES_ARCHIVE.getPath(params)}
					routeToMatch={Routes.COMPANY_INVOICES_ARCHIVE}
					iconName="folder2">
					<FormattedMessage id="submenu.invoices.archive" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_INVOICES_CLIENTS.getPath(params)}
					routeToMatch={Routes.COMPANY_INVOICES_CLIENTS}
					iconName="people2">
					<FormattedMessage id="submenu.invoices.clients" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_SETTINGS_INVOICES.getPath(params)}
					routeToMatch={Routes.COMPANY_SETTINGS_INVOICES}
					iconName="gear">
					<FormattedMessage id="submenu.invoices.settings" />
				</MenuItem>
			</ul>
		);
	}
}

InvoicesSubMenu.contextType = ApplicationContext;

InvoicesSubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(InvoicesSubMenu);
