import cookie from 'react-cookies';

export const COOKIE_TYPES = {
	SELECTED_COMPANY_COOKIE_ID: 'selected_company',
	SELECTED_COMPANY_BRAND_ID: 'selected_company_brand',
	TODOS_COOKIE_ID: 'todos',
	SKIP_ON_BOARDING_COOKIE_ID: 'skip_onboarding',
	ASSET_UPLOAD_PANEL_COLLAPSED: 'asset_upload_panel_collapsed',
	NO_MOLLIE_ALERT_SEEN: 'no_mollie_alert_seen'
};

const COOKIE_EXPIRES = 365; // year expiration duration

/**
 *
 */
class CookieController {
	/**
	 *
	 * @param type
	 */
	getCookie(type) {
		console.log('getCookie', type);
		return cookie.load(type);
	}

	/**
	 *
	 * @param type
	 * @param value
	 * @param expiration
	 */
	setCookie(type, value, expiration = null) {
		console.log('setCookie', type, value);

		const expires = new Date();
		expires.setDate(expires.getDate() + (expiration || COOKIE_EXPIRES));
		cookie.save(type, value, { path: '/', expires });
	}
}

const cookieController = new CookieController();
export default cookieController;
