import fetch2 from '../fetch2';
import FetchOptions from '../FetchOptions';

import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

/**
 *
 * @param userId
 * @param data
 * @param options FetchOptions
 * @return {Promise<any>|Promise}
 */
export default (userId, data, options = new FetchOptions()) => {
	const url = EndPoints.USER_DRAFTS.replace(':userId', userId);

	const fetchOptions = options;
	fetchOptions.parameters = data;
	fetchOptions.withCredentials = true;
	fetchOptions.ignoreResponseTimeout = true;
	fetchOptions.blockUI = false;

	return fetch2(Methods.POST, url, fetchOptions);
};
