import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
export default class Switch extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { focus: false };

		this.uid = uniqueKey('sw-uid-');
		this.checkbox = null;

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.state.checked = this.props.checked;
		this.state.value = this.props.value;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'switch--focus': this.state.focus,
			'switch--disabled': this.props.disabled
		});

		return (
			<div
				className={`switch ${this.props.className ? this.props.className : ''} ${classes}`}
				onClick={this.onClick}>
				<input
					id={this.uid}
					type="checkbox"
					ref={(checkbox) => (this.checkbox = checkbox)}
					name={this.props.name}
					value={this.state.value}
					checked={this.props.useState ? this.state.checked : this.props.checked}
					disabled={this.props.disabled}
					onChange={this.onChange}
				/>
				<div className="switch__check" />

				<label className="no-select" htmlFor={this.uid}>
					{this.props.label}
				</label>
			</div>
		);
	}

	onFocus() {
		this.setState({ focus: true });
	}

	onBlur() {
		this.setState({ focus: false });
	}

	onClick(e) {
		this.checkbox.checked = !this.checkbox.checked;
		e.target.checked = this.checkbox.checked;

		this.onChange(e);
	}

	onChange(e) {
		this.state.checked = this.checkbox.checked;

		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}
}

Switch.propTypes = {
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	checked: PropTypes.bool,
	useState: PropTypes.bool,
	disabled: PropTypes.bool
};

Switch.defaultProps = {
	useState: true,
	disabled: false
};
