import React from 'react';
import { Observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PontoLogo from '../../../../images/images_css/founders/ponto.svg';
import DeletePontoIntegrationStatusCommand from '../../../commands/bank/DeletePontoIntegrationCommand';
import useCompany from '../../../utils/useCompany';
import useBankbookStatusStore from '../../../utils/useBankbookStatusStore';
import FormattedMessageWithClass from '../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import useFiscalYear from '../../../utils/useFiscalYear';
import Signals from '../../../signals/Signals';
import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';

function DisconnectPonto({ intl }) {
	const company = useCompany();
	const fiscalYear = useFiscalYear();
	const bankbookStatusStore = useBankbookStatusStore();

	const handleClick = () => {
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={intl.formatMessage({ id: 'bankbook.settings.disconnect.title' })}
				body={intl.formatMessage({ id: 'bankbook.settings.disconnect.body' })}
				yesLabel={intl.formatMessage({ id: 'label.yes.sure' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
				onConfirm={() => {
					if (company && fiscalYear && bankbookStatusStore) {
						const command = new DeletePontoIntegrationStatusCommand();
						command.execute(company.id, fiscalYear.id, bankbookStatusStore).then(() => {
							Signals.HideModal.dispatch();
						});
					}
				}}
			/>
		);
	};

	return (
		<Observer>
			{() => {
				return (
					<div className="flex flex-row items-stretch content-center justify-between mt-5 mb-8 py-3 px-3 border border-gray-100 rounded-xl shadow-sm">
						<div className="flex flex-row items-center content-center justify-center space-x-2">
							<img src={PontoLogo} className="w-25 h-20" alt="Ponto" />
							{bankbookStatusStore && (
								<span className="text-l"> {bankbookStatusStore.organizationName} </span>
							)}
						</div>
						<button
							type="button"
							className="color-error bg-transparent border-none shadow-none active:bg-transparent self-center"
							onClick={handleClick}>
							<FormattedMessageWithClass id="bankbook.settings.ponto.unlink" />
						</button>
					</div>
				);
			}}
		</Observer>
	);
}

DisconnectPonto.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(DisconnectPonto);
