import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ApplicationContext } from '../../../ApplicationContext';
import Signals from '../../../signals/Signals';

import CompanyBrandWrapper from './components/CompanyBrandWrapper';
import CompanyBrandInput from './modals/CompanyBrandInput';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
@observer
class GenericSettingsBrands extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { companyBrandStore: null, submitting: false };

		this.onAddCompanyBrand = this.onAddCompanyBrand.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		// Initialize
		this.componentWillReceiveProps();
	}

	/**
	 * Initialize, fetch brands
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		const { applicationStore } = this.context;
		const { companyBrandStore } = applicationStore;
		const company = applicationStore.getSelectedCompany();
		if (company) {
			this.setState({ companyBrandStore });
			companyBrandStore.fetch(company.id);
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { intl } = this.props;
		const { submitting, companyBrandStore } = this.state;
		const company = applicationStore.getSelectedCompany();

		if (!applicationStore.isLoggedIn || !company) {
			return null;
		}

		//
		const formClasses = classNames({
			'generic-settings__panel panel grid col--12 box-shadow border border--dark': true,
			'form--submitting': submitting
		});

		return (
			<div className={formClasses} onSubmit={this.onSubmit}>
				<div className="generic-settings__header padding border--bottom border--dark">
					<h2 className="pad-left">
						<FormattedMessage id="generic.settings.title.brands" />
					</h2>
				</div>
				<div className="generic-settings__content">
					<div className="generic-settings-addresses">
						{/* Default */}
						<CompanyBrandWrapper
							label={intl.formatMessage({ id: 'company.brand.default' })}
							company={company}
							companyBrand={company.defaultCompanyBrand}
							canEdit
						/>

						{companyBrandStore.list.map((companyAddress) => {
							return (
								<CompanyBrandWrapper
									className="border--top border--dark"
									company={company}
									companyBrand={companyAddress}
									canEdit
								/>
							);
						})}
					</div>

					<div className="options-footer generic-settings__footer border--top border--dark grid grid--spread">
						<div className="grid user-info__footer-buttons" />
						<button type="button" onClick={this.onAddCompanyBrand}>
							<FormattedMessage id="company.brand.add" />
						</button>
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param event
	 */
	onAddCompanyBrand(_event) {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput key={uniqueKey('cbi-')} isNew companyBrand={company.defaultCompanyBrand} />
		);
	}
}

GenericSettingsBrands.contextType = ApplicationContext;

GenericSettingsBrands.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(GenericSettingsBrands);
