export default class Country {
	constructor(iso, data) {
		this.iso = iso;
		this.name = data.name;
		this.isEU = !!data.isEU;
		this.vatCode = data.vatCode;
		this.vatValidationRegex = data.vatValidationRegex;
		this.vatExample = data.vatExample ? data.vatExample : '';
	}

	getName(lang = 'nl') {
		return this.name[lang];
	}
}
