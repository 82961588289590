import { observable } from 'mobx';

export default class AuthorizationStore {
	@observable
	authorizations = [];

	/**
	 *
	 * @param data
	 */
	setAuthorisations(data) {
		this.authorizations = data;
	}

	/**
	 *
	 * @param application
	 * @return {boolean}
	 */
	hasAuthorization(application) {
		return this.authorizations.find((value) => {
			return value === application;
		});
	}

	/**
	 *
	 * @param application
	 */
	revokeAuthorization(application) {
		const index = this.authorizations.findIndex((value) => {
			return value === application;
		});

		if (index >= 0) {
			this.authorizations.splice(index, 1);
		}
	}
}
