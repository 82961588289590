import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import PropertiesController from '../../../../controllers/PropertiesController';
import InvoiceConceptModel from '../../../../stores/models/InvoiceConceptModel';
import InvoiceHistoryRow from './InvoiceHistoryRow';
import { ApplicationContext } from '../../../../ApplicationContext';
import GetInvoiceConceptCommand from '../../../../commands/invoiceConcepts/GetInvoiceConceptCommand';

/**
 *
 */
class InvoiceHistory extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentDidMount() {
		// Retrieve history and force render when retrieved
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new GetInvoiceConceptCommand(this.props.invoiceConceptModel, company);
			command.execute(() => {
				this.forceUpdate();
			});
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Always hide on demo account
		if (PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
			return null;
		}

		const rows = this.props.invoiceConceptModel.history.map((historyData, index) => {
			return (
				<InvoiceHistoryRow
					key={`inv-his-${index}`}
					description={historyData.description}
					date={historyData.date}
				/>
			);
		});

		return (
			<div className="invoice-history panel grid col--12 box-shadow border border--dark">
				<div className="invoice-history__header padding grid grid--spread">
					<h2>
						<FormattedMessage id="invoice.history.title" />
					</h2>
				</div>
				<div className="invoice-history__rows grid">{rows}</div>
			</div>
		);
	}
}

InvoiceHistory.contextType = ApplicationContext;

InvoiceHistory.propTypes = {
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel),
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(InvoiceHistory);
