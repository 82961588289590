/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function AlertMessage(props) {
	const { className, title, message, icon, danger, info, success, warning, onClose } = props;
	const classes = classNames({
		'alert-message grid': true,
		'alert-message--danger': danger,
		'alert-message--info': info,
		'alert-message--success': success,
		'alert-message--warning': warning,
		'alert-message--no-message': !message
	});

	return (
		<div className={`${classes} ${className}`}>
			<div className="alert-message__title grid grid--no-wrap col--12">
				{icon ? <div className={`icon ${icon}`} /> : null} <label>{title}</label>
			</div>
			{/* eslint-disable-next-line react/no-danger */}
			{message ? <span dangerouslySetInnerHTML={{ __html: message }} /> : null}

			{onClose ? (
				<div className="alert-message__close icon icon icon--close-info" onClick={onClose} />
			) : null}
		</div>
	);
}

AlertMessage.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	icon: PropTypes.string,
	className: PropTypes.string,
	danger: PropTypes.bool,
	success: PropTypes.bool,
	warning: PropTypes.bool,
	info: PropTypes.bool,
	onClose: PropTypes.func
};

AlertMessage.defaultProps = {
	className: '',
	danger: false,
	info: false,
	success: false,
	warning: false,
	onClose: null
};
