import React from 'react';
import PropTypes from 'prop-types';
import { extractErrorMessage } from '../../../utils/extractErrorMessage';
import { FormErrorContext } from '../../../FormErrorContext';

/**
 *
 */
export default class FormField extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		const { children } = this.props;
		const errors = this.props.errors ? this.props.errors : this.context ? this.context.errors : [];
		let errorMessage = this.props.error ? this.props.error : '';

		// Loop through children, scan for errors where child name props == error field name
		const childrenWithErrors = React.Children.map(children, (child) => {
			if (!child) {
				return null;
			}

			const msg = extractErrorMessage(errors, child.props.name);

			// If an error exists, clone the child, and add an error class to className prop
			if (msg) {
				errorMessage += errorMessage.length > 0 ? ` / ${msg}` : msg;
				const className = child.props.className ? `${child.props.className} error` : 'error';
				return React.cloneElement(child, { className });
			}
			return child;
		});

		// Create error div when error exists
		const error =
			errorMessage.length > 0 ? (
				<div
					className="form-field__error"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: `${errorMessage}` }}
				/>
			) : null;

		return (
			<div className={`form-field grid ${this.props.className ? this.props.className : ''}`}>
				{childrenWithErrors}
				{error}
			</div>
		);
	}
}

FormField.contextType = FormErrorContext;

FormField.displayName = 'FormField';

FormField.propTypes = {
	className: PropTypes.string,
	error: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
	errors: PropTypes.array,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
