/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Routes } from '../../data/Routes';

import Signals from '../../signals/Signals';

import ModalAlert from '../../components/ui/Modal/ModalAlert';

import Page from '../Page';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';

import forgotPassword from '../../requests/forgotPassword';

class ForgotPassword extends React.Component {
	constructor() {
		super();

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);

		this.state = {
			errors: null
		};
	}

	render() {
		return (
			<Page pageName="forgot-password page--logo">
				<div className="vertically-center">
					<div className="forgot-password__panel panel border border--dark">
						<div className="forgot-password__header padding border--bottom border--dark">
							<h2 className="pad-left">
								<FormattedMessage id="forgot-password.title" />
							</h2>
						</div>

						<form
							className="forgot-password__form padding-small"
							action={Routes.LOGIN.pathName}
							name="forgot-password"
							onSubmit={this.onSubmit}>
							<FormGroup error={this.state.errors}>
								<FormField className="col--12">
									<label>
										<FormattedMessage id="login.field.email" />
									</label>
									<input
										type="email"
										name="username"
										required
										placeholder={this.props.intl.formatMessage({
											id: 'forgot-password.field.email'
										})}
									/>
								</FormField>
							</FormGroup>

							<FormGroup>
								<FormField className="col--12">
									<button className="forgot-password__submit-button button--primary" type="submit">
										<FormattedMessage id="forgot-password.field.submit" />
									</button>
								</FormField>
							</FormGroup>

							<FormGroup className="grid--center">
								<FormField className="col-12 grid grid--center">
									<Link to={Routes.LOGIN.pathName}>
										<button type="button" className="button--tertiary button--no-border">
											<FormattedMessage id="forgot-password.field.login" />
										</button>
									</Link>
								</FormField>
							</FormGroup>
						</form>
					</div>
				</div>
			</Page>
		);
	}

	onSubmit(e) {
		e.preventDefault();

		this.setState({ error: null });

		const form = e.target;
		const userName = form.querySelector('input[name="username"]').value;

		forgotPassword(userName).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	onSubmitSuccess(_response) {
		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatMessage({ id: 'forgot-password.success.title' })}
				body={this.props.intl.formatMessage({ id: 'forgot-password.success.body' })}
				onConfirm={() => Signals.RequestRoute.dispatch(Routes.LOGIN.pathName)}
			/>
		);
	}

	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}
}

ForgotPassword.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(ForgotPassword);
