import React from 'react';
import { observable } from 'mobx';

import logout from '../requests/logout';

import Signals from '../signals/Signals';
import { Routes } from '../data/Routes';
import { getParameterByName } from '../utils/urlUtils';

// Controllers
import SessionController from '../controllers/SessionController';
import CookieController, { COOKIE_TYPES } from '../controllers/CookieController';

// Stores
import TodoStore from './TodoStore';
import IncomeStore from './IncomeStore';
import ExpenseStore from './ExpenseStore';
import YearResultStore from './YearResultStore';
import FinancialStatementStore from './FinancialStatementStore';
import AnnualStatementStore from './AnnualStatementStore';
import InvoicesConceptStore from './InvoicesConceptStore';
import OffersStore from './OffersStore';
import CompanyCustomerStore from './CompanyCustomerStore';
import CommunityProfileStore from './CommunityProfileStore';
import CommunityCategoriesStore from './CommunityCategoriesStore';
import CompanyLatestInvoiceNumberStore from './CompanyLatestInvoiceNumberStore';
import CountryStore from './CountryStore';
import BlogStore from './BlogStore';
import AutocompleteStore from './AutocompleteStore';
import UserMessagesStore from './UserMessagesStore';
import DraftsFileUploadStore from './DraftsFileUploadStore';
import BankRecordStore from './BankRecordStore';

//
import ModalAlert from '../components/ui/Modal/ModalAlert';

// Commands
import GetApplicationDataCommand from '../commands/GetApplicationDataCommand';
import LoginCommand from '../commands/LoginCommand';
import FetchStartDataCommand from '../commands/FetchStartDataCommand';
import CompanyLatestOfferNumberStore from './CompanyLatestOfferNumberStore';
import CompanyBrandStore from './CompanyBrandStore';
import WalkthroughStore from './WalkthroughStore';
import ProgressStatusStore from './ProgressStatusStore';
import PeriodicInvoicesConceptStore from './PeriodicInvoicesConceptStore';
import BankbookStatusStore from './BankbookStatusStore';
import BankAccountStore from './BankAccountStore';
import BankCategoryStore from './BankCategoryStore';
import putPolicyTerms from '../requests/policyTerms/putPolicyTerms';
import BankbookAnimationStore from './BankbookAnimationStore';

/**
 *
 */
export default class ApplicationStore {
	// States
	_selectedCompanyId = null;

	_selectedFinancialYearId = null;

	_selectedDocumentYear = null;

	@observable user = null;

	@observable isInitialized = false;

	@observable isLoggedIn = false;

	// Stores
	@observable todoStore = new TodoStore();

	@observable incomeStore = new IncomeStore();

	@observable expenseStore = new ExpenseStore();

	@observable yearResultStore = new YearResultStore();

	@observable financialStatementStore = new FinancialStatementStore();

	@observable annualStatementStore = new AnnualStatementStore();

	@observable invoicesConceptStore = new InvoicesConceptStore();

	@observable periodicInvoicesConceptStore = new PeriodicInvoicesConceptStore();

	@observable invoicesConceptArchiveStore = new InvoicesConceptStore(true);

	@observable companyCustomerStore = new CompanyCustomerStore();

	@observable companyBrandStore = new CompanyBrandStore();

	@observable communityProfileStore = new CommunityProfileStore();

	@observable communityCategoriesStore = new CommunityCategoriesStore();

	@observable companyLatestInvoiceNumberStore = new CompanyLatestInvoiceNumberStore();

	@observable companyLatestOfferNumberStore = new CompanyLatestOfferNumberStore();

	@observable walkthroughStore = new WalkthroughStore();

	@observable countryStore = new CountryStore();

	@observable blogStore = new BlogStore();

	@observable offersStore = new OffersStore();

	@observable offersArchiveStore = new OffersStore(true);

	@observable userMessagesStore = new UserMessagesStore();

	@observable bankCategoryStore = new BankCategoryStore();

	@observable bankRecordStore = new BankRecordStore(this.bankCategoryStore);

	@observable bankbookStatusStore = new BankbookStatusStore();

	@observable bankAccountStore = new BankAccountStore();

	@observable bankbookAnimationStore = new BankbookAnimationStore();

	progressStatusStore = new ProgressStatusStore();

	draftsFileUploadStore = new DraftsFileUploadStore();

	autocompleteStore = new AutocompleteStore();

	// Route variables, updated by RoutingController.jsx
	currentRoute;

	currentRouteParams;

	currentRoutePath;

	// Original URL
	deeplinkPath;

	deeplinkSearch;

	deeplinkHash;

	deeplinkRoute;

	// Intl
	intl;

	/**
	 *
	 */
	constructor() {
		// Capture global key events and send via Signal
		document.addEventListener('keydown', (e) => {
			Signals.KeyDown.dispatch(e);
		});

		// Bind functions to this scope
		this.logout = this.logout.bind(this);

		// Listen to Logout Signal
		Signals.Logout.add(this.logout);
	}

	/**
	 * Start up sequence, check if user is logged in
	 */
	start() {
		console.log('ApplicationStore.start');

		const getApplicationDataCommand = new GetApplicationDataCommand(this);
		getApplicationDataCommand.execute(() => {
			this.fetchStartData();
		});
	}

	/**
	 * Login a user
	 *
	 * @param userName
	 * @param password
	 * @param errorCallback
	 */
	login(userName, password, errorCallback) {
		console.log('ApplicationStore.login');

		const loginCommand = new LoginCommand(this, userName, password);
		loginCommand.execute(() => {
			this.fetchStartData();
		}, errorCallback);
	}

	/**
	 *
	 */
	logout() {
		console.log('ApplicationStore.logout');

		logout()
			.then((_response) => {
				this.reset();
			})
			.catch((_err) => {
				this.reset();
			});
	}

	acceptPolicyTerms() {
		console.log('ApplicationStore.acceptPolicyTerms');
		putPolicyTerms()
			.then(() => {
				this.user.acceptedPrivacyTerms = true;
				Signals.RequestRoute.dispatch(Routes.ON_BOARDING.pathName);
			})
			.catch((err) => {
				Signals.Error.dispatch(err);
			});
	}

	/**
	 *
	 */
	reset() {
		console.log('ApplicationStore.reset');

		this.isLoggedIn = false;
		this.user = null;
		this._selectedCompanyId = null;
		this._selectedFinancialYearId = null;

		// Clear data
		this.todoStore.reset();
		this.invoicesConceptStore.reset();
		this.periodicInvoicesConceptStore.reset();
		this.invoicesConceptArchiveStore.reset();
		this.incomeStore.reset();
		this.expenseStore.reset();
		this.companyCustomerStore.reset();
		this.companyBrandStore.reset();
		this.financialStatementStore.reset();
		this.annualStatementStore.reset();
		this.yearResultStore.reset();
		this.companyLatestInvoiceNumberStore.reset();
		this.companyLatestOfferNumberStore.reset();
		this.offersStore.reset();
		this.offersArchiveStore.reset();
		this.autocompleteStore.reset();
		this.draftsFileUploadStore.reset();
		this.progressStatusStore.reset();
		this.userMessagesStore.reset();
		this.bankRecordStore.resetStore();
		this.bankAccountStore.reset();
		this.bankCategoryStore.reset();
		this.bankbookAnimationStore.reset();

		// Deactivate session expiration check
		SessionController.disable();

		// Navigate to Login page
		Signals.RequestRoute.dispatch(Routes.LOGIN);
	}

	/**
	 *
	 */
	fetchStartData() {
		console.log('ApplicationStore.fetchStartData');

		const fetchStartDataCommand = new FetchStartDataCommand(this);
		fetchStartDataCommand.execute(
			() => {
				this.onStartDataFetched();
			},
			() => {
				this.onStartDataFailed();
			}
		);
	}

	/**
	 *
	 */
	onStartDataFetched() {
		console.log('ApplicationStore.onStartDataFetched');

		//
		this.isLoggedIn = true;
		this.isInitialized = true;

		//
		SessionController.enable();

		// Dispatch LoggedIn Signal
		Signals.LoggedIn.dispatch(this.user);

		// Load todos after login sequence
		this.todoStore.fetch();
	}

	/**
	 *
	 */
	onStartDataFailed() {
		console.log('ApplicationStore.onStartDataFailed');
	}

	/**
	 ** Sets the selected company  id to the specified id
	 *
	 * @param companyId
	 */
	setSelectedCompany(companyId) {
		companyId = parseInt(companyId, 10);

		if (this._selectedCompanyId !== companyId) {
			this._selectedCompanyId = companyId;
			this.companyCustomerStore.fetch(this._selectedCompanyId);
			this.companyBrandStore.fetch(this._selectedCompanyId);
			this.bankRecordStore.resetStore();
			this.bankbookStatusStore.reset();
			this.bankAccountStore.reset();
			Signals.CompanyChanged.dispatch(companyId);
		}

		// Save last selected company
		CookieController.setCookie(COOKIE_TYPES.SELECTED_COMPANY_COOKIE_ID, companyId);
	}

	/**
	 * Get selected Company object, if none is set it will try to get a default (first in User object). Will return null if none found.
	 *
	 * @returns {CompanyModel}
	 */
	getSelectedCompany(autoSelect = false) {
		if (!this.user) {
			return null;
		}

		let company = this.user.getCompanyById(this._selectedCompanyId);
		if (!company && autoSelect) {
			company = this.user.getFirstCompany();
			this.setSelectedCompany(company.id);
		}

		return company;
	}

	/**
	 * Sets the selected financial year id to the specified id
	 *
	 * @param financialYearId
	 */
	setSelectedFinancialYear(financialYearId) {
		if (financialYearId !== this._selectedFinancialYearId) {
			this._selectedFinancialYearId = financialYearId;

			// Dispatch so that components can determine if they need to be updated
			Signals.FinancialYearChanged.dispatch(financialYearId);

			// Show financial year closed alert if necessary
			const financialYear = this.getSelectedFinancialYear();

			// Reset bank record store
			this.bankRecordStore.resetStore();
			this.bankbookAnimationStore.reset();
			this.incomeStore.reset();
			this.expenseStore.reset();

			if (this.intl && !financialYear.isActive()) {
				if (financialYear.isClosed()) {
					Signals.ShowModal.dispatch(
						<ModalAlert
							title={this.intl.formatMessage(
								{ id: 'alert.financialyear.closed.title' },
								{ year: financialYear.year }
							)}
							body={this.intl.formatMessage(
								{
									id: 'alert.financialyear.closed.description'
								},
								{ year: financialYear.year }
							)}
						/>
					);
				} else if (financialYear.isSubmitted()) {
					Signals.ShowModal.dispatch(
						<ModalAlert
							title={this.intl.formatMessage(
								{ id: 'alert.financialyear.submitted.title' },
								{ year: financialYear.year }
							)}
							body={this.intl.formatMessage(
								{
									id: 'alert.financialyear.submitted.description'
								},
								{ year: financialYear.year }
							)}
						/>
					);
				}
			}
		}
	}

	/**
	 * Get selected FinancialYear object, if none is set it will try to get a default (first selected Company object). Will return null if none found.
	 *
	 * @returns {CompanyModel}
	 */
	getSelectedFinancialYear() {
		if (!this.user) {
			return null;
		}

		const company = this.getSelectedCompany();
		if (!company) {
			return null;
		}

		let financialYear = company.getFinancialYearById(this._selectedFinancialYearId);

		if (!financialYear) {
			financialYear = company.getFirstFinancialYear();

			if (financialYear) {
				this.setSelectedFinancialYear(financialYear.id);
			}
		}

		return financialYear;
	}

	/**
	 *
	 * @param year
	 */
	setSelectedDocumentYear(year) {
		this._selectedDocumentYear = year;
		Signals.DocumentYearChanged.dispatch(year);
	}

	/**
	 *
	 * @return {*}
	 */
	getSelectedDocumentYear() {
		return this._selectedDocumentYear ? this._selectedDocumentYear : new Date().getFullYear();
	}

	/**
	 * Set all FinancialYears in all companies on AFGEROND for the specified year
	 *
	 * @param year
	 */
	closeFinancialYear(year) {
		this.user.companies.forEach((company) => {
			company.financialYears.forEach((financialYear) => {
				if (financialYear.year === year) {
					financialYear.setClosed();
				}
			});
		});
	}

	/**
	 * Returns an array of available bookyears e.g. [2016,2017,2018,...]
	 */
	getAvailableBookYears() {
		const years = [];

		if (!this.user) {
			return years;
		}

		this.user.companies.forEach((company) => {
			company.financialYears.forEach((financialYear) => {
				const year = financialYear.year;
				if (years.indexOf(year) < 0) {
					years.push(year);
				}
			});
		});

		const currentYear = new Date();
		if (years.indexOf(currentYear.getFullYear()) < 0) {
			years.push(currentYear.getFullYear());
		}

		return years;
	}

	/**
	 *
	 * @param reset
	 * @returns {*}
	 */
	getDeeplink(reset = true) {
		let url = null;
		if (this.deeplinkPath) {
			url = this.deeplinkPath;

			if (this.deeplinkSearch) {
				// Mollie specific deeplink ?app=MOLLIE&state=success
				if (getParameterByName('app-kdb.scss', url + this.deeplinkSearch) === 'MOLLIE') {
					url += '#integrations';
				}
			}

			// Make sure to add hashes for deeplink purposes
			if (this.deeplinkHash) {
				url += this.deeplinkHash;
			}
		}

		if (reset) {
			this.deeplinkSearch = null;
			this.deeplinkPath = null;
			this.deeplinkHash = null;
		}

		return url;
	}

	/**
	 * Get current route url search params
	 * @returns {UrlSearchParams}
	 */
	getSearchParams() {
		if (this.currentRoute) {
			return this.currentRoute.urlSearchParams;
		}
		return null;
	}
}
