import React from 'react';
import PropTypes from 'prop-types';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class ModalConfirm extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onConfirm = this.onConfirm.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.KeyDown.add(this.onKeyDown);
	}

	/**
	 *
	 */
	componentDidMount() {
		// Blur active element
		if ('activeElement' in document) document.activeElement.blur();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { icon } = this.props;

		return (
			<div className="modal-confirm">
				<div className="modal-confirm__close icon icon icon--close-black" onClick={this.onCancel} />

				<div className={`modal-confirm__icon icon ${icon}`} />

				<h1 className="modal-confirm__title">{this.props.title}</h1>
				{this.props.body ? (
					<p
						className="modal-confirm__body col--12 text--center"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: this.props.body }}
					/>
				) : null}

				<div className="grid grid--center padding--top">
					<button
						type="button"
						className="modal-confirm__no margin-small--bottom button--tertiary"
						onClick={this.onCancel}>
						{this.props.noLabel}
					</button>
					<button
						type="button"
						className="modal-confirm__yes margin-small--bottom button--primary"
						onClick={this.onConfirm}>
						{this.props.yesLabel}
					</button>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 * @return {boolean}
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 13: // ENTER
				this.onConfirm();
				return false; // stop propagating
			case 27: // ESC
				this.onCancel();
				return false; // stop propagating
			default:
				break;
		}
	}

	/**
	 *
	 */
	onCancel() {
		Signals.HideModal.dispatch();
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	/**
	 *
	 */
	onConfirm() {
		Signals.HideModal.dispatch();
		if (this.props.onConfirm) {
			this.props.onConfirm();
		}
	}
}

ModalConfirm.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string.isRequired,
	body: PropTypes.string,
	noLabel: PropTypes.string,
	yesLabel: PropTypes.string,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func
};

ModalConfirm.defaultProps = {
	icon: 'icon--eye-black',
	noLabel: 'No',
	yesLabel: 'Yes'
};
