import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueKey } from '../../../utils/ReactUtils';

export default class Checkbox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			focus: false
		};

		this.uid = uniqueKey('cb-uid-');
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.checkbox = null;

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {}

	render() {
		const classes = classNames({
			'checkbox--focus': this.state.focus
		});

		return (
			<div className={`checkbox ${this.props.className ? this.props.className : ''} ${classes}`}>
				<input
					id={this.uid}
					type="checkbox"
					// eslint-disable-next-line react/no-unused-class-component-methods
					ref={(checkbox) => (this.checkbox = checkbox)}
					name={this.props.name}
					value={this.props.value}
					required={this.props.required}
					defaultChecked={this.props.checked}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>
				<div className="checkbox__check icon icon--check-white" />

				<label className="no-select" htmlFor={this.uid}>
					{this.props.label}
				</label>
			</div>
		);
	}

	onFocus() {
		this.setState({ focus: true });
	}

	onBlur() {
		this.setState({ focus: false });
	}

	onChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}
}

Checkbox.propTypes = {
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	checked: PropTypes.bool,
	required: PropTypes.bool
};

Checkbox.defaultProps = {
	required: false
};
