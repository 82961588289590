import { observable } from 'mobx';
import Model from './Model';

/**
 *
 */
export default class ActionModel extends Model {
	@observable type;

	@observable icon;

	@observable label;

	/**
	 *
	 * @return {string}
	 */
	toString() {
		return `ActionModel: ${this.type}, ${this.icon}, ${this.label}`;
	}
}
