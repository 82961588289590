export function compareNumbers(a, b) {
	if (b === null || b === undefined) {
		return 1;
	}
	if (a === null || a === undefined) {
		return -1;
	}

	return a - b;
}

export function compareStrings(a, b) {
	const left = a || '';
	const right = b || '';

	return left.localeCompare(right);
}

export function compareBooleans(a, b) {
	const valA = a ? 1 : 0;
	const valB = b ? 1 : 0;

	return valA - valB;
}

export function compareDates(a, b) {
	const valA = a || new Date(Number.NEGATIVE_INFINITY);
	const valB = b || new Date(Number.NEGATIVE_INFINITY);

	return valA.getTime() - valB.getTime();
}
