export function forceDownload(fileURL, fileName) {
	// Create an invisible A element
	const a = document.createElement('a');
	a.style.display = 'none';
	document.body.appendChild(a);

	//
	a.href = fileURL;

	// Use download attribute to set set desired file name
	a.setAttribute('download', fileName);
	a.setAttribute('target', '_blank');

	// Trigger the download by simulating click
	a.click();

	// Cleanup
	window.URL.revokeObjectURL(a.href);
	document.body.removeChild(a);
}

/**
 *
 * @param type
 * @param accept
 * @return {boolean}
 * @private
 */
export function validateMimeType(type, accept = '.gif,.png,.jpg,.jpeg,.pdf,.txt,.csv') {
	console.log('validateMimeType', type, accept);
	const validTypes = accept.split(',');
	const parts = type.split('/');

	console.log(validTypes, parts);
	let valid = false;
	if (parts.length > 1) {
		const extension = `.${parts[1]}`;
		console.log('\textension', extension);
		validTypes.forEach((allowedType) => {
			if (allowedType === extension) {
				valid = true;
			}
		});
	} else {
		valid = true;
	}

	return valid;
}

/**
 *
 * @param size
 * @param maxSize
 * @return {boolean}
 * @private
 */
export function validateSize(size, maxSize = 1024 * 1024 * 20) {
	return size <= maxSize;
}

/**
 *
 * @param fileName
 * @param force
 * @return {*}
 * @private
 */
export function normalizeFileName(fileName, force = false) {
	// Use current date + timestamp when filename doesn't exist
	if (!fileName || fileName.length === 0 || force) {
		const date = new Date();
		const day = date.getDate();
		const monthIndex = date.getMonth();
		const year = date.getFullYear();

		fileName = `${year}-${monthIndex}-${day}`;
	}

	return fileName;
}

/**
 *
 * @param mimeType
 * @return {*}
 */
export function compressMimeType(mimeType) {
	const segements = mimeType.split('/');
	return segements.length > 1 ? segements[1] : segements[0];
}
