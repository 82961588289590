import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
// eslint-disable-next-line no-unused-vars
import BankCategoryStore from '../stores/BankCategoryStore';

/**
 * This hook returns the category store.
 * @return {BankCategoryStore}
 */
const useBankCategoryStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { bankCategoryStore } = applicationStore;

	return bankCategoryStore;
};

export default useBankCategoryStore;
