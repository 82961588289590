import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Breakpoints from '../../../data/Breakpoints';

import SubMenu from '../SubMenu/SubMenu';

@observer
/**
 *
 */
class Footer extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onResize = this.onResize.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Only show on smaller screens
		if (window.innerWidth >= Breakpoints.TABLET) {
			return null;
		}

		const path = this.props.location.pathname;
		const show =
			path.indexOf('/bookkeeping') >= 0 ||
			path.indexOf('/invoices') >= 0 ||
			path.indexOf('/settings') >= 0 ||
			path.indexOf('/documents') >= 0;
		const className = show ? 'footer--show' : 'footer--hide';

		return (
			<footer className={`footer ${className}`}>
				<SubMenu />
			</footer>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.forceUpdate();
	}
}

Footer.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

Footer.defaultProps = {};

export default withRouter(Footer);
