import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

import objectToFormData from '../../utils/objectToFormData';

import Signals from '../../signals/Signals';
import AbstractCommand from '../AbstractCommand';
import {AutocompleteTypes} from '../../data/AutocompleteSearchObject';

import expenseUpdate from '../../requests/expenseUpdate';
import expenseFileDelete from '../../requests/expenseFileDelete';

/**
 *
 */
export default class UpdateExpenseCommand extends AbstractCommand {

	/**

	 */
	constructor(applicationStore, expenseModel, file = null) {
		super();

		this.applicationStore = applicationStore;
		this.expenseStore = this.applicationStore.expenseStore;
		this.autocompleteStore = this.applicationStore.autocompleteStore;

		this.expenseModel = expenseModel;
		this.file = file;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		const ignoreFields = [];

		// Create FormData
		const formData = objectToFormData(this.expenseModel, true, ignoreFields);

		// Add file
		if (this.file) {
			formData.append('file', this.file);
		}

		// Empty file
		if(!this.expenseModel.fileName) {
			formData.append('fileName', null);
		}

		const promises = [];

		// Update Income
		promises.push(
			expenseUpdate(this.expenseModel.id, formData).then((response) => {

				//
				if(!this.expenseModel.fileName) {
					response.data.fileName = null;
				}

				//
				const model = this.expenseStore.updateExpense(response.data);
				this.autocompleteStore.update(model, AutocompleteTypes.TYPE_EXPENSES);
				Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="expense.edited.message" values={{expenseId:model.expenseNr}}/>));

			})
		);

		// Remove file
		if(!this.expenseModel.fileName) {
			promises.push(expenseFileDelete(this.expenseModel.id));
		}


		//
		Promise.all(promises).then((response) => {
			if (success) {
				success(response);
			}
		}).catch((err) => {
			if (error) {
				error(err);
			}
		});
	}
}
