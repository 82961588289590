import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Routes } from '../../../data/Routes';

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function MainMenuUser(props) {
	const { menuItemWrapperClasses, menuItemClasses, params } = props;
	return (
		<ul className={`main-menu__item-wrapper ${menuItemWrapperClasses}`}>
			<MenuItem
				className={menuItemClasses}
				route={Routes.USER_DOCUMENTS.getPath(params)}
				routeToMatch={Routes.USER_DOCUMENTS}>
				<FormattedMessage id="mainmenu.user.documents" />
			</MenuItem>
			<MenuItem
				className={menuItemClasses}
				route={Routes.USER_SETTINGS_INFO.getPath(params)}
				routeToMatch={Routes.USER_SETTINGS}>
				<FormattedMessage id="mainmenu.user.settings" />
			</MenuItem>
		</ul>
	);
}

MainMenuUser.propTypes = {
	menuItemWrapperClasses: PropTypes.string,
	menuItemClasses: PropTypes.string,
	params: PropTypes.object
};
