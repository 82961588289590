/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import { FormattedDateParts, FormattedNumberParts, injectIntl } from 'react-intl';
import { Observer } from 'mobx-react';
import { FcCheckmark } from 'react-icons/fc';
// eslint-disable-next-line no-unused-vars
import classNames from 'classnames';
// eslint-disable-next-line no-unused-vars
import BankRecord from '../../../../stores/models/BankRecord';
import { defaultDateFormatOptions } from '../../../../data/Settings';
import Tag from '../../../../components/ui/Tag/Tag';
import BankRecordMatchType from '../../../../data/BankRecordMatchType';
import TransactionActions from './TransactionActions';
import Signals from '../../../../signals/Signals';
import TransactionMatch from './TransactionMatch/TransactionMatch';
import useBankCategoryStore from '../../../../utils/useBankCategoryStore';
import { amountWithSignFromParts } from '../../../../utils/intlUtils';

/**
 *
 * @param {BankRecord} bankRecord
 * @returns
 */

function TransactionItem({ bankRecord }) {
	const bankCategoryStore = useBankCategoryStore();

	const onActionClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (bankRecord.periodSubmitted) {
			return;
		}
		Signals.ShowToolTip.dispatch(
			<TransactionActions bankRecord={bankRecord} />,
			e.currentTarget,
			-16
		);
	};

	const onTransactionClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (bankRecord.periodSubmitted) {
			return;
		}
		Signals.ShowOverlay.dispatch(<TransactionMatch bankRecord={bankRecord} />);
		Signals.HideToolTip.dispatch();
	};

	return (
		<Observer>
			{() => {
				return (
					<div
						className={classNames('transaction-row', {
							matched: bankRecord.isProcessed,
							disabled: bankRecord.periodSubmitted
						})}
						onClick={onTransactionClick}>
						<div className="transaction-item transaction-date">
							<FormattedDateParts value={bankRecord.executionDate} {...defaultDateFormatOptions}>
								{(parts) => (
									<span>
										{parts[0].value}-{parts[2].value}
									</span>
								)}
							</FormattedDateParts>
						</div>
						<div className="transaction-item transaction-name">{bankRecord.counterpartName}</div>
						<div className="transaction-item transaction-description">
							{bankRecord.descriptionParsed}
						</div>
						<div className="transaction-item transaction-category-label">
							{bankCategoryStore &&
								bankCategoryStore.fetched &&
								bankCategoryStore.getLabelById(bankRecord.categoryId)}
						</div>
						<div className="transaction-item transaction-status">
							{bankRecord.matchStatus === BankRecordMatchType.MATCHED && (
								<Tag labelId={`transactions.${bankRecord.matchedResource.status}`} type="matched" />
							)}
						</div>
						<div className="transaction-item transaction-processed">
							{bankRecord.isProcessed && <FcCheckmark className="w-6 h-6" />}
						</div>
						<div className="transaction-item transaction-amount">
							<FormattedNumberParts
								value={bankRecord.amount}
								minimumFractionDigits={2}
								maximumFractionDigits={2}>
								{(parts) => <span>{amountWithSignFromParts(bankRecord.amount, parts)}</span>}
							</FormattedNumberParts>
						</div>
						<div
							onClick={onActionClick}
							className={classNames(
								'transaction-item transaction-actions transaction-actions-icons !mt-6',
								{
									disabled: bankRecord.periodSubmitted
								}
							)}
						/>
					</div>
				);
			}}
		</Observer>
	);
}

export default injectIntl(TransactionItem);

TransactionItem.propTypes = {
	bankRecord: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired
};
