import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import NotificationNumber from '../NotificationNumber/NotificationNumber';

/**
 *
 */
export default class DashboardPanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.content = null;
	}

	/**
	 *
	 */
	componentDidMount() {}

	/**
	 *
	 */
	render() {
		let link = null;
		const { number, title, notifications, className, linkLabel, children } = this.props;

		if (this.props.link) {
			link = (
				<Link className="arrow" to={this.props.link}>
					{linkLabel}{' '}
				</Link>
			);
			if (this.props.isExternal) {
				link = (
					<a className="arrow" href={this.props.link} target="_blank" rel="noreferrer">
						{linkLabel}{' '}
					</a>
				);
			}
		}

		const scrollingEnabled = this.content && this.content.scrollHeight > this.content.clientHeight;

		return (
			<div
				className={`dashboard__panel grid col--6 ${className} ${classNames({
					'dashboard__panel--content-shadow': scrollingEnabled
				})}`}>
				<div className="panel grid col--12 box-shadow border border--dark">
					{this.props.title ? (
						<div className="dashboard__panel-header grid grid--spread col--12">
							<div className="grid">
								<h2 className="font--title dashboard__panel-title">
									{number ? (
										<span className="dashboard__panel-header__number">{number}</span>
									) : null}
									{title}
								</h2>
								<NotificationNumber
									className="dashboard__panel__notification"
									count={notifications}
								/>
							</div>
							{link}
						</div>
					) : null}

					<div
						ref={(ref) => {
							this.content = ref;
						}}
						className="dashboard__panel-content col--12">
						{children}
					</div>
				</div>
			</div>
		);
	}
}

DashboardPanel.propTypes = {
	className: PropTypes.string,
	number: PropTypes.string,
	title: PropTypes.string,
	link: PropTypes.string,
	linkLabel: PropTypes.string,
	isExternal: PropTypes.bool,
	notifications: PropTypes.number,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

DashboardPanel.defaultProps = {
	className: '',
	notifications: 0
};
