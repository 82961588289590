import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { validateMimeType, validateSize } from '../../../utils/fileUtils';
import Signals from '../../../signals/Signals';
import ModalAlert from '../Modal/ModalAlert';

/**
 *
 */
class FileUploadArea extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.fileUpload = null;

		this.onChange = this.onChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);

		this.state = { dragOver: false };
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { className, multiple, hideContentArea, title, description } = this.props;
		const classes = classNames({
			'file-upload-area': true,
			'file-upload-area--drag-over': this.state.dragOver,
			'file-upload-area--hide-content-area': hideContentArea
		});

		return (
			<section
				className={`${classes} ${className}`}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}>
				{hideContentArea ? null : (
					<div className="file-upload-area__content">
						<input
							type="file"
							ref={(fileUpload) => (this.fileUpload = fileUpload)}
							name="file-upload-area__files"
							multiple={multiple}
							onChange={this.onChange}
							accept={this.props.accept}
						/>
						<h2>
							<FormattedMessage
								id={
									title ||
									(multiple ? 'fileuploadarea.title.multiple' : 'fileuploadarea.title.single')
								}
							/>
						</h2>
						<div className="file-upload-area__description">
							<FormattedMessage
								id={
									description ||
									(multiple
										? 'fileuploadarea.description.multiple'
										: 'fileuploadarea.description.single')
								}
							/>
						</div>
						<button
							type="button"
							className="icon icon--left icon--big-plus icon--color"
							onClick={() => this.fileUpload.click()}>
							<FormattedMessage
								id={multiple ? 'fileuploadarea.button.multiple' : 'fileuploadarea.button.single'}
							/>
						</button>
					</div>
				)}
			</section>
		);
	}

	/**
	 *
	 * @param event
	 */
	onChange(event) {
		const files = event.target.files;
		this.triggerChange(files);
	}

	/**
	 *
	 * @param event
	 */
	onDragOver() {
		this.setState({ dragOver: true });
	}

	/**
	 *
	 * @param event
	 */
	onDragLeave() {
		this.setState({ dragOver: false });
	}

	/**
	 *
	 * @param event
	 */
	onDrop(event) {
		const files = event.dataTransfer.files;
		this.setState({ dragOver: false });
		this.triggerChange(files);
	}

	/**
	 *
	 * @param files
	 * @return {boolean}
	 */
	triggerChange(files) {
		const { multiple, onChange, useValidation } = this.props;

		// Validate individual files
		if (useValidation) {
			for (let i = 0; i < files.length; i++) {
				if (!this.validate(files[i])) {
					return false;
				}
			}
		}

		// Multiple files or single
		if (multiple) {
			onChange(files);
		} else {
			onChange(files[0]);
		}
	}

	/**
	 *
	 * @param file
	 * @return {boolean}
	 */
	validate(file) {
		const { accept, maxSize, intl } = this.props;
		const values = {
			size: `${Math.floor(maxSize / (1024 * 1024))}MB`,
			types: accept.split(',').join(', ')
		};
		const validMimeType = file && validateMimeType(file.type, accept);
		if (!validMimeType) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={intl.formatMessage({ id: 'alert.fileupload.invalid.type.title' })}
					body={intl.formatMessage({ id: 'alert.fileupload.invalid.type.description' }, values)}
				/>
			);
			return false;
		}

		const validSize = validateSize(file.size, maxSize);
		if (!validSize) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={intl.formatMessage({ id: 'alert.fileupload.invalid.size.title' })}
					body={intl.formatMessage({ id: 'alert.fileupload.invalid.size.description' }, values)}
				/>
			);
			return false;
		}

		return true;
	}
}

FileUploadArea.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	accept: PropTypes.string,
	maxSize: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	multiple: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	useValidation: PropTypes.bool,
	hideContentArea: PropTypes.bool
};

FileUploadArea.defaultProps = {
	className: '',
	accept: '.gif,.png,.jpg,.jpeg,.pdf,.txt,.csv',
	maxSize: 1024 * 1024 * 20, // 20Mb see FileUtil.java
	multiple: true,
	title: null,
	description: null,
	useValidation: false,
	hideContentArea: false
};

export default injectIntl(FileUploadArea);
