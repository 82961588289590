/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
	injectIntl,
	FormattedMessage,
	FormattedHTMLMessage,
	FormattedDate,
	IntlProvider
} from 'react-intl';
import classNames from 'classnames';

import Page from '../../Page';
import { getTranslations } from '../../../utils/Translations';

import { Routes } from '../../../data/Routes';
import SendTypes from '../../../data/SendTypes';

import Signals from '../../../signals/Signals';
import BTW from '../../../data/BTW';
import { createDynamicValues, addDynamicValues } from '../../../data/OfferDynamicValues';
import OfferStatus from '../../../data/OfferStatus';
import Locale from '../../../data/Locale';

import CompanyAddress from '../../../components/ui/CompanyAddress/CompanyAddress';
import ClientAddress from '../../../components/ui/ClientAddress/ClientAddress';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import Checkbox from '../../../components/ui/Checkbox/Checkbox';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import SignedOffer from '../../../components/ui/SignedOffer/SignedOffer';

import OfferSummaryRow from './components/OfferSummaryRow';
import OfferHistory from './components/OfferHistory';
import OfferAttachments from './components/OfferAttachments';
import OfferRow from './components/OfferRow';

import { buildCompanyOfferPDFURL } from '../../../requests/offers/companyOfferPDF';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';
import { ApplicationContext } from '../../../ApplicationContext';

import ArchiveOfferCommand from '../../../commands/offers/ArchiveOfferCommand';
import DeleteOfferCommand from '../../../commands/offers/DeleteOfferCommand';
import UnarchiveOfferCommand from '../../../commands/offers/UnarchiveOfferCommand';
import SendOfferCommand from '../../../commands/offers/SendOfferCommand';
import GetOfferCommand from '../../../commands/offers/GetOfferCommand';
import OfferModel from '../../../stores/models/OfferModel';
import ToggleButton from '../../../components/ui/ToggleButton/ToggleButton';
import ToggleButtonOption from '../../../components/ui/ToggleButton/ToggleButtonOption';
import CreateInvoiceFromOfferCommand from '../../../commands/offers/CreateInvoiceFromOfferCommand';
import UpdateOfferStatusCommand from '../../../commands/offers/UpdateOfferStatusCommand';
import CompanyBrandModel from '../../../stores/models/CompanyBrandModel';
import CompanyBrandInput from '../GenericSettings/modals/CompanyBrandInput';
import SaveOfferCommand from '../../../commands/offers/SaveOfferCommand';
import ClientInput from '../Clients/components/modals/ClientInput';
import CompanyCustomer from '../../../stores/models/CompanyCustomer';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
class Offer extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		console.log('Offer.constructor');

		this.locale = null;
		this.translations = null;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.sendType = -1;
		this.state = {
			settingsExpanded: false
		};

		this.onCancel = this.onCancel.bind(this);
		this.onArchive = this.onArchive.bind(this);
		this.onUnarchive = this.onUnarchive.bind(this);
		this.onSendEmail = this.onSendEmail.bind(this);
		this.onMarkAsSend = this.onMarkAsSend.bind(this);
		this.onSendReminder = this.onSendReminder.bind(this);
		this.onShowPDF = this.onShowPDF.bind(this);
		this.onShowPDF = this.onShowPDF.bind(this);
		this.onViewPublicOffer = this.onViewPublicOffer.bind(this);
		this.onCreateInvoiceFromOffer = this.onCreateInvoiceFromOffer.bind(this);
		this.onChangeOfferStatus = this.onChangeOfferStatus.bind(this);
		this.onViewOption = this.onViewOption.bind(this);
		this.onAction = this.onAction.bind(this);

		this.onToggleSettings = this.onToggleSettings.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onClientUpdate = this.onClientUpdate.bind(this);
		this.onError = this.onError.bind(this);
		this.onSendSuccess = this.onSendSuccess.bind(this);

		this.onEditClient = this.onEditClient.bind(this);
		this.onClientUpdate = this.onClientUpdate.bind(this);

		this.onEditSender = this.onEditSender.bind(this);
		this.onSenderUpdate = this.onSenderUpdate.bind(this);

		this.onSaveSuccess = this.onSaveSuccess.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.resetFieldHeaderSettings();
		this.getOfferModel();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.resetFieldHeaderSettings();
		this.getOfferModel();
	}

	/**
	 *
	 */
	render() {
		console.log('Offer.render');

		// Check if user logged in
		const { applicationStore } = this.context;
		if (!applicationStore.isLoggedIn || !this.offerModel) {
			return null;
		}
		//
		const company = applicationStore.getSelectedCompany();
		const companyBrand = this.offerModel.companyBrand
			? this.offerModel.companyBrand
			: CompanyBrandModel.fromCompany(company);
		const companyCustomer = this.offerModel.companyCustomer
			? this.offerModel.companyCustomer
			: applicationStore.companyCustomerStore.find(this.offerModel.companyCustomerId);

		// Log the company companyBrand companyCustomer
		console.log('Offer.render.company', company);
		console.log('Offer.render companyBrand', companyBrand);
		console.log('Offer.render companyCustomer', companyCustomer);

		// Can't render
		if (!company || !companyCustomer) {
			// Log the error
			console.log('Offer.render.error.company', company);
			console.log('Offer.render.error.companyCustomer', companyCustomer);
			return null;
		}

		// Build logo URL
		const logoPreviewURL = companyBrand.logoAssetUuid
			? buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid)
			: null;
		const deviatingUnits = this.offerModel.hasDeviatingUnits();

		if (!deviatingUnits) {
			// eslint-disable-next-line array-callback-return
			this.fieldHeaderSettings.map((fieldData) => {
				if (fieldData.id === 'units') {
					fieldData.hide = true;
				}

				if (fieldData.id === 'unitprice') {
					fieldData.labelId = fieldData.labelIdSingle;
				}
			});
		}

		//
		const detailsWrapperClasses = classNames({
			'offer__details-wrapper': true,
			'offer__details-wrapper--no-units': !deviatingUnits
		});

		// Create table headers
		const values = { totalUnits: '' };
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			return (
				<td
					key={`invh-${index}`}
					className={`offer__header--${`${fieldData.id}`.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}>
					{fieldData.hide ? null : <FormattedMessage id={fieldData.labelId} values={values} />}
				</td>
			);
		});

		// Create offers concept rows
		const rows = this.offerModel.offerRows.map((offerRowModel, index) => {
			return (
				<OfferRow
					key={`i-${index}-${offerRowModel._uid}`}
					hideUnits={!deviatingUnits}
					offerModel={this.offerModel}
					offerRowModel={offerRowModel}
				/>
			);
		});

		return (
			<Page pageName="offer grid">
				<div className="offer__panel panel grid col--12 box-shadow border border--dark">
					{/* Option buttons */}
					{this._getOptions()}

					{/* Expanding/Collapsing settings */}
					<div
						className={`offer__settings col--12 grid ${classNames({
							'offer__settings--expanded': this.state.settingsExpanded
						})}`}>
						{this._getSettings()}
					</div>

					{/* Offer status */}
					<div
						className={`offer__status ${
							this.offerModel.status
								? `offer__status--${`${this.offerModel.status}`.toLowerCase()}`
								: ''
						}`}>
						<label>
							<FormattedMessage
								id={`offer.status.${
									this.offerModel.status ? `${this.offerModel.status}`.toLowerCase() : ''
								}`}
								values={{
									statusDateChanged: this.props.intl.formatDate(this.offerModel.statusDateChanged)
								}}
							/>
						</label>
					</div>

					{/* Offer layout + localised translation */}
					<IntlProvider locale={this.locale} messages={this.translations}>
						<div className="offer__layout-wrapper padding grid">
							<div className="offer__information grid col--12">
								<div className="offer__logo col--6">
									{logoPreviewURL ? (
										<img className="offer__logo-img" src={logoPreviewURL} alt="logo" />
									) : null}
								</div>

								<div className="col--6 grid">
									<label className="text--right offer__information-label col--5">
										<FormattedMessage id="offer.label.sender" />
									</label>
									<div className="col--7">
										<CompanyAddress
											company={companyBrand.toCompany(company)}
											useDate={this.offerModel.date}
											onEdit={this.offerModel.canEdit() ? this.onEditSender : null}
										/>
									</div>
								</div>

								<div className="offer__information-detail-labels grid col--6">
									<label className="offer__information-label col--4">
										<FormattedMessage id="offer.info.offer" />
									</label>

									<div className="offer__information-value offer__information-value--bold col--8">
										{this.offerModel.subject}
									</div>

									<label className="offer__information-label col--4">
										<FormattedMessage id="offer.info.offernr" />
									</label>

									<div className="offer__information-value col--8">{this.offerModel.offerNr}</div>

									<label
										className={`offer__information-label col--4 ${classNames({
											hidden: !this.offerModel.projectcode
										})}`}>
										<FormattedMessage id="offer.info.projectcode" />
									</label>

									<div
										className={`offer__information-value col--8 ${classNames({
											hidden: !this.offerModel.projectcode
										})}`}>
										{this.offerModel.projectcode}
									</div>

									<label className="offer__information-label col--4">
										<FormattedMessage id="offer.info.date" />
									</label>

									<div className="offer__information-value col--8">
										<FormattedDate
											value={this.offerModel.date}
											day="numeric"
											month="long"
											year="numeric"
										/>
									</div>

									{this.offerModel.hasExpirationDate() ? (
										<label className="offer__information-label col--4">
											<FormattedMessage id="offer.info.expirationdate" />
										</label>
									) : null}

									{this.offerModel.hasExpirationDate() ? (
										<div className="offer__information-value col--8">
											<FormattedDate
												value={this.offerModel.getExpirationDateFromDays()}
												day="numeric"
												month="long"
												year="numeric"
											/>
										</div>
									) : null}
								</div>

								<div className="col--6 grid">
									<label className="text--right offer__information-label col--5">
										<FormattedMessage id="offer.label.client" />
									</label>
									<div className="col--7">
										<ClientAddress
											companyCustomer={this.offerModel.companyCustomer}
											onUpdate={this.onClientUpdate}
											onEdit={this.offerModel.canEdit() ? this.onEditClient : null}
											language={this.locale}
										/>
									</div>
								</div>
							</div>

							<div className={detailsWrapperClasses}>
								<table className="table table--wide offer__details col--12">
									<thead className="table__headers">
										<tr>{headers}</tr>
									</thead>
									<tbody className="table__body">
										{rows}

										{/* Empty row */}
										<tr className="offer-summary-row">
											<td colSpan={5}>&nbsp;</td>
										</tr>

										{this._getSubtotal()}
										{this._getVATLow()}
										{this._getVATHigh()}
										{this._getVATReversed()}
										{this._getVATExempt()}
										{this._getTotal()}
									</tbody>
								</table>
							</div>

							{/* Optional notes */}
							{this.offerModel.hasNotes() ? (
								<div className="offer__notes col--12 grid padding border--top padding--bottom">
									<label>
										<FormattedMessage id="offer.label.notes" />
									</label>
									{/* eslint-disable-next-line react/no-danger */}
									<div dangerouslySetInnerHTML={{ __html: this.offerModel.getNotesAsHTML() }} />
								</div>
							) : null}
						</div>
					</IntlProvider>
				</div>

				{/* Sign data	*/}
				{this.offerModel.isSigned() ? (
					<SignedOffer offerModel={this.offerModel} company={company} />
				) : null}

				{/* Attachments */}
				<OfferAttachments offerModel={this.offerModel} company={company} />

				{/* Offer history */}
				<OfferHistory offerModel={this.offerModel} />
			</Page>
		);
	}

	/**
	 *
	 */
	onToggleSettings() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ settingsExpanded: !this.state.settingsExpanded });
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const company = this.context.applicationStore.getSelectedCompany();
		switch (e.target.name) {
			case 'sendToSelf':
				this.setState({ bccRecipientEmail: e.target.checked ? company.email : '' });
				break;
			case 'remindCompanyUserInDays':
				this.setState({ remindCompanyUserInDays: e.target.checked ? 0 : null });
				break;
			default:
				// eslint-disable-next-line no-case-declarations
				const nextState = {};
				nextState[e.target.name] = e.target.value;
				this.setState(nextState);
		}
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onClientUpdate(companyCustomer) {
		this.setState({ recipientEmail: companyCustomer.contactPersonEmail });
		this.forceUpdate();
	}

	/**
	 *
	 */
	onCancel() {
		this.setState({ settingsExpanded: false });
		this.forceUpdate();
	}

	/**
	 *
	 * @param sendType
	 */
	onSend(sendType) {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.setState({ submitting: true, errors: null });

			const command = new SendOfferCommand(
				this.context.applicationStore,
				this.offerModel,
				company,
				this.state,
				sendType
			);

			command.execute(this.onSendSuccess, (error) => {
				this.onError(error);
			});
		}
	}

	/**
	 *
	 */
	onSendSuccess() {
		this.setState({ settingsExpanded: false, submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	onViewOption(option) {
		switch (option) {
			case 'web':
				this.onViewPublicOffer();
				break;
			case 'pdf':
				this.onShowPDF();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param option
	 */
	onAction(option) {
		switch (option) {
			case 'edit':
				this.edit();
				break;
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'archive':
				this.onArchive();
				break;
			case 'unarchive':
				this.onUnarchive();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 */
	onViewPublicOffer() {
		const url = Routes.COMPANY_OFFERS_CLIENT.getPath({ uuid: this.offerModel.publicAccessId });
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	onShowPDF() {
		const company = this.context.applicationStore.getSelectedCompany();
		const companyId = company.id;
		const offerId = this.offerModel.id;

		const url = buildCompanyOfferPDFURL(companyId, offerId);
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	onCreateInvoiceFromOffer() {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new CreateInvoiceFromOfferCommand(this.offerModel, company);
		command.execute();
	}

	/**
	 *
	 * @param value
	 */
	onChangeOfferStatus(value) {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new UpdateOfferStatusCommand(this.offerModel, company, value, this.props.intl);
		command.execute(() => {
			this.setState({ settingsExpanded: false });
			this.forceUpdate();
		});
	}

	/**
	 *
	 */
	onArchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new ArchiveOfferCommand(this.offerModel, company);
			command.execute(() => {
				// Go back to overview
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_OFFERS_ALL.getPath({
						id: company.id,
						offerId: this.offerModel.id
					})
				);
			});
		}
	}

	/**
	 *
	 */
	onUnarchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new UnarchiveOfferCommand(this.offerModel, company);
			command.execute(() => {
				// Go back to overview
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_OFFERS_ALL.getPath({
						id: company.id,
						offerId: this.offerModel.id
					})
				);
			});
		}
	}

	/**
	 *
	 */
	onSendEmail() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'offer.send.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'offer.send.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'offer.send.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'offer.send.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_SEND_EMAIL)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	onMarkAsSend() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'offer.markassend.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'offer.markassend.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'offer.markassend.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'offer.markassend.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_MARKED_AS_SEND)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	onSendReminder() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'offer.sendreminder.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'offer.sendreminder.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'offer.sendreminder.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'offer.sendreminder.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_SEND_REMINDER)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	resetFieldHeaderSettings() {
		this.fieldHeaderSettings = [
			{ id: 'description', labelId: 'offer.header.description' },
			{ id: 'units', labelId: 'offer.header.units', right: true },
			{
				id: 'unitprice',
				labelId: 'offer.header.unitprice',
				labelIdSingle: 'offer.header.price',
				right: true
			},
			{ id: 'btw', labelId: 'offer.header.btw', right: true },
			{ id: 'amount', labelId: 'offer.header.amount', right: true }
		];
	}

	/**
	 *
	 */
	remove() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const title = this.props.intl.formatMessage(
			{ id: 'offer.remove.alert.title' },
			{
				description: this.offerModel.description,
				customer: this.offerModel.companyCustomer.companyName
			}
		);

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				// eslint-disable-next-line react/jsx-no-bind
				onConfirm={this._doRemove.bind(this)}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	duplicate() {
		const company = this.context.applicationStore.getSelectedCompany();
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_OFFERS_DUPLICATE.getPath({
				id: company.id,
				offerId: this.offerModel.id
			})
		);
	}

	/**
	 *
	 */
	edit() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_OFFERS_EDIT.getPath({
					id: company.id,
					offerId: this.offerModel.id
				})
			);
		}
	}

	/**
	 *
	 * @returns {boolean}
	 */
	validate() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// BTW check, ignore this check always for VRIJGESTELD
			if (!company.ignoreBTWNumber() && !this.offerModel.containsVATType(BTW.VRIJGESTELD, true)) {
				const useDate = this.offerModel.date;
				const useVatId = useDate && company.useBTWId(useDate);

				let title = '';
				let body = '';

				if (useVatId && !company.hasVATId()) {
					title = this.props.intl.formatMessage({ id: 'offer.novatid.alert.title' });
					body = this.props.intl.formatMessage({ id: 'offer.novatid.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
				if (!company.hasVATNumber()) {
					title = this.props.intl.formatMessage({ id: 'offer.novat.alert.title' });
					body = this.props.intl.formatMessage({ id: 'offer.novat.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
			}

			// BTW check, ignore this check always for VRIJGESTELD
			if (
				!company.ignoreBTWNumber() &&
				!this.offerModel.containsVATType(BTW.VRIJGESTELD, true) &&
				(!company.vatNumber || company.vatNumber.trim().length <= 2)
			) {
				const title = this.props.intl.formatMessage({ id: 'offer.novat.alert.title' });
				const body = this.props.intl.formatMessage({ id: 'offer.novat.alert.body' });

				Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);

				return false;
			}

			// KVK check
			// if (!company.kvkNumber || company.kvkNumber.trim().length <= 0) {
			// 	const title = this.props.intl.formatMessage({id: 'offer.nokvk.alert.title'});
			// 	const body = this.props.intl.formatMessage({id: 'offer.nokvk.alert.body'});
			//
			// 	Signals.ShowModal.dispatch(<ModalAlert title={title} body={body}/>);
			//
			// 	return false;
			// }
		}

		return true;
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeleteOfferCommand(
				this.context.applicationStore.offersStore,
				this.offerModel,
				company
			);
			command.execute(() => {
				Signals.RequestRoute.dispatch(Routes.COMPANY_OFFERS_ALL.getPath({ id: company.id }));
			});
		}
	}

	/**
	 * Returns the right option button depending on the status of this
	 *
	 * @returns {*}
	 * @private
	 */
	_getOptions() {
		return (
			<div className="offer__options-top padding-small border--bottom border--dark grid grid--spread col--12">
				{this._getOptionsLeft()}
				{this._getOptionsRight()}
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsLeft() {
		return (
			<div className="offer__options-left grid">
				{this.offerModel.canChangeStatus() ? (
					<ToggleButton
						label={this.props.intl.formatMessage({ id: 'offer.change.status' })}
						className="toggle-button--primary btn-margin-right"
						buttonClassName="button--primary"
						options={this._generateStateOptions()}
						onChange={this.onChangeOfferStatus}
					/>
				) : null}

				<ToggleButton
					label={this.props.intl.formatMessage({ id: 'label.actions' })}
					chevronColor="black"
					options={this._generateActionOptions()}
					onChange={this.onAction}
				/>
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsRight() {
		const toggleButtonClasses = classNames({
			'offer__btn-toggle-settings btn-margin-left button icon icon--right icon--chevron-down icon--color': true,
			'button--primary': !this.offerModel.canChangeStatus()
		});

		return (
			<div className="offer__options-right grid grid--right">
				<ToggleButton
					label={this.props.intl.formatMessage({ id: 'offer.view' })}
					chevronColor="black"
					options={this._generateViewOptions()}
					className="btn-margin-left"
					onChange={this.onViewOption}
				/>

				{this.offerModel.canInvoice() ? (
					<button
						type="button"
						className="btn-margin-left button icon icon--left icon--file2-black"
						onClick={this.onCreateInvoiceFromOffer}>
						<FormattedMessage id="offer.create.invoice" />
					</button>
				) : null}

				{this.offerModel.canSend() ? (
					<button type="button" className={toggleButtonClasses} onClick={this.onToggleSettings}>
						<FormattedMessage id="offer.send.toggle" />
					</button>
				) : null}

				{this.offerModel.canSendReminder() ? (
					<button
						type="button"
						className="offer__btn-toggle-settings button--primary btn-margin-left button icon icon--right icon--chevron-down icon--color"
						onClick={this.onToggleSettings}>
						<FormattedMessage id="offer.remind.toggle" />
					</button>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getSettings() {
		switch (this.offerModel.status) {
			case OfferStatus.CREATED:
			case OfferStatus.SENT_MANUALLY:
			case OfferStatus.SENT:
				return this._getConceptSettings();
			case OfferStatus.SENT_REMINDER_KDB:
			case OfferStatus.LATE:
				return this._getReminderSettings();
			case OfferStatus.PAID:
				break;
			case OfferStatus.SIGNED:
			case OfferStatus.DECLINED:
				// TODO:
				break;
			case OfferStatus.DELETED:
				// should not happen
				break;
			default:
				break;
		}

		return null;
	}

	/**
	 * Settings shown when  has status CREATED
	 *
	 * @returns {*}
	 * @private
	 */
	_getConceptSettings() {
		const company = this.context.applicationStore.getSelectedCompany();

		return (
			<div className="offer__settings-wrapper col--12 grid">
				<FormGroup className="col--12" errors={this.state.errors}>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.replyto" />
						</label>
						<input
							className="col--12"
							name="senderEmail"
							type="email"
							value={this.state.senderEmail}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.recipient" />
						</label>
						<input name="recipientEmail" type="email" value={this.state.recipientEmail} disabled />
					</FormField>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.ccrecipient" />
						</label>
						<input
							name="ccRecipientEmail"
							type="email"
							value={this.state.ccRecipientEmail}
							onInput={this.onInputChange}
						/>
					</FormField>

					<FormField className="col--6 form-field__desktop-filler" />

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.email.subject" />
						</label>
						<input
							className="col--12"
							name="emailSubject"
							type="text"
							value={this.state.emailSubject}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--12">
						<label>
							<FormattedMessage id="offer.settings.email.message" />
						</label>
						<textarea
							className="col--12"
							name="emailMessage"
							value={this.state.emailMessage}
							onChange={this.onInputChange}
						/>
					</FormField>

					<FormField className="col--12">
						<Checkbox
							className="col--12"
							name="sendToSelf"
							label={
								<FormattedHTMLMessage
									id="offer.settings.sendcopy"
									values={{ email: company.email }}
								/>
							}
							checked={this.state.bccRecipientEmail !== ''}
							onChange={this.onInputChange}
						/>

						{this.offerModel.hasExpirationDate() ? (
							<Checkbox
								className="col--12"
								name="remindCompanyUserInDays"
								label={
									<FormattedHTMLMessage
										id="offer.settings.remind.user.label"
										values={{ email: this.state.senderEmail }}
									/>
								}
								checked={this.state.remindCompanyUserInDays !== null}
								onChange={this.onInputChange}
							/>
						) : null}
					</FormField>
				</FormGroup>

				<div className="offer__send-options grid col--12">
					<FormField className="col--12 grid--spread">
						<button type="button" className="button--tertiary" onClick={this.onCancel}>
							<FormattedMessage id="label.cancel" />
						</button>

						<div className="grid grid--right col--6">
							<button type="button" className="button btn-margin-right" onClick={this.onMarkAsSend}>
								<FormattedMessage id="offer.mark.as.send" />
							</button>
							<button
								type="button"
								className="offer__btn-send button button--primary"
								onClick={this.onSendEmail}>
								<FormattedMessage id="offer.send" />
							</button>
						</div>
					</FormField>
				</div>
			</div>
		);
	}

	/**
	 * Settings to display when reminder is option is active
	 *
	 * @returns {*}
	 * @private
	 */
	_getReminderSettings() {
		return (
			<div className="offer__settings-wrapper col--12 grid">
				<FormGroup className="col--12" errors={this.state.errors}>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.replyto" />
						</label>
						<input
							className="col--12"
							name="senderEmail"
							type="email"
							value={this.state.senderEmail}
							disabled
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.recipient" />
						</label>
						<input
							className="col--12"
							name="recipientEmail"
							type="email"
							value={this.state.recipientEmail}
							disabled
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="offer.settings.email.subject" />
						</label>
						<input
							className="col--12"
							type="text"
							name="reminderSubject"
							value={this.state.reminderSubject}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--12 ">
						<label>
							<FormattedMessage id="offer.settings.email.message" />
						</label>
						<textarea
							className="col--12"
							name="reminderMessage"
							value={this.state.reminderMessage}
							onChange={this.onInputChange}
						/>
					</FormField>
				</FormGroup>

				<div className="offer__send-options grid col--12">
					<FormField className="col--12 grid--spread">
						<button type="button" className="button--tertiary" onClick={this.onCancel}>
							<FormattedMessage id="label.cancel" />
						</button>

						<button
							type="button"
							className="offer__btn-send button button--primary"
							onClick={this.onSendReminder}>
							<FormattedMessage id="offer.remind" />
						</button>
					</FormField>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onEditClient() {
		const companyCustomer = this.offerModel.companyCustomer;
		Signals.ShowOverlay.dispatch(
			<ClientInput
				key={uniqueKey('ci-')}
				companyCustomer={companyCustomer}
				updateLocalOnly
				onUpdate={this.onClientUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyCustomer
	 */
	// eslint-disable-next-line no-dupe-class-members
	onClientUpdate(companyCustomer) {
		this.setState({ recipientEmail: companyCustomer.contactPersonEmail });

		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		this.offerModel.companyCustomer = new CompanyCustomer(companyCustomer);
		this.forceUpdate();

		const command = new SaveOfferCommand(
			applicationStore,
			this.offerModel,
			company,
			this.offerModel.toJSON(),
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 */
	onEditSender() {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const companyBrand = this.offerModel.companyBrand
			? this.offerModel.companyBrand
			: CompanyBrandModel.fromCompany(company);
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput
				key={uniqueKey('cb-')}
				companyBrand={companyBrand}
				updateLocalOnly
				onUpdate={this.onSenderUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyBrand
	 */
	onSenderUpdate(companyBrand) {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const newCompanyBrand = new CompanyBrandModel(companyBrand);
		newCompanyBrand.update(companyBrand);
		this.offerModel.companyBrand = newCompanyBrand;

		this.forceUpdate();

		const command = new SaveOfferCommand(
			applicationStore,
			this.offerModel,
			company,
			this.offerModel.toJSON(),
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 * @param response
	 */
	onSaveSuccess(_response) {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="offer.saved.message"
				values={{ offerNr: this.offerModel.offerNr }}
			/>
		);

		// Update store
		const { applicationStore } = this.context;
		const storeOffer = applicationStore.offersStore.getOfferById(this.offerModel.id);
		if (storeOffer) {
			storeOffer.update(this.offerModel);
		}
	}

	/**
	 *
	 * @private
	 */
	onGetOfferModel() {
		const company = this.context.applicationStore.getSelectedCompany();
		const user = this.context.applicationStore.user;

		// Load localised translations
		this.locale = user.language;
		switch (this.offerModel.locale) {
			case Locale.en_US:
				this.locale = 'en';
				break;
			case Locale.nl_NL:
				this.locale = 'nl';
				break;
			default:
				this.locale = 'nl';
		}

		// Update OffersStore {
		this.context.applicationStore.offersStore.addOffer(this.offerModel);

		// Get translations for selected locale
		this.translations = getTranslations(this.locale);

		// Replace dynamic values
		const dynamicValues = createDynamicValues(user, company, this.offerModel, this.props.intl);
		this._applyDefaults(company, dynamicValues);

		// Update render
		this.forceUpdate();
	}

	/**
	 * Fetch OfferModel based on parameter Id
	 *
	 * @private
	 */
	getOfferModel() {
		if (
			this.context.applicationStore &&
			this.context.applicationStore.currentRouteParams &&
			this.context.applicationStore.currentRouteParams.offerId
		) {
			const company = this.context.applicationStore.getSelectedCompany();
			this.offerModel = new OfferModel();
			this.offerModel.id = parseInt(this.context.applicationStore.currentRouteParams.offerId, 10);
			const command = new GetOfferCommand(this.offerModel, company);
			command.execute(() => {
				this.onGetOfferModel();
			});
		}
	}

	/**
	 *
	 * @param e
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	changeStatus(_e) {
		console.log();
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getSubtotal() {
		return (
			<OfferSummaryRow
				label={<FormattedMessage id="offer.summary.subtotal" />}
				amount={this.offerModel.getSubtotal()}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATLow() {
		const label = (
			<FormattedMessage
				id="offer.summary.btw"
				values={{
					percentage: this.props.intl.formatMessage({
						id: BTW.LAAG.translationId(this.offerModel.date)
					})
				}}
			/>
		);

		return (
			<OfferSummaryRow
				hideWhenZero={!this.offerModel.containsVATType(BTW.LAAG)}
				label={label}
				amount={this.offerModel.getBTWTotal(BTW.LAAG.name)}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATHigh() {
		const label = (
			<FormattedMessage
				id="offer.summary.btw"
				values={{ percentage: this.props.intl.formatMessage({ id: BTW.HOOG.translationId() }) }}
			/>
		);

		return (
			<OfferSummaryRow
				hideWhenZero={!this.offerModel.containsVATType(BTW.HOOG)}
				label={label}
				amount={this.offerModel.getBTWTotal(BTW.HOOG.name)}
			/>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.offerModel.containsVATType(BTW.VERLEGD)) {
			return (
				<OfferSummaryRow label={<FormattedMessage id="offer.vat.reversed.summary" />} amount={0} />
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.offerModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<OfferSummaryRow label={<FormattedMessage id="offer.vat.exempt.summary" />} amount={null} />
			);
		}
		return null;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getTotal() {
		return (
			<OfferSummaryRow
				label={<FormattedMessage id="offer.summary.total" />}
				amount={this.offerModel.getTotal()}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_generateActionOptions() {
		const result = [];

		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.edit' }),
				'edit',
				'icon icon--left icon--edit-black',
				false,
				!this.offerModel.canEdit()
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.duplicate' }),
				'duplicate',
				'icon icon--left icon--file-black'
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.remove' }),
				'remove',
				'icon icon--left icon--delete-black',
				false,
				!this.offerModel.canRemove()
			)
		);

		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.archive' }),
				'archive',
				'icon icon--left icon--folder2-black',
				false,
				!this.offerModel.canArchive()
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.unarchive' }),
				'unarchive',
				'icon icon--left icon--folder2-black',
				false,
				!this.offerModel.isArchived()
			)
		);

		return result;
	}

	/**
	 *
	 * @private
	 */
	_generateStateOptions() {
		const result = [];

		const isSigned = this.offerModel.isSigned();
		const currentStatus = this.offerModel.status;
		const lastSentStatus = this.offerModel.getLastSentStatus();

		// SENT states, always hide when isSigned
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.sent' }),
				OfferStatus.SENT,
				null,
				OfferStatus.SENT === currentStatus,
				OfferStatus.SENT !== lastSentStatus || isSigned
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.sent' }),
				OfferStatus.SENT_MANUALLY,
				null,
				OfferStatus.SENT_MANUALLY === currentStatus,
				OfferStatus.SENT_MANUALLY !== lastSentStatus || isSigned
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.late' }),
				OfferStatus.LATE,
				'toggle-button-option--color-danger',
				OfferStatus.LATE === currentStatus,
				OfferStatus.LATE !== lastSentStatus || isSigned
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.reminder' }),
				OfferStatus.SENT_REMINDER,
				'toggle-button-option--color-danger',
				OfferStatus.SENT_REMINDER === currentStatus,
				OfferStatus.SENT_REMINDER !== lastSentStatus || isSigned
			)
		);

		// SIGNED/DECLINED states, shown when SENT
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.signed' }),
				OfferStatus.SIGNED,
				'toggle-button-option--color-success',
				OfferStatus.SIGNED === currentStatus
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.declined' }),
				OfferStatus.DECLINED,
				'toggle-button-option--color-warning',
				OfferStatus.DECLINED === currentStatus
			)
		);

		// Show INVOICED option when current status is SIGNED
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'offer.state.invoiced' }),
				OfferStatus.INVOICED,
				'toggle-button-option--color-dark',
				OfferStatus.INVOICED === currentStatus,
				currentStatus !== OfferStatus.SIGNED
			)
		);

		return result;
	}

	/**
	 *
	 * @private
	 */
	_generateViewOptions() {
		const result = [];

		if (this.offerModel.isPublic()) {
			result.push(new ToggleButtonOption('Online', 'web'));
		}
		result.push(new ToggleButtonOption('PDF', 'pdf'));

		return result;
	}

	/**
	 *
	 * @private
	 */
	_applyDefaults(company, dynamicValues) {
		// TODO: change to offerSettings
		this.state.recipientEmail = this.offerModel.companyCustomer
			? this.offerModel.companyCustomer.contactPersonEmail
			: '';
		this.state.senderEmail = company.offersSettings.senderEmail
			? company.offersSettings.senderEmail
			: company.email;

		// Pick prefilled messaged based on OfferModel.locale
		if (this.offerModel.locale === Locale.en_US) {
			this.state.emailSubject = addDynamicValues(
				company.offersSettings.defaultOfferEmailSubjectEN,
				dynamicValues,
				this.props.intl
			);
			this.state.emailMessage = addDynamicValues(
				company.offersSettings.defaultOfferEmailMessageEN,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderSubject = addDynamicValues(
				company.offersSettings.reminderSubjectEN,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderMessage = addDynamicValues(
				company.offersSettings.reminderMessageEN,
				dynamicValues,
				this.props.intl
			);
		} else {
			this.state.emailSubject = addDynamicValues(
				company.offersSettings.defaultOfferEmailSubject,
				dynamicValues,
				this.props.intl
			);
			this.state.emailMessage = addDynamicValues(
				company.offersSettings.defaultOfferEmailMessage,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderSubject = addDynamicValues(
				company.offersSettings.reminderSubject,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderMessage = addDynamicValues(
				company.offersSettings.reminderMessage,
				dynamicValues,
				this.props.intl
			);
		}

		this.state.remindCompanyUserInDays = company.offersSettings.remindCompanyUserInDays;
		this.state.bccRecipientEmail = company.offersSettings.bccRecipientEmail; // Send to self
	}
}

Offer.contextType = ApplicationContext;

Offer.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Offer);
