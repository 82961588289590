import { observable, action, computed } from 'mobx';
import { compareStrings } from '@/utils/compareUtils';
import Store from './Store';
import BankRecord from './models/BankRecord';
import Filter from './filters/Filter';
import FilterRuleHasOneOrMoreValues from './filters/FilterRuleHasOneOrMoreValues';
import BankRecordMatchedResourceStatusType from '../data/BankRecordMatchedResourseStatusType';
import { BankRecordProcessedStatusType } from '../data/BankRecordType';

/**
 *
 */
export default class BankRecordStore extends Store {
	filter = new Filter();

	statusFilterRule = new FilterRuleHasOneOrMoreValues('isProcessed');

	@observable isFetched = false;

	@observable search = '';

	@observable sortField = 'executionDate';

	/**
	 *
	 */
	constructor(bankCategoryStore) {
		super(BankRecord);
		this.bankCategoryStore = bankCategoryStore;
		this.sortDirection = -1;
		this.resetFilter();
		this.filter.addRule(this.statusFilterRule);
	}

	setIsFetched(isFetched) {
		this.isFetched = isFetched;
		console.log('BankRecordStore.setIsFetched', isFetched);
	}

	@action resetStore() {
		console.log('BankRecordStore.resetStore');
		this.isFetched = false;
		this.search = '';
		this.reset();
	}

	@action resetFilter() {
		this.statusFilterRule.value = [
			BankRecordProcessedStatusType.PROCESSED,
			BankRecordProcessedStatusType.NOT_PROCESSED
		];
	}

	@computed get filteredAndSortedTransactions() {
		const searchBy = this.search.toLowerCase();

		return this.filteredAndSorted.filter((transaction) => {
			const searchLookup = this._generateSearchLookupString(transaction);
			return searchLookup.includes(searchBy);
		});
	}

	@computed get automatchedCount() {
		return this.list.filter(
			(transaction) =>
				transaction?.matchedResource?.status === BankRecordMatchedResourceStatusType.AUTO_MACH
		).length;
	}

	@computed get processedCount() {
		return this.list.filter((transaction) => transaction.isProcessed).length;
	}

	@computed get toBeProcessedCount() {
		return this.list.filter(
			(transaction) => !transaction.isProcessed && !transaction.periodSubmitted
		).length;
	}

	_sort(a, b) {
		if (this.sortField === 'categoryId') {
			return (
				compareStrings(
					this.bankCategoryStore.getLabelById(a.categoryId),
					this.bankCategoryStore.getLabelById(b.categoryId)
				) * this.sortDirection
			);
		}
		return super._sort(a, b);
	}

	_generateSearchLookupString(transaction) {
		return (
			this._getLowerCaseOrEmpty(transaction.description) +
			this._getLowerCaseOrEmpty(transaction.counterpartName) +
			this._getLowerCaseOrEmpty(transaction.counterpartReference) +
			this._getLowerCaseOrEmpty(transaction.amount) +
			this._getLowerCaseOrEmpty(this.bankCategoryStore.getLabelById(transaction.categoryId))
		);
	}

	_getLowerCaseOrEmpty(s) {
		return s ? s.toString().toLowerCase() : '';
	}
}
