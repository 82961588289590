import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';
import WalkthroughStepList from './WalkthroughStepList';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
class WalkthroughProgressTab extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			expanded: false
		};

		this.onWalkthroughPaused = this.onWalkthroughPaused.bind(this);
		this.onWalkthroughUpdate = this.onWalkthroughUpdate.bind(this);
		this.onToggleStepList = this.onToggleStepList.bind(this);
		this.onStart = this.onStart.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.WalkthroughUpdate.add(this.onWalkthroughUpdate);
		Signals.WalkthroughPaused.add(this.onWalkthroughPaused);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.WalkthroughUpdate.remove(this.onWalkthroughUpdate);
		Signals.WalkthroughPaused.remove(this.onWalkthroughPaused);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { expanded } = this.state;
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		if (applicationStore && applicationStore.isLoggedIn && !walkthroughStore.stopped) {
			const classes = classNames({
				'walkthrough-progress-tab': true,
				'walkthrough-progress-tab--expanded': expanded,
				'walkthrough-progress-tab--showing': walkthroughStore.current >= 0
			});

			const toggleIcons = classNames({
				'icon icon--left icon--chevron-up icon--white': !expanded,
				'icon icon--left icon--chevron-down icon--white': expanded
			});

			return (
				<div className={classes}>
					{walkthroughStore.playing && walkthroughStore.current > 0 ? (
						<div
							className="walkthrough-progress-tab__toggle cursor-pointer"
							onClick={this.onToggleStepList}>
							<FormattedMessage id="walkthrough.allsteps" />
							<span className={toggleIcons} />
						</div>
					) : (
						<div
							className="walkthrough-progress-tab__toggle walkthrough-progress-tab__toggle--play cursor-pointer"
							onClick={this.onStart}>
							<span className="walkthrough-progress-tab__toggle__play-icon" />
							<FormattedMessage id="walkthrough.tourbutton" />
						</div>
					)}
					<div className="walkthrough-progress-tab__list">
						<WalkthroughStepList walkthroughStore={walkthroughStore} />
					</div>
				</div>
			);
		}
		return null;
	}

	/**
	 *
	 */
	onStart() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;
		walkthroughStore.setStep(1);
	}

	/**
	 *
	 */
	onToggleStepList() {
		const { expanded } = this.state;
		this.setState({ expanded: !expanded });
	}

	/**
	 *
	 */
	onWalkthroughUpdate() {
		this.setState({ expanded: false });
	}

	/**
	 *
	 */
	onWalkthroughPaused() {
		this.setState({ expanded: false });
	}
}

WalkthroughProgressTab.contextType = ApplicationContext;

WalkthroughProgressTab.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore)
};

WalkthroughProgressTab.defaultProps = {};

export default injectIntl(WalkthroughProgressTab);
