import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';

import companyInvoicesGet from '../requests/companyInvoicesGet';

import IncomeModel from '../stores/models/IncomeModel';
import { AutocompleteTypes } from '../data/AutocompleteSearchObject';

/**
 *
 */
export default class GetCompanyIncomeCommand extends AbstractCommand {
	/**
	 * @param autocompleteStore AutocompleteStore
	 * @param companyId Number
	 */
	constructor(autocompleteStore, companyId) {
		super();

		this.companyId = companyId;
		this.autocompleteStore = autocompleteStore;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetCompanyIncomeCommand.execute');

		// financialYearIncome(5620)
		companyInvoicesGet(this.companyId)
			.then((response) => {
				this._parseResponse(response);
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				} else {
					Signals.Error.dispatch(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResponse(response) {
		const result = [];

		response.list.forEach((data) => {
			result.push(IncomeModel.parseIncome(data));
		});

		this.autocompleteStore.income = result;
		this.autocompleteStore.sort(AutocompleteTypes.TYPE_INVOICES);
	}
}
