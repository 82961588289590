/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../signals/Signals';

import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';
import BTWInput from '../../components/ui/BTWInput/BTWInput';

import companyUpdate from '../../requests/companyUpdate';

const VAT_REGEXP_NL = /^[0-9]{9}B[0-9]{2}$/; // NL VAT Number check, ^(NL)?[0-9]{9}B[0-9]{2}$

/**
 *
 */
class OnBoardingCompanyBTW extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onSkip = this.onSkip.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);

		this.onInputChange = this.onInputChange.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.state.vatNumber = this.props.company.vatNumber;
		this.state.vatId = this.props.company.vatId;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { company } = this.props;
		const formClasses = classNames({ 'form--submitting': this.state.submitting });

		return (
			<div className={`panel border border--dark ${this.props.className}`}>
				<form
					className={`on-boarding__form ${formClasses}`}
					name="on-boarding"
					onSubmit={this.onSubmit}>
					<div className="on-boarding__header padding border--bottom border--dark">
						<h2>
							<FormattedMessage
								id="onboarding.company.btw.title"
								values={{ name: this.props.company.name }}
							/>
						</h2>
					</div>

					<div className="padding-small">
						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="onboarding.field.company.btw" />
								</label>
								<BTWInput
									className="company-info__vatnumber col--12"
									name="vatNumber"
									value={this.state.vatNumber}
									required
									onChange={this.onInputChange}
								/>
							</FormField>
						</FormGroup>

						{!company.ignoreBTWId() ? (
							<FormGroup errors={this.state.errors}>
								<FormField className="col--12">
									<label>
										<FormattedMessage id="onboarding.field.company.btwid" />
									</label>
									<BTWInput
										className="company-info__vatid col--12"
										name="vatId"
										value={this.state.vatId}
										onChange={this.onInputChange}
									/>
								</FormField>
							</FormGroup>
						) : null}
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<button
							type="button"
							className="on-boarding__skip-button button--tertiary"
							onClick={this.onSkip}>
							{this.props.intl.formatMessage({ id: 'onboarding.form.skip' })}
						</button>
						<input
							className="on-boarding__submit-button"
							type="submit"
							value={this.props.intl.formatMessage({ id: 'onboarding.form.submit' })}
						/>
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		this.state[e.target.name] = e.target.value;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onSkip(_e) {
		this.props.successCallback(this);
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		if (this.state.sumbitting) {
			return;
		}

		if (!this.validate()) {
			return;
		}

		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'errors':
					case 'submitting':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		this.setState({ errors: null, submitting: true });
		companyUpdate(this.props.company.id, data).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		this.setState({ submitting: false });
		this.props.company.update(response.data);
		this.props.successCallback(this);
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		this.setState({ submitting: false });
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	validate() {
		const { vatNumber } = this.state;

		if (vatNumber !== undefined) {
			if (typeof vatNumber === 'string') {
				const check = vatNumber.match(VAT_REGEXP_NL);
				if (!check) {
					this.setState({
						errors: [['vatNumber', this.props.intl.formatMessage({ id: 'error.vatnumber' })]]
					});
					return false;
				}
			}
		}

		// if (vatId !== undefined) {
		// 	if (typeof(vatId) === 'string') {
		// 		const check = vatId.match(VAT_REGEXP_NL);
		// 		if (!check) {
		// 			this.setState({errors: [['vatId', this.props.intl.formatMessage({id: 'error.vatid'})]]});
		// 			return false;
		// 		}
		// 	}
		// }

		return true;
	}
}

OnBoardingCompanyBTW.propTypes = {
	intl: PropTypes.object,
	company: PropTypes.object.isRequired,
	successCallback: PropTypes.func.isRequired,
	className: PropTypes.string
};

export default injectIntl(OnBoardingCompanyBTW);
