import bankRecordsGet from '../../requests/bank/bankRecordsGet';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class GetBankRecordsCommand {
	/**
	 *
	 */
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.isFetching = false;
	}

	/**
	 *
	 * @param companyId
	 * @param year
	 * @param bankRecordStore
	 * @return {Promise<any | never>}
	 */
	execute(companyId, year, bankRecordStore) {
		if (this.isFetching) return;
		this.bankRecordStore = bankRecordStore;
		return bankRecordsGet(companyId, year).then(this.then).catch(this.catch);
	}

	/**
	 *
	 * @param response
	 */
	then(response) {
		this.isFetching = false;
		this.bankRecordStore.addAll(response, true);
	}

	/**
	 *
	 * @param error
	 */
	catch(error) {
		this.isFetching = false;
		Signals.Error.dispatch(error);
	}
}
