import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
// eslint-disable-next-line no-unused-vars
import IncomeStore from '../stores/IncomeStore';
/**
 * This hook returns the income store.
 * @return {IncomeStore}
 */
const useIncomeStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { incomeStore } = applicationStore;

	return incomeStore;
};

export default useIncomeStore;
