/* eslint-disable no-case-declarations */
import { computed, observable, action } from 'mobx';

import InvoiceConceptStatus from '../data/InvoiceConceptStatus';

import PeriodicInvoiceConceptModel from './models/PeriodicInvoiceConceptModel';
import InvoiceConceptRowModel from './models/InvoiceConceptRowModel';
import { compareBooleans, compareStrings } from '../utils/compareUtils';
import PeriodicScheduleInterval from '../data/PeriodicScheduleInterval';

/**
 *
 */
export default class PeriodicInvoicesConceptStore {
	@observable invoices = [];

	@observable searchFilter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable fetched = false; // Has invoice concept store been fetched at all?

	@observable fetching = false;

	/**
	 *
	 */
	constructor() {
		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);
	}

	/**
	 *
	 */
	reset() {
		this.searchFilter = null;
		this.sortBy = null;
		this.sortDirection = 1;

		this.invoices = [];

		this.fetched = false;
		this.fetching = false;
	}

	/**
	 *
	 * @returns {*[]}
	 */
	@computed get filteredAndSortedInvoiceConcepts() {
		return this.invoices
			.filter((invoiceConcept) => {
				return this._filter(invoiceConcept);
			})
			.sort(this._sort);
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getInvoiceConceptById(id) {
		return this.invoices.find((invoice) => {
			return invoice.id === id;
		});
	}

	/**
	 *
	 * @param id
	 */
	removeInvoiceConcept(id) {
		const invoice = this.invoices.find((i) => {
			return i.id === id;
		});

		this.invoices.remove(invoice);
	}

	/**
	 *
	 * @param periodicInvoiceConceptModel
	 */
	@action addInvoiceConcept(periodicInvoiceConceptModel) {
		const index = this.invoices.findIndex(
			(invoice) => invoice.id === periodicInvoiceConceptModel.id
		);

		if (index >= 0) {
			this.invoices[index] = periodicInvoiceConceptModel;
		} else {
			this.invoices.push(periodicInvoiceConceptModel);
		}
	}

	/**
	 * @param invoiceConceptModels Array
	 */
	@action addAll(invoiceConceptModels) {
		const invoices = this.invoices.concat([]);
		invoiceConceptModels.forEach((invoiceConceptModel) => {
			const index = invoices.findIndex((invoice) => invoice.id === invoiceConceptModel.id);

			if (index >= 0) {
				invoices[index] = invoiceConceptModel;
			} else {
				invoices.push(invoiceConceptModel);
			}
		});

		this.invoices = invoices;
	}

	/**
	 *
	 * @param invoiceConceptModel
	 */
	updateInvoiceConcept(invoiceConceptModel) {
		const invoice = this.invoices.find((i) => {
			return i.id === invoiceConceptModel.id;
		});

		if (invoice) {
			this._updateInvoiceConcept(invoiceConceptModel, invoice);

			// Force update
			this.removeInvoiceConcept(invoice.id);
			this.invoices.push(invoice);
		} else {
			console.error('invoice concept not found');
		}
	}

	/**
	 *
	 */
	update() {
		this.invoices = this.invoices.concat([]);
	}

	/**
	 *
	 * @param data
	 * @returns {PeriodicInvoiceConceptModel}
	 * @private
	 */
	_parseInvoiceConcept(data) {
		const invoiceConcept = new PeriodicInvoiceConceptModel();
		this._updateInvoiceConcept(data, invoiceConcept);
		return invoiceConcept;
	}

	/**
	 *
	 * @param data
	 * @param invoiceConcept
	 * @private
	 */
	_updateInvoiceConcept(data, invoiceConcept) {
		invoiceConcept.update(data);
		invoiceConcept.invoiceConceptRows = [];

		if (!data.invoiceConceptRows) {
			data.invoiceConceptRows = [];
		}

		data.invoiceConceptRows.forEach((invoiceRow) => {
			invoiceConcept.invoiceConceptRows.push(
				new InvoiceConceptRowModel(invoiceRow, invoiceConcept.date)
			);
		});
	}

	/**
	 * @param invoiceConcept
	 * @returns {boolean}
	 * @private
	 */
	_filter(invoiceConcept) {
		if (!invoiceConcept) {
			return true;
		}

		// Apply Search filter
		if (!this.searchFilter) {
			return true;
		}

		const searchString = invoiceConcept.toSearchString();
		return searchString.indexOf(this.searchFilter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'status':
				// eslint-disable-next-line no-nested-ternary
				result = compareBooleans(a ? a.isActive() : false.b ? b.isActive() : false);
				break;
			case 'companyName':
				const companyNameA = a.companyCustomer.getCustomerName(); // a.companyCustomer && a.companyCustomer.companyName ? a.companyCustomer.companyName : null;
				const companyNameB = b.companyCustomer.getCustomerName(); // b.companyCustomer && b.companyCustomer.companyName ? b.companyCustomer.companyName : null;

				if (!companyNameA && !companyNameB) {
					result = 0;
				} else if (companyNameA && !companyNameB) {
					result = 1;
				} else if (companyNameB && !companyNameA) {
					result = -1;
				} else {
					result = companyNameA.localeCompare(companyNameB);
				}
				break;
			case 'interval':
				result = this._compareInterval(a, b);
				break;
			case 'lastAttemptStatus':
				result = compareStrings(
					a.periodicSchedule.lastAttemptStatus,
					b.periodicSchedule.lastAttemptStatus
				);
				break;
			case 'amount':
				result = a.getTotal() - b.getTotal();
				break;
			case 'subject':
				result = a.subject.localeCompare(b.subject);
				break;
			default:
				result = 0;
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}

	/**
	 *
	 * @param periodicInvoiceA
	 * @param periodicInvoiceB
	 * @return {number}
	 */
	_compareInterval(periodicInvoiceA, periodicInvoiceB) {
		const a = this._getIntervalWeight(periodicInvoiceA.periodicSchedule.interval);
		const b = this._getIntervalWeight(periodicInvoiceB.periodicSchedule.interval);
		const result = a - b;

		if (result === 0) {
			return periodicInvoiceA.periodicSchedule.amount - periodicInvoiceB.periodicSchedule.amount;
		}

		return result;
	}

	/**
	 *
	 * @param statusA
	 * @param statusB
	 * @return {number}
	 * @private
	 */
	_compareStatus(statusA, statusB) {
		const weightA = this._getStatusWeight(statusA);
		const weightB = this._getStatusWeight(statusB);

		return weightA - weightB;
	}

	/**
	 *
	 * @param interval
	 * @return {number}
	 * @private
	 */
	_getIntervalWeight(interval) {
		switch (interval) {
			case PeriodicScheduleInterval.DAILY:
				return 0;
			case PeriodicScheduleInterval.WEEKLY:
				return 1;
			case PeriodicScheduleInterval.MONTHLY:
				return 2;
			default:
				return 3;
		}
	}

	/**
	 *
	 * @param status
	 * @return {number}
	 * @private
	 */
	_getStatusWeight(status) {
		switch (status) {
			case InvoiceConceptStatus.CREATED:
				return 0;
			case InvoiceConceptStatus.SENT_REMINDER_KDB:
				return 1;
			case InvoiceConceptStatus.LATE:
				return 2;
			case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
			case InvoiceConceptStatus.SENT_MANUALLY:
				return 3;
			case InvoiceConceptStatus.PAID:
				return 4;
			case InvoiceConceptStatus.ARCHIVED:
				return 5;
			case InvoiceConceptStatus.DELETED:
				return 5;
			default:
				return 7;
		}
	}
}
