import Signals from '../signals/Signals';

/**
 *
 */
class RequestController {
	/**
	 *
	 */
	constructor() {
		this._requests = [];
		this._onProgress = this._onProgress.bind(this);
	}

	/**
	 *
	 * @param req
	 */
	addRequest(req) {
		if (this._exists(req) < 0) {
			this._requests.push(req);
			req.addEventListener('progress', this._onProgress);
			this._evaluate();
		}
	}

	/**
	 *
	 * @param req
	 */
	removeRequest(req) {
		req.removeEventListener('progress', this._onProgress);

		const index = this._exists(req);
		if (index >= 0) {
			this._requests.splice(index, 1);
			this._evaluate();
		}
	}

	/**
	 *
	 * @private
	 */
	_evaluate() {
		if (this._requests.length > 0) {
			Signals.ShowLoadingScreen.dispatch();
		} else {
			Signals.HideLoadingScreen.dispatch();
		}
	}

	/**
	 *
	 * @param req
	 * @return {number}
	 * @private
	 */
	_exists(req) {
		for (let i = 0; i < this._requests.length; i++) {
			if (this._requests[i] === req) {
				return i;
			}
		}

		return -1;
	}

	/**
	 *
	 * @param event
	 * @private
	 */
	_onProgress(event = null) {
		if (event && event.direction === 'upload') {
			event.target.percent = event.percent;
			this._updateProgress();
		}
	}

	_updateProgress() {
		if (this._requests.length <= 0) {
			return 0;
		}

		let totalPercentage = 0;
		let totalUploads = 0;
		this._requests.forEach((req) => {
			if (req.xhr.upload) {
				totalPercentage += req.xhr.upload.percent ? req.xhr.upload.percent : 0;
				totalUploads++;
			}
		});

		if (totalPercentage === 0) {
			return 0;
		}

		const percentage = totalPercentage / totalUploads;
		Signals.ProgressUpload.dispatch(percentage);
	}
}

const requestController = new RequestController();
export default requestController;
