import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Routes } from '../../../../data/Routes';
import MenuItem from '../../MenuItem/MenuItem';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class UserSettingsSubMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const { applicationStore } = this.context;
		if (!applicationStore.isLoggedIn) {
			return null;
		}

		return (
			<ul className="sub-menu__item-wrapper">
				<MenuItem
					className="submenu-item"
					route={Routes.USER_SETTINGS_INFO.getPath({})}
					routeToMatch={Routes.USER_SETTINGS_INFO}
					matchExact
					iconName="settings2">
					<FormattedMessage id="settings.submenu.user.info" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.USER_SETTINGS_CHANGEPASSWORD.getPath({})}
					routeToMatch={Routes.USER_SETTINGS_CHANGEPASSWORD}
					matchExact
					iconName="edit">
					<FormattedMessage id="settings.submenu.user.password" />
				</MenuItem>
			</ul>
		);
	}
}

UserSettingsSubMenu.contextType = ApplicationContext;

UserSettingsSubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(UserSettingsSubMenu);
