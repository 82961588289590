import AbstractCommand from '../AbstractCommand';
import companyBrandCreate from '../../requests/brands/companyBrandCreate';

/**
 *
 */
export default class CreateCompanyBrandCommand extends AbstractCommand {
	/**
	 *
	 * @param company
	 * @param companyBrand
	 */
	constructor(company, companyBrand) {
		super();
		this.company = company;
		this.companyBrand = companyBrand;
	}

	/**
	 *
	 * @param successCallback
	 * @param failCallback
	 */
	execute(successCallback, failCallback) {
		companyBrandCreate(this.company.id, this.companyBrand)
			.then((response) => {
				successCallback(response);
			})
			.catch((err) => {
				if (failCallback) {
					failCallback(err);
				}
			});
	}
}
