/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Page from '../Page';

import { getTranslations } from '../../utils/Translations';

import BTW from '../../data/BTW';
import PaymentStatus from '../../data/PaymentStatus';

import InvoiceRow from '../Company/Invoice/components/InvoiceRow';
import CompanyAddress from '../../components/ui/CompanyAddress/CompanyAddress';
import ClientAddress from '../../components/ui/ClientAddress/ClientAddress';

import InvoiceSummaryRow from '../Company/Invoice/components/InvoiceSummaryRow';

import Signals from '../../signals/Signals';
import { getParameterByName } from '../../utils/urlUtils';
import { buildPublicAssetURL, buildPublicInvoicePdfURL } from '../../utils/assetUtils';
import { buildCompanyInvoicePDFURL } from '../../requests/companyInvoicePDF';

import InvoiceAttachments from '../Company/Invoice/components/InvoiceAttachments';
import { ApplicationContext } from '../../ApplicationContext';

import GetPublicInvoiceCommand from '../../commands/invoiceConcepts/GetPublicInvoiceCommand';
import GetPublicInvoicePaymentStatusCommand from '../../commands/invoiceConcepts/GetPublicInvoicePaymentStatusCommand';
import InvoiceConceptModel from '../../stores/models/InvoiceConceptModel';
import Locale from '../../data/Locale';
import EndPoints from '../../data/EndPoints';
import CanPayOnlineStatus from '../../data/CanPayOnlineStatus';
import User from '../../stores/models/User';
import ClientHeader from '../../components/ui/ClientHeader/ClientHeader';
import ModalAlert from '../../components/ui/Modal/ModalAlert';
import CompanyBrandModel from '../../stores/models/CompanyBrandModel';

/**
 *
 */
class PublicInvoice extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onShowPDF = this.onShowPDF.bind(this);
		this.onViewOption = this.onViewOption.bind(this);
		this.onPayOnline = this.onPayOnline.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		console.log('PublicInvoice.componentDidMount', this.state);

		this.initialize();

		const { intl } = this.props;

		// Has error? Show error message
		if (getParameterByName('error')) {
			setTimeout(() => {
				Signals.ShowModal.dispatch(
					<ModalAlert
						title={intl.formatMessage({ id: 'error.generic.title' })}
						body={intl.formatMessage({ id: 'error.generic.invoice.description' })}
					/>
				);
			}, 500);
		}
	}

	/**
	 *
	 */
	componentWillUnmount() {
		console.log('PublicInvoice.componentWillUnmount');
	}

	/**
	 *
	 */
	render() {
		const { invoiceConceptModel } = this.state;
		if (!invoiceConceptModel) {
			return null;
		}

		//
		const { intl } = this.props;
		const { company, date } = invoiceConceptModel;
		const companyBrand = invoiceConceptModel.companyBrand
			? invoiceConceptModel.companyBrand
			: CompanyBrandModel.fromCompany(company);

		// Build logo URL
		const logoPreviewURL = companyBrand.logoAssetUuid
			? buildPublicAssetURL(invoiceConceptModel.publicAccessId, companyBrand.logoAssetUuid)
			: null;
		const deviatingUnits = invoiceConceptModel.hasDeviatingUnits();

		if (!deviatingUnits) {
			// eslint-disable-next-line array-callback-return
			this.fieldHeaderSettings.map((fieldData) => {
				if (fieldData.id === 'units') {
					fieldData.hide = true;
				}

				if (fieldData.id === 'unitprice') {
					fieldData.labelId = fieldData.labelIdSingle;
				}
			});
		}

		const detailsWrapperClasses = classNames({
			'invoice__details-wrapper': true,
			'invoice__details-wrapper--no-units': !deviatingUnits
		});

		// Create table headers
		const values = { totalUnits: '' };
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			return (
				<td
					key={`invh-${index}`}
					className={`${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})} offer__header--${fieldData.id.toLowerCase()}`}>
					{fieldData.hide ? null : <FormattedMessage id={fieldData.labelId} values={values} />}
				</td>
			);
		});

		// Create invoices concept rows
		const rows = invoiceConceptModel.invoiceConceptRows.map((invoiceConceptRowModel, index) => {
			return (
				<InvoiceRow
					key={`i-${index}-${invoiceConceptRowModel._uid}`}
					hideUnits={!deviatingUnits}
					invoiceConceptModel={invoiceConceptModel}
					invoiceConceptRowModel={invoiceConceptRowModel}
				/>
			);
		});

		return (
			<div>
				<ClientHeader
					company={company}
					companyCustomer={invoiceConceptModel.companyCustomer}
					forCompanyLabel={intl.formatMessage({ id: 'public.invoice.for.label' })}
				/>

				<IntlProvider locale={this.state.locale} messages={this.state.translations}>
					<Page pageName="public-invoice">
						{/* Main Panel */}
						<div className="invoice__panel panel grid col--12 box-shadow border border--dark">
							{/* Option buttons */}
							<div className="invoice__options-top padding-small border--bottom border--dark grid grid--spread col--12">
								<div className="invoice__options-left grid">
									{invoiceConceptModel.canBePaidOnline() ? (
										<button
											type="button"
											className="btn-margin-left button icon icon--left icon--pay-icon-white"
											onClick={this.onPayOnline}>
											<FormattedMessage id="public.invoice.payment.button" />
										</button>
									) : null}
								</div>
								<div className="invoice__options-right grid grid--right">
									<button
										type="button"
										className="button--tertiary icon icon--left icon--eye2-black"
										onClick={() => this.downloadPDF()}>
										<FormattedMessage id="invoice.view.pdf" />
									</button>
								</div>
							</div>

							{/* Invoice status */}
							<div
								className={`invoice__status ${
									invoiceConceptModel.status
										? `invoice__status--${invoiceConceptModel.status.toLowerCase()}`
										: ''
								}`}>
								<label>
									<FormattedMessage
										id={`invoice.status.${
											invoiceConceptModel.status ? invoiceConceptModel.status.toLowerCase() : ''
										}`}
										values={{
											statusDateChanged: this.props.intl.formatDate(
												invoiceConceptModel.statusDateChanged
											)
										}}
									/>
								</label>
							</div>

							{/* Invoice layout + localised translation */}
							<div className="invoice__layout-wrapper padding grid">
								<div className="invoice__information grid col--12">
									<div className="invoice__logo col--6">
										{logoPreviewURL ? (
											<img className="invoice__logo-img" src={logoPreviewURL} alt="logo" />
										) : null}
									</div>

									<div className="col--6 grid">
										<label className="text--right invoice__information-label col--5">
											<FormattedMessage id="invoice.label.sender" />
										</label>
										<div className="col--7">
											<CompanyAddress
												company={companyBrand.toCompany(company)}
												useDate={date}
												canEdit={false}
											/>
										</div>
									</div>

									<div className="invoice__information-detail-labels grid col--6">
										<label className="invoice__information-label col--4">
											<FormattedMessage id="invoice.info.invoice" />
										</label>
										<div className="invoice__information-value invoice__information-value--bold col--8">
											{invoiceConceptModel.subject}
										</div>

										<label className="invoice__information-label col--4">
											<FormattedMessage id="invoice.info.invoicenr" />
										</label>
										<div className="invoice__information-value col--8">
											{invoiceConceptModel.invoiceNr}
										</div>

										<label
											className={`invoice__information-label col--4 ${classNames({
												hidden: !invoiceConceptModel.projectcode
											})}`}>
											<FormattedMessage id="invoice.info.projectcode" />
										</label>
										<div
											className={`invoice__information-value col--8 ${classNames({
												hidden: !invoiceConceptModel.projectcode
											})}`}>
											{invoiceConceptModel.projectcode}
										</div>

										<label className="invoice__information-label col--4">
											<FormattedMessage id="invoice.info.date" />
										</label>
										<div className="invoice__information-value col--8">
											<FormattedDate
												value={invoiceConceptModel.date}
												day="numeric"
												month="long"
												year="numeric"
											/>
										</div>

										<label className="invoice__information-label col--4">
											<FormattedMessage id="invoice.info.expirationdate" />
										</label>

										<div className="invoice__information-value col--8">
											{/* Calculate expirationDate from date + expirationDays to cancel out discrepantie of 1 day */}
											<FormattedDate
												value={invoiceConceptModel.expirationDate}
												day="numeric"
												month="long"
												year="numeric"
											/>
										</div>
									</div>

									<div className="col--6 grid">
										<label className="text--right invoice__information-label col--5">
											<FormattedMessage id="invoice.label.client" />
										</label>
										<div className="col--7">
											<ClientAddress
												companyCustomer={invoiceConceptModel.companyCustomer}
												language={invoiceConceptModel.locale === Locale.en_US ? 'en' : 'nl'}
											/>
										</div>
									</div>
								</div>

								<div className={detailsWrapperClasses}>
									<table className="table table--wide col--12 invoice__details">
										<thead className="table__headers">
											<tr>{headers}</tr>
										</thead>
										<tbody className="table__body">
											{rows}

											{/* Empty row */}
											<tr className="invoice-summary-row">
												<td colSpan={5}>&nbsp;</td>
											</tr>

											<InvoiceSummaryRow
												label={intl.formatMessage({ id: 'invoice.summary.subtotal' })}
												amount={invoiceConceptModel.getSubtotal()}
											/>
											<InvoiceSummaryRow
												hideWhenZero={!invoiceConceptModel.containsVATType(BTW.LAAG)}
												label={intl.formatMessage(
													{ id: 'invoice.summary.btw' },
													{
														percentage: intl.formatMessage({
															id: BTW.LAAG.translationId(invoiceConceptModel.date)
														})
													}
												)}
												amount={invoiceConceptModel.getBTWTotal(BTW.LAAG.name)}
											/>
											<InvoiceSummaryRow
												hideWhenZero={!invoiceConceptModel.containsVATType(BTW.HOOG)}
												label={intl.formatMessage(
													{ id: 'invoice.summary.btw' },
													{ percentage: intl.formatMessage({ id: BTW.HOOG.translationId() }) }
												)}
												amount={invoiceConceptModel.getBTWTotal(BTW.HOOG.name)}
											/>

											{this._getVATReversed()}
											{this._getVATExempt()}

											<InvoiceSummaryRow
												label={intl.formatMessage({ id: 'invoice.summary.total' })}
												amount={invoiceConceptModel.getTotal()}
											/>
										</tbody>
									</table>
								</div>

								{/* Optional notes */}
								{invoiceConceptModel.hasNotes() ? (
									<div className="invoice__notes col--12 grid padding border--top padding--bottom">
										<label>
											<FormattedMessage id="invoice.label.notes" />
										</label>
										<div
											// eslint-disable-next-line react/no-danger
											dangerouslySetInnerHTML={{ __html: invoiceConceptModel.getNotesAsHTML() }}
										/>
									</div>
								) : null}
							</div>

							{/* Extra option panel */}
							{invoiceConceptModel.canBePaidOnline() ? (
								<div className="invoice__panel__options-bottom padding-small border--top border--dark grid grid--spread col--12">
									<div />
									<div>
										<button
											type="button"
											className="btn-margin-left button icon icon--left icon--pay-icon-white"
											onClick={this.onPayOnline}>
											<FormattedMessage id="public.invoice.payment.button" />
										</button>
									</div>
								</div>
							) : null}
						</div>

						{/* Attachments */}
						<InvoiceAttachments invoiceConceptModel={invoiceConceptModel} company={company} />
					</Page>
				</IntlProvider>
			</div>
		);
	}

	/**
	 *
	 */
	onViewOption(option) {
		switch (option) {
			case 'pdf':
				this.onShowPDF();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 */
	onShowPDF() {
		const company = this.context.applicationStore.getSelectedCompany();
		const companyId = company.id;
		const invoiceId = this.state.invoiceConceptModel.id;

		const url = buildCompanyInvoicePDFURL(companyId, invoiceId);
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	onPayOnline() {
		const uuid = this.context.applicationStore.currentRouteParams.uuid;
		const url = window.config.apiPrefix + EndPoints.PUBLIC_INVOICE_PAYMENT.replace(':uuid', uuid);
		window.open(url, '_self');
	}

	/**
	 *
	 */
	resetFieldHeaderSettings() {
		this.fieldHeaderSettings = [
			{ id: 'description', labelId: 'invoice.header.description' },
			{ id: 'units', labelId: 'invoice.header.units', right: true },
			{
				id: 'unitprice',
				labelId: 'invoice.header.unitprice',
				labelIdSingle: 'invoice.header.price',
				right: true
			},
			{ id: 'btw', labelId: 'invoice.header.btw', right: true },
			{ id: 'amount', labelId: 'invoice.header.amount', right: true }
		];
	}

	/**
	 *
	 */
	initialize() {
		this.resetFieldHeaderSettings();

		const newState = {};
		newState.invoiceConceptModel = new InvoiceConceptModel();
		newState.locale = 'nl';
		this.state = newState;

		const uuid = this.context.applicationStore.currentRouteParams.uuid;
		const invoiceCommand = new GetPublicInvoiceCommand(newState.invoiceConceptModel, uuid);
		invoiceCommand.execute(
			() => {
				switch (newState.invoiceConceptModel.locale) {
					case Locale.en_US:
						newState.locale = 'en';
						break;
					case Locale.nl_NL:
						newState.locale = 'nl';
						break;
					default:
						newState.locale = 'nl';
				}

				const { applicationStore } = this.context;

				// Force localisation to invoice locale
				const user = new User();
				user.language = window.config.defaultLocale = newState.locale;
				newState.translations = getTranslations(newState.locale);
				applicationStore.user = user;

				//
				newState.invoiceConceptModel.publicAccessId = applicationStore.currentRouteParams.uuid;

				//
				this.setState(newState, () => {
					this.checkPaymentStatus();
				});
			},
			(_err) => {
				const translationTitleId = 'public.invoice.notfoundorexpired.title';
				const translationId = 'public.invoice.notfoundorexpired.description';
				Signals.ShowModal.dispatch(
					<ModalAlert
						title={this.props.intl.formatMessage({ id: translationTitleId })}
						body={this.props.intl.formatMessage({ id: translationId })}
						onConfirm={() => (window.location.href = '/')}
					/>
				);
			}
		);
	}

	/**
	 *
	 */
	checkPaymentStatus() {
		const { invoiceConceptModel } = this.state;
		// Retrieve payment status
		if (invoiceConceptModel.canPayOnline === CanPayOnlineStatus.YES) {
			const { intl } = this.props;
			const uuid = this.context.applicationStore.currentRouteParams.uuid;
			const statusCommand = new GetPublicInvoicePaymentStatusCommand(uuid);
			statusCommand.execute(
				(response) => {
					switch (response.data.status) {
						case PaymentStatus.OPEN:
						case PaymentStatus.UNKNOWN:
							// Do nothing
							break;
						case PaymentStatus.PAID:
							if (invoiceConceptModel.ownInvoice) {
								// Show message that invoice has been added to administration if invoice is for customer
								const body = invoiceConceptModel.customerFactuur
									? intl.formatMessage({ id: 'invoice.payment.status.paid.system.body' })
									: '';
								Signals.ShowModal.dispatch(
									<ModalAlert
										icon="icon--check-black"
										title={intl.formatMessage({ id: 'invoice.payment.status.paid.system.title' })}
										body={body}
									/>
								);
							}
							break;
						case PaymentStatus.AUTHORIZED:
						case PaymentStatus.PENDING:
							Signals.ShowModal.dispatch(
								<ModalAlert
									icon="icon--clock-black"
									title={intl.formatMessage({ id: 'invoice.payment.status.pending.title' })}
									body={intl.formatMessage({ id: 'invoice.payment.status.pending.body' })}
								/>
							);
							break;
						case PaymentStatus.CANCELED:
						case PaymentStatus.FAILED:
						case PaymentStatus.EXPIRED:
							Signals.ShowModal.dispatch(
								<ModalAlert
									icon="icon--x-circle-black"
									title={intl.formatMessage({ id: 'invoice.payment.status.failed.title' })}
									body={intl.formatMessage({ id: 'invoice.payment.status.failed.body' })}
								/>
							);
							break;
						default:
							break;
					}
				},
				(err) => {
					Signals.Error.dispatch(err);
				}
			);
		}
	}

	/**
	 *
	 */
	downloadPDF() {
		const url = buildPublicInvoicePdfURL(this.state.invoiceConceptModel.publicAccessId);
		window.open(url, '_blank');
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.state.invoiceConceptModel.containsVATType(BTW.VERLEGD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.reversed.summary" />}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.state.invoiceConceptModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.exempt.summary" />}
					amount={null}
				/>
			);
		}

		return null;
	}
}

PublicInvoice.contextType = ApplicationContext;

PublicInvoice.propTypes = {
	intl: PropTypes.object,
	// eslint-disable-next-line react/no-unused-prop-types
	history: PropTypes.object
};

export default withRouter(injectIntl(PublicInvoice));
