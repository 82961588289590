const draftTypes = {
	// private
	DOCUMENT: {
		name: 'DOCUMENT',
		iconName: 'DOCUMENT',
		toString() {
			return this.name;
		}
	},
	EXPENSE: {
		name: 'EXPENSE',
		iconName: 'EXPENSE',
		toString() {
			return this.name;
		}
	},
	INCOME: {
		name: 'INCOME',
		iconName: 'INCOME',
		toString() {
			return this.name;
		}
	}
};

export default draftTypes;
