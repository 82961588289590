import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (profileId) => {
	return fetch(
		Methods.GET,
		EndPoints.COMMUNITY_PROFILE.replace(':profileId', profileId),
		null,
		null,
		true
	);
};
