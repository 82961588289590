import acceptAggrementPut from '../../requests/bank/acceptAggrementPut';

export default class AcceptAgreementCommand {
	/**
	 *
	 */
	constructor() {
		this.then = this.then.bind(this);
	}

	/**
	 *
	 * @param companyId
	 *
	 * @return {Promise<any | never>}
	 */
	execute(companyId, bankbookStatusStore) {
		this.companyId = companyId;
		this.bankbookStatusStore = bankbookStatusStore;
		return acceptAggrementPut(companyId, {
			accepted: true
		}).then(this.then);
	}

	/**
	 *
	 */
	then() {
		console.log('AcceptAgreementCommand.then', this.bankbookStatusStore);
		this.bankbookStatusStore.agreement.accepted = true;
	}
}
