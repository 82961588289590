import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

@observer
class BookKeeperInfo extends React.Component {
	render() {
		const { bookKeeper } = this.props.user;
		const name = bookKeeper.getFullName();
		const imageURL = bookKeeper.getImageURL();

		const email = bookKeeper.email;
		const phone = bookKeeper.phone ?? '';

		return (
			<div className="bookkeeper-info">
				<h2 className="bookkeeper__title">
					<FormattedMessage id="bookkeeper.title" />
				</h2>
				<div className="bookkeeper-info__wrapper grid">
					<div
						className="bookkeeper-info__image"
						style={{ backgroundImage: `url("${imageURL}")` }}
					/>
					<div className="bookkeeper-info__content">
						<h3 className="bookkeeper-info__name">{name}</h3>
						<p className="bookkeeper-info__email">
							<i className="icon icon--mail-smoke" />
							<a href={`mailto:${email}`} target="_blank" rel="noreferrer">
								{email}
							</a>
						</p>
						<p className="bookkeeper-info__phones">
							<i className="icon icon--phone-smoke" />
							<a href={`tel:${phone}`} target="_blank" rel="noreferrer">
								{phone}
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

BookKeeperInfo.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object,
	user: PropTypes.object
};

export default injectIntl(BookKeeperInfo);
