/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import FormGroup from '../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../components/ui/FormField/FormField';
import DateInput from '../../../../components/ui/DateInput/DateInput';
import Child from '../../../../data/Child';

/**
 *
 */
export default class ChildrenInput extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = { children: this.props.children };

		// Make sure there is a minimum of 1 child
		if (this.state.children.length <= 0) {
			this.state.children.push(new Child());
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeInitials = this.onChangeInitials.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onAdd = this.onAdd.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({});

		return (
			<div
				className={`children-input ${this.props.className ? this.props.className : ''} ${classes}`}>
				{this._getChildrenInputs()}

				{this.props.disabled ? null : (
					<div
						className="children-input__add border--top border--dark icon icon--left icon--add-black"
						onClick={this.onAdd}>
						<FormattedMessage id="children.input.add.row" />
					</div>
				)}
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onChange(_e) {
		if (this.props.onChange) {
			this.props.onChange(this.state.children.concat([]));
		}
	}

	/**
	 *
	 * @param e
	 */
	onChangeInitials(e) {
		if (e && e.target && e.target.dataset) {
			const index = e.target.dataset.index;
			const name = e.target.name;
			this.state.children[index][name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 * @param date
	 * @param index
	 */
	onDateChange(e, date, index) {
		this.state.children[index].birthDate = date;

		this.forceUpdate();
	}

	onBlur() {
		this.onChange();
	}

	/**
	 *
	 * @param e
	 */
	onAdd(_e) {
		this.state.children.push(new Child());

		this.onChange();
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onRemove(e) {
		const index = e.target.dataset.index;
		console.log(index);

		this.state.children.splice(index, 1);
		this.onChange();
		this.forceUpdate();
	}

	/**
	 *
	 * @private
	 */
	_getChildrenInputs() {
		return this.state.children.map((child, index) => {
			const i = index;
			const classes = classNames({
				'col--12': true,
				'children-input__can-remove': !this.props.disabled // index !== 0 &&
			});

			return (
				<FormGroup key={`chin-${index}`} className={classes}>
					<FormField className="col--6">
						<label>
							<FormattedMessage id="user.profile.field.initials" />
						</label>
						<input
							name="initials"
							type="text"
							required
							value={child.initials}
							onChange={this.onChangeInitials}
							onBlur={this.onBlur}
							disabled={this.props.disabled}
							data-index={index}
						/>
					</FormField>
					<FormField className="col--6 form-field__date-picker">
						<label>
							<FormattedMessage id="user.profile.field.birthdate" />
						</label>
						<DateInput
							name="birthdate"
							required
							value={child.birthDate}
							onChange={(e, date) => {
								this.onDateChange(e, date, i);
							}}
							onBlur={this.onBlur}
							disabled={this.props.disabled}
							data-index={index}
						/>
					</FormField>

					{/* Normal remove */}
					<div
						className="children-input__remove icon icon--delete-black"
						data-index={index}
						onClick={this.onRemove}
					/>

					{/* Mobile remove */}
					<div className="grid grid--right col--12 children-input__remove-mobile">
						<button
							className="button--tertiary icon icon--left icon--delete-black"
							type="button"
							data-index={index}
							onClick={this.onRemove}>
							<FormattedMessage id="label.remove" />
						</button>
					</div>
				</FormGroup>
			);
		});
	}
}

ChildrenInput.propTypes = {
	children: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool
};

ChildrenInput.defaultProps = {};
