import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import Page from '../../Page';
import { ApplicationContext } from '../../../ApplicationContext';
import OffersSettingsGeneric from './OffersSettingsGeneric';
import OffersSettingsDutch from './OffersSettingsDutch';
import OffersSettingsEnglish from './OffersSettingsEnglish';
import GenericSettingsBrands from '../GenericSettings/GenericSettingsBrands';

const SETTINGS_FORM_TYPE = {
	GENERIC: 'generic',
	DUTCH: 'dutch',
	ENGLISH: 'english',
	BRANDS: 'addresses'
};

/**
 *
 */
class OffersSettings extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			formType: SETTINGS_FORM_TYPE.GENERIC
		};
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();

		if (!this.context.applicationStore.isLoggedIn || !company) {
			return null;
		}

		//

		const genericButtonClasses = classNames({
			'offers-settings__form-type-button col--12': true,
			'offers-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.GENERIC
		});
		const addressesButtonClasses = classNames({
			'offers-settings__form-type-button col--12': true,
			'offers-settings__form-type-button--active': this.state.formType === SETTINGS_FORM_TYPE.BRANDS
		});
		const dutchButtonClasses = classNames({
			'offers-settings__form-type-button col--12': true,
			'offers-settings__form-type-button--active': this.state.formType === SETTINGS_FORM_TYPE.DUTCH
		});
		const englishButtonClasses = classNames({
			'offers-settings__form-type-button col--12': true,
			'offers-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.ENGLISH
		});

		return (
			<Page pageName="offers-settings">
				<div className="offers-settings__content-wrapper grid">
					{this._getSelectedForm()}

					{/* Menu */}
					<div className="offers-settings__menu grid">
						<div
							className={genericButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.GENERIC);
							}}>
							<FormattedMessage id="offers.settings.title.generic" />
						</div>
						<div
							className={addressesButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.BRANDS);
							}}>
							<FormattedMessage id="offers.settings.title.brands" />
						</div>
						<div
							className={dutchButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.DUTCH);
							}}>
							<FormattedMessage id="offers.settings.title.dutch" />
						</div>
						<div
							className={englishButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.ENGLISH);
							}}>
							<FormattedMessage id="offers.settings.title.english" />
						</div>
					</div>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param formType
	 * @private
	 */
	_setSelectedForm(formType) {
		this.setState({ formType });
	}

	/**
	 *
	 * @private
	 */
	_getSelectedForm() {
		switch (this.state.formType) {
			case SETTINGS_FORM_TYPE.GENERIC:
				return <OffersSettingsGeneric />;
			case SETTINGS_FORM_TYPE.BRANDS:
				return <GenericSettingsBrands />;
			case SETTINGS_FORM_TYPE.DUTCH:
				return <OffersSettingsDutch />;
			case SETTINGS_FORM_TYPE.ENGLISH:
				return <OffersSettingsEnglish />;
			default:
				return null;
		}
	}
}

OffersSettings.contextType = ApplicationContext;

OffersSettings.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(OffersSettings);
