/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

/**
 *
 * @param value
 * @returns {*}
 * @private
 */
export function _parseCurrency(value) {
	value += '';
	value = parseFloat(value.replace(',', '.'));
	return isNaN(value) ? 0 : value;
}

/**
 *
 * @param dataURI
 * @return {Blob}
 */
export function dataURItoBlob(dataURI) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	} else {
		byteString = unescape(dataURI.split(',')[1]);
	}

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

/**
 * Returns a Date object based on string with format YYYY-MM-DD
 *
 * @param s String with format YYYY-MM-DDx
 * @param reverse boolean, is the date reversed DD-MM--YYYY
 * @param includesTime boolean Also include time
 * @param offsetHalfday boolean Offset hourse by +12 if no time is set
 * @returns {Date}
 * @private
 */
export function _formatStringToDate(
	s,
	reverse = false,
	includesTime = false,
	offsetHalfday = false
) {
	// Split date and time
	let dt = null;
	if (includesTime) {
		dt = s.split(' ');
		s = dt[0];
		dt = dt.length > 1 ? dt[1] : null;
	}

	// Extract date
	const a = s.split('-');
	if (reverse) {
		a.reverse();
	}

	const year = a.length > 0 ? parseInt(a[0], 10) : undefined;
	const month = a.length > 1 ? parseInt(a[1], 10) : undefined;
	const day = a.length > 2 ? parseInt(a[2], 10) : undefined;

	// Extract time
	let hours = offsetHalfday ? 12 : null;
	let minutes = null;
	let seconds = null;

	// Has time
	if (dt) {
		const dta = dt.split(':');
		hours = dta.length > 0 ? parseInt(dta[0], 10) : null;
		minutes = dta.length > 1 ? parseInt(dta[1], 10) : null;
		seconds = dta.length > 2 ? parseInt(dta[2], 10) : null;
	}

	return new Date(year, month - 1, day, hours, minutes, seconds);
}

export function _addZeros(value, length = 2) {
	let s = `${value}`;
	while (s.length < length) {
		s = `0${s}`;
	}

	return s;
}

export function _formatDate(date, reverse = false) {
	if (date instanceof Date && isNaN(date.getTime())) {
		return null;
	}

	const day = _addZeros(date.getDate());
	const month = _addZeros(date.getMonth() + 1);
	const year = date.getFullYear();
	if (reverse) {
		return `${year}-${month}-${day}`;
	}
	return `${day}-${month}-${year}`;
}

/**
 *
 * @param value
 * @param convertNumbers
 * @returns {*}
 * @private
 */
export function _formatValue(value, convertNumbers = false) {
	if (value instanceof Date) {
		return _formatDate(value);
	}

	if (typeof value === 'number' && convertNumbers) {
		return value.toString().replace('.', ',');
	}

	return value;
}

export function isHTTP(value) {
	if (!value) {
		return false;
	}

	value = value.toLowerCase();
	return value.startsWith('http://');
}

export function isHTTPS(value) {
	if (!value) {
		return false;
	}

	value = value.toLowerCase();
	return value.startsWith('https://');
}

/**
 * Strips http:// or https:// or anything before :// from the specified value
 *
 * @param value
 * @param reAdd, try to re-add the protocol
 * @returns {string|null}
 * @private
 */
export function stripProtocolFromUrl(value, reAdd = false) {
	if (!value) {
		return value;
	}

	const parts = value.split('://');
	const stripped = parts[parts.length - 1];

	if (reAdd) {
		if (isHTTP(value)) {
			return `http://${stripped}`;
		}
		if (isHTTPS(value)) {
			return `https://${stripped}`;
		}

		return `http://${stripped}`;
	}

	return stripped;
}

export default function objectToFormData(data, convertNumbers = false, ignoreFields = []) {
	const formData = new FormData();

	// Loop through keys in data object
	for (const key in data) {
		//
		if (data.hasOwnProperty(key)) {
			// Skip undefined values or values to be ignored
			// eslint-disable-next-line eqeqeq
			if (!(ignoreFields.indexOf(key) >= 0) && data[key] != undefined) {
				// Check if value is array
				if (Array.isArray(data[key])) {
					data[key].forEach((value, index) => {
						// If contents of array is object, loop through keys
						if (typeof value === 'object') {
							for (const valueKey in value) {
								// eslint-disable-next-line eqeqeq
								if (value[valueKey] != undefined) {
									formData.append(
										`${key}[${index}].${valueKey}`,
										_formatValue(value[valueKey], convertNumbers)
									);
								}
							}
						} else {
							formData.append(`${key}[${index}]`, _formatValue(value, convertNumbers));
						}
					});
				} else {
					formData.append(key, _formatValue(data[key], convertNumbers));
				}
			}
		}
	}

	return formData;
}
