import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
export default class Page extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { show: false };
	}

	/**
	 *
	 */
	componentDidMount() {
		setTimeout(() => {
			this.setState({ show: true });
		}, 1);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({ 'page--show': this.state.show });

		return (
			<div
				className={`page ${this.props.pageName} ${classes} ${
					this.props.className ? this.props.className : ''
				}`}>
				<div className="kdb-logo kdb-logo--page" />

				{this.props.children}
			</div>
		);
	}

	/**
	 *
	 */
	static blurActiveElement() {
		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}
	}
}

Page.propTypes = {
	pageName: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
