// Replace dynamic values
export const dynamicValueTypes = {
	USER: 'user',
	COMPANY: 'company',
	CLIENT: 'client',
	OFFER: 'offer'
};

// TODO, move NON offer related labelId's to client/company/user dynamic values
const dynamicValues = {
	CLIENT_NAME: {
		tag: '{CONTACT_NAME}',
		labelId: 'invoice.dynamic.value.client_name.label',
		translationTag: '{client.contactPersonName}',
		type: dynamicValueTypes.CLIENT
	},
	CLIENT_COMPANY: {
		tag: '{CLIENT_COMPANY}',
		labelId: 'invoice.dynamic.value.client_company.label',
		translationTag: '{client.company}',
		type: dynamicValueTypes.CLIENT
	},
	OFFER_NR: {
		tag: '{OFFER_NR}',
		labelId: 'offer.dynamic.value.offer_nr.label',
		translationTag: '{offer.offerNr}',
		type: dynamicValueTypes.OFFER
	},
	OFFER_SUBJECT: {
		tag: '{OFFER_SUBJECT}',
		labelId: 'offer.dynamic.value.offer_subject.label',
		translationTag: '{offer.subject}',
		type: dynamicValueTypes.OFFER
	},
	OFFER_ISSUE_DATE: {
		tag: '{OFFER_ISSUE_DATE}',
		labelId: 'offer.dynamic.value.offer_date.label',
		translationTag: '{offer.date}',
		type: dynamicValueTypes.OFFER
	},
	OFFER_EXPIRATION_DATE: {
		tag: '{OFFER_EXPIRATION_DATE}',
		labelId: 'offer.dynamic.value.offer_expiration_date.label',
		translationTag: '{offer.expirationDate}',
		type: dynamicValueTypes.OFFER
	},
	OFFER_PO_NR: {
		tag: '{OFFER_PO_NR}',
		labelId: 'offer.dynamic.value.offer_po_nr.label',
		translationTag: '{offer.poNr}',
		type: dynamicValueTypes.OFFER
	},
	OFFER_AMOUNT_TOTAL: {
		tag: '{OFFER_AMOUNT_TOTAL}',
		labelId: 'offer.dynamic.value.offer_amount_total.label',
		translationTag: '{offer.amountTotal}',
		type: dynamicValueTypes.OFFER
	},
	COMPANY_NAME: {
		tag: '{MY_COMPANY_NAME}',
		labelId: 'invoice.dynamic.value.company_name.label',
		translationTag: '{company.name}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_IBAN: {
		tag: '{MY_COMPANY_IBAN}',
		labelId: 'invoice.dynamic.value.company_iban.label',
		translationTag: '{company.iban}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_BTW: {
		tag: '{MY_COMPANY_BTW}',
		labelId: 'invoice.dynamic.value.company_btw.label',
		translationTag: '{company.btw}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_KVK: {
		tag: '{MY_COMPANY_KVK}',
		labelId: 'invoice.dynamic.value.company_kvk.label',
		translationTag: '{company.kvk}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_WEBSITE: {
		tag: '{MY_COMPANY_WEBSITE}',
		labelId: 'invoice.dynamic.value.company_website.label',
		translationTag: '{company.website}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_PHONE: {
		tag: '{MY_COMPANY_PHONE}',
		labelId: 'invoice.dynamic.value.company_phone.label',
		translationTag: '{company.phoneNumber}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_CITY: {
		tag: '{MY_COMPANY_CITY}',
		labelId: 'invoice.dynamic.value.company_city.label',
		translationTag: '{company.city}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_ADDRESS: {
		tag: '{MY_COMPANY_ADDRESS}',
		labelId: 'invoice.dynamic.value.company_address.label',
		translationTag: '{company.address}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_POSTALCODE: {
		tag: '{MY_COMPANY_POSTALCODE}',
		labelId: 'invoice.dynamic.value.company_postalcode.label',
		translationTag: '{company.postalCode}',
		type: dynamicValueTypes.COMPANY
	},
	USER_FIRSTNAME: {
		tag: '{MY_FIRSTNAME}',
		labelId: 'invoice.dynamic.value.user_firstname.label',
		translationTag: '{user.firstName}',
		type: dynamicValueTypes.USER
	},
	USER_LASTNAME: {
		tag: '{MY_LASTNAME}',
		labelId: 'invoice.dynamic.value.user_lastname.label',
		translationTag: '{user.lastName}',
		type: dynamicValueTypes.USER
	}
};

export default dynamicValues;

/**
 *
 * @param user
 * @param company
 * @param offerModel
 * @param intl
 */
export function createDynamicValues(user, company, offerModel, intl) {
	const values = {};

	values[dynamicValues.CLIENT_NAME.translationTag] = offerModel.companyCustomer
		? offerModel.companyCustomer.contactPersonName
		: '';
	values[dynamicValues.CLIENT_COMPANY.translationTag] = offerModel.companyCustomer
		? offerModel.companyCustomer.getCustomerName()
		: '';

	values[dynamicValues.OFFER_NR.translationTag] = offerModel.offerNr;
	values[dynamicValues.OFFER_SUBJECT.translationTag] = offerModel.subject;
	values[dynamicValues.OFFER_ISSUE_DATE.translationTag] = intl.formatDate(offerModel.date);
	values[dynamicValues.OFFER_EXPIRATION_DATE.translationTag] = intl.formatDate(
		offerModel.expirationDate
	);
	values[dynamicValues.OFFER_PO_NR.translationTag] = offerModel.projectcode;
	values[dynamicValues.OFFER_AMOUNT_TOTAL.translationTag] = intl.formatNumber(
		offerModel.getTotal(),
		{ style: 'currency', currency: 'EUR' }
	);

	values[dynamicValues.COMPANY_NAME.translationTag] = company.name;
	values[dynamicValues.COMPANY_IBAN.translationTag] = company.iban;

	if (company.useBTWId(offerModel.date)) {
		values[dynamicValues.COMPANY_BTW.translationTag] = company.vatId;
	} else {
		values[dynamicValues.COMPANY_BTW.translationTag] = company.vatNumber;
	}

	values[dynamicValues.COMPANY_KVK.translationTag] = company.kvkNumber;
	values[dynamicValues.COMPANY_WEBSITE.translationTag] = company.website;
	values[dynamicValues.COMPANY_PHONE.translationTag] = company.phoneNumber;
	values[dynamicValues.COMPANY_CITY.translationTag] = company.address.city;
	values[dynamicValues.COMPANY_ADDRESS.translationTag] = company.address.address;
	values[dynamicValues.COMPANY_POSTALCODE.translationTag] = company.address.postalCode;

	values[dynamicValues.USER_FIRSTNAME.translationTag] = user.firstName;
	values[dynamicValues.USER_LASTNAME.translationTag] = user.lastName;

	return values;
}

/**
 *
 * @param text
 * @param values
 * @param intl
 * @returns {*}
 * @private
 */
export function addDynamicValues(text, values, intl) {
	console.log('OfferDynamicValues.addDynamicValues', text, values);

	// Convert translation object to String
	if (typeof text === 'object') {
		text = intl.formatMessage(text);
	}

	for (const key in values) {
		if (values.hasOwnProperty(key)) {
			const value = values[key] ? values[key] : ''; // filter out undefined or null
			text = text.split(`${key}`).join(value);
		}
	}

	return text;
}

/**
 *
 * @param value
 * @private
 */
export function convertTranslationsToTags(value) {
	for (const key in dynamicValues) {
		if (dynamicValues.hasOwnProperty(key)) {
			value = value.split(dynamicValues[key].translationTag).join(dynamicValues[key].tag);
		}
	}

	return value;
}

/**
 *
 * @return {*}
 * @private
 */
export function convertTagsToTranslations(value) {
	if (typeof value !== 'string') {
		return value;
	}

	for (const key in dynamicValues) {
		if (dynamicValues.hasOwnProperty(key)) {
			value = value.split(dynamicValues[key].tag).join(dynamicValues[key].translationTag);
		}
	}
	return value;
}
