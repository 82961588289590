import AbstractCommand from './AbstractCommand';

import GetUserDocumentTypeCommand from './GetUserDocumentTypeCommand';
import GetAnnualStatementCommand from './GetAnnualStatementCommand';
import GetUserDraftsCommand from './drafts/GetUserDraftsCommand';

/**
 *
 */
export default class FetchStartDataCommand extends AbstractCommand {
	/**
	 *
	 * @param applicationStore ApplicationStore
	 */
	constructor(applicationStore) {
		super();

		this.applicationStore = applicationStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		console.log('FetchStartDataCommand.execute');

		this.successCallback = success;
		this.errorCallback = error;

		this._fetchAllCompanies();
	}

	/**
	 *
	 * @private
	 */
	_fetchAllCompanies() {
		console.log('FetchStartDataCommand._fetchAllCompanies');

		try {
			const promises = this.applicationStore.user.companies.map((company) => {
				return company.fetch();
			});

			Promise.all(promises)
				.then(() => {
					this._checkUserPassport();
				})
				.catch((err) => {
					if (this.errorCallback) {
						this.errorCallback(err);
					}
				});
		} catch (err) {
			if (this.errorCallback) {
				this.errorCallback(err);
			}
		}
	}

	/**
	 *
	 * @private
	 */
	_checkUserPassport() {
		console.log('FetchStartDataCommand._checkUserPassport');
		const getUserDocumentTypeCommand = new GetUserDocumentTypeCommand(this.applicationStore.user);
		getUserDocumentTypeCommand.execute(() => {
			this._getAnnualStatement();
		});
	}

	/**
	 *
	 * @private
	 */
	_getAnnualStatement() {
		console.log('FetchStartDataCommand._getAnnualStatement');

		const getAnnualStatementCommand = new GetAnnualStatementCommand(
			this.applicationStore.annualStatementStore
		);
		getAnnualStatementCommand.execute(() => {
			this._getUserDrafts();
		});
	}

	/**
	 *
	 * @private
	 */
	_getUserDrafts() {
		console.log('FetchStartDataCommand._getUserDrafts');

		const { draftsStore, id } = this.applicationStore.user;

		const getUserDraftsCommand = new GetUserDraftsCommand(draftsStore, id);
		getUserDraftsCommand.execute(() => {
			// Done! Call successCallback
			if (this.successCallback) {
				this.successCallback();
			}
		});
	}
}
