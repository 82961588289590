import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import UserMessages from './UserMessages';
import Signals from '../../../signals/Signals';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
@observer
export default class UserMessagesDialog extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { messages: [] };

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.setState({ userMessagesStore: this.context.applicationStore.userMessagesStore });
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.ShowUserMessagesDialog.add(this.show);
		Signals.HideUserMessagesDialog.add(this.hide);
	}

	componentWillUnmount() {
		Signals.ShowUserMessagesDialog.remove(this.show);
		Signals.HideUserMessagesDialog.remove(this.hide);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const show = this.state.messages.length > 0;
		const classes = classNames({
			'user-messages-dialog': true,
			'user-messages-dialog--show': show,
			'user-messages-dialog--hide': !show
		});

		// User Messages
		return (
			<div className={classes}>
				<div className="user-messages-dialog__background" />
				<div className="user-messages-dialog__container">
					<UserMessages messages={this.state.messages} />
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param messages
	 */
	show(messages) {
		this.setState({ messages });
	}

	/**
	 *
	 */
	hide() {
		this.setState({ messages: [] });
	}
}

UserMessagesDialog.contextType = ApplicationContext;

UserMessagesDialog.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};
