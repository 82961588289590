import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import OfferStatus from '../../data/OfferStatus';

import offerUnarchive from '../../requests/offers/offerUnarchive';

/**
 *
 */
export default class ArchiveOfferCommand extends AbstractCommand {

	/**
	 *
	 * @param offerModel OfferModel
	 * @param company Company
	 */
	constructor(offerModel, company) {
		super();

		this.offerModel = offerModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return offerUnarchive(this.company.id, this.offerModel.id)
			.then((response) => {

				this.offerModel.status = OfferStatus.SIGNED;
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="offer.unarchived.message"
								   values={{offerNr: this.offerModel.offerNr}}/>)
		);
	}
}
