/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedDate, FormattedNumber } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../../signals/Signals';
import { defaultDateFormatOptions } from '../../../../data/Settings';
import { Routes } from '../../../../data/Routes';
import OfferStatus from '../../../../data/OfferStatus';
import OfferModel from '../../../../stores/models/OfferModel';

import Tag from '../../../../components/ui/Tag/Tag';
import OfferActions from '../../../../components/ui/ToolTip/tooltips/OfferActions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';

import { ApplicationContext } from '../../../../ApplicationContext';
import DeleteOfferCommand from '../../../../commands/offers/DeleteOfferCommand';
import ArchiveOfferCommand from '../../../../commands/offers/ArchiveOfferCommand';
import UnarchiveOfferCommand from '../../../../commands/offers/UnarchiveOfferCommand';
import CreateInvoiceFromOfferCommand from '../../../../commands/offers/CreateInvoiceFromOfferCommand';
import { getRelativeDays } from '../../../../utils/dateUtils';

@observer
/**
 *
 */
class OffersRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onFieldClick = this.onFieldClick.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.offersStore = this.props.offerModel.isArchived()
			? this.context.applicationStore.offersArchiveStore
			: this.context.applicationStore.offersStore;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const sortBy = this.offersStore.sortBy;
		const sortByClasses = sortBy ? `offers-row--sorted-by--${`${sortBy}`.toLowerCase()}` : '';
		const status = `${this.props.offerModel.status}`.toLowerCase();

		const classes = classNames({
			'table-row--can-edit': this.props.offerModel.canEdit()
		});

		return (
			<tr className={`table-row ${sortByClasses} offers-row--${status} ${classes}`}>
				<td
					className="table-row__column offers-row__status"
					onClick={() => this.onFieldClick('status')}>
					<div className="grid">
						<Tag labelId={`tag-label.${status}`} type={status} />
						<div className="offers-row__status-date">{this._getStatusDateValue()}</div>
					</div>
				</td>
				<td
					className="table-row__column table-row__column--editable offers-row__date"
					onClick={() => this.onFieldClick('date')}>
					<FormattedDate value={this.props.offerModel.date} {...defaultDateFormatOptions} />
				</td>
				<td
					className="table-row__column table-row__column--editable offers-row__offernr"
					onClick={() => this.onFieldClick('offerNr')}>
					{this.props.offerModel.offerNr}
				</td>
				<td
					className="table-row__column table-row__column--editable offers-row__companyname"
					onClick={() => this.onFieldClick('companyCustomerId')}>
					<Truncate>
						{this.props.offerModel.companyCustomer
							? this.props.offerModel.companyCustomer.getCustomerName()
							: '-'}
					</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable offers-row__subject"
					onClick={() => this.onFieldClick('subject')}>
					<Truncate>{this.props.offerModel.subject}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable offers-row__amount text--right"
					onClick={() => this.onFieldClick('unitPrice')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.offerModel.getTotal()}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column table-row__column--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();

		const hideTypes = [];

		/**
		 {type:'edit', icon:'edit', label:'label.edit'},
		 {type:'duplicate', icon:'file', label:'label.duplicate'},
		 {type:'prepare', icon:'upload', label:'label.prepare'},
		 {type:'view', icon:'eye2', label:'label.view'},
		 {type:'remove', icon:'delete', label:'label.remove'},
		 {type:'archive', icon:'folder2', label:'label.archive'}
		 */
		switch (this.props.offerModel.status) {
			case OfferStatus.ARCHIVED:
				hideTypes.push('edit'); // Cannot edit this Invoice
				hideTypes.push('prepare'); // Invoice already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this invoice
				hideTypes.push('archive'); // Cannot archive this invoice
				break;
			case OfferStatus.DELETED:
				hideTypes.push('view');
				hideTypes.push('edit'); // Cannot edit this Invoice
				hideTypes.push('prepare'); // Invoice already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this invoice
				hideTypes.push('unarchive'); // Cannot unarchive this invoice
				hideTypes.push('invoice'); // Hide view, Offer has to be send first
				break;
			case OfferStatus.LATE:
			case OfferStatus.SENT:
			case OfferStatus.SENT_MANUALLY:
			case OfferStatus.SENT_REMINDER:
				hideTypes.push('edit'); // Cannot edit this Offer
				hideTypes.push('prepare');
				// hideTypes.push('prepare'); // Offer already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this Offer
				hideTypes.push('archive'); // Offer pending, can't archive
				hideTypes.push('unarchive'); // Offer pending, can't archive
				hideTypes.push('invoice'); // Offer pending, can't archive
				break;
			case OfferStatus.CREATED:
				hideTypes.push('view');
				hideTypes.push('archive'); // Hide view, Offer has to be send first
				hideTypes.push('unarchive'); // Hide view, Offer has to be send first
				hideTypes.push('invoice'); // Hide view, Offer has to be send first
				break;
			case OfferStatus.SIGNED:
			case OfferStatus.DECLINED:
			case OfferStatus.INVOICED:
				hideTypes.push('prepare');
				hideTypes.push('edit'); // Cannot edit this Offer
				hideTypes.push('unarchive'); // Hide view, Offer has to be send first
				hideTypes.push('invoice'); // Hide view, already invoiced
				break;
			default:
				break;
		}

		Signals.ShowToolTip.dispatch(
			<OfferActions onSelect={this.onActionSelect} hideTypes={hideTypes} />,
			e.currentTarget,
			-16
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'prepare':
			case 'view':
				this._prepare();
				break;
			case 'remove':
				this._remove();
				break;
			case 'edit':
				this._edit();
				break;
			case 'duplicate':
				this._duplicate();
				break;
			case 'archive':
				this._archive();
				break;
			case 'unarchive':
				this._unarchive();
				break;
			case 'invoice':
				this._invoice();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param field
	 */
	onFieldClick(_field) {
		this._prepare();
	}

	/**
	 *
	 * @private
	 */
	_remove() {
		const title = this.props.intl.formatMessage(
			{ id: 'offer.remove.alert.title' },
			{
				description: this.props.offerModel.description,
				customer: this.props.offerModel.companyCustomer
					? this.props.offerModel.companyCustomer.companyName
					: '-'
			}
		);

		const body = null;

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={() => this._doRemove(this)}
				body={body}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeleteOfferCommand(this.offersStore, this.props.offerModel, company);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_prepare() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_OFFERS_OFFER.getPath({ id: company.id, offerId: this.props.offerModel.id })
			);
		}
	}

	/**
	 *
	 * @param field
	 * @private
	 */
	_edit(_field = null) {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// Jump to send screen
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_OFFERS_EDIT.getPath({
					id: company.id,
					offerId: this.props.offerModel.id
				})
			);
		}
	}

	/**
	 *
	 * @private
	 */
	_duplicate() {
		const company = this.context.applicationStore.getSelectedCompany();
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_OFFERS_DUPLICATE.getPath({
				id: company.id,
				offerId: this.props.offerModel.id
			})
		);
	}

	/**
	 *
	 * @private
	 */
	_archive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new ArchiveOfferCommand(this.props.offerModel, company);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_unarchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new UnarchiveOfferCommand(this.props.offerModel, company);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_invoice() {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new CreateInvoiceFromOfferCommand(this.props.offerModel, company);
		command.execute();
	}

	/**
	 *
	 * @return {string}
	 * @private
	 */
	_getStatusDateValue() {
		let value = '';
		if (!this.props.offerModel.expirationDate) {
			return value;
		}

		const settings = {
			unit: 'day',
			options: {
				style: 'long',
				localeMatcher: 'best fit'
			}
		};

		switch (this.props.offerModel.status) {
			case OfferStatus.SENT_REMINDER_KDB:
			case OfferStatus.LATE:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.offerModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'offers.statusdate.late' }, { date: value });
				break;
			case OfferStatus.SENT_MANUALLY:
			case OfferStatus.SENT:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.offerModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'offers.statusdate.send' }, { date: value });
				break;
			default:
				value = null;
		}

		return value;
	}
}

OffersRow.contextType = ApplicationContext;

OffersRow.propTypes = {
	offerModel: PropTypes.instanceOf(OfferModel).isRequired,
	isArchive: PropTypes.bool,
	intl: PropTypes.object
};

export default injectIntl(OffersRow);
