import React from 'react';
import PropTypes from 'prop-types';

function LinkUnlink({ width = 14, height = 14, color = '#F4F4F5', fill = '#213643', ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={`0 0  ${width} ${height}`}
			{...props}>
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
				transform="translate(-1101 -646)">
				<path fill={color} d="M0 0H1440V1330H0z" />
				<g fill={fill} fillRule="nonzero" transform="translate(1083 567.544)">
					<g transform="translate(17 77.456) translate(1.255 1.255)">
						<path d="M11.49 10.727l-.545-.545a.527.527 0 00-.763 0 .527.527 0 000 .763l.545.546a.59.59 0 00.382.164.59.59 0 00.382-.164.527.527 0 000-.764zM7.836 12.2c0 .327.219.545.546.545.327 0 .545-.218.545-.545v-1.09c0-.328-.218-.546-.545-.546-.327 0-.546.218-.546.545V12.2zM12.2 7.836h-1.09c-.328 0-.546.219-.546.546 0 .327.218.545.545.545H12.2c.327 0 .545-.218.545-.545 0-.327-.218-.546-.545-.546zM13.49 2.782c0-.764-.272-1.418-.817-1.964A2.678 2.678 0 0010.709 0c-.764 0-1.418.273-1.964.818l-1.2 1.2a.527.527 0 000 .764.527.527 0 00.764 0l1.2-1.2a1.712 1.712 0 012.4 0c.327.327.491.763.491 1.2 0 .436-.164.873-.49 1.2l-1.2 1.2a.527.527 0 000 .763.59.59 0 00.38.164.59.59 0 00.383-.164l1.2-1.2c.545-.545.818-1.2.818-1.963zM0 10.71c0 .763.273 1.417.818 1.963.546.545 1.2.818 1.964.818.763 0 1.418-.273 1.963-.818l1.2-1.2a.527.527 0 000-.764.527.527 0 00-.763 0l-1.2 1.2a1.712 1.712 0 01-2.4 0 1.692 1.692 0 01-.491-1.2c0-.436.164-.873.49-1.2l1.2-1.2a.527.527 0 000-.764.527.527 0 00-.763 0l-1.2 1.2A2.678 2.678 0 000 10.71zM3.31 2.545L2.763 2A.527.527 0 002 2a.527.527 0 000 .764l.545.545c.11.11.219.164.382.164a.495.495 0 00.382-.164.527.527 0 000-.764zM5.655 2.382V1.29c0-.327-.2-.546-.5-.546s-.5.219-.5.546v1.09c0 .328.2.546.5.546s.5-.218.5-.545zM2.382 5.655c.327 0 .545-.219.545-.546 0-.327-.218-.545-.545-.545H1.29c-.327 0-.546.218-.546.545 0 .327.219.546.546.546h1.09z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

LinkUnlink.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
	fill: PropTypes.string
};

export default LinkUnlink;
