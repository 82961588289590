import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, customerId, data) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		EndPoints.COMPANY_CUSTOMERS_ID.replace(':id', companyId).replace(':customerId', customerId),
		data,
		headers,
		true
	);
};
