import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { stripProtocolFromUrl } from '../../../utils/objectToFormData';

import CommunityProfile from '../../../stores/models/CommunityProfile';

import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';
import Company from '../../../stores/models/Company';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
export default class CommunityProfileBlock extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.preloadImage = null;
		this.state = {
			link: Routes.COMPANY_COMMUNITY_PROFILE.getPath({
				id: this.props.company.id,
				profileId: this.props.communityProfile.id
			}),
			coverImageUrl: CommunityProfile.buildAsset(
				null,
				this.props.communityProfile.coverAssetUuid,
				290 * 2,
				172 * 2
			),
			logoImageUrl: CommunityProfile.buildAsset(
				null,
				this.props.communityProfile.logoAssetUuid,
				96 * 2,
				96 * 2
			),
			imageLoaded: false
		};

		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this._ismounted = true;

		try {
			this.preloadImage = new Image();
			this.preloadImage.onload = this.onImageLoaded;
			this.preloadImage.src = this.state.coverImageUrl; // TODO: build coverAssetUuid url
		} catch (e) {
			console.log(e);
		}
	}

	/**
	 *
	 */
	componentWillUnmount() {
		this._ismounted = false;
	}

	/**
	 *
	 */
	render() {
		const imageClasses = classNames({
			'community-profile-block__image--loaded': this.state.imageLoaded
		});

		return (
			<a
				className={`community-profile-block ${this.props.className}`}
				href={this.state.link}
				target="_blank"
				onClick={this.onLinkClick}
				rel="noreferrer">
				<div className="community-profile-block__content-wrapper">
					<div
						className={`community-profile-block__image ${imageClasses}`}
						style={{ backgroundImage: `url("${this.state.coverImageUrl}")` }}>
						<div className="community-profile-block__cover-gradient" />

						<div className="community-profile-block__logo-title-wrapper grid">
							<div
								className="community-profile-block__logo"
								style={{ backgroundImage: `url("${this.state.logoImageUrl}")` }}
							/>
							<div className="community-profile-block__titles">
								<h2 className="community-profile-block__name">
									{this.props.communityProfile.name}
								</h2>
								<span className="community-profile-block__category">
									{this._getCategoryName(this.props.communityProfile.category)}
								</span>
							</div>
						</div>
					</div>

					<div className="community-profile-block__info-wrapper grid grid--spread">
						<div
							className="community-profile-block__description"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: this._getIntroText() }}
						/>
						<div className="community-profile-block__contact">
							<div className="community-profile-block__website">
								{stripProtocolFromUrl(this.props.communityProfile.website)}
							</div>
						</div>
					</div>
				</div>
			</a>
		);
	}

	/**
	 *
	 */
	onImageLoaded() {
		if (this._ismounted) {
			this.setState({ imageLoaded: true });
		}
	}

	/**
	 *
	 * @param event
	 */
	onLinkClick(event) {
		event.preventDefault();
		Signals.RequestRoute.dispatch(this.state.link);
	}

	/**
	 *
	 * @param categoryId
	 * @returns {*}
	 * @private
	 */
	_getCategoryName(categoryId) {
		const user = this.context.applicationStore.user;
		const category =
			this.context.applicationStore.communityCategoriesStore.getCategoryById(categoryId);
		if (user && category) {
			return category.getName(user.language);
		}

		return null;
	}

	_getIntroText() {
		const description = this.props.communityProfile.description;
		if (description) {
			const maxChars = 140;
			const trimmed =
				description.length > maxChars
					? `${description.substring(0, maxChars - 3)}...`
					: description;

			return trimmed.replace(/(?:\r\n|\r|\n)/g, '<br>');
		}

		return null;
	}
}

CommunityProfileBlock.contextType = ApplicationContext;

CommunityProfileBlock.propTypes = {
	className: PropTypes.string,
	company: PropTypes.instanceOf(Company).isRequired,
	communityProfile: PropTypes.instanceOf(CommunityProfile).isRequired
};

CommunityProfileBlock.defaultProps = {
	className: ''
};
