export default {
	CREATED: 'CREATED',
	SENT_MANUALLY: 'SENT_MANUALLY',
	SENT_BY_EMAIL_KDB: 'SENT_BY_EMAIL_KDB',
	SENT_REMINDER_KDB: 'SENT_REMINDER_KDB',
	LATE: 'LATE',
	PAID: 'PAID',
	DELETED: 'DELETED',
	ARCHIVED: 'ARCHIVED'
};
