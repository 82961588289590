/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

import FormField from '../../../components/ui/FormField/FormField';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import Checkbox from '../../../components/ui/Checkbox/Checkbox';

import { ApplicationContext } from '../../../ApplicationContext';
import { FormErrorContext } from '../../../FormErrorContext';

import UpdateInvoiceSettingsCommand from '../../../commands/invoiceConcepts/UpdateInvoiceSettingsCommand';

/**
 *
 */
class InvoicesSettingsGeneric extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { errors: [] };

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		// Set initial state
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.setState(company.invoicesSettings.toObject());
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();

		if (!this.context.applicationStore.isLoggedIn || !company) {
			return null;
		}

		//
		const { intl } = this.props;
		const { submitting, errors } = this.state;
		const formClasses = classNames({ 'form--submitting': submitting });

		return (
			<FormErrorContext.Provider value={{ errors }}>
				<form
					className={`invoices-settings__panel panel grid col--12 box-shadow border border--dark ${formClasses}`}
					onSubmit={this.onSubmit}
					onChange={this.onInputChange}>
					<div className="invoices-settings__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="invoices.settings.title.generic" />
						</h2>
					</div>
					<div className="invoices-settings__content">
						{this._getGenericSettings()}

						<div className="options-footer generic-settings__footer border--top border--dark grid grid--spread">
							<div className="grid user-info__footer-buttons" />
							<input type="submit" value={intl.formatMessage({ id: 'label.save' })} />
						</div>
					</div>
				</form>
			</FormErrorContext.Provider>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const newState = {};
		switch (e.target.name) {
			case 'bccRecipientEmail':
				// eslint-disable-next-line no-case-declarations
				const company = this.context.applicationStore.getSelectedCompany();
				newState[e.target.name] = e.target.checked ? company.email : '';
				break;
			case 'remindCompanyUserInDays':
				newState[e.target.name] = e.target.checked ? -1 : null;
				break;
			default:
				newState[e.target.name] = e.target.value;
		}

		this.setState(newState);
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		this.setState({ errors: null, submitting: true });

		const company = this.context.applicationStore.getSelectedCompany();
		const command = new UpdateInvoiceSettingsCommand(this.props.intl, company, this.state);
		command.execute(this.onSubmitSuccess, this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		// Display success message
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage id="invoice.settings.update.success.message" />
		);

		// Reset state
		this.setState({ submitting: false, errors: null });
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onMollieConnect() {
		// eslint-disable-next-line no-alert
		alert('mollie connect');
	}

	/**
	 *
	 * @private
	 */
	_getGenericSettings() {
		const company = this.context.applicationStore.getSelectedCompany();

		return (
			<FormGroup className="padding padding-none--bottom">
				<FormField className="col--6 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.sender.email" />
					</label>
					<input
						className="col--12"
						type="email"
						name="senderEmail"
						value={this.state.senderEmail}
					/>
				</FormField>
				<FormField className="col--12">
					<Checkbox
						className="col--12"
						name="remindCompanyUserInDays"
						label={
							<FormattedHTMLMessage
								id="invoice.settings.remind.user.label"
								values={{ email: this.state.senderEmail }}
							/>
						}
						checked={this.state.remindCompanyUserInDays !== null}
						onChange={this.onInputChange}
					/>
					<Checkbox
						className="col--12"
						name="bccRecipientEmail"
						label={
							<FormattedHTMLMessage
								id="invoice.settings.sendcopy"
								values={{ email: company.email }}
							/>
						}
						checked={this.state.bccRecipientEmail !== ''}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}
}

InvoicesSettingsGeneric.contextType = ApplicationContext;

InvoicesSettingsGeneric.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(InvoicesSettingsGeneric);
