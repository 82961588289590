import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import { Routes } from '../../data/Routes';

/**
 *
 */
export default class CreateInvoiceFromOfferCommand extends AbstractCommand {
	/**
	 *
	 * @param offerModel OfferModel
	 * @param company Company
	 */
	constructor(offerModel, company) {
		super();

		this.offerModel = offerModel;
		this.company = company;
	}

	/**
	 *
	 */
	execute(_success, _error) {
		const route = Routes.COMPANY_INVOICES_OFFER.getPath({
			id: this.company.id,
			offerId: this.offerModel.id
		});
		Signals.RequestRoute.dispatch(route);
	}
}
