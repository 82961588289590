/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedDate, FormattedNumber, injectIntl } from 'react-intl';

import { getTotalAmountExclBTW, getTotalAmountInclBTW } from '../../../../utils/btwUtils';

import DraftsStore from '../../../../stores/DraftsStore';
import Draft from '../../../../stores/models/Draft';

import { defaultDateFormatOptions } from '../../../../data/Settings';

import Signals from '../../../../signals/Signals';
import Tag from '../../../../components/ui/Tag/Tag';
import Actions from '../../../../components/ui/ToolTip/tooltips/Actions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';
import { SourceIcon } from '../../../../components/ui/SourceIcon/SourceIcon';

import { ApplicationContext } from '../../../../ApplicationContext';
import IncomeModel from '../../../../stores/models/IncomeModel';
import IncomeInputPanel from './modals/IncomeInputPanel';

import DeleteCompanyDraftCommand from '../../../../commands/drafts/DeleteCompanyDraftCommand';
import { uniqueKey } from '../../../../utils/ReactUtils';
import Checkbox from '../../../../components/ui/Checkbox/Checkbox';

/**
 *
 */
@observer
class IncomeDraftRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.edit = this.edit.bind(this);
		this.remove = this.remove.bind(this);

		this.onFieldClick = this.onFieldClick.bind(this);
		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onFileClick = this.onFileClick.bind(this);
		this.onSelectedChange = this.onSelectedChange.bind(this);

		this.state = { checkboxSelected: false };
	}

	/**
	 *
	 */
	componentDidMount() {
		const { draft } = this.props;
		if (draft.isNew) {
			setTimeout(() => {
				draft.isNew = false;
			}, 600);
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		//
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		const { draft, intl } = this.props;
		const { checkboxSelected } = this.state;
		const incomeModel = IncomeModel.createNewIncomeFromDraft(draft);

		//
		const classes = classNames({
			'table-row__locked':
				!financialYear.isActive() ||
				this.context.applicationStore.yearResultStore.hasLockedDeadlines(),
			'income-draft-row--is-new': draft.isNew
		});

		// Determine BTW label
		const btwLabel = intl.formatMessage({ id: incomeModel.getBTWTranslationId() });

		return (
			<tr className={`income-draft-row table-row ${classes}`}>
				<td className="table-row__column table-row__column--editable income-row__invoicenr">
					<div className="grid grid--no-wrap grid--vcenter">
						<SourceIcon type={draft.source} onClick={() => this.onFieldClick('invoiceNr')} />

						<Checkbox
							onChange={this.onSelectedChange}
							name={`draft-${draft.id}`}
							checked={checkboxSelected}
						/>
					</div>
				</td>
				<td
					className="table-row__column  table-row__column--editable income-row__date"
					onClick={() => this.onFieldClick('date')}>
					<FormattedDate
						value={incomeModel.date ? incomeModel.date : draft.createdAt}
						{...defaultDateFormatOptions}
					/>
				</td>
				<td
					className="table-row__column table-row__column--editable income-row__customername"
					onClick={() => this.onFieldClick('customerName')}>
					<Truncate>{incomeModel.customerName}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable income-row__description"
					onClick={() => this.onFieldClick('description')}>
					<Truncate>{incomeModel.description}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--center income-row__btw"
					onClick={() => this.onFieldClick('btwType')}>
					{btwLabel}
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right income-row__exclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountExclBTW(incomeModel.invoiceRows, incomeModel.date)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right income-row__inclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountInclBTW(incomeModel.invoiceRows)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className={`table-row__column table-row__column--center income-row__filename ${
						draft.assetUuid ? 'icon icon--check-smoke' : ''
					}`}
					onClick={this.onFileClick}
				/>
				<td
					className="table-row__column table-row__column--editable table-row__column--center income-row__state"
					onClick={() => this.onFieldClick('state')}>
					<Tag labelId="tag-label.draft" type="draft" values={{ year: financialYear.year }} />
				</td>
				<td
					className="table-row__column table-row__column--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
					<div className="table-row__actions--locked icon icon--lock-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param field
	 */
	onFieldClick(field) {
		this.edit(field);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();
		Signals.ShowToolTip.dispatch(
			<Actions onSelect={this.onActionSelect} hideTypes={['duplicate']} />,
			e.currentTarget,
			-16
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'remove':
				this.remove();
				break;
			case 'edit':
				this.edit();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 */
	onFileClick() {}

	/**
	 *
	 * @param e
	 */
	onSelectedChange(_e) {
		const { onSelect, onDeselect, draft } = this.props;
		const { checkboxSelected } = this.state;
		if (!checkboxSelected && onSelect) {
			onSelect(draft);
		}
		if (checkboxSelected && onDeselect) {
			onDeselect(draft);
		}

		this.setState({ checkboxSelected: !checkboxSelected });
	}

	/**
	 *
	 */
	remove() {
		const { intl, draft } = this.props;
		const title = intl.formatMessage({ id: 'draft.remove.alert.title' }, { id: draft.id });
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
				onConfirm={() => this.doRemove()}
			/>
		);
	}

	/**
	 *
	 * @param fieldToFocus
	 */
	edit(fieldToFocus) {
		const { draft } = this.props;

		// Create copy to prevent unwanted changes to original
		const incomeModel = IncomeModel.createNewIncomeFromDraft(draft);

		// Show IncomeInput overlay
		Signals.ShowOverlay.dispatch(
			<IncomeInputPanel
				key={uniqueKey('iip-')}
				incomeModel={incomeModel}
				draft={draft}
				fieldToFocus={fieldToFocus}
			/>
		);
	}

	/**
	 *
	 */
	doRemove() {
		/**
		 * draftsStore, companyId, draft
		 * @type {DeleteCompanyDraftCommand}
		 */
		const { draft, draftsStore } = this.props;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft);
		command.execute();
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	static getInvoiceNrOrDraftId(draft, incomeModel) {
		if (incomeModel.invoiceNr) {
			return incomeModel.invoiceNr;
		}

		return <span className="income-draft__id">#{draft.id}</span>;
	}
}

IncomeDraftRow.contextType = ApplicationContext;

IncomeDraftRow.propTypes = {
	draft: PropTypes.instanceOf(Draft).isRequired,
	draftsStore: PropTypes.instanceOf(DraftsStore).isRequired,
	intl: PropTypes.object,
	onSelect: PropTypes.func,
	onDeselect: PropTypes.func
};

export default injectIntl(IncomeDraftRow);
