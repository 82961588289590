import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { BsChevronDown } from 'react-icons/bs';
import classNames from 'classnames';
import useBankCategoryStore from '../../../../../utils/useBankCategoryStore';
// eslint-disable-next-line no-unused-vars
import BankCategoryStore from '../../../../../stores/BankCategoryStore';
import Signals from '../../../../../signals/Signals';
import CategoryPicker from '../CategoryPicker/CategoryPicker';

function Category({ categoryId, onCategorySelect, bankRecordMatchStore, disabled = false }) {
	/**
	 * @type {BankCategoryStore}
	 */
	const bankCategoryStore = useBankCategoryStore();
	const ref = useRef(null);
	const [isClicked, setIsClicked] = React.useState(false);
	const [target, setTarget] = React.useState(null);

	const categorySelect = (category) => {
		onCategorySelect(category);
		setIsClicked(false);
		// Label as matched sourced as editted.
		bankRecordMatchStore.setIsEditted(true);
	};

	useEffect(() => {
		if (isClicked) {
			Signals.ShowToolTip.dispatch(
				<CategoryPicker
					categories={bankCategoryStore.list}
					bankCategoryMap={bankCategoryStore.bankCategoryMap}
					bankCategoryLabels={bankCategoryStore.bankCategoryLabels}
					selectedCategoryId={categoryId}
					onSelect={categorySelect}
				/>,
				target,
				10
			);
		} else {
			Signals.HideToolTip.dispatch();
		}
	}, [isClicked, target]);

	const handleClick = (e) => {
		if (disabled) return;
		e.preventDefault();
		e.stopPropagation();
		setTarget(e.currentTarget);
		setIsClicked((prev) => !prev);
	};

	const getLabel = (categoryId) => {
		if (!categoryId) return '';
		const category = bankCategoryStore.getCategoryById(categoryId);
		if (category.isHidden) {
			return '';
		}
		return category.label;
	};

	return (
		<Observer>
			{() => {
				if (bankCategoryStore && bankCategoryStore.fetched) {
					const categoryLabel = getLabel(categoryId, disabled);
					return (
						<div
							className={classNames('transaction-category', {
								'transaction-category--disabled': disabled
							})}>
							<div className="transaction-categorise" onClick={handleClick} ref={ref}>
								{categoryLabel || <FormattedMessage id="transaction.category.select" />}
								{!disabled && <BsChevronDown />}
							</div>
						</div>
					);
				}
				return null;
			}}
		</Observer>
	);
}

Category.propTypes = {
	categoryId: PropTypes.string,
	onCategorySelect: PropTypes.func,
	bankRecordMatchStore: PropTypes.object,
	disabled: PropTypes.bool
};

export default Category;
