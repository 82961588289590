import React from 'react';
import PropTypes from 'prop-types';

import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
export default class AutocompleteResultRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onSelect = this.onSelect.bind(this);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps() {}

	/**
	 *
	 */
	render() {
		return (
			<div className="autocomplete-result-row grid" onClick={this.onSelect}>
				{this.props.children}
			</div>
		);
	}

	/**
	 *
	 */
	onSelect() {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.rowData);
		}
	}
}

AutocompleteResultRow.contextType = ApplicationContext;

AutocompleteResultRow.propTypes = {
	rowData: PropTypes.object.isRequired,
	onSelect: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
