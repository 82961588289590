/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import Signals from '../../../../signals/Signals';

import Company from '../../../../stores/models/Company';
import CompanyBrandModel from '../../../../stores/models/CompanyBrandModel';
import CompanyBrand from '../../../../components/ui/CompanyBrand/CompanyBrand';
import Actions from '../../../../components/ui/ToolTip/tooltips/Actions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import DeleteCompanyBrandCommand from '../../../../commands/brands/DeleteCompanyBrandCommand';
import CompanyBrandInput from '../modals/CompanyBrandInput';
import CompanyInput from '../../Info/modals/CompanyInput';
import { ApplicationContext } from '../../../../ApplicationContext';
import { uniqueKey } from '../../../../utils/ReactUtils';

/**
 *
 */
class CompanyBrandWrapper extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { canEdit, className, label, company, companyBrand } = this.props;
		const actionClasses = classNames({
			'company-brand-wrapper__actions table-row__actions': true,
			'company-brand-wrapper__actions--disabled': !canEdit
		});
		return (
			<div className={`company-brand-wrapper ${className}`}>
				{label ? <label className="company-brand-wrapper__label">{label}</label> : null}
				<div className="grid grid--spread grid--no-wrap">
					<CompanyBrand companyBrand={companyBrand} company={company} />

					<div className={actionClasses} onClick={this.onActionClick}>
						<div className="icon icon--more-smoke" />
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();

		const { companyBrand } = this.props;
		const { applicationStore } = this.context;

		// If default, show edit CompanyInput modal
		if (companyBrand.id === -1) {
			const company = applicationStore.getSelectedCompany();
			Signals.ShowOverlay.dispatch(<CompanyInput key={uniqueKey('ci-')} company={company} />);
		} else {
			const dotsDiv = e.currentTarget.querySelector('div');
			Signals.ShowToolTip.dispatch(<Actions onSelect={this.onActionSelect} />, dotsDiv, 0, 0);
		}
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'edit':
				this.edit();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 */
	duplicate() {
		const { companyBrand } = this.props;
		const newCompanyBrand = companyBrand.clone();
		newCompanyBrand.id = null;

		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput key={uniqueKey('cbi-')} companyBrand={newCompanyBrand} />
		);
	}

	/**
	 *
	 */
	edit() {
		const { companyBrand } = this.props;
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput key={uniqueKey('cbi-')} companyBrand={companyBrand.clone()} />
		);
	}

	/**
	 *
	 */
	remove() {
		const { companyBrand, intl } = this.props;
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={intl.formatMessage({ id: 'company.brand.remove.alert.title' }, companyBrand)}
				onConfirm={() => this.doRemove()}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 */
	doRemove() {
		const { company, companyBrand } = this.props;
		const command = new DeleteCompanyBrandCommand(company, companyBrand.id);
		command.execute(() => {
			const { applicationStore } = this.context;
			applicationStore.companyBrandStore.remove(companyBrand);
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage id="company.brand.removed.message" values={companyBrand} />
			);
		});
	}
}

CompanyBrandWrapper.contextType = ApplicationContext;

CompanyBrandWrapper.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	company: PropTypes.instanceOf(Company).isRequired,
	companyBrand: PropTypes.instanceOf(CompanyBrandModel),
	canEdit: PropTypes.bool,
	intl: PropTypes.object
};

CompanyBrandWrapper.defaultProps = {
	label: null,
	className: '',
	companyBrand: null
};

export default injectIntl(CompanyBrandWrapper);
