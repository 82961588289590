import Signals from '../../signals/Signals';
import getPolicyTerms from '../../requests/policyTerms/getPolicyTerms';

export default class GetPolicyTermsCommand {
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
	}

	execute() {
		return getPolicyTerms().then(this.then).catch(this.catch);
	}

	then(response) {
		return response;
	}

	catch(error) {
		Signals.HideModal.dispatch();
		Signals.Error.dispatch(error);
	}
}
