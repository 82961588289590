import { observable } from 'mobx';

import Question from './Question';

import Signals from '../../signals/Signals';

import IBAangifteStatus from '../../data/IBAangifteStatus';

import annualStatement from '../../requests/annualStatement';
import annualStatementFilesDelete from '../../requests/annualStatementFilesDelete';
import financialStatementInfo from '../../requests/financialStatementInfo';

/**
 *
 */
export default class AnnualStatement {
	@observable saving = false;

	@observable fetching = false;

	@observable year = new Date().getFullYear();

	@observable ibAangifteStatus = null;

	@observable question1 = new Question();

	@observable question2 = new Question();

	@observable question3 = new Question();

	@observable question4 = new Question();

	@observable question5 = new Question();

	@observable question6 = new Question();

	@observable question7 = new Question();

	@observable question8 = new Question();

	@observable question9 = new Question();

	@observable question10 = new Question();

	@observable question11 = new Question();

	@observable financialStatementInfo = [];

	/**
	 *
	 */
	constructor() {
		this.financialStatementInfoLoaded = this.financialStatementInfoLoaded.bind(this);
	}

	/**
	 *
	 * @param onSaveSuccess
	 * @param onError
	 * @param submit
	 */
	save(onSaveSuccess, onError, submit = false) {
		if (this.saving) {
			return;
		}

		this.saving = true;

		const fileIdsToRemove = this._getFilesToRemove();
		const formData = this._createFormData();

		if (submit) {
			formData.append('ibAangifteStatus', IBAangifteStatus.SUBMITTED_CLIENT);
		}

		// Save with file removal
		if (fileIdsToRemove.length > 0) {
			// Delete files first
			annualStatementFilesDelete(fileIdsToRemove)
				.then(() => {
					// Clear removed files array
					this._clearRemovedFiles();

					// Update AnnualStatement with latest data
					annualStatement(formData)
						.then((response) => {
							// Update data with latest results
							this.updateData(response.data);
							this.saving = false;
							onSaveSuccess();
							// Handle error
						})
						.catch((error) => {
							this.saving = false;
							onError(error);
						});
					// Handle error
				})
				.catch((error) => {
					this.saving = false;
					onError(error);
				});
			// Just save
		} else {
			// Update AnnualStatement with latest data
			annualStatement(formData)
				.then((response) => {
					// Update data with latest results
					this.updateData(response.data);
					this.saving = false;
					onSaveSuccess();
					// Handle error
				})
				.catch((error) => {
					this.saving = false;
					onError(error);
				});
		}
	}

	/**
	 *
	 */
	loadFinancialStatementInfo() {
		financialStatementInfo(this.year)
			.then(this.financialStatementInfoLoaded)
			.catch((error) => {
				Signals.Error.dispatch(error);
			});
	}

	/**
	 *
	 * @param response
	 */
	financialStatementInfoLoaded(response) {
		this.financialStatementInfo = response.list;
	}

	/**
	 *
	 * @return {*}
	 */
	isComplete() {
		return (
			this.question1.isComplete(true) &&
			this.question2.isComplete(true) &&
			this.question3.isComplete() &&
			this.question4.isComplete() &&
			(!this.question4.checked || this.question5.isComplete()) && // q5 depends on question 4 answer set to true
			this.question6.isComplete() &&
			this.question7.isComplete(true) &&
			(!this.question7.checked || this.question8.isComplete(true)) && // q8 depends on question 7 answer set to true
			this.question9.isComplete(true, true) &&
			this.question10.isComplete() &&
			this.question11.isComplete()
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isExternal() {
		return this.ibAangifteStatus === IBAangifteStatus.EXTERNAL_SUBMIT;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isSubmitted() {
		return !(this.ibAangifteStatus == null || this.ibAangifteStatus === IBAangifteStatus.OPEN);
	}

	/**
	 *
	 * @param data
	 */
	parseData(data) {
		this.year = data.year;
		this.ibAangifteStatus = data.ibAangifteStatus;

		this.question1 = new Question(
			data.question1,
			data.question1Files,
			data.question1FileInfos,
			data.question1Meta
		);
		this.question2 = new Question(
			data.question2,
			data.question2Files,
			data.question2FileInfos,
			data.question2Meta
		);
		this.question3 = new Question(
			data.question3,
			data.question3Files,
			data.question3FileInfos,
			data.question3Meta
		);
		this.question4 = new Question(
			data.question4,
			data.question4Files,
			data.question4FileInfos,
			data.question4Meta
		);
		this.question5 = new Question(
			data.question5,
			data.question5Files,
			data.question5FileInfos,
			data.question5Meta
		);
		this.question6 = new Question(
			data.question6,
			data.question6Files,
			data.question6FileInfos,
			data.question6Meta
		);
		this.question7 = new Question(
			data.question7,
			data.question7Files,
			data.question7FileInfos,
			data.question7Meta
		);
		this.question8 = new Question(
			data.question8,
			data.question8Files,
			data.question8FileInfos,
			data.question8Meta
		);
		this.question9 = new Question(
			data.question9,
			data.question9Files,
			data.question9FileInfos,
			data.question9Meta
		);
		this.question10 = new Question(
			data.question10,
			data.question10Files,
			data.question10FileInfos,
			data.question10Meta
		);
		this.question11 = new Question(
			data.question11,
			data.question11Files,
			data.question11FileInfos,
			data.question11Meta
		);
	}

	/**
	 *
	 * @param data
	 */
	updateData(data) {
		this.question1.update(data.question1Files, data.question1FileInfos, data.question1Meta);
		this.question2.update(data.question2Files, data.question2FileInfos, data.question2Meta);
		this.question3.update(data.question3Files, data.question3FileInfos, data.question3Meta);
		this.question4.update(data.question4Files, data.question4FileInfos, data.question4Meta);
		this.question5.update(data.question5Files, data.question5FileInfos, data.question5Meta);
		this.question6.update(data.question6Files, data.question6FileInfos, data.question6Meta);
		this.question7.update(data.question7Files, data.question7FileInfos, data.question7Meta);
		this.question8.update(data.question8Files, data.question8FileInfos, data.question8Meta);
		this.question9.update(data.question9Files, data.question9FileInfos, data.question9Meta);
		this.question10.update(data.question10Files, data.question10FileInfos, data.question10Meta);
		this.question11.update(data.question11Files, data.question11FileInfos, data.question11Meta);
	}

	/**
	 *
	 * @param companyId
	 * @return {boolean}
	 */
	deadlinesComplete(companyId) {
		let result = false;
		if (this.financialStatementInfo) {
			this.financialStatementInfo.forEach((info) => {
				if (info.companyId === companyId) {
					result = !!info.submitted;
				}
			});
		}

		return result;
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getFilesToRemove() {
		return this.question1.removedFileIds
			.concat(this.question2.removedFileIds)
			.concat(this.question3.removedFileIds)
			.concat(this.question4.removedFileIds)
			.concat(this.question5.removedFileIds)
			.concat(this.question6.removedFileIds)
			.concat(this.question7.removedFileIds)
			.concat(this.question8.removedFileIds)
			.concat(this.question9.removedFileIds)
			.concat(this.question10.removedFileIds)
			.concat(this.question11.removedFileIds);
	}

	/**
	 *
	 * @private
	 */
	_clearRemovedFiles() {
		this.question1.removedFileIds = [];
		this.question2.removedFileIds = [];
		this.question3.removedFileIds = [];
		this.question4.removedFileIds = [];
		this.question5.removedFileIds = [];
		this.question6.removedFileIds = [];
		this.question7.removedFileIds = [];
		this.question8.removedFileIds = [];
		this.question9.removedFileIds = [];
		this.question10.removedFileIds = [];
		this.question11.removedFileIds = [];
	}

	/**
	 *
	 * @return {FormData}
	 * @private
	 */
	_createFormData() {
		const formData = new FormData();
		formData.append('year', this.year);

		this.question1.addToFormData(formData, 'question1');
		this.question2.addToFormData(formData, 'question2');
		this.question3.addToFormData(formData, 'question3');
		this.question4.addToFormData(formData, 'question4');
		this.question5.addToFormData(formData, 'question5');
		this.question6.addToFormData(formData, 'question6');
		this.question7.addToFormData(formData, 'question7');
		this.question8.addToFormData(formData, 'question8');
		this.question9.addToFormData(formData, 'question9');
		this.question10.addToFormData(formData, 'question10');
		this.question11.addToFormData(formData, 'question11');

		return formData;
	}
}
