import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import CookieController, { COOKIE_TYPES } from '../../controllers/CookieController';

import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';

/**
 *
 */
class OnBoardingIntro extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onSkip = this.onSkip.bind(this);
		this.onSkipRemember = this.onSkipRemember.bind(this);
		this.onNext = this.onNext.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className={`panel border border--dark ${this.props.className}`}>
				<div className="on-boarding__form">
					<div className="on-boarding__header padding border--bottom border--dark">
						<h2>
							<FormattedMessage id="onboarding.intro.title" />
						</h2>
					</div>

					<div className="padding-small">
						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<FormattedMessage id="onboarding.intro.description" />
							</FormField>
						</FormGroup>
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<div className="grid grid--vcenter">
							<button
								type="button"
								id="skip-onboarding"
								className="on-boarding__skip-button button--tertiary"
								onClick={this.onSkip}>
								{this.props.intl.formatMessage({ id: 'onboarding.button.skip.all' })}
							</button>
							<div
								className="on-boarding__skip-remember-button link link--small margin-small--left"
								onClick={this.onSkipRemember}>
								<FormattedMessage id="onboarding.button.skip.remember" />
							</div>
						</div>
						<button type="button" className="on-boarding__submit-button" onClick={this.onNext}>
							{this.props.intl.formatMessage({ id: 'onboarding.button.start' })}
						</button>
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onSkip() {
		this.props.skipAllCallback(this);
	}

	/**
	 *
	 */
	onSkipRemember() {
		CookieController.setCookie(COOKIE_TYPES.SKIP_ON_BOARDING_COOKIE_ID, true);
		this.props.skipAllCallback(this);
	}

	/**
	 *
	 */
	onNext() {
		this.props.successCallback(this);
	}
}

OnBoardingIntro.propTypes = {
	intl: PropTypes.object,
	skipAllCallback: PropTypes.func.isRequired,
	successCallback: PropTypes.func.isRequired,
	className: PropTypes.string
};

export default injectIntl(OnBoardingIntro);
