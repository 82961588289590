import { observable, computed } from 'mobx';
import Paid from '../../data/Paid';
import IncomeRowModel from './IncomeRowModel';

import {
	getFinancialRowExclBTW,
	getFinancialRowInclBTW,
	getBTWTypes,
	getTotalAmountInclBTW,
	getTotalAmountExclBTW
} from '../../utils/btwUtils';
import Draft from './Draft';

/**
 *
 */
export default class IncomeModel {
	@observable id = undefined;

	@observable invoiceNr = undefined;

	@observable date = undefined;

	@observable state = Paid.PAID_WITHIN_FYSCAL_YEAR;

	@observable removed = undefined;

	@observable customerName = undefined;

	@observable description = undefined;

	@observable projectcode = undefined;

	@observable fileName = undefined;

	@observable invoiceConceptId = undefined;

	@observable assetUuid = undefined;

	@observable draftId = undefined;

	invoiceRows = [];

	/**
	 *
	 * @param incomeModel
	 */
	copy(incomeModel) {
		this.state = incomeModel.state;
		this.customerName = incomeModel.customerName;
		this.description = incomeModel.description;
		this.projectcode = incomeModel.projectcode;
		this.invoiceRows = [];

		incomeModel.invoiceRows.forEach((row) => {
			this.invoiceRows.push(row.clone(true));
		});

		return this;
	}

	/**
	 *
	 */
	clone(unique = false) {
		const clone = IncomeModel.createNewIncome();

		clone.id = unique ? undefined : this.id;
		clone.invoiceNr = unique ? undefined : this.invoiceNr;
		clone.date = this.date ? new Date(this.date.getTime()) : undefined;
		clone.state = this.state;
		clone.removed = this.removed;
		clone.customerName = this.customerName;
		clone.description = this.description;
		clone.projectcode = this.projectcode;
		clone.fileName = unique ? undefined : this.fileName;
		clone.assetUuid = this.assetUuid;
		clone.invoiceConceptId = unique ? undefined : this.invoiceConceptId;
		clone.invoiceRows = [];

		this.invoiceRows.forEach((row) => {
			clone.invoiceRows.push(row.clone(unique));
		});

		return clone;
	}

	/**
	 *
	 * @param incomeModel
	 * @return {boolean}
	 */
	equals(incomeModel) {
		for (const key in incomeModel) {
			if (incomeModel.hasOwnProperty(key)) {
				if (key === 'invoiceRows') {
					if (
						this.invoiceRows.length !== incomeModel.invoiceRows.length ||
						!this.invoiceRows.every((row, index) => {
							return row.equals(incomeModel.invoiceRows[index]);
						})
					) {
						return false;
					}
				} else {
					const original = JSON.stringify(this[key]);
					const modified = JSON.stringify(incomeModel[key]);

					if (original !== modified) {
						console.log('IncomeModel.equals', key, original, modified);
						return false;
					}
				}
			}
		}

		return true;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isNew() {
		return !this.id;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isFromInvoiceConcept() {
		return !!this.invoiceConceptId;
	}

	/**
	 *
	 * @return {string}
	 */
	getBTWTranslationId() {
		const btwTypes = getBTWTypes(this.invoiceRows);
		let translationId = '-';

		// If multiple btw types
		if (btwTypes.length > 1) {
			translationId = 'btw.mixed';

			// If one btw type
		} else if (btwTypes.length === 1) {
			translationId = btwTypes[0].translationId(this.date);
		}

		return translationId;
	}

	/**
	 *
	 * @param autocompleteSearchObject
	 * @param ignoreCase
	 * @return {boolean}
	 */
	matches(autocompleteSearchObject, ignoreCase = true) {
		let match = true;

		autocompleteSearchObject.fields.forEach((autocompleteFieldData) => {
			let value = this[autocompleteFieldData.name];
			let searchQuery = autocompleteFieldData.searchQuery;

			if (!value) {
				match = false;
				return;
			}

			value = ignoreCase ? value.toLowerCase() : value;
			searchQuery = ignoreCase ? searchQuery.toLowerCase() : searchQuery;

			if (value.indexOf(searchQuery) < 0) {
				match = false;
			}
		});

		return match;
	}

	/**
	 *
	 * @param intl
	 * @param year
	 * @param useEuroSymbol
	 * @return {Array}
	 */
	toArray(intl, year, _useEuroSymbol = false) {
		const result = [];

		this.invoiceRows.forEach((incomeRow) => {
			let row = [];

			// Shared information
			row = row.concat([
				this.invoiceNr,
				new Date(this.date.getTime() + 43200000), // Half day offset to prevent jumping date in excel/csv viewers
				this.customerName,
				this.description,
				this.projectcode ? this.projectcode : '',
				intl.formatMessage({ id: `tag-label.${this.state}`.toLowerCase() }, { year }) // State
			]);

			const inclBTW = getFinancialRowInclBTW(incomeRow);
			const exclBTW = getFinancialRowExclBTW(incomeRow, this.date);

			// Row specific information
			row = row.concat([
				intl.formatMessage({ id: this.getBTWTranslationId() }), // BTW
				inclBTW,
				exclBTW
			]);

			// Add to result
			result.push(row);
		});

		return result;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get totalAmountInclBTW() {
		return getTotalAmountInclBTW(this.invoiceRows);
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get totalAmountExclBTW() {
		return getTotalAmountExclBTW(this.invoiceRows);
	}

	/**
	 *
	 * @return {boolean}
	 */
	@computed get searchString() {
		let searchString = '';
		searchString += this.invoiceNr ? `${this.invoiceNr} ` : '';
		searchString += this.customerName ? `${this.customerName} ` : '';
		searchString += this.description ? `${this.description} ` : '';
		searchString += this.projectcode ? `${this.projectcode} ` : '';
		try {
			searchString += new Intl.NumberFormat('nl-NL', {
				style: 'currency',
				currency: 'EUR'
			}).format(this.totalAmountInclBTW);
		} catch (e) {
			// Ignore
		}

		return searchString.toLowerCase();
	}

	/**
	 *
	 * @param data
	 * @returns {IncomeModel}
	 */
	static parseIncome(data) {
		const income = new IncomeModel();
		IncomeModel.updateIncome(data, income);
		return income;
	}

	/**
	 *
	 * @param data
	 * @param income
	 * @param forceInvoiceRow
	 * @param unique
	 */
	static updateIncome(data, income, forceInvoiceRow = false, unique = false) {
		if (data) {
			income.id = unique ? undefined : data.id;
			income.invoiceNr = data.invoiceNr;
			income.date = data.date ? new Date(data.date) : undefined;
			if (income.date) {
				income.date = new Date(income.date.getTime());
			}
			income.state = data.state;
			income.removed = data.removed;
			income.customerName = data.customerName;
			income.description = data.description;
			income.projectcode = data.projectcode;
			income.fileName = data.fileName;
			income.invoiceConceptId = data.invoiceConceptId;
			income.assetUuid = data.assetUuid;
			income.invoiceRows = [];

			// eslint-disable-next-line no-unused-expressions
			data.invoiceRows
				? data.invoiceRows.forEach((incomeRow) => {
						const incomeRowModel = new IncomeRowModel(incomeRow);
						if (unique) {
							incomeRowModel.id = undefined;
						}
						income.invoiceRows.push(incomeRowModel);
				  })
				: null;

			if (forceInvoiceRow && !data.invoiceRows) {
				income.invoiceRows = [];
				income.invoiceRows.push(new IncomeRowModel());
			}
		}
	}

	/**
	 *
	 * @return {IncomeModel}
	 */
	static createNewIncome() {
		const incomeModel = new IncomeModel();
		incomeModel.invoiceRows.push(new IncomeRowModel());
		return incomeModel;
	}

	/**
	 * @param draft Draft
	 *
	 * @return {IncomeModel}
	 */
	static createNewIncomeFromDraft(draft) {
		const incomeModel = new IncomeModel();
		let data = {};
		try {
			data = JSON.parse(draft.data);
		} catch (err) {
			// Ignore
		}

		// Update data with draft assetUuid
		data.assetUuid = data.assetUuid ? data.assetUuid : draft.assetUuid;

		// Set defaults
		data.id = undefined;
		data.state = data.state ? data.state : Paid.PAID_WITHIN_FYSCAL_YEAR;

		this.updateIncome(data, incomeModel, true, true);

		return incomeModel;
	}

	/**
	 *
	 * @param {BankRecord} bankRecord
	 */
	static createDraftIncomeFromBankRecord(bankRecord) {
		const draft = new Draft();

		const incomeModel = new IncomeModel();
		incomeModel.customerName = bankRecord.counterpartName;
		incomeModel.description = bankRecord.description;

		const invoiceRow = new IncomeRowModel();
		invoiceRow.amount = bankRecord.amount;
		incomeModel.invoiceRows.push(invoiceRow);
		draft.data = JSON.stringify(incomeModel);
		return draft;
	}
}
