import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'svg.js';

const LINE_THICKNESS = 4;
const SIZE = 24;

export default class ProgressCircle extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.svg = SVG(this.svgElement).size('100%', '100%').viewbox(0, 0, 24, 24);
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.outerCircle = this.svg.circle(SIZE).attr({ x: 0, y: 0 }).fill({ color: '#D8D8D8' });
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.innerCircle = this.svg
			.circle(SIZE - 2 * LINE_THICKNESS)
			.attr({ cx: SIZE / 2, cy: SIZE / 2 })
			.fill({ color: '#F7F9FB' });
		this.path = this.svg.path('');
		this.path
			.stroke({
				color: window.COLORS.RESULTS.PRIMARY,
				width: LINE_THICKNESS,
				linecap: 'round',
				linejoin: 'rect'
			})
			.fill('none');
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {
		this.updateSVG();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className="progress-circle">
				<div ref={(ref) => (this.svgElement = ref)} className="progress-circle__svg" />
			</div>
		);
	}

	/**
	 *
	 */
	updateSVG() {
		if (this.path) {
			const { percentage } = this.props;

			const startAngle = 0;
			const endAngle = 360 * percentage;
			const radius = (SIZE - LINE_THICKNESS) / 2;
			const x = SIZE / 2;
			const y = SIZE / 2;
			this.path.plot(ProgressCircle.describeArc(x, y, radius, startAngle, endAngle));
		}
	}

	/**
	 *
	 * @param x
	 * @param y
	 * @param radius
	 * @param startAngle
	 * @param endAngle
	 * @return {string}
	 */
	static describeArc(x, y, radius, startAngle, endAngle) {
		const start = ProgressCircle.polarToCartesian(x, y, radius, endAngle);
		const end = ProgressCircle.polarToCartesian(x, y, radius, startAngle);

		const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

		return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
	}

	/**
	 *
	 * @param centerX
	 * @param centerY
	 * @param radius
	 * @param angleInDegrees
	 * @return {{x: *, y: *}}
	 */
	static polarToCartesian(centerX, centerY, radius, angleInDegrees) {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians)
		};
	}
}

ProgressCircle.propTypes = {
	percentage: PropTypes.number.isRequired
};

ProgressCircle.defaultProps = {
	// eslint-disable-next-line react/default-props-match-prop-types
	percentage: 0
};
