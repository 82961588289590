import AbstractCommand from './AbstractCommand';
import communityCompanyProfileUpdate from '../requests/communityCompanyProfileUpdate';

export default class UpdateCommunityProfileCommand extends AbstractCommand {
	/**
	 * @param company Company
	 * @param communityProfile CommunityProfile
	 * @param communityCategoriesStore CommunityCategoriesStore
	 */
	constructor(company, communityProfile, communityCategoriesStore) {
		super();

		this.company = company;
		this.communityProfile = communityProfile;
		this.communityCategoriesStore = communityCategoriesStore;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		// If mandatory data is missing, deactivate this profile
		if (this.communityProfile.active && !this.communityProfile.canActivate()) {
			this.communityProfile.active = false;
		}

		communityCompanyProfileUpdate(this.company.id, this.communityProfile.toJSON({}))
			.then((response) => {
				this._updateCompanyCommunityProfile(response);
				success(response);
			})
			.catch(error);
	}

	_updateCompanyCommunityProfile(_response) {
		// Update company profile data from state profile
		this.company.communityProfile.update(JSON.parse(this.communityProfile.toJSON()));
	}
}
