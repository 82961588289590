/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { buildAssetURL } from '../../../utils/assetUtils';
import Asset from '../../../stores/models/Asset';

/**
 *
 */
class AssetPreview extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { isImageLoaded: false, cacheBuster: `t=${new Date().getTime()}` };

		this.onImageLoaded = this.onImageLoaded.bind(this);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(nextProps) {
		const { hideImage } = nextProps;
		if (hideImage) {
			this.setState({ isImageLoaded: false });
		}
	}

	/**
	 *
	 */
	render() {
		const { asset, className } = this.props;
		const { isImageLoaded, cacheBuster } = this.state;
		const classes = classNames({
			'asset-preview': true,
			'asset-preview--is-object': !asset.isImage(),
			'asset-preview--is-image': asset.isImage(),
			'asset-preview--image-loaded': isImageLoaded
		});

		if (!asset) return null;

		const assetUrl = asset.isPreviewUrl() ? asset.previewUrl : buildAssetURL(asset.uuid);

		return (
			<div className={`${classes} ${className}`}>
				<div className="asset-preview__container">
					{asset && asset.isImage() ? (
						<img
							key={cacheBuster}
							src={
								assetUrl.indexOf('?') > 0
									? `${assetUrl}&${cacheBuster}`
									: `${assetUrl}?${cacheBuster}`
							}
							alt="preview"
							onLoad={this.onImageLoaded}
						/>
					) : null}

					{asset.isObject() ? (
						<object data={assetUrl} width="100%" height="100%" type={asset.contentType}>
							<label>PDF preview not supported by your browser</label>
						</object>
					) : null}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onImageLoaded() {
		this.setState({ isImageLoaded: true });
	}
}

AssetPreview.propTypes = {
	className: PropTypes.string,
	asset: PropTypes.instanceOf(Asset),
	previewUrl: PropTypes.string,
	hideImage: PropTypes.bool
};

AssetPreview.defaultProps = {
	className: ''
};

export default AssetPreview;
