import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Routes } from './data/Routes';
import Signals from './signals/Signals';
import PropertiesController from './controllers/PropertiesController';

// Login / Authorization pages
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';

// On Boarding process
import OnBoarding from './pages/OnBoarding/OnBoarding';

// Company
import CompanyDashboard from './pages/Company/Dashboard/CompanyDashboard';
import Deadlines from './pages/Company/Deadlines/Deadlines';
import Expenses from './pages/Company/Expenses/Expenses';
import Income from './pages/Company/Income/Income';
import CompanyInfo from './pages/Company/Info/CompanyInfo';
import Results from './pages/Company/Results/Results';
import Invoices from './pages/Company/Invoices/Invoices';
import InvoicesArchive from './pages/Company/Invoices/InvoicesArchive';
import InvoiceConcept from './pages/Company/Invoice/InvoiceConcept';
import Invoice from './pages/Company/Invoice/Invoice';
import InvoicesSettings from './pages/Company/InvoicesSettings/InvoicesSettings';
import Offers from './pages/Company/Offers/Offers';
import OffersArchive from './pages/Company/Offers/OffersArchive';
import OfferConcept from './pages/Company/Offer/OfferConcept';
import Offer from './pages/Company/Offer/Offer';
import OffersSettings from './pages/Company/OffersSettings/OffersSettings';
import PontoOnboarding from './pages/Company/PontoOnboarding/PontoOnboarding';
import PontoCallback from './pages/Company/PontoOnboarding/PontoCallback';

// Personal
import UserInfo from './pages/User/Info/UserInfo';
import UserChangePassword from './pages/User/ChangePassword/UserChangePassword';
import UserPassport from './pages/User/UserPassport/UserPassport';
import UserMessages from './pages/UserMessages/UserMessages';

// UI
import ToolTip from './components/ui/ToolTip/ToolTip';
import Overlay from './components/ui/Overlay/Overlay';
import Modal from './components/ui/Modal/Modal';
import ModalAlert from './components/ui/Modal/ModalAlert';
import MessageDialog from './components/ui/MessageDialog/MessageDialog';
import UserMessagesDialog from './components/ui/UserMessages/UserMessagesDialog';
import LoadingScreen from './components/ui/LoadingScreen/LoadingScreen';
import Footer from './components/ui/Footer/Footer';
import Header from './components/ui/Header/Header';

//
import ErrorController from './controllers/ErrorController';
import RoutingController from './controllers/RoutingController';
import Clients from './pages/Company/Clients/Clients';
import CommunityOverview from './pages/Community/Overview/CommunityOverview';
import CommunityProfile from './pages/Community/Profile/CommunityProfile';
import Documents from './pages/Documents/Documents';
import PayrollDocuments from './pages/Documents/PayrollDocuments';

// Public
import PublicOffer from './pages/Public/PublicOffer';
import PublicInvoice from './pages/Public/PublicInvoice';
import ExpiredMessage from './pages/ExpiredMessage/ExpiredMessage';

// Context
import { ApplicationContext } from './ApplicationContext';
import DraftsFileUpload from './components/ui/DraftsFileUpload/DraftsFileUpload';
import Walkthrough from './components/ui/Walkthrough/Walkthrough';
import WalkthroughProgressTab from './components/ui/Walkthrough/WalkthroughProgressTab';
import PeriodicInvoices from './pages/Company/PeriodicInvoices/PeriodicInvoices';
import PeriodicInvoiceConcept from './pages/Company/PeriodicInvoice/PeriodicInvoiceConcept';
import PeriodicInvoice from './pages/Company/PeriodicInvoice/PeriodicInvoice';
import ProgressStatus from './components/ui/ProgressStatus/ProgressStatus';
import Transactions from './pages/Company/Bankbook/Transactions';
import BankBookSettings from './pages/Company/BankBookSettings/BankBookSettings';
import PontoGuard from './components/guards/PontoGuard';
import PolicyTerms from './pages/PolicyTerms/PolicyTerms';

/**
 *
 */
@observer
class Views extends Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		console.log('Views.constructor');

		this.onRootClick = this.onRootClick.bind(this);
		this.onSessionExpired = this.onSessionExpired.bind(this);

		Signals.SessionExpired.add(this.onSessionExpired);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.props.applicationStore.intl = this.props.intl;
	}

	/**
	 *
	 */
	componentDidMount() {
		this.props.applicationStore.intl = this.props.intl;
	}

	/**
	 *
	 */
	componentWillUnmount() {
		console.log('Views.unmount');
	}

	/**
	 *
	 */
	render() {
		// Render Views
		return (
			<ApplicationContext.Provider
				value={{ applicationStore: this.props.applicationStore, intl: this.props.intl }}>
				<div onClick={this.onRootClick}>
					{/* Routing Controller */}
					<Route render={() => <RoutingController />} />
					<Route render={() => <ErrorController />} />

					{/* Pages */}
					<Switch>
						<Route exact path={Routes.LOGIN.pathName} render={() => <Login />} />
						<Route exact path={Routes.FORGOT_PASSWORD.pathName} render={() => <ForgotPassword />} />
						<Route exact path={Routes.RESET_PASSWORD.pathName} render={() => <ResetPassword />} />

						<Route
							exact
							path={Routes.COMPANY_OFFERS_CLIENT.pathName}
							render={() => <PublicOffer />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_CLIENT.pathName}
							render={() => <PublicInvoice />}
						/>

						<Route exact path={Routes.ON_BOARDING.pathName} render={() => <OnBoarding />} />
						{PropertiesController.getProperty(PropertiesController.TERMS_AND_CONDITIONS) && (
							<Route exact path={Routes.POLICY_TERMS.pathName} render={() => <PolicyTerms />} />
						)}

						<Route
							exact
							path={Routes.COMPANY_DASHBOARD.pathName}
							render={() => <CompanyDashboard todoStore={this.props.applicationStore.todoStore} />}
						/>
						<Route exact path={Routes.COMPANY_RESULTS.pathName} render={() => <Results />} />
						<Route exact path={Routes.COMPANY_REVENUES.pathName} render={() => <Income />} />
						<Route exact path={Routes.COMPANY_EXPENSES.pathName} render={() => <Expenses />} />
						<Route
							exact
							path={Routes.COMPANY_DEADLINES.pathName}
							render={() => (
								<Deadlines
									annualStatementStore={this.props.applicationStore.annualStatementStore}
								/>
							)}
						/>

						<Route
							exact
							path={Routes.COMPANY_INVOICES_ALL.pathName}
							render={() => (
								<Invoices invoicesConceptStore={this.props.applicationStore.invoicesConceptStore} />
							)}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_ARCHIVE.pathName}
							render={() => (
								<InvoicesArchive
									invoicesConceptArchiveStore={
										this.props.applicationStore.invoicesConceptArchiveStore
									}
								/>
							)}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_EDIT.pathName}
							render={() => <InvoiceConcept />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_DUPLICATE.pathName}
							render={() => <InvoiceConcept />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_OFFER.pathName}
							render={() => <InvoiceConcept />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_INVOICE.pathName}
							render={() => <Invoice />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_CLIENTS.pathName}
							render={() => <Clients />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_PERIODIC_EDIT.pathName}
							render={() => <PeriodicInvoiceConcept />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_PERIODIC_INVOICE.pathName}
							render={() => <PeriodicInvoice />}
						/>
						<Route
							exact
							path={Routes.COMPANY_INVOICES_PERIODIC_ALL.pathName}
							render={() => (
								<PeriodicInvoices
									periodicInvoicesConceptStore={
										this.props.applicationStore.periodicInvoicesConceptStore
									}
								/>
							)}
						/>

						<Route
							exact
							path={Routes.COMPANY_OFFERS_ALL.pathName}
							render={() => <Offers offersStore={this.props.applicationStore.offersStore} />}
						/>
						<Route
							exact
							path={Routes.COMPANY_OFFERS_ARCHIVE.pathName}
							render={() => (
								<OffersArchive
									offersArchiveStore={this.props.applicationStore.offersArchiveStore}
								/>
							)}
						/>
						<Route
							exact
							path={Routes.COMPANY_OFFERS_EDIT.pathName}
							render={() => <OfferConcept />}
						/>
						<Route
							exact
							path={Routes.COMPANY_OFFERS_DUPLICATE.pathName}
							render={() => <OfferConcept />}
						/>
						<Route exact path={Routes.COMPANY_OFFERS_OFFER.pathName} render={() => <Offer />} />
						<Route exact path={Routes.COMPANY_OFFERS_CLIENTS.pathName} render={() => <Clients />} />

						<Route
							exact
							path={Routes.COMMUNITY_OVERVIEW.pathName}
							render={() => (
								<CommunityOverview
									communityProfileStore={this.props.applicationStore.communityProfileStore}
								/>
							)}
						/>

						<Route
							exact
							path={Routes.COMPANY_SETTINGS_INFO.pathName}
							render={() => <CompanyInfo />}
						/>
						<Route
							exact
							path={Routes.COMPANY_SETTINGS_INVOICES.pathName}
							render={() => <InvoicesSettings />}
						/>
						<Route
							exact
							path={Routes.COMPANY_SETTINGS_OFFERS.pathName}
							render={() => <OffersSettings />}
						/>
						<Route
							exact
							path={Routes.COMPANY_SETTINGS_COMMUNITY_PROFILE.pathName}
							render={() => (
								<CommunityProfile
									communityProfileStore={this.props.applicationStore.communityProfileStore}
								/>
							)}
						/>

						<Route
							exact
							path={Routes.COMPANY_COMMUNITY_PROFILE.pathName}
							render={() => (
								<CommunityProfile
									communityProfileStore={this.props.applicationStore.communityProfileStore}
								/>
							)}
						/>

						<Route exact path={Routes.USER_SETTINGS_INFO.pathName} render={() => <UserInfo />} />
						<Route
							exact
							path={Routes.USER_SETTINGS_CHANGEPASSWORD.pathName}
							render={() => <UserChangePassword />}
						/>
						<Route
							exact
							path={Routes.USER_SETTINGS_PASSPORT.pathName}
							render={() => <UserPassport />}
						/>

						<Route path={Routes.USER_DOCUMENTS.pathName} render={() => <Documents />} />
						<Route exact path={Routes.COMPANY_DOCUMENTS.pathName} render={() => <Documents />} />
						<Route
							exact
							path={Routes.COMPANY_PAYROLL_DOCUMENTS.pathName}
							render={() => <PayrollDocuments />}
						/>

						<Route path={Routes.USER_MESSAGES.pathName} render={() => <UserMessages />} />

						<Route path={Routes.EXPIRED_UUID.pathName} render={() => <ExpiredMessage />} />
						<Route
							exact
							path={Routes.COMPANY_PONTO_ONBOARDING.pathName}
							render={() => (
								<PontoGuard restricted={false} redirect={Routes.COMPANY_BANK}>
									<PontoOnboarding />
								</PontoGuard>
							)}
						/>
						<Route
							exact
							path={Routes.COMPANY_PONTO_CALLBACK.pathName}
							render={() => <PontoCallback />}
						/>
						<Route
							exact
							path={Routes.COMPANY_BANK.pathName}
							render={() => (
								<PontoGuard restricted redirect={Routes.COMPANY_PONTO_ONBOARDING}>
									<Transactions />
								</PontoGuard>
							)}
						/>
						<Route
							exact
							path={Routes.COMPANY_PONTO_SETTINGS.pathName}
							render={() => (
								<PontoGuard restricted redirect={Routes.COMPANY_PONTO_ONBOARDING}>
									<BankBookSettings />
								</PontoGuard>
							)}
						/>
					</Switch>

					{/* UI */}
					<Route
						render={() => (
							<div>
								<Footer />
								<Header />
								<DraftsFileUpload />
								<ProgressStatus />
								<ToolTip />
								<UserMessagesDialog />

								{PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)
									? [<Walkthrough />, <WalkthroughProgressTab />]
									: null}

								<LoadingScreen />
								<Overlay />
								<Modal />
								<MessageDialog />
							</div>
						)}
					/>
				</div>
			</ApplicationContext.Provider>
		);
	}

	/**
	 *
	 */
	onRootClick() {
		Signals.RootClick.dispatch();
	}

	/**
	 *
	 */
	onSessionExpired() {
		if (this.props && this.props.intl) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'session.expired.title' })}
					body={this.props.intl.formatMessage({ id: 'session.expired.description' })}
				/>
			);
		}
	}
}

Views.propTypes = {
	applicationStore: PropTypes.object.isRequired,
	intl: PropTypes.object
};

export default injectIntl(withRouter(Views));
