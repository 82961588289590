export default class FilterRule {
	param = null;

	val = null;

	constructor(param) {
		this.param = param;
	}

	set parameter(param) {
		this.param = param;
	}

	get parameter() {
		return this.param;
	}

	set value(val) {
		this.val = val;
	}

	get value() {
		return this.val;
	}

	isActive() {
		return this.param != null && this.val != null;
	}

	// eslint-disable-next-line consistent-return
	apply(object) {
		if (!object) {
			return false;
		}
	}
}
