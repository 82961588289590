import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NotificationNumber extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor() {
		super();
	}

	render() {
		const classes = classNames({
			'notification-number--hidden': this.props.count <= 0
		});

		return (
			<div className={`notification-number ${classes} ${this.props.className}`}>
				<span>{this.props.count}</span>
			</div>
		);
	}
}

NotificationNumber.propTypes = {
	count: PropTypes.number.isRequired,
	className: PropTypes.string
};

NotificationNumber.defaultProps = {
	className: ''
};
