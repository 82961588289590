import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import Signals from '../../signals/Signals';

import AbstractCommand from '../AbstractCommand';
import objectToFormData from '../../utils/objectToFormData';
import documentsCompanyCreate from '../../requests/documentsCompanyCreate';

/**
 *
 */
export default class CreateCompanyDocumentCommand extends AbstractCommand {

	/**
	 *
	 * @param document
	 * @param documentStore
	 * @param companyId
	 * @param financialYearId
	 * @param applicationStore
	 */
	constructor(document, documentStore, companyId, financialYearId, applicationStore) {
		super();

		this.document = document;
		this.documentStore = documentStore;
		this.companyId = companyId;
		this.financialYearId = financialYearId;
		this.applicationStore = applicationStore;

	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		const ignoreFields = ['errors', 'submitting', 'owner', 'sendToBookkeeper'];
		const formData = objectToFormData(this.document, true, ignoreFields);

		documentsCompanyCreate(formData, this.companyId, this.financialYearId, false).then((response) => {
			Signals.DocumentSubmitted.dispatch(response.data);
			Signals.ShowMessageDialog.dispatch((
				<FormattedHTMLMessage id="documents.added.message" values={{fileName: this.document.fileName}}/>));

			// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
			Signals.HideOverlay.dispatch();

			// Preset year
			this.applicationStore.setSelectedDocumentYear(this.document.year);

			if(success) {
				success(response);
			}
		}).catch((err) => {
			if(error) {
				error(err);
			}
		});
	}
}
