import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, periodicInvoiceConceptId, data) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		EndPoints.COMPANIES_PERIODIC_INVOICE_CONCEPTS_ID.replace(':id', companyId).replace(
			':invoiceConceptId',
			periodicInvoiceConceptId
		),
		data,
		headers,
		true
	);
};
