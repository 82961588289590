/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedDate, FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';

import ExpenseStore from '../../../../stores/ExpenseStore';
import ExpenseModel from '../../../../stores/models/ExpenseModel';

import { getTotalAmountExclBTW, getTotalAmountInclBTW } from '../../../../utils/btwUtils';

import LedgerCodes from '../../../../data/LedgerCodes';
import { defaultDateFormatOptions } from '../../../../data/Settings';

import Signals from '../../../../signals/Signals';
import Tag from '../../../../components/ui/Tag/Tag';
import Actions from '../../../../components/ui/ToolTip/tooltips/Actions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';
import { ApplicationContext } from '../../../../ApplicationContext';
import DeleteExpenseCommand from '../../../../commands/expenses/DeleteExpenseCommand';
import ExpenseInputPanel from './modals/ExpenseInputPanel';
import { uniqueKey } from '../../../../utils/ReactUtils';

/**
 *
 */
class ExpenseRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.edit = this.edit.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.remove = this.remove.bind(this);

		this.onFieldClick = this.onFieldClick.bind(this);
		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onImageClick = this.onImageClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { expenseModel } = this.props;
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		const classes = classNames({
			'table-row__locked':
				!financialYear.isActive() ||
				this.context.applicationStore.yearResultStore.hasLockedDeadlines()
		});
		const state = expenseModel.state ? expenseModel.state.toLowerCase() : 'default';
		const ledgerCode = expenseModel.getLedgerCode();

		// Determine BTW label
		const btwLabel = this.props.intl.formatMessage({ id: expenseModel.getBTWTranslationId() });

		return (
			<tr
				className={`table-row ${
					this.props.expenseStore.sortBy
						? `expense-row--sorted-by--${this.props.expenseStore.sortBy.toLowerCase()}`
						: ''
				} ${classes}`}>
				<td className="table-row__column expense-row__expensenr">{expenseModel.expenseNr}</td>
				<td
					className="table-row__column table-row__column--editable expense-row__date"
					onClick={() => this.onFieldClick('date')}>
					<FormattedDate value={expenseModel.date} {...defaultDateFormatOptions} />
				</td>
				<td
					className="table-row__column table-row__column--editable expense-row__customername"
					onClick={() => this.onFieldClick('customerName')}>
					<Truncate>{expenseModel.customerName}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable expense-row__description"
					onClick={() => this.onFieldClick('description')}>
					<Truncate>{expenseModel.description}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable expense-row__ledgercode"
					onClick={() => this.onFieldClick('ledgerCode')}>
					<FormattedMessage id={LedgerCodes.getObjectByCode(ledgerCode).translationId} />
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--center expense-row__btw"
					onClick={() => this.onFieldClick('btwType')}>
					{btwLabel}
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right expense-row__exclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountExclBTW(expenseModel.expenseRows, expenseModel.date)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right expense-row__inclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountInclBTW(expenseModel.expenseRows)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className={`table-row__column table-row__column--center expense-row__filename ${
						expenseModel.fileName ? 'icon icon--check-smoke' : ''
					}`}
					onClick={this.onImageClick}
				/>
				<td
					className="table-row__column table-row__column--editable table-row__column--center expense-row__state"
					onClick={() => this.onFieldClick('state')}>
					<Tag labelId={`tag-label.${state}`} type={state} values={{ year: financialYear.year }} />
				</td>
				<td
					className="table-row__column table-row__column--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
					<div className="table-row__actions--locked icon icon--lock-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param field
	 */
	onFieldClick(field) {
		this.edit(field);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();
		if (!this.context.applicationStore.showToolTip) {
			const { expenseModel } = this.props;
			let hideTypes = [];
			if (!expenseModel.mayBeChanged) {
				hideTypes = ['duplicate', 'remove'];
			}
			Signals.ShowToolTip.dispatch(
				<Actions onSelect={this.onActionSelect} hideTypes={hideTypes} />,
				e.currentTarget,
				-16
			);
		}
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'edit':
				this.edit();
				break;
			default:
				console.log('Action not found');
		}
	}

	/**
	 *
	 */
	onImageClick() {
		if (this.props.expenseModel.id && this.props.expenseModel.fileName) {
			const url = window.config.imageExpense + this.props.expenseModel.id;
			window.open(url, '_blank');
		}
	}

	/**
	 *
	 */
	remove() {
		const { intl, expenseModel } = this.props;
		const title = intl.formatMessage(
			{ id: 'expense.remove.alert.title' },
			{
				description: expenseModel.description,
				customer: expenseModel.customerName
			}
		);

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={() => this.doRemove()}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 */
	doRemove() {
		const { expenseModel, expenseStore } = this.props;
		const command = new DeleteExpenseCommand(expenseModel, expenseStore);
		command.execute();
	}

	/**
	 *
	 * @param fieldToFocus
	 */
	edit(fieldToFocus) {
		// Create copy to prevent unwanted changes to original
		const expenseModel = this.props.expenseModel.clone();

		// Show ExpenseInputPanel overlay
		Signals.ShowOverlay.dispatch(
			<ExpenseInputPanel
				key={uniqueKey('eip-')}
				expenseModel={expenseModel}
				fieldToFocus={fieldToFocus}
			/>
		);
	}

	/**
	 *
	 */
	duplicate() {
		// Copy all data from ExpenseModel except id, expenseNr and fileName
		const expenseModel = this.props.expenseModel.clone(true);

		// Show IncomeInput overlay
		Signals.ShowOverlay.dispatch(
			<ExpenseInputPanel key={uniqueKey('eip-')} expenseModel={expenseModel} />
		);
	}
}

ExpenseRow.contextType = ApplicationContext;

ExpenseRow.propTypes = {
	expenseModel: PropTypes.instanceOf(ExpenseModel).isRequired,
	expenseStore: PropTypes.instanceOf(ExpenseStore).isRequired,
	intl: PropTypes.object
};

export default injectIntl(ExpenseRow);
