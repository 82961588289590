/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { getTranslations } from '../../utils/Translations';
import { buildPublicAssetURL, buildPublicOfferPdfURL } from '../../utils/assetUtils';

import ScrollController from '../../controllers/ScrollController';

import BTW from '../../data/BTW';
import Locale from '../../data/Locale';

import OfferModel from '../../stores/models/OfferModel';

import GetPublicOfferCommand from '../../commands/offers/GetPublicOfferCommand';

import { ApplicationContext } from '../../ApplicationContext';

import Page from '../Page';
import OfferRow from '../Company/Offer/components/OfferRow';
import OfferAttachments from '../Company/Offer/components/OfferAttachments';
import ClientHeader from '../../components/ui/ClientHeader/ClientHeader';
import CompanyAddress from '../../components/ui/CompanyAddress/CompanyAddress';
import ClientAddress from '../../components/ui/ClientAddress/ClientAddress';
import AcceptOfferForm from '../../components/ui/AcceptOfferForm/AcceptOfferForm';

import OfferSummaryRow from '../Company/Offer/components/OfferSummaryRow';
import SignOfferModel from '../../stores/models/SignOfferModel';
import SignedOffer from '../../components/ui/SignedOffer/SignedOffer';
import User from '../../stores/models/User';
import Signals from '../../signals/Signals';
import CompanyBrandModel from '../../stores/models/CompanyBrandModel';
import ModalAlert from '../../components/ui/Modal/ModalAlert';
/**
 *
 */
@observer
class PublicOffer extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onInputChange = this.onInputChange.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.initialize();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (!this.state.offerModel) {
			return null;
		}

		//
		const { intl } = this.props;
		const { offerModel } = this.state;
		const { company, date } = offerModel;
		const companyBrand = offerModel.companyBrand
			? offerModel.companyBrand
			: CompanyBrandModel.fromCompany(company);

		// Build logo URL
		const logoPreviewURL = companyBrand.logoAssetUuid
			? buildPublicAssetURL(offerModel.publicAccessId, companyBrand.logoAssetUuid)
			: null;
		const deviatingUnits = offerModel.hasDeviatingUnits();

		if (!deviatingUnits) {
			// eslint-disable-next-line array-callback-return
			this.fieldHeaderSettings.map((fieldData) => {
				if (fieldData.id === 'units') {
					fieldData.hide = true;
				}

				if (fieldData.id === 'unitprice') {
					fieldData.labelId = fieldData.labelIdSingle;
				}
			});
		}

		const detailsWrapperClasses = classNames({
			'offer__details-wrapper': true,
			'offer__details-wrapper--no-units': !deviatingUnits
		});

		// Create table headers
		const values = { totalUnits: '' };
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			return (
				<td
					key={`invh-${index}`}
					className={`${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})} offer__header--${fieldData.id.toLowerCase()}`}>
					{fieldData.hide ? null : <FormattedMessage id={fieldData.labelId} values={values} />}
				</td>
			);
		});

		// Create offers concept rows
		const rows = offerModel.offerRows.map((offerRowModel, index) => {
			return (
				<OfferRow
					key={`i-${index}-${offerRowModel._uid}`}
					hideUnits={!deviatingUnits}
					offerModel={offerModel}
					offerRowModel={offerRowModel}
				/>
			);
		});

		return (
			<div>
				<ClientHeader
					company={company}
					companyCustomer={offerModel.companyCustomer}
					forCompanyLabel={intl.formatMessage({ id: 'public.offer.for.label' })}
				/>

				<IntlProvider locale={this.state.locale} messages={this.state.translations}>
					<Page pageName="public-offer">
						{/* Main Panel */}
						<div className="offer__panel panel grid col--12 box-shadow border border--dark">
							{/* Option buttons */}
							<div className="offer__options-top padding-small border--bottom border--dark grid grid--spread col--12">
								<div className="offer__options-left grid">
									{offerModel.canSign() ? (
										<button
											type="button"
											className="btn-margin-left button icon icon--right icon--chevron-down-white"
											onClick={() => this.scrollToAccept()}>
											<FormattedMessage id="label.accept.offer" />
										</button>
									) : null}
								</div>
								<div className="offer__options-right grid grid--right">
									<button
										type="button"
										className="button--tertiary icon icon--left icon--eye2-black"
										onClick={() => this.downloadPDF()}>
										<FormattedMessage id="offer.view.pdf" />
									</button>
								</div>
							</div>

							{/* Offer status */}
							<div
								className={`offer__status ${
									offerModel.status ? `offer__status--${offerModel.status.toLowerCase()}` : ''
								}`}>
								<label>
									<FormattedMessage
										id={`offer.status.${offerModel.status ? offerModel.status.toLowerCase() : ''}`}
										values={{
											statusDateChanged: this.props.intl.formatDate(offerModel.statusDateChanged)
										}}
									/>
								</label>
							</div>

							{/* Offer layout + localised translation */}
							<div className="offer__layout-wrapper padding grid">
								<div className="offer__information grid col--12">
									<div className="offer__logo col--6">
										{logoPreviewURL ? (
											<img className="offer__logo-img" src={logoPreviewURL} alt="logo" />
										) : null}
									</div>

									<div className="col--6 grid">
										<label className="text--right offer__information-label col--5">
											<FormattedMessage id="offer.label.sender" />
										</label>
										<div className="col--7">
											<CompanyAddress company={companyBrand.toCompany(company)} useDate={date} />
										</div>
									</div>

									<div className="offer__information-detail-labels grid col--6">
										<label className="offer__information-label col--4">
											<FormattedMessage id="offer.info.offer" />
										</label>
										<div className="offer__information-value offer__information-value--bold col--8">
											{offerModel.subject}
										</div>

										<label className="offer__information-label col--4">
											<FormattedMessage id="offer.info.offernr" />
										</label>
										<div className="offer__information-value col--8">{offerModel.offerNr}</div>

										<label
											className={`offer__information-label col--4 ${classNames({
												hidden: !offerModel.projectcode
											})}`}>
											<FormattedMessage id="offer.info.projectcode" />
										</label>
										<div
											className={`offer__information-value col--8 ${classNames({
												hidden: !offerModel.projectcode
											})}`}>
											{offerModel.projectcode}
										</div>

										<label className="offer__information-label col--4">
											<FormattedMessage id="offer.info.date" />
										</label>
										<div className="offer__information-value col--8">
											<FormattedDate
												value={offerModel.date}
												day="numeric"
												month="long"
												year="numeric"
											/>
										</div>

										{offerModel.hasExpirationDate() ? (
											<label className="offer__information-label col--4">
												<FormattedMessage id="offer.info.expirationdate" />
											</label>
										) : null}

										{offerModel.hasExpirationDate() ? (
											<div className="offer__information-value col--8">
												{/* Calculate expirationDate from date + expirationDays to cancel out discrepantie of 1 day */}
												<FormattedDate
													value={offerModel.getExpirationDateFromDays()}
													day="numeric"
													month="long"
													year="numeric"
												/>
											</div>
										) : null}
									</div>

									<div className="col--6 grid">
										<label className="text--right offer__information-label col--5">
											<FormattedMessage id="offer.label.client" />
										</label>
										<div className="col--7">
											<ClientAddress
												companyCustomer={offerModel.companyCustomer}
												language={offerModel.locale === Locale.en_US ? 'en' : 'nl'}
											/>
										</div>
									</div>
								</div>

								<div className={detailsWrapperClasses}>
									<table className="table table--wide col--12 offer__details">
										<thead className="table__headers">
											<tr>{headers}</tr>
										</thead>
										<tbody className="table__body">
											{rows}

											{/* Empty row */}
											<tr className="offer-summary-row">
												<td colSpan={5}>&nbsp;</td>
											</tr>

											<OfferSummaryRow
												label={this.props.intl.formatMessage({ id: 'offer.summary.subtotal' })}
												amount={offerModel.getSubtotal()}
											/>
											<OfferSummaryRow
												hideWhenZero={!offerModel.containsVATType(BTW.LAAG)}
												label={this.props.intl.formatMessage(
													{ id: 'offer.summary.btw' },
													{
														percentage: this.props.intl.formatMessage({
															id: BTW.LAAG.translationId(offerModel.date)
														})
													}
												)}
												amount={offerModel.getBTWTotal(BTW.LAAG.name)}
											/>
											<OfferSummaryRow
												hideWhenZero={!offerModel.containsVATType(BTW.HOOG)}
												label={this.props.intl.formatMessage(
													{ id: 'offer.summary.btw' },
													{
														percentage: this.props.intl.formatMessage({
															id: BTW.HOOG.translationId()
														})
													}
												)}
												amount={offerModel.getBTWTotal(BTW.HOOG.name)}
											/>

											{this._getVATReversed()}
											{this._getVATExempt()}

											<OfferSummaryRow
												label={this.props.intl.formatMessage({ id: 'offer.summary.total' })}
												amount={offerModel.getTotal()}
											/>
										</tbody>
									</table>
								</div>

								{/* Optional notes */}
								{offerModel.hasNotes() ? (
									<div className="offer__notes col--12 grid padding border--top padding--bottom">
										<label>
											<FormattedMessage id="offer.label.notes" />
										</label>
										{/* eslint-disable-next-line react/no-danger */}
										<div dangerouslySetInnerHTML={{ __html: offerModel.getNotesAsHTML() }} />
									</div>
								) : null}
							</div>
						</div>

						{/* Signed data	*/}
						{offerModel.isSigned() ? (
							<SignedOffer offerModel={offerModel} company={company} />
						) : null}

						{/* Attachments */}
						<OfferAttachments offerModel={offerModel} company={company} />

						{/*	AcceptOfferForm	*/}
						{offerModel.canSign() ? (
							<AcceptOfferForm
								offerModel={offerModel}
								signOfferModel={this.state.signOfferModel}
								company={company}
							/>
						) : null}
					</Page>
				</IntlProvider>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const newState = {};
		newState[e.target.name] = e.target.value;
		this.setState(newState);
	}

	/**
	 *
	 */
	resetFieldHeaderSettings() {
		this.fieldHeaderSettings = [
			{ id: 'description', labelId: 'offer.header.description' },
			{ id: 'units', labelId: 'offer.header.units', right: true },
			{
				id: 'unitprice',
				labelId: 'offer.header.unitprice',
				labelIdSingle: 'offer.header.price',
				right: true
			},
			{ id: 'btw', labelId: 'offer.header.btw', right: true },
			{ id: 'amount', labelId: 'offer.header.amount', right: true }
		];
	}

	/**
	 *
	 */
	initialize() {
		this.resetFieldHeaderSettings();

		const newState = {};
		newState.offerModel = new OfferModel();
		newState.locale = 'nl';
		this.state = newState;

		const uuid = this.context.applicationStore.currentRouteParams.uuid;
		const command = new GetPublicOfferCommand(newState.offerModel, uuid);
		command.execute(
			() => {
				switch (newState.offerModel.locale) {
					case Locale.en_US:
						newState.locale = 'en';
						break;
					case Locale.nl_NL:
						newState.locale = 'nl';
						break;
					default:
						newState.locale = 'nl';
				}

				const { applicationStore } = this.context;

				// Force localisation to invoice locale
				const user = new User();
				user.language = window.config.defaultLocale = newState.locale;
				newState.translations = getTranslations(newState.locale);
				applicationStore.user = user;

				//
				newState.offerModel.publicAccessId = this.context.applicationStore.currentRouteParams.uuid;

				// Create new SignOfferModel with default values
				newState.signOfferModel = new SignOfferModel();
				newState.signOfferModel.fullName = newState.offerModel.companyCustomer.contactPersonName;
				newState.signOfferModel.companyName = newState.offerModel.companyCustomer.companyName;
				newState.signOfferModel.email = newState.offerModel.companyCustomer.contactPersonEmail;

				//
				this.setState(newState);
			},
			(_err) => {
				const translationTitleId = 'public.offer.notfoundorexpired.title';
				const translationId = 'public.offer.notfoundorexpired.description';
				Signals.ShowModal.dispatch(
					<ModalAlert
						title={this.props.intl.formatMessage({ id: translationTitleId })}
						body={this.props.intl.formatMessage({ id: translationId })}
						onConfirm={() => (window.location.href = '/')}
					/>
				);
			}
		);
	}

	/**
	 *
	 */
	downloadPDF() {
		const url = buildPublicOfferPdfURL(this.state.offerModel.publicAccessId);
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	scrollToAccept() {
		const el = document.querySelector('.offer-accept-form__header');
		ScrollController.scrollPageToElement(el, 152 + 24);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.state.offerModel.containsVATType(BTW.VERLEGD)) {
			return (
				<OfferSummaryRow
					label={this.props.intl.formatMessage({ id: 'offer.vat.reversed.summary' })}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.state.offerModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<OfferSummaryRow
					label={this.props.intl.formatMessage({ id: 'offer.vat.exempt.summary' })}
					amount={null}
				/>
			);
		}
		return null;
	}
}

PublicOffer.contextType = ApplicationContext;

PublicOffer.propTypes = {
	intl: PropTypes.object,
	// eslint-disable-next-line react/no-unused-prop-types
	history: PropTypes.object
};

export default withRouter(injectIntl(PublicOffer));
