import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { injectIntl } from 'react-intl';

/**
 *
 */
class BTWInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { value: undefined };
		this.input = null;

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const value = this.format(this.input.value);
		this.setState({ value });
	}

	/**
	 * @param nextProps
	 */
	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		let value = this.state.value !== undefined ? this.state.value : this.props.value;

		// Is the label value used in the result?
		if (value && this.props.useLabel && this.props.label && this.props.label.length > 0) {
			// strip of Label if start of value contains the substring
			if (value.toLowerCase().indexOf(this.props.label.toLowerCase()) === 0) {
				value = value.substr(this.props.label.length);
			}
		}

		return (
			<div
				className={`btw-input ${this.props.className ? this.props.className : ''} ${classNames({
					'btw-input__label--hidden': !this.props.showLabel
				})}`}>
				{this.props.showLabel ? (
					<div
						className="btw-input__label"
						onClick={() => {
							this.input.focus();
						}}>
						{this.props.label}
					</div>
				) : null}
				<input
					className="btw-input__input"
					type="text"
					ref={(input) => {
						this.input = input;

						// Make sure React remembers cursor position, see https://github.com/facebook/react/issues/955
						if (input && this.state && !isNaN(parseInt(this.state.selectionStart, 10))) {
							input.selectionStart = input.selectionEnd = this.state.selectionStart;
							input.selectionEnd = this.state.selectionEnd
								? this.state.selectionEnd
								: this.state.selectionStart;
						}
					}}
					required={this.props.required}
					disabled={this.props.disabled}
					name={this.props.name}
					value={value}
					placeholder={this.props.placeholder}
					onChange={this.onChange}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					maxLength="14"
				/>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		this.state.value = e.target.value;

		// Remember cursor position
		this.setState({
			selectionStart: this.input.selectionStart,
			selectionEnd: this.input.selectionEnd
		});

		this._update(e);
	}

	/**
	 *
	 * @param e
	 */
	onBlur(e) {
		this.state.value = this.format(e.target.value);

		// Erase cursor position
		this.state.selectionStart = null;
		this.state.selectionEnd = null;

		this._update(e);
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {}

	/**
	 * Function to format the value when input is complete (initialize, blur)
	 *
	 * @param value
	 * @returns {*}
	 */
	format(value) {
		value = value.toUpperCase();
		return this.filter(value);
	}

	/**
	 *
	 * @param value
	 * @return {*}
	 * @private
	 */
	filter(value) {
		const { label, showLabel } = this.props;

		// If showing label, and label is set,
		// strip label off of value, if needed the _update function will re-add it
		if (showLabel && label.length > 1) {
			return value.replace(label, '');
		}
		return value;
	}

	/**
	 *
	 * @param e
	 * @private
	 */
	_update(e) {
		const { onChange, label, useLabel } = this.props;
		const { value } = this.state;
		if (onChange && value) {
			e.target.value = useLabel ? label + value : value;
			this.props.onChange(e);
		}
	}
}

BTWInput.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	label: PropTypes.string,
	useLabel: PropTypes.bool, // Strip and apply label text from and to value?
	showLabel: PropTypes.bool // Show or hide label
};

BTWInput.defaultProps = {
	placeholder: '001234567B01',
	required: false,
	label: 'NL',
	useLabel: false,
	showLabel: true
};

export default injectIntl(BTWInput);
