import { observable } from 'mobx';
import communityProfiles from '../requests/communityProfiles';

import Signals from '../signals/Signals';

import CommunityProfile from './models/CommunityProfile';

/**
 *
 */
export default class CommunityProfileStore {
	@observable communityProfiles = [];

	@observable resultsPerPage = 25;

	@observable pageIndex = 0;

	@observable searchQuery = '*'; // default

	@observable category = null;

	@observable totalResults = -1;

	@observable fetched = false;

	@observable fetching = false;

	constructor() {
		this.addProfile = this.addProfile.bind(this);
		this.getProfileById = this.getProfileById.bind(this);
	}

	fetch(force = false, clearBefore = true) {
		// Only fetch when first time, or when forced
		if (!this.fetched || force) {
			this.fetching = true;

			if (clearBefore) {
				this.pageIndex = 0;
				this.communityProfiles = [];
			}

			communityProfiles(
				this.category,
				this.searchQuery,
				this.pageIndex * this.resultsPerPage,
				this.resultsPerPage
			)
				.then((response) => {
					this.totalResults = response.data.totalResults;
					response.data.results.forEach((data) => {
						this.addProfile(this._parseCommunityProfile(data));
					});

					this.fetched = true;
					this.fetching = false;

					Signals.CommunityFetched.dispatch();
				})
				.catch((error) => {
					this.fetching = false;
					Signals.Error.dispatch(error);
				});

			// Force change triggers
		} else {
			this.communityProfiles = this.communityProfiles.concat([]);
			Signals.CommunityFetched.dispatch();
		}
	}

	hasNext() {
		// No results retrieved yet
		if (this.totalResults < 0) {
			return true;
		}

		return (this.pageIndex + 1) * this.resultsPerPage < this.totalResults;
	}

	loadNext() {
		console.log('loadNext');
		this.pageIndex++;
		this.fetch(true, false);
	}

	reset() {
		console.log('reset');
		this.fetched = false;
		this.pageIndex = 0;
		this.totalResults = -1;
		this.communityProfiles = [];
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getProfileById(id) {
		return this.communityProfiles.find((profile) => {
			return profile.id === id;
		});
	}

	/**
	 *
	 * @param profile
	 */
	addProfile(profile) {
		if (!this.getProfileById(profile.id)) {
			this.communityProfiles.push(profile);
		}
	}

	/**
	 *
	 * @param data
	 * @returns {CommunityProfile}
	 * @private
	 */
	_parseCommunityProfile(data) {
		const communityProfile = new CommunityProfile();
		this._updateCommunityProfile(data, communityProfile);
		return communityProfile;
	}

	/**
	 *
	 * @param data
	 * @param CommunityProfile
	 * @private
	 */
	_updateCommunityProfile(data, communityProfile) {
		communityProfile.update(data);
	}
}
