import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Signals from '../../signals/Signals';
import pontoAuthCodePost from '../../requests/bank/pontoAuthCodePost';

export default class SavePontoAuthCode {
	/**
	 *
	 */
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
	}

	/**
	 *
	 * @param companyId
	 *
	 * @return {Promise<any | never>}
	 */
	execute(companyId, code) {
		return pontoAuthCodePost(companyId, {
			authCode: code
		})
			.then(this.then)
			.catch(this.catch);
	}

	/**
	 *
	 */
	then() {
		Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="ponto.connect.success.message" />);
	}

	/**
	 *
	 * @param error
	 */
	catch(error) {
		Signals.Error.dispatch(error);
	}
}
