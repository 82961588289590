/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import assetDataGet from '../../../requests/assets/assetDataGet';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';

import Asset from '../../../stores/models/Asset';
import CookieController, { COOKIE_TYPES } from '../../../controllers/CookieController';

import AssetPreview from './AssetPreview';
import FileUploadArea from '../FileUploadArea/FileUploadArea';
import rotateCompanyAsset from '../../../requests/assets/rotateCompanyAsset';
import Company from '../../../stores/models/Company';
import { forceDownload } from '../../../utils/fileUtils';
import Truncate from '../Truncate/Truncate';

/**
 *
 */
class AssetUpload extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			forceReloadAssetKey: 0,
			imageOutTransition: false,
			collapsed: CookieController.getCookie(COOKIE_TYPES.ASSET_UPLOAD_PANEL_COLLAPSED)
		};

		this.onChange = this.onChange.bind(this);
		this.onToggleState = this.onToggleState.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.initialize(this.props);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(nextProps) {
		this.initialize(nextProps);
	}

	/**
	 *
	 */
	render() {
		const { className, disabled, accept } = this.props;
		const { asset, forceReloadAssetKey, imageOutTransition, collapsed } = this.state;
		const classes = classNames({
			'asset-upload': true,
			'asset-upload--collapsed': collapsed
		});

		return (
			<div className={`${classes} ${className}`} onClick={this.showActions}>
				{disabled ? null : (
					<FileUploadArea
						multiple={false}
						accept={accept}
						useValidation
						onChange={this.onChange}
						hideContentArea={asset}
					/>
				)}

				{!asset ? null : (
					<div className="asset-upload__wrapper">
						<div className="asset-upload__preview-container">
							<AssetPreview
								key={`ap-${forceReloadAssetKey}`}
								asset={asset}
								hideImage={imageOutTransition}
							/>
						</div>

						{disabled ? null : (
							<div className="asset-upload__asset-actions">
								<div className="asset-upload__asset-actions__info">
									<label>
										<Truncate>{asset.filename}</Truncate>
									</label>
									{collapsed ? (
										<div
											className="icon icon--right icon--chevron-up-smoke"
											onClick={this.onToggleState}
										/>
									) : (
										<div
											className="icon icon--right icon--chevron-down-smoke"
											onClick={this.onToggleState}
										/>
									)}
								</div>
								<div className="asset-upload__asset-actions__buttons">
									{/* Can't rotate when 'old' asset, requires a uuid */}
									{!asset.canRotate() ? null : (
										<button
											type="button"
											className="button--icon-only button--flat"
											onClick={() => {
												this.rotateAssetCCW();
											}}>
											<span className="icon icon--left icon--rotate-ccw-black" />
										</button>
									)}
									{!asset.canRotate() ? null : (
										<button
											type="button"
											className="button--icon-only button--flat"
											onClick={() => {
												this.rotateAssetCW();
											}}>
											<span className="icon icon--left icon--rotate-cw-black" />
										</button>
									)}
									{!asset.canDownload() ? null : (
										<button
											type="button"
											className="button--icon-only button--flat"
											onClick={() => {
												this.downloadAsset();
											}}>
											<span className="icon icon--left icon--download2-black" />
										</button>
									)}
									<button
										type="button"
										className="button--icon-only button--flat"
										onClick={() => {
											this.removeAsset();
										}}>
										<span className="icon icon--left icon--delete-black" />
									</button>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}

	/**
	 *
	 */
	onToggleState() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ collapsed: !this.state.collapsed }, () => {
			CookieController.setCookie(COOKIE_TYPES.ASSET_UPLOAD_PANEL_COLLAPSED, this.state.collapsed);
		});
	}

	/**
	 *
	 * @param file
	 */
	onChange(file) {
		const { onChange } = this.props;
		if (onChange) {
			onChange(file);
		}
	}

	/**
	 *
	 * @param props
	 */
	initialize(props) {
		const { assetUuid, previewUrl, previewFileName } = props;

		// Load asset
		if (assetUuid) {
			this.loadAsset(assetUuid);
			// Just show specific file
		} else if (previewFileName && previewUrl) {
			this.setState({ asset: Asset.createFromPreviewUrl(previewFileName, previewUrl) });
		}
	}

	/**
	 *
	 * @param assetUuid
	 */
	loadAsset(assetUuid) {
		assetDataGet(assetUuid).then(
			(response) => {
				this.setState({ asset: new Asset(response.data) });
			},
			(err) => {
				console.log(err);
			}
		);
	}

	/**
	 *
	 */
	rotateAssetCW() {
		const { company } = this.props;
		const { asset, forceReloadAssetKey } = this.state;

		this.setState({ imageOutTransition: true });
		if (company && asset) {
			rotateCompanyAsset(company.id, asset.uuid, false).then(() => {
				this.setState({ forceReloadAssetKey: forceReloadAssetKey + 1, imageOutTransition: false });
			});
		}
	}

	/**
	 *
	 */
	rotateAssetCCW() {
		const { company } = this.props;
		const { asset, forceReloadAssetKey } = this.state;

		this.setState({ imageOutTransition: true });
		if (company && asset) {
			rotateCompanyAsset(company.id, asset.uuid, true).then(() => {
				this.setState({ forceReloadAssetKey: forceReloadAssetKey + 1, imageOutTransition: false });
			});
		}
	}

	/**
	 *
	 */
	downloadAsset() {
		const { company } = this.props;
		const { asset } = this.state;
		if (company && asset) {
			const fileUrl = buildCompanyAssetURL(company.id, asset.uuid, true);
			forceDownload(fileUrl);
		}
	}

	/**
	 *
	 */
	removeAsset() {
		const { onChange } = this.props;
		this.setState({ asset: null });
		if (onChange) {
			onChange(null);
		}
	}
}

AssetUpload.propTypes = {
	accept: PropTypes.string,
	className: PropTypes.string,
	assetUuid: PropTypes.string,
	previewUrl: PropTypes.string,
	previewFileName: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	company: PropTypes.instanceOf(Company)
};

AssetUpload.defaultProps = {
	accept: '.gif,.png,.jpg,.jpeg,.pdf,.txt,.csv',
	className: '',
	company: null
};

export default AssetUpload;
