import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (fromFinancialYearId, toFinancialYearId, draftIds) => {
	const url = EndPoints.MOVE_DRAFTS_TO_YEAR;
	let params = '?';

	params += `from=${fromFinancialYearId}`;
	params += `&to=${toFinancialYearId}`;

	draftIds.forEach((id) => {
		params += `&id=${id}`;
	});

	return fetch(Methods.POST, url + params, null, null, true);
};
