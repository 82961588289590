import { observable } from 'mobx';

/**
 *
 */
export default class CompanyCustomer {
	@observable id = undefined;

	@observable companyName = undefined;

	@observable contactPersonEmail = undefined;

	@observable contactPersonName = undefined;

	@observable info = undefined;

	@observable city = undefined;

	@observable postalCode = undefined;

	@observable address = undefined;

	@observable btwNr = undefined;

	@observable country = undefined;

	@observable countryCode = 'nl';

	@observable privatePerson = false;

	/**
	 *
	 * @param data
	 */
	constructor(data = {}) {
		this.id = null;
		this.update(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'id':
						this.id = data[key];
						break;
					case 'companyName':
						this.companyName = data[key];
						break;
					case 'contactPersonEmail':
						this.contactPersonEmail = data[key];
						break;
					case 'contactPersonName':
						this.contactPersonName = data[key];
						break;
					case 'info':
						this.info = data[key];
						break;
					case 'city':
						this.city = data[key];
						break;
					case 'postalCode':
						this.postalCode = data[key];
						break;
					case 'address':
						this.address = data[key];
						break;
					case 'countryCode':
						this.countryCode = data[key] ? data[key] : 'nl'; // Default country
						break;
					case 'privatePerson':
						this.privatePerson = data[key] ? data[key] : false;
						break;
					case 'submitting':
					case 'errors':
						// ignore
						break;
					default:
						this[key] = data[key];
				}
			}
		}

		return this;
	}

	/**
	 *
	 */
	clone() {
		const clone = new CompanyCustomer();

		for (const key in this) {
			if (this.hasOwnProperty(key) && typeof this[key] !== 'function') {
				switch (key) {
					default:
						clone[key] = this[key];
				}
			}
		}

		return clone;
	}

	/**
	 * Is this object the same as `companyCustomer`, ignores id
	 */
	isEqual(companyCustomer) {
		const keys = Object.keys(this);
		let isSame = true;
		keys.forEach((key) => {
			if (key !== 'id' && key !== 'submitting' && key !== 'errors') {
				if (this[key] !== companyCustomer[key]) {
					isSame = false;
				}
			}
		});

		return isSame;
	}

	/**
	 *
	 * @return {*}
	 */
	getCustomerName() {
		const customerName = this.privatePerson
			? this.contactPersonName
			: `${this.companyName} (${this.contactPersonName})`;
		return customerName === undefined ? null : customerName;
	}

	/**
	 *
	 * @param seperator
	 * @return {string}
	 */
	getAddress(seperator = ', ') {
		const fields = [this.address, this.postalCode, this.city];
		return fields.join(seperator);
	}

	/**
	 *
	 * @return {string}
	 */
	getLabel() {
		return `${this.getCustomerName()} - ${this.contactPersonEmail}`;
	}

	/**
	 *
	 * @return {string}
	 */
	toSearchString() {
		let searchString = '';
		searchString += this.companyName ? `${this.companyName} ` : '';
		searchString += this.contactPersonEmail ? `${this.contactPersonEmail} ` : '';
		searchString += this.contactPersonName ? `${this.contactPersonName} ` : '';

		return searchString.toLowerCase();
	}

	/**
	 *
	 * @return {string}
	 */
	toString() {
		return `CompanyCustomer: ${this.companyName} ${this.contactPersonEmail} ${this.address}, ${this.city}, ${this.postalCode}`;
	}

	/**
	 *
	 * @return {[]}
	 */
	toArray() {
		return [
			[this.contactPersonName, this.contactPersonEmail, this.companyName, this.address, this.info]
		];
	}
}
