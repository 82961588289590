import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { formatIBAN } from '../../../utils/ibanUtils';

/**
 *
 */
class IBANInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { value: undefined };
		this.input = null;
		this.isFocussed = false;

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const value = formatIBAN(this.input.value); // Initial formatting, add white spaces
		this.setState({ value });
	}

	/**
	 * @param nextProps
	 */
	componentWillReceiveProps(nextProps) {
		if (!this.isFocussed) {
			const value = formatIBAN(nextProps.value, true);
			this.setState({ value });
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className={`iban-input ${this.props.className ? this.props.className : ''}`}>
				<div
					className="iban-input__label"
					onClick={() => {
						this.input.focus();
					}}>
					{this.props.label}
				</div>
				<input
					className="iban-input__input"
					type="text"
					ref={(input) => {
						this.input = input;

						// Make sure React remembers cursor position, see https://github.com/facebook/react/issues/955
						if (input && this.state && !isNaN(parseInt(this.state.selectionStart, 10))) {
							input.selectionStart = this.state.selectionStart;
							input.selectionEnd = this.state.selectionEnd
								? this.state.selectionEnd
								: this.state.selectionStart;
						}
					}}
					required={this.props.required}
					name={this.props.name}
					value={this.state.value !== undefined ? this.state.value : this.props.value}
					placeholder={this.props.placeholder}
					onChange={this.onChange}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
				/>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		this.state.value = e.target.value;

		this.setState({
			selectionStart: this.input.selectionStart,
			selectionEnd: this.input.selectionEnd
		});
	}

	/**
	 *
	 * @param e
	 */
	onBlur(e) {
		this.isFocussed = false;
		this.setState({
			value: formatIBAN(e.target.value, true),
			selectionStart: null,
			selectionEnd: null
		});

		this._update(e);
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {
		this.isFocussed = true;
	}

	/**
	 *
	 * @param e
	 * @private
	 */
	_update(e) {
		if (this.props.onChange) {
			e.target.value = formatIBAN(this.state.value, false); // Always strip whitespaces of outgoing value
			this.props.onChange(e);
		}
	}
}

IBANInput.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	label: PropTypes.string
};

IBANInput.defaultProps = {
	placeholder: 'NLXX BANK 0000 0000 00',
	required: false,
	label: 'IBAN'
};

export default injectIntl(IBANInput);
