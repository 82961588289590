import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Signals from '../../../signals/Signals';

export default class AddButton extends React.Component {
	/**
	 *
	 */
	constructor() {
		super();

		this.state = {
			focus: false,
			shown: true
		};

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onClick = this.onClick.bind(this);

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.ShowModal.add(this.hide);
		Signals.ShowOverlay.add(this.hide);
		Signals.HideModal.add(this.show);
		Signals.HideOverlay.add(this.show);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.ShowModal.remove(this.hide);
		Signals.ShowOverlay.remove(this.hide);
		Signals.HideModal.remove(this.show);
		Signals.HideOverlay.remove(this.show);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'add-button--focus': this.state.focus,
			'add-button--hidden': !this.state.shown,
			'add-button--disabled': this.props.disabled
		});

		return (
			<div
				className={`add-button grid ${this.props.className ? this.props.className : ''} ${classes}`}
				title={this.props.title}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onClick={this.onClick}>
				<div className="add-button__icon icon icon--big-plus" />
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {
		this.setState({ focus: true });
	}

	/**
	 *
	 * @param e
	 */
	onBlur() {
		this.setState({ focus: false });
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	/**
	 *
	 */
	show() {
		// Show with small delay to allow overlay/modal
		// to animate out without this button jumping
		clearTimeout(this.showTimeout);
		this.showTimeout = setTimeout(() => {
			this.setState({ shown: true });
		}, 300);
	}

	/**
	 *
	 */
	hide() {
		clearTimeout(this.showTimeout);
		this.setState({ shown: false });
	}
}

AddButton.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	value: PropTypes.string,
	title: PropTypes.string,
	disabled: PropTypes.bool
};

AddButton.defaultProps = {};
