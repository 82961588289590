/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { ApplicationContext } from '../../../../ApplicationContext';
import FormGroup from '../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../components/ui/FormField/FormField';
import MollieConnectButton from '../../../../components/ui/MollieConnectButton/MollieConnectButton';
import DropDown from '../../../../components/ui/DropDown/DropDown';
import AlertMessage from '../../../../components/ui/AlertMessage/AlertMessage';
import GetMollieProfiles from '../../../../commands/mollie/GetMollieProfiles';
import UpdateInvoiceSettingsCommand from '../../../../commands/invoiceConcepts/UpdateInvoiceSettingsCommand';
import EndPoints from '../../../../data/EndPoints';
import RevokeOauthApplication from '../../../../commands/oauth/RevokeOauthApplication';
import OauthApplications from '../../../../data/OauthApplications';
import Signals from '../../../../signals/Signals';
import CanPayOnlineStatus from '../../../../data/CanPayOnlineStatus';

/**
 *
 */
@observer
class MollieIntegrationSettings extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onMollieProfileChange = this.onMollieProfileChange.bind(this);
		this.onMollieConnect = this.onMollieConnect.bind(this);
		this.onMollieRevoke = this.onMollieRevoke.bind(this);

		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);

		this.state = {};
	}

	/**
	 *
	 */
	componentDidMount() {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const command = new GetMollieProfiles(company);
		command.execute((profiles) => {
			const { invoicesSettings } = company;

			// Get currently set payment profile
			const molliePaymentProfile = invoicesSettings ? invoicesSettings.molliePaymentProfile : null;
			this.setState({ mollieProfiles: profiles, molliePaymentProfile });
		});
	}

	/**
	 *
	 */
	render() {
		const { authorizationStore } = this.props;
		const { errors } = this.state;

		return (
			<FormGroup className="padding padding-none--bottom" errors={errors}>
				<FormField className="col--12 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.integrations.mollie.title" />
					</label>
					<div className="col--12">
						<FormattedMessage id="invoice.settings.integrations.mollie.description" />
					</div>
				</FormField>

				{/* Alert messages when Mollie is authorized */}
				{authorizationStore.hasAuthorization(OauthApplications.MOLLIE)
					? this._getAlertMessages()
					: null}

				{/* Connect settings or setup settings based on authorization status */}
				{authorizationStore.hasAuthorization(OauthApplications.MOLLIE)
					? this._getMollieConnected()
					: this._getMollieSetup()}
			</FormGroup>
		);
	}

	/**
	 *
	 */
	onMollieConnect() {
		const { intl } = this.props;
		const company = this.context.applicationStore.getSelectedCompany();

		const command = new UpdateInvoiceSettingsCommand(intl, company, {});

		// Force update settings, to make sure we can store the profile later (backend)
		command.execute(
			() => {
				const url =
					window.config.apiPrefix +
					EndPoints.AUTHORIZE_COMPANY_APPLICATION.replace(':companyId', company.id).replace(
						':application',
						'MOLLIE'
					);
				window.open(url, '_self');
				// Error
			},
			(error) => {
				Signals.Error.dispatch(error);
			}
		);
	}

	/**
	 *
	 */
	onMollieRevoke() {
		const { intl, authorizationStore } = this.props;
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new RevokeOauthApplication(
			company,
			OauthApplications.MOLLIE,
			authorizationStore,
			intl
		);
		command.execute(
			() => {},
			(err) => {
				Signals.Error.dispatch(err);
			}
		);
	}

	/**
	 *
	 * @param e
	 */
	onMollieProfileChange(e) {
		this.setState({ molliePaymentProfile: e.target.value }, () => {
			const company = this.context.applicationStore.getSelectedCompany();
			const command = new UpdateInvoiceSettingsCommand(this.props.intl, company, this.state);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
		});
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		// Display success message
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage id="invoice.settings.update.success.message" />
		);
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}

	/**
	 *
	 * @return {null|*}
	 * @private
	 */
	_getAlertMessages() {
		const { intl } = this.props;
		const { mollieProfiles, molliePaymentProfile } = this.state;
		const messages = [];

		// Ignore if profiles still need to be retrieved
		if (!mollieProfiles || mollieProfiles.length === 0) {
			return null;
		}

		const activeMollieProfile = this.getActiveMollieProfile();

		// If mollieProfile in state is unavailable
		if (molliePaymentProfile && !activeMollieProfile) {
			messages.push(
				<AlertMessage
					title={intl.formatMessage({
						id: 'invoicemollieintegrationalert.PAYMENT_PROFILE_UNAVAILABLE'
					})}
					danger
				/>
			);

			// Has profiles but status isn't valid
		} else if (activeMollieProfile && activeMollieProfile.canPayOnline !== CanPayOnlineStatus.YES) {
			messages.push(
				<AlertMessage
					title={intl.formatMessage({
						id: `invoicemollieintegrationalert.${activeMollieProfile.canPayOnline}`
					})}
					danger
				/>
			);

			// Success
		} else {
			messages.push(
				<AlertMessage
					title={intl.formatMessage({ id: 'invoicemollieintegrationalert.success' })}
					success
					icon="icon--check-success"
				/>
			);
		}

		if (messages.length > 0) {
			return <FormField className="col--12 form-field__padding-bottom">{messages}</FormField>;
		}

		return null;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getMollieSetup() {
		const { intl } = this.props;
		return (
			<FormField className="col--12 form-field__padding-bottom">
				<MollieConnectButton onClick={this.onMollieConnect} />
				<label className="mollie-signup-intro">
					<FormattedMessage id="invoice.settings.integrations.mollie.signup.intro" />
				</label>

				<a
					className="mollie-signup-link"
					href={intl.formatMessage({ id: 'mollie.refferal.link' })}
					target="_blank"
					rel="noreferrer">
					<FormattedMessage id="invoice.settings.integrations.mollie.signup.link" />
				</a>

				<label className="mollie-signup-help-title">
					<FormattedMessage id="invoice.settings.integrations.mollie.signup.help.title" />
				</label>

				<div className="margin-small--bottom">
					<FormattedHTMLMessage id="invoice.settings.integrations.mollie.signup.help.link" />
				</div>
			</FormField>
		);
	}

	/**
	 *
	 * @return {{mollieProfiles: null}}
	 * @private
	 */
	_getMollieConnected() {
		const { mollieProfiles, molliePaymentProfile } = this.state;
		const hasActiveMollieProfile = this.hasActiveMollieProfile();

		console.log(
			'_getMollieConnected',
			hasActiveMollieProfile,
			hasActiveMollieProfile ? molliePaymentProfile : ''
		);

		return mollieProfiles ? (
			<FormField className="col--12 form-field__padding-bottom grid--spread">
				<div className="grid">
					<DropDown
						name="mollieProfile"
						value={hasActiveMollieProfile ? molliePaymentProfile : ''}
						onChange={this.onMollieProfileChange}>
						{this._getMollieProfiles()}
					</DropDown>
				</div>
				<button type="button" className="button-small--danger" onClick={this.onMollieRevoke}>
					<FormattedMessage id="invoice.settings.integrations.revoke" />
				</button>
			</FormField>
		) : null;
	}

	/**
	 *
	 * @return {null}
	 * @private
	 */
	_getMollieProfiles() {
		const { mollieProfiles } = this.state;

		const options = mollieProfiles.map((profile) => {
			return (
				<option key={`mp-${profile.id}`} value={profile.id}>
					{profile.name}
				</option>
			);
		});

		const activeMollieProfile = this.getActiveMollieProfile();
		if (!activeMollieProfile) {
			options.unshift(
				<option key="mp" value="">
					-
				</option>
			);
		}

		return options;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasActiveMollieProfile() {
		const { mollieProfiles, molliePaymentProfile } = this.state;
		return mollieProfiles && mollieProfiles.length > 0 && molliePaymentProfile;
	}

	/**
	 *
	 * @return {boolean|*}
	 */
	getActiveMollieProfile() {
		const { mollieProfiles, molliePaymentProfile } = this.state;

		// Ignore of no profiles available or no profile has been set
		if (!mollieProfiles || !molliePaymentProfile) {
			return false;
		}

		return mollieProfiles.find((profile) => {
			return profile.id === molliePaymentProfile;
		});
	}
}

MollieIntegrationSettings.contextType = ApplicationContext;

MollieIntegrationSettings.propTypes = {
	intl: PropTypes.object,
	authorizationStore: PropTypes.object.isRequired
};

export default injectIntl(MollieIntegrationSettings);
