/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { extractErrorMessage } from '../../../../../utils/extractErrorMessage';

import Paid from '../../../../../data/Paid';

import ExpenseRowModel from '../../../../../stores/models/ExpenseRowModel';

import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import RadioButton from '../../../../../components/ui/RadioButton/RadioButton';
import DateInput from '../../../../../components/ui/DateInput/DateInput';
import ExpenseRowInput from './ExpenseRowInput';

import { ApplicationContext } from '../../../../../ApplicationContext';

import AutocompleteResults from '../../../../../components/ui/AutocompleteResult/AutocompleteResults';
import AutocompleteResultRow from '../../../../../components/ui/AutocompleteResult/AutocompleteResultRow';
import AutocompleteSearchObject, {
	AutocompleteTypes
} from '../../../../../data/AutocompleteSearchObject';
import AutocompleteExpenseResult from '../../../../../components/ui/AutocompleteResult/AutocompleteExpenseResult';

import Company from '../../../../../stores/models/Company';
import FinancialYear from '../../../../../stores/models/FinancialYear';
import ExpenseModel from '../../../../../stores/models/ExpenseModel';

/**
 *
 */
@observer
class ExpenseEditor extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.onDateChange = this.onDateChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.addExpenseRow = this.addExpenseRow.bind(this);
		this.changeExpenseRow = this.changeExpenseRow.bind(this);
		this.removeExpenseRow = this.removeExpenseRow.bind(this);

		this.state = {};
	}

	/**
	 *
	 */
	componentWillMount() {
		this.company = this.context.applicationStore.getSelectedCompany();
		this.financialYear = this.context.applicationStore.getSelectedFinancialYear();
	}

	/**
	 *
	 */
	componentDidMount() {
		// Focus field with short delay, so all elements are mounted and rendered
		setTimeout(() => {
			if (this.el && this.props.fieldToFocus) {
				this.el.querySelector(`[name="${this.props.fieldToFocus}"]`).focus();
			}
		}, 100);
	}

	/**
	 *
	 */
	render() {
		//
		const { intl, expenseModel, financialYear, errors, lockInput } = this.props;
		const { autocompleteSearchObject } = this.state;

		// Render
		return (
			<div
				ref={(el) => {
					this.el = el;
				}}
				className="expense-editor">
				<div className="expense-editor__content padding-small">
					{/* Common settings for each row */}
					<FormGroup className="grid grid--bottom" errors={errors}>
						<FormField className="col--6 form-field__date-picker">
							<label>
								<FormattedMessage id="expense.input.date" />
							</label>
							<DateInput
								name="date"
								value={expenseModel.date}
								openToDate={new Date()}
								required
								autoComplete="off"
								onChange={this.onDateChange}
								disabled={lockInput}
							/>
						</FormField>

						<FormField className="col--6">
							<label>
								<FormattedMessage id="expense.input.projectnumber" />
							</label>
							<input
								name="projectcode"
								type="text"
								maxLength="255"
								autoComplete="off"
								value={expenseModel ? expenseModel.projectcode : null}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					</FormGroup>

					<FormGroup className="grid" errors={errors}>
						<FormField className="col--12">
							<label htmlFor="expense-customerName">
								<FormattedMessage id="expense.input.company" />
							</label>
							<input
								name="customerName"
								type="text"
								placeholder=""
								autoComplete="off"
								required
								maxLength="255"
								value={expenseModel ? expenseModel.customerName : null}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					</FormGroup>

					<FormGroup className="grid" errors={errors}>
						<FormField className="col--12" error={extractErrorMessage(errors, 'description')}>
							<label htmlFor="expense-description">
								<FormattedMessage id="expense.input.subject" />
							</label>
							<input
								name="description"
								autoComplete="off"
								type="text"
								required
								maxLength={250}
								value={expenseModel ? expenseModel.description : null}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					</FormGroup>
				</div>

				{/* Autocomplete suggestions */}
				<AutocompleteResults autocompleteSearchObject={autocompleteSearchObject}>
					{this._createAutocompleteResults()}
				</AutocompleteResults>

				{/* Rows */}
				<div className="expense-input__rows">
					{this._getExpenseRows()}
					{lockInput ? null : (
						<div
							className="expense-input__add-expense-row border--top border--dark icon icon--left icon--add-black"
							onClick={this.addExpenseRow}>
							<FormattedMessage id="expense.input.add.row" />
						</div>
					)}
				</div>

				{/* Common settings, paid before/after/not paid */}
				<div className="expense-input__receipt">
					<FormGroup error={extractErrorMessage(errors, 'state')}>
						<label className="col--12 margin-small--left">
							<FormattedMessage id="expense.input.paid" />
						</label>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.PAID_WITHIN_FYSCAL_YEAR}
								name="state"
								label={intl.formatMessage({ id: 'paid.within.year' }, { year: financialYear.year })}
								checked={expenseModel.state === Paid.PAID_WITHIN_FYSCAL_YEAR}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.PAID_AFTER_FYSCAL_YEAR}
								name="state"
								label={intl.formatMessage({ id: 'paid.after.year' }, { year: financialYear.year })}
								checked={expenseModel.state === Paid.PAID_AFTER_FYSCAL_YEAR}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.UNPAID}
								name="state"
								label={intl.formatMessage({ id: 'paid.unpaid' })}
								checked={expenseModel.state === Paid.UNPAID}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					</FormGroup>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		const { expenseModel } = this.props;
		expenseModel.date = date;
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const { expenseModel } = this.props;
		expenseModel[e.target.name] = e.target.value;

		// Update autocomplete only on customerName and description changes
		switch (e.target.name) {
			case 'customerName':
			case 'description':
				this._updateAutocomplete();
				break;
			default:
				break;
		}

		this.forceUpdate();
	}

	/**
	 *
	 */
	addExpenseRow() {
		const { expenseModel } = this.props;
		expenseModel.expenseRows.push(new ExpenseRowModel());
		this.forceUpdate();
	}

	/**
	 *
	 * @param rowIndex
	 */
	removeExpenseRow(rowIndex) {
		const { expenseModel } = this.props;
		expenseModel.expenseRows.splice(rowIndex, 1);
		this.forceUpdate();
	}

	/**
	 *
	 * @param rowIndex
	 * @param data
	 */
	changeExpenseRow(rowIndex, data) {
		const { expenseModel } = this.props;
		expenseModel.expenseRows[rowIndex].update(data);
		this.forceUpdate();
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getExpenseRows() {
		const { expenseModel, errors, lockInput } = this.props;

		return expenseModel.expenseRows.map((expenseRow, index) => {
			const key = `er-${index}-${expenseRow._uid}`;
			return (
				<ExpenseRowInput
					key={key}
					expenseData={expenseModel}
					errors={errors}
					rowIndex={index}
					amount={expenseRow.amount}
					btw={expenseRow.btw}
					lockInput={lockInput}
					ledgerCode={expenseRow.ledgerCode}
					btwCustomerNr={expenseRow.btwCustomerNr}
					investYears={expenseRow.investYears}
					investResidualValue={expenseRow.investResidualValue}
					onChange={this.changeExpenseRow}
					onRemove={this.removeExpenseRow}
				/>
			);
		});
	}

	/**
	 *
	 */
	_updateAutocomplete() {
		const { expenseModel } = this.props;
		const { applicationStore } = this.context;
		const autocompleteSearchObject = new AutocompleteSearchObject(AutocompleteTypes.TYPE_EXPENSES);

		const fields = ['customerName', 'description'];
		fields.forEach((field) => {
			autocompleteSearchObject.addField(field, expenseModel[field]);
		});

		const autocompleteResults = applicationStore.autocompleteStore.filter(autocompleteSearchObject);
		this.setState({
			autocompleteSearchObject,
			autocompleteResults
		});
	}

	/**
	 *
	 * @private
	 */
	_createAutocompleteResults() {
		const { autocompleteResults, autocompleteSearchObject } = this.state;

		if (autocompleteResults) {
			return autocompleteResults.map((expenseModel) => {
				return (
					<AutocompleteResultRow
						key={`arr-${expenseModel.id}`}
						rowData={expenseModel}
						onSelect={(rowData) => {
							this._autoFill(rowData);
						}}>
						<AutocompleteExpenseResult
							expenseModel={expenseModel}
							autocompleteSearchObject={autocompleteSearchObject}
						/>
					</AutocompleteResultRow>
				);
			});
		}

		return null;
	}

	/**
	 *
	 * @param expenseModel
	 * @private
	 */
	_autoFill(expenseModel) {
		this.props.expenseModel.copy(expenseModel);

		// Clear autocomplete suggestion results
		this.setState({
			autocompleteSearchObject: null,
			autocompleteResults: []
		});
	}
}

ExpenseEditor.contextType = ApplicationContext;

ExpenseEditor.propTypes = {
	intl: PropTypes.object,
	errors: PropTypes.array,
	fieldToFocus: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	originalExpenseModel: PropTypes.instanceOf(ExpenseModel).isRequired,
	expenseModel: PropTypes.instanceOf(ExpenseModel).isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	company: PropTypes.instanceOf(Company).isRequired,
	financialYear: PropTypes.instanceOf(FinancialYear).isRequired,
	lockInput: PropTypes.bool
};

export default injectIntl(ExpenseEditor);
