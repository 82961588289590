import { observable } from 'mobx';
import RSSParser from 'rss-parser';

/**
 *
 */
export default class BlogStore {
	@observable entries = [];

	@observable loading = false;

	@observable loaded = false;

	constructor() {
		this._onFeedDataLoaded = this._onFeedDataLoaded.bind(this);
	}

	fetch(lang = 'nl') {
		if (!this.loading || this.loaded) {
			const parser = new RSSParser();
			parser.parseURL(`${process.env.BLOG_FEED}?lang=${lang}`, this._onFeedDataLoaded);
		}
	}

	_onFeedDataLoaded(err, parsed) {
		try {
			const entries = [];
			const max = 2;

			for (let i = 0; i < Math.min(parsed.items.length, max); i++) {
				const entry = parsed.items[i];
				const parts = entry.content.split('src="');

				if (parts.length > 1) {
					entries.push({
						title: entry.title,
						image: parts[1].split('"')[0],
						date: new Date(entry.pubDate),
						description: entry.contentSnippet,
						link: entry.link
					});
				}
			}

			this.entries = entries;
			this.loaded = true;
		} catch (e) {
			console.log('BlogStore._onFeedDataLoaded', e);
		}

		this.loading = false;
	}
}
