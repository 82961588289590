/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedNumber } from 'react-intl';

import BTW from '../../../../data/BTW';
import InvoiceConceptRowModel from '../../../../stores/models/InvoiceConceptRowModel';
import InvoiceConceptModel from '../../../../stores/models/InvoiceConceptModel';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class InvoiceRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<tr className="table-row border--bottom">
				<td className="table-row__column invoice-row__description">
					<span
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: this.props.invoiceConceptRowModel.getDescriptionAsHTML()
						}}
					/>
				</td>

				{this._getUnits()}

				<td className="table-row__column invoice-row__unitprice">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.invoiceConceptRowModel.unitPrice}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>

				<td className="table-row__column invoice-row__btw">
					<span>
						{this.props.intl.formatMessage({
							id: BTW.getBTWObject(this.props.invoiceConceptRowModel.btw).translationId(
								this.props.invoiceConceptModel.date
							)
						})}
					</span>
				</td>

				<td className="table-row__column invoice-row__amount">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.invoiceConceptRowModel.amount}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @return {null}
	 * @private
	 */
	_getUnits() {
		return (
			<td className="table-row__column invoice-row__units">
				<span>
					{this.props.hideUnits ? null : (
						<FormattedNumber
							value={this.props.invoiceConceptRowModel.units}
							minimumFractionDigits={2}
							maximumFractionDigits={2}
						/>
					)}
				</span>
			</td>
		);
	}
}

InvoiceRow.contextType = ApplicationContext;

InvoiceRow.propTypes = {
	hideUnits: PropTypes.bool,
	invoiceConceptRowModel: PropTypes.instanceOf(InvoiceConceptRowModel),
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel).isRequired,
	intl: PropTypes.object
};

export default injectIntl(InvoiceRow);
