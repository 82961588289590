import { observable } from 'mobx';
import FinancialYear from './FinancialYear';
import Address from './Address';
// eslint-disable-next-line import/no-cycle
import User from './User';

import Signals from '../../signals/Signals';
import CompanyInvoiceSettings from './CompanyInvoiceSettings';
import CommunityProfile from './CommunityProfile';
import DraftsStore from '../DraftsStore';

import BTWPeriod from '../../data/BTWPeriod';
import FetchCompanyCommand from '../../commands/FetchCompanyCommand';
import CompanyOfferSettings from './CompanyOfferSettings';
import CompanyType from '../../data/CompanyType';
// eslint-disable-next-line import/no-cycle
import CompanyBrandModel from './CompanyBrandModel';

import PropertiesController from '../../controllers/PropertiesController';
/**
 *
 */
export default class Company {
	@observable id = -1;

	@observable name;

	@observable financialYears;

	// Additional data
	@observable acceptedContract;

	@observable address = {};

	@observable startCompany;

	@observable location;

	@observable activity;

	@observable website;

	@observable phoneNumber;

	@observable companyType;

	@observable companyTypeLabel;

	@observable email;

	@observable kvkNumber;

	@observable vatNumber;

	@observable vatId;

	@observable vatTerm;

	@observable iban;

	@observable owners = [];

	@observable contact;

	additionalProperties = null;

	@observable initialFetchComplete = false;

	invoicesSettings = new CompanyInvoiceSettings();

	offersSettings = new CompanyOfferSettings();

	communityProfile = new CommunityProfile();

	draftsStore = new DraftsStore(this);

	/**
	 * @returns {*|Promise<T>}
	 */
	fetch() {
		const fetchCommand = new FetchCompanyCommand(this);
		fetchCommand.execute(() => {
			this.initialFetchComplete = true;
			Signals.CompanyFetched.dispatch(this);
		});
	}

	/**
	 *
	 * @return {boolean}
	 */
	ignoreBTWNumber() {
		const isDemoEnv = PropertiesController.getProperty(PropertiesController.IS_DEMO_ENV);
		return isDemoEnv || this.vatTerm === BTWPeriod.VRIJGESTELD.name;
	}

	/**
	 *
	 * @return {boolean}
	 */
	ignoreBTWId() {
		return this.ignoreBTWNumber() || this.companyType !== CompanyType.EENMANSZAAK.id;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasVATNumber() {
		return !!this.vatNumber && !this.vatNumber.length < 2;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasVATId() {
		return !!this.vatId && !this.vatId.length < 2;
	}

	/**
	 *
	 * @return {*|boolean}
	 */
	hasIBAN() {
		return this.iban && this.iban.length > 0;
	}

	/**
	 *
	 * @param date
	 * @return {*}
	 */
	getVatNrOrId(date = new Date()) {
		return this.useBTWId(date) ? this.vatId : this.vatNumber;
	}

	/**
	 *
	 */
	useBTWId(date) {
		const btwIdStartDate = new Date(2019, 11, 31, 24, 0, 0);
		if (date && date.getTime() >= btwIdStartDate.getTime()) {
			return (
				!this.ignoreBTWNumber() &&
				!this.ignoreBTWId() &&
				this.companyType === CompanyType.EENMANSZAAK.id
			);
		}

		return false;
	}

	/**
	 *
	 * @return {boolean}
	 */
	ignoreHours() {
		return !(
			this.companyType === CompanyType.EENMANSZAAK.id || this.companyType === CompanyType.VOF.id
		);
	}

	/**
	 *
	 * @param year
	 * @return {boolean}
	 */
	hasPreviousFinancialYear(year) {
		if (this.financialYears) {
			return !!this.financialYears.find((financialYear) => {
				return financialYear.year === year - 1;
			});
		}

		return false;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const dateOffset = 12 * 3600 * 1000; // Add 12 hours to prevent rounding issues Edge
				switch (key) {
					case 'address':
						this[key] = Address.parseAddress(data[key]);
						break;
					case 'owners':
						this.owners = data.owners
							? data.owners.map((owner) => {
									return User.parseUserData(owner);
							  })
							: [];
						break;
					case 'contact':
						this.contact = data.contact ? User.parseUserData(data.contact) : undefined;
						break;
					case 'financialYears':
						break;
					case 'startCompany':
						this.startCompany = new Date(data[key] + dateOffset);
						break;
					case 'additonalProperties':
						return;
					default:
						this[key] = data[key] == null ? undefined : data[key];
				}
			}
		}
	}

	/**
	 *
	 * @param data
	 */
	parseAdditionalData(data) {
		if (!data) return;

		this.acceptedContract = data.acceptedContract;

		this.startCompany = data.startCompany ? new Date(data.startCompany) : undefined;
		this.location = Company._normalizeValue(data.location);
		this.activity = Company._normalizeValue(data.activity);
		this.website = Company._normalizeValue(data.website);
		this.phoneNumber = Company._normalizeValue(data.phoneNumber);
		this.companyType = Company._normalizeValue(data.companyType);
		this.companyTypeLabel = Company._normalizeValue(data.companyTypeLabel);
		this.email = Company._normalizeValue(data.email);

		this.kvkNumber = Company._normalizeValue(data.kvkNumber);
		this.vatNumber = Company._normalizeValue(data.vatNumber);
		this.vatId = Company._normalizeValue(data.vatId);
		this.vatTerm = data.vatTerm;
		this.iban = data.iban;

		this.owners = data.owners.map((owner) => {
			return User.parseUserData(owner);
		});

		this.contact = data.contact ? User.parseUserData(data.contact) : undefined;
		this.address = Address.parseAddress(data.address);
	}

	/**
	 *
	 * @return {CompanyBrandModel}
	 */
	get defaultCompanyBrand() {
		const companyBrand = CompanyBrandModel.fromCompany(this);
		companyBrand.id = -1;
		return companyBrand;
	}

	/**
	 *
	 * @returns {null}
	 */
	getFirstFinancialYear() {
		return this.financialYears.length > 0 ? this.financialYears[0] : null;
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getFinancialYearById(id) {
		let r = null;
		// eslint-disable-next-line consistent-return
		this.financialYears.forEach((year) => {
			if (year.id === id) {
				r = year;
				return false;
			}
		});

		return r;
	}

	/**
	 *
	 * @param year
	 * @returns {*}
	 */
	getFinancialYearByYear(year) {
		let r = null;
		// eslint-disable-next-line consistent-return
		this.financialYears.forEach((y) => {
			if (y.year === year) {
				r = y;
				return false;
			}
		});

		return r;
	}

	/**
	 *
	 * @returns {boolean}
	 */
	requiresOnBoarding() {
		const fields = [
			this.startCompany,
			this.activity,
			this.address.address,
			this.address.postalCode,
			this.address.city,
			this.phoneNumber,
			this.email,
			this.kvkNumber,
			this.vatNumber,
			this.vatId,
			this.vatTerm,
			this.acceptedContract
		];

		if (this.address) {
			fields.push(this.address.address);
			fields.push(this.address.postalCode);
			fields.push(this.address.city);
		}

		let isRequired = false;
		fields.forEach((field) => {
			if (!field) {
				console.log('\tcompany requires onboarding: ', field);
				isRequired = true;
			}
		});

		return isRequired;
	}

	/**
	 *
	 */
	toObject() {
		const result = {};

		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (key === 'address') {
					const address = this[key];
					if (address) {
						result.address = address.address;
						result.city = address.city;
						result.postalCode = address.postalCode;
					}
				} else {
					result[key] = this[key];
				}
			}
		}

		return result;
	}

	/**
	 * @param {string} feature
	 * @returns {boolean}
	 */
	isFeatureEnabled(feature) {
		if (!this.additionalProperties) return false;
		return this.additionalProperties[feature] === true;
	}

	/**
	 *
	 * @returns {string}
	 */
	toString() {
		let s = `Company: ${this.id}, ${this.name}`;
		this.financialYears.forEach((financialYear) => {
			s += `\n${financialYear.toString()}`;
		});

		return s;
	}

	static parseAdditionalProperties(data) {
		if (!data) return null;
		const result = {};
		Object.keys(data).forEach((key) => {
			result[key] = data[key] === 'true';
		});
		return result;
	}

	/**
	 *
	 * @param data
	 * @returns {Company}
	 */
	static parseCompany(data) {
		const company = new Company();

		if (!data) {
			return company;
		}

		company.id = data.id;
		company.name = data.name;

		// Check if we have financial years, othwerwise create empty array
		if (!data.financialYears) {
			data.financialYears = [];
		}

		if (data.additionalProperties) {
			company.additionalProperties = Company.parseAdditionalProperties(data.additionalProperties);
		}

		// Sort financial years by year
		data.financialYears.sort((a, b) => {
			return b.year - a.year;
		});
		company.financialYears = data.financialYears
			? data.financialYears.map((financialYearData) => {
					return FinancialYear.parseFinancialYear(financialYearData);
			  })
			: [];

		return company;
	}

	/**
	 *
	 * @param value
	 * @returns {undefined}
	 * @private
	 */
	static _normalizeValue(value) {
		return value || undefined;
	}

	/**
	 * Used to modify values before turning into JSON
	 * and prevent circular structure errors
	 *
	 * @param key
	 * @param value
	 * @return {boolean}
	 */
	static jsonReplacer(key, value) {
		switch (key) {
			case 'draftsStore':
				return undefined;
			default:
				return value;
		}
	}
}
