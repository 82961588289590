import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
export default class IconRadioButton extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { focus: false };

		this.uid = uniqueKey('irb-');
		this.radioButton = null;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.label = null;

		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'icon-radio-button--focus': this.state.focus,
			'icon-radio-button--selected': this.props.checked
		});

		return (
			<div
				className={`icon-radio-button ${classes} ${
					this.props.className ? this.props.className : ''
				}`}
				onClick={this.onClick}
				title={this.props.label}>
				<input
					id={this.uid}
					type="radio"
					ref={(radioButton) => (this.radioButton = radioButton)}
					name={this.props.name}
					value={this.props.value}
					defaultChecked={this.props.checked}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					required={this.props.required}
				/>
				<div className="icon-radio-button__check">
					<i className={`icon icon--${this.props.iconName}`} />
				</div>
				{/* eslint-disable-next-line react/no-unused-class-component-methods */}
				<label className="no-select" ref={(label) => (this.label = label)} htmlFor={this.uid}>
					<span>{this.props.label}</span>
				</label>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		console.log('IconRadioButton.onClick', e.target, e.currentTarget);
		this.radioButton.click();
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		console.log('IconRadioButton.onChange', e.target, e.currentTarget);
		if (this.props.onChange) this.props.onChange(e);
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {
		this.setState({ focus: true });
	}

	/**
	 *
	 * @param e
	 */
	onBlur() {
		this.setState({ focus: false });
	}
}

IconRadioButton.propTypes = {
	required: PropTypes.bool,
	className: PropTypes.string,
	iconName: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func
};
