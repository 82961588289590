import AbstractCommand from '../AbstractCommand';
import companyCustomerDelete from '../../requests/customers/companyCustomerDelete';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class DeleteCompanyCustomerCommand extends AbstractCommand {
	/**
	 *
	 * @param company
	 * @param customerId
	 */
	constructor(company, customerId) {
		super();
		this.company = company;
		this.customerId = customerId;
	}

	/**
	 *
	 * @param successCallback
	 * @param failCallback
	 */
	execute(successCallback, failCallback) {
		companyCustomerDelete(this.company.id, this.customerId)
			.then((response) => {
				successCallback(response);
			})
			.catch((err) => {
				Signals.Error.dispatch(err);
				if (failCallback) {
					failCallback(err);
				}
			});
	}
}
