import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useBankbookStatusStore from '../../utils/useBankbookStatusStore';
import useCompany from '../../utils/useCompany';
import useFiscalYear from '../../utils/useFiscalYear';
import Signals from '../../signals/Signals';
import CompanyProperties from '../../data/CompanyProperties';

function PontoGuard({ restricted, redirect, children }) {
	const company = useCompany();
	const financialYear = useFiscalYear();
	const bankbookStatusStore = useBankbookStatusStore();

	return (
		<Observer>
			{() => {
				console.log('PontoGuard', bankbookStatusStore);
				if (company && financialYear && bankbookStatusStore) {
					if (company.isFeatureEnabled(CompanyProperties.FEATURE_BANKBOOK)) {
						if (bankbookStatusStore.fetched) {
							// Ponto fetched
							if (
								(bankbookStatusStore.connected && bankbookStatusStore.agreement.accepted) !==
								restricted
							) {
								// Ponto not connected
								if (redirect) {
									// Redirect to Ponto
									console.log('PontoGuard: Restricted, redirecting to', redirect);
									Signals.RequestRoute.dispatch(
										redirect.getPath({ id: company.id, year: financialYear.id })
									);
								}
							} else {
								// Ponto connected
								console.log('PontoGuard: rendering children');
								return children;
							}
						} else {
							// Ponto not fetched
							console.log('PontoGuard: Fetching Ponto Integration data');
							bankbookStatusStore.fetch({
								companyId: company.id
							});
						}
					}
				}
				return null;
			}}
		</Observer>
	);
}

PontoGuard.propTypes = {
	restricted: PropTypes.bool,
	redirect: PropTypes.object,
	children: PropTypes.node.isRequired
};

PontoGuard.defaultProps = {
	restricted: false,
	redirect: null
};

export default PontoGuard;
