/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';

import BTW from '../../../../../data/BTW';

import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import DropDown from '../../../../../components/ui/DropDown/DropDown';
import NumberInput from '../../../../../components/ui/NumberInput/NumberInput';
import BTWInput from '../../../../../components/ui/BTWInput/BTWInput';

const SHOW_VERLEGD = [
	BTW.VERLEGD.name,
	BTW.BINNENLAND.name,
	BTW.BUITENLAND.name,
	BTW.EU.name,
	BTW.BUITEN_EU.name,
	BTW.MMOS.name,
	BTW.OSS.name
];
const SHOW_BUITENLAND = [
	BTW.BUITENLAND.name,
	BTW.EU.name,
	BTW.BUITEN_EU.name,
	BTW.MMOS.name,
	BTW.OSS.name
];
const SHOW_BTW_NUMBER = [BTW.EU.name];

/**
 *
 */
class IncomeRowInput extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = {
			amount: props.amount,
			btw: props.btw,
			btwCustomerNr: props.btwCustomerNr,
			rowIndex: props.rowIndex
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onRemoveClick = this.onRemoveClick.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		if (this.props.onChange) {
			const data = {};
			for (const key in this.state) {
				if (this.state.hasOwnProperty(key)) {
					switch (key) {
						case 'rowIndex':
							// Ignore
							break;
						default:
							data[key] = this.state[key];
							break;
					}
				}
			}

			// Trigger first change
			this.props.onChange(this.props.rowIndex, data);
		}
	}

	/**
	 *
	 */
	render() {
		const showVerlegd = SHOW_VERLEGD.indexOf(this.state.btw) >= 0;
		const showBuitenland = SHOW_BUITENLAND.indexOf(this.state.btw) >= 0;
		const showBTWNumber = SHOW_BTW_NUMBER.indexOf(this.state.btw) >= 0;

		const btwType = SHOW_VERLEGD.indexOf(this.state.btw) >= 0 ? BTW.VERLEGD.name : this.state.btw;
		// eslint-disable-next-line no-unused-vars
		const btwCountry =
			SHOW_BUITENLAND.indexOf(this.state.btw) >= 0 ? BTW.BUITENLAND.name : undefined;
		const btwContinent = SHOW_BTW_NUMBER.indexOf(this.state.btw) >= 0 ? this.state.btw : undefined;

		const amount = this.state.amount;
		const amountExclBTW = BTW.getAmountExclBTW(
			this.state.amount,
			this.state.btw,
			this.props.incomeData.date
		);
		const amountBTW = amount - amountExclBTW;

		// Normalise errors, strip off `invoiceRows[0].` and correct `vatNrCheck` to `btwCustomerNr`
		const rowKey = `invoiceRows[${this.props.rowIndex}]`;
		const rowErrors = this.props.errors
			? // eslint-disable-next-line array-callback-return
			  this.props.errors.map((error) => {
					if (error[0].indexOf(rowKey) >= 0) {
						return [
							error[0].split(`${rowKey}.`)[1].replace('vatNrCheck', 'btwCustomerNr'),
							error[1]
						];
					}
			  })
			: [];

		const { lockInput } = this.props;

		// Determine classes
		const classes = classNames({
			'income-row-input': true,
			'income-row-input__can-remove': this.state.rowIndex > 0,
			'income-row-input__locked': lockInput
		});

		return (
			<div className={classes}>
				<FormGroup errors={rowErrors}>
					{/* BTW */}
					<FormField className="col--3">
						<label className="col--12">
							<FormattedMessage id="income.input.btw" />
						</label>

						<DropDown
							name="btwType"
							className="col--12"
							value={btwType}
							onChange={this.onInputChange}
							disabled={lockInput}>
							<option value={BTW.HOOG.name}>
								{this.props.intl.formatMessage({ id: BTW.HOOG.translationId() })}
							</option>
							<option value={BTW.LAAG.name}>
								{this.props.intl.formatMessage({
									id: BTW.LAAG.translationId(this.props.incomeData.date)
								})}
							</option>
							<option value={BTW.VERLEGD.name}>
								{this.props.intl.formatMessage({ id: BTW.VERLEGD.translationId() })}
							</option>
							<option value={BTW.VRIJGESTELD.name}>
								{this.props.intl.formatMessage({ id: BTW.VRIJGESTELD.translationId() })}
							</option>
							<option value={BTW.GEEN.name}>
								{this.props.intl.formatMessage({ id: BTW.GEEN.translationId() })}
							</option>
						</DropDown>
					</FormField>

					<FormField className="col--3">
						<label className="col--12 label--no-wrap">
							<FormattedMessage id="income.input.amount.incl.btw" />
						</label>
						<NumberInput
							name="amount"
							value={amount}
							disabled={lockInput}
							onChange={this.onInputChange}
						/>
					</FormField>

					<FormField className="col--3">
						<label className="col--12 label--no-wrap">
							<FormattedMessage id="income.input.amount.excl.btw" />
						</label>
						<div className="form-field--fake-component">
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={amountExclBTW || 0}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</FormField>

					<FormField className="col--3">
						<label className="col--12">
							<FormattedMessage id="income.input.amount.btw" />
						</label>
						<div className="form-field--fake-component">
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={amountBTW || 0}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</FormField>

					{/* Verlegd */}
					{showVerlegd ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="income.input.btw.verlegd" />
							</label>

							<DropDown
								name="btwCountry"
								className="col--12"
								value={this.state.btw}
								onChange={this.onInputChange}
								disabled={lockInput}>
								<option value={BTW.BUITENLAND.name}>
									{this.props.intl.formatMessage({ id: BTW.BUITENLAND.translationId() })}
								</option>
								<option value={BTW.BINNENLAND.name}>
									{this.props.intl.formatMessage({ id: BTW.BINNENLAND.translationId() })}
								</option>
							</DropDown>
						</FormField>
					) : null}

					{/* Verlegd buitenland */}
					{showBuitenland ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="income.input.btw.outside" />
							</label>

							<DropDown
								name="btwContinent"
								value={this.state.btw ? this.state.btw : btwContinent}
								className="col--12"
								onChange={this.onInputChange}
								disabled={lockInput}>
								<option value={BTW.BUITEN_EU.name}>
									{this.props.intl.formatMessage({ id: BTW.BUITEN_EU.translationId() })}
								</option>
								<option value={BTW.EU.name}>
									{this.props.intl.formatMessage({ id: BTW.EU.translationId() })}
								</option>
								<option value={BTW.OSS.name}>
									{this.props.intl.formatMessage({ id: BTW.OSS.translationId() })}
								</option>
							</DropDown>
						</FormField>
					) : null}

					{/* BTW nummer klant */}
					{showBTWNumber ? (
						<FormField className="col--6">
							<label className="col--12">
								<FormattedMessage id="income.input.btw.number.klant" />
							</label>
							<BTWInput
								name="btwCustomerNr"
								value={this.state.btwCustomerNr}
								showLabel={false}
								required
								disabled={lockInput}
								placeholder="NL123456789B01"
								onChange={this.onInputChange}
								className={`${!this.props.isValidBTW ? 'error' : ''}`}
							/>
							{!this.props.isValidBTW && (
								<div className="form-field__error">
									{this.props.intl.formatMessage({ id: 'error.vatnumber' })}
								</div>
							)}
						</FormField>
					) : null}

					{/* Normal remove */}
					<div
						className="income-row-input__remove icon icon--delete-black"
						onClick={this.onRemoveClick}
					/>

					{/* Mobile remove */}
					<div className="grid grid--right col--12 income-row-input__remove-mobile">
						<button
							className="button--tertiary icon icon--left icon--delete-black"
							type="button"
							onClick={this.onRemoveClick}>
							<FormattedMessage id="label.remove" />
						</button>
					</div>
				</FormGroup>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'btwType':
			case 'btwCountry':
			case 'btwContinent':
				this.state.btw = BTW.getAdjustedBTWType(e.target.value);
				break;
			case 'amount':
				// Replace , with ., then parse to number
				this.state.amount = parseFloat(e.target.value.replace(/,/g, '.'));
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}

		if (this.props.onChange) {
			this.props.onChange(this.props.rowIndex, this.state);
		} else {
			// eslint-disable-next-line react/no-access-state-in-setstate
			this.setState(this.state);
		}
	}

	/**
	 *
	 */
	onRemoveClick() {
		if (this.props.onRemove) {
			this.props.onRemove(this.props.rowIndex);
		}
	}
}

IncomeRowInput.propTypes = {
	incomeData: PropTypes.object.isRequired,
	rowIndex: PropTypes.number.isRequired,
	amount: PropTypes.number,
	btw: PropTypes.string.isRequired,
	btwCustomerNr: PropTypes.string,
	isValidBTW: PropTypes.bool,

	onRemove: PropTypes.func,
	onChange: PropTypes.func,

	errors: PropTypes.array,

	lockInput: PropTypes.bool,

	intl: PropTypes.object
};
export default injectIntl(IncomeRowInput);
