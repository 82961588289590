import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId, invoiceId) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_INVOICE_CONCEPT_ID.replace(':id', companyId).replace(
			':invoiceconceptid',
			invoiceId
		),
		null,
		headers,
		true
	);
};
