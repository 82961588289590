/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import companyUpdate from '../../../requests/companyUpdate';
import Signals from '../../../signals/Signals';

import BTWPeriod from '../../../data/BTWPeriod';
import CompanyType from '../../../data/CompanyType';

import Page from '../../Page';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import DropDown from '../../../components/ui/DropDown/DropDown';
import DateInput from '../../../components/ui/DateInput/DateInput';
import BTWInput from '../../../components/ui/BTWInput/BTWInput';
import IBANInput from '../../../components/ui/IBANInput/IBANInput';

import { _formatDate } from '../../../utils/objectToFormData';
import { forceControlledValue, getStateValue } from '../../../utils/ReactUtils';
import { extractErrorMessage } from '../../../utils/extractErrorMessage';
import { ApplicationContext } from '../../../ApplicationContext';
import FileUpload from '../../../components/ui/FileUpload/FileUpload';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';
import CompanyAssetType from '../../../data/CompanyAssetType';
import companyAssetCreate from '../../../requests/companyAssetCreate';
import UpdateInvoiceSettingsCommand from '../../../commands/invoiceConcepts/UpdateInvoiceSettingsCommand';

/**
 *
 */
class CompanyInfo extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			submitting: false
		};

		this.onDateChange = this.onDateChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this._update();
	}

	/**
	 *
	 */
	componentWillReceiveProps() {
		this._update();
	}

	/**
	 *
	 */
	render() {
		const company = this.company;
		if (!company) {
			return null;
		}

		const { intl } = this.props;
		const { errors } = this.state;

		const formClasses = classNames({ 'form--submitting': this.state.submitting });
		const logoAssetUuid = company.invoicesSettings ? company.invoicesSettings.logoAssetUuid : null;
		const logoPreview = buildCompanyAssetURL(company.id, logoAssetUuid);

		return (
			<Page pageName="company-info">
				<div className="company-info__panel panel border border--dark">
					<div className="company-info__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="company.profile.title" values={{ name: company.name }} />
						</h2>
					</div>

					<form className={formClasses} onSubmit={this.onSubmit} onChange={this.onInputChange}>
						<div className="company-info__content">
							<FormGroup className="padding" errors={errors}>
								<FormField className="col--12 margin-none--bottom">
									<label>
										<FormattedMessage id="company.profile.field.owners" />
									</label>
									<div className="col--12">{this._getOwnerData()}</div>
								</FormField>
							</FormGroup>

							<FormGroup
								className="padding padding--bottom-none border--top border--dark"
								errors={errors}>
								<FormField className="col--12 ">
									<label>
										<FormattedMessage id="invoice.settings.logo" />
									</label>
									<FileUpload
										className="col--12"
										key={`fu-${company.id}`}
										name="logo"
										fileName={logoPreview ? 'logo.png' : null}
										filePreview={logoPreview}
										showFilePreview
										accept=".png,.jpg,.jpeg"
										label={intl.formatMessage({ id: 'fileupload.drag.file' })}
										onChange={this.onFileChange}
									/>
								</FormField>
							</FormGroup>

							<FormGroup className="grid padding padding--top-none" errors={errors}>
								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.name" />
									</label>
									<input name="name" type="text" value={company.name} disabled="disabled" />
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.type" />
									</label>
									<input
										name="companyType"
										type="text"
										value={intl.formatMessage({
											id: CompanyType.getObjectByID(company.companyType).translationId
										})}
										disabled="disabled"
									/>
								</FormField>

								<FormField
									className="col--5"
									error={extractErrorMessage(errors, 'address.address')}>
									<label>
										<FormattedMessage id="company.profile.field.address" />
									</label>
									<input
										name="address"
										type="text"
										value={this.state.address}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField
									className="col--3 company-info__postalcode-field"
									error={extractErrorMessage(errors, 'address.postalCode')}>
									<label>
										<FormattedMessage id="company.profile.field.postalcode" />
									</label>
									<input
										name="postalCode"
										type="text"
										value={this.state.postalCode}
										maxLength="7"
										required
										placeholder={intl.formatMessage({ id: 'placeholder.postalcode' })}
										onChange={this.onInputChange}
									/>
								</FormField>

								<FormField
									className="col--4 company-info__city-field"
									error={extractErrorMessage(errors, 'address.city')}>
									<label>
										<FormattedMessage id="company.profile.field.city" />
									</label>
									<input name="city" type="text" value={this.state.city} maxLength="255" required />
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.phonenumber" />
									</label>
									<input
										name="phoneNumber"
										type="tel"
										value={this.state.phoneNumber}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.email" />
									</label>

									{/* Add hidden input field with same name to catch autofill */}
									<input style={{ display: 'none' }} type="email" name="email" />
									<input
										name="email"
										ref={(ref) => (this.email = ref)}
										className="col--12"
										type="email"
										maxLength="255"
										autoComplete="off"
										value={this.state.email}
										required
										onFocus={() => this.email.removeAttribute('readonly')}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.activity" />
									</label>
									<input
										name="activity"
										type="text"
										maxLength="255"
										value={this.state.activity}
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.website" />
									</label>
									<input
										name="website"
										className="col--12"
										type="text"
										maxLength="255"
										value={this.state.website}
									/>
								</FormField>
							</FormGroup>

							<FormGroup className="border--top border--dark padding" errors={errors}>
								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.kvknumber" />
									</label>
									<input
										name="kvkNumber"
										className="col--12"
										type="text"
										maxLength="10"
										required
										value={this.state.kvkNumber}
										onChange={this.onInputChange}
									/>
								</FormField>

								<FormField className="col--6 form-field__date-picker">
									<label>
										<FormattedMessage id="company.profile.field.startcompany" />
									</label>
									<DateInput
										name="birthday"
										value={this.state.startCompany}
										required
										onChange={this.onDateChange}
									/>
								</FormField>

								{!company.ignoreBTWNumber() ? (
									<FormField className="col--6">
										<label>
											<FormattedMessage id="company.profile.field.vatnumber" />
										</label>
										<BTWInput
											name="vatNumber"
											className="company-info__vatnumber col--12"
											value={this.state.vatNumber}
											required
										/>
									</FormField>
								) : null}

								{!company.ignoreBTWId() ? (
									<FormField className="col--6">
										<label>
											<FormattedMessage id="company.profile.field.vatid" />
										</label>
										<BTWInput
											name="vatId"
											className="company-info__vatid col--12"
											value={this.state.vatId}
										/>
									</FormField>
								) : null}

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.vatterm" />
									</label>
									<DropDown name="vatTerm" className="col--12" value={company.vatTerm} disabled>
										{this._getBTWOptions()}
									</DropDown>
								</FormField>

								<FormField className="col--12">
									<label>
										<FormattedMessage id="company.profile.field.iban" />
									</label>
									<IBANInput name="iban" className="col--12" value={this.state.iban} />
								</FormField>
							</FormGroup>
						</div>

						<div className="options-footer company-info__footer border--top border--dark grid grid--right">
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		this.state.startCompany = date;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'email':
				this.state[e.target.name] = `${e.target.value}`.trim();
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 */
	onFileChange(file, fileName) {
		console.log('onFileChange', file, fileName);
		// Create
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', fileName);
			formData.append('type', CompanyAssetType.INVOICECONCEPT_LOGO);

			companyAssetCreate(this.company.id, formData)
				.then(this.onAssetCreated)
				.catch((error) => {
					console.log(error);
				});

			// Remove
		} else {
			const { intl } = this.props;
			const command = new UpdateInvoiceSettingsCommand(intl, this.company, { logoAssetUuid: null });
			command.execute();
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		if (response.data) {
			const { intl } = this.props;
			const command = new UpdateInvoiceSettingsCommand(intl, this.company, {
				logoAssetUuid: response.data.uuid
			});
			command.execute();
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		// Modify data
		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'city':
						data['address.city'] = this.state[key];
						break;
					case 'address':
						data['address.address'] = this.state[key];
						break;
					case 'postalCode':
						data['address.postalCode'] = this.state[key];
						break;
					case 'startCompany':
						try {
							data[key] = _formatDate(this.state[key]);
						} catch (e) {
							data[key] = null;
						}
						break;
					case 'errors':
					case 'submitting':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		if (Object.keys(data).length > 0) {
			this.setState({ errors: null, submitting: true });
			companyUpdate(this.company.id, data).then(this.onSubmitSuccess).catch(this.onSubmitError);
		} else {
			// Just show success message
			Signals.ShowMessageDialog.dispatch(<FormattedMessage id="company.profile.success.body" />);
		}
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		this.company.update(response.data);
		this.updateState();

		// Reload todos
		this.context.applicationStore.todoStore.fetch();

		//
		Signals.ShowMessageDialog.dispatch(<FormattedMessage id="company.profile.success.body" />);

		// Reset state
		this.setState({
			errors: null,
			submitting: false
		});
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	updateState() {
		const company = this.company;

		this.state.errors = [];
		this.state.address = forceControlledValue(company.address.address);
		this.state.postalCode = forceControlledValue(company.address.postalCode);
		this.state.city = forceControlledValue(company.address.city);
		this.state.phoneNumber = forceControlledValue(company.phoneNumber);
		this.state.email = forceControlledValue(company.email);
		this.state.activity = forceControlledValue(company.activity);
		this.state.website = forceControlledValue(company.website);
		this.state.kvkNumber = forceControlledValue(company.kvkNumber);
		this.state.startCompany = forceControlledValue(company.startCompany);
		this.state.vatNumber = getStateValue(company.vatNumber, null);
		this.state.vatId = getStateValue(company.vatId, null);
		this.state.iban = forceControlledValue(company.iban);

		this.forceUpdate();
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getOwnerData() {
		return this.company.owners.map((owner, index) => {
			return <div key={`own-${index}`}>{`${owner.getFullName()}`}</div>;
		});
	}

	/**
	 *
	 * @returns {any[]}
	 * @private
	 */
	_getBTWOptions() {
		const btwPeriods = [
			BTWPeriod.NONE,
			BTWPeriod.MONTH,
			BTWPeriod.QUARTER,
			BTWPeriod.YEAR,
			BTWPeriod.VRIJGESTELD
		];
		return btwPeriods.map((btwPeriod, index) => {
			return (
				<option key={`c-${index}`} value={btwPeriod.name}>
					{this.props.intl.formatMessage({ id: btwPeriod.translationId })}
				</option>
			);
		});
	}

	/**
	 *
	 * @private
	 */
	_update() {
		if (
			!this.context.applicationStore.isLoggedIn ||
			!this.context.applicationStore.currentRouteParams
		) {
			return null;
		}

		const companyId = parseInt(this.context.applicationStore.currentRouteParams.id, 10);
		this.company = this.context.applicationStore.user.getCompanyById(companyId);

		if (this.company) {
			// Set initial state values, these will be saved when clicking the save button
			this.updateState();
		}
	}
}

CompanyInfo.contextType = ApplicationContext;

CompanyInfo.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(CompanyInfo);
