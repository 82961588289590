import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, accountId, data) => {
	return fetch(
		Methods.PUT,
		`${EndPoints.COMPANIES_PONTO_ACCOUNT_DETAIL.replace(
			':accountId',
			accountId
		)}?companyId=${companyId}`,
		data,
		null,
		true
	);
};
