import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

import Signals from '../../signals/Signals';
import AbstractCommand from '../AbstractCommand';

import incomeDelete from '../../requests/incomeDelete';

/**
 *
 */
export default class DeleteIncomeCommand extends AbstractCommand {

	/**

	 */
	constructor(incomeModel, incomeStore) {
		super();

		this.incomeModel = incomeModel;
		this.incomeStore = incomeStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		incomeDelete(this.incomeModel).then(
			() => {
				this.incomeStore.removeIncome(this.incomeModel.id);
				Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="income.removed.message"
																		  values={{invoiceId: this.incomeModel.invoiceNr}}/>));
			}
		).catch((error) => {
			Signals.Error.dispatch(error);
		});
	}
}
