import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import objectToFormData from '../../utils/objectToFormData';
import {AutocompleteTypes} from '../../data/AutocompleteSearchObject';
import Signals from '../../signals/Signals';

import AbstractCommand from '../AbstractCommand';
import expenseCreate from '../../requests/expenseCreate';

/**
 *
 */
export default class CreateExpenseCommand extends AbstractCommand {

	/**

	 */
	constructor(applicationStore, expenseModel, file = null) {
		super();

		this.applicationStore = applicationStore;
		this.expenseStore = this.applicationStore.expenseStore;
		this.autocompleteStore = this.applicationStore.autocompleteStore;
		this.financialYear = this.applicationStore.getSelectedFinancialYear();

		this.expenseModel = expenseModel;
		this.file = file;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		const ignoreFields = [];
		if(!this.file) {
			ignoreFields.push('fileName', 'expenseNr');
		}

		// Create FormData
		const formData = objectToFormData(this.expenseModel, true, ignoreFields);

		// Add or remove file
		if(this.file) {
			formData.append('file', this.file);
		}

		//data, financialYearId
		return expenseCreate(formData, this.financialYear.id).then((response) => {
				const model = this.expenseStore.addExpense(response.data);
				this.autocompleteStore.add(model.clone(), AutocompleteTypes.TYPE_EXPENSES);

				Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="expense.added.message" values={{expenseId:response.data.expenseNr}}/>));

				if(success) {
					success(response);
				}
			}).catch((err) => {
				if(error) {
					error(err);
				}
			});
	}
}
