import React, { useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { HiOutlineExternalLink } from '@react-icons/all-files/hi/HiOutlineExternalLink';
import { autorun } from 'mobx';
import { marked } from 'marked';
import { IoMdSync } from 'react-icons/io';
import Signals from '../../../signals/Signals';
import { useKeyPress } from '../../../utils/useKeyPress';
import PontoLogo from '../../../../images/images_css/founders/ponto.svg';
import useFiscalYear from '../../../utils/useFiscalYear';
import useCompany from '../../../utils/useCompany';
import OutSideClickHandler from '../../../components/ui/OutSideClickHandler';
import FormattedMessageWithClass from '../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import useBankbookStatusStore from '../../../utils/useBankbookStatusStore';
import AcceptAgreementCommand from '../../../commands/bank/AcceptAggrementCommand';
import PontoIntegrationStore from '../../../stores/PontoIntegrationStore';

function PontoConnectModal() {
	const company = useCompany();
	const financialYear = useFiscalYear();
	const bankbookStatusStore = useBankbookStatusStore();
	const pontoIntegrationStore = new PontoIntegrationStore();
	const [accepted, setAccepted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const escKey = useKeyPress('Escape');

	const openPontoPage = () => {
		autorun(() => {
			if (pontoIntegrationStore.fetched && financialYear) {
				const state = {
					year: financialYear.id,
					companyId: company.id
				};
				const url = `${process.env.PONTO_URL}?\
client_id=${pontoIntegrationStore.clientId}\
&redirect_uri=${pontoIntegrationStore.redirectUri}\
&response_type=${process.env.PONTO_RESPONSE_TYPE}\
&scope=${process.env.PONTO_SCOPE}\
&state=${JSON.stringify(state)}\
&code_challenge=${pontoIntegrationStore.codeChallenge}\
&code_challenge_method=${process.env.PONTO_CODE_CHALLENGE_METHOD}`;
				window.open(url, '_self');
			}
		});
	};

	const handleEror = (error) => {
		setLoading(false);
		Signals.HideModal.dispatch();
		Signals.Error.dispatch(error);
	};

	const handlePontoConnect = () => {
		const saveAgreementCommand = new AcceptAgreementCommand();
		setLoading(true);
		if (bankbookStatusStore && company && pontoIntegrationStore && !loading) {
			if (!bankbookStatusStore.agreement.accepted) {
				saveAgreementCommand
					.execute(company.id, bankbookStatusStore)
					.then(() => {
						pontoIntegrationStore.fetch({ companyId: company.id }).then(() => {
							openPontoPage();
						});
					})
					.catch((error) => {
						handleEror(error);
					});
			} else {
				pontoIntegrationStore
					.fetch({
						companyId: company.id
					})
					.then(() => {
						openPontoPage();
					})
					.catch((error) => {
						handleEror(error);
					});
			}
		}
	};

	useEffect(
		() =>
			autorun(() => {
				if (bankbookStatusStore && bankbookStatusStore.agreement) {
					setAccepted(bankbookStatusStore.agreement.accepted);
				}
			}),
		[bankbookStatusStore]
	);

	useEffect(() => {
		if (escKey) {
			Signals.HideModal.dispatch();
		}
	}, [escKey]);

	return (
		<OutSideClickHandler onClickOutside={() => Signals.HideModal.dispatch()}>
			<div className="flex flex-col px-10 pt-7 pb-7 justify-start items-center max-w-[600px]">
				<div
					className="absolute top-3 right-3 w-10 hover:cursor-pointer icon icon--close-black"
					onClick={() => Signals.HideModal.dispatch()}
				/>
				<img className="w-20 h-20 mb-10 " src={PontoLogo} alt="Ponto" />
				<FormattedMessageWithClass
					className="text-center text-2xl font-bold w-full mb-7"
					id="company.bankbook.onboarding.modal.header1"
				/>
				<div className="text-left w-full">
					<FormattedMessageWithClass id="company.bankbook.onboarding.modal.body1" />
					<br />
					<FormattedHTMLMessage id="company.bankbook.onboarding.modal.body2" />
				</div>

				{!bankbookStatusStore.agreement.accepted && (
					<div className="m-5 mt-12 p-5  w-full h-[240px] bg-zinc-100 overflow-y-auto shadow-inner">
						<div
							className="text-left"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: marked.parse(bankbookStatusStore.agreement.termsOfService, {
									sanitize: false,
									breaks: true
								})
							}}
						/>
						<div
							className="bg-white w-fit flex flex-row space-x-4 justify-start items-center py-3 px-5 rounded-md shadow-md cursor-pointer"
							onClick={() => setAccepted(!accepted)}>
							<input
								className="appearance-none h-4 w-4 border border-zinc-200 rounded-sm bg-zinc-100 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer checked:bg-secondary-darker"
								type="checkbox"
								name="termsOfService"
								id="termsOfService"
								checked={accepted}
								onChange={(val) => {
									setAccepted(val);
								}}
							/>
							<div>
								<FormattedMessageWithClass id="company.bankbook.onboarding.modal.acceptTerms" />
							</div>
						</div>
					</div>
				)}
				<button
					type="button"
					className="flex flex-row justify-center items-center space-x-2 min-w-[230px] mt-12"
					onClick={handlePontoConnect}
					disabled={!accepted}>
					{loading ? (
						<IoMdSync className={loading ? 'animate-spin' : 'text-color-black'} />
					) : (
						<>
							<FormattedMessageWithClass
								className="text-center text-md w-full"
								id="company.bankbook.onboarding.modal.make"
							/>
							<HiOutlineExternalLink />
						</>
					)}
				</button>
			</div>
		</OutSideClickHandler>
	);
}

export default PontoConnectModal;
