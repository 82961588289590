import React from 'react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class Overlay extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.el = null;
		this.state = {
			showOverlay: false,
			content: null
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.onBackgroundClick = this.onBackgroundClick.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.ShowOverlay.add(this.show);
		Signals.HideOverlay.add(this.hide);
	}

	/**
	 *
	 */
	componentDidMount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = true;
	}

	/**
	 *
	 */
	componentWillUnmount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = false;

		Signals.ShowOverlay.remove(this.show);
		Signals.HideOverlay.remove(this.hide);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			overlay: true,
			'overlay--show': this.state.showOverlay,
			'overlay--hide': !this.state.showOverlay
		});

		return (
			<div ref={(ref) => (this.el = ref)} className={classes}>
				<div
					ref={(ref) => {
						this.backgroundElement = ref;
					}}
					className="overlay__background"
					onClick={this.onBackgroundClick}
				/>
				<div className="overlay__scroll-panel">
					<div
						ref={(ref) => {
							this.contentWrapperElement = ref;
						}}
						className="overlay__content-wrapper"
						onMouseDown={this.onBackgroundClick}>
						{this.state.content}
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onBackgroundClick(e) {
		if (e.target === this.contentWrapperElement || e.target === this.backgroundElement) {
			Signals.OverlayBackgroundClick.dispatch();
		}
	}

	/**
	 *
	 * @param content
	 */
	show(content) {
		if (!this.state.showOverlay) {
			clearTimeout(this.hideTimeout);
			this.setState({ content, showOverlay: true });
			this.lockPage();
		}
	}

	/**
	 *
	 */
	hide() {
		if (this.state.showOverlay) {
			clearTimeout(this.hideTimeout);
			this.setState({ showOverlay: false });

			this.hideTimeout = setTimeout(() => {
				this.unlockPage();
				this.setState({ content: null });
			}, 300);
		}
	}

	/**
	 *
	 */
	lockPage() {
		this.lockTarget = document.querySelector('.page');
		if (this.lockTarget) {
			this.el.style.display = 'block';
			this.lockTargetBoundingClientRect = this.lockTarget.getBoundingClientRect();
			this.lockTarget.classList.add('page--locked');
			this.lockTarget.style.top = `${this.lockTargetBoundingClientRect.y}px`;
			window.scrollTo(0, 0);
		}
	}

	/**
	 *
	 */
	unlockPage() {
		if (this.lockTarget) {
			this.lockTarget.style.top = 'inherit';
			this.lockTarget.classList.remove('page--locked');
			this.lockTarget.scrollTop = this.lockTargetBoundingClientRect.y;
			this.el.style.display = 'none';
			window.scrollTo(0, -this.lockTargetBoundingClientRect.y);
		}

		this.lockTargetBoundingClientRect = null;
		this.lockTarget = null;
	}
}
