import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 *
 */
export default class Tag extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div
				className={`tag tag--${this.props.type} ${
					this.props.className ? this.props.className : ''
				}`}
				onClick={this.onClick}>
				<FormattedMessage id={this.props.labelId} values={this.props.values} />
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}
}

Tag.propTypes = {
	labelId: PropTypes.string.isRequired,
	className: PropTypes.string,
	type: PropTypes.string,
	values: PropTypes.object,
	onClick: PropTypes.func
};

Tag.defaultProps = {
	type: 'default'
};
