import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import moment from 'moment';

import OverlayPanel from '../../../../../components/ui/Panel/OverlayPanel';
import Draft from '../../../../../stores/models/Draft';
import Signals from '../../../../../signals/Signals';

import { ApplicationContext } from '../../../../../ApplicationContext';

import CompanyAssetType from '../../../../../data/CompanyAssetType';
import DraftTypes from '../../../../../data/DraftTypes';

import AssetUpload from '../../../../../components/ui/AssetUpload/AssetUpload';
import PanelHeader from '../../../../../components/ui/Panel/PanelHeader';
import PanelFooter from '../../../../../components/ui/Panel/PanelFooter';
import IncomeModel from '../../../../../stores/models/IncomeModel';

import IncomeEditor from './IncomeEditor';
import ModalAlert from '../../../../../components/ui/Modal/ModalAlert';
import UpdateIncomeCommand from '../../../../../commands/income/UpdateIncomeCommand';
import CreateIncomeCommand from '../../../../../commands/income/CreateIncomeCommand';
import DeleteCompanyDraftCommand from '../../../../../commands/drafts/DeleteCompanyDraftCommand';
import UpdateCompanyDraftsCommand from '../../../../../commands/drafts/UpdateCompanyDraftsCommand';
import ModalConfirm from '../../../../../components/ui/Modal/ModalConfirm';
import companyAssetCreate from '../../../../../requests/companyAssetCreate';
import CreateCompanyDraftCommand from '../../../../../commands/drafts/CreateCompanyDraftCommand';
import { Routes } from '../../../../../data/Routes';
import { uniqueKey } from '../../../../../utils/ReactUtils';

/**
 *
 */
@observer
class IncomeInputPanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onRemove = this.onRemove.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onSubmit = this.onSubmit.bind(this);
		this.onEditInvoiceConcept = this.onEditInvoiceConcept.bind(this);

		this.onAssetFile = this.onAssetFile.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);
		this.onAssetError = this.onAssetError.bind(this);

		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.resetBTWErrorsOnChange = this.resetBTWErrorsOnChange.bind(this);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.el = null;
		this.content = null;

		this.state = {
			singleSubmit: false,
			originalIncomeModel: null,
			incomeModel: null,
			draft: null
		};
	}

	/**
	 *
	 */
	componentWillMount() {
		const { incomeModel, draft } = this.props;

		// Use IncomeModel as base
		if (incomeModel) {
			this.setState({
				originalIncomeModel: incomeModel.clone(),
				incomeModel: incomeModel.clone(),
				draft
			});

			// Use Draft as base
		} else if (this.props.draft) {
			this.setState({
				originalIncomeModel: IncomeModel.createNewIncomeFromDraft(draft),
				incomeModel: IncomeModel.createNewIncomeFromDraft(draft),
				draft
			});
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.KeyDown.add(this.onKeyDown);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 */
	render() {
		const { intl, className, fieldToFocus, suggestInvoiceNr } = this.props;
		const { incomeModel, originalIncomeModel, submitting, errors, draft } = this.state;
		const { applicationStore } = this.context;
		const formDisabled = incomeModel.isFromInvoiceConcept();

		const financialYear = applicationStore.getSelectedFinancialYear();
		const company = applicationStore.getSelectedCompany();

		if (!financialYear || !company) {
			return null;
		}

		const classes = classNames({
			'income-input-panel': true,
			'income-input-panel--scrollable':
				this.content && this.content.scrollHeight > this.content.clientHeight
		});
		const formClasses = classNames({
			'income-input-panel__right': true,
			'form--submitting': submitting
		});

		let titleId = incomeModel.id ? 'income.input.edit.title' : 'income.input.new.title';
		titleId = draft ? 'income.input.draft.title' : titleId;

		const previewUrl = incomeModel.fileName ? window.config.imageIncome + incomeModel.id : null;

		return (
			<OverlayPanel className={`${classes} ${className}`}>
				{/* Image/PDF/File */}
				<div className="income-input-panel__left">
					<AssetUpload
						assetUuid={incomeModel.assetUuid}
						previewUrl={previewUrl}
						previewFileName={incomeModel.fileName}
						onChange={this.onAssetFile}
						company={company}
						disabled={formDisabled}
					/>
				</div>

				{/* Input/Data */}
				<form className={formClasses} onSubmit={this.onSubmit}>
					<PanelHeader>
						<h2 className="pad-left">
							<FormattedMessage id={titleId} values={{ id: draft ? `#${draft.id}` : '' }} />
						</h2>
						<div
							className="overlay-panel__close icon icon icon--close-black"
							onClick={this.onCancel}
						/>
					</PanelHeader>

					<div
						ref={(ref) => {
							this.content = ref;
						}}
						className="income-input-panel__form-container">
						<fieldset>
							<IncomeEditor
								company={company}
								financialYear={financialYear}
								incomeModel={incomeModel}
								originalIncomeModel={originalIncomeModel}
								fieldToFocus={fieldToFocus}
								submitting={submitting}
								errors={errors}
								lockInput={formDisabled}
								suggestInvoiceNr={suggestInvoiceNr}
								resetBTWErrors={this.resetBTWErrorsOnChange}
							/>
						</fieldset>
					</div>

					<PanelFooter>
						<div className="income-input-panel__footer__left grid">
							{draft ? (
								<button
									className="button button-small--danger"
									type="button"
									onClick={this.onRemove}>
									<FormattedMessage id="label.remove" />
								</button>
							) : null}
						</div>
						<div className="income-input-panel__footer__right grid">
							{incomeModel.isNew() ? (
								<button className="button" type="button" onClick={() => this.doSaveDraft()}>
									<FormattedMessage id="drafts.label.save.draft" />
								</button>
							) : null}

							{incomeModel.isFromInvoiceConcept() ? (
								<button className="button" type="button" onClick={this.onEditInvoiceConcept}>
									<FormattedMessage id="label.edit.invoice" />
								</button>
							) : null}

							<input
								className="button--primary btn-margin-left"
								type="submit"
								value={intl.formatMessage({
									id: draft || incomeModel.isNew() ? 'drafts.label.save.income' : 'label.save'
								})}
							/>
						</div>
					</PanelFooter>
				</form>
			</OverlayPanel>
		);
	}

	/**
	 *
	 */
	onRemove() {
		const { intl } = this.props;
		const { draft } = this.state;
		const title = intl.formatMessage({ id: 'draft.remove.alert.title' }, { id: draft.id });
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
				onConfirm={() => this.doRemove()}
			/>
		);
	}

	/**
	 *
	 */
	onCancel() {
		const { intl } = this.props;

		if (this.hasChanged()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={intl.formatMessage({ id: 'income.input.discard.alert' })}
					yesLabel={intl.formatMessage({ id: 'label.yes.discard' })}
					noLabel={intl.formatMessage({ id: 'label.no.keep' })}
					onConfirm={() => this.close()}
				/>
			);
		} else {
			this.close();
		}
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		// esc
		if (e.keyCode === 27) {
			this.onCancel();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			Signals.HideOverlay.dispatch();
		}
	}

	/**
	 *
	 * @param file
	 */
	onAssetFile(file) {
		const { incomeModel } = this.state;

		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', file.name);
			formData.append('type', CompanyAssetType.DRAFT);

			const company = this.context.applicationStore.getSelectedCompany();

			companyAssetCreate(company.id, formData).then(this.onAssetCreated).catch(this.onAssetError);
		} else {
			incomeModel.assetUuid = null;
			incomeModel.fileName = null;
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		const { incomeModel } = this.state;

		// If IncomeModel
		if (incomeModel) {
			incomeModel.assetUuid = response.data.uuid;
			incomeModel.fileName = response.data.filename;
		}
	}

	/**
	 *
	 * @param err
	 */
	onAssetError(err) {
		Signals.Error.dispatch(err);
	}

	/**
	 *
	 */
	onEditInvoiceConcept(event) {
		event.preventDefault();

		const { incomeModel } = this.state;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		Signals.RequestRoute.dispatch(
			Routes.COMPANY_INVOICES_EDIT.getPath({
				id: company.id,
				invoiceId: incomeModel.invoiceConceptId
			})
		);
	}

	/**
	 *
	 */
	onSubmit(e) {
		const { intl } = this.props;
		const { incomeModel } = this.state;
		const { applicationStore } = this.context;

		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		// Check if date is valid
		if (!this.isDateValid()) {
			this.setState({ errors: [['date', intl.formatMessage({ id: 'errors.dateFormat' })]] });

			// Check date range
		} else if (!this.isDateInRange()) {
			const financialYear = applicationStore.getSelectedFinancialYear();
			const enteredYear = moment(incomeModel.date).year();

			Signals.ShowModal.dispatch(
				<ModalAlert
					title={intl.formatMessage(
						{ id: 'income.input.dateoutofrange.alert.title' },
						{
							year: financialYear.year,
							enteredYear
						}
					)}
					body={intl.formatMessage(
						{ id: 'income.input.dateoutofrange.alert.body' },
						{
							year: financialYear.year,
							enteredYear
						}
					)}
				/>
			);
		} else {
			if (incomeModel.invoiceRows.length > 0) {
				for (const row of incomeModel.invoiceRows) {
					if (!row.isValidBTW) {
						return;
					}
				}
			}
			// All good
			this.doSubmitIncome();
		}
	}

	/**
	 *
	 * @param response
	 * @param showNext
	 */
	onSubmitSuccess(response, showNext = true) {
		const { onInvoiceCreated } = this.props;
		const { draft } = this.state;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		// Close when callback is active
		showNext = onInvoiceCreated ? false : showNext;

		// Delete Draft when succesfully saved as Income
		if (draft) {
			const command = new DeleteCompanyDraftCommand(company.draftsStore, company.id, draft, true);
			command.execute(() => {
				// Show next draft
				this.showNextDraft();
			});
		} else {
			// Show new income input
			// eslint-disable-next-line no-lonely-if
			if (showNext) {
				this.showNewIncome();
			} else {
				if (onInvoiceCreated) {
					onInvoiceCreated(response);
				}
				Signals.HideOverlay.dispatch();
			}
		}
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	doSaveDraft() {
		this.setState({ errors: null, submitting: true });

		const { incomeModel, draft } = this.state;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const financialYear = applicationStore.getSelectedFinancialYear();

		// Update draft
		if (draft) {
			// Update draft data
			draft.data = JSON.stringify(incomeModel);

			// Save
			const command = new UpdateCompanyDraftsCommand(company.id, draft);
			command.execute(
				() => Signals.HideOverlay.dispatch(),
				() => {
					this.setState({ submitting: false });
				}
			);

			// Create new draft
		} else {
			const draft = new Draft();
			draft.data = JSON.stringify(incomeModel);
			draft.assetUuid = incomeModel.assetUuid;
			const command = new CreateCompanyDraftCommand(
				company.draftsStore,
				company.id,
				DraftTypes.INCOME,
				null,
				financialYear.id,
				draft
			);
			command.execute(
				() => {
					Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="draft.added.message" />);
					Signals.HideOverlay.dispatch();
				},
				() => {
					this.setState({ submitting: false });
				}
			);
		}
	}

	/**
	 *
	 */
	doSubmitIncome() {
		const { applicationStore } = this.context;
		const { file, incomeModel } = this.state;

		this.setState({ errors: null, submitting: true });

		// Update
		if (incomeModel.id && incomeModel.id >= 0) {
			const command = new UpdateIncomeCommand(applicationStore, incomeModel, file);
			command.execute((response) => this.onSubmitSuccess(response, false), this.onSubmitError);

			// Create new
		} else {
			const command = new CreateIncomeCommand(applicationStore, incomeModel, file);
			command.execute((repsonse) => this.onSubmitSuccess(repsonse, true), this.onSubmitError);
		}
	}

	/**
	 *
	 */
	doRemove() {
		/**
		 * draftsStore, companyId, draft
		 * @type {DeleteCompanyDraftCommand}
		 */
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const { draft } = this.state;
		const { draftsStore } = company;

		const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft);
		command.execute(() => Signals.HideOverlay.dispatch());
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasChanged() {
		const { incomeModel, originalIncomeModel } = this.state;
		return !incomeModel.equals(originalIncomeModel);
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDateValid() {
		const { incomeModel } = this.state;
		return !(
			!incomeModel.date ||
			(incomeModel.date instanceof Date && isNaN(incomeModel.date.getTime()))
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDateInRange() {
		const { incomeModel } = this.state;
		const { applicationStore } = this.context;
		const financialYear = applicationStore.getSelectedFinancialYear();

		const m = moment(incomeModel.date);
		return m.year() === financialYear.year;
	}

	/**
	 *
	 */
	showNextDraft() {
		// Always close overlay first
		Signals.HideOverlay.dispatch();

		const { applicationStore } = this.context;
		const { intl } = this.props;
		const company = applicationStore.getSelectedCompany();
		const financialYear = applicationStore.getSelectedFinancialYear();
		const { draftsStore } = company;

		const nextDrafts = draftsStore.getDraftsOfType(DraftTypes.INCOME, financialYear.id);
		if (nextDrafts.length > 0) {
			setTimeout(() => {
				Signals.ShowOverlay.dispatch(
					<IncomeInputPanel key={uniqueKey('iip-')} draft={nextDrafts[0]} intl={intl} />
				);
			}, 250);
		}
	}

	/**
	 *
	 */
	showNewIncome() {
		// Always close overlay first
		Signals.HideOverlay.dispatch();

		const { intl } = this.props;

		// Show IncomeInput overlay
		setTimeout(() => {
			Signals.ShowOverlay.dispatch(
				<IncomeInputPanel
					key={uniqueKey('iip-')}
					incomeModel={IncomeModel.createNewIncome()}
					suggestInvoiceNr
					intl={intl}
				/>
			);
		}, 250);
	}

	resetBTWErrorsOnChange = () => {
		const { errors } = this.state;

		if (errors && errors.length > 0) {
			const filteredErrors = errors.filter((error) => !error[0].includes('btwCustomerNr'));
			this.setState({ errors: filteredErrors });
		}
	};
}

IncomeInputPanel.contextType = ApplicationContext;

IncomeInputPanel.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	incomeModel: PropTypes.instanceOf(IncomeModel),
	draft: PropTypes.instanceOf(Draft),
	fieldToFocus: PropTypes.string,
	suggestInvoiceNr: PropTypes.bool,
	onInvoiceCreated: PropTypes.func
};

IncomeInputPanel.defaultProps = {
	className: '',
	suggestInvoiceNr: false
};

export default injectIntl(IncomeInputPanel);
