/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Routes } from '../../data/Routes';

import Signals from '../../signals/Signals';

import Page from '../Page';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';
import { ApplicationContext } from '../../ApplicationContext';

/**
 *
 */
class Login extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			submitting: false
		};

		this.login = this.login.bind(this);
		this.onError = this.onError.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		let userNameType = 'email';
		if (window.config.debug) {
			userNameType = 'text';
		}

		const errorClass = classNames({ error: this.state.error });
		const formClasses = classNames({
			login__form: true,
			'form--submitting': this.state.submitting
		});

		return (
			<Page pageName="login">
				<div className="login__content">
					<div className="login__logo" />

					<div className="login__panel">
						<form className={formClasses} name="login" onSubmit={this.login}>
							<FormGroup error={this.state.error}>
								<FormField className="col--12">
									<label>
										<FormattedMessage id="login.field.email" />
									</label>
									<input
										className={errorClass}
										type={userNameType}
										name="username"
										required
										placeholder={this.props.intl.formatMessage({ id: 'login.field.email' })}
									/>
								</FormField>
								<FormField className="col--12">
									<label>
										<FormattedMessage id="login.field.password" />
									</label>
									<input
										className={errorClass}
										type="password"
										name="password"
										required
										placeholder={this.props.intl.formatMessage({ id: 'login.field.password' })}
									/>
								</FormField>
							</FormGroup>

							<FormGroup>
								<FormField className="col--12">
									<input
										className="login__submit-button button--primary"
										type="submit"
										value={this.props.intl.formatMessage({ id: 'login.field.submit' })}
									/>

									<Link to={Routes.FORGOT_PASSWORD.pathName}>
										<button
											type="button"
											className="button--tertiary button--no-border login__forgot-button">
											<FormattedMessage id="login.field.forgot" />
										</button>
									</Link>
								</FormField>
							</FormGroup>
						</form>
					</div>

					<div className="login__appointment-link">
						<div className="login__header">
							<h3>
								<FormattedMessage id="login.tagline" />
							</h3>
						</div>
						<a
							href={this.props.intl.formatMessage({ id: 'login.link.register.url' })}
							target="_self">
							<FormattedMessage id="login.link.register" />
						</a>
					</div>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param user
	 */
	onSuccess(_user) {
		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		let messageId = 'error.login';

		try {
			switch (error.error.response.statusText) {
				case 'Authentication Failed: User account has expired':
					messageId = 'error.login.expired';
					break;
				case 'Authentication Failed: Bad credentials':
					messageId = 'error.login';
					break;
				default:
					break;
			}
		} catch (e) {
			// Ignore
		}

		const errorMessage = this.props.intl.formatMessage({ id: messageId });
		this.setState({ error: errorMessage, submitting: false });
	}

	/**
	 *
	 * @param e
	 */
	login(e) {
		e.preventDefault();

		if (this.state.submitting) {
			return;
		}

		this.setState({ error: null, submitting: true });

		const form = e.target;
		const userName = form.querySelector('input[name="username"]').value.trim();
		const password = form.querySelector('input[name="password"]').value;

		Signals.LoggedIn.addOnce(this.onSuccess);
		this.context.applicationStore.login(userName, password, this.onError);
	}
}

Login.contextType = ApplicationContext;

Login.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Login);
