export const WALKTHROUGH_ARROW = { NORMAL: 'normal', LEFT: 'left', RIGHT: 'right' };

/**
 *
 */
export default class WalkthroughStep {
	route;

	targetPreScript;

	targetPostScript;

	targetSelector;

	targetOffsetX = 0;

	targetOffsetY = 0;

	scroll = 0;

	arrow = WALKTHROUGH_ARROW.NORMAL;

	hideArrow = false;

	clickable = false;

	step;

	titleId = 'walkthrough.title.step';

	bodyId = 'walkthrough.body.step';

	/**
	 *
	 */
	constructor(data) {
		this.parse(data);
	}

	/**
	 *
	 * @param data
	 */
	parse(data) {
		if (!data) return;

		const keys = Object.keys(data);
		keys.forEach((key) => {
			this[key] = data[key];
		});
	}
}
