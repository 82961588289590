/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';

import SeenUserMessageCommand from '../../commands/userMessages/SeenUserMessageCommand';
import UnseeUserMessageCommand from '../../commands/userMessages/UnseeUserMessageCommand';

import Signals from '../../signals/Signals';
import UserMessage from '../../stores/models/UserMessage';

import { ApplicationContext } from '../../ApplicationContext';
import UserMessageActions from '../../components/ui/ToolTip/tooltips/UserMessageActions';
import Truncate from '../../components/ui/Truncate/Truncate';

/**
 *
 */
@observer
class UserMessageRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
	}

	/**
	 *
	 */
	render() {
		const { publicationDate, type, title, subTitle, readAt } = this.props.userMessage;

		const sortBy = this.context.applicationStore.userMessagesStore.sortBy;
		const sortByClasses = sortBy ? `user-message-row--sorted-by--${sortBy.toLowerCase()}` : '';
		const readAtClasses = !readAt ? 'user-message-row--unread' : '';

		return (
			<tr className={`user-message-row table-row border--bottom ${sortByClasses} ${readAtClasses}`}>
				<td
					className="table-row__column table-row__column--editable user-message-row__publicationdate"
					onClick={() => this.onFieldClick('publicationdate')}>
					<FormattedDate value={publicationDate} day="numeric" month="long" year="numeric" />
					&nbsp;
					{/* <FormattedTime value={publicationDate}/> */}
				</td>
				<td
					className="table-row__column table-row__column--editable user-message-row__type"
					onClick={() => this.onFieldClick('type')}>
					<FormattedMessage id={`usermessage.type.${type}`.toLowerCase()} />
				</td>
				<td
					className="table-row__column table-row__column--editable user-message-row__title"
					onClick={() => this.onFieldClick('title')}>
					<Truncate>{title}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable user-message-row__subtitle"
					onClick={() => this.onFieldClick('subtitle')}>
					<Truncate>{subTitle}</Truncate>
				</td>
				{/* <td className="table-row__column user-message-row__body">{body}</td> */}
				<td
					className="table-row__column text--center table-row__actions user-message-row__action"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
				</td>
			</tr>
		);
	}

	onActionClick(e) {
		const target = e.currentTarget;
		const offsetTop = -e.currentTarget.getBoundingClientRect().height * 0.5 + 16;
		Signals.ShowToolTip.dispatch(
			<UserMessageActions onSelect={this.onActionSelect} hideTypes={[]} />,
			target,
			offsetTop
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'view':
				this.markAsUnread();
				this.view();
				break;
			case 'read':
				this.markAsRead();
				break;
			case 'unread':
				this.markAsUnread();
				break;
			default:
				break;
		}
	}

	onFieldClick() {
		this.view();
	}

	/**
	 *
	 */
	view() {
		// Show message
		Signals.ShowUserMessagesDialog.dispatch([this.props.userMessage]);
	}

	/**
	 *
	 */
	markAsRead() {
		const command = new SeenUserMessageCommand(
			this.props.userMessage,
			this.context.applicationStore.userMessagesStore
		);
		command.execute();
	}

	/**
	 *
	 */
	markAsUnread() {
		const command = new UnseeUserMessageCommand(
			this.props.userMessage,
			this.context.applicationStore.userMessagesStore
		);
		command.execute();
	}
}

UserMessageRow.contextType = ApplicationContext;

UserMessageRow.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object,
	userMessage: PropTypes.instanceOf(UserMessage)
};

export default injectIntl(UserMessageRow);
