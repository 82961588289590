/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import { computed, observable, action } from 'mobx';
import _ from 'lodash';

import InvoiceConceptStatus from '../data/InvoiceConceptStatus';

import InvoiceConceptModel from './models/InvoiceConceptModel';
import InvoiceConceptRowModel from './models/InvoiceConceptRowModel';
import FilterRuleHasOneOrMoreValues from './filters/FilterRuleHasOneOrMoreValues';
import Filter from './filters/Filter';

/**
 *
 */
export default class InvoicesConceptStore {
	filter = new Filter();

	statusFilterRule = new FilterRuleHasOneOrMoreValues('status');

	bookyearFilter = new FilterRuleHasOneOrMoreValues('bookyear');

	@observable invoices = [];

	@observable searchFilter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable fetched = false; // Has invoice concept store been fetched at all?

	@observable fetching = false;

	autofocusField = null;

	isArchiveStore = false;

	/**
	 *
	 */
	constructor(isArchiveStore = false) {
		this.isArchiveStore = isArchiveStore;

		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);

		this.filter.addRule(this.statusFilterRule);
		this.filter.addRule(this.bookyearFilter);
	}

	/**
	 *
	 */
	reset() {
		this.searchFilter = null;
		this.sortBy = null;
		this.sortDirection = 1;
		this.statusFilterRule.value = null;

		this.invoices = [];

		this.fetched = false;
		this.fetching = false;
	}

	/**
	 *
	 * @returns {*[]}
	 */
	@computed get filteredAndSortedInvoiceConcepts() {
		return this.invoices
			.filter((invoiceConcept) => {
				return this._filter(invoiceConcept);
			})
			.sort(this._sort);
	}

	/**
	 *
	 * @returns {*[]}
	 */
	@computed get importantInvoiceConcepts() {
		return this.invoices
			.filter((invoiceConcept) => {
				return (
					invoiceConcept.status === InvoiceConceptStatus.LATE ||
					invoiceConcept.status === InvoiceConceptStatus.SENT_MANUALLY ||
					invoiceConcept.status === InvoiceConceptStatus.SENT_BY_EMAIL_KDB ||
					invoiceConcept.status === InvoiceConceptStatus.SENT_REMINDER_KDB
				);
			})
			.sort((a, b) => {
				let c = this._compareStatus(a.status, b.status);
				if (c === 0) {
					c =
						// eslint-disable-next-line no-nested-ternary
						a.expirationDate < b.expirationDate ? -1 : a.expirationDate > b.expirationDate ? 1 : 0;
				}

				return c;
			});
	}

	/**
	 *
	 * @return {this}
	 */
	getUnarchivedYears() {
		const years = [];
		this.invoices.forEach((invoiceConceptModel) => {
			if (
				invoiceConceptModel.status !== InvoiceConceptStatus.ARCHIVED &&
				invoiceConceptModel.status !== InvoiceConceptStatus.DELETED
			) {
				years.push(invoiceConceptModel.date.getFullYear());
			}
		});

		return _.uniq(years).sort();
	}

	/**
	 *
	 * @return {number}
	 */
	getArchivedInvoiceCount() {
		return this.invoices.filter((invoiceConcept) => {
			return invoiceConcept.status === InvoiceConceptStatus.ARCHIVED;
		}).length;
	}

	/**
	 *
	 */
	getLastUsedInvoice() {
		const sortedById = this.invoices.sort((a, b) => {
			// No id means new Invoice is last added
			if (!a.id) {
				return 1;
			}
			if (!b.id) {
				return -1;
			}

			return b.id - a.id;
		});

		//
		if (sortedById.length > 0) {
			return sortedById[0];
			// First invoice!
		}
		return null;
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getInvoiceConceptById(id) {
		return this.invoices.find((invoice) => {
			return invoice.id === id;
		});
	}

	/**
	 *
	 * @param id
	 */
	removeInvoiceConcept(id) {
		const invoice = this.invoices.find((i) => {
			return i.id === id;
		});

		this.invoices.remove(invoice);
	}

	/**
	 *
	 * @param invoiceConceptModel
	 */
	@action addInvoiceConcept(invoiceConceptModel) {
		const index = this.invoices.findIndex((invoice) => invoice.id === invoiceConceptModel.id);

		if (index >= 0) {
			this.invoices[index] = invoiceConceptModel;
		} else {
			this.invoices.push(invoiceConceptModel);
		}
	}

	/**
	 * @param invoiceConceptModels Array
	 */
	@action addAll(invoiceConceptModels) {
		const invoices = this.invoices.concat([]);
		invoiceConceptModels.forEach((invoiceConceptModel) => {
			const index = invoices.findIndex((invoice) => invoice.id === invoiceConceptModel.id);

			if (index >= 0) {
				invoices[index] = invoiceConceptModel;
			} else {
				invoices.push(invoiceConceptModel);
			}
		});

		this.invoices = invoices;
	}

	/**
	 *
	 * @param invoiceConceptModel
	 */
	updateInvoiceConcept(invoiceConceptModel) {
		const invoice = this.invoices.find((i) => {
			return i.id === invoiceConceptModel.id;
		});

		if (invoice) {
			this._updateInvoiceConcept(invoiceConceptModel, invoice);

			// Force update
			this.removeInvoiceConcept(invoice.id);
			this.invoices.push(invoice);
		} else {
			console.error('invoice concet not found');
		}
	}

	/**
	 *
	 */
	update() {
		this.invoices = this.invoices.concat([]);
	}

	/**
	 *
	 * @param data
	 * @returns {InvoiceConceptModel}
	 * @private
	 */
	_parseInvoiceConcept(data) {
		const invoiceConcept = new InvoiceConceptModel();
		this._updateInvoiceConcept(data, invoiceConcept);
		return invoiceConcept;
	}

	/**
	 *
	 * @param data
	 * @param invoiceConcept
	 * @private
	 */
	_updateInvoiceConcept(data, invoiceConcept) {
		invoiceConcept.update(data);
		invoiceConcept.invoiceConceptRows = [];

		if (!data.invoiceConceptRows) {
			data.invoiceConceptRows = [];
		}

		data.invoiceConceptRows.forEach((invoiceRow) => {
			invoiceConcept.invoiceConceptRows.push(
				new InvoiceConceptRowModel(invoiceRow, invoiceConcept.date)
			);
		});
	}

	/**
	 * @param invoiceConcept
	 * @returns {boolean}
	 * @private
	 */
	_filter(invoiceConcept) {
		if (!invoiceConcept) {
			return true;
		}

		//
		if (
			(!this.isArchiveStore && invoiceConcept.status === InvoiceConceptStatus.ARCHIVED) ||
			(this.isArchiveStore && invoiceConcept.status !== InvoiceConceptStatus.ARCHIVED) ||
			invoiceConcept.status === InvoiceConceptStatus.DELETED
		) {
			return false;
		}

		// Apply Filter
		if (!this.filter.apply(invoiceConcept)) {
			return false;
		}

		// Apply Search filter
		if (!this.searchFilter) {
			return true;
		}

		const searchString = invoiceConcept.toSearchString();
		return searchString.indexOf(this.searchFilter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		let aNumber;
		let bNumber;
		switch (this.sortBy) {
			case 'invoiceNr':
				aNumber = parseInt(a.invoiceNr, 10);
				bNumber = parseInt(b.invoiceNr, 10);
				if (aNumber && bNumber) {
					result = aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0;
				} else if (a.invoiceNr) {
					result = a.invoiceNr.localeCompare(b.invoiceNr);
				} else {
					return -1;
				}
				break;
			case 'companyName':
				const companyNameA = a.companyCustomer.getCustomerName(); // a.companyCustomer && a.companyCustomer.companyName ? a.companyCustomer.companyName : null;
				const companyNameB = b.companyCustomer.getCustomerName(); // b.companyCustomer && b.companyCustomer.companyName ? b.companyCustomer.companyName : null;

				if (!companyNameA && !companyNameB) {
					result = 0;
				} else if (companyNameA && !companyNameB) {
					result = 1;
				} else if (companyNameB && !companyNameA) {
					result = -1;
				} else {
					result = companyNameA.localeCompare(companyNameB);
				}
				break;
			case 'date':
				result = a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
				break;
			case 'expirationDate':
				result =
					a.expirationDate < b.expirationDate ? -1 : a.expirationDate > b.expirationDate ? 1 : 0;
				break;
			case 'statusDateChanged':
				result =
					a.statusDateChanged > b.statusDateChanged
						? -1
						: a.statusDateChanged < b.statusDateChanged
						? 1
						: 0;
				break;
			case 'amount':
				result = a.getTotal() - b.getTotal();
				break;
			case 'subject':
				result = a.subject.localeCompare(b.subject);
				break;
			case 'status':
			default:
				result = this._compareStatus(a.status, b.status);
				if (result === 0) {
					switch (a.status) {
						// sort by oldest first
						case InvoiceConceptStatus.SENT_MANUALLY:
						case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
						case InvoiceConceptStatus.LATE:
						case InvoiceConceptStatus.SENT_REMINDER_KDB:
						case InvoiceConceptStatus.CREATED:
						case InvoiceConceptStatus.PAID:
						case InvoiceConceptStatus.ARCHIVED:
							// Sort by invoicenr
							aNumber = parseInt(a.invoiceNr, 10);
							bNumber = parseInt(b.invoiceNr, 10);
							if (aNumber && bNumber) {
								result = aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0;
							} else if (a.invoiceNr) {
								result = a.invoiceNr.localeCompare(b.invoiceNr);
							} else {
								return -1;
							}

							// result = a.expirationDate < b.expirationDate ? -1 : (a.expirationDate > b.expirationDate ? 1 : 0);
							break;
						default:
							return 1;
					}
				}
				break;
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}

	/**
	 *
	 * @param statusA
	 * @param statusB
	 * @return {number}
	 * @private
	 */
	_compareStatus(statusA, statusB) {
		const weightA = this._getStatusWeight(statusA);
		const weightB = this._getStatusWeight(statusB);

		return weightA - weightB;
	}

	/**
	 * \
	 * @param status
	 * @return {number}
	 * @private
	 */
	_getStatusWeight(status) {
		switch (status) {
			case InvoiceConceptStatus.CREATED:
				return 0;
			case InvoiceConceptStatus.SENT_REMINDER_KDB:
				return 1;
			case InvoiceConceptStatus.LATE:
				return 2;
			case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
			case InvoiceConceptStatus.SENT_MANUALLY:
				return 3;
			case InvoiceConceptStatus.PAID:
				return 4;
			case InvoiceConceptStatus.ARCHIVED:
				return 5;
			case InvoiceConceptStatus.DELETED:
				return 5;
			default:
				return 7;
		}
	}
}
