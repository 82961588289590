/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import ProfileItem from '../Profiles/ProfileItem';
import { ApplicationContext } from '../../../ApplicationContext';

import CompanyCustomer from '../../../stores/models/CompanyCustomer';
import Company from '../../../stores/models/Company';

/**
 *
 */
@observer
export default class ClientHeader extends React.Component {
	/**
	 *
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<header className="client-header client-header grid grid--spread grid--no-wrap border--bottom border--dark">
				<div className="client-header__left grid grid--vcenter">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
					<a href="#" className="kdb-logo" />
					<div className="client-header__seperator" />
					<ProfileItem userCompany={this.props.company} hideSettings />
				</div>

				<div className="client-header__right grid grid--vcenter">
					<label>
						{this.props.forCompanyLabel} {this.props.companyCustomer.getCustomerName()}
					</label>
				</div>
			</header>
		);
	}
}

ClientHeader.contextType = ApplicationContext;

ClientHeader.propTypes = {
	forCompanyLabel: PropTypes.string.isRequired,
	company: PropTypes.instanceOf(Company).isRequired,
	companyCustomer: PropTypes.instanceOf(CompanyCustomer).isRequired
};
