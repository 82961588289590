import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import moment from 'moment';

import OverlayPanel from '../../../../../components/ui/Panel/OverlayPanel';
import Draft from '../../../../../stores/models/Draft';
import Signals from '../../../../../signals/Signals';

import { ApplicationContext } from '../../../../../ApplicationContext';

import AssetUpload from '../../../../../components/ui/AssetUpload/AssetUpload';
import PanelHeader from '../../../../../components/ui/Panel/PanelHeader';
import PanelFooter from '../../../../../components/ui/Panel/PanelFooter';
import ExpenseModel from '../../../../../stores/models/ExpenseModel';

import ModalAlert from '../../../../../components/ui/Modal/ModalAlert';

import DeleteCompanyDraftCommand from '../../../../../commands/drafts/DeleteCompanyDraftCommand';
import UpdateCompanyDraftsCommand from '../../../../../commands/drafts/UpdateCompanyDraftsCommand';
import ExpenseEditor from './ExpenseEditor';
import UpdateExpenseCommand from '../../../../../commands/expenses/UpdateExpenseCommand';
import CreateExpenseCommand from '../../../../../commands/expenses/CreateExpenseCommand';

import ModalConfirm from '../../../../../components/ui/Modal/ModalConfirm';
import CompanyAssetType from '../../../../../data/CompanyAssetType';
import companyAssetCreate from '../../../../../requests/companyAssetCreate';
import DraftTypes from '../../../../../data/DraftTypes';
import CreateCompanyDraftCommand from '../../../../../commands/drafts/CreateCompanyDraftCommand';
import { uniqueKey } from '../../../../../utils/ReactUtils';

/**
 *
 */
@observer
class ExpenseInputPanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onRemove = this.onRemove.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onSubmit = this.onSubmit.bind(this);

		this.onAssetFile = this.onAssetFile.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);
		this.onAssetError = this.onAssetError.bind(this);

		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.el = null;
		this.content = null;
		this.state = {
			singleSubmit: false,
			originalExpenseModel: null,
			expenseModel: null,
			draft: null
		};
	}

	/**
	 *
	 */
	componentWillMount() {
		const { expenseModel, draft } = this.props;

		// Use ExpenseModel as base
		if (expenseModel) {
			this.setState({
				originalExpenseModel: expenseModel.clone(),
				expenseModel: expenseModel.clone(),
				draft
			});

			// Use Draft as base
		} else if (this.props.draft) {
			this.setState({
				originalExpenseModel: ExpenseModel.createNewExpenseFromDraft(draft),
				expenseModel: ExpenseModel.createNewExpenseFromDraft(draft),
				draft
			});
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.KeyDown.add(this.onKeyDown);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 */
	render() {
		const { intl, className, fieldToFocus } = this.props;
		const { expenseModel, originalExpenseModel, submitting, errors, draft } = this.state;
		const { applicationStore } = this.context;
		const formDisabled = !expenseModel.mayBeChanged;

		const financialYear = applicationStore.getSelectedFinancialYear();
		const company = applicationStore.getSelectedCompany();

		if (!financialYear || !company) {
			return null;
		}

		const classes = classNames({
			'expense-input-panel': true,
			'expense-input-panel--scrollable':
				this.content && this.content.scrollHeight > this.content.clientHeight
		});
		const formClasses = classNames({
			'expense-input-panel__right': true,
			'form--submitting': submitting
		});

		let titleId = expenseModel.id ? 'expense.input.edit.title' : 'expense.input.new.title';
		titleId = draft ? 'expense.input.draft.title' : titleId;

		const previewUrl = expenseModel.fileName ? window.config.imageExpense + expenseModel.id : null;

		return (
			<OverlayPanel className={`${classes} ${className}`}>
				{/* Image/PDF/File */}
				<div className="expense-input-panel__left">
					<AssetUpload
						assetUuid={expenseModel.assetUuid}
						previewUrl={previewUrl}
						previewFileName={expenseModel.fileName}
						onChange={this.onAssetFile}
						company={company}
					/>
				</div>

				{/* Input/Data */}
				<form className={formClasses} onSubmit={this.onSubmit}>
					<PanelHeader>
						<h2 className="pad-left">
							<FormattedMessage id={titleId} values={{ id: draft ? `#${draft.id}` : '' }} />
						</h2>
						<div
							className="overlay-panel__close icon icon icon--close-black"
							onClick={this.onCancel}
						/>
					</PanelHeader>

					<div
						ref={(ref) => {
							this.content = ref;
						}}
						className="expense-input-panel__form-container">
						<ExpenseEditor
							company={company}
							financialYear={financialYear}
							expenseModel={expenseModel}
							originalExpenseModel={originalExpenseModel}
							fieldToFocus={fieldToFocus}
							submitting={submitting}
							lockInput={formDisabled}
							errors={errors}
						/>
					</div>

					{formDisabled ? null : (
						<PanelFooter>
							<div className="expense-input-panel__footer__left grid">
								{draft ? (
									<button
										className="button button-small--danger"
										type="button"
										onClick={this.onRemove}>
										<FormattedMessage id="label.remove" />
									</button>
								) : null}
							</div>
							<div className="expense-input-panel__footer__right grid">
								{expenseModel.isNew() ? (
									<button className="button" type="button" onClick={() => this.doSaveDraft()}>
										<FormattedMessage id="drafts.label.save.draft" />
									</button>
								) : null}

								<input
									className="button--primary btn-margin-left"
									type="submit"
									value={intl.formatMessage({
										id: draft || expenseModel.isNew() ? 'drafts.label.save.expense' : 'label.save'
									})}
								/>
							</div>
						</PanelFooter>
					)}
				</form>
			</OverlayPanel>
		);
	}

	/**
	 *
	 */
	onRemove() {
		const { intl } = this.props;
		const { draft } = this.state;
		const title = intl.formatMessage({ id: 'draft.remove.alert.title' }, { id: draft.id });
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
				onConfirm={() => this.doRemove()}
			/>
		);
	}

	/**
	 *
	 */
	onCancel() {
		const { intl } = this.props;

		if (this.hasChanged()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={intl.formatMessage({ id: 'expense.input.discard.alert' })}
					yesLabel={intl.formatMessage({ id: 'label.yes.discard' })}
					noLabel={intl.formatMessage({ id: 'label.no.keep' })}
					onConfirm={() => this.close()}
				/>
			);
		} else {
			this.close();
		}
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		// esc
		if (e.keyCode === 27) {
			this.onCancel();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			Signals.HideOverlay.dispatch();
		}
	}

	/**
	 *
	 * @param file
	 */
	onAssetFile(file) {
		const { expenseModel } = this.state;

		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', file.name);
			formData.append('type', CompanyAssetType.DRAFT);

			const company = this.context.applicationStore.getSelectedCompany();

			companyAssetCreate(company.id, formData).then(this.onAssetCreated).catch(this.onAssetError);
		} else {
			expenseModel.assetUuid = null;
			expenseModel.fileName = null;
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		const { expenseModel } = this.state;

		// If ExpenseModel
		if (expenseModel) {
			expenseModel.assetUuid = response.data.uuid;
			expenseModel.fileName = response.data.filename;
		}
	}

	/**
	 *
	 * @param err
	 */
	onAssetError(err) {
		Signals.Error.dispatch(err);
	}

	/**
	 *
	 */
	onSubmit(e) {
		const { intl } = this.props;
		const { expenseModel } = this.state;
		const { applicationStore } = this.context;

		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		// Check if date is valid
		if (!this.isDateValid()) {
			this.setState({ errors: [['date', intl.formatMessage({ id: 'errors.dateFormat' })]] });

			// Check date range
		} else if (!this.isDateInRange()) {
			const financialYear = applicationStore.getSelectedFinancialYear();
			const enteredYear = moment(expenseModel.date).year();

			Signals.ShowModal.dispatch(
				<ModalAlert
					title={intl.formatMessage(
						{ id: 'expense.input.dateoutofrange.alert.title' },
						{
							year: financialYear.year,
							enteredYear
						}
					)}
					body={intl.formatMessage(
						{ id: 'expense.input.dateoutofrange.alert.body' },
						{
							year: financialYear.year,
							enteredYear
						}
					)}
				/>
			);
		} else {
			// TODO: check if we are dealing with an ExpenseModel or Draft
			this.doSubmitExpense();
		}
	}

	/**
	 *
	 * @param response
	 * @param showNext
	 */
	onSubmitSuccess(response, showNext = true) {
		const { onExpenseCreated } = this.props;
		const { draft } = this.state;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		// Close when callback is active
		showNext = onExpenseCreated ? false : showNext;

		// Delete Draft when succesfully saved as Income
		if (draft) {
			const command = new DeleteCompanyDraftCommand(company.draftsStore, company.id, draft, true);
			command.execute(() => {
				// Show next draft
				this.showNextDraft();
			});
		} else {
			// Show next expense input
			// eslint-disable-next-line no-lonely-if
			if (showNext) {
				this.showNewExpense();
			} else {
				if (onExpenseCreated) {
					onExpenseCreated(response);
				}
				Signals.HideOverlay.dispatch();
			}
		}
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	doSaveDraft() {
		this.setState({ errors: null, submitting: true });

		const { draft } = this.props;
		const { expenseModel } = this.state;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const financialYear = applicationStore.getSelectedFinancialYear();

		// Save draft
		if (draft) {
			draft.data = JSON.stringify(expenseModel);
			const command = new UpdateCompanyDraftsCommand(company.id, draft);
			command.execute(
				() => {
					Signals.HideOverlay.dispatch();
				},
				() => {
					this.setState({ submitting: false });
				}
			);

			// Create new draft
		} else {
			const draft = new Draft();
			draft.data = JSON.stringify(expenseModel);
			draft.assetUuid = expenseModel.assetUuid;
			const command = new CreateCompanyDraftCommand(
				company.draftsStore,
				company.id,
				DraftTypes.EXPENSE,
				null,
				financialYear.id,
				draft
			);
			command.execute(
				() => {
					Signals.HideOverlay.dispatch();
					Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="draft.added.message" />);
				},
				() => {
					this.setState({ submitting: false });
				}
			);
		}
	}

	/**
	 *
	 */
	doSubmitExpense() {
		const { applicationStore } = this.context;
		const { file, expenseModel } = this.state;

		this.setState({ errors: null, submitting: true });

		// Update
		if (expenseModel.id && expenseModel.id >= 0) {
			const command = new UpdateExpenseCommand(applicationStore, expenseModel, file);
			command.execute((repsonse) => this.onSubmitSuccess(repsonse, false), this.onSubmitError);

			// Create new
		} else {
			const command = new CreateExpenseCommand(applicationStore, expenseModel, file);
			command.execute((repsonse) => this.onSubmitSuccess(repsonse, true), this.onSubmitError);
		}
	}

	/**
	 *
	 */
	doRemove() {
		/**
		 * draftsStore, companyId, draft
		 * @type {DeleteCompanyDraftCommand}
		 */
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const { draft } = this.state;
		const { draftsStore } = company;

		const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft);
		command.execute(() => Signals.HideOverlay.dispatch());
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasChanged() {
		const { expenseModel, originalExpenseModel } = this.state;
		return !expenseModel.equals(originalExpenseModel);
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDateValid() {
		const { expenseModel } = this.state;
		return !(
			!expenseModel.date ||
			(expenseModel.date instanceof Date && isNaN(expenseModel.date.getTime()))
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDateInRange() {
		const { expenseModel } = this.state;
		const { applicationStore } = this.context;
		const financialYear = applicationStore.getSelectedFinancialYear();

		const m = moment(expenseModel.date);
		return m.year() === financialYear.year;
	}

	/**
	 *
	 */
	showNextDraft() {
		// Always close overlay first
		Signals.HideOverlay.dispatch();

		const { intl } = this.props;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const { draft } = this.props;
		const { draftsStore } = company;

		const nextDrafts = draftsStore.getDraftsOfType(DraftTypes.EXPENSE, draft.boekjaarId);
		if (nextDrafts.length > 0) {
			setTimeout(() => {
				Signals.ShowOverlay.dispatch(
					<ExpenseInputPanel key={uniqueKey('eip-')} draft={nextDrafts[0]} intl={intl} />
				);
			}, 250);
		}
	}

	/**
	 *
	 */
	showNewExpense() {
		// Always close overlay first
		Signals.HideOverlay.dispatch();

		const { intl } = this.props;

		// Show ExpenseInputPanel overlay
		setTimeout(() => {
			Signals.ShowOverlay.dispatch(
				<ExpenseInputPanel
					key={uniqueKey('eip-')}
					expenseModel={ExpenseModel.createNewExpense()}
					intl={intl}
				/>
			);
		}, 250);
	}
}

ExpenseInputPanel.contextType = ApplicationContext;

ExpenseInputPanel.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	expenseModel: PropTypes.instanceOf(ExpenseModel),
	draft: PropTypes.instanceOf(Draft),
	fieldToFocus: PropTypes.string,
	onExpenseCreated: PropTypes.func
};

ExpenseInputPanel.defaultProps = {
	className: ''
};

export default injectIntl(ExpenseInputPanel);
