/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

/**
 *
 */
class ResultRow extends React.Component {
	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'result-row--light': this.props.isLight,
			'result-row--dark': this.props.isDark
		});

		const text = this.props.label ? <FormattedMessage id={this.props.label} /> : this.props.text;

		return (
			<div className={`result-row grid grid--spread ${this.props.className} ${classes}`}>
				<div className="result-row__label col--8">
					{this.getLabelPrefix()}
					{text} {this.props.labelSuffix}
				</div>
				<div className="result-row__value col--4 text--right">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.value}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	getLabelPrefix() {
		if (this.props.labelPrefix) {
			return <span className="result-row__prefix" />;
		}
	}
}

ResultRow.propTypes = {
	label: PropTypes.string,
	labelSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	labelPrefix: PropTypes.bool,
	text: PropTypes.string,
	value: PropTypes.number.isRequired,
	className: PropTypes.string,
	isLight: PropTypes.bool.isRequired,
	isDark: PropTypes.bool.isRequired
};

ResultRow.defaultProps = {
	value: 0,
	isLight: false,
	isDark: false
};

export default injectIntl(ResultRow);
