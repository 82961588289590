/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
	IntlProvider,
	injectIntl,
	FormattedMessage,
	FormattedHTMLMessage,
	FormattedDate
} from 'react-intl';
import classNames from 'classnames';
import Page from '../../Page';

import { getTranslations } from '../../../utils/Translations';
import { captureDeeplinkClick } from '../../../utils/routeUtils';
import CookieController, { COOKIE_TYPES } from '../../../controllers/CookieController';

import { Routes } from '../../../data/Routes';
import SendTypes from '../../../data/SendTypes';

import Signals from '../../../signals/Signals';
import BTW from '../../../data/BTW';
import { createDynamicValues, addDynamicValues } from '../../../data/InvoiceDynamicValues';
import InvoiceConceptStatus from '../../../data/InvoiceConceptStatus';

import InvoiceRow from './components/InvoiceRow';
import CompanyAddress from '../../../components/ui/CompanyAddress/CompanyAddress';
import ClientAddress from '../../../components/ui/ClientAddress/ClientAddress';

import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import Checkbox from '../../../components/ui/Checkbox/Checkbox';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import ToggleButton from '../../../components/ui/ToggleButton/ToggleButton';
import ToggleButtonOption from '../../../components/ui/ToggleButton/ToggleButtonOption';

import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import InvoiceSummaryRow from './components/InvoiceSummaryRow';
import InvoiceHistory from './components/InvoiceHistory';

import { buildCompanyInvoicePDFURL } from '../../../requests/companyInvoicePDF';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';
import Locale from '../../../data/Locale';
import InvoiceAttachments from './components/InvoiceAttachments';
import { ApplicationContext } from '../../../ApplicationContext';

import ArchiveInvoiceConceptCommand from '../../../commands/invoiceConcepts/ArchiveInvoiceConceptCommand';
import DeleteInvoiceConceptCommand from '../../../commands/invoiceConcepts/DeleteInvoiceConceptCommand';
import UnarchiveInvoiceConceptCommand from '../../../commands/invoiceConcepts/UnarchiveInvoiceConceptCommand';
import ToggleInvoiceConceptPaidStatusCommand from '../../../commands/invoiceConcepts/ToggleInvoiceConceptPaidStatusCommand';
import SendInvoiceConceptCommand from '../../../commands/invoiceConcepts/SendInvoiceConceptCommand';
import GetInvoiceConceptCommand from '../../../commands/invoiceConcepts/GetInvoiceConceptCommand';
import InvoiceConceptModel from '../../../stores/models/InvoiceConceptModel';

import AlertMessage from '../../../components/ui/AlertMessage/AlertMessage';
import CanPayOnlineStatus from '../../../data/CanPayOnlineStatus';
import CompanyBrandModel from '../../../stores/models/CompanyBrandModel';

import CompanyBrandInput from '../GenericSettings/modals/CompanyBrandInput';
import SaveInvoiceConceptCommand from '../../../commands/invoiceConcepts/SaveInvoiceConceptCommand';
import ClientInput from '../Clients/components/modals/ClientInput';
import CompanyCustomer from '../../../stores/models/CompanyCustomer';
import { uniqueKey } from '../../../utils/ReactUtils';
import PropertiesController from '../../../controllers/PropertiesController';

/**
 *
 */
class Invoice extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.locale = null;
		this.translations = null;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.sendType = -1;
		this.state = {
			settingsExpanded: false
		};

		this.onCancel = this.onCancel.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onArchive = this.onArchive.bind(this);
		this.onUnarchive = this.onUnarchive.bind(this);
		this.onSendEmail = this.onSendEmail.bind(this);
		this.onMarkAsSend = this.onMarkAsSend.bind(this);
		this.onSendReminder = this.onSendReminder.bind(this);
		this.onShowPDF = this.onShowPDF.bind(this);
		this.onAction = this.onAction.bind(this);
		this.onViewOption = this.onViewOption.bind(this);
		this.onPaidStatusChange = this.onPaidStatusChange.bind(this);
		this.onToggleSettings = this.onToggleSettings.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onError = this.onError.bind(this);
		this.onSendSuccess = this.onSendSuccess.bind(this);
		this.onSaveSuccess = this.onSaveSuccess.bind(this);

		this.onEditClient = this.onEditClient.bind(this);
		this.onClientUpdate = this.onClientUpdate.bind(this);

		this.onEditSender = this.onEditSender.bind(this);
		this.onSenderUpdate = this.onSenderUpdate.bind(this);

		this.remove = this.remove.bind(this);
		this.edit = this.edit.bind(this);
		this.duplicate = this.duplicate.bind(this);

		this._doRemove = this._doRemove.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.componentWillReceiveProps();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.resetFieldHeaderSettings();
		this.getInvoiceConceptModel();
	}

	/**
	 *
	 */
	render() {
		// Check if user logged in
		const { applicationStore } = this.context;
		if (!applicationStore.isLoggedIn || !this.invoiceConceptModel) {
			return null;
		}

		const company = applicationStore.getSelectedCompany();
		const companyBrand = this.invoiceConceptModel.companyBrand
			? this.invoiceConceptModel.companyBrand
			: CompanyBrandModel.fromCompany(company);
		const companyCustomer = this.invoiceConceptModel.companyCustomer
			? this.invoiceConceptModel.companyCustomer
			: applicationStore.companyCustomerStore.find(this.invoiceConceptModel.companyCustomerId);

		// Can't render
		if (!company || !companyCustomer) {
			return null;
		}

		// Build logo URL
		const logoPreviewURL = companyBrand.logoAssetUuid
			? buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid)
			: null;
		const deviatingUnits = this.invoiceConceptModel.hasDeviatingUnits();

		if (!deviatingUnits) {
			// eslint-disable-next-line array-callback-return
			this.fieldHeaderSettings.map((fieldData) => {
				if (fieldData.id === 'units') {
					fieldData.hide = true;
				}

				if (fieldData.id === 'unitprice') {
					fieldData.labelId = fieldData.labelIdSingle;
				}
			});
		}

		// Create table headers
		const values = { totalUnits: '' };
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			return (
				<td
					key={`invh-${index}`}
					className={`invoice__header--${`${fieldData.id}`.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}>
					{fieldData.hide ? null : <FormattedMessage id={fieldData.labelId} values={values} />}
				</td>
			);
		});

		//
		const hasDeviatingUnits = this.invoiceConceptModel.hasDeviatingUnits();
		const detailsWrapperClasses = classNames({
			'invoice__details-wrapper': true,
			'invoice__details-wrapper--no-units': !hasDeviatingUnits
		});

		// Create invoices concept rows
		const rows = this.invoiceConceptModel.invoiceConceptRows.map(
			(invoiceConceptRowModel, index) => {
				return (
					<InvoiceRow
						key={`i-${index}-${invoiceConceptRowModel._uid}`}
						hideUnits={!hasDeviatingUnits}
						invoiceConceptModel={this.invoiceConceptModel}
						invoiceConceptRowModel={invoiceConceptRowModel}
					/>
				);
			}
		);

		return (
			<Page pageName="invoice grid">
				<div className="invoice__panel panel grid col--12 box-shadow border border--dark">
					{/* Option buttons */}
					{this._getOptions()}

					{/* Expanding/Collapsing settings */}
					<div
						className={`invoice__settings col--12 grid ${classNames({
							'invoice__settings--expanded': this.state.settingsExpanded
						})}`}>
						{this._getSettings()}
					</div>

					{/* Invoice status */}
					<div
						className={`invoice__status ${
							this.invoiceConceptModel.status
								? `invoice__status--${`${this.invoiceConceptModel.status}`.toLowerCase()}`
								: ''
						}`}>
						<label>
							<FormattedMessage
								id={`invoice.status.${
									this.invoiceConceptModel.status
										? `${this.invoiceConceptModel.status}`.toLowerCase()
										: ''
								}`}
							/>
						</label>
					</div>

					{/* Invoice layout + localised translation */}
					<IntlProvider locale={this.locale} messages={this.translations}>
						<div className="invoice__layout-wrapper padding grid">
							<div className="invoice__information grid col--12">
								<div className="invoice__logo col--6">
									{logoPreviewURL ? (
										<img className="invoice__logo-img" src={logoPreviewURL} alt="logo" />
									) : null}
								</div>

								<div className="col--6 grid">
									<label className="text--right invoice__information-label col--5">
										<FormattedMessage id="invoice.label.sender" />
									</label>
									<div className="col--7">
										<CompanyAddress
											company={companyBrand.toCompany(company)}
											useDate={this.invoiceConceptModel.date}
											onEdit={this.invoiceConceptModel.canEdit() ? this.onEditSender : null}
										/>
									</div>
								</div>

								<div className="invoice__information-detail-labels grid col--6">
									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.invoice" />
									</label>
									<div className="invoice__information-value invoice__information-value--bold col--8">
										{this.invoiceConceptModel.subject}
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.invoicenr" />
									</label>
									<div className="invoice__information-value col--8">
										{this.invoiceConceptModel.invoiceNr}
									</div>

									<label
										className={`invoice__information-label col--4 ${classNames({
											hidden: !this.invoiceConceptModel.projectcode
										})}`}>
										<FormattedMessage id="invoice.info.projectcode" />
									</label>
									<div
										className={`invoice__information-value col--8 ${classNames({
											hidden: !this.invoiceConceptModel.projectcode
										})}`}>
										{this.invoiceConceptModel.projectcode}
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.date" />
									</label>
									<div className="invoice__information-value col--8">
										<FormattedDate
											value={this.invoiceConceptModel.date}
											day="numeric"
											month="long"
											year="numeric"
										/>
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.expirationdate" />
									</label>
									<div className="invoice__information-value col--8">
										{/* Calculate expirationDate from date + expirationDays to cancel out discrepantie of 1 day */}
										<FormattedDate
											value={this.invoiceConceptModel.getExpirationDateFromDate()}
											day="numeric"
											month="long"
											year="numeric"
										/>
									</div>
								</div>

								<div className="col--6 grid">
									<label className="text--right invoice__information-label col--5">
										<FormattedMessage id="invoice.label.client" />
									</label>
									<div className="col--7">
										<ClientAddress
											companyCustomer={this.invoiceConceptModel.companyCustomer}
											onUpdate={this.onClientUpdate}
											onEdit={this.invoiceConceptModel.canEdit() ? this.onEditClient : null}
											language={this.locale}
										/>
									</div>
								</div>
							</div>

							<div className={detailsWrapperClasses}>
								<table className="table table--wide invoice__details col--12">
									<thead className="table__headers">
										<tr>{headers}</tr>
									</thead>
									<tbody className="table__body">
										{rows}

										{/* Empty row */}
										<tr className="invoice-summary-row">
											<td colSpan={5}>&nbsp;</td>
										</tr>

										{this._getSubtotal()}
										{this._getVATLow()}
										{this._getVATHigh()}
										{this._getVATReversed()}
										{this._getVATExempt()}
										{this._getTotal()}
									</tbody>
								</table>
							</div>

							{/* Optional notes */}
							{this.invoiceConceptModel.hasNotes() ? (
								<div className="invoice__notes col--12 grid padding border--top padding--bottom">
									<label>
										<FormattedMessage id="invoice.label.notes" />
									</label>
									<div
										// eslint-disable-next-line react/no-danger
										dangerouslySetInnerHTML={{ __html: this.invoiceConceptModel.getNotesAsHTML() }}
									/>
								</div>
							) : null}
						</div>
					</IntlProvider>
				</div>

				{/* Attachments */}
				<InvoiceAttachments invoiceConceptModel={this.invoiceConceptModel} company={company} />

				{/* Invoice history */}
				<InvoiceHistory invoiceConceptModel={this.invoiceConceptModel} />
			</Page>
		);
	}

	/**
	 *
	 */
	onToggleSettings() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ settingsExpanded: !this.state.settingsExpanded });
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const company = this.context.applicationStore.getSelectedCompany();
		switch (e.target.name) {
			case 'sendToSelf':
				this.setState({ bccRecipientEmail: e.target.checked ? company.email : '' });
				break;
			case 'remindCompanyUserInDays':
				this.setState({ remindCompanyUserInDays: e.target.checked ? 0 : null });
				break;
			default:
				// eslint-disable-next-line no-case-declarations
				const nextState = {};
				nextState[e.target.name] = e.target.value;
				this.setState(nextState);
		}
	}

	/**
	 *
	 */
	onEditClient() {
		const companyCustomer = this.invoiceConceptModel.companyCustomer;
		Signals.ShowOverlay.dispatch(
			<ClientInput
				key={uniqueKey('cc-')}
				companyCustomer={companyCustomer}
				updateLocalOnly
				onUpdate={this.onClientUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onClientUpdate(companyCustomer) {
		this.setState({ recipientEmail: companyCustomer.contactPersonEmail });

		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		this.invoiceConceptModel.companyCustomer = new CompanyCustomer(companyCustomer);
		this.forceUpdate();

		const command = new SaveInvoiceConceptCommand(
			applicationStore,
			this.invoiceConceptModel,
			company,
			this.invoiceConceptModel.toJSON(),
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 */
	onEditSender() {
		const company = this.context.applicationStore.getSelectedCompany();
		const companyBrand = this.invoiceConceptModel.companyBrand
			? this.invoiceConceptModel.companyBrand
			: company.defaultCompanyBrand;
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput
				key={uniqueKey('cbi-')}
				companyBrand={companyBrand}
				updateLocalOnly
				onUpdate={this.onSenderUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyBrand
	 */
	onSenderUpdate(companyBrand) {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		this.invoiceConceptModel.companyBrand = new CompanyBrandModel(companyBrand);
		this.forceUpdate();

		const command = new SaveInvoiceConceptCommand(
			applicationStore,
			this.invoiceConceptModel,
			company,
			this.invoiceConceptModel.toJSON(),
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 * @param response
	 */
	onSaveSuccess(_response) {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="invoice.saved.message"
				values={{ invoiceNr: this.invoiceConceptModel.invoiceNr }}
			/>
		);

		// Update store
		const { applicationStore } = this.context;
		const storedInvoiceConcept = applicationStore.invoicesConceptStore.getInvoiceConceptById(
			this.invoiceConceptModel.id
		);
		if (storedInvoiceConcept) {
			storedInvoiceConcept.update(this.invoiceConceptModel);
		}
	}

	/**
	 *
	 */
	onCancel() {
		this.setState({ settingsExpanded: false });
		this.forceUpdate();
	}

	/**
	 *
	 */
	onEdit() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_EDIT.getPath({
					id: company.id,
					invoiceId: this.invoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @param sendType
	 */
	onSend(sendType) {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.setState({ submitting: true, errors: null });

			const command = new SendInvoiceConceptCommand(
				this.context.applicationStore,
				this.invoiceConceptModel,
				company,
				this.state,
				sendType
			);

			command.execute(this.onSendSuccess, (error) => {
				this.onError(error);
			});
		}
	}

	/**
	 *
	 */
	onSendSuccess() {
		this.setState({ settingsExpanded: false, submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	onViewOption(option) {
		switch (option) {
			case 'web':
				this.onViewPublicInvoice();
				break;
			case 'pdf':
				this.onShowPDF();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param option
	 */
	onAction(option) {
		switch (option) {
			case 'edit':
				this.edit();
				break;
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'archive':
				this.onArchive();
				break;
			case 'unarchive':
				this.onUnarchive();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 */
	onViewPublicInvoice() {
		const url = Routes.COMPANY_INVOICES_CLIENT.getPath({
			uuid: this.invoiceConceptModel.publicAccessId
		});
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	onShowPDF() {
		const company = this.context.applicationStore.getSelectedCompany();
		const companyId = company.id;
		const invoiceId = this.invoiceConceptModel.id;

		const url = buildCompanyInvoicePDFURL(companyId, invoiceId);
		window.open(url, '_blank');
	}

	/**
	 *
	 */
	onArchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new ArchiveInvoiceConceptCommand(this.invoiceConceptModel, company);
			command.execute(() => {
				// Go back to overview
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_INVOICES_ALL.getPath({
						id: company.id,
						invoiceId: this.invoiceConceptModel.id
					})
				);
			});
		}
	}

	/**
	 *
	 */
	onUnarchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new UnarchiveInvoiceConceptCommand(this.invoiceConceptModel, company);
			command.execute(() => {
				// Go back to overview
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_INVOICES_ALL.getPath({
						id: company.id,
						invoiceId: this.invoiceConceptModel.id
					})
				);
			});
		}
	}

	/**
	 *
	 * @param value
	 */
	onPaidStatusChange(_value) {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new ToggleInvoiceConceptPaidStatusCommand(
			this.invoiceConceptModel,
			company,
			this.props.intl
		);
		command.execute(() => {
			this.setState({ settingsExpanded: false });
			this.forceUpdate();
		});
	}

	/**
	 *
	 */
	onSendEmail() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'invoice.send.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'invoice.send.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'invoice.send.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'invoice.send.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_SEND_EMAIL)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	onMarkAsSend() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'invoice.markassend.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'invoice.markassend.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'invoice.markassend.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'invoice.markassend.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_MARKED_AS_SEND)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	onSendReminder() {
		if (this.validate()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'invoice.sendreminder.confirm.title' })}
					body={this.props.intl.formatMessage({ id: 'invoice.sendreminder.confirm.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'invoice.sendreminder.confirm.yes' })}
					noLabel={this.props.intl.formatMessage({ id: 'invoice.sendreminder.confirm.no' })}
					onConfirm={() => this.onSend(SendTypes.TYPE_SEND_REMINDER)}
					onCancel={() => {}}
				/>
			);
		}
	}

	/**
	 *
	 */
	resetFieldHeaderSettings() {
		this.fieldHeaderSettings = [
			{ id: 'description', labelId: 'invoice.header.description' },
			{ id: 'units', labelId: 'invoice.header.units', right: true },
			{
				id: 'unitprice',
				labelId: 'invoice.header.unitprice',
				labelIdSingle: 'invoice.header.price',
				right: true
			},
			{ id: 'btw', labelId: 'invoice.header.btw', right: true },
			{ id: 'amount', labelId: 'invoice.header.amount', right: true }
		];
	}

	/**
	 *
	 * @returns {boolean}
	 */
	validate() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// BTW check, ignore this check always for VRIJGESTELD
			if (
				!company.ignoreBTWNumber() &&
				!this.invoiceConceptModel.containsVATType(BTW.VRIJGESTELD, true)
			) {
				const useDate = this.invoiceConceptModel.date;
				const useVatId = useDate && company.useBTWId(useDate);

				let title = '';
				let body = '';

				if (useVatId && !company.hasVATId()) {
					title = this.props.intl.formatMessage({ id: 'invoice.novatid.alert.title' });
					body = this.props.intl.formatMessage({ id: 'invoice.novatid.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
				if (!company.hasVATNumber()) {
					title = this.props.intl.formatMessage({ id: 'invoice.novat.alert.title' });
					body = this.props.intl.formatMessage({ id: 'invoice.novat.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
			}

			// KVK check
			// if (!company.kvkNumber || company.kvkNumber.trim().length <= 0) {
			// 	const title = this.props.intl.formatMessage({id: 'invoice.nokvk.alert.title'});
			// 	const body = this.props.intl.formatMessage({id: 'invoice.nokvk.alert.body'});
			//
			// 	Signals.ShowModal.dispatch(<ModalAlert title={title} body={body}/>);
			//
			// 	return false;
			// }
		}

		return true;
	}

	/**
	 *
	 * @private
	 */
	remove() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const title = this.props.intl.formatMessage(
			{ id: 'invoice.remove.alert.title' },
			{
				description: this.invoiceConceptModel.description,
				customer: this.invoiceConceptModel.companyCustomer.companyName
			}
		);

		let body = null;
		if (this.invoiceConceptModel.status !== InvoiceConceptStatus.CREATED) {
			body = this.props.intl.formatMessage({ id: 'invoice.remove.alert.body' });
		}

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={this._doRemove}
				body={body}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	duplicate() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_DUPLICATE.getPath({
					id: company.id,
					invoiceId: this.invoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 */
	edit() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_EDIT.getPath({
					id: company.id,
					invoiceId: this.invoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeleteInvoiceConceptCommand(
				this.context.applicationStore.invoicesConceptStore,
				this.invoiceConceptModel,
				company
			);
			command.execute(() => {
				Signals.RequestRoute.dispatch(Routes.COMPANY_INVOICES_ALL.getPath({ id: company.id }));
			});
		}
	}

	/**
	 * Returns the right option button depending on the status of this invoice
	 *
	 * @returns {*}
	 * @private
	 */
	_getOptions() {
		return (
			<div className="invoice__options-top padding-small border--bottom border--dark grid grid--spread col--12">
				{this._getOptionsLeft()}
				{this._getOptionsRight()}
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsLeft() {
		return (
			<div className="invoice__options-left grid">
				{this.invoiceConceptModel.canTogglePaid() ? this._getPaidToggle() : null}

				<ToggleButton
					label={this.props.intl.formatMessage({ id: 'label.actions' })}
					chevronColor="black"
					options={this._generateActionOptions()}
					onChange={this.onAction}
				/>
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsRight() {
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		const toggleButtonClasses = classNames({
			'invoice__btn-toggle-settings btn-margin-left button icon icon--right icon--chevron-down icon--color': true,
			'button--primary': !this.invoiceConceptModel.canTogglePaid()
		});

		return (
			<div className="invoice__options-right grid grid--right">
				<ToggleButton
					label={this.props.intl.formatMessage({ id: 'invoice.view' })}
					chevronColor="black"
					options={this._generateViewOptions()}
					className="btn-margin-left"
					onChange={this.onViewOption}
				/>

				{this.invoiceConceptModel.canSend() ? (
					<button
						type="button"
						className={toggleButtonClasses}
						disabled={!financialYear}
						onClick={this.onToggleSettings}>
						<FormattedMessage id="invoice.send.toggle" />
					</button>
				) : null}

				{this.invoiceConceptModel.canSendReminder() ? (
					<button
						type="button"
						className="invoice__btn-toggle-settings button--primary btn-margin-left button icon icon--right icon--chevron-down icon--color"
						onClick={this.onToggleSettings}>
						<FormattedMessage id="invoice.remind.toggle" />
					</button>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getPaidToggle() {
		return (
			<ToggleButton
				label={this.props.intl.formatMessage({ id: 'invoice.change.status' })}
				className="toggle-button--primary btn-margin-right"
				buttonClassName="button--primary"
				options={this._generateStateOptions()}
				onChange={this.onPaidStatusChange}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_getSettings() {
		if (this.invoiceConceptModel.canSend()) {
			return this._getConceptSettings();
		}
		if (this._getReminderSettings()) {
			return this._getReminderSettings();
		}

		return null;
	}

	/**
	 * Settings shown when invoice has can be send
	 *
	 * @returns {*}
	 * @private
	 */
	_getConceptSettings() {
		const company = this.context.applicationStore.getSelectedCompany();
		const sendDisabled =
			this.invoiceConceptModel &&
			(this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.PAYMENT_METHODS_REQUIRED ||
				this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.VERIFICATION_REQUIRED);

		return (
			<div className="invoice__settings-wrapper col--12 grid">
				{/* Form */}
				<FormGroup className="col--12" errors={this.state.errors}>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.replyto" />
						</label>
						<input
							className="col--12"
							name="senderEmail"
							type="email"
							value={this.state.senderEmail}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.recipient" />
						</label>
						<input name="recipientEmail" type="email" value={this.state.recipientEmail} disabled />
					</FormField>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.ccrecipient" />
						</label>
						<input
							name="ccRecipientEmail"
							type="email"
							value={this.state.ccRecipientEmail}
							onInput={this.onInputChange}
						/>
					</FormField>

					<FormField className="col--6 form-field__desktop-filler" />

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.email.subject" />
						</label>
						<input
							className="col--12"
							name="emailSubject"
							type="text"
							value={this.state.emailSubject}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--12">
						<label>
							<FormattedMessage id="invoice.settings.email.message" />
						</label>
						<textarea
							className="col--12"
							name="emailMessage"
							value={this.state.emailMessage}
							onChange={this.onInputChange}
						/>
					</FormField>

					<FormField className="col--12">
						<Checkbox
							className="col--12"
							name="sendToSelf"
							label={
								<FormattedHTMLMessage
									id="invoice.settings.sendcopy"
									values={{ email: company.email }}
								/>
							}
							checked={this.state.bccRecipientEmail !== ''}
							onChange={this.onInputChange}
						/>
						<Checkbox
							className="col--12"
							name="remindCompanyUserInDays"
							label={
								<FormattedHTMLMessage
									id="invoice.settings.remind.user.label"
									values={{ email: this.state.senderEmail }}
								/>
							}
							checked={this.state.remindCompanyUserInDays !== null}
							onChange={this.onInputChange}
						/>
					</FormField>
				</FormGroup>

				<div className="invoice__send-options grid col--12">
					{this._getNoMollieAlertMessage()}
					{this._getPaymentProviderAlertMessage()}

					<FormField className="col--12 grid--spread">
						<button type="button" className="button--tertiary" onClick={this.onCancel}>
							<FormattedMessage id="label.cancel" />
						</button>

						<div className="grid grid--right col--6">
							<button type="button" className="button btn-margin-right" onClick={this.onMarkAsSend}>
								<FormattedMessage id="invoice.mark.as.send" />
							</button>
							<button
								type="button"
								className="invoice__btn-send button--primary button"
								disabled={sendDisabled}
								onClick={this.onSendEmail}>
								<FormattedMessage id="invoice.send" />
							</button>
						</div>
					</FormField>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	onGetInvoiceConceptModel() {
		const company = this.context.applicationStore.getSelectedCompany();
		const user = this.context.applicationStore.user;

		// Load localised translations
		this.locale = user.language;
		switch (this.invoiceConceptModel.locale) {
			case Locale.en_US:
				this.locale = 'en';
				break;
			case Locale.nl_NL:
				this.locale = 'nl';
				break;
			default:
				this.locale = 'nl';
		}

		// Get translations for selected locale
		this.translations = getTranslations(this.locale);

		// Replace dynamic values
		const dynamicValues = createDynamicValues(
			user,
			company,
			this.invoiceConceptModel,
			this.props.intl
		);
		this._applyDefaults(company, dynamicValues);

		// Update render
		this.forceUpdate();
	}

	/**
	 *
	 * @private
	 */
	_getNoMollieAlertMessage() {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const noMollieAlertSeenCookie = CookieController.getCookie(COOKIE_TYPES.NO_MOLLIE_ALERT_SEEN);
		const { intl } = this.props;

		if (company.invoicesSettings.molliePaymentProfile == null && noMollieAlertSeenCookie == null) {
			const settingsUrl = `${Routes.COMPANY_SETTINGS_INVOICES.getPath({
				id: company.id
			})}#integrations`;
			const title = intl.formatMessage({ id: 'invoicemollieintegrationalert.nomollie.title' });
			const message = intl.formatHTMLMessage(
				{ id: 'invoicemollieintegrationalert.nomollie.message' },
				{ settingsUrl }
			);
			return (
				<FormField className="col--12">
					<div
						className="col--12"
						ref={(ref) => {
							captureDeeplinkClick(ref);
						}}>
						<AlertMessage
							title={title}
							message={message}
							info
							onClose={() => {
								CookieController.setCookie(COOKIE_TYPES.NO_MOLLIE_ALERT_SEEN, true, 3650);
								this.forceUpdate();
							}}
						/>
					</div>
				</FormField>
			);
		}
	}

	/**
	 *
	 * @private
	 */
	_getPaymentProviderAlertMessage() {
		// Show alert when current set payement profile
		if (
			this.invoiceConceptModel &&
			(this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.VERIFICATION_REQUIRED ||
				this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.PAYMENT_METHODS_REQUIRED)
		) {
			const { intl } = this.props;
			const { applicationStore } = this.context;
			const company = applicationStore.getSelectedCompany();
			const settingsUrl = `${Routes.COMPANY_SETTINGS_INVOICES.getPath({
				id: company.id
			})}#integrations`;

			const title = intl.formatMessage({ id: 'invoicemollieintegrationalert.title' });
			const message = intl.formatHTMLMessage(
				{ id: `invoicemollieintegrationalert.message.${this.invoiceConceptModel.canPayOnline}` },
				{ settingsUrl }
			);
			return (
				<FormField className="col--12">
					<div
						className="col--12"
						ref={(ref) => {
							captureDeeplinkClick(ref);
						}}>
						<AlertMessage title={title} message={message} danger />
					</div>
				</FormField>
			);
		}
	}

	/**
	 * Settings to display when reminder is option is active
	 *
	 * @returns {*}
	 * @private
	 */
	_getReminderSettings() {
		const sendDisabled =
			this.invoiceConceptModel &&
			(this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.PAYMENT_METHODS_REQUIRED ||
				this.invoiceConceptModel.canPayOnline === CanPayOnlineStatus.VERIFICATION_REQUIRED);

		return (
			<div className="invoice__settings-wrapper col--12 grid">
				<FormGroup className="col--12" errors={this.state.errors}>
					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.replyto" />
						</label>
						<input
							className="col--12"
							name="senderEmail"
							type="email"
							value={this.state.senderEmail}
							disabled
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.recipient" />
						</label>
						<input
							className="col--12"
							name="recipientEmail"
							type="email"
							value={this.state.recipientEmail}
							disabled
						/>
					</FormField>

					<FormField className="col--6 ">
						<label>
							<FormattedMessage id="invoice.settings.email.subject" />
						</label>
						<input
							className="col--12"
							type="text"
							name="reminderSubject"
							value={this.state.reminderSubject}
							onChange={this.onInputChange}
							maxLength={255}
						/>
					</FormField>

					<FormField className="col--12 ">
						<label>
							<FormattedMessage id="invoice.settings.email.message" />
						</label>
						<textarea
							className="col--12"
							name="reminderMessage"
							value={this.state.reminderMessage}
							onChange={this.onInputChange}
						/>
					</FormField>
				</FormGroup>

				<div className="invoice__send-options grid col--12">
					{this._getPaymentProviderAlertMessage()}

					<FormField className="col--12 grid--spread">
						<button type="button" className="button--tertiary" onClick={this.onCancel}>
							<FormattedMessage id="label.cancel" />
						</button>

						<button
							type="button"
							className="invoice__btn-send button--primary button"
							disabled={sendDisabled}
							onClick={this.onSendReminder}>
							<FormattedMessage id="invoice.remind" />
						</button>
					</FormField>
				</div>
			</div>
		);
	}

	/**
	 * Fetch InvoiceConceptModel based on parameter invoiceId
	 *
	 * @private
	 */
	getInvoiceConceptModel() {
		if (
			this.context.applicationStore &&
			this.context.applicationStore.currentRouteParams &&
			this.context.applicationStore.currentRouteParams.invoiceId
		) {
			const company = this.context.applicationStore.getSelectedCompany();

			if (company) {
				this.invoiceConceptModel = new InvoiceConceptModel();
				this.invoiceConceptModel.id = parseInt(
					this.context.applicationStore.currentRouteParams.invoiceId,
					10
				);
				const command = new GetInvoiceConceptCommand(this.invoiceConceptModel, company);
				command.execute(() => {
					this.onGetInvoiceConceptModel();
				});
			}
		}
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getSubtotal() {
		return (
			<InvoiceSummaryRow
				label={<FormattedMessage id="invoice.summary.subtotal" />}
				amount={this.invoiceConceptModel.getSubtotal()}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATLow() {
		const label = (
			<FormattedMessage
				id="invoice.summary.btw"
				values={{
					percentage: this.props.intl.formatMessage({
						id: BTW.LAAG.translationId(this.invoiceConceptModel.date)
					})
				}}
			/>
		);

		return (
			<InvoiceSummaryRow
				hideWhenZero={!this.invoiceConceptModel.containsVATType(BTW.LAAG)}
				label={label}
				amount={this.invoiceConceptModel.getBTWTotal(BTW.LAAG.name)}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATHigh() {
		const label = (
			<FormattedMessage
				id="invoice.summary.btw"
				values={{ percentage: this.props.intl.formatMessage({ id: BTW.HOOG.translationId() }) }}
			/>
		);
		return (
			<InvoiceSummaryRow
				hideWhenZero={!this.invoiceConceptModel.containsVATType(BTW.HOOG)}
				label={label}
				amount={this.invoiceConceptModel.getBTWTotal(BTW.HOOG.name)}
			/>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.invoiceConceptModel.containsVATType(BTW.VERLEGD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.reversed.summary" />}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.invoiceConceptModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.exempt.summary" />}
					amount={null}
				/>
			);
		}
		return null;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getTotal() {
		return (
			<InvoiceSummaryRow
				label={<FormattedMessage id="invoice.summary.total" />}
				amount={this.invoiceConceptModel.getTotal()}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_generateActionOptions() {
		const result = [];

		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.edit' }),
				'edit',
				'icon icon--left icon--edit-black',
				false,
				!this.invoiceConceptModel.canEdit()
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.duplicate' }),
				'duplicate',
				'icon icon--left icon--file-black'
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.remove' }),
				'remove',
				'icon icon--left icon--delete-black',
				false,
				!this.invoiceConceptModel.canRemove()
			)
		);

		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.archive' }),
				'archive',
				'icon icon--left icon--folder2-black',
				false,
				!this.invoiceConceptModel.canArchive()
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.unarchive' }),
				'unarchive',
				'icon icon--left icon--folder2-black',
				false,
				!this.invoiceConceptModel.isArchived()
			)
		);

		return result;
	}

	/**
	 *
	 * @private
	 */
	/**
	 *
	 * @private
	 */
	_generateStateOptions() {
		const result = [];

		const isOpen = this.invoiceConceptModel.isOpen();

		// SENT states, always hide when isSigned
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'invoice.paidswitch.label.no' }),
				null,
				'toggle-button-option--color-warning',
				isOpen
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'invoice.paidswitch.label.yes' }),
				InvoiceConceptStatus.PAID,
				'toggle-button-option--color-success',
				!isOpen
			)
		);

		return result;
	}

	/**
	 *
	 * @private
	 */
	_generateViewOptions() {
		const result = [];

		if (
			this.invoiceConceptModel.isPublic() &&
			!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)
		) {
			result.push(new ToggleButtonOption('Online', 'web'));
		}

		result.push(new ToggleButtonOption('PDF', 'pdf'));

		return result;
	}

	/**
	 *
	 * @private
	 */
	_applyDefaults(company, dynamicValues) {
		this.state.recipientEmail = this.invoiceConceptModel.companyCustomer.contactPersonEmail;
		this.state.senderEmail = company.invoicesSettings.senderEmail
			? company.invoicesSettings.senderEmail
			: company.email;

		// Pick prefilled messaged based on InvoiceConceptModel.locale
		if (this.invoiceConceptModel.locale === Locale.en_US) {
			this.state.emailSubject = addDynamicValues(
				company.invoicesSettings.defaultInvoiceConceptEmailSubjectEN,
				dynamicValues,
				this.props.intl
			);
			this.state.emailMessage = addDynamicValues(
				company.invoicesSettings.defaultInvoiceConceptEmailMessageEN,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderSubject = addDynamicValues(
				company.invoicesSettings.reminderSubjectEN,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderMessage = addDynamicValues(
				company.invoicesSettings.reminderMessageEN,
				dynamicValues,
				this.props.intl
			);
		} else {
			this.state.emailSubject = addDynamicValues(
				company.invoicesSettings.defaultInvoiceConceptEmailSubject,
				dynamicValues,
				this.props.intl
			);
			this.state.emailMessage = addDynamicValues(
				company.invoicesSettings.defaultInvoiceConceptEmailMessage,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderSubject = addDynamicValues(
				company.invoicesSettings.reminderSubject,
				dynamicValues,
				this.props.intl
			);
			this.state.reminderMessage = addDynamicValues(
				company.invoicesSettings.reminderMessage,
				dynamicValues,
				this.props.intl
			);
		}

		this.state.remindCompanyUserInDays = company.invoicesSettings.remindCompanyUserInDays;
		this.state.bccRecipientEmail = company.invoicesSettings.bccRecipientEmail; // Send to self
	}
}

Invoice.contextType = ApplicationContext;

Invoice.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Invoice);
