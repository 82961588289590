import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';
import companyLatestOfferNrs from '../../requests/offers/companyLatestOfferNrs';

/**
 *
 */
export default class GetLatestOfferNumberCommand extends AbstractCommand {
	/**
	 * @param companyLatestOfferNumberStore CompanyLatestOfferNumberStore
	 * @param company Company
	 */
	constructor(companyLatestOfferNumberStore, company) {
		super();

		this.companyLatestOfferNumberStore = companyLatestOfferNumberStore;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		companyLatestOfferNrs(this.company.id)
			.then((response) => {
				this.companyLatestOfferNumberStore.update(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				Signals.Error.dispatch(err);

				if (error) {
					error(err);
				}
			});
	}
}
