import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId, data) => {
	return fetch(
		Methods.POST,
		EndPoints.COMPANY_ASSETS.replace(':id', companyId),
		data,
		null,
		true,
		true,
		false,
		false,
		true
	);
};
