import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (financialYearId, termType, state = 'INGEDIEND') => {
	const formData = new FormData();
	formData.append('termType', termType);
	formData.append('state', state);
	return fetch(
		Methods.POST,
		EndPoints.FINANCIAL_YEAR_DEADLINES.replace(':year', financialYearId),
		formData,
		null,
		true
	);
};
