/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { getPrivateDocumentTypes, getCompanyDocumentTypes } from '../../data/DocumentTypes';

import { ApplicationContext } from '../../ApplicationContext';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';
import DropDown from '../../components/ui/DropDown/DropDown';
import IconRadioButton from '../../components/ui/IconRadioButton/IconRadioButton';

/**
 *
 */
@observer
class DocumentEditor extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onInputChange = this.onInputChange.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const { document } = this.props;

		// Set defaults
		document.year = this.context.applicationStore.getSelectedDocumentYear();
	}

	/**
	 *
	 */
	render() {
		const { errors, document } = this.props;
		const { year, owner } = document;

		return (
			<div className="document-editor__content padding-small">
				<FormGroup errors={errors}>
					<FormField className="col--6">
						<label>
							<FormattedMessage id="documents.year" />
							<DropDown name="year" value={year} onChange={this.onInputChange}>
								{this._getYearOptions()}
							</DropDown>
						</label>
					</FormField>
					<FormField className="col--6">
						<label>
							<FormattedMessage id="documents.owner" />
							<DropDown name="owner" value={owner} onChange={this.onInputChange}>
								{this._getOwnerOptions()}
							</DropDown>
						</label>
					</FormField>
				</FormGroup>

				<FormGroup errors={errors}>
					<FormField className="col--12">
						<label>
							<FormattedMessage id="documents.category" />
						</label>
						{this._getCategoryOptions()}
					</FormField>
				</FormGroup>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const { document } = this.props;

		switch (e.target.name) {
			case 'sendToBookkeeper':
				document.sendToBookkeeper = e.target.checked;
				break;
			default:
				document[e.target.name] = e.target.value;
		}
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getYearOptions() {
		// Check which company, or which user is selected
		const { applicationStore } = this.context;
		const now = new Date();
		let availableYears = [now.getFullYear()];
		applicationStore.user.companies.forEach((company) => {
			// eslint-disable-next-line array-callback-return
			company.financialYears.map((year) => {
				availableYears.push(parseInt(year.year, 10));
			});
		});

		// Only unique years
		availableYears = availableYears.filter((year, index) => {
			return availableYears.indexOf(year) === index;
		});
		availableYears.sort((a, b) => {
			return b - a;
		});

		return availableYears.map((year, index) => {
			return (
				<option key={`fy-${index}`} value={year}>
					{year}
				</option>
			);
		});
	}

	/**
	 *
	 * @private
	 */
	_getOwnerOptions() {
		const { applicationStore } = this.context;
		const result = [];

		result.push(
			<option key="udo" value="user">
				{applicationStore.user.getFullName()}
			</option>
		);
		applicationStore.user.companies.forEach((company, index) => {
			result.push(
				<option key={`cdo-${index}`} value={company.id}>
					{company.name}
				</option>
			);
		});

		return result;
	}

	/**
	 *
	 * @private
	 */
	_getCategoryOptions() {
		const { document, intl } = this.props;

		const documents =
			document.owner === 'user' ? getPrivateDocumentTypes() : getCompanyDocumentTypes();

		return documents.map((type) => {
			const label = intl.formatMessage({ id: `document.type.${type.name}` });
			const iconName = type.iconName;
			return (
				<IconRadioButton
					key={`document.type.${type.name}`}
					className="icon-radio-button--border"
					name="type"
					checked={type.name === document.type}
					value={type.name}
					label={label}
					iconName={iconName}
					onChange={this.onInputChange}
					required
				/>
			);
		});
	}
}

DocumentEditor.contextType = ApplicationContext;

DocumentEditor.propTypes = {
	intl: PropTypes.object,
	errors: PropTypes.array,
	document: PropTypes.instanceOf(Document).isRequired
};

export default injectIntl(DocumentEditor);
