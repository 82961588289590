import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

import Question from '../Question/Question';
import ModalConfirm from '../Modal/ModalConfirm';
import { ApplicationContext } from '../../../ApplicationContext';

@observer
/**
 *
 */
class FinancialStatement extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onSave = this.onSave.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onQuestionChanged = this.onQuestionChanged.bind(this);
		this.onSaveSuccess = this.onSaveSuccess.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { financialStatementStore, intl, disabled, className } = this.props;
		const financialYear = applicationStore.getSelectedFinancialYear();

		if (!financialYear) {
			return null;
		}

		this._prefillQuestions();
		const translationVariables = {
			year: financialYear.year,
			previousYear: financialYear.year - 1,
			lastDateYear: intl.formatDate(new Date(financialYear.year, 11, 31))
		};

		const classes = classNames({
			'financial-statement--disabled': disabled
		});

		// Content
		return (
			<div
				ref={(ref) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					this.el = ref;
				}}
				className={`financial-statement ${classes} ${className}`}>
				<div>
					<Question
						index={0}
						label={intl.formatMessage(
							{ id: 'financialstatement.question1.title' },
							translationVariables
						)}
						description={intl.formatMessage(
							{ id: 'financialstatement.question1.description' },
							translationVariables
						)}
						allowedFiles={25}
						questionModel={financialStatementStore.question1}
						onChange={this.onQuestionChanged}
						type="financial"
						disabled={disabled}
						extraInfoTitleId="financialstatement.question1.extrainfo.title"
						extraInfoBodyId="financialstatement.question1.extrainfo.body"
					/>

					<Question
						index={1}
						label={intl.formatHTMLMessage(
							{ id: 'financialstatement.question2.title' },
							translationVariables
						)}
						description={intl.formatMessage(
							{ id: 'financialstatement.question2.description' },
							translationVariables
						)}
						allowedFiles={25}
						questionModel={financialStatementStore.question2}
						onChange={this.onQuestionChanged}
						type="financial"
						disabled={disabled}
						extraInfoTitleId="financialstatement.question2.extrainfo.title"
						extraInfoBodyId="financialstatement.question2.extrainfo.body"
					/>

					<Question
						index={2}
						label={intl.formatMessage(
							{ id: 'financialstatement.question3.title' },
							translationVariables
						)}
						description={intl.formatHTMLMessage(
							{ id: 'financialstatement.question3.description' },
							translationVariables
						)}
						allowedFiles={25}
						questionModel={financialStatementStore.question3}
						onChange={this.onQuestionChanged}
						type="financial"
						disabled={disabled}
						extraInfoTitleId="financialstatement.question3.extrainfo.title"
						extraInfoBodyId="financialstatement.question3.extrainfo.body"
					/>
				</div>
				<div className="options-footer deadlines__section-footer padding-small grid grid--right border--top border--dark">
					{this._getSaveButton()}
					{this._getSubmitButton()}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onQuestionChanged() {
		this.forceUpdate();
	}

	/**
	 *
	 */
	onSave() {
		this.props.financialStatementStore.save(this.onSaveSuccess);
	}

	/**
	 *
	 */
	onSubmit() {
		const { intl, financialStatementStore } = this.props;
		const title = intl.formatMessage({ id: 'financialstatement.submit.confirm.title' });
		const yesLabel = intl.formatMessage({ id: 'financialstatement.submit.confirm.yes' });
		const noLabel = intl.formatMessage({ id: 'financialstatement.submit.confirm.no' });

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={() => {
					financialStatementStore.save(this.onSubmitSuccess, true);
				}}
				yesLabel={yesLabel}
				noLabel={noLabel}
			/>
		);
	}

	/**
	 *
	 */
	onSaveSuccess() {
		const { applicationStore } = this.context;
		const financialYear = applicationStore.getSelectedFinancialYear();
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage
				id="financialstatement.saved.message"
				values={{ year: financialYear.year }}
			/>
		);
		this.forceUpdate();
	}

	/**
	 *
	 */
	onSubmitSuccess() {
		const { applicationStore } = this.context;
		const { financialStatementStore } = this.props;
		const financialYear = applicationStore.getSelectedFinancialYear();
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage
				id="financialstatement.submitted.message"
				values={{ year: financialYear.year }}
			/>
		);

		// Set submitted state to true to re-render Deadlines.jsx and other components
		financialYear.financialStatementSubmitted = true;
		financialStatementStore.submitted = true;

		// Update todos
		applicationStore.todoStore.fetch();
	}

	/**
	 *
	 * @private
	 */
	_prefillQuestions() {
		const { applicationStore } = this.context;
		const { financialStatementStore } = this.props;
		const currentYear = applicationStore.getSelectedFinancialYear();
		const currentCompany = applicationStore.getSelectedCompany();
		const hasPreviousFinancialYear = currentCompany.hasPreviousFinancialYear(currentYear.year);

		// Did we do the financial statement last year?
		if (financialStatementStore.question1.isUnanswered() && hasPreviousFinancialYear) {
			financialStatementStore.question1.checked = false;
			financialStatementStore.question1.disabled = true;
		}

		// Did the user do his/her own VAT submits?
		if (financialStatementStore.question2.isUnanswered() && hasPreviousFinancialYear) {
			financialStatementStore.question2.checked = false;
			financialStatementStore.question2.disabled = true;
		}
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getSaveButton() {
		const { financialStatementStore } = this.props;
		if (financialStatementStore.saving) {
			return (
				<button
					type="button"
					className="financial-statement__save button--tertiary"
					disabled
					onClick={this.onSave}>
					<FormattedMessage id="deadlines.financial-statement.save" />
				</button>
			);
			// Is form completed?
		}
		if (financialStatementStore.isComplete()) {
			return (
				<button
					type="button"
					className="financial-statement__save button--tertiary"
					onClick={this.onSave}>
					<FormattedMessage id="deadlines.financial-statement.save" />
				</button>
			);
			// If form not complete, disable submit button
		}
		return (
			<button
				type="button"
				className="financial-statement__save button--tertiary"
				onClick={this.onSave}>
				<FormattedMessage id="deadlines.financial-statement.save" />
			</button>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getSubmitButton() {
		const { financialStatementStore } = this.props;

		if (financialStatementStore.saving) {
			return (
				<button
					type="button"
					className="financial-statement__submit button--primary"
					disabled
					onClick={this.onSubmit}>
					<FormattedMessage id="deadlines.financial-statement.submit" />
				</button>
			);
			// Is form completed?
		}
		if (financialStatementStore.isComplete()) {
			return (
				<button
					type="button"
					className="financial-statement__submit button--primary"
					onClick={this.onSubmit}>
					<FormattedMessage id="deadlines.financial-statement.submit" />
				</button>
			);
			// If form not complete, disable submit button
		}
		return (
			<button
				type="button"
				className="financial-statement__submit button--primary"
				disabled
				onClick={this.onSubmit}>
				<FormattedMessage id="deadlines.financial-statement.submit" />
			</button>
		);
	}
}

FinancialStatement.contextType = ApplicationContext;

FinancialStatement.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	titleNumber: PropTypes.string,
	financialStatementStore: PropTypes.object.isRequired,
	disabled: PropTypes.bool
};

FinancialStatement.defaultProps = {
	className: ''
};

export default injectIntl(FinancialStatement);
