import React from 'react';
import PropTypes from 'prop-types';

export function SkeletonTransaction({ count }) {
	return (
		<div className="skeleton-transaction">
			{new Array(count).fill(0).map((_, index) => (
				<div
					key={`${index}-skeleton-transaction`}
					className="transaction-row transaction-row-skeleton">
					<div className="transaction-item transaction-skeleton transaction-date " />
					<div className="transaction-item transaction-skeleton transaction-name" />
					<div className="transaction-item transaction-skeleton transaction-description" />
					<div className="transaction-item transaction-skeleton transaction-category-label" />
					<div className="transaction-item transaction-skeleton transaction-status" />
					<div className="transaction-item transaction-skeleton transaction-processed" />
					<div className="transaction-item transaction-skeleton transaction-amount" />
					<div className="transaction-item transaction-skeleton transaction-actions" />
				</div>
			))}
		</div>
	);
}

SkeletonTransaction.propTypes = {
	count: PropTypes.number
};

SkeletonTransaction.defaultProps = {
	count: 10
};
