import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (uuid) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		EndPoints.PUBLIC_OFFER_DECLINE.replace(':uuid', uuid),
		null,
		headers,
		false
	);
};
