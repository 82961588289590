import AbstractCommand from './AbstractCommand';
import annualStatementsGet from '../requests/annualStatementsGet';
import Signals from '../signals/Signals';

/**
 *
 */
export default class GetAnnualStatementCommand extends AbstractCommand {
	/**
	 *
	 * @param annualStatementStore AnnualStatementStore
	 */
	constructor(annualStatementStore) {
		super();
		this.annualStatementStore = annualStatementStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 * @return {Promise<any | never>}
	 */
	execute(success, error) {
		this.annualStatementStore.fetching = true;

		return annualStatementsGet()
			.then((response) => {
				this.annualStatementStore.parseData(response.list);
				this.annualStatementStore.fetching = false;

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				this.annualStatementStore.fetching = false;

				if (error) {
					error(err);
				}

				Signals.Error.dispatch(err, true);
			});
	}
}
