export const EUROPEAN_COUNTRIES = [
	{
		name: 'Austria',
		regex: '(AT)U[0-9]{8}'
	},
	{
		name: 'Belgium',
		regex: '(BE)0[0-9]{9}'
	},
	{
		name: 'Bulgaria',
		regex: '(BG)[0-9]{9,10}'
	},
	{
		name: 'Cyprus',
		regex: '(CY)[0-9]{8}L'
	},
	{
		name: 'Czech Republic',
		regex: '(CZ)[0-9]{8,10}'
	},
	{
		name: 'Germany',
		regex: '(DE)[0-9]{9}'
	},
	{
		name: 'Denmark',
		regex: '(DK)[0-9]{8}'
	},
	{
		name: 'Estonia',
		regex: '(EE)[0-9]{9}'
	},
	{
		name: 'Greece',
		regex: '(EL|GR)[0-9]{9}'
	},
	{
		name: 'Spain',
		regex: '(ES)[0-9A-Z][0-9]{7}[0-9A-Z]'
	},
	{
		name: 'Finland',
		regex: '(FI)[0-9]{8}'
	},
	{
		name: 'France',
		regex: '(FR)[0-9A-Z]{2}[0-9]{9}'
	},
	{
		name: 'Hungary',
		regex: '(HU)[0-9]{8}'
	},
	{
		name: 'Ireland',
		regex: '(IE)[0-9]{7}[a-zA-Z]{1,2}'
	},
	{
		name: 'Italy',
		regex: '(IT)[0-9]{11}'
	},
	{
		name: 'Lithuania',
		regex: '(LT)([0-9]{9}|[0-9]{12})'
	},
	{
		name: 'Luxembourg',
		regex: '(LU)[0-9]{8}'
	},
	{
		name: 'Latvia',
		regex: '(LV)?[0-9]{11}'
	},
	{
		name: 'Malta',
		regex: '(MT)[0-9]{8}'
	},
	{
		name: 'Netherlands',
		regex: '(NL)[0-9]{9}B[0-9]{2}'
	},
	{
		name: 'Northern Ireland',
		regex: '(XI)[0-9]{9}'
	},
	{
		name: 'Poland',
		regex: '(PL)[0-9]{10}'
	},
	{
		name: 'Portugal',
		regex: '(PT)[0-9]{9}'
	},
	{
		name: 'Romania',
		regex: '(RO)[0-9]{2,10}'
	},
	{
		name: 'Sweden',
		regex: '(SE)[0-9]{12}'
	},
	{
		name: 'Slovenia',
		regex: '(SI)[0-9]{8}'
	},
	{
		name: 'Slovakia',
		regex: '(SK)[0-9]{10}'
	}
];

/**
 * Creates the regex for the countries in the europe.
 * @param skippedCountries - Country name arrays
 */
export const vatRegexBuilderForEuropeanCountries = (skippedCountries) => {
	let countriesRegexArray = [];
	if (!(skippedCountries && skippedCountries.length > 0)) {
		countriesRegexArray = EUROPEAN_COUNTRIES.map((country) => country.regex);
	} else {
		countriesRegexArray = EUROPEAN_COUNTRIES.filter(
			(country) => skippedCountries.indexOf(country.name) < 0
		).map((country) => country.regex);
	}
	return new RegExp(`^(${countriesRegexArray.join('|')})$`);
};
