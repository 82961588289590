import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import classNames from 'classnames';

/**
 *
 */
export default class BlogEntry extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { imageLoaded: false };
		this.preloadImage = null;

		this.onImageLoaded = this.onImageLoaded.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this._ismounted = true;

		try {
			this.preloadImage = new Image();
			this.preloadImage.onload = this.onImageLoaded;
			this.preloadImage.src = this.props.image;
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 *
	 */
	componentWillUnmount() {
		this._ismounted = false;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const imageClasses = classNames({ 'blog-entry__image--loaded': this.state.imageLoaded });

		return (
			<a
				className={`blog-entry ${this.props.className}`}
				href={this.props.link}
				target="_blank"
				rel="noreferrer">
				<div className="blog-entry__content-wrapper grid">
					<div
						className={`blog-entry__image ${imageClasses}`}
						style={{ backgroundImage: `url("${this.props.image}")` }}
					/>
					<div className="blog-entry__date col--12">
						<FormattedDate value={this.props.date} day="numeric" month="long" year="numeric" />
					</div>
					<h2 className="blog-entry__title col--12">
						<span className="text--overflow-ellipsis" title={this.props.title}>
							{this.props.title}
						</span>
					</h2>
					<div className="blog-entry__description col--12">
						<span className="text--overflow-ellipsis" title={this.props.description}>
							{this.props.description}
						</span>
					</div>
				</div>
			</a>
		);
	}

	/**
	 *
	 */
	onImageLoaded() {
		if (this._ismounted) {
			this.setState({ imageLoaded: true });
		}
	}
}

BlogEntry.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	date: PropTypes.instanceOf(Date).isRequired,
	description: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};
