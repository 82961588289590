import { observable } from 'mobx';

/**
 *
 */
export default class UserMessage {
	@observable id;

	@observable title;

	@observable subTitle;

	@observable body;

	@observable type;

	@observable assetUuid;

	@observable createdAt;

	@observable publicationDate;

	@observable readAt;

	/**
	 *
	 */
	constructor(data) {
		this.parse(data);
	}

	/**
	 *
	 * @param data
	 */
	parse(data) {
		this.id = parseInt(data.id, 10);
		this.publicationDate = data.publicationDate ? new Date(data.publicationDate) : null;
		this.readAt = data.readAt ? new Date(data.readAt) : null;
		this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
		this.title = data.title;
		this.subTitle = data.subTitle;
		this.body = data.body;
		this.type = data.type;
		this.assetUuid = data.assetUuid;
		this.status = data.status;
	}

	/**
	 *
	 * @return {string}
	 */
	toSearchString() {
		let searchString = '';
		searchString += this.title ? `${this.title} ` : '';
		searchString += this.subTitle ? `${this.subTitle} ` : '';
		searchString += this.body ? `${this.body} ` : '';
		searchString = searchString.toLowerCase();

		return searchString;
	}
}
