/**
 *
 * @param str
 * @param n
 * @return {Array}
 * @private
 */
function chunk(str, n) {
	const ret = [];
	let i;
	let len;

	for (i = 0, len = str.length; i < len; i += n) {
		ret.push(str.substr(i, n));
	}

	return ret;
}

/**
 * Function to format the value
 *
 * @param value
 * @param withSpaces Use NLXX BANK 0000 0000 00 (true) or NLXXBANK0000000000 (false)
 * @param {number} hideLastDigitCount Hide the last digit count
 * @returns {*}
 */
export function formatIBAN(value, withSpaces = true, hideLastDigitCount = 0) {
	if (value) {
		// All caps
		value = value.toUpperCase();

		// Clear spaces
		value = value.split(' ').join('');

		// Force spaces every 4 characters
		if (withSpaces) {
			value = chunk(value, 4).join(' ');
		}

		if (hideLastDigitCount > 0) {
			value = `${value.substr(0, value.length - hideLastDigitCount)}${'*'.repeat(
				hideLastDigitCount
			)}`;
		}
	}

	return value;
}

export function hideIBANExceptLastN(value, lastVisibleDigits = 4) {
	if (value) {
		const firstDigitsExpectLastNLength = value.length - lastVisibleDigits;
		value =
			'*'.repeat(firstDigitsExpectLastNLength) +
			value.substr(firstDigitsExpectLastNLength, value.length);
	}

	return formatIBAN(value, true, 0);
}
