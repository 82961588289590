import React from 'react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class LoadingScreen extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = { showLoadingScreen: false };

		this.progressBar = null;

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.onUploadProgress = this.onUploadProgress.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.ShowLoadingScreen.add(this.show);
		Signals.HideLoadingScreen.add(this.hide);
		Signals.ProgressUpload.add(this.onUploadProgress);
	}

	/**
	 *
	 */
	componentDidMount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = true;
	}

	/**
	 *
	 */
	componentWillUnmount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = false;
		Signals.ShowLoadingScreen.remove(this.show);
		Signals.HideLoadingScreen.remove(this.hide);
		Signals.ProgressUpload.remove(this.onUploadProgress);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'loading-screen--show': this.state.showLoadingScreen,
			'loading-screen--hide': !this.state.showLoadingScreen
		});

		return (
			<div className={`loading-screen ${classes}`}>
				<div className="loading-screen__background" />
				<div className="loading-screen__upload-progress" ref={(ref) => (this.progressBar = ref)} />
				<div className="loading-screen__animation" />
			</div>
		);
	}

	/**
	 *
	 * @param percentage
	 */
	onUploadProgress(percentage) {
		if (this.progressBar) {
			this.progressBar.style.width = `${percentage}%`;
		}
	}

	/**
	 *
	 */
	show() {
		if (!this.state.showLoadingScreen) {
			this.onUploadProgress(0);
			this.setState({ showLoadingScreen: true });
		}
	}

	/**
	 *
	 */
	hide() {
		if (this.state.showLoadingScreen) {
			this.setState({ showLoadingScreen: false });
		}
	}
}
