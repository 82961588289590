import { observable } from 'mobx';

export default class Asset {
	@observable contentType;

	@observable filename;

	@observable sizeInBytes;

	@observable type;

	@observable uuid;

	@observable previewUrl;

	/**
	 *
	 * @param data
	 */
	constructor(data) {
		this.update(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	canDownload() {
		return !!this.uuid;
	}

	/**
	 *
	 * @return {*|boolean}
	 */
	canRotate() {
		return this.uuid && this.isImage();
	}

	/**
	 *
	 * @return {boolean}
	 */
	isImage() {
		switch (this.contentType) {
			case 'image/png':
			case 'image/jpeg':
			case 'image/gif':
				return true;
			default:
				return false;
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	isObject() {
		switch (this.contentType) {
			case 'application/pdf':
				return true;
			default:
				return false;
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	isPreviewUrl() {
		return !!this.previewUrl;
	}

	/**
	 *
	 * @param fileName
	 * @param previewUrl
	 * @return {Asset}
	 */
	static createFromPreviewUrl(fileName, previewUrl) {
		const asset = new Asset({});

		// Determine filetype
		const fileNameSegments = fileName.split('.');
		if (fileNameSegments.length > 1) {
			const extension = fileNameSegments[fileNameSegments.length - 1].toLowerCase();

			switch (extension) {
				case 'pdf':
					asset.contentType = 'application/pdf';
					break;
				case 'gif':
					asset.contentType = 'image/gif';
					break;
				case 'png':
					asset.contentType = 'image/png';
					break;
				case 'jpg':
				case 'jpeg':
					asset.contentType = 'image/jpeg';
					break;
				default:
					asset.contentType = 'application/octet-stream';
					break;
			}

			// Default to PDF?
		} else {
			asset.contentType = 'application/pdf';
		}

		asset.filename = fileName;
		asset.previewUrl = previewUrl;

		return asset;
	}
}
