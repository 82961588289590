import { observable, computed, action } from 'mobx';

import Model from './Model';
// eslint-disable-next-line import/no-cycle
import BankRecordMatchedResource from './BankRecordMatchedResource';
import BankRecordMatchType from '../../data/BankRecordMatchType';
import { BankRecordType } from '../../data/BankRecordType';

export default class BankRecord extends Model {
	@observable id;

	@observable amount;

	@observable description;

	@observable descriptionParsed;

	@observable counterpartName;

	@observable counterpartReference;

	@observable executionDate;

	/**
	 * @type {Boolean}
	 */
	@observable periodSubmitted;

	/**
	 * @type {BankRecordMatchedResource}
	 */
	@observable matchedResource;

	@observable categoryId;

	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		super(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'executionDate':
						this[key] = new Date(data[key]);
						break;
					case 'matchedResource':
						this[key] = new BankRecordMatchedResource(data[key]);

						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	@action unMatch() {
		this.matchedResource = null;
		this.categoryId = null;
	}

	/**
	 *
	 * @param {BankRecordMatchedResource} resource
	 */
	@action match(resource) {
		this.matchedResource = resource;
	}

	/**
	 *
	 * @return {BankRecordMatchType}
	 */
	@computed get matchStatus() {
		if (this.matchedResource && this.matchedResource.matchStatus) {
			return BankRecordMatchType.MATCHED;
		}
		return BankRecordMatchType.UNMATCHED;
	}

	/**
	 * @return {String}
	 */
	@computed get type() {
		if (this.amount > 0) {
			return BankRecordType.INCOME;
		}
		return BankRecordType.EXPENSE;
	}

	@computed get restAmount() {
		return this.amount - this.matchedResource.amount;
	}

	/**
	 * @return {boolean}
	 */
	@computed get isProcessed() {
		return this.categoryId !== null;
	}
}
