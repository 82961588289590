import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, application) => {
	return fetch(
		Methods.GET,
		EndPoints.REVOKE_COMPANY_APPLICATION.replace(':companyId', companyId).replace(
			':application',
			application
		),
		{},
		null,
		true,
		true,
		false
	);
};
