import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 *
 * @param data
 * @param sendToBookkeeper
 * @return {Promise}
 */
export default (data, sendToBookkeeper) => {
	const endPoint = sendToBookkeeper
		? `${EndPoints.DOCUMENTS_USER}?sendToBookkeeper=true`
		: EndPoints.DOCUMENTS_USER;
	return fetch(Methods.POST, endPoint, data, null, true);
};
