import bankAccountGet from '../../requests/bank/bankAccountGet';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class GetBankAccountsCommand {
	/**
	 *
	 */
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.companyId = null;
	}

	/**
	 *
	 * @param companyId
	 * @param year
	 * @return {Promise<any | never>}
	 */
	execute(companyId) {
		this.companyId = companyId;
		return bankAccountGet(companyId).then(this.then).catch(this.catch);
	}

	/**
	 *
	 * @param response
	 */
	then(response) {
		return response;
	}

	/**
	 *
	 * @param error
	 */
	catch(error) {
		Signals.Error.dispatch(error);
	}
}
