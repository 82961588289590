import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { ApplicationContext } from '../../../ApplicationContext';

import AutocompleteSearchObject from '../../../data/AutocompleteSearchObject';

const SHOW_MORE_INCREMENT = 4;

/**
 *
 */
export default class AutocompleteResults extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this._reset();
		this.onShowMore = this.onShowMore.bind(this);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps() {
		this._reset();
	}

	/**
	 *
	 */
	render() {
		return (
			<div className="autocomplete-results">
				{this._getVisibleChildren()}
				{this._hasMoreResults() ? (
					<div className="autocomplete-results__more grid grid--center" onClick={this.onShowMore}>
						<div className="link icon icon--eye2-smoke">
							<FormattedMessage
								id="autocomplete.showmore"
								values={{ count: this._getMoreCount() }}
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	/**
	 *
	 */
	onShowMore() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ visibleCount: this.state.visibleCount + SHOW_MORE_INCREMENT });
	}

	/**
	 *
	 * @private
	 */
	_reset() {
		this.state.visibleCount = SHOW_MORE_INCREMENT;
	}

	/**
	 *
	 * @return {boolean}
	 * @private
	 */
	_hasMoreResults() {
		return this.props.children && this.props.children.length - this.state.visibleCount > 0;
	}

	/**
	 *
	 * @return {number}
	 * @private
	 */
	_getMoreCount() {
		if (this.props.children) {
			return Math.max(this.props.children.length - this.state.visibleCount, 0);
		}

		return 0;
	}

	/**
	 *
	 * @return {string|null}
	 * @private
	 */
	_getVisibleChildren() {
		if (this.props.children) {
			return this.props.children.slice(0, this.state.visibleCount);
		}

		return null;
	}
}

AutocompleteResults.contextType = ApplicationContext;

AutocompleteResults.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	// eslint-disable-next-line react/no-unused-prop-types
	searchObject: PropTypes.instanceOf(AutocompleteSearchObject)
};
