import React from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class ToolTip extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.screenPadding = 16;
		this.toolTip = null;
		this.toolTipArrow = null;

		this.state = {
			content: null,
			showToolTip: false,
			toolTipOffset: 0,
			toolTipOffsetX: 0,
			toolTipTarget: null,
			tooltipArrow: 'up'
		};

		this.close = this.close.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.update = this.update.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.ShowToolTip.add(this.show);
		Signals.HideToolTip.add(this.hide);
	}

	/**
	 *
	 */
	componentDidMount() {
		this._isMounted = true;

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);
	}

	/**
	 *
	 */
	componentWillUpdate() {
		if (this.state.showToolTip) {
			Signals.RootClick.add(this.close);
		} else {
			Signals.RootClick.remove(this.close);
		}
	}

	/**
	 *
	 */
	componentDidUpdate() {
		this.updatePosition();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		this._isMounted = false;

		Signals.ShowToolTip.remove(this.show);
		Signals.HideToolTip.remove(this.hide);

		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'tooltip--show': this.state.showToolTip,
			'tooltip--hide': !this.state.showToolTip
		});

		const arrow = classNames({
			tooltip__arrow_up: this.state.tooltipArrow === 'up',
			tooltip__arrow_down: this.state.tooltipArrow === 'down'
		});

		return (
			<div
				ref={(toolTip) => {
					this.toolTip = toolTip;
				}}
				className={`tooltip ${classes}`}>
				<div className="tooltip__content-wrapper">{this.state.content}</div>
				<div
					ref={(toolTipArrow) => {
						this.toolTipArrow = toolTipArrow;
					}}
					className={arrow}
				/>
			</div>
		);
	}

	/**
	 *
	 */
	update() {
		this.updatePosition();
	}

	/**
	 *
	 */
	close() {
		if (this.state.showToolTip) {
			Signals.HideToolTip.dispatch();
		}
	}

	/**
	 *
	 */
	updatePosition() {
		const target = this.state.toolTipTarget;
		const offsetTop = this.state.toolTipOffset;
		const offsetLeft = this.state.toolTipOffsetX;

		try {
			if (target && this.toolTip) {
				const targetBounds = target.getBoundingClientRect();
				const toolTipBounds = this.toolTip.getBoundingClientRect();

				// Ideal point for tooltip to 'point' at
				const px = targetBounds.left + targetBounds.width * 0.5;
				const py = targetBounds.bottom + offsetTop; // + Math.round(targetBounds.height * 0.5);

				// Center tooltip by substracting half its width
				let x = px - toolTipBounds.width * 0.5;

				// Apply bounds to calculated position
				if (x < this.screenPadding) {
					x = this.screenPadding;
				}

				// var offsetX = 0;
				const curX = x;
				const rightSide = x + toolTipBounds.width + this.screenPadding;
				if (rightSide > window.innerWidth) {
					// offsetX = nx - x;
					x = window.innerWidth - toolTipBounds.width - this.screenPadding;
					const movedX = Math.round(x - curX);
					this.toolTipArrow.style.left = `calc(50% - ${movedX}px)`;
				} else {
					this.toolTipArrow.style.left = 'calc(50%)';
				}

				// Set position
				TweenLite.set(this.toolTip, { x: Math.round(x + offsetLeft), y: Math.round(py) });
			}
		} catch (e) {
			// Ignore
		}
	}

	/**
	 *
	 * @param content
	 * @param target
	 * @param offsetTop
	 * @param offsetLeft
	 * @param tooltipArrow - 'up' or 'down'
	 */
	show(content, target, offsetTop = 16, offsetLeft = 2, tooltipArrow = 'up') {
		if (!this.state.showToolTip) {
			clearTimeout(this.hideTimeout);
			this.state.tooltipArrow = tooltipArrow;
			this.state.content = content;
			this.state.toolTipTarget = target;
			this.state.toolTipOffset = offsetTop;
			this.state.toolTipOffsetX = offsetLeft;
			this.state.showToolTip = true;
			if (this._isMounted) {
				this.forceUpdate();
			}
		}
	}

	/**
	 *
	 */
	hide() {
		if (this.state.showToolTip) {
			clearTimeout(this.hideTimeout);
			this.state.showToolTip = false;

			this.hideTimeout = setTimeout(() => {
				this.state.content = null;
				this.state.toolTipTarget = null;
				this.state.toolTipOffset = 0;
				this.state.toolTipOffsetX = 0;
				this.forceUpdate();
			}, 300);

			if (this._isMounted) {
				this.forceUpdate();
			}
		}
	}
}

ToolTip.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	onClose: PropTypes.func
};

ToolTip.defaultProps = {};
