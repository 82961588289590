import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import ScrollController from '../../../controllers/ScrollController';
import Signals from '../../../signals/Signals';

import Page from '../../Page';
import Deadline from '../../../components/ui/Deadline/Deadline';
import CollapsablePanel from '../../../components/ui/CollapsablePanel/CollapsablePanel';
import FinancialStatement from '../../../components/ui/FinancialStatement/FinancialStatement';
import TaxReturnYear from './TaxReturnYear';
import { ApplicationContext } from '../../../ApplicationContext';
import PropertiesController from '../../../controllers/PropertiesController';
import BTWPeriod from '../../../data/BTWPeriod';

@observer
/**
 *
 */
class Deadlines extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.update = this.update.bind(this);
		this.scrollToDeadlines = this.scrollToDeadlines.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.TodosFetched.add(this.scrollToDeadlines);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.update();
		this.context.applicationStore.todoStore.fetch();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.update();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.TodosFetched.remove(this.scrollToDeadlines);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		// Checks
		if (this.context.applicationStore.yearResultStore.fetchingDeadlines) {
			return null;
		}

		if (!this.context.applicationStore.user) {
			return null;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company || !financialYear) {
			return null;
		}

		const annualStatementModel = this.props.annualStatementStore.getAnnualStatementByYear(
			financialYear.year,
			[financialYear.year]
		);
		if (!annualStatementModel) {
			return null;
		}

		const translationValues = {
			year: financialYear.year,
			bookkeeperFirstName: this.context.applicationStore.user.bookKeeper.firstName
		};

		const financialStatementSubmitted =
			this.context.applicationStore.financialStatementStore.submitted;
		const annualStatementSubmitted = annualStatementModel.isSubmitted();
		let deadlinesSubmitted =
			!this.context.applicationStore.yearResultStore.hasIncompleteDeadlines();

		const deadlineTodoCount = this.context.applicationStore.todoStore.hasVATStatementTodo(
			company,
			financialYear
		).length;
		const financialTodoCount = this.context.applicationStore.todoStore.hasFinancialStatementTodo(
			company,
			financialYear
		)
			? 1
			: 0;
		const annualTodoCount = this.context.applicationStore.todoStore.hasAnnualStatementTodo(
			financialYear.year
		)
			? 1
			: 0;

		// Collapse deadlines when FinancialStatement or AnnualStatement are active or have been submitted
		deadlinesSubmitted =
			(deadlinesSubmitted ||
				annualStatementSubmitted ||
				financialStatementSubmitted ||
				annualTodoCount > 0 ||
				financialTodoCount > 0) &&
			!deadlineTodoCount > 0;

		//
		const vatClasses = classNames({
			'deadlines__vat-statement': true,
			'collapsable-panel--completed': deadlinesSubmitted
		});

		const financialStatementClasses = classNames({
			'deadlines__financial-statement': true,
			'collapsable-panel--completed': financialStatementSubmitted
		});

		const annualStatementClasses = classNames({
			'deadlines__annual-statement': true,
			'collapsable-panel--completed': annualStatementSubmitted
		});

		// Render
		return (
			<Page pageName="deadlines">
				<div className="deadlines__content">
					{/* VAT */}
					<CollapsablePanel
						className={vatClasses}
						key={`colpan-vat-${company.id}-${financialYear.id}`}
						preTitle="01"
						collapsed={deadlinesSubmitted}
						canCollapse={deadlinesSubmitted}
						isComplete={deadlinesSubmitted}
						todoCount={deadlineTodoCount}
						title={<FormattedMessage id="deadlines.deadlines.title" />}>
						{/* Ignore deadlines for btw period VRIJGSTELD */}
						{company.vatTerm !== BTWPeriod.VRIJGESTELD.name
							? this._getBTWDeadlines(company, financialYear, translationValues)
							: null}
					</CollapsablePanel>

					{/* Financial Statement */}
					<CollapsablePanel
						className={financialStatementClasses}
						key={`colpan-fin-${company.id}-${financialYear.id}`}
						preTitle="02"
						title={<FormattedMessage id="deadlines.financial-statement.title" />}
						collapsed={financialStatementSubmitted}
						canCollapse={financialStatementSubmitted}
						isComplete={financialStatementSubmitted}
						todoCount={financialTodoCount}>
						{this._getFinancialStatement(company, financialYear, translationValues)}
					</CollapsablePanel>

					{/* Annual Statement, hide if external */}
					{!annualStatementModel.isExternal() ? (
						<CollapsablePanel
							className={annualStatementClasses}
							key={`colpan-anu-${company.id}-${financialYear.id}`}
							preTitle="03"
							title={<FormattedMessage id="taxreturn.panel.title" values={translationValues} />}
							collapsed={annualStatementSubmitted}
							canCollapse={annualStatementSubmitted}
							isComplete={annualStatementSubmitted}
							todoCount={annualTodoCount}>
							{this._getTaxReturn(company, financialYear, annualStatementModel, translationValues)}
						</CollapsablePanel>
					) : null}
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	update() {
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (financialYear) {
			this.context.applicationStore.yearResultStore.fetchDeadlines(financialYear.id);
			this.context.applicationStore.financialStatementStore.fetch(financialYear.id);
		}
	}

	/**
	 *
	 */
	scrollToDeadlines() {
		console.log('Deadlines.scrollToDeadlines');

		// Ignore scroll
		if (PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
			return;
		}

		// Scroll to Financial Statement component when a todo is found for this company + financial year
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company || !financialYear) {
			return;
		}

		// Make all components update
		this.forceUpdate();

		const hasVATDeadlines = this.context.applicationStore.todoStore.hasVATStatementTodo(
			company,
			financialYear
		);
		const hasFinancialStatementTodo =
			this.context.applicationStore.todoStore.hasFinancialStatementTodo(company, financialYear);
		const hasAnnualStatementTodo = this.context.applicationStore.todoStore.hasAnnualStatementTodo(
			financialYear.year
		);

		let targetEl;
		if (hasVATDeadlines && hasVATDeadlines.length > 0) {
			targetEl = document.querySelector(
				`.deadlines__vat-statement [data-term="${hasVATDeadlines[0].termType}"]`
			);
		} else if (hasFinancialStatementTodo) {
			targetEl = document.querySelector('.deadlines__financial-statement');
		} else if (hasAnnualStatementTodo) {
			targetEl = document.querySelector('.deadlines__annual-statement');
		}

		//
		if (targetEl) {
			console.log('Deadlines.scrollToDeadlines');

			clearTimeout(this.scrollToDeadlineTimeout);
			this.scrollToDeadlineTimeout = setTimeout(() => {
				const el = targetEl;
				if (el) {
					ScrollController.scrollPageToElement(el, 152 + 24, 0.3, 0); // offset top = menu + submenu + 24px + 24px
				}
			}, 300);
		}
	}

	/**
	 * @param company
	 * @param financialYear
	 * @param translationValues
	 * @private
	 */
	_getBTWDeadlines(company, financialYear, _translationValues = {}) {
		let deadlines = this.context.applicationStore.yearResultStore.deadlines;
		deadlines = deadlines.sort((a, b) => {
			return a.submitStart.getTime() - b.submitStart.getTime();
		});

		// BTW Deadlines
		const deadlineRows = [];
		deadlines.forEach((deadline, index) => {
			deadlineRows.push(
				<Deadline
					key={`dl-${index}`}
					deadline={deadline}
					financialyear={financialYear}
					index={index}
				/>
			);
		});

		return deadlineRows;
	}

	/**
	 * @param company
	 * @param financialYear
	 * @param translationValues
	 * @returns {*}
	 * @private
	 */
	_getFinancialStatement(company, financialYear, translationValues = {}) {
		// Only check if there is a todo, then unlock
		const { applicationStore } = this.context;
		const { financialStatementStore } = applicationStore;
		const locked =
			!applicationStore.todoStore.hasFinancialStatementTodo(company, financialYear) &&
			!financialStatementStore.submitted;
		let content = null;

		if (locked) {
			content = this._getLockedState('financialstatement.locked', translationValues);
		} else {
			content = (
				<FinancialStatement
					className="deadlines__financial-statement"
					titleNumber="02."
					financialStatementStore={financialStatementStore}
					disabled={financialStatementStore.submitted}
				/>
			);
		}

		return content;
	}

	/**
	 *
	 * @param company
	 * @param financialYear
	 * @param annualStatementModel
	 * @param translationValues
	 * @returns {*}
	 * @private
	 */
	_getTaxReturn(company, financialYear, annualStatementModel, _translationValues = {}) {
		// Only check if there is a todo, then unlock
		const locked =
			!this.context.applicationStore.todoStore.hasAnnualStatementTodo(financialYear.year) &&
			!annualStatementModel.isSubmitted();

		return (
			<TaxReturnYear
				className="border--top border--dark deadlines__financial-statement"
				annualStatementModel={annualStatementModel}
				locked={locked}
				disabled={annualStatementModel.isSubmitted()}
			/>
		);
	}

	/**
	 * @param descriptionTranslationId
	 * @param translationValues
	 * @returns {*}
	 * @private
	 */
	_getLockedState(descriptionTranslationId, translationValues) {
		return (
			<div className="grid grid--spread border--top border--dark padding">
				<div className="deadlines-locked__icon icon icon--lock-smoke" />
				<div className="deadlines-locked__text">
					<FormattedMessage id={descriptionTranslationId} values={translationValues} />
				</div>
			</div>
		);
	}
}

Deadlines.contextType = ApplicationContext;

Deadlines.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object,
	annualStatementStore: PropTypes.object.isRequired
};

export default injectIntl(Deadlines);
