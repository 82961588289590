/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class ImageCarousel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.preloadImage = null;
		this.state = {
			index: this.props.startIndex ? this.props.startIndex : 0,
			loaded: []
		};

		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.close = this.close.bind(this);
		this.onDotClick = this.onDotClick.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onImageError = this.onImageError.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.RouteChanged.add(this.onRouteChanged);
		Signals.KeyDown.add(this.onKeyDown);
		Signals.OverlayBackgroundClick.add(this.close);

		// Start preloading
		this.loadNext();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		if (this.preloadImage) {
			this.preloadImage.onload = () => {};
			this.preloadImage.onerror = () => {};
		}

		Signals.RouteChanged.remove(this.onRouteChanged);
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.close);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const images = this._generateImages();
		const dots = this._generateDots();

		return (
			<div className={`image-carousel ${this.props.className}`}>
				<div className="image-carousel__image-wrapper">
					<div className="image-carousel__image-container">{images}</div>
				</div>
				<div className="image-carousel__dots grid">{dots}</div>
				<div
					className="image-carousel__arrow-left icon icon--chevron-left-smoke"
					onClick={this.previous}
				/>
				<div
					className="image-carousel__arrow-right icon icon--chevron-right-smoke"
					onClick={this.next}
				/>

				<div className="image-carousel__close icon icon icon--close-black" onClick={this.close} />
			</div>
		);
	}

	/**
	 *
	 * @param event
	 */
	onKeyDown(event) {
		// left arrow
		if (event.keyCode === 37) {
			this.previous();
		}

		// right arrow
		if (event.keyCode === 39) {
			this.next();
		}

		// esc
		if (event.keyCode === 27) {
			this.close();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		this.close();
	}

	/**
	 *
	 * @param event
	 */
	onImageLoaded(event) {
		this.state.loaded.push(event.target);
		this.loadNext();
	}

	/**
	 *
	 * @param event
	 */
	onImageError() {
		this.state.loaded.push(null);
		this.loadNext();
	}

	/**
	 *
	 * @param event
	 */
	onDotClick(event) {
		this.state.index = parseInt(event.target.dataset.index, 10);
		this.update();
	}

	/**
	 *
	 */
	loadNext() {
		if (this.state.loaded.length >= this.props.images.length) {
			this.preloaded();
		} else {
			try {
				this.preloadImage = new Image();
				this.preloadImage.onload = this.onImageLoaded;
				this.preloadImage.onerror = this.onImageError;
				this.preloadImage.src = this.props.images[this.state.loaded.length];
				console.log('load next', this.preloadImage.src);
			} catch (error) {
				console.log(error);
			}
		}
	}

	/**
	 *
	 */
	preloaded() {}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 */
	next() {
		this.state.index++;
		this.update();
	}

	/**
	 *
	 */
	previous() {
		this.state.index--;
		this.update();
	}

	/**
	 *
	 */
	update() {
		if (isNaN(this.state.index)) {
			this.state.index = 0;
		}

		if (this.state.index < 0) {
			this.state.index += this.props.images.length;
		}

		if (this.state.index > this.props.images.length - 1) {
			this.state.index -= this.props.images.length;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_generateImages() {
		return this.props.images.map((image, index) => {
			return (
				<div
					key={`imgc-${index}`}
					className={`image-carousel__image ${
						index === this.state.index ? 'image-carousel__image--selected' : ''
					}`}>
					<div
						className="image-carousel__image-img"
						style={{
							backgroundImage: `url("${image}")`,
							backgroundSize: 'contain',
							backgroundPosition: 'center'
						}}
					/>
				</div>
			);
		});
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_generateDots() {
		return this.props.images.map((image, index) => {
			return (
				<div
					key={`imgd-${index}`}
					className={`image-carousel__dot ${
						index === this.state.index ? 'image-carousel__dot--selected' : ''
					}`}
					data-index={index}
					onClick={this.onDotClick}
				/>
			);
		});
	}
}

ImageCarousel.propTypes = {
	startIndex: PropTypes.number,
	className: PropTypes.string,
	images: PropTypes.array.isRequired
};

ImageCarousel.defaultProps = {
	startIndex: 0,
	className: ''
};
