/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import { computed, observable, action } from 'mobx';

import OfferStatus from '../data/OfferStatus';
import FilterRuleHasOneOrMoreValues from './filters/FilterRuleHasOneOrMoreValues';
import Filter from './filters/Filter';

/**
 *
 */
export default class OffersStore {
	filter = new Filter();

	bookyearFilter = new FilterRuleHasOneOrMoreValues('bookyear');

	@observable offers = [];

	@observable searchFilter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable fetched = false; // Has invoice concept store been fetched at all?

	@observable fetching = false;

	autofocusField = null;

	isArchiveStore = false;

	/**
	 *
	 */
	constructor(isArchiveStore = false) {
		this.isArchiveStore = isArchiveStore;

		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);

		this.filter.addRule(this.bookyearFilter);
	}

	/**
	 *
	 */
	reset() {
		this.searchFilter = null;
		this.sortBy = null;
		this.sortDirection = 1;

		this.offers = [];

		this.fetched = false;
		this.fetching = false;
	}

	/**
	 *
	 * @returns {*[]}
	 */
	@computed get filteredAndSortedOffers() {
		return this.offers
			.filter((offer) => {
				return this._filter(offer);
			})
			.sort(this._sort);
	}

	/**
	 *
	 * @return {number}
	 */
	getArchivedOfferCount() {
		return this.offers.filter((offer) => {
			return offer.status === OfferStatus.ARCHIVED;
		}).length;
	}

	/**
	 *
	 */
	getLastUsedOffer() {
		const sortedById = this.offers.sort((a, b) => {
			// No id means new Invoice is last added
			if (!a.id) {
				return 1;
			}
			if (!b.id) {
				return -1;
			}

			return b.id - a.id;
		});

		//
		if (sortedById.length > 0) {
			return sortedById[0];
			// First offer!
		}
		return null;
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getOfferById(id) {
		return this.offers.find((offer) => {
			return offer.id === id;
		});
	}

	/**
	 *
	 * @param id
	 */
	@action removeOffer(id) {
		const offer = this.offers.find((i) => {
			return i.id === id;
		});

		this.offers.remove(offer);
	}

	/**
	 *
	 * @param offerModel OfferModel
	 */
	@action addOffer(offerModel) {
		const index = this.offers.findIndex((offer) => offer.id === offerModel.id);

		if (index >= 0) {
			this.offers[index] = offerModel;
		} else {
			this.offers.push(offerModel);
		}
	}

	/**
	 *
	 * @param offerModels Array
	 */
	@action addAll(offerModels) {
		const offers = this.offers.concat([]);
		offerModels.forEach((offerModel) => {
			const index = this.offers.findIndex((offer) => offer.id === offerModel.id);

			if (index >= 0) {
				offers[index] = offerModel;
			} else {
				offers.push(offerModel);
			}
		});

		this.offers = offers;
	}

	/**
	 *
	 */
	update() {
		this.offers = this.offers.concat([]);
	}

	/**
	 * @param offerModel
	 * @returns {boolean}
	 * @private
	 */
	_filter(offerModel) {
		if (!offerModel) {
			return true;
		}

		// Always filter out deleted, filter archive or other state based on isArchiveStore
		if (
			(!this.isArchiveStore && offerModel.status === OfferStatus.ARCHIVED) ||
			(this.isArchiveStore && offerModel.status !== OfferStatus.ARCHIVED) ||
			offerModel.status === OfferStatus.DELETED
		) {
			return false;
		}

		// Apply Filter
		if (!this.filter.apply(offerModel)) {
			return false;
		}

		// Apply Search filter
		if (!this.searchFilter) {
			return true;
		}

		const searchString = offerModel.toSearchString();
		return searchString.indexOf(this.searchFilter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'offerNr':
				const aNumber = parseInt(a.offerNr, 10);
				const bNumber = parseInt(b.offerNr, 10);
				if (aNumber && bNumber) {
					result = aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0;
				} else {
					result = a.offerNr.localeCompare(b.offerNr);
				}
				break;
			case 'companyName':
				const companyNameA = a.companyCustomer.getCustomerName(); // a.companyCustomer && a.companyCustomer.companyName ? a.companyCustomer.companyName : null;
				const companyNameB = b.companyCustomer.getCustomerName(); // b.companyCustomer && b.companyCustomer.companyName ? b.companyCustomer.companyName : null;

				if (!companyNameA && !companyNameB) {
					result = 0;
				} else if (companyNameA && !companyNameB) {
					result = 1;
				} else if (companyNameB && !companyNameA) {
					result = -1;
				} else {
					result = companyNameA.localeCompare(companyNameB);
				}
				break;
			case 'date':
				result = a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
				break;
			case 'expirationDate':
				result =
					a.expirationDate < b.expirationDate ? -1 : a.expirationDate > b.expirationDate ? 1 : 0;
				break;
			case 'statusDateChanged':
				result =
					a.statusDateChanged > b.statusDateChanged
						? -1
						: a.statusDateChanged < b.statusDateChanged
						? 1
						: 0;
				break;
			case 'amount':
				result = a.getTotal() - b.getTotal();
				break;
			case 'subject':
				result = a.subject.localeCompare(b.subject);
				break;
			case 'status':
			default:
				result = this._compareStatus(a.status, b.status);
				if (result === 0) {
					switch (a.status) {
						// sort by oldest first
						case OfferStatus.SIGNED:
						case OfferStatus.DECLINED:
						case OfferStatus.SENT_MANUALLY:
						case OfferStatus.SENT:
						case OfferStatus.LATE:
						case OfferStatus.SENT_REMINDER:
						case OfferStatus.CREATED:
						case OfferStatus.ARCHIVED:
							result =
								a.expirationDate < b.expirationDate
									? -1
									: a.expirationDate > b.expirationDate
									? 1
									: 0;
							break;
						default:
							result = 1;
					}
				}
				break;
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}

	/**
	 *
	 * @param statusA
	 * @param statusB
	 * @return {number}
	 * @private
	 */
	_compareStatus(statusA, statusB) {
		const weightA = this._getStatusWeight(statusA);
		const weightB = this._getStatusWeight(statusB);

		return weightA - weightB;
	}

	/**
	 *
	 * @param status
	 * @return {number}
	 * @private
	 */
	_getStatusWeight(status) {
		switch (status) {
			case OfferStatus.CREATED:
				return 0;
			case OfferStatus.SENT:
			case OfferStatus.SENT_MANUALLY:
				return 1;
			case OfferStatus.LATE:
				return 2;
			case OfferStatus.SIGNED:
			case OfferStatus.DECLINED:
				return 3;
			case OfferStatus.ARCHIVED:
				return 4;
			case OfferStatus.DELETED:
				return 5;
			default:
				return 6;
		}
	}
}
