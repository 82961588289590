import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ApplicationContext } from '../../../../ApplicationContext';
import Signals from '../../../../signals/Signals';
import { Routes } from '../../../../data/Routes';
import PropertiesController from '../../../../controllers/PropertiesController';
import Context from '../../../../data/Context';

import MenuItem from '../../MenuItem/MenuItem';
import DropDown from '../../DropDown/DropDown';
import AddDocumentInput from '../../../../pages/Documents/AddDocumentInput';
import { uniqueKey } from '../../../../utils/ReactUtils';

/**
 *
 */
class DocumentsSubMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		const now = new Date();
		this.state = { selectedYear: now.getFullYear() };
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;

		this.onYearChanged = this.onYearChanged.bind(this);
		this.onYearChangedByDocumentStore = this.onYearChangedByDocumentStore.bind(this);
		this.addNew = this.addNew.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.DocumentYearChanged.add(this.onYearChangedByDocumentStore);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.DocumentYearChanged.remove(this.onYearChangedByDocumentStore);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		return [
			<ul className="documents__sub-menu sub-menu__item-wrapper">
				<DropDown
					color={PropertiesController.getContext() === Context.FOUNDERS ? 'smoke' : 'navy'}
					value={this.state.selectedYear}
					onChange={this.onYearChanged}>
					{this._getYearOptions()}
				</DropDown>

				{this._getCompaniesItems()}
				{/* {this._getUserItems()} */}
			</ul>,
			this._getOptions()
		];
	}

	/**
	 *
	 * @param e
	 */
	onYearChanged(e) {
		this.context.applicationStore.setSelectedDocumentYear(e.target.value);
	}

	/**
	 *
	 * @param year
	 */
	onYearChangedByDocumentStore(year) {
		console.log('onYearChangedByDocumentStore', year);
		this.setState({ selectedYear: year });
	}

	/**
	 *
	 */
	addNew() {
		// Show IncomeInput overlay
		Signals.ShowOverlay.dispatch(<AddDocumentInput key="add-document-input" />);
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getYearOptions() {
		// Check which company, or which user is selected
		const now = new Date();
		let availableYears = [now.getFullYear()];
		this.context.applicationStore.user.companies.forEach((company) => {
			// eslint-disable-next-line array-callback-return
			company.financialYears.map((year) => {
				availableYears.push(parseInt(year.year, 10));
			});
		});

		// Only unique years
		availableYears = availableYears.filter((year, index) => {
			return availableYears.indexOf(year) === index;
		});
		availableYears.sort((a, b) => {
			return b - a;
		});

		return availableYears.map((year, index) => {
			return (
				<option key={`fy-${index}`} value={year}>
					{year}
				</option>
			);
		});
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getCompaniesItems() {
		const { applicationStore } = this.context;
		const currentRoute = applicationStore.currentRoute;
		const company = applicationStore.getSelectedCompany();

		if (
			currentRoute !== Routes.COMPANY_DOCUMENTS &&
			currentRoute !== Routes.COMPANY_PAYROLL_DOCUMENTS
		) {
			return null;
		}

		const items = [];

		items.push(
			<MenuItem
				key={uniqueKey('company-documents-', company.id)}
				className="submenu-item"
				route={Routes.COMPANY_DOCUMENTS.getPath({ id: company.id })}
				routeToMatch={Routes.COMPANY_DOCUMENTS}
				matchExact
				iconName="home">
				<span>
					<FormattedMessage id="submenu.company.documents" />
				</span>
			</MenuItem>
		);

		if (PropertiesController.getProperty(PropertiesController.FEATURE_PAYROLLADMINISTRATION)) {
			items.push(
				<MenuItem
					key={uniqueKey('company-documents-payroll-', company.id)}
					className="submenu-item"
					route={Routes.COMPANY_PAYROLL_DOCUMENTS.getPath({ id: company.id })}
					routeToMatch={Routes.COMPANY_PAYROLL_DOCUMENTS}
					matchExact
					iconName="file2">
					<span>
						<FormattedMessage id="submenu.company.payroll" />
					</span>
				</MenuItem>
			);
		}

		return items;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getOptions() {
		const { applicationStore } = this.context;
		const currentRoute = applicationStore.currentRoute;

		if (currentRoute !== Routes.COMPANY_PAYROLL_DOCUMENTS) {
			return (
				<button
					type="button"
					className="documents__add-new icon icon--left icon--big-plus icon--color"
					onClick={this.addNew}>
					<FormattedMessage id="documents.add.new" />
				</button>
			);
		}
	}
}

DocumentsSubMenu.contextType = ApplicationContext;

DocumentsSubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(DocumentsSubMenu);
