import Signals from '../../signals/Signals';
import getBankbookStatus from '../../requests/bank/getBankbookStatus';
import { Routes } from '../../data/Routes';

export default class GetBankbookStatusCommand {
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.companyId = null;
	}

	execute(companyId) {
		this.companyId = companyId;
		return getBankbookStatus(companyId).then(this.then).catch(this.catch);
	}

	then(response) {
		return response;
	}

	catch(error) {
		Signals.RequestRoute.dispatch(Routes.COMPANY_DASHBOARD.getPath({ id: this.companyId }));
		Signals.Error.dispatch(error);
	}
}
