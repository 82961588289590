import AbstractCommand from '../AbstractCommand';
import userDraftsGet from '../../requests/drafts/userDraftsGet';
import Draft from '../../stores/models/Draft';

/**
 *
 */
export default class GetUserDraftsCommand extends AbstractCommand {
	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param userId Number
	 */
	constructor(draftsStore, userId) {
		super();

		this.draftsStore = draftsStore;
		this.userId = userId;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return userDraftsGet(this.userId)
			.then((response) => {
				this._parseResult(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResult(response) {
		this.draftsStore.reset();
		response.list.forEach((data) => {
			const draft = new Draft(data);
			this.draftsStore.add(draft);
		});
	}
}
