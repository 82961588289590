/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedNumber } from 'react-intl';

import BTW from '../../../../data/BTW';
import OfferRowModel from '../../../../stores/models/OfferRowModel';
import OfferModel from '../../../../stores/models/OfferModel';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class OfferRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<tr className="table-row border--bottom">
				<td className="table-row__column table-row__column--multiline offer-row__description">
					<span
						dangerouslySetInnerHTML={{ __html: this.props.offerRowModel.getDescriptionAsHTML() }}
					/>
				</td>

				{this._getUnits()}

				<td className="table-row__column offer-row__unitprice">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.offerRowModel.unitPrice}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>

				<td className="table-row__column offer-row__btw">
					<span>
						{this.props.intl.formatMessage({
							id: BTW.getBTWObject(this.props.offerRowModel.btw).translationId(
								this.props.offerModel.date
							)
						})}
					</span>
				</td>

				<td className="table-row__column offer-row__amount">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.offerRowModel.amount}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @return {null}
	 * @private
	 */
	_getUnits() {
		return (
			<td className="offer-row__column offer-row__units">
				<span>
					{this.props.hideUnits ? null : (
						<FormattedNumber
							value={this.props.offerRowModel.units}
							minimumFractionDigits={2}
							maximumFractionDigits={2}
						/>
					)}
				</span>
			</td>
		);
	}
}

OfferRow.contextType = ApplicationContext;

OfferRow.propTypes = {
	hideUnits: PropTypes.bool,
	offerRowModel: PropTypes.instanceOf(OfferRowModel),
	offerModel: PropTypes.instanceOf(OfferModel).isRequired,
	intl: PropTypes.object
};

export default injectIntl(OfferRow);
