/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { TweenLite } from 'gsap';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import Page from '../../Page';

import UpdateCommunityProfileCommand from '../../../commands/UpdateCommunityProfileCommand';

import { uniqueKey } from '../../../utils/ReactUtils';
import Signals from '../../../signals/Signals';
import CommunityProfileModel from '../../../stores/models/CommunityProfile';
import CommunityProfileStore from '../../../stores/CommunityProfileStore';

import { stripProtocolFromUrl } from '../../../utils/objectToFormData';

import { Routes } from '../../../data/Routes';

import ImageCarousel from '../../../components/ui/ImageCarousel/ImageCarousel';
import SocialLink from '../../../components/ui/SocialLink/SocialLink';
import Switch from '../../../components/ui/Switch/Switch';
import CompanyInput from '../../Company/Info/modals/CompanyInput';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';

import CommunityProfileInput from './components/modals/CommunityProfileInput';
import CommunityProfileBlock from '../../../components/ui/Community/CommunityProfileBlock';

import communityProfile from '../../../requests/communityProfile';
import { ApplicationContext } from '../../../ApplicationContext';

const MAX_RELATED = 3;

/**
 *
 */
class CommunityProfile extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onLogoClick = this.onLogoClick.bind(this);
		this.onImageClick = this.onImageClick.bind(this);
		this.onImageOut = this.onImageOut.bind(this);
		this.onImageOver = this.onImageOver.bind(this);
		this.onProfileLoaded = this.onProfileLoaded.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onActivateSwitch = this.onActivateSwitch.bind(this);
		this.onCommunityOverview = this.onCommunityOverview.bind(this);
		this.onCompanyProfile = this.onCompanyProfile.bind(this);
		this.onCompanyChanged = this.onCompanyChanged.bind(this);
		this.reload = this.reload.bind(this);

		this.onCompanyUpdate = this.onCompanyUpdate.bind(this);
		this.onProfileUpdate = this.onProfileUpdate.bind(this);
		this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
		this.onUpdateError = this.onUpdateError.bind(this);

		this.onResize = this.onResize.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.state = {};
		this.reload();
		Signals.CompanyChanged.add(this.onCompanyChanged);
	}

	/**
	 *
	 */
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
		window.addEventListener('scroll', this.onScroll);
	}

	/**
	 *
	 */
	componentWillReceiveProps() {
		this.state = {};
		this.reload();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('scroll', this.onScroll);
		Signals.CompanyChanged.remove(this.onCompanyChanged);
	}

	/**
	 *
	 */
	render() {
		if (
			!this.company ||
			!this.context.applicationStore.communityCategoriesStore.fetched ||
			!this.state.communityProfile
		) {
			return null;
		}

		const company = this.state.canEdit ? this.company : null;
		const description = this.state.communityProfile.description
			? this.state.communityProfile.description
			: this.props.intl.formatMessage({ id: 'community.input.edit.description.placeholder' });
		const descriptionClasses = classNames({
			'community-profile__description': true,
			'community-profile__value--disabled': !this.state.communityProfile.description
		});
		const coverClasses = classNames({
			'community-profile__value--disabled': !this.state.communityProfile.coverAssetUuid
		});

		return (
			<Page pageName="community-profile">
				<div className="community-profile__options grid grid--spread border--bottom border--dark">
					<button
						type="button"
						className="button--tertiary icon icon--left icon--eye2-black btn-margin-left"
						onClick={this.onCommunityOverview}>
						<FormattedMessage id="community.showprofileoverview.button" />
					</button>

					<div className="community-profile__options--right">{this._getEdit()}</div>
				</div>

				<div className="community-profile__content grid">
					<div className="community-profile__wrapper grid col--12">
						<div className="community-profile__info-wrapper grid">
							<div className="community-profile__info panel border border--dark">
								{this._getBrand()}
								{this._getContact()}
								{this._getSocial()}
							</div>
						</div>

						{/* Profile */}
						<div className="community-profile__profile panel border border--dark">
							<div className="community-profile__cover-image">
								<img
									className={coverClasses}
									src={CommunityProfileModel.buildAsset(
										company,
										this.state.communityProfile.coverAssetUuid,
										1600
									)}
									data-index={0}
									onClick={this.onImageClick}
									onMouseOver={this.state.canEdit ? this.onImageOver : null}
									onMouseOut={this.state.canEdit ? this.onImageOut : null}
									alt=""
								/>
							</div>
							<div
								className={descriptionClasses}
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
							/>

							{this.state.canEdit ? (
								<button
									type="button"
									className="button--tertiary community-profile__profile-edit-button icon icon--left icon--edit-black"
									onClick={() => this.onEdit(CommunityProfileInput.PROFILE)}>
									<FormattedMessage id="label.edit" />
								</button>
							) : null}

							{this._getPhotos()}

							{this.state.canEdit ? (
								<div
									className="community-profile__edit-icon box-shadow-dark"
									ref={(ref) => (this.editIcon = ref)}>
									<div className="icon icon--edit-black" />
									{/* <div className="icon icon--edit-white"/> */}
								</div>
							) : null}
						</div>
					</div>

					{this.state.communityProfile.relatedProfiles.length > 0 ? (
						<div className="community-profile__related panel padding border border--dark col--12">
							<h2 className="community-profile__related__title font--title-grey">
								<FormattedMessage id="community.related.title" />
							</h2>
							<div className="community-profile__related-content padding--top grid">
								{this._getRelated()}
							</div>
						</div>
					) : null}
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param result
	 */
	onProfileLoaded(result) {
		// eslint-disable-next-line no-shadow
		const communityProfile = new CommunityProfileModel();
		communityProfile.update(result.data);
		this.state.communityProfile = communityProfile;

		this.setState({ communityProfile });
	}

	/**
	 *
	 * @param event
	 */
	onActivateSwitch(event) {
		const missingFieldIds = this._getMissingFields();

		// Not complete
		if (missingFieldIds.length > 0) {
			const missingFieldsTexts = missingFieldIds.map((fieldId) => {
				return this.props.intl.formatMessage({ id: fieldId });
			});

			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'community.missingfields.alert.title' })}
					body={this.props.intl.formatHTMLMessage(
						{ id: 'community.missingfields.alert.body' },
						{ fields: missingFieldsTexts.join(', ') }
					)}
				/>
			);

			// Complete, save data
		} else if (!this.state.submitting) {
			this.state.submitting = true;
			this.state.communityProfile.active = event.target.checked;

			// TODO: update value immediately in case network is slow?
			const updateCommunityProfileCommand = new UpdateCommunityProfileCommand(
				this.company,
				this.state.communityProfile,
				this.context.applicationStore.communityCategoriesStore
			);

			updateCommunityProfileCommand.execute(this.onUpdateSuccess, this.onUpdateError);
		}
	}

	/**
	 *
	 */
	onCompanyChanged() {
		console.log('onCompanyChanged');
		this.reload();
	}

	/**
	 *
	 */
	onCompanyProfile() {
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_SETTINGS_COMMUNITY_PROFILE.getPath({ id: this.company.id, profileId: 'edit' })
		);
	}

	/**
	 *
	 */
	onCommunityOverview() {
		Signals.RequestRoute.dispatch(Routes.COMMUNITY_OVERVIEW.getPath({ id: this.company.id }));
	}

	/**
	 *
	 * @param response
	 */
	onUpdateSuccess(_response) {
		this.state.submitting = false;

		// Notify user of update
		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="community.edited.message"
				values={{ companyName: this.company.name }}
			/>
		);

		// Clear community profile store so it retrieves the update profile (just in case it appears in the results)
		this.props.communityProfileStore.reset();

		// Force re-render
		this.forceUpdate();
	}

	/**
	 *
	 * @param error
	 */
	onUpdateError(error) {
		this.state.submitting = false;
		Signals.Error.dispatch(error);
	}

	/**
	 *
	 * @param event
	 */
	onLogoClick(_event) {
		this.onEdit(CommunityProfileInput.DEFAULT);
	}

	/**
	 *
	 * @param event
	 */
	onImageOut(_event) {
		if (this.editIcon) {
			this.editElement = null;
			this.editIcon.classList.remove('community-profile__edit-icon--shown');
		}
	}

	/**
	 *
	 * @param event
	 */
	onImageOver(event) {
		if (this.editIcon) {
			this.editElement = event.target;
			this.editIcon.classList.add('community-profile__edit-icon--shown');
			this._updateEditIcon();
		}
	}

	/**
	 *
	 * @param event
	 */
	onImageClick(event) {
		// Show edit
		if (this.state.canEdit) {
			this.onEdit(CommunityProfileInput.PHOTOS);

			// Show image carousel
		} else {
			const company = this.state.canEdit ? this.company : null;
			const carouselPhotos = this.state.communityProfile.photos
				.filter((photoObject) => {
					return !!photoObject;
				})
				.map((photoObject) => {
					return CommunityProfileModel.buildAsset(company, photoObject.assetUuid, 1920);
				});

			carouselPhotos.unshift(
				CommunityProfileModel.buildAsset(company, this.state.communityProfile.coverAssetUuid)
			);

			Signals.ShowOverlay.dispatch(
				<ImageCarousel
					images={carouselPhotos}
					startIndex={parseInt(event.target.dataset.index, 10)}
				/>
			);
		}
	}

	/**
	 *
	 */
	onResize() {
		this._updateEditIcon();
	}

	/**
	 *
	 */
	onScroll() {
		this._updateEditIcon();
	}

	/**
	 *
	 * @param type
	 * @param field
	 */
	onEdit(type, field = null) {
		if (type) {
			Signals.ShowOverlay.dispatch(
				<CommunityProfileInput type={type} fieldToFocus={field} onUpdate={this.onProfileUpdate} />
			);
		} else {
			// Company Info
			Signals.ShowOverlay.dispatch(
				<CompanyInput
					key={uniqueKey('cbi-')}
					company={this.company}
					type={CompanyInput.CONTACT}
					fieldToFocus={field}
					onUpdate={this.onCompanyUpdate}
				/>
			);
		}
	}

	/**
	 *
	 */
	onCompanyUpdate() {
		this.state.communityProfile.website = this.company.website;
		this.state.communityProfile.phone = this.company.phoneNumber;
		this.state.communityProfile.email = this.company.email;

		this.state.communityProfile.address = this.company.address;

		this.forceUpdate();
	}

	/**
	 *
	 */
	onProfileUpdate() {
		this.forceUpdate();
	}

	/**
	 *
	 * @returns {*}
	 */
	reload() {
		this.company = this.context.applicationStore.getSelectedCompany();

		if (
			this.company &&
			this.context.applicationStore.currentRouteParams &&
			this.context.applicationStore.currentRouteParams.profileId
		) {
			const profileId = this.context.applicationStore.currentRouteParams.profileId;

			// Edit own profile
			this.state.canEdit = profileId === 'edit';

			// Profile owned by user, can edit
			if (this.state.canEdit) {
				this.state.communityProfile = this.company.communityProfile;

				this.state.communityProfile.address = this.company.address;
				this.state.communityProfile.email = this.company.email;
				this.state.communityProfile.website = this.company.website;
				this.state.communityProfile.phone = this.company.phoneNumber;
			}

			// If not found in store, load profile
			if (!this.state.communityProfile) {
				communityProfile(profileId)
					.then(this.onProfileLoaded)
					.catch((error) => {
						Signals.Error.dispatch(error);
					});
			}

			// Make sure categories are retrieved
			Signals.CommunityCategoriesFetched.addOnce(this.forceUpdate.bind(this));
			this.context.applicationStore.communityCategoriesStore.fetch();
		}
	}

	/**
	 *
	 * @param categoryId
	 * @returns {*}
	 * @private
	 */
	_getCategoryName(categoryId) {
		const user = this.context.applicationStore.user;
		const category =
			this.context.applicationStore.communityCategoriesStore.getCategoryById(categoryId);
		if (category) {
			return category.getName(user.language);
		}

		return null;
	}

	/**
	 *
	 * @returns {null}
	 * @private
	 */
	_getEdit() {
		return this.state.canEdit ? (
			<Switch
				onChange={this.onActivateSwitch}
				name="active"
				useState={false}
				checked={this.state.communityProfile.active}
				label={
					this.state.communityProfile.active
						? this.props.intl.formatMessage({ id: 'community.profile.activated' })
						: this.props.intl.formatMessage({ id: 'community.profile.deactivated' })
				}
			/>
		) : (
			<button
				type="button"
				className="button--tertiary icon icon--left icon--eye2-black btn-margin-left"
				onClick={this.onCompanyProfile}>
				<FormattedMessage id="community.showprofile.button" />
			</button>
		);
	}

	/**
	 * Get Branding JSX (logo, name, subtitle)
	 *
	 * @returns {*}
	 * @private
	 */
	_getBrand() {
		const company = this.state.canEdit ? this.company : null;

		const name = this.state.communityProfile.name
			? this.state.communityProfile.name
			: this.props.intl.formatMessage({ id: 'community.companyname.placeholder' });
		const category = this.state.communityProfile.category
			? this._getCategoryName(this.state.communityProfile.category)
			: this.props.intl.formatMessage({ id: 'community.category.placeholder' });

		const logoAsset = CommunityProfileModel.buildAsset(
			company,
			this.state.communityProfile.logoAssetUuid,
			96 * 2,
			96 * 2
		);
		const logoClasses = classNames({
			'community-profile__brand-logo': true,
			'community-profile__brand-logo--inactive': !this.state.canEdit,
			'community-profile__value--disabled': !this.state.communityProfile.logoAssetUuid
		});
		const nameClasses = classNames({
			'community-profile__brand-name': true,
			'community-profile__value--disabled': !this.state.communityProfile.name
		});
		const categoryClasses = classNames({
			'community-profile__brand-category font--meta': true,
			'community-profile__value--disabled': !this.state.communityProfile.category
		});

		return (
			<div className="community-profile__brand padding margin--bottom grid">
				<div
					className={logoClasses}
					style={{ backgroundImage: `url("${logoAsset}")` }}
					onClick={this.state.canEdit ? this.onLogoClick : null}
				/>
				<h2 className={nameClasses}>{name}</h2>
				<span className={categoryClasses}>{category}</span>

				{this.state.canEdit ? (
					<button
						type="button"
						className="button--tertiary icon icon--left icon--edit-black"
						onClick={() => this.onEdit(CommunityProfileInput.DEFAULT)}>
						<FormattedMessage id="label.edit" />
					</button>
				) : null}
			</div>
		);
	}

	/**
	 * Get JSX fo contact details
	 *
	 * @returns {*}
	 * @private
	 */
	_getContact() {
		if (!this.state.communityProfile.address) {
			return null;
		}

		const email = this.state.communityProfile.email
			? this.state.communityProfile.email
			: this.props.intl.formatMessage({ id: 'community.email.placeholder' });
		const phone = this.state.communityProfile.phone
			? this.state.communityProfile.phone
			: this.props.intl.formatMessage({ id: 'community.phone.placeholder' });
		const website = this.state.communityProfile.website
			? this.state.communityProfile.website
			: this.props.intl.formatMessage({ id: 'community.website.placeholder' });

		const address = this.state.communityProfile.address.address
			? this.state.communityProfile.address.address
			: this.props.intl.formatMessage({ id: 'community.address.placeholder' });
		const zipCodeCity =
			this.state.communityProfile.address.postalCode && this.state.communityProfile.address.city
				? `${this.state.communityProfile.address.postalCode} ${this.state.communityProfile.address.city}`
				: this.props.intl.formatMessage({ id: 'community.zipcodecity.placeholder' });

		const emailClasses = classNames({
			'community-profile__value--disabled': !this.state.communityProfile.email
		});
		const phoneClasses = classNames({
			'community-profile__value--disabled': !this.state.communityProfile.phone
		});
		const websiteClasses = classNames({
			'community-profile__value--disabled': !this.state.communityProfile.website
		});
		const addressClasses = classNames({
			'community-profile__value--disabled': !this.state.communityProfile.address.address
		});
		const zipCodeCityClasses = classNames({
			'padding--bottom': true,
			'community-profile__value--disabled': !(
				this.state.communityProfile.address.postalCode && this.state.communityProfile.address.city
			)
		});

		return (
			<div className="community-profile__contact padding border--top border--dark">
				{this.state.communityProfile.address.address || this.state.canEdit ? (
					<div className={addressClasses}>{address}</div>
				) : null}
				{(this.state.communityProfile.address.postalCode &&
					this.state.communityProfile.address.city) ||
				this.state.canEdit ? (
					<div className={zipCodeCityClasses}>{zipCodeCity}</div>
				) : null}

				{this.state.communityProfile.email || this.state.canEdit ? (
					<a className={emailClasses} href={`mailto:${email}`} target="_blank" rel="noreferrer">
						{email}
					</a>
				) : null}
				{this.state.communityProfile.phone || this.state.canEdit ? (
					<a className={phoneClasses} href={`tel:${phone}`} target="_blank" rel="noreferrer">
						{phone}
					</a>
				) : null}
				{this.state.communityProfile.website || this.state.canEdit ? (
					<a
						className={websiteClasses}
						href={stripProtocolFromUrl(website, true)}
						target="_blank"
						rel="noreferrer">
						{stripProtocolFromUrl(website)}
					</a>
				) : null}

				{this.state.canEdit ? (
					<button
						type="button"
						className="button--tertiary icon icon--left icon--edit-black"
						onClick={() => this.onEdit()}>
						<FormattedMessage id="label.edit" />
					</button>
				) : null}
			</div>
		);
	}

	/**
	 * Get JSX for social links
	 *
	 * @returns {*}
	 * @private
	 */
	_getSocial() {
		// Hide social block alltogether when no links, and not editing
		if (!this.state.canEdit && !this.state.communityProfile.hasSocialLinks()) {
			return null;
		}

		return (
			<div className="community-profile__social padding border--top border--dark">
				{this.state.communityProfile.facebookUrl || this.state.canEdit ? (
					<SocialLink
						type={SocialLink.FACEBOOK}
						url={this.state.communityProfile.facebookUrl}
						inactive={!this.state.communityProfile.facebookUrl}
					/>
				) : null}
				{this.state.communityProfile.twitterUrl || this.state.canEdit ? (
					<SocialLink
						type={SocialLink.TWITTER}
						url={this.state.communityProfile.twitterUrl}
						inactive={!this.state.communityProfile.twitterUrl}
					/>
				) : null}
				{this.state.communityProfile.instagramUrl || this.state.canEdit ? (
					<SocialLink
						type={SocialLink.INSTAGRAM}
						url={this.state.communityProfile.instagramUrl}
						inactive={!this.state.communityProfile.instagramUrl}
					/>
				) : null}
				{this.state.communityProfile.linkedinUrl || this.state.canEdit ? (
					<SocialLink
						type={SocialLink.LINKEDIN}
						url={this.state.communityProfile.linkedinUrl}
						inactive={!this.state.communityProfile.linkedinUrl}
					/>
				) : null}

				{this.state.canEdit ? (
					<button
						type="button"
						className="button--tertiary icon icon--left icon--edit-black"
						onClick={() => this.onEdit(CommunityProfileInput.SOCIAL)}>
						<FormattedMessage id="label.edit" />
					</button>
				) : null}
			</div>
		);
	}

	/**
	 * Get JSX for photos section
	 *
	 * @returns {*}
	 * @private
	 */
	_getPhotos() {
		// Create images from photos
		const company = this.state.canEdit ? this.company : null;
		const photos = this.state.communityProfile.photos
			.filter((photoObject) => {
				return !!photoObject;
			})
			// eslint-disable-next-line array-callback-return, consistent-return
			.map((photoObject, index) => {
				if (photoObject) {
					const url = CommunityProfileModel.buildAsset(company, photoObject.assetUuid, null, 460);
					return (
						// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
						<img
							src={url}
							data-index={index + 1}
							key={`pimg-${index}`}
							alt=""
							onClick={this.onImageClick}
							onMouseOver={this.state.canEdit ? this.onImageOver : null}
							onMouseOut={this.state.canEdit ? this.onImageOut : null}
						/>
					);
				}
			});

		// No photos set, use placeholders
		if (this.state.canEdit && photos.length <= 0) {
			const widths = [240, 760, 920, 320, 1024];
			for (let i = 0; i < 5; i++) {
				const url = CommunityProfileModel.buildAsset(company, null, widths[i], 460);
				photos.push(
					// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
					<img
						className="community-profile__value--disabled"
						src={url}
						data-index={i + 1}
						alt=""
						onClick={this.onImageClick}
						onMouseOver={this.onImageOver}
						onMouseOut={this.onImageOut}
					/>
				);
			}
		}

		return <div className="community-profile__photos">{photos}</div>;
	}

	/**
	 *
	 * @returns {any[]}
	 * @private
	 */
	_getRelated() {
		return this.state.communityProfile.relatedProfiles.map((profile, index) => {
			return index < MAX_RELATED ? (
				<CommunityProfileBlock
					key={`cpr-${index}`}
					company={this.company}
					className="community-profile-block--flat"
					communityProfile={profile}
				/>
			) : null;
		});
	}

	/**
	 *
	 * @returns {Array}
	 * @private
	 */
	_getMissingFields() {
		const misingFields = [];

		if (!this.state.communityProfile.name) {
			misingFields.push('community.profile.missing.name');
		}
		if (!this.state.communityProfile.category) {
			misingFields.push('community.profile.missing.category');
		}
		if (!this.state.communityProfile.description) {
			misingFields.push('community.profile.missing.description');
		}
		if (!this.state.communityProfile.logoAssetUuid) {
			misingFields.push('community.profile.missing.logo');
		}
		if (!this.state.communityProfile.coverAssetUuid) {
			misingFields.push('community.profile.missing.cover');
		}

		return misingFields;
	}

	/**
	 *
	 * @private
	 */
	_updateEditIcon() {
		if (this.editElement) {
			const rect = this.editElement.getBoundingClientRect();
			TweenLite.set(this.editIcon, {
				x: rect.left + (rect.width - 40) * 0.5,
				y: rect.top + (rect.height - 40) * 0.5
			});
		}
	}
}

CommunityProfile.contextType = ApplicationContext;

CommunityProfile.propTypes = {
	intl: PropTypes.object,
	communityProfileStore: PropTypes.instanceOf(CommunityProfileStore)
};

export default injectIntl(CommunityProfile);
