/**
 *
 * @param row
 * @param date Date
 * @return {number}
 */
import BTW from '../data/BTW';

/**
 *
 * @param financialRow
 * @return {Number}
 */
export function getFinancialRowInclBTW(financialRow) {
	return Math.round(financialRow.amount * 100) / 100;
}

/**
 *
 * @param row
 * @param date
 * @return {number}
 */
export function getFinancialRowExclBTW(row, date) {
	const btwPercentage = BTW.getPercentage(row.btw, date);
	return row.amount / (1 + btwPercentage / 100);
}

/**
 *
 * @param financialRows
 * @return {number}
 */
export function getTotalAmountInclBTW(financialRows) {
	let amount = 0;
	financialRows.forEach((financialRow) => {
		amount += getFinancialRowInclBTW(financialRow);
	});

	return isNaN(amount) ? 0 : amount;
}

/**
 *
 * @param financialRows Array
 * @param date Date
 * @return {number}
 */
export function getTotalAmountExclBTW(financialRows, date) {
	let amountExclBTW = 0;
	financialRows.forEach((financialRow) => {
		amountExclBTW += getFinancialRowExclBTW(financialRow, date);
	});

	return isNaN(amountExclBTW) ? 0 : amountExclBTW;
}

/**
 *
 * @param financialRows
 * @return {Array}
 */
export function getBTWTypes(financialRows) {
	const types = [];
	let btwObject;
	financialRows.forEach((financialRow) => {
		const { btw } = financialRow;
		btwObject = BTW.getBTWObject(btw);
		if (types.indexOf(btwObject) < 0) {
			types.push(btwObject);
		}
	});

	return types;
}

/**
 *
 * @param financialRows Array (IncomeModel.invoiceRows or ExpenseModel.expenseRows, ...)
 * @param date Date
 * @return {Array}
 */
export function getBTWPercentages(financialRows, date) {
	const percentages = [];
	let percentage;
	financialRows.forEach((financialRow) => {
		const { btw } = financialRow;
		percentage = BTW.getPercentage(btw, date);
		if (percentages.indexOf(percentage) < 0) {
			percentages.push(percentage);
		}
	});

	return percentages;
}
