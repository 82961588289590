import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { VscGear } from '@react-icons/all-files/vsc/VscGear';
import PropTypes from 'prop-types';
import { autorun } from 'mobx';
import Page from '../../Page';
import useCompany from '../../../utils/useCompany';
import useFiscalYear from '../../../utils/useFiscalYear';
import useBankRecordStore from '../../../utils/useBankRecordStore';
import GetBankRecordsCommand from '../../../commands/bank/GetBankRecordsCommand';
import TransactionItem from './components/TransactionItem';
import { SkeletonTransaction } from './components/SkeletonTransaction';
import TransactionTableHeader from './components/TransactionTableHeader';
import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';
import FilterButton from '../../../components/ui/FilterButton/FilterButton';
import FilterButtonOption from '../../../components/ui/FilterButton/FilterButtonOption';
import SearchField from '../../../components/ui/SearchField/SearchField';
import useBankCategoryStore from '../../../utils/useBankCategoryStore';
import useBankAccountStore from '../../../utils/useBankAccountStore';
import InsightsAnimation from './components/InsightsAnimation';
import RefreshTransactions from './components/RefreshTransactions';
import Notification from './components/Notification';
import { BankRecordProcessedStatusType } from '../../../data/BankRecordType';
import useBankbookAnimationStore from '../../../utils/useBankbookAnimationStore';

function Transactions({ intl }) {
	const company = useCompany();
	const fiscalYear = useFiscalYear();
	const bankRecordStore = useBankRecordStore();
	const bankCategoryStore = useBankCategoryStore();
	const bankAccountStore = useBankAccountStore();
	const bankbookAnimationStore = useBankbookAnimationStore();

	const [statusFilter, setStatusFilter] = useState([]);

	useEffect(() => {
		let filterUpdateCancel;
		if (bankRecordStore && bankRecordStore.statusFilterRule) {
			filterUpdateCancel = autorun(() => {
				setStatusFilter([
					new FilterButtonOption(
						intl.formatMessage(
							{ id: 'tag-label.processed' },
							{
								count: bankRecordStore.processedCount
							}
						),
						BankRecordProcessedStatusType.PROCESSED,
						bankRecordStore.statusFilterRule.value.includes(BankRecordProcessedStatusType.PROCESSED)
					),
					new FilterButtonOption(
						intl.formatMessage(
							{ id: 'tag-label.not-processed' },
							{
								count: bankRecordStore.toBeProcessedCount
							}
						),
						BankRecordProcessedStatusType.NOT_PROCESSED,
						bankRecordStore.statusFilterRule.value.includes(
							BankRecordProcessedStatusType.NOT_PROCESSED
						)
					)
				]);
			});
		}
		return () => {
			if (filterUpdateCancel) {
				filterUpdateCancel();
			}
		};
	}, [bankRecordStore, intl]);

	useEffect(() => {
		Signals.IncomeTableUpdated.add(() => {
			bankRecordStore.resetStore();
			bankbookAnimationStore.reset();
		});
		Signals.ExpenseTableUpdated.add(() => {
			bankRecordStore.resetStore();
			bankbookAnimationStore.reset();
		});
	}, []);

	const onGetBankRecords = ({ company, fiscalYear, bankRecordStore }) => {
		const command = new GetBankRecordsCommand();
		bankRecordStore.search = '';
		bankRecordStore.setIsFetched(false);
		command.execute(company.id, fiscalYear.year, bankRecordStore).then(() => {
			bankRecordStore.setIsFetched(true);
		});
	};

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		bankCategoryStore && bankCategoryStore.fetchCategories();
	}, []);

	useEffect(() => {
		// Fetches bank records if not already fetched or if the fiscal year has changed
		if (company && fiscalYear) {
			if (bankRecordStore && !bankRecordStore.isFetched) {
				onGetBankRecords({
					company,
					fiscalYear,
					bankRecordStore
				});
			}
			if (bankAccountStore && !bankAccountStore.isFetched) {
				bankAccountStore.fetch(company.id);
			}
		}
	}, [company, fiscalYear, bankRecordStore]);

	useEffect(() => {
		const cancelAnimation = autorun(() => {
			if (
				(!bankAccountStore.isFetched || !bankRecordStore.isFetched) &&
				!bankbookAnimationStore.isAnimating
			) {
				bankbookAnimationStore.startAnimation();
			}
		});
		return () => {
			bankbookAnimationStore.stopAnimation();
			cancelAnimation();
		};
	}, [bankAccountStore, bankRecordStore, bankbookAnimationStore]);

	const onStatusFilterChange = (name, value, checked, ignoreUpdate) => {
		// Update filter button option state
		switch (name) {
			case 'statusFilter':
				statusFilter
					.filter((filterButtonOption) => filterButtonOption.value === value)
					.forEach((option) => (option.checked = checked));
				break;
			default:
				break;
		}

		// Apply filters
		bankRecordStore.statusFilterRule.value = statusFilter
			.filter((filterOption) => filterOption.checked)
			.map((option) => option.value);

		if (!ignoreUpdate) {
			bankRecordStore.update();
		}
	};

	const onRefresh = () => {
		bankRecordStore.resetFilter();
		bankbookAnimationStore.reset();
		bankbookAnimationStore.startAnimation();
		onGetBankRecords({
			company,
			fiscalYear,
			bankRecordStore
		});
	};

	const getNotification = (toBeProcessedCount) => {
		if (toBeProcessedCount > 0) {
			return (
				<Notification type="warning">
					{intl.formatMessage(
						{
							id: 'page.transactions.notification.warning'
						},
						{
							transactions: (
								<span
									key="count"
									className="underline mx-1 cursor-pointer"
									onClick={() => {
										onStatusFilterChange(
											'statusFilter',
											BankRecordProcessedStatusType.PROCESSED,
											false,
											false
										);
										bankbookAnimationStore.stopAnimationImmediately();
									}}>
									{intl.formatMessage(
										{ id: 'page.transactions.amount.transaction' },
										{
											count: bankRecordStore.toBeProcessedCount
										}
									)}
								</span>
							)
						}
					)}
				</Notification>
			);
		}
		return (
			<Notification type="success">
				<FormattedMessage id="page.transactions.description.no_transactions" />
			</Notification>
		);
	};

	const getFilterLabel = () => {
		const length = bankRecordStore.statusFilterRule.value.length;
		if (length === 0 || bankRecordStore.animating) {
			return intl.formatMessage({
				id: 'transactions.header.status'
			});
		}
		if (length === 1) {
			if (bankRecordStore.statusFilterRule.value[0]) {
				return intl.formatMessage(
					{
						id: 'tag-label.processed'
					},
					{
						count: bankRecordStore.processedCount
					}
				);
			}
			return intl.formatMessage(
				{
					id: 'tag-label.not-processed'
				},
				{
					count: bankRecordStore.toBeProcessedCount
				}
			);
		}
		return intl.formatMessage(
			{
				id: 'tag-label.all'
			},
			{
				count: bankRecordStore.list.length
			}
		);
	};

	return (
		<Observer>
			{() => {
				return (
					<Page pageName="transactions">
						<div className="flex flex-col space-y-3 justify-center items-center my-8">
							{bankbookAnimationStore.showInsights ? (
								<InsightsAnimation />
							) : (
								<RefreshTransactions
									onRefresh={onRefresh}
									disabled={!bankAccountStore.enabledBankAccounts.length}
									toBeProcessedTransactionCount={bankRecordStore.toBeProcessedCount}
									onSettingsPageRoute={() => {
										Signals.RequestRoute.dispatch(
											Routes.COMPANY_PONTO_SETTINGS.getPath({
												id: company.id,
												year: fiscalYear.id
											})
										);
									}}
								/>
							)}
						</div>
						<div className="flex flex-col w-full h-full bg-white rounded-t mx-auto">
							<div className="flex flew-row p-3 justify-between h-10">
								<div className="flex flex-row space-x-2">
									<SearchField
										name="search"
										onChange={(e) => {
											if (e && e.target) {
												bankRecordStore.search = e.target.value;
											} else {
												bankRecordStore.search = '';
											}
										}}
										placeholder={intl.formatMessage({
											id: 'transaction.search'
										})}
										value={bankRecordStore.search}
									/>

									<FilterButton
										name="statusFilter"
										className="filter-button--primary"
										label={getFilterLabel()}
										options={statusFilter}
										onChange={onStatusFilterChange}
									/>
								</div>
								<div className="flex flex-row space-x-2">
									<button
										type="button"
										className="border-none shadow-none"
										onClick={() =>
											Signals.RequestRoute.dispatch(
												Routes.COMPANY_PONTO_SETTINGS.getPath({
													id: company.id,
													year: fiscalYear.id
												})
											)
										}>
										<div className="flex flex-row justify-center space-x-2">
											<VscGear />
											<FormattedMessage id="submenu.offers.settings" />
										</div>
									</button>
								</div>
							</div>
							<TransactionTableHeader />
							<div className="flex flex-col bg-tableBackground">
								{bankbookAnimationStore.isAnimating ? (
									<SkeletonTransaction count={5} />
								) : // Render empty row if no bank records
								bankRecordStore.filteredAndSortedTransactions.length === 0 ? (
									<div className="flex flex-1 p-4 justify-center items-center text-gray-400">
										<FormattedMessage id="company.bankbook.transactions.noTransactions" />
									</div>
								) : (
									<>
										{bankbookAnimationStore.showInsights && (
											<div className="h-9">
												{getNotification(bankRecordStore.toBeProcessedCount)}
											</div>
										)}
										{bankRecordStore.filteredAndSortedTransactions.map((bankRecord) => {
											return <TransactionItem key={bankRecord.id} bankRecord={bankRecord} />;
										})}
									</>
								)}
							</div>
						</div>
					</Page>
				);
			}}
		</Observer>
	);
}

Transactions.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(Transactions);
