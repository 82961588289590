import { observable, action, computed } from 'mobx';
import UserMessage from './models/UserMessage';

import { compareDates, compareStrings } from '../utils/compareUtils';

/**
 *
 */
export default class UserMessagesStore {
	@observable userMessages = [];

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable filter = null;

	/**
	 *
	 */
	constructor() {
		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);
	}

	/**
	 *
	 * @param data
	 */
	@action parseData(data) {
		this.userMessages = [];

		if (data) {
			data.forEach((messageData) => {
				const userMessage = new UserMessage(messageData);
				this.userMessages.push(userMessage);
			});
		}
	}

	/**
	 *
	 * @param userMessage
	 */
	@action remove(userMessage) {
		for (let i = this.userMessages.length - 1; i >= 0; i--) {
			if (this.userMessages[i] === userMessage) {
				this.userMessages.splice(i, 1);
			}
		}
	}

	/**
	 *
	 */
	@action reset() {
		this.userMessages = [];
		this.sortBy = null;
		this.sortDirection = 1;
	}

	/**
	 * Returns unread messages of last three publications
	 * @return {*[]}
	 */
	@computed get latestRelevantMessages() {
		const latest = this.userMessages.sort((a, b) => {
			return compareDates(b.publicationDate, a.publicationDate);
		});
		const relevant = latest.slice(0, Math.min(latest.length, 3));

		return relevant.filter((message) => {
			return !message.readAt;
		});
	}

	/**
	 *
	 * @return {*[]}
	 */
	@computed get unreadMessages() {
		return this.userMessages
			.sort((a, b) => {
				return compareDates(b.publicationDate, a.publicationDate);
			})
			.filter((message) => {
				return !message.readAt;
			});
	}

	/**
	 * Filter and sorted Invoices
	 *
	 * @returns {Array.<UserMessage>}
	 */
	@computed get filteredAndSortedUserMessages() {
		return this.userMessages
			.filter((message) => {
				return this._filter(message);
			})
			.sort(this._sort);
	}

	/**
	 *
	 * @param userMessage
	 * @return {boolean}
	 * @private
	 */
	_filter(userMessage) {
		if (!userMessage || !this.filter) {
			return true;
		}

		const searchString = userMessage.toSearchString();
		return searchString.indexOf(this.filter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'publicationDate':
				result = compareDates(b.publicationDate, a.publicationDate);
				break;
			case 'type':
				result = compareStrings(a.type, b.type);
				break;
			case 'title':
				result = compareStrings(a.title, b.title);
				break;
			case 'subTitle':
				result = compareStrings(a.subTitle, b.subTitle);
				break;
			case 'body':
				result = compareStrings(a.body, b.body);
				break;
			default:
				result = compareDates(b.publicationDate, a.publicationDate);
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}
}
