import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (category = null, searchQuery = '*', pageIndex = 0, resultsPerPage = 50) => {
	const queries = [];

	/**
	 @RequestParam(value = "q", required = false) String query,
	 @RequestParam(value = "category", required = false) String category,
	 @RequestParam(value = "pageIndex", required = false) Integer pageIndexParam,
	 @RequestParam(value = "resultsPerPage", required = false) Integer resultsPerPageParam
	 */

	if (searchQuery) {
		queries.push(`q=${encodeURIComponent(searchQuery)}`);
	}
	if (category) {
		queries.push(`category=${encodeURIComponent(category)}`);
	}
	if (pageIndex) {
		queries.push(`pageIndex=${pageIndex}`);
	}
	if (resultsPerPage) {
		queries.push(`resultsPerPage=${resultsPerPage}`);
	}

	const query = queries.length > 0 ? `?${queries.join('&')}` : '';
	return fetch(Methods.GET, EndPoints.COMMUNITY_PROFILES + query, null, null, true);
};
