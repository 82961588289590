export default {
	/**
	 * Partner is a customer of Kdeb/Founders
	 */
	PARTNER_IS_CUSTOMER: 'PARTNER_IS_CUSTOMER',
	/**
	 * Partner is not customer, but wants Kdeb to service the declaration
	 */
	DECLARATION_VIA_KDB: 'DECLARATION_VIA_KDB',
	/**
	 * Partner is not customer, and will manage its own declaration
	 */
	DECLARATION_SELF_SERVICE: 'DECLARATION_SELF_SERVICE'
};
