/**
 * Created by hugo on 10/10/17.
 */
export default {
	GC_0100: { name: 'Investering', translationId: 'ledgercode.investering' }, // results only
	GC_0101: { name: 'Investering', translationId: 'ledgercode.investering' },

	GC_4100: { name: 'Personeelskosten', translationId: 'ledgercode.personnelcosts' },
	GC_4101: { name: 'Personeelskosten Overig', translationId: 'ledgercode.personnelcosts.other' },
	GC_4103: { name: 'Lonen en Salarissen', translationId: 'ledgercode.personnelcosts.salary' },

	GC_4200: { name: 'Huisvesting', translationId: 'ledgercode.huisvesting' }, // results only
	GC_4201: { name: 'Huisvesting', translationId: 'ledgercode.huisvesting' },

	GC_4400: { name: 'Verkoop', translationId: 'ledgercode.verkoop' }, // results only
	GC_4401: { name: 'Verkoop', translationId: 'ledgercode.verkoop' },

	GC_4500: { name: 'Kantoor', translationId: 'ledgercode.kantoor' }, // results only
	GC_4501: { name: 'Kantoor', translationId: 'ledgercode.kantoor' },

	GC_4600: { name: 'Consumptie', translationId: 'ledgercode.consumptie' }, // results only
	GC_4601: { name: 'Consumptie', translationId: 'ledgercode.consumptie' },
	GC_4602: { name: 'Overig', translationId: 'ledgercode.overig' },

	GC_7100: { name: 'Productie', translationId: 'ledgercode.productie' },
	GC_7101: { name: 'Materialen', translationId: 'ledgercode.materialen' },
	GC_7102: { name: 'Uitbesteed', translationId: 'ledgercode.uitbesteed' },

	GC_4300: { name: 'Reis en verblijf', translationId: 'ledgercode.reis.verblijf' },
	GC_4301: { name: 'Reis en verblijf overig', translationId: 'ledgercode.reis.verblijf.overig' },
	GC_4302: { name: 'Auto kosten', translationId: 'ledgercode.auto.kosten' },

	GC_XXXX: { name: 'Meerdere', translationId: 'ledgercode.multiple' },

	EMPTY: { name: 'Geen', translationId: 'ledgercode.none' },

	/**
	 *
	 * @param code
	 * @returns {*}
	 */
	getObjectByCode(code) {
		const value = this[code];

		if (!value) {
			return this.EMPTY;
		}

		return value;
	},

	/**
	 *
	 * @param ledgerCode
	 * @returns {*}
	 */
	getCodeByObject(ledgerCode) {
		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (this[key] === ledgerCode) {
					return key;
				}
			}
		}

		return null;
	},

	/**
	 *
	 * @param ledgerCode
	 * @returns {*}
	 */
	getAdjustedCode(ledgerCode) {
		switch (ledgerCode) {
			// Personnel
			case this.GC_4101:
			case this.GC_4103:
				ledgerCode = this.GC_4100;
				break;
			// Travel
			case this.GC_4301:
			case this.GC_4302:
				ledgerCode = this.GC_4300;
				break;
			// Production
			case this.GC_7101:
			case this.GC_7102:
				ledgerCode = this.GC_7100;
				break;
			default:
				break;
		}

		return ledgerCode;
	}
};
