import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import Signals from '../../../signals/Signals';

import CompanyCustomer from '../../../stores/models/CompanyCustomer';

import ClientInput from '../../../pages/Company/Clients/components/modals/ClientInput';

import { ApplicationContext } from '../../../ApplicationContext';

import DropDownIndicator from './DropDownIndicator';
import CompanyCustomerOption from './CompanyCustomerOption';

@observer
/**
 *
 */
export default class CompanyCustomerSearch extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onAddClient = this.onAddClient.bind(this);
		this.onEditClient = this.onEditClient.bind(this);
		this.onToggleChangeOnly = this.onToggleChangeOnly.bind(this);

		this.state = { showChangeOnly: null };
	}

	componentWillReceiveProps(nextProps) {
		console.log('CompanyCustomerSearch.componentWillReceiveProps', nextProps);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { changeOnly, value, initialCompanyCustomer } = this.props;
		const { showChangeOnly } = this.state;

		const showChange = showChangeOnly == null ? changeOnly : showChangeOnly;

		return (
			<div className={`company-customer-search ${this.props.className}`}>
				<div className="company-customer-search__input-wrapper">
					{showChange ? (
						<input
							type="text"
							value={initialCompanyCustomer ? initialCompanyCustomer.getLabel() : null}
							disabled
						/>
					) : (
						<Select
							className="company-customer-search__select"
							classNamePrefix="react-select"
							placeholder={this.context.intl.formatMessage({ id: 'clients.search' })}
							noOptionsMessage={() => {
								return this.context.intl.formatMessage({ id: 'clients.noresults' });
							}}
							components={{ DropdownIndicator: DropDownIndicator, Option: CompanyCustomerOption }}
							value={this._getOptionByValue(this.props.value)}
							isSearchable
							options={this._createOptions()}
							onChange={this.onChange}
						/>
					)}
				</div>

				<div className="grid col--12 grid--spread">
					{showChange ? (
						<div className="company-customer-search__add link" onClick={this.onToggleChangeOnly}>
							<FormattedMessage id="company.customer.search.change" />
						</div>
					) : null}

					{!showChange ? (
						<div className="company-customer-search__add link" onClick={this.onAddClient}>
							<FormattedMessage id="company.customer.add" />
						</div>
					) : null}

					{!showChange && value ? (
						<div className="company-customer-search__edit link" onClick={this.onEditClient}>
							<FormattedMessage id="company.customer.edit" />
						</div>
					) : null}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onToggleChangeOnly() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ showChangeOnly: !(this.state.showChangeOnly == null) }, () => {
			if (!this.state.showChangeOnly) {
				const list = this._getCompanyCustomers();
				if (list.length > 0) {
					this.onUpdate(list[0]);
				}
			}
		});
	}

	/**
	 *
	 */
	onAddClient() {
		Signals.ShowOverlay.dispatch(<ClientInput companyCustomer={new CompanyCustomer()} />);
	}

	/**
	 *
	 */
	onEditClient() {
		const { value } = this.props;
		if (value) {
			const { applicationStore } = this.context;
			const { companyCustomerStore } = applicationStore;
			const id = parseInt(`${value}`, 10);
			const companyCustomer = companyCustomerStore.find(id);
			Signals.ShowOverlay.dispatch(
				<ClientInput companyCustomer={companyCustomer} onUpdate={this.onUpdate} />
			);
		}
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onClick() {
		this.select.click();
	}

	/**
	 *
	 * @param option
	 */
	onChange(option) {
		if (this.props.onChange) {
			const companyCustomer = this.context.applicationStore.companyCustomerStore.find(
				parseInt(option.value, 10)
			);
			this.props.onChange(companyCustomer);
		}
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onUpdate(companyCustomer) {
		if (this.props.onChange) {
			this.props.onChange(companyCustomer);
		}
	}

	/**
	 *
	 * @param value
	 * @private
	 */
	_getOptionByValue(value) {
		return this._createOptions().find((searchItem) => {
			return searchItem.value === value;
		});
	}

	/**
	 *
	 * @return {Array|*}
	 * @private
	 */
	_getCompanyCustomers() {
		const { companyCustomerStore } = this.context.applicationStore;
		if (companyCustomerStore) {
			// Create list
			return companyCustomerStore.companyCustomers.concat([]);
		}

		return [];
	}

	/**
	 *
	 * @return {{label: (*|string), value: *}[]|Array}
	 * @private
	 */
	_createOptions() {
		const { companyCustomerStore } = this.context.applicationStore;
		if (companyCustomerStore) {
			return companyCustomerStore.companyCustomers.map((companyCustomer) => {
				return {
					value: companyCustomer.id,
					label: companyCustomer.getLabel(),
					customerName: companyCustomer.getCustomerName(),
					customerEmail: companyCustomer.contactPersonEmail,
					privatePerson: companyCustomer.privatePerson
				};
			});
		}

		return [];
	}
}

CompanyCustomerSearch.contextType = ApplicationContext;

CompanyCustomerSearch.propTypes = {
	initialCompanyCustomer: PropTypes.instanceOf(CompanyCustomer),
	// eslint-disable-next-line react/no-unused-prop-types
	name: PropTypes.string,
	value: PropTypes.number,
	className: PropTypes.string,
	onChange: PropTypes.func,
	// eslint-disable-next-line react/no-unused-prop-types
	placeholder: PropTypes.string,
	changeOnly: PropTypes.bool
};

CompanyCustomerSearch.defaultProps = {
	className: '',
	placeholder: 'Selecteer opdrachtgever'
};
