import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';
import communityCompanyProfileGet from '../requests/communityCompanyProfileGet';

/**
 *
 */
export default class GetCompanyCommunityProfileCommand extends AbstractCommand {
	/**
	 * @param company Company
	 */
	constructor(company) {
		super();

		this.company = company;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, _error) {
		console.log('GetCompanyCommunityProfileCommand.execute');

		return communityCompanyProfileGet(this.company.id)
			.then((response) => {
				this.company.communityProfile.update(response.data);
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				// Result can be 404 when a company is new and doesn't have any profile settings set,
				// this still needs to call the success callback
				if (err.error && err.error.statusCode === 404) {
					if (success) {
						success(null);
					}
				} else {
					Signals.Error.dispatch(err);
				}
			});
	}
}
