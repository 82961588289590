/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import { validateMimeType, validateSize, normalizeFileName } from '../../../utils/fileUtils';

import ModalAlert from '../Modal/ModalAlert';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
class FileUpload extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.uid = uniqueKey('fu-');
		this.fileUpload = null;
		this.urlCreator = window.URL || window.webkitURL;

		this.state = {
			focus: false,
			dragOver: false,
			fileName: this.props.fileName,
			file: null,
			filePreview: this.props.filePreview,
			disabled: this.props.disabled
		};

		this._openFileDialog = this._openFileDialog.bind(this);

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onFileDialogChange = this.onFileDialogChange.bind(this);
		this.onClear = this.onClear.bind(this);

		this.onPreviewLoaded = this.onPreviewLoaded.bind(this);
		this.onFileClick = this.onFileClick.bind(this);
	}

	/**
	 *
	 */
	componentWillReceiveProps(nextProps) {
		this.setState({
			disabled: nextProps.disabled
		});
	}

	/**
	 *
	 */
	componentWillUnmount() {
		this._clearFilePreview();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Determine classes for file-upload
		const showObject = this._showObjectPreview();
		const showImage = this._showImagePreview();

		const classes = classNames({
			'file-upload--disabled': this.state.disabled,
			'file-upload--focus': this.state.focus,
			'file-upload--has-file': this.state.fileName != null,
			'file-upload--has-image-preview': showImage,
			'file-upload--has-object-preview': showObject
		});

		let buttonClasses = classNames({
			'button icon icon--big-plus icon--color icon--left': true
		});

		buttonClasses += ` ${this.props.buttonClasses}`;

		return (
			<div
				className={`file-upload ${this.props.className ? this.props.className : ''} ${
					this.state.dragOver ? 'file-upload--drag-over' : ''
				} ${classes}`}
				onDrop={this.onDrop}
				onDragOver={this.onDragOver}
				onDragEnd={this.onDragEnd}
				onDragLeave={this.onDragEnd}>
				{/* when file is set, ignore required prop as the requirements have been met,
				    otherwise a not focusable error will occur */}
				<div className="file-upload__no-file col--12">
					<input
						id={this.uid}
						type="file"
						ref={(fileUpload) => (this.fileUpload = fileUpload)}
						name={this.props.name}
						multiple={this.props.multiple}
						onChange={this.onFileDialogChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						required={this.props.required && !this.state.file}
						accept={this.props.accept}
						disabled={this.state.disabled}
					/>

					<button
						type="button"
						name="file-upload__select-file"
						className={buttonClasses}
						onClick={this._openFileDialog}>
						<FormattedMessage id={this.props.buttonLabelId} />
					</button>

					{this.props.label ? <label htmlFor={this.uid}>{this.props.label}</label> : null}
				</div>

				<div className="file-upload__has-file col--12 grid grid--spread">
					<div className="file-upload__has-file-wrapper grid" onClick={this.onFileClick}>
						<div className="file-upload__check icon icon--check-black" />
						<label className="label--bold">{this.state.fileName}</label>
					</div>
					{!this.state.disabled ? (
						<div className="file-upload__remove icon icon--delete-black" onClick={this.onClear} />
					) : null}
				</div>

				{/* Show image block when image type */}
				{showImage ? (
					<div className="file-upload__image-preview col--12" onClick={this.onFileClick}>
						<img src={this.state.filePreview} alt="file preview" />
					</div>
				) : null}

				{/* Show object block when pdf */}
				{showObject ? (
					<div className="file-upload__object-preview col--12">
						<object
							data={this.state.filePreview}
							width="100%"
							height="100%"
							type={this.state.file && this.state.file.type}>
							<label>PDF preview not supported</label>
						</object>
					</div>
				) : null}
			</div>
		);
	}

	/**
	 *
	 */
	onPreviewLoaded() {
		//
		if (this.props.onPreviewLoaded) {
			this.props.onPreviewLoaded();
		}
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {
		this.setState({ focus: true });
	}

	/**
	 *
	 * @param e
	 */
	onBlur() {
		this.setState({ focus: false });
	}

	/**
	 *
	 * @param e
	 */
	onDrop(e) {
		if (!this.state.disabled) {
			e.preventDefault();
			e.stopPropagation();

			const dataTransfer = e.dataTransfer;
			let file = null;

			// Get file
			if (dataTransfer.items && dataTransfer.items.length > 0) {
				if (dataTransfer.items[0].kind === 'file') {
					// && dataTransfer.items[0].type !== ''
					file = dataTransfer.items[0].getAsFile();
				}
			}

			// Get file backup
			if (file === null && dataTransfer.files && dataTransfer.files.length > 0) {
				file = dataTransfer.files[0];
			}

			// File found and validated on extension
			if (this._validate(file)) {
				this.setState({
					file,
					fileName: normalizeFileName(file.name)
				});

				this.onChange(file);

				// No file or invalid file
			} else {
				this.onClear();
			}
		}

		// Reset drag over state
		this.setState({
			dragOver: false
		});
	}

	/**
	 *
	 * @param e
	 */
	onDragOver(e) {
		if (!this.state.disabled) {
			e.preventDefault();
			this.setState({ dragOver: true });
		}
	}

	/**
	 *
	 * @param e
	 */
	onDragEnd(e) {
		if (!this.state.disabled) {
			e.preventDefault();
			this.setState({ dragOver: false });
		}
	}

	/**
	 *
	 * @param e
	 */
	onFileDialogChange(e) {
		const file = e.target.files[0];

		if (file && this._validate(file)) {
			this.setState({
				file,
				fileName: normalizeFileName(file.name)
			});

			this.onChange(file);
		}
	}

	/**
	 *
	 * @param e
	 */
	onClear() {
		console.log('FileUpload.onClear');

		this.fileUpload.value = '';
		this._clearFilePreview();
		this.setState({ file: null, fileName: null });
		this.onChange(null);
	}

	/**
	 *
	 * @param file
	 */
	onChange(file) {
		console.log('FileUpload.onChange', file);

		// Always clear preview
		this._clearFilePreview();

		// Show preview if it is an object we can show
		if (file) {
			if (this._showObjectPreview(true) || this._showImagePreview(true)) {
				this._createFilePreview(file);
			}
		}

		//
		if (this.props.onChange) {
			this.props.onChange(file, file ? file.name : null, this.props.name);
		}
	}

	/**
	 * Open file in new tab if the preview is a url
	 */
	onFileClick() {
		if (this._previewIsUrl()) {
			window.open(this.state.filePreview);
		}
	}

	/**
	 *
	 * @private
	 */
	_createFilePreview(file) {
		// Always clear file preview before (clear memory)
		this._clearFilePreview();

		// Create file preview from File
		if (this.props.showFilePreview) {
			const newBlobUrl = this.urlCreator.createObjectURL(file);

			this.setState({ filePreview: newBlobUrl });
			this.onPreviewLoaded();
		}
	}

	/**
	 *
	 * @private
	 */
	_clearFilePreview() {
		if (this.state.filePreview) {
			this.urlCreator.revokeObjectURL(this.state.filePreview);
			this.setState({ filePreview: null });
		}
	}

	/**
	 * Check whether the filePreview is a url
	 *
	 * @returns {boolean}
	 * @private
	 */
	_previewIsUrl() {
		if (this.state.filePreview && !(this.state.filePreview instanceof File)) {
			return this.state.filePreview.indexOf('http') >= 0;
		}

		return false;
	}

	/**
	 * Force open the file dialog
	 *
	 * @private
	 */
	_openFileDialog() {
		this.fileUpload.click();
	}

	/**
	 * Determines whether to show file preview based on extension in filename and state and props values
	 *
	 * @returns {Boolean|String|*}
	 * @private
	 */
	_showImagePreview(force = false) {
		let show =
			force || (this.props.showFilePreview && this.state.fileName && this.state.filePreview);
		if (show && this.state.fileName) {
			const fileNameSegments = this.state.fileName.split('.');
			const extension = fileNameSegments[fileNameSegments.length - 1].toLowerCase();

			switch (extension) {
				case 'gif':
				case 'png':
				case 'jpg':
				case 'jpeg':
					break;
				default:
					show = false;
			}
		}

		return show;
	}

	/**
	 *
	 * @returns {boolean}
	 *
	 * @private
	 */
	_showObjectPreview(force = false) {
		let show =
			force || (this.props.showFilePreview && !!this.state.fileName && !!this.state.filePreview);
		if (show && this.state.fileName) {
			const fileNameSegments = this.state.fileName.split('.');
			const extension = fileNameSegments[fileNameSegments.length - 1].toLowerCase();
			show = extension === 'pdf';
		}

		return show;
	}

	/**
	 *
	 * @param file
	 * @return {boolean}
	 * @private
	 */
	_validate(file) {
		const values = {
			size: `${Math.floor(this.props.maxSize / (1024 * 1024))}MB`,
			types: this.props.accept.split(',').join(', ')
		};
		const validMimeType = file && validateMimeType(file.type, this.props.accept);
		if (!validMimeType) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'alert.fileupload.invalid.type.title' })}
					body={this.props.intl.formatMessage(
						{ id: 'alert.fileupload.invalid.type.description' },
						values
					)}
				/>
			);
			return false;
		}

		const validSize = validateSize(file.size, this.props.maxSize);
		if (!validSize) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'alert.fileupload.invalid.size.title' })}
					body={this.props.intl.formatMessage(
						{ id: 'alert.fileupload.invalid.size.description' },
						values
					)}
				/>
			);
			return false;
		}

		return true;
	}
}

FileUpload.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	fileName: PropTypes.string,
	filePreview: PropTypes.string,
	showFilePreview: PropTypes.bool,
	accept: PropTypes.string,
	maxSize: PropTypes.number,
	multiple: PropTypes.bool,
	onPreviewLoaded: PropTypes.func,
	onChange: PropTypes.func,
	buttonLabelId: PropTypes.string,
	buttonClasses: PropTypes.string
};

FileUpload.defaultProps = {
	label: null,
	maxSize: 1024 * 1024 * 20, // 20Mb see FileUtil.java
	accept: '.gif,.png,.jpg,.jpeg,.pdf,.txt,.csv',
	multiple: false,
	showFilePreview: true,
	required: false,
	buttonLabelId: 'fileupload.select.file',
	buttonClasses: ''
};

export default injectIntl(FileUpload);
