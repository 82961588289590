import BankRecordMatchType from '../../data/BankRecordMatchType';
import bankRecordMatchPut from '../../requests/bank/bankRecordMatchPut';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class MatchBankRecordsCommand {
	/**
	 *
	 */
	constructor(company, fiscalYear, bankRecordMatchStore, bankCategoryStore) {
		this.company = company;
		this.fiscalYear = fiscalYear;
		this.bankRecordMatchStore = bankRecordMatchStore;
		this.bankCategoryStore = bankCategoryStore;
	}

	/**
	 *
	 * @param companyId
	 * @param bankRecordId
	 * @param data
	 * @return {Promise<any | never>}
	 */
	execute(onSuccess, onError) {
		// Check if the bank record is unmatched and the category is hidden.
		// If so, then we need to set category to null because it is automatically assigned by backend.
		const bankRecord = this.bankRecordMatchStore.bankRecord;
		if (this.bankRecordMatchStore.bankRecordInEditMatchStatus === BankRecordMatchType.UNMATCHED) {
			// Bank Record is not matched.
			if (this.bankRecordMatchStore.bankRecord.categoryId) {
				// Bank Record has a category.
				const category = this.bankCategoryStore.getCategoryById(
					this.bankRecordMatchStore.bankRecord.categoryId
				);
				if (category.hidden) {
					// Category is hidden.
					bankRecord.categoryId = null;
				}
			}
		}

		return bankRecordMatchPut(
			this.bankRecordMatchStore.bankRecord.id,
			this.company.id,
			this.fiscalYear.year,
			bankRecord
		)
			.then((res) => {
				this.bankRecordMatchStore.save(res);
				// eslint-disable-next-line no-unused-expressions
				onSuccess && onSuccess();
				this.bankRecordMatchStore.reset();
			})
			.catch((error) => {
				// eslint-disable-next-line no-unused-expressions
				onError && onError();
				Signals.Error.dispatch(error);
			});
	}
}
