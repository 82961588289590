import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CommunityProfileBlock from './CommunityProfileBlock';
import { ApplicationContext } from '../../../ApplicationContext';

const MAX_VISIBLE = 2;

@observer
/**
 *
 */
export default class CommunityFeed extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.company = this.context.applicationStore.getSelectedCompany();
		this.props.communityProfileStore.fetch();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		//
		if (!this.context.applicationStore.isLoggedIn) {
			return null;
		}

		const profiles = this.props.communityProfileStore.communityProfiles.map((profile, index) => {
			return index < MAX_VISIBLE ? (
				<CommunityProfileBlock
					key={`cpb-${index}`}
					className="col--6 community-profile-block--flat"
					company={this.company}
					communityProfile={profile}
				/>
			) : null;
		});

		return <div className="community-feed grid border--top border--dark">{profiles}</div>;
	}
}

CommunityFeed.contextType = ApplicationContext;

CommunityFeed.propTypes = {
	communityProfileStore: PropTypes.object.isRequired
};
