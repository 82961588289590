/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../signals/Signals';

import DocumentTypes from '../../data/DocumentTypes';

import FileUpload from '../../components/ui/FileUpload/FileUpload';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';

import userDocumentCreate from '../../requests/userDocumentCreate';

/**
 *
 */
class OnBoardingUserPassport extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			file: undefined,
			fileName: undefined,
			errors: null,
			submitting: false
		};

		this.onSkip = this.onSkip.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);

		this.onFileChange = this.onFileChange.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const formClasses = classNames({ 'form--submitting': this.state.submitting });

		return (
			<div className={`panel border border--dark ${this.props.className}`}>
				<form
					className={`on-boarding__form ${formClasses}`}
					name="on-boarding"
					onSubmit={this.onSubmit}>
					<div className="on-boarding__header padding border--bottom border--dark">
						<h2>
							<FormattedMessage id="onboarding.user.passport.title" />
						</h2>
					</div>

					<div className="padding-small">
						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="onboarding.field.user.passport" />
								</label>
								<FileUpload
									className="col--12"
									name="file"
									filePreview={undefined}
									fileName={this.state.fileName}
									label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
									showFilePreview={false}
									onChange={this.onFileChange}
								/>
							</FormField>
						</FormGroup>
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<button
							type="button"
							className="on-boarding__skip-button button--tertiary"
							onClick={this.onSkip}>
							{this.props.intl.formatMessage({ id: 'onboarding.form.skip' })}
						</button>
						<input
							className="on-boarding__submit-button"
							type="submit"
							value={this.props.intl.formatMessage({ id: 'onboarding.form.submit' })}
						/>
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 */
	onFileChange(file, fileName) {
		this.setState({ file, fileName });
	}

	/**
	 *
	 */
	onSkip() {
		this.props.successCallback();
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		if (this.state.submitting || !this.validate()) {
			return;
		}

		const formData = new FormData();
		formData.append('file', this.state.file);
		formData.append('type', DocumentTypes.PASPOORT.name);
		formData.append('year', new Date().getFullYear());

		this.setState({ errors: null, submitting: true });
		userDocumentCreate(formData).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		this.setState({ submitting: false });
		this.props.user.passportFile = this.state.file;
		this.props.successCallback(this);
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		this.setState({ submitting: false });
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	validate() {
		if (!this.state.file) {
			const errorMessage = this.props.intl.formatMessage({ id: 'onboarding.error.nopassportfile' });
			this.setState({ errors: [['file', errorMessage]] });
			return false;
		}

		return true;
	}
}

OnBoardingUserPassport.propTypes = {
	intl: PropTypes.object,
	successCallback: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	className: PropTypes.string
};

export default injectIntl(OnBoardingUserPassport);
