import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

/** @type {intlShape} */

class Support extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { intl } = this.props;
		return (
			<div className="support">
				<h2>
					<FormattedMessage id="support.label" />
				</h2>
				<a
					className="link link--primary"
					href={`mailto:${intl.formatMessage({ id: 'support.email' })}`}>
					<FormattedMessage id="support.email" />
				</a>
			</div>
		);
	}
}

Support.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Support);
