import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

import Signals from '../../signals/Signals';
import AbstractCommand from '../AbstractCommand';
import expenseDelete from '../../requests/expenseDelete';

/**
 *
 */
export default class DeleteExpenseCommand extends AbstractCommand {

	/**
	 *
	 * @param expenseModel
	 * @param expenseStore
	 */
	constructor(expenseModel, expenseStore) {
		super();

		this.expenseModel = expenseModel;
		this.expenseStore = expenseStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		expenseDelete(this.expenseModel).then(
			() => {
				this.expenseStore.removeExpense(this.expenseModel.id);
				Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="expense.removed.message"
																		  values={{expenseId: this.expenseModel.expenseNr}}/>));
			}
		).catch((error) => {
			Signals.Error.dispatch(error);
		});
	}
}
