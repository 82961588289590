/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';

/**
 *
 */
@observer
class WalkthroughStartScreen extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onStart = this.onStart.bind(this);
		this.onClose = this.onClose.bind(this);

		this.state = { initialized: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ initialized: true });
		}, 300);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { walkthroughStore } = this.props;
		const { initialized } = this.state;

		const classes = classNames({
			'walkthrough-start-screen': true,
			'walkthrough-start-screen--showing':
				initialized && walkthroughStore.playing && walkthroughStore.current === 0
		});

		return (
			<div className={classes}>
				<div className="walkthrough-start-screen__panel">
					<div className="walkthrough-start-screen__panel-content">
						<h1>
							<FormattedMessage id="walkthrough.start.title" />
						</h1>
						<div className="walkthrough-start-screen__animation">
							<img
								alt="walkthrough start"
								src={`${window.config.imageRoot}walkthrough/walkthrough-start-image.gif`}
								width={385}
								height={251}
							/>
							<div className="rotated-label">
								<label>
									<FormattedMessage id="walkthrough.start.label" />
								</label>
							</div>
						</div>

						<p className="walkthrough-start-screen__description">
							<FormattedMessage id="walkthrough.start.description" />
						</p>

						<div className="walkthrough-start-screen__controls">
							<button
								type="button"
								className="button button--no-shadow button--no-border"
								onClick={this.onClose}>
								<FormattedMessage id="walkthrough.start.closebutton" />
							</button>
							<button
								type="button"
								className="button--primary icon icon--left icon--color icon--play2"
								onClick={this.onStart}>
								<FormattedMessage id="walkthrough.start.startbutton" />
							</button>
						</div>
					</div>
					<div
						className="walkthrough-start-screen__close icon icon--left icon--close cursor-pointer"
						onClick={this.onClose}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onClose() {
		const { walkthroughStore } = this.props;
		walkthroughStore.current = 1;
		walkthroughStore.pause();
	}

	/**
	 *
	 */
	onStart() {
		const { walkthroughStore } = this.props;
		walkthroughStore.next();
	}
}

WalkthroughStartScreen.contextType = ApplicationContext;

WalkthroughStartScreen.propTypes = {
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore),
	// eslint-disable-next-line react/no-unused-prop-types
	showing: PropTypes.bool
};

WalkthroughStartScreen.defaultProps = {};

export default injectIntl(WalkthroughStartScreen);
