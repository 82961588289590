import { vatRegexBuilderForEuropeanCountries } from './EuropeanCountries';

export const JANUARY_1_2019 = new Date(2019, 0, 0, 0, 0, 0);

/**
 * Created by hugo on 10/10/17.
 */
export default {
	HOOG: {
		percentage() {
			return 21;
		},
		name: 'HOOG',
		translationId() {
			return 'btw.hoog';
		}
	},
	LAAG: {
		name: 'LAAG',
		percentage(referenceDate = new Date()) {
			return referenceDate.getFullYear() >= 2019 ? 9 : 6;
		},
		translationId(referenceDate = new Date()) {
			return referenceDate.getFullYear() >= 2019 ? 'btw.laag2' : 'btw.laag';
		}
	},
	NUL: {
		percentage() {
			return 0;
		},
		name: 'NUL',
		translationId() {
			return 'btw.nul';
		}
	},
	VRIJGESTELD: {
		percentage() {
			return 0;
		},
		name: 'VRIJGESTELD',
		translationId() {
			return 'btw.vrijgesteld';
		}
	},
	BUITENLAND: {
		percentage() {
			return 0;
		},
		name: 'BUITENLAND',
		translationId() {
			return 'btw.buitenland';
		}
	},
	BINNENLAND: {
		percentage() {
			return 0;
		},
		name: 'BINNENLAND',
		translationId() {
			return 'btw.binnenland';
		}
	},
	EU: {
		percentage() {
			return 0;
		},
		name: 'EU',
		translationId() {
			return 'btw.eu';
		},
		isValid(btwString) {
			// Regular expression for the european union countries.
			const regex = vatRegexBuilderForEuropeanCountries();
			return regex.test(btwString);
		}
	},
	BUITEN_EU: {
		percentage() {
			return 0;
		},
		name: 'BUITEN_EU',
		translationId() {
			return 'btw.buiten.eu';
		}
	},
	MMOS: {
		percentage() {
			return 0;
		},
		name: 'MMOS',
		translationId() {
			return 'btw.mmos';
		}
	},
	OSS: {
		percentage() {
			return 0;
		},
		name: 'OSS',
		translationId() {
			return 'btw.oss';
		}
	},
	VERLEGD: {
		percentage() {
			return 0;
		},
		name: 'VERLEGD',
		translationId() {
			return 'btw.verlegd';
		}
	},
	GEEN: {
		percentage() {
			return 0;
		},
		name: 'GEEN',
		translationId() {
			return 'btw.geen';
		}
	},
	MIXED: {
		percentage() {
			return 0;
		},
		name: 'MIXED',
		translationId() {
			return 'btw.mixed';
		}
	},

	/**
	 *
	 * @param btwType
	 * @param referenceDate Date
	 * @return {*}
	 */
	getPercentage(btwType, referenceDate) {
		if (!btwType) {
			return this.HOOG.percentage();
		}

		for (const key in this) {
			if (this.hasOwnProperty(key) && key.toUpperCase() === btwType.toUpperCase()) {
				return this[key].percentage(referenceDate);
			}
		}
	},

	/**
	 *
	 * @param btwType
	 * @returns {*}
	 */
	getBTWObject(btwType) {
		if (!btwType) {
			return this.HOOG;
		}

		for (const key in this) {
			if (this.hasOwnProperty(key) && key.toUpperCase() === btwType.toUpperCase()) {
				return this[key];
			}
		}
	},

	/**
	 *
	 * @param amount Number
	 * @param btwType String
	 * @param referenceDate Date
	 * @return {number}
	 */
	getAmountExclBTW(amount, btwType, referenceDate) {
		const p = this.getPercentage(btwType, referenceDate);
		return amount / (1 + p / 100);
	},

	/**
	 *
	 * @param amount Number
	 * @param btwType String
	 * @param referenceDate Date
	 * @return {number}
	 */
	getAmountFromAmountExclBTW(exclBTWAmount, btwType, referenceDate) {
		const p = this.getPercentage(btwType, referenceDate);
		return exclBTWAmount * (1 + p / 100);
	},

	/**
	 *
	 * @param amount Number
	 * @param btwType String
	 * @param referenceDate Date
	 * @return {number}
	 */
	getAmountExclBTWFromAmountBTW(amountBTW, btwType, referenceDate) {
		const p = this.getPercentage(btwType, referenceDate);
		return (amountBTW / (p / 100)) * 100;
	},

	/**
	 *
	 * @param amount Number
	 * @param btwType String
	 * @param referenceDate Date
	 * @return {number}
	 */
	getAmountBTW(amount, btwType, referenceDate) {
		return amount - this.getAmountExclBTW(amount, btwType, referenceDate);
	},

	/**
	 *
	 * @param value
	 * @returns {*}
	 */
	getAdjustedBTWType(value) {
		switch (value) {
			case this.VERLEGD.name:
			case this.BUITENLAND.name:
				value = this.EU.name;
				break;
			default:
				break;
		}

		return value;
	}
};
