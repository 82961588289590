import Signals from '../../signals/Signals';
import bankAccountUpdate from '../../requests/bank/bankAccountUpdate';

export default class ToggleBankAccountCommand {
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.companyId = null;
		this.bankAccount = null;
	}

	execute(companyId, bankAccount) {
		this.companyId = companyId;
		this.bankAccount = bankAccount;
		return bankAccountUpdate(companyId, bankAccount.id, {
			enabled: !bankAccount.enabled
		})
			.then(this.then)
			.catch(this.catch);
	}

	then(response) {
		return response;
	}

	catch(error) {
		Signals.Error.dispatch(error);
	}
}
