import { computed, observable } from 'mobx';
import moment from 'moment';

import { _formatDate, _formatStringToDate } from '../../utils/objectToFormData';

import BTW from '../../data/BTW';
import Locale from '../../data/Locale';
import OfferStatus from '../../data/OfferStatus';

import CompanyCustomer from './CompanyCustomer';
import OfferRowModel from './OfferRowModel';
import Company from './Company';
import SignOfferModel from './SignOfferModel';
import CompanyBrandModel from './CompanyBrandModel';

/**
 *
 */
export default class OfferModel {
	@observable statusDateChanged = null;

	@observable status = null;

	/**
	 *
	 */
	constructor() {
		this.id = null;
		this.companyCustomer = null;
		this.companyBrand = null;
		this.offerNr = null;
		this.projectcode = null;
		this.date = new Date();
		this.expirationDays = null;
		this.expirationDate = null;
		this.recipientEmail = null;
		this.senderEmail = null;
		this.notes = null;
		this.subject = null;
		this.emailMessage = null;
		this.emailSubject = null;
		this.logoAssetUuid = null;
		this.ccRecipientEmail = null;
		this.bccRecipientEmail = null;
		this.locale = null;
		this.publicAccessId = null;

		this.assetAttachments = [];
		this.history = [];

		this.reminderDate = null;
		this.remindCompanyUserInDays = null;
		this.remindCompanyCustomerInDays = null;

		this.company = null;

		this.offerRows = [];
		this.signData = null; // SignOfferModel
	}

	/**
	 *
	 * @return {number|null}
	 */
	@computed get bookyear() {
		return this.date ? this.date.getFullYear() : null;
	}

	/**
	 * Can this Offer be removed
	 *
	 * @returns {boolean}
	 */
	canDelete() {
		return !this.status || this.status === OfferStatus.CREATED;
	}

	/**
	 * Can this Offer be edited, can't edit if already signed, otherwise
	 * can change up until Sent/Late/Reminder statusses
	 *
	 * @returns {boolean}
	 */
	canEdit() {
		return (
			!this.signData &&
			(!this.status ||
				this.status === OfferStatus.CREATED ||
				this.status === OfferStatus.SENT ||
				this.status === OfferStatus.SENT_MANUALLY ||
				this.status === OfferStatus.SENT_REMINDER ||
				this.status === OfferStatus.LATE)
		);
	}

	/**
	 *
	 */
	canChangeStatus() {
		// TODO: crawl history?
		return !(!this.status || this.status === OfferStatus.CREATED);
	}

	/**
	 * Can this Offer be send to a client?
	 *
	 * @returns {boolean}
	 */
	canSend() {
		return (
			!this.status ||
			this.status === OfferStatus.CREATED ||
			this.status === OfferStatus.SENT ||
			this.status === OfferStatus.SENT_MANUALLY
		);
	}

	/**
	 * Can this Offer be send to a client?
	 *
	 * @returns {boolean}
	 */
	canSendReminder() {
		return !this.status || this.status === OfferStatus.LATE;
	}

	/**
	 * Can this Offer be removed?
	 * @returns {boolean}
	 */
	canRemove() {
		return !!this.status;
	}

	/**
	 *
	 */
	canInvoice() {
		return !this.status || this.status === OfferStatus.SIGNED;
	}

	/**
	 * Is this Offer still open?
	 *
	 * * @returns {boolean}
	 */
	isOpen() {
		return !(
			this.status === OfferStatus.SIGNED ||
			this.status === OfferStatus.DECLINED ||
			this.status === OfferStatus.DELETED
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	canArchive() {
		return (
			this.status === OfferStatus.SIGNED ||
			this.status === OfferStatus.DECLINED ||
			this.status === OfferStatus.INVOICED
		);
	}

	/**
	 *
	 */
	canSign() {
		return (
			this.status !== OfferStatus.SIGNED &&
			this.status !== OfferStatus.DECLINED &&
			this.status !== OfferStatus.CREATED &&
			this.status !== OfferStatus.INVOICED
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isSigned() {
		return !!this.signData;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isPublic() {
		return !!this.publicAccessId;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isArchived() {
		return this.status === OfferStatus.ARCHIVED;
	}

	/**
	 * Does this Offer have rows where amount of units are not default? (default = 1)
	 */
	hasDeviatingUnits() {
		let deviating = false;
		this.offerRows.forEach((row) => {
			if (row.units !== 1) {
				deviating = true;
			}
		});

		return deviating;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasLanguage() {
		return this.locale !== null;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasExpirationDate() {
		return !!this.expirationDate;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isEnglish() {
		return this.locale === Locale.en_US;
	}

	/**
	 * Replaces linebreaks like \r and \n with <br>
	 *
	 * @return {String}
	 */
	getNotesAsHTML() {
		return this.notes ? this.notes.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
	}

	/**
	 *
	 */
	hasNotes() {
		return this.notes && this.notes.trim().length > 0;
	}

	/**
	 *
	 * @return {String|null}
	 */
	getLastSentStatus() {
		const sendStates = [
			OfferStatus.SENT_MANUALLY,
			OfferStatus.SENT,
			OfferStatus.SENT_REMINDER,
			OfferStatus.LATE
		];

		const lastStatus = this.history.find((entry) => {
			return sendStates.indexOf(entry.offerStatus) >= 0;
		});

		if (lastStatus && lastStatus.offerStatus) {
			return lastStatus.offerStatus;
		}

		return null;
	}

	/**
	 *
	 */
	getSubtotal() {
		if (this.getInclVat()) {
			return this.getTotal() - this.getBTWTotal(BTW.HOOG.name) - this.getBTWTotal(BTW.LAAG.name);
		}
		let subTotal = 0;
		this.offerRows.forEach((row) => {
			subTotal += row.getSubtotal(this.date);
		});

		return subTotal;
	}

	/**
	 *
	 * @param btwType
	 *
	 * @returns {number}
	 */
	getBTWTotal(btwType) {
		let btwTotal = 0;

		this.offerRows.forEach((row) => {
			if (btwType === row.btw) {
				btwTotal += row.getVATTotal(this.date);
			}
		});

		if (isNaN(btwTotal)) {
			btwTotal = 0;
		}

		//
		return Math.round(btwTotal * 100) / 100;
	}

	/**
	 *
	 * @returns {*}
	 */
	getTotal() {
		let total = 0;
		if (this.getInclVat()) {
			this.offerRows.forEach((row) => {
				total += row.amount;
			});

			return total;
		}
		this.offerRows.forEach((row) => {
			total += row.amount;
		});

		return total + this.getBTWTotal(BTW.HOOG.name) + this.getBTWTotal(BTW.LAAG.name);
	}

	/**
	 *
	 * @param expirationDate
	 */
	setExpirationDaysFromDate(expirationDate) {
		const dateA = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
		const dateB = new Date(
			expirationDate.getFullYear(),
			expirationDate.getMonth(),
			expirationDate.getDate()
		);

		const a = moment(dateA);
		const b = moment(dateB);
		this.expirationDays = b.diff(a, 'days', false);
	}

	/**
	 *
	 * @return {Date}
	 */
	getExpirationDateFromDays() {
		if (this.expirationDays) {
			const date = new Date(this.date);
			date.setDate(date.getDate() + parseInt(this.expirationDays, 10));
			return date;
		}

		return null;
	}

	/**
	 * Returns whether this Offer contains any VAT of type {type}
	 *
	 * @param type The BTW type
	 * @param exclusive If this BTW type can be the only one found
	 * @returns {boolean}
	 */
	containsVATType(type, exclusive = false) {
		let typeFound = false;
		let othersFound = false;

		//
		this.offerRows.forEach((row) => {
			if (type.name === row.btw) {
				typeFound = true;
			} else {
				othersFound = true;
			}
		});

		//
		if (exclusive && othersFound) {
			return false;
		}

		return typeFound;
	}

	/**
	 * @return {Number|undefined}
	 */
	getCompanyCustomerId() {
		const id = parseInt(
			// eslint-disable-next-line no-nested-ternary
			this.companyCustomerId
				? this.companyCustomerId
				: this.companyCustomer
				? this.companyCustomer.id
				: undefined,
			10
		);
		return !isNaN(id) ? id : undefined;
	}

	/**
	 * @return {Number|undefined}
	 */
	getCompanyBrandId() {
		const id = this.companyBrand ? this.companyBrand.id : undefined;
		return !isNaN(id) ? id : undefined;
	}

	/**
	 *
	 * @return {OfferModel}
	 */
	clone(copyDate = false, ignoreUniqueFields = true) {
		const clone = new OfferModel();

		for (const key in this) {
			if (this.hasOwnProperty(key) && key !== 'offerRows' && key !== 'history') {
				switch (key) {
					case 'date':
						clone.date = this.date && copyDate ? new Date(this.date.getTime()) : new Date();

						break;
					default:
						clone[key] = this[key];
				}
			}
		}

		// Reset all unique fields
		if (ignoreUniqueFields) {
			clone.id = null;
			clone.offerNr = null;
			clone.status = null;
			clone.statusDateChanged = null;
			clone.history = [];

			clone.signData = null;
			clone.publicAccessId = null;

			clone.recipientEmail = null;
			clone.senderEmail = null;
			clone.emailMessage = null;
			clone.emailSubject = null;
			clone.ccRecipientEmail = null;
			clone.bccRecipientEmail = null;
		}

		//
		this.offerRows.forEach((row) => {
			clone.offerRows.push(row.clone());
		});

		// Reset offerRow id's
		if (ignoreUniqueFields) {
			clone.offerRows.forEach((row) => {
				row.id = null;
			});
		}

		return clone;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		if (!data) {
			return;
		}

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const company = new Company();
				switch (key) {
					case 'expirationDays':
						this[key] = parseInt(data[key], 10);
						break;
					case 'expirationDate':
						this[key] = _formatStringToDate(data[key], true, false, true);
						break;
					case 'date':
					case 'reminderDate':
						this[key] = _formatStringToDate(data[key], true);
						break;
					case 'statusDateChanged':
						this[key] = _formatStringToDate(data[key], true, true);
						break;
					case 'company':
						company.update(data[key]);
						this[key] = company;
						break;
					case 'signData':
						if (data[key]) {
							const signOfferModel = new SignOfferModel();
							signOfferModel.fromData(data[key]);
							this[key] = signOfferModel;
						} else {
							data[key] = null;
						}
						break;
					case 'offerRows':
						// do nothing
						break;
					case 'companyCustomer':
						this[key] = new CompanyCustomer(data[key]);
						break;
					case 'companyBrand':
						this[key] = new CompanyBrandModel(data[key]);
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	/**
	 *
	 * @return {OfferRowModel}
	 */
	addNewOfferRow() {
		const row = new OfferRowModel();
		this.offerRows.push(row);

		return row;
	}

	/**
	 *
	 * @param inclVat
	 */
	setInclVat(inclVat) {
		this.offerRows.forEach((row) => {
			row.inclVat = inclVat;
		});
	}

	/**
	 *
	 */
	getInclVat() {
		let inclVat = null;
		this.offerRows.forEach((row) => {
			// Get first found value
			if (inclVat == null) {
				inclVat = row.inclVat;

				// Update row to match first found value
			} else {
				row.inclVat = inclVat;
			}

			row.update(this.date);
		});

		return inclVat;
	}

	/**
	 *
	 * @param uid
	 */
	getConceptRowByUid(uid) {
		return this.offerRows.find((row) => {
			return row._uid === uid;
		});
	}

	/**
	 *
	 * @param offerRowModel
	 */
	removeConceptRow(offerRowModel) {
		let i = 0;
		const l = this.offerRows.length;
		for (; i < l; i++) {
			if (this.offerRows[i] === offerRowModel) {
				this.offerRows.splice(i, 1);
			}
		}
	}

	/**
	 *
	 * @return {string}
	 */
	toSearchString() {
		let searchString = '';
		searchString += this.offerNr ? `${this.offerNr} ` : '';
		searchString += this.companyCustomer ? `${this.companyCustomer.toSearchString()} ` : '';
		searchString += this.subject ? `${this.subject} ` : '';
		searchString = searchString.toLowerCase();

		return searchString;
	}

	/**
	 *
	 * @param defaults
	 * @param ignoreFields
	 * @return {string}
	 */
	toJSON(defaults = {}, ignoreFields = { status: true }) {
		const data = {};

		// Apply companyCustomer id to companyCustomerId if empty
		if (this.companyCustomer && this.companyCustomerId === null) {
			this.companyCustomerId = this.companyCustomer.id;
		}

		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (typeof this[key] !== 'function' && !(ignoreFields && ignoreFields[key])) {
					let value = this[key];

					// Try to set defaults when value is not set
					if (value === undefined || value === null) {
						value = defaults[key] !== null && defaults[key] !== undefined ? defaults[key] : null;
					}

					// Force format of specific types and objects (like `Date`)
					data[key] = this._formatJSONData(value);
				}
			}
		}

		data.offerRows = this.offerRows;

		return JSON.stringify(data);
	}

	/**
	 *
	 * @param value
	 * @return {*}
	 * @private
	 */
	_formatJSONData(value) {
		if (value instanceof Date) {
			return _formatDate(value);
		}
		return value;
	}

	/**
	 *
	 * @param data
	 * @returns {OfferModel}
	 */
	static parseOfferData(data) {
		const model = new OfferModel();
		OfferModel.updateOfferModel(data, model);
		return model;
	}

	/**
	 *
	 * @param data
	 * @param offerModel
	 */
	static updateOfferModel(data, offerModel) {
		offerModel.update(data);
		offerModel.offerRows = [];

		if (!data.offerRows) {
			data.offerRows = [];
		}

		data.offerRows.forEach((offerRow) => {
			offerModel.offerRows.push(new OfferRowModel(offerRow, offerModel.date));
		});
	}
}
