import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, data) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		`${EndPoints.COMPANIES_PONTO_AUTH}?companyId=${companyId}`,
		data,
		headers,
		true
	);
};
