import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
// eslint-disable-next-line no-unused-vars
import BankbookStatusStore from '../stores/BankbookStatusStore';

/**
 * This hook returns the ponto integration store.
 * @return {BankbookStatusStore}
 */
const useBankbookStatusStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { bankbookStatusStore } = applicationStore;

	return bankbookStatusStore;
};

export default useBankbookStatusStore;
