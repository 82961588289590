import React from 'react';
import { render } from 'react-dom';
import { fetchConfig } from '@/utils/fetchConfiguration';
import propertiesController from '@/controllers/PropertiesController';

// Context dependent translations
import translationsCommon from './lang/translations.common.json';
import translationsContext from './lang/translations.founders.json';

// Styles
import './styles/app-founders.scss';

// Start javascript/react
import Application from './app/Application';

fetchConfig('founders')
	.then((config) => {
		window.config = config;
		propertiesController.setConfig(config);

		// Disable production console.log
		if (!window.config.debug) {
			console.log = () => {};
		}

		Object.assign(translationsCommon[0], translationsContext[0]); // Override translation with context specific translations
		window.translations = translationsCommon;

		// Context dependent variables
		window.COLORS = {
			RESULTS: {
				PRIMARY: '#e8e5f8',
				PRIMARY_LINE: '#1F01B9',
				SECONDARY: '#fbc8bf',
				SECONDARY_LINE: '#F4482C'
			},
			LIGHT_GREY: '#f7f9fb'
		};
	})
	.catch((error) => {
		console.log(error);
	})
	.finally(() => {
		// eslint-disable-next-line react/jsx-filename-extension
		render(<Application />, document.getElementById('root'));
	});
