import { observable, action } from 'mobx';
import Document from './models/Document';

export const DOCUMENTS_OWNER = { USER: 'user', COMPANY: 'company' };

/**
 *
 */
export default class DocumentStore {
	@observable documents = [];

	/** \
	 *
	 * @param data
	 * @param owner
	 */
	@action update(data, _owner) {
		this.documents = [];

		if (data.list) {
			data.list.forEach((documentData) => {
				const document = new Document(documentData);
				this.documents.push(document);
			});
		}
	}

	/**
	 *
	 */
	addFromData(data) {
		const document = new Document(data);
		this.add(document);
	}

	/**
	 *
	 * @param document
	 */
	@action add(document) {
		this.documents.push(document);
	}

	/**
	 *
	 * @param document
	 */
	@action remove(document) {
		for (let i = this.documents.length - 1; i >= 0; i--) {
			if (this.documents[i] === document) {
				this.documents.splice(i, 1);
			}
		}
	}

	/**
	 *
	 * @param year
	 * @param type
	 * @return {*[]}
	 */
	getDocumentsByYearByType(year, type = null) {
		const documentsArray = this.documents.slice();
		return documentsArray.filter((document) => {
			return `${document.year}` === `${year}` && (type ? document.type === type.name : true);
		});
	}
}
