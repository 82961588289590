export default class RouteObject {
	/**
	 *
	 * @param path
	 * @param requiresAuth
	 * @param ignoreDeeplink Ignore this route when deeplinked?
	 * @param isPublic is this a public accessible link?
	 * @param urlSearchParams URL search params
	 * @param isRestricted is this a restricted route
	 * @param {{value: string, type: CompanyProperty}} feature is this route restricted to a specific feature
	 */
	constructor(
		path,
		requiresAuth = true,
		ignoreDeeplink = false,
		isPublic = false,
		urlSearchParams = null,
		isRestricted = false,
		feature = null
	) {
		this.pathName = (process.env.BASE_URL ? process.env.BASE_URL : '') + path;
		this.requiresAuth = requiresAuth;
		this.ignoreDeeplink = ignoreDeeplink;
		this.isPublic = isPublic;
		this.urlSearchParams = urlSearchParams;
		this.isRestricted = isRestricted;
		this.feature = feature;
	}

	/**
	 * Returns pathName with variables replaced by key/value pair in params
	 *
	 * @param params
	 * @returns {String}
	 */
	getPath(params = {}) {
		const keys = Object.keys(params);
		let path = this.pathName;

		keys.forEach((key) => {
			path = path.replace(`:${key}`, params[key]);
		});

		if (this.urlSearchParams) {
			path += this.urlSearchParams.toString();
		}

		return path;
	}

	/**
	 * Returns true if specified route is part of this RouteObject
	 * @param routeObject
	 */
	containsRoute(routeObject) {
		if (!routeObject || !routeObject.pathName) {
			return false;
		}

		const rs = routeObject.pathName.split('/');
		const ps = this.pathName.split('/');

		if (rs.length > ps.length) {
			return false;
		}

		let i = 0;
		let result = false;
		while (i < rs.length) {
			if (rs[i].indexOf(':') < 0) {
				if (!(rs[i] === ps[i])) {
					return false;
				}
				result = true;
			}

			i++;
		}

		return result;
	}

	/**
	 * Get object with replaced :values
	 *
	 * @param location The current location of the browser
	 */
	getVariables(location) {
		const ls = location.split('/');
		const ps = this.pathName.split('/');
		let i = 0;

		const result = {};

		while (i < ls.length && i < ps.length) {
			if (ps[i].indexOf(':') >= 0) {
				result[ps[i].replace(':', '')] = ls[i];
			}
			i++;
		}

		return result;
	}

	toString() {
		return `${this.pathName}, ${this.requiresAuth}`;
	}
}
