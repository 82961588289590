import AbstractCommand from './AbstractCommand';
import documentsCompany from '../requests/documentsCompany';
import documentsUser from '../requests/documentsUser';

/**
 *
 */
export default class FetchDocumentsCommand extends AbstractCommand {
	/**
	 * @param documentStore DocumentStore
	 * @param company Company
	 */
	constructor(documentStore, company = null) {
		console.log('FetchDocumentsCommand.constructor', company);

		super();

		this.company = company;
		this.documentStore = documentStore;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('FetchDocumentsCommand.execute');

		const request = this.company ? documentsCompany : documentsUser;
		request(this.company ? this.company.id : null)
			.then((response) => {
				this._updateDocuments(response);
				success(response);
			})
			.catch(error);
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_updateDocuments(response) {
		console.log('FetchDocumentsCommand._updateDocuments', response);

		this.documentStore.update(response);
	}
}
