/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedNumber } from 'react-intl';
import TextArea from 'react-textarea-autosize';

import { _formatValue, _parseCurrency } from '../../../../utils/objectToFormData';

import BTW from '../../../../data/BTW';

import InvoiceConceptRowModel from '../../../../stores/models/InvoiceConceptRowModel';
import InvoiceConceptModel from '../../../../stores/models/InvoiceConceptModel';

import NumberInput from '../../../../components/ui/NumberInput/NumberInput';
import DropDown from '../../../../components/ui/DropDown/DropDown';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class InvoiceConceptRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onRemove = this.onRemove.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onDragStartBlock = (_e) => {
			this.blockDrag = true;
		};
		this.onDragStartRelease = (_e) => {
			this.blockDrag = false;
		};
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'invoice-concept-row border border--bottom': true,
			'invoice-concept-row--dragging': this.state.dragging
		});

		const { invoiceConceptModel, invoiceConceptRowModel, intl } = this.props;

		return (
			<tr
				className={classes}
				draggable
				onDragStart={this.onDragStart}
				onDragEnd={this.onDragEnd}
				data-uid={invoiceConceptRowModel._uid}>
				<td className="invoice-concept-row__column invoice-concept-row__dragicon">
					<div className="icon icon--left icon--drag-smoke" />
				</td>
				<td
					className="invoice-concept-row__column invoice-concept-row__description"
					onMouseDown={this.onDragStartBlock}
					onMouseUp={this.onDragStartRelease}
					onMouseLeave={this.onDragStartRelease}>
					<TextArea
						maxLength={4096}
						name="description"
						onChange={this.onInputChange}
						required
						value={invoiceConceptRowModel.description}
					/>
				</td>
				<td
					className="invoice-concept-row__column invoice-concept-row__units"
					onMouseDown={this.onDragStartBlock}
					onMouseUp={this.onDragStartRelease}
					onMouseLeave={this.onDragStartRelease}>
					<NumberInput
						name="units"
						value={_formatValue(invoiceConceptRowModel.units, true)}
						showIcon={false}
						onChange={this.onInputChange}
					/>
				</td>
				<td
					className="invoice-concept-row__column invoice-concept-row__unitprice"
					onMouseDown={this.onDragStartBlock}
					onMouseUp={this.onDragStartRelease}
					onMouseLeave={this.onDragStartRelease}>
					<NumberInput
						name="unitPrice"
						value={_formatValue(invoiceConceptRowModel.unitPrice, true)}
						onChange={this.onInputChange}
					/>
				</td>
				<td className="invoice-concept-row__column invoice-concept-row__btw">
					<DropDown
						name="btw"
						className="col--12"
						value={invoiceConceptRowModel.btw}
						onChange={this.onInputChange}>
						<option value={BTW.HOOG.name}>
							{intl.formatMessage({ id: BTW.HOOG.translationId() })}
						</option>
						<option value={BTW.LAAG.name}>
							{intl.formatMessage({ id: BTW.LAAG.translationId(invoiceConceptModel.date) })}
						</option>
						<option value={BTW.VERLEGD.name}>
							{intl.formatMessage({ id: BTW.VERLEGD.translationId() })}
						</option>
						<option value={BTW.VRIJGESTELD.name}>
							{intl.formatMessage({ id: BTW.VRIJGESTELD.translationId() })}
						</option>
						<option value={BTW.GEEN.name}>
							{intl.formatMessage({ id: BTW.GEEN.translationId() })}
						</option>
					</DropDown>
				</td>
				<td className="invoice-concept-row__column invoice-concept-row__amount">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={invoiceConceptRowModel.amount}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td className="invoice-concept-row__column table-row__column">
					<div className="table-row__actions icon icon--delete-black" onClick={this.onRemove} />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'units':
			case 'unitPrice':
				this.props.invoiceConceptRowModel[e.target.name] = _parseCurrency(e.target.value);
				break;
			default:
				this.props.invoiceConceptRowModel[e.target.name] = e.target.value;
		}

		this.props.invoiceConceptRowModel.update(this.props.invoiceConceptModel.date);
		this.onChange();
	}

	/**
	 *
	 * @param event
	 */
	onDragStart(event) {
		if (this.blockDrag) {
			event.preventDefault();
			event.stopImmediatePropagation();
			return;
		}

		console.log('InvoiceConceptRow.onDragStart', event.target, event.currentTarget);
		this.draggingTimeout = setTimeout(() => {
			this.setState({ dragging: true });
		}, 50);

		this.props.onDragStart(this.props.invoiceConceptRowModel, event);
	}

	/**
	 *
	 * @param event
	 */
	onDragEnd(_event) {
		clearTimeout(this.draggingTimeout);
		this.setState({ dragging: false });
		this.props.onDragEnd(this.props.invoiceConceptRowModel);
	}

	/**
	 *
	 */
	onRemove() {
		if (this.props.onRemove) {
			this.props.onRemove(this.props.invoiceConceptRowModel);
		}
	}

	/**
	 *
	 */
	onChange() {
		if (this.props.onChange) {
			this.props.onChange(this.props.invoiceConceptRowModel);
		}
	}
}

InvoiceConceptRow.contextType = ApplicationContext;

InvoiceConceptRow.propTypes = {
	invoiceConceptRowModel: PropTypes.instanceOf(InvoiceConceptRowModel),
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel).isRequired,
	intl: PropTypes.object,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
	onDragStart: PropTypes.func.isRequired,
	onDragEnd: PropTypes.func.isRequired
};

export default injectIntl(InvoiceConceptRow);
