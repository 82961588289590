/**
 *
 * @param errors
 * @param fieldName
 * @return {undefined}
 */
import Signals from '../signals/Signals';

export function extractErrorMessage(errors, fieldName) {
	if (!errors) return undefined;
	if (!errors.forEach) return undefined;

	let message;
	errors.forEach((error) => {
		if (error && error.length > 1) {
			const errorFieldSegments = error[0].split('.');
			errorFieldSegments.forEach((segmentName) => {
				if (segmentName === fieldName) {
					if (message) {
						message += `<br>${error[1]}`;
					} else {
						// eslint-disable-next-line prefer-destructuring
						message = error[1];
					}
				}
			});
		}
	});

	return message;
}

/**
 *
 * @param errors
 * @param fieldName
 * @return {boolean}
 */
export function hasError(errors, fieldName) {
	if (!errors) return false;
	if (!errors.forEach) return false;

	let isError = false;
	errors.forEach((error) => {
		if (error && error.length > 0 && error[0] === fieldName) {
			isError = true;
		}
	});

	return isError;
}

/**
 *
 * @param state
 * @param intl
 * @param suppress
 */
export function notifyUncaughtErrors(state, intl, suppress) {
	const { errors } = state;
	const keys = errors ? Object.keys(errors) : [];
	const suppressKeys = suppress || [];

	let message = '';
	keys.map((key) => {
		if (!suppressKeys.includes(key)) {
			message += message.length > 0 ? ', ' : '';
			const msg = extractErrorMessage(errors, key, intl);
			message += msg ? `${key}: ${msg}` : '';
		}
		return key;
	});

	if (message.length > 0 && keys.length > 0) {
		Signals.ShowMessageDialog.dispatch(message, 'danger');
	}

	state.errors = [];
}
