/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import CompanyAssetType from '../../../../../data/CompanyAssetType';

import Signals from '../../../../../signals/Signals';
import ModalConfirm from '../../../../../components/ui/Modal/ModalConfirm';
import FileUpload from '../../../../../components/ui/FileUpload/FileUpload';

import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import DropDown from '../../../../../components/ui/DropDown/DropDown';

import companyAssetCreate from '../../../../../requests/companyAssetCreate';
import { buildCompanyAssetURL } from '../../../../../requests/companyAssetGet';

import UpdateCommunityProfileCommand from '../../../../../commands/UpdateCommunityProfileCommand';
import { ApplicationContext } from '../../../../../ApplicationContext';

@observer
class CommunityProfileInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: null,
			files: {},
			submitting: false,
			communityProfile: null
		};

		this.close = this.close.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onRouteChanged = this.onRouteChanged.bind(this);
		this.onCategoriesFetched = this.onCategoriesFetched.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);
		this.onAssetError = this.onAssetError.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.company = this.context.applicationStore.getSelectedCompany();
		this.state.communityProfile = this.company.communityProfile.clone();

		// Set defaults
		if (!this.state.communityProfile.name || this.state.communityProfile.name.trim().length <= 0) {
			this.state.communityProfile.name = this.company.name;
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		// Focus field with short delay, so all elements are mounted and rendered
		setTimeout(() => {
			if (this.el && this.props.fieldToFocus) {
				const field = this.el.querySelector(`[name="${this.props.fieldToFocus}"]`);
				field.focus();
				const cursorPosition = field.value ? field.value.length : 0;

				try {
					field.setSelectionRange(cursorPosition, cursorPosition);
				} catch (e) {
					// Ignore
				}
			}
		}, 100);

		Signals.KeyDown.add(this.onKeyDown);
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.RouteChanged.add(this.onRouteChanged);

		// Make sure categories are retrieved
		Signals.CommunityCategoriesFetched.addOnce(this.onCategoriesFetched);
		this.context.applicationStore.communityCategoriesStore.fetch();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		if (
			!this.context.applicationStore.communityCategoriesStore.fetched ||
			!this.state.communityProfile
		) {
			return null;
		}

		const formClasses = classNames({ 'form--submitting': this.state.submitting });
		return (
			<div
				ref={(ref) => {
					this.el = ref;
				}}
				className="community-profile-input border--bottom border--dark grid grid--spread">
				<form className={formClasses} onSubmit={this.onSubmit}>
					<div className="community-input__header padding border--bottom border--dark grid grid--spread">
						<h2 className="pad-left">
							<FormattedMessage id="community.input.edit.title" />
						</h2>
						<div
							className="community-input__close icon icon icon--close-black"
							onClick={this.onCancel}
						/>
					</div>

					<div className="community-input__content padding-small">
						{this.props.type === CommunityProfileInput.DEFAULT ? this._getDefault() : null}
						{this.props.type === CommunityProfileInput.PHOTOS ? this._getCover() : null}
						{this.props.type === CommunityProfileInput.PROFILE ? this._getProfile() : null}
						{this.props.type === CommunityProfileInput.PHOTOS ? this._getPhotos() : null}
						{this.props.type === CommunityProfileInput.SOCIAL ? this._getSocial() : null}
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<button className="button--tertiary" onClick={this.onCancel} type="button">
							<FormattedMessage id="label.cancel" />
						</button>
						<input type="submit" value={this.props.intl.formatMessage({ id: 'label.save' })} />
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 */
	onCategoriesFetched() {
		// Set default category if necessary
		// this.state.communityProfile.category = this.state.communityProfile.category ?
		// 	this.state.communityProfile.category :
		// 	this.context.applicationStore.communityCategoriesStore.categories[0].id;

		// Try to render
		this.forceUpdate();
	}

	/**
	 *
	 * @param event
	 */
	onInputChange(event) {
		switch (event.target.name) {
			default:
				this.state.communityProfile[event.target.name] = event.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param type
	 * @param file
	 * @param fileName
	 * @param index
	 */
	onFileChange(type, file, fileName, index = 0) {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', fileName);
			formData.append('type', type);

			// Store files and fileName to show image while uploading to server
			switch (type) {
				case CompanyAssetType.COMPANY_PROFILE_LOGO:
					this.state.files[CompanyAssetType.COMPANY_PROFILE_LOGO] = { file, fileName };
					break;
				case CompanyAssetType.COMPANY_PROFILE_COVER:
					this.state.files[CompanyAssetType.COMPANY_PROFILE_COVER] = { file, fileName };
					break;
				case CompanyAssetType.COMPANY_PROFILE_PHOTO:
					if (!this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO]) {
						this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO] = [];
					}
					this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO][index] = { file, fileName };
					break;
				default:
					break;
			}

			// Create assets (upload to server and retrieve UUID)
			companyAssetCreate(this.company.id, formData)
				.then((response) => this.onAssetCreated(response, index))
				.catch(this.onAssetError);

			// Reset files
		} else {
			switch (type) {
				case CompanyAssetType.COMPANY_PROFILE_LOGO:
					this.state.communityProfile.logoAssetUuid = null;
					this.state.files[CompanyAssetType.COMPANY_PROFILE_LOGO] = { file: null, fileName: null };
					break;
				case CompanyAssetType.COMPANY_PROFILE_COVER:
					this.state.communityProfile.coverAssetUuid = null;
					this.state.files[CompanyAssetType.COMPANY_PROFILE_COVER] = { file: null, fileName: null };
					break;
				case CompanyAssetType.COMPANY_PROFILE_PHOTO:
					if (!this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO]) {
						this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO] = [];
					}
					this.state.communityProfile.photos[index] = null;
					this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO][index] = {
						file: null,
						fileName: null
					};
					break;
				default:
					break;
			}
		}
	}

	/**
	 *
	 * @param response Object
	 * @param index Number
	 */
	onAssetCreated(response, index = 0) {
		if (response.data) {
			switch (response.data.type) {
				case CompanyAssetType.COMPANY_PROFILE_LOGO:
					this.state.communityProfile.logoAssetUuid = response.data.uuid;
					break;
				case CompanyAssetType.COMPANY_PROFILE_COVER:
					this.state.communityProfile.coverAssetUuid = response.data.uuid;
					break;
				case CompanyAssetType.COMPANY_PROFILE_PHOTO:
					console.log(
						this.state.communityProfile.photos,
						this.state.communityProfile.photos.length
					);
					this.state.communityProfile.photos[index] = { assetUuid: response.data.uuid };
					console.log(this.state.communityProfile.photos[index]);
					break;
				default:
					break;
			}
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param event
	 */
	onAssetError(event) {
		console.log(event);
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 27:
				this.onCancel();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param event
	 */
	onSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		if (!this.state.submitting) {
			this.setState({ errors: null, submitting: true });

			const updateCommunityProfileCommand = new UpdateCommunityProfileCommand(
				this.company,
				this.state.communityProfile,
				this.context.applicationStore.communityCategoriesStore
			);

			updateCommunityProfileCommand.execute(this.onSubmitSuccess, this.onSubmitError);
		}
	}

	/**
	 *
	 */
	onSubmitSuccess() {
		// Notify user of update
		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="community.edited.message"
				values={{ companyName: this.company.name }}
			/>
		);

		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();

		// Call update function if specified
		if (this.props.onUpdate) {
			this.props.onUpdate();
		}

		// Clear community profile store so it retrieves the update profile (just in case it appears in the results)
		this.context.applicationStore.communityProfileStore.reset();

		//
		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param event
	 */
	onCancel(event) {
		if (event) {
			event.preventDefault();
		}

		if (this.hasChanged()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'community.input.discard.alert' })}
					yesLabel={this.props.intl.formatMessage({ id: 'label.yes.discard' })}
					noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
					// eslint-disable-next-line react/jsx-no-bind
					onConfirm={this.close.bind(this)}
				/>
			);
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @returns {boolean}
	 */
	hasChanged() {
		// TODO: changed rules
		return false;
	}

	/** *
	 *
	 * @returns {*}
	 * @private
	 */
	_getDefault() {
		const logoPreview = this._getImagePreview(CompanyAssetType.COMPANY_PROFILE_LOGO);

		return (
			<FormGroup errors={this.state.errors}>
				<FormField className="col--6">
					<label>
						<FormattedMessage id="community.input.edit.logo" />
					</label>
					<FileUpload
						name="logo"
						showFilePreview
						accept=".png,.jpg,.jpeg"
						fileName={logoPreview ? 'logo.png' : null}
						filePreview={logoPreview}
						onChange={(file, fileName) => {
							this.onFileChange(CompanyAssetType.COMPANY_PROFILE_LOGO, file, fileName);
						}}
					/>
				</FormField>

				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.name" />
					</label>
					<input
						name="name"
						type="text"
						maxLength="128"
						required
						value={this.state.communityProfile.name ? this.state.communityProfile.name : undefined}
						onChange={this.onInputChange}
					/>
				</FormField>

				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.category" />
					</label>
					{this._getCategoryDropDown()}
				</FormField>
			</FormGroup>
		);
	}

	_getCover() {
		const coverPreview = this._getImagePreview(CompanyAssetType.COMPANY_PROFILE_COVER);
		return (
			<FormGroup errors={this.state.errors}>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.cover" />
					</label>
					<FileUpload
						name="cover"
						className="col--12"
						showFilePreview
						accept=".png,.jpg,.jpeg"
						fileName={coverPreview ? 'cover.png' : null}
						filePreview={coverPreview}
						onChange={(file, fileName) => {
							this.onFileChange(CompanyAssetType.COMPANY_PROFILE_COVER, file, fileName);
						}}
					/>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getProfile() {
		return (
			<FormGroup errors={this.state.errors}>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.description" />
					</label>
					<textarea
						name="description"
						maxLength={1024}
						required
						placeholder={this.props.intl.formatMessage({
							id: 'community.input.edit.description.placeholder'
						})}
						value={
							this.state.communityProfile.description
								? this.state.communityProfile.description
								: undefined
						}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getSocial() {
		return (
			<FormGroup errors={this.state.errors}>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.facebook" />
					</label>
					<input
						name="facebookUrl"
						type="text"
						maxLength={256}
						value={
							this.state.communityProfile.facebookUrl
								? this.state.communityProfile.facebookUrl
								: undefined
						}
						placeholder="https://www.facebook.com/keesdeboekhouder.nl/"
						onChange={this.onInputChange}
					/>
				</FormField>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.twitter" />
					</label>
					<input
						name="twitterUrl"
						type="text"
						maxLength={256}
						placeholder="https://twitter.com/keesdeboekhouder"
						value={
							this.state.communityProfile.twitterUrl
								? this.state.communityProfile.twitterUrl
								: undefined
						}
						onChange={this.onInputChange}
					/>
				</FormField>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.instagram" />
					</label>
					<input
						name="instagramUrl"
						type="text"
						maxLength={256}
						placeholder="https://www.instagram.com/keesdeboekhouder/"
						value={
							this.state.communityProfile.instagramUrl
								? this.state.communityProfile.instagramUrl
								: undefined
						}
						onChange={this.onInputChange}
					/>
				</FormField>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.linkedin" />
					</label>
					<input
						name="linkedinUrl"
						type="text"
						maxLength={256}
						placeholder="https://www.linkedin.com/company/keesdeboekhouder/"
						value={
							this.state.communityProfile.linkedinUrl
								? this.state.communityProfile.linkedinUrl
								: undefined
						}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getPhotos() {
		console.log('_getPhotos', this.state.communityProfile.photos);

		// Generate preview urls or files
		const photoUrls = this.state.communityProfile.photos.map((photo, index) => {
			return this._getImagePreview(CompanyAssetType.COMPANY_PROFILE_PHOTO, index);
		});

		const fileUploads = photoUrls.map((fileOrUrl, index) => {
			return (
				<FileUpload
					name={`photo-${index}`}
					className="col--12"
					showFilePreview
					accept=".png,.jpg,.jpeg"
					fileName={fileOrUrl ? 'photo.png' : null}
					filePreview={fileOrUrl}
					onChange={(file, fileName) => {
						this.onFileChange(CompanyAssetType.COMPANY_PROFILE_PHOTO, file, fileName, index);
					}}
				/>
			);
		});

		return (
			<FormGroup className="community-profile-input__photo-group" errors={this.state.errors}>
				<FormField className="col--12">
					<label>
						<FormattedMessage id="community.input.edit.photo" />
					</label>
					{fileUploads}
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getCategoryDropDown() {
		const user = this.context.applicationStore.user;
		const options = this.context.applicationStore.communityCategoriesStore.categories.map(
			(category, index) => {
				return (
					<option key={`comcat-${index}`} value={category.id}>
						{category.getName(user.language)}
					</option>
				);
			}
		);

		// Sort by label
		options.sort((a, b) => {
			return a.props.children.localeCompare(b.props.children);
		});

		options.unshift(
			<option key="comcat-empty" disabled selected>
				{this.props.intl.formatMessage({ id: 'community.category.placeholder' })}
			</option>
		);

		return (
			<DropDown
				name="category"
				className="col--12"
				required
				value={this.state.communityProfile.category ? this.state.communityProfile.category : null}
				onChange={this.onInputChange}>
				{options}
			</DropDown>
		);
	}

	/**
	 *
	 * @param type string
	 * @param index Number
	 * @returns {null|File|string}
	 * @private
	 */
	_getImagePreview(type, index = 0) {
		switch (type) {
			case CompanyAssetType.COMPANY_PROFILE_LOGO:
				if (this.state.communityProfile.logoAssetUuid) {
					return buildCompanyAssetURL(this.company.id, this.state.communityProfile.logoAssetUuid);
				}
				if (this.state.files[CompanyAssetType.COMPANY_PROFILE_LOGO]) {
					return this.state.files[CompanyAssetType.COMPANY_PROFILE_LOGO].file;
				}
				break;
			case CompanyAssetType.COMPANY_PROFILE_COVER:
				if (this.state.communityProfile.coverAssetUuid) {
					return buildCompanyAssetURL(this.company.id, this.state.communityProfile.coverAssetUuid);
				}
				if (this.state.files[CompanyAssetType.COMPANY_PROFILE_COVER]) {
					return this.state.files[CompanyAssetType.COMPANY_PROFILE_COVER].file;
				}
				break;
			case CompanyAssetType.COMPANY_PROFILE_PHOTO:
				if (this.state.communityProfile.photos && this.state.communityProfile.photos[index]) {
					return buildCompanyAssetURL(
						this.company.id,
						this.state.communityProfile.photos[index]
							? this.state.communityProfile.photos[index].assetUuid
							: null
					);
				}
				if (
					this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO] &&
					this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO][index]
				) {
					return this.state.files[CompanyAssetType.COMPANY_PROFILE_PHOTO][index].file;
				}
				break;
			default:
				return null;
		}

		return null;
	}
}

CommunityProfileInput.contextType = ApplicationContext;

CommunityProfileInput.propTypes = {
	intl: PropTypes.object,
	type: PropTypes.string,
	fieldToFocus: PropTypes.string,
	// applicationStore: PropTypes.object.isRequired,
	onUpdate: PropTypes.func
};

CommunityProfileInput.defaultProps = {
	type: CommunityProfileInput.DEFAULT
};

const communityProfileInput = injectIntl(CommunityProfileInput);
communityProfileInput.DEFAULT = CommunityProfileInput.DEFAULT = 'DEFAULT';
communityProfileInput.SOCIAL = CommunityProfileInput.SOCIAL = 'SOCIAL';
communityProfileInput.PROFILE = CommunityProfileInput.PROFILE = 'PROFILE';
communityProfileInput.PHOTOS = CommunityProfileInput.PHOTOS = 'PHOTOS';

export default communityProfileInput;
