/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedNumber, injectIntl } from 'react-intl';
import classNames from 'classnames';

import IncomeStore from '../../../../stores/IncomeStore';
import IncomeModel from '../../../../stores/models/IncomeModel';

import { getTotalAmountExclBTW, getTotalAmountInclBTW } from '../../../../utils/btwUtils';
import { defaultDateFormatOptions } from '../../../../data/Settings';

import Signals from '../../../../signals/Signals';
import Tag from '../../../../components/ui/Tag/Tag';
import Actions from '../../../../components/ui/ToolTip/tooltips/Actions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';

import { ApplicationContext } from '../../../../ApplicationContext';
import DeleteIncomeCommand from '../../../../commands/income/DeleteIncomeCommand';
import IncomeInputPanel from './modals/IncomeInputPanel';
import { uniqueKey } from '../../../../utils/ReactUtils';

/**
 *
 */
class IncomeRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.edit = this.edit.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.remove = this.remove.bind(this);

		this.onFieldClick = this.onFieldClick.bind(this);
		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onFileClick = this.onFileClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { incomeModel } = this.props;
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		const classes = classNames({
			'table-row__locked':
				!financialYear.isActive() ||
				this.context.applicationStore.yearResultStore.hasLockedDeadlines()
		});

		const state = incomeModel.state ? incomeModel.state.toLowerCase() : 'default';

		// Determine BTW label
		const btwLabel = this.props.intl.formatMessage({ id: incomeModel.getBTWTranslationId() });

		return (
			<tr
				className={`table-row ${
					this.props.incomeStore.sortBy
						? `table-row--sorted-by--${this.props.incomeStore.sortBy.toLowerCase()}`
						: ''
				} ${classes}`}>
				<td
					className="table-row__column table-row__column--editable income-row__invoicenr"
					onClick={() => this.onFieldClick('invoiceNr')}>
					{incomeModel.invoiceNr}
				</td>
				<td
					className="table-row__column  table-row__column--editable income-row__date"
					onClick={() => this.onFieldClick('date')}>
					<FormattedDate value={incomeModel.date} {...defaultDateFormatOptions} />
				</td>
				<td
					className="table-row__column table-row__column--editable income-row__customername"
					onClick={() => this.onFieldClick('customerName')}>
					<Truncate>{incomeModel.customerName}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable income-row__description"
					onClick={() => this.onFieldClick('description')}>
					<Truncate>{incomeModel.description}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--center income-row__btw"
					onClick={() => this.onFieldClick('btwType')}>
					{btwLabel}
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right income-row__exclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountExclBTW(incomeModel.invoiceRows, incomeModel.date)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column table-row__column--editable table-row__column--right income-row__inclbtw"
					onClick={() => this.onFieldClick('amount')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountInclBTW(incomeModel.invoiceRows)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className={`table-row__column table-row__column--center income-row__filename ${
						incomeModel.fileName ? 'icon icon--check-smoke' : ''
					}`}
					onClick={this.onFileClick}
				/>
				<td
					className="table-row__column table-row__column--editable table-row__column--center income-row__state"
					onClick={() => this.onFieldClick('state')}>
					<Tag labelId={`tag-label.${state}`} type={state} values={{ year: financialYear.year }} />
				</td>
				<td
					className="table-row__column table-row__column--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
					<div className="table-row__actions--locked icon icon--lock-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param field
	 */
	onFieldClick(field) {
		this.edit(field);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();
		Signals.ShowToolTip.dispatch(<Actions onSelect={this.onActionSelect} />, e.currentTarget, -16);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'edit':
				this.edit();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 */
	onFileClick() {
		if (this.props.incomeModel.id && this.props.incomeModel.fileName) {
			const url = window.config.imageIncome + this.props.incomeModel.id;
			window.open(url, '_blank');
		}
	}

	/**
	 *
	 */
	doRemove() {
		const { incomeModel, incomeStore } = this.props;
		const command = new DeleteIncomeCommand(incomeModel, incomeStore);
		command.execute();
	}

	/**
	 *
	 */
	remove() {
		const { intl, incomeModel } = this.props;
		const title = intl.formatMessage(
			{ id: 'income.remove.alert.title' },
			{
				description: incomeModel.description,
				customer: incomeModel.customerName
			}
		);

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={() => this.doRemove()}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @param fieldToFocus
	 */
	edit(fieldToFocus) {
		// Create copy to prevent unwanted changes to original
		const incomeModel = this.props.incomeModel.clone();

		// Show IncomeInputPanel overlay
		Signals.ShowOverlay.dispatch(
			<IncomeInputPanel
				key={uniqueKey('iip-')}
				incomeModel={incomeModel}
				fieldToFocus={fieldToFocus}
			/>
		);
	}

	/**
	 *
	 */
	duplicate() {
		// Copy all data from IncomeModel except id, invoiceNr, invoiceConceptId, and fileName
		const incomeModel = this.props.incomeModel.clone(true);

		// Show IncomeInputPanel overlay
		Signals.ShowOverlay.dispatch(
			<IncomeInputPanel key={uniqueKey('iip-')} incomeModel={incomeModel} />
		);
	}
}

IncomeRow.contextType = ApplicationContext;

IncomeRow.propTypes = {
	incomeModel: PropTypes.instanceOf(IncomeModel).isRequired,
	incomeStore: PropTypes.instanceOf(IncomeStore).isRequired,
	intl: PropTypes.object
};

export default injectIntl(IncomeRow);
