/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Page from '../../Page';

import { Routes } from '../../../data/Routes';
import Locale from '../../../data/Locale';
import OfferModel from '../../../stores/models/OfferModel';

import Signals from '../../../signals/Signals';

import BTW from '../../../data/BTW';
import CompanyAssetType from '../../../data/CompanyAssetType';

import OfferConceptRow from './components/OfferConceptRow';

import InfoIcon from '../../../components/ui/InfoIcon/InfoIcon';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';

import DropDown from '../../../components/ui/DropDown/DropDown';
import Switch from '../../../components/ui/Switch/Switch';
import OfferConceptSummaryRow from './components/OfferConceptSummaryRow';
import DateInput from '../../../components/ui/DateInput/DateInput';

import FileUploadList from '../../../components/ui/FileUpload/FileUploadList';
import CompanyCustomerSearch from '../../../components/ui/CompanyCustomerSearch/CompanyCustomerSearch';
import CompanyBrandSearch from '../../../components/ui/CompanyBrandSearch/CompanyBrandSearch';

import { ApplicationContext } from '../../../ApplicationContext';
import { FormErrorContext } from '../../../FormErrorContext';

import SaveOfferCommand from '../../../commands/offers/SaveOfferCommand';
import GetOffersCommand from '../../../commands/offers/GetOffersCommand';
import GetLatestOfferNumberCommand from '../../../commands/offers/GetLatestOfferNumberCommand';
import CookieController, { COOKIE_TYPES } from '../../../controllers/CookieController';
import PropertiesController from '../../../controllers/PropertiesController';
import AlertMessage from '../../../components/ui/AlertMessage/AlertMessage';

//
const localesArray = [Locale.nl_NL, Locale.en_US];

/**
 *
 */
@observer
class OfferConcept extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.offerModel = null;

		this.state = {
			offerNrChangedManually: false,
			submitting: false
		};

		this.onCancel = this.onCancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onError = this.onError.bind(this);

		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragUpdate = this.onDragUpdate.bind(this);

		this.onDateChange = this.onDateChange.bind(this);
		this.onExpirationDateChange = this.onExpirationDateChange.bind(this);
		this.onClientChange = this.onClientChange.bind(this);
		this.onBrandChange = this.onBrandChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.onAttachmentsChange = this.onAttachmentsChange.bind(this);

		this.showProjectOrderInfo = this.showProjectOrderInfo.bind(this);

		this.addNewOfferRow = this.addNewOfferRow.bind(this);
		this.onOfferRowChange = this.onOfferRowChange.bind(this);
		this.onOfferRowRemove = this.onOfferRowRemove.bind(this);

		this.onCompanyCustomerAdded = this.onCompanyCustomerAdded.bind(this);
		this.onCompanyBrandAdded = this.onCompanyBrandAdded.bind(this);

		this.fieldHeaderSettings = [
			{ id: 'dragicon', labelId: null },
			{ id: 'description', labelId: 'offer.header.description' },
			{ id: 'units', labelId: 'offer.header.units', center: true },
			{ id: 'unitprice', labelId: 'offer.header.unitprice', center: true },
			{ id: 'btw', labelId: 'offer.header.btw', center: true },
			{ id: 'amount', labelId: 'offer.header.amount', right: true },
			{ id: 'remove', labelId: 'offer.header.remove' }
		];
	}

	/**
	 *
	 */
	componentWillMount() {
		this.state.companyLatestOfferNumberStore =
			this.context.applicationStore.companyLatestOfferNumberStore;
		Signals.CompanyCustomerAdded.add(this.onCompanyCustomerAdded);
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this._initialize();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyCustomerAdded.remove(this.onCompanyCustomerAdded);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		// Check if user logged in, and we have company
		const company = this.context.applicationStore.getSelectedCompany();
		const user = this.context.applicationStore.user;

		const tableBodyClasses = classNames({
			table__body: true,
			'table__body--dragging': !!this.state.dragging
		});

		//
		if (!this.offerModel || !company || !user) {
			return null;
		}

		// Invoice Number suggestions and defaults
		const latestOfferNumber = this.state.companyLatestOfferNumberStore.getLatestOfferNumber(
			this.offerModel.date.getFullYear()
		);
		const suggestedInvoiceNumber = latestOfferNumber ? latestOfferNumber.suggestedNextOfferNr : '';

		// Set, override invoice nr
		if (!this.offerModel.id && !this.state.offerNrChangedManually && latestOfferNumber) {
			this.offerModel.offerNr = suggestedInvoiceNumber;
		}

		const inclVat = this.offerModel.getInclVat();
		const { intl } = this.props;
		const { errors } = this.state;

		return (
			<Page pageName="offer-concept grid">
				{PropertiesController.getProperty(PropertiesController.FEATURE_DEMO, this.context) ? (
					<AlertMessage
						className="offer-concept__demo-warning"
						title={this.props.intl.formatMessage({ id: 'demo.offerconcept.warning' })}
						warning
					/>
				) : null}

				<FormErrorContext.Provider value={{ errors }}>
					<form
						ref={(el) => {
							this.el = el;
						}}
						className="offer-concept__panel panel grid col--12 box-shadow border border--dark"
						onSubmit={this.onSubmit}>
						<div className="offer-concept__options-top padding-small border--bottom border--dark grid grid--spread col--12">
							{this._getOptionButtons()}
						</div>

						<div className="offer-concept__settings col--12 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--6">
									<label>
										<FormattedMessage id="invoice.settings.brand" />
									</label>
									{this._getCompanyBrandSearch()}
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="offer.settings.client" />
									</label>
									{this._getCompanyCustomerSearch()}
								</FormField>

								<FormField className="col--6 ">
									<label>
										<FormattedMessage id="offer.settings.offernr" /> (
										{this._getLastUsedOfferNrPlaceholder()})
									</label>
									<input
										name="offerNr"
										type="text"
										value={this.offerModel.offerNr}
										onChange={this.onInputChange}
										required
										placeholder={this._getLastUsedOfferNrPlaceholder()}
									/>
								</FormField>

								<FormField className="col--6 ">
									<label>
										<FormattedMessage id="offer.settings.projectcode" />
										<InfoIcon onClick={this.showProjectOrderInfo} />
									</label>
									<input
										name="projectcode"
										type="text"
										value={this.offerModel.projectcode}
										onChange={this.onInputChange}
									/>
								</FormField>

								<FormField className="col--3 form-field__date-picker offer-concept__form-field-stretch">
									<label>
										<FormattedMessage id="offer.settings.issuedate" />
									</label>
									<DateInput
										className="offer-concept__issue-date"
										name="date"
										autoComplete="off"
										value={this.offerModel.date}
										onChange={this.onDateChange}
									/>
								</FormField>

								<FormField className="col--3 form-field__date-picker offer-concept__form-field-stretch">
									<label>
										<FormattedMessage id="offer.settings.expirationterm" />
									</label>
									<DateInput
										className="offer-concept__expiration-date"
										name="expirationDate"
										autoComplete="off"
										minDate={this.offerModel.date}
										value={this.offerModel.getExpirationDateFromDays()}
										onChange={this.onExpirationDateChange}
									/>
								</FormField>

								<FormField className="col--6 offer-concept__form-field-stretch">
									<label>
										<FormattedMessage id="offer.settings.language" />
									</label>
									<DropDown
										value={this.offerModel.locale}
										className="col--12"
										name="locale"
										onChange={this.onInputChange}>
										{this._getLocaleOptions()}
									</DropDown>
								</FormField>
							</FormGroup>

							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="offer.subject.label" />
									</label>
									<input
										name="subject"
										value={this.offerModel.subject}
										type="text"
										placeholder={intl.formatMessage({ id: 'offer.subject.placeholder' })}
										onChange={this.onInputChange}
										maxLength={255}
										required
									/>
								</FormField>
							</FormGroup>
						</div>

						<div className="offer-concept__rows-wrapper">
							<div className="offer-concept__options-middle grid grid--right border--top">
								<Switch
									checked={inclVat}
									name="inclVat"
									useState={false}
									label={
										inclVat
											? intl.formatMessage({ id: 'offer.settings.label.inclvat' })
											: intl.formatMessage({ id: 'offer.settings.label.exclvat' })
									}
									onChange={this.onInputChange}
								/>
							</div>

							<table className="table offer-concept__rows col--12">
								<thead className="table__headers">
									<tr>{this._getTableHeaders()}</tr>
								</thead>
								<tbody className={tableBodyClasses} onDragOver={this.onDragOver}>
									{this._getOfferConceptRows()}
								</tbody>
								<tfoot>
									{/* subtotal */}
									<OfferConceptSummaryRow
										label={intl.formatMessage({ id: 'offer.summary.subtotal' })}
										amount={this.offerModel.getSubtotal()}
									/>

									{/* btw laag */}
									<OfferConceptSummaryRow
										hideWhenZero={!this.offerModel.containsVATType(BTW.LAAG)}
										label={intl.formatMessage(
											{ id: 'offer.summary.btw' },
											{
												percentage: intl.formatMessage({
													id: BTW.LAAG.translationId(this.offerModel.date)
												})
											}
										)}
										amount={this.offerModel.getBTWTotal(BTW.LAAG.name)}
									/>
									{/* btw hoog */}
									<OfferConceptSummaryRow
										hideWhenZero={!this.offerModel.containsVATType(BTW.HOOG)}
										label={intl.formatMessage(
											{ id: 'offer.summary.btw' },
											{ percentage: intl.formatMessage({ id: BTW.HOOG.translationId() }) }
										)}
										amount={this.offerModel.getBTWTotal(BTW.HOOG.name)}
									/>

									{this._getVATReversed()}
									{this._getVATExempt()}

									<OfferConceptSummaryRow
										label={intl.formatMessage({ id: 'offer.summary.total' })}
										amount={this.offerModel.getTotal()}
									/>
								</tfoot>
							</table>
						</div>

						{/* Notes */}
						<div className="offer-concept__extra col--12 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="offer.notes.label" />
									</label>
									<textarea
										name="notes"
										placeholder={intl.formatMessage({ id: 'offer.notes.placeholder' })}
										className="offer-concept__notes col--12"
										value={this.offerModel.notes}
										onChange={this.onInputChange}
									/>
								</FormField>
							</FormGroup>
						</div>

						{/* Attachments */}
						<div className="offer-concept__attachments col--7 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="offer.attachments.label" />
									</label>
									<FileUploadList
										fileAssets={this.offerModel.assetAttachments}
										maxTotalFileSize={20971520} // 20Mb
										onChange={this.onAttachmentsChange}
										assetType={CompanyAssetType.OFFER_ATTACHMENT}
									/>
								</FormField>
							</FormGroup>
						</div>

						{/* Options (Cancel/Save) */}
						<div className="offer-concept__options-bottom padding-small border--top border--dark grid grid--spread col--12">
							{this._getOptionButtons()}
						</div>
					</form>
				</FormErrorContext.Provider>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		this.offerModel.date = date;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onExpirationDateChange(e, date) {
		console.log('onExpirationDateChange', date);
		this.offerModel.setExpirationDaysFromDate(date);
		this.forceUpdate();
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onClientChange(companyCustomer) {
		this.offerModel.companyCustomer = companyCustomer;
		this.forceUpdate();
	}

	/**
	 *
	 * @param companyBrand
	 */
	onBrandChange(companyBrand) {
		const { applicationStore } = this.context;
		if (!companyBrand) {
			const company = applicationStore.getSelectedCompany();
			companyBrand = company.defaultCompanyBrand;
		}
		CookieController.setCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID, companyBrand.id);
		this.offerModel.companyBrand = companyBrand;

		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'offerNr':
				this.setState({ offerNrChangedManually: true });
				this.offerModel[e.target.name] = e.target.value;
				break;
			case 'expirationDays':
				// eslint-disable-next-line no-case-declarations
				const days = parseInt(e.target.value, 10);
				this.offerModel[e.target.name] = !isNaN(days) ? days : null;
				break;
			case 'locale':
				this.offerModel.locale = e.target.value;
				this._updateNotes();
				break;
			case 'inclVat':
				// toggle incl/excl vat
				this.offerModel.setInclVat(!e.target.checked);
				break;
			default:
				this.offerModel[e.target.name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 */
	onCancel() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			if (this.offerModel.id) {
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_OFFERS_OFFER.getPath({
						id: company.id,
						offerId: this.offerModel.id
					})
				);
			} else {
				Signals.RequestRoute.dispatch(Routes.COMPANY_OFFERS_ALL.getPath({ id: company.id }));
			}
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		Page.blurActiveElement();
		this.doSubmit();
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		this.setState({ submitting: false });

		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}

	/**
	 *
	 */
	onDragStart(offerRowModel, event) {
		const draggingContainer = document.querySelector('.offer-concept__rows tbody');
		const draggingElements = draggingContainer.querySelectorAll('.offer-concept-row');
		const elementRect = event.target.getBoundingClientRect();
		const elementCenterY = elementRect.y + elementRect.height * 0.5;
		this.dragOffsetY = event.clientY - elementCenterY;

		this.setState({
			dragging: offerRowModel,
			draggingContainer,
			draggingElements
		});

		this.dragUpdateInterval = setInterval(this.onDragUpdate, 31);
	}

	/**
	 *
	 * @param offerRowModel
	 */
	onDragEnd(_offerRowModel) {
		clearInterval(this.dragUpdateInterval);
		this.dragClientY = null;
		this.setState({ dragging: null, draggingElements: null, draggingContainer: null });
	}

	/**
	 *
	 */
	onDragUpdate() {
		const { dragging, draggingElements } = this.state;

		if (dragging && this.dragClientY) {
			const draggingUid = `${dragging._uid}`;
			const positionY = this.dragClientY - this.dragOffsetY;
			const before = [];
			const element = [];
			const after = [];

			// Determine order
			draggingElements.forEach((rowElement) => {
				const rect = rowElement.getBoundingClientRect();

				if (rowElement.dataset.uid !== draggingUid) {
					if (positionY <= rect.y + rect.height * 0.5) {
						after.push(rowElement);
					} else {
						before.push(rowElement);
					}
				} else {
					element.push(rowElement);
				}
			});

			// Apply order
			const ordered = before.concat(element).concat(after);
			const result = [];
			ordered.forEach((rowElement, index) => {
				const uid = rowElement.dataset.uid;
				const offerRowModel = this.offerModel.getConceptRowByUid(parseInt(uid, 10));
				offerRowModel.sortOrder = index;
				result.push(offerRowModel);
			});

			// Update invoiceConceptRows order
			this.offerModel.offerRows = result;
			this.forceUpdate();
		}
	}

	/**
	 *
	 * @param event
	 */
	onDragOver(event) {
		event.preventDefault();
		this.dragClientY = event.clientY;
	}

	/**
	 *
	 * @param id
	 */
	onCompanyCustomerAdded(id) {
		this.offerModel.companyCustomerId = parseInt(id, 10);
		this.offerModel.companyCustomer = this.context.applicationStore.companyCustomerStore.find(
			parseInt(id, 10)
		);
		this.forceUpdate();
	}

	/**
	 *
	 * @param id
	 */
	onCompanyBrandAdded(id) {
		console.log('onCompanyBrandAdded', id);
		this.offerModel.companyBrand = this.context.applicationStore.companyBrandStore.find(
			parseInt(id, 10)
		);
		this.forceUpdate();
	}

	/**
	 *
	 */
	onOfferRowChange() {
		this.forceUpdate();
	}

	/**
	 *
	 * @param OfferConceptRowModel
	 */
	onOfferRowRemove(OfferConceptRowModel) {
		this.offerModel.removeConceptRow(OfferConceptRowModel);
		this.forceUpdate();
	}

	/**
	 *
	 * @param fileAssets FileAssets
	 */
	onAttachmentsChange(fileAssets) {
		this.offerModel.assetAttachments = fileAssets;
	}

	/**
	 *
	 */
	doSubmit() {
		Page.blurActiveElement();
		const company = this.context.applicationStore.getSelectedCompany();

		if (company) {
			// Validate
			// TODO: check if expirationDate not before issueDate

			// Prepare requests
			this.setState({ errors: null, submitting: true });
			const defaults = {
				senderEmail: this.context.applicationStore.user.email,
				notes: ''
			};

			// ignore signData, for when editing an already signed offer
			const data = this.offerModel.toJSON(defaults);
			const saveCommand = new SaveOfferCommand(
				this.context.applicationStore,
				this.offerModel,
				company,
				data
			);
			saveCommand.execute(() => {
				this.setState({ submitting: false });
			}, this.onError);
		}
	}

	/**
	 *
	 */
	showProjectOrderInfo() {
		const translationTitleId = 'info.projectcode.title';
		const translationId = 'info.projectcode.body';
		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatMessage({ id: translationTitleId })}
				body={this.props.intl.formatMessage({ id: translationId })}
			/>
		);
	}

	/**
	 *
	 */
	addNewOfferRow() {
		this.offerModel.addNewOfferRow();
		this.forceUpdate();
	}

	/**
	 *
	 * @private
	 */
	_getCompanyCustomerSearch() {
		const { intl } = this.props;
		const { applicationStore } = this.context;
		let showChangeOnly = false;

		const companyCustomerId = this.offerModel.getCompanyCustomerId();
		const currentCompanyCustomer = this.offerModel.companyCustomer;

		// If companyCustomerId is found on invoiceconcept, compare to stored version
		if (companyCustomerId) {
			const savedCompanyCustomer = applicationStore.companyCustomerStore.find(companyCustomerId);

			if (!savedCompanyCustomer || !savedCompanyCustomer.isEqual(currentCompanyCustomer)) {
				showChangeOnly = true;
			}
		}

		//
		return (
			<CompanyCustomerSearch
				name="companyCustomer"
				value={this.offerModel.getCompanyCustomerId()}
				initialCompanyCustomer={currentCompanyCustomer}
				placeholder={intl.formatMessage({ id: 'company.customerselect.placeholder' })}
				changeOnly={showChangeOnly}
				onChange={this.onClientChange}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getCompanyBrandSearch() {
		const { intl } = this.props;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		let currentCompanyBrandId = this.offerModel.getCompanyBrandId()
			? this.offerModel.getCompanyBrandId()
			: parseInt(CookieController.getCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID), 10);
		const currentCompanyBrand = this.offerModel.companyBrand;
		let showChangeOnly = false;

		// If companybrand is found on offer, compare to stored version
		if (currentCompanyBrandId >= 0) {
			const savedCompanyBrand = applicationStore.companyBrandStore.find(currentCompanyBrandId);
			if (!savedCompanyBrand || !savedCompanyBrand.isEqual(currentCompanyBrand)) {
				showChangeOnly = true;
			}
			// Default is used, compare if same as current default
		} else if (currentCompanyBrandId === -1 && currentCompanyBrand) {
			showChangeOnly = !currentCompanyBrand.isEqual(company.defaultCompanyBrand);
			// No companybrand store, set -1 to select default
		} else {
			currentCompanyBrandId = -1; // set default
			this.offerModel.companyBrand = company.defaultCompanyBrand;
		}

		//
		return (
			<CompanyBrandSearch
				name="companyBrand"
				value={currentCompanyBrandId}
				initialCompanyBrand={currentCompanyBrand}
				placeholder={intl.formatMessage({ id: 'company.brandselect.placeholder' })}
				includeDefault
				changeOnly={showChangeOnly}
				onChange={this.onBrandChange}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getOfferConceptRows() {
		// Create invoices concept rows
		const rows = this.offerModel.offerRows.map((offerRowModel, index) => {
			offerRowModel.sortOrder = index;
			return (
				<OfferConceptRow
					key={`i-${offerRowModel._uid}-${offerRowModel.id}`}
					offerRowModel={offerRowModel}
					offerModel={this.offerModel}
					onChange={this.onOfferRowChange}
					onRemove={this.onOfferRowRemove}
					onDragStart={this.onDragStart}
					onDragEnd={this.onDragEnd}
				/>
			);
		});

		// Add new row
		rows.push(
			<tr
				key="add-offer-concept-row"
				className="offer-concept__add-offer-concept-row border--top border--dark"
				onClick={this.addNewOfferRow}>
				<td
					className="offer-concept-row__column offer-concept-row__add icon icon--left icon--add-black"
					colSpan={7}>
					<FormattedMessage id="offer.add.row" />
				</td>
			</tr>
		);

		return rows;
	}

	/**
	 *
	 * @return {any[]}
	 * @private
	 */
	_getTableHeaders() {
		let totalUnits = 0;
		if (this.offerModel) {
			this.offerModel.offerRows.forEach((offerRow) => {
				totalUnits += offerRow.units;
			});
		}
		const values = { totalUnits: ` (${totalUnits})` };

		// Create table headers
		return this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`table__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}>
					{fieldData.labelId ? <FormattedMessage id={fieldData.labelId} values={values} /> : ''}
				</td>
			);
		});
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getLocaleOptions() {
		// Locale options
		return localesArray.map((locale, index) => {
			return (
				<option key={`locale-${index}`} value={locale}>
					{this.props.intl.formatMessage({ id: `locale.${locale}` })}
				</option>
			);
		});
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.offerModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<OfferConceptSummaryRow
					label={this.props.intl.formatMessage({ id: 'offer.vat.exempt.summary' })}
					amount={null}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.offerModel.containsVATType(BTW.VERLEGD)) {
			return (
				<OfferConceptSummaryRow
					label={this.props.intl.formatMessage({ id: 'offer.vat.reversed.summary' })}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {Array}
	 * @private
	 */
	_getOptionButtons() {
		const buttons = [];

		buttons.push(
			<button
				key="btn-cancel"
				className="button--tertiary button--no-shadow"
				onClick={this.onCancel}
				type="button">
				<FormattedMessage id="label.cancel" />
			</button>
		);

		buttons.push(
			<button
				key="btn-submit"
				className="button button--primary"
				type="submit"
				disabled={
					!this.offerModel.canEdit() ||
					PropertiesController.getProperty(PropertiesController.FEATURE_DEMO, this.context)
				}>
				<FormattedMessage id="offer.prepare" />
			</button>
		);

		return buttons;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getLastUsedOfferNrPlaceholder() {
		// Invoice Number suggestions and defaults
		const latestOfferNumber = this.state.companyLatestOfferNumberStore.getLatestOfferNumber(
			this.offerModel.date.getFullYear()
		);

		if (latestOfferNumber && latestOfferNumber.latestInvoiceNr) {
			return this.props.intl.formatMessage(
				{ id: 'offer.offernr.placeholder' },
				{ offerNr: latestOfferNumber.latestInvoiceNr }
			);
		}
		return this.props.intl.formatMessage(
			{ id: 'offer.offernr.kdbformat' },
			{ year: new Date().getFullYear() }
		);
	}

	/**
	 * Try to get or create offerModel based on route. Fetch all invoices if necessary
	 *
	 * @private
	 */
	_initialize(skipFetch = false) {
		//
		const company = this.context.applicationStore.getSelectedCompany();
		if (!company) {
			return;
		}

		// NEW: Create a new invoice if 'new' is found
		if (this.context.applicationStore.currentRouteParams.offerId === 'new') {
			this._createNewOfferConcept();

			// DUPLICATE: Check if the supplied offerId needs to be duplicated
		} else if (this.context.applicationStore.currentRoute === Routes.COMPANY_OFFERS_DUPLICATE) {
			this._createDuplicateOfferConcept();

			// EDIT: Check if the supplied offerId needs to be duplicated
		} else if (
			!isNaN(parseInt(this.context.applicationStore.currentRouteParams.offerId, 10))
		) {
			const offerId = parseInt(this.context.applicationStore.currentRouteParams.offerId, 10);
			this.offerModel = this.context.applicationStore.offersStore.getOfferById(offerId);
		}

		// NONE FOUND OR CREATED: If no offerModel exists, force fetch of invoiceConcepts from server before trying again
		if (!this.offerModel) {
			if (!skipFetch) {
				const command = new GetOffersCommand(this.context.applicationStore.offersStore, company.id);
				command.execute(() => {
					this._initialize(true);
				});
			} else {
				console.log('invoiceConcept not found');
			}

			return;
		}

		// Set default values
		this._applyDefaults(company.offersSettings, company.invoicesSettings);

		// New/Duplicate invoice? Fetch suggested offerNr
		if (!this.offerModel.id) {
			const command = new GetLatestOfferNumberCommand(
				this.state.companyLatestOfferNumberStore,
				company
			);
			command.execute();
		}

		// Make sure we always have a minimum of 1 row
		while (this.offerModel.offerRows.length < 1) {
			this.addNewOfferRow();
		}

		// Update rendering
		this.forceUpdate();

		// Autofocus on selected field
		this._autoFocus();
	}

	/**
	 *
	 * @private
	 */
	_createNewOfferConcept() {
		this.offerModel = new OfferModel();

		const { applicationStore } = this.context;
		const companyBrand = applicationStore.companyBrandStore.find(
			parseInt(CookieController.getCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID), 10)
		);
		if (companyBrand) {
			this.offerModel.companyBrand = companyBrand;
		}
	}

	/**
	 *
	 * @private
	 */
	_createDuplicateOfferConcept() {
		const offerId = parseInt(this.context.applicationStore.currentRouteParams.offerId, 10);
		const toClone = this.context.applicationStore.offersStore.getOfferById(offerId);

		if (toClone) {
			const clone = toClone.clone();

			// Todo, check if company customer still exists, if not, set to null
			clone.companyCustomerId = clone.companyCustomer.id;

			this.offerModel = clone;
		}
	}

	/**
	 *
	 * @private
	 */
	_applyDefaults(_offersSettings, _invoicesSettings) {
		// Set, override locale based on users preference if invoice is new
		if (!this.offerModel.locale) {
			this.offerModel.locale = this.context.applicationStore.user.isEnglish()
				? Locale.en_US
				: Locale.nl_NL;
		}

		// Set default notes, based on locale
		this._updateNotes();
	}

	/**
	 *
	 * @private
	 */
	_updateNotes() {
		const company = this.context.applicationStore.getSelectedCompany();
		const offersSettings = company.offersSettings;

		// Get default string or convert default translation object to string
		const defaultEN =
			typeof offersSettings.defaultNotesEN === 'object'
				? this.props.intl.formatMessage(offersSettings.defaultNotesEN)
				: offersSettings.defaultNotesEN;
		const defaultNL =
			typeof offersSettings.defaultNotes === 'object'
				? this.props.intl.formatMessage(offersSettings.defaultNotes)
				: offersSettings.defaultNotes;

		// Is notes one of the default values? Or is notes not set?
		if (
			!this.offerModel.notes ||
			this.offerModel.notes === defaultEN ||
			this.offerModel.notes === defaultNL
		) {
			// Set notes based on locale
			if (this.offerModel.locale === Locale.en_US) {
				this.offerModel.notes = defaultEN;
			} else {
				this.offerModel.notes = defaultNL;
			}
		}
	}

	/**
	 *
	 */
	_autoFocus() {
		// Autofocus on autofocusField
		const fieldToFocus = this.context.applicationStore.offersStore.autofocusField;
		if (fieldToFocus) {
			setTimeout(() => {
				if (this.el && fieldToFocus) {
					const field = this.el.querySelector(`[name="${fieldToFocus}"]`);

					try {
						field.focus();
						const cursorPosition = field.value ? field.value.length : 0;
						field.setSelectionRange(cursorPosition, cursorPosition);
					} catch (e) {
						// Ignore
					}
				}
			}, 100);
		}
	}
}

OfferConcept.contextType = ApplicationContext;

OfferConcept.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(OfferConcept);
