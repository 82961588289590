import React from 'react';
import PropTypes from 'prop-types';

export default class Truncate extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="truncated">
				<div className="truncated__content" title={JSON.stringify(this.props.children)}>
					{this.props.children}
				</div>
				<span>&nbsp;</span>
			</div>
		);
	}
}

Truncate.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
