import BTW from '../../data/BTW';

let UID = 0;

/**
 *
 */
export default class IncomeRowModel {
	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		this._uid = UID++;

		this.amount = null;
		this.btw = BTW.HOOG.name;
		this.btwCustomerNr = undefined;
		this.inclVat = true;
		this.isValidBTW = true;

		if (data) {
			this.update(data);
		}
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				// `inclVat` Should always be true, vatNrCheck should always be filtered out
				if (key !== 'inclVat' && key !== 'vatNrCheck') {
					this[key] = data[key];
				}
			}
		}
		this._checkVATNumber();
	}

	/**
	 *
	 * @return {IncomeRowModel}
	 */
	clone(unique = false) {
		const incomeRowModel = new IncomeRowModel(this);
		if (unique) {
			incomeRowModel.id = undefined;
		}
		return incomeRowModel;
	}

	/**
	 *
	 * @param incomeRowModel
	 * @return {boolean}
	 */
	equals(incomeRowModel) {
		for (const key in incomeRowModel) {
			if (incomeRowModel.hasOwnProperty(key)) {
				if (key !== '_uid') {
					const original = JSON.stringify(this[key]);
					const modified = JSON.stringify(incomeRowModel[key]);

					if (original !== modified) {
						console.log('incomeRowModel.equals', key, original, modified);
						return false;
					}
				}
			}
		}

		return true;
	}

	/**
	 * Checks if the european VAT number valid or not.
	 * @private
	 */
	_checkVATNumber() {
		if (this.btw === BTW.EU.name) {
			this.isValidBTW = BTW.EU.isValid(this.btwCustomerNr);
		} else {
			this.isValidBTW = true;
		}
	}
}
