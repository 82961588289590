import { observable } from 'mobx';
import GetCompanyBrandsCommand from '../commands/brands/GetCompanyBrandsCommand';
import CompanyBrandModel from './models/CompanyBrandModel';

export default class CompanyBrandStore {
	@observable list = [];

	/**
	 *
	 * @param companyId
	 */
	fetch(companyId) {
		this.list = [];

		// Request
		const command = new GetCompanyBrandsCommand(companyId);
		command.execute((response) => {
			const results = [];

			response.list.forEach((data) => {
				const model = new CompanyBrandModel();
				model.update(data);
				results.push(model);
			});

			this.list = results;
		});
	}

	/**
	 *
	 */
	reset() {
		this.list = [];
	}

	/**
	 *
	 * @param id
	 * @return {CompanyBrandModel}
	 */
	find(id) {
		return this.list.find((companyBrand) => {
			return companyBrand.id === id;
		});
	}

	/**
	 *
	 * @param companyBrand
	 */
	add(companyBrand) {
		this.list.push(companyBrand);
		this.update();
	}

	/**
	 *
	 * @param companyBrand
	 */
	remove(companyBrand) {
		this.list.remove(companyBrand);
		this.update();
	}

	/**
	 *
	 */
	update() {
		this.list = this.list.concat([]);
	}
}
