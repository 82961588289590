/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getTotalAmountInclBTW } from '../../../utils/btwUtils';

import { ApplicationContext } from '../../../ApplicationContext';
import IncomeModel from '../../../stores/models/IncomeModel';
import AutocompleteSearchObject from '../../../data/AutocompleteSearchObject';

/**
 *
 */
export default class AutocompleteInvoiceResult extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	render() {
		const { incomeModel } = this.props;
		return (
			<div className="autocomplete-invoice-result grid grid--no-wrap col--12">
				<div className="autocomplete-invoice-result__name" title={incomeModel.customerName}>
					<div
						className="autocomplete-invoice-result__value"
						dangerouslySetInnerHTML={{ __html: this._applySearchHighlights('customerName') }}
					/>
				</div>
				<div className="autocomplete-invoice-result__description" title={incomeModel.description}>
					<div
						className="autocomplete-invoice-result__value"
						dangerouslySetInnerHTML={{ __html: this._applySearchHighlights('description') }}
					/>
				</div>
				<div className="autocomplete-invoice-result__vat">
					<div className="autocomplete-expense-result__value">
						<FormattedMessage id={incomeModel.getBTWTranslationId()} />
					</div>
				</div>
				<div className="autocomplete-invoice-result__amount">
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={getTotalAmountInclBTW(incomeModel.invoiceRows)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param fieldName
	 * @return {string|null}
	 * @private
	 */
	_applySearchHighlights(fieldName) {
		const value = this.props.incomeModel[fieldName];
		const autocompleteFieldData = this.props.autocompleteSearchObject.getField(fieldName);
		if (!autocompleteFieldData || !value) {
			return value;
		}

		const searchQuery = autocompleteFieldData.searchQuery;

		if (searchQuery && searchQuery.length > 0) {
			const startIndex = value.toLowerCase().indexOf(searchQuery.toLowerCase());
			const endIndex = startIndex + searchQuery.length;
			const result = [];

			if (startIndex !== 0) {
				result.push(value.slice(0, startIndex));
			}

			const highlightText = value.slice(startIndex, endIndex);

			result.push(`<span class="autocomplete-result-row__highlight">${highlightText}</span>`);
			result.push(value.slice(endIndex));
			return result.join('');
		}

		return value;
	}
}

AutocompleteInvoiceResult.contextType = ApplicationContext;

AutocompleteInvoiceResult.propTypes = {
	incomeModel: PropTypes.instanceOf(IncomeModel),
	autocompleteSearchObject: PropTypes.instanceOf(AutocompleteSearchObject).isRequired
};
