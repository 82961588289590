/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { Routes, RouteUtil } from '../../data/Routes';

import Signals from '../../signals/Signals';

import Page from '../Page';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';

import resetPassword from '../../requests/resetPassword';

import { ApplicationContext } from '../../ApplicationContext';
import { FormErrorContext } from '../../FormErrorContext';
import PasswordValidator from '../../components/ui/PasswordValidator/PasswordValidator';
import ModalAlert from '../../components/ui/Modal/ModalAlert';

/**
 *
 */
class ResetPassword extends React.Component {
	constructor(props) {
		super(props);

		this.onInputChange = this.onInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);

		this.state = {
			password: '',
			errors: []
		};
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { errors } = this.state;

		return (
			<Page pageName="forgot-password page--logo">
				<div className="vertically-center">
					<div className="forgot-password__panel panel border border--dark">
						<div className="forgot-password__header padding border--bottom border--dark">
							<h2 className="pad-left">
								<FormattedMessage id="reset-password.title" />
							</h2>
						</div>

						<FormErrorContext.Provider value={{ errors }}>
							<form
								className="reset-password__form padding-small"
								action={Routes.LOGIN.pathName}
								name="reset-password"
								onSubmit={this.onSubmit}>
								<FormGroup>
									<FormField className="col--12">
										<label>
											<FormattedMessage id="reset-password.field.password" />
										</label>
										<input
											type="password"
											name="password"
											required
											autoComplete="new-password"
											value={this.state.passwprd}
											onChange={this.onInputChange}
										/>

										<PasswordValidator value={this.state.password} />
									</FormField>
								</FormGroup>

								<FormGroup>
									<FormField className="col--12">
										<button className="reset-password__submit-button button--primary" type="submit">
											<FormattedMessage id="reset-password.field.submit" />
										</button>
									</FormField>
								</FormGroup>

								<FormGroup className="grid--center">
									<FormField className="col-12 grid grid--center">
										<Link to={Routes.LOGIN.pathName}>
											<button type="button" className="button--tertiary button--no-border">
												<FormattedMessage id="reset-password.field.login" />
											</button>
										</Link>
									</FormField>
								</FormGroup>
							</form>
						</FormErrorContext.Provider>
					</div>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const newState = {};
		newState[e.target.name] = e.target.value;
		this.setState(newState);
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		this.setState({ errors: null, error: null });
		const { location } = this.props;

		const currentRoute = RouteUtil.getRoute(location.pathname);
		if (!currentRoute) {
			return null;
		}

		const currentParams = currentRoute.getVariables(location.pathname);
		const password = this.state.password;
		const uuid = currentParams.uuid;

		resetPassword(password, uuid).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="password.reset.message" />);
		Signals.RequestRoute.dispatch(Routes.LOGIN.pathName);
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			if (error.response.body.description) {
				Signals.ShowModal.dispatch(<ModalAlert title={error.response.body.description} body="" />);
			} else {
				this.setState({ errors: error.response.body });
			}
		} else {
			Signals.Error.dispatch(error);
		}
	}
}

ResetPassword.contextType = ApplicationContext;

ResetPassword.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object,
	location: PropTypes.object
};

export default withRouter(injectIntl(ResetPassword));
