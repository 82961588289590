/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { ApplicationContext } from '../../../ApplicationContext';
import DraftFileUpload from './DraftFileUpload';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
export default class DraftsFileUpload extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { collapsed: false, draftsFileUploadStore: null };

		this.closeWhenFinished = this.closeWhenFinished.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.CompanyChanged.add(this.closeWhenFinished);
		Signals.FinancialYearChanged.add(this.closeWhenFinished);

		this.setState({ draftsFileUploadStore: this.context.applicationStore.draftsFileUploadStore });
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {
		// Check if it should be closed
		const { closeWhenFinished } = this.state;

		if (closeWhenFinished) {
			const { draftsFileUploadStore } = this.state;
			if (!draftsFileUploadStore) {
				return null;
			}

			// Only check if needs to close when no more incomplete workers
			const incompleteWorkerCount = draftsFileUploadStore.incompleteWorkerCount;
			if (incompleteWorkerCount === 0) {
				// Reset closeWhenFinished state, but reset DraftsUploadStore to hide view
				this.setState({ closeWhenFinished: false }, () => {
					this.state.draftsFileUploadStore.reset();
				});
			}
		}
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.closeWhenFinished);
		Signals.FinancialYearChanged.remove(this.closeWhenFinished);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { draftsFileUploadStore } = this.state;
		if (!draftsFileUploadStore) {
			return null;
		}

		const hasWorkers = draftsFileUploadStore.workers.length > 0;
		const incompleteWorkerCount = draftsFileUploadStore.incompleteWorkerCount;

		const classes = classNames({
			'drafts-file-upload': true,
			'box-shadow-heavy': true,
			'drafts-file-upload--collapsed':
				this.state.collapsed && !(hasWorkers && incompleteWorkerCount === 0), // Expand when done
			'drafts-file-upload--show': hasWorkers
		});

		return (
			<div className={classes}>
				<header>
					<div className="drafts-file-upload__info">{this._getTitle()}</div>
					<div className="drafts-file-upload__actions">
						{this._getCollapseExpandButton()}
						{this._getCloseButton()}
					</div>
				</header>
				<div className="drafts-file-upload__container">
					<div className="drafts-file-upload__wrapper">
						{draftsFileUploadStore.sortedWorkers.map((worker) => {
							return <DraftFileUpload key={`dfu-${worker._uid}`} draftUploadWorker={worker} />;
						})}
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	collapse() {
		this.setState({ collapsed: true });
	}

	/**
	 *
	 */
	expand() {
		this.setState({ collapsed: false });
	}

	/**
	 *
	 */
	close() {
		this.state.draftsFileUploadStore.reset();
	}

	/**
	 *
	 */
	closeWhenFinished() {
		this.setState({ closeWhenFinished: true });
	}

	/**
	 *
	 * @return {null|*}
	 * @private
	 */
	_getTitle() {
		const draftsFileUploadStore = this.state.draftsFileUploadStore;

		const workerCount = draftsFileUploadStore.workers.length;
		const incompleteWorkerCount = draftsFileUploadStore.incompleteWorkerCount;
		const completeWorkersCount = workerCount - incompleteWorkerCount;
		const hasIncompleteWorkers = incompleteWorkerCount > 0;
		const hasFailedUploads = draftsFileUploadStore.hasFailedUploads;
		const percentage = Math.round(draftsFileUploadStore.percentage * 100);

		if (hasIncompleteWorkers) {
			return (
				<div className="drafts-file-upload__title">
					<label>
						<FormattedMessage
							id="draft.fileupload.title.uploading"
							values={{
								workersCompleteCount: `${completeWorkersCount}`,
								workerCount: `${workerCount}`,
								percentage: `${percentage}`
							}}
						/>
					</label>
				</div>
			);
		}
		if (hasFailedUploads) {
			return (
				<div className="drafts-file-upload__title">
					<div className="drafts-file-upload__icon">
						<i className="icon icon--info-warning" />
					</div>
					<label>
						<FormattedMessage id="draft.fileupload.title.donewithfailures" />
					</label>
				</div>
			);
		}
		return (
			<div className="drafts-file-upload__title">
				<div className="drafts-file-upload__icon">
					<i className="icon icon--check-success" />
				</div>
				<label>
					<FormattedMessage id="draft.fileupload.title.done" />
				</label>
			</div>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getCollapseExpandButton() {
		const hasWorkers = this.state.draftsFileUploadStore.workers.length > 0;
		const hasIncompleteWorkers = this.state.draftsFileUploadStore.incompleteWorkerCount > 0;

		// Only show when not completed
		if (hasWorkers && hasIncompleteWorkers) {
			if (this.state.collapsed && hasWorkers) {
				return (
					<button type="button" className="button-small--grey" onClick={() => this.expand()}>
						<FormattedMessage id="drafts.uploads.view" />
					</button>
				);
			}
			return (
				<button type="button" className="button-small--grey" onClick={() => this.collapse()}>
					<FormattedMessage id="drafts.uploads.hide" />
				</button>
			);
		}

		return null;
	}

	/**
	 *
	 * @return {null|*}
	 * @private
	 */
	_getCloseButton() {
		const hasWorkers = this.state.draftsFileUploadStore.workers.length > 0;
		const hasIncompleteWorkers = this.state.draftsFileUploadStore.incompleteWorkerCount > 0;

		if (hasWorkers && !hasIncompleteWorkers) {
			return (
				<button type="button" className="button-small--grey" onClick={() => this.close()}>
					<FormattedMessage id="drafts.uploads.close" />
				</button>
			);
		}
		return null;
	}
}

DraftsFileUpload.contextType = ApplicationContext;
