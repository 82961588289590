import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Routes } from '../../../data/Routes';
import ProfileItem from './ProfileItem';
import { ApplicationContext } from '../../../ApplicationContext';
import Signals from '../../../signals/Signals';

/**
 *
 */
class Profiles extends React.Component {
	/**
	 *
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		//
		if (!this.context.applicationStore.user) {
			return null;
		}

		// Build company entries
		const companies = this.context.applicationStore.user.companies.map((company) => {
			return (
				<Link key={`cid-${company.id}`} to={Routes.COMPANY_DASHBOARD.getPath({ id: company.id })}>
					<ProfileItem
						className="profiles-item--interactive"
						todoCount={this.context.applicationStore.todoStore.getCompanyTodos(company).length}
						userCompany={company}
					/>
				</Link>
			);
		});

		return (
			<div>
				<h2 className="profiles__title">
					<FormattedMessage id="profile.title" />
				</h2>

				{companies}

				<Link key="cid-user" to={Routes.USER_DOCUMENTS.getPath({})}>
					<ProfileItem
						className="profiles-item--user profiles-item--interactive"
						userCompany={this.context.applicationStore.user}
					/>
				</Link>

				{/* <div className="profiles__settings icon icon--settings2-black" onClick={this.onPersonal.bind(this)}> */}
				{/*	<FormattedMessage id="profile.personal"/> */}
				{/* </div> */}

				<div
					className="profiles__logout icon icon--logout-black"
					onClick={this.onLogout.bind(this)}>
					<FormattedMessage id="profile.logout" />
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onPersonal() {
		Signals.RequestRoute.dispatch(Routes.USER_DOCUMENTS.getPath({}));
	}

	/**
	 *
	 */
	onLogout() {
		this.context.applicationStore.logout();
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onDocuments() {}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onSettings() {}
}

Profiles.contextType = ApplicationContext;

Profiles.propTypes = {};

export default injectIntl(Profiles);
