import { observable } from 'mobx';

import CookieController, { COOKIE_TYPES } from '../controllers/CookieController';
import PropertiesController from '../controllers/PropertiesController';

import Signals from '../signals/Signals';
import todoGet from '../requests/todoGet';

import TodoType from '../data/TodoType';

/**
 *
 */
export default class TodoStore {
	previousTodos = [];

	@observable todos = [];

	@observable fetching = false;

	@observable hasFetched = false;

	todoImageIndex = 1; // used to update empty todolist background image

	/**
	 *
	 */
	constructor() {
		this._sort = this._sort.bind(this);

		// Load previous todo's
		const previousCookies = CookieController.getCookie(COOKIE_TYPES.TODOS_COOKIE_ID);

		if (previousCookies) {
			this.previousTodos = previousCookies.concat([]);
			this.todos = previousCookies.concat([]);
		}
	}

	/**
	 *
	 * @returns {*|Promise<T>}
	 */
	fetch() {
		console.log('TodoStore.fetch');
		this.todoImageIndex = Math.floor(Math.random() * 4) + 1;
		this.fetching = true;

		return todoGet()
			.then((response) => {
				this.fetching = false;
				this.hasFetched = true;
				this.todos = response.list.sort(this._sort);

				Signals.TodosFetched.dispatch();
			})
			.catch((error) => {
				this.fetching = false;
				Signals.Error.dispatch(error);
			});
	}

	/**
	 *
	 */
	reset() {
		this.fetching = false;
		this.hasFetched = false;
		this.previousTodos = [];
		this.todos = [];
	}

	/**
	 *
	 * @returns {Array}
	 */
	getUserTodos() {
		// No todos in Demo account
		if (PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
			return [];
		}

		let result = [];
		this.todos.forEach((todo, _index) => {
			switch (todo.type) {
				case TodoType.USER:
					result.push(todo);
					break;
				case TodoType.ANNUAL_DECLERATION:
					result = result.concat(this._splitTodoRules(todo));
					break;
				default:
					break;
			}
		});

		return result;
	}

	/**
	 *
	 * @param company
	 * @returns {Array}
	 */
	getCompanyTodos(company) {
		let result = [];
		this.todos.forEach((todo) => {
			if (todo.id === company.id) {
				// Split data into seperate rule objects
				switch (todo.type) {
					case TodoType.VAT:
						result = result.concat(this._splitTodoRules(todo)); // allowed in demo account
						break;
					case TodoType.FINANCIAL_STATEMENT:
						// No todos in Demo account
						if (!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
							result = result.concat(this._splitTodoRules(todo));
						}
						break;
					case TodoType.COMPANY:
						// No todos in Demo account
						if (!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
							result.push(todo);
						}
						break;
					default:
						break;
				}
			}
		});

		return result;
	}

	/**
	 *
	 * @return {Array}
	 */
	getAllTodos(user) {
		let todos = this.getUserTodos();

		user.companies.forEach((company) => {
			todos = todos.concat(this.getCompanyTodos(company));
		});

		return todos;
	}

	/**
	 * Returns whether a company has VAT or Financial Statement deadlines for a specific year
	 *
	 * @param company
	 * @param financialYear
	 * @returns {boolean}
	 */
	hasDeadlines(company, financialYear) {
		const result = [];
		this.todos.forEach((todo) => {
			if (
				todo.id === company.id &&
				(todo.type === TodoType.VAT || todo.type === TodoType.FINANCIAL_STATEMENT)
			) {
				const rules = this._splitTodoRules(todo.data);
				rules.forEach((rule) => {
					if (rule.id === financialYear.id) {
						result.push(rule);
					}
				});
			}
		});

		return result.length > 0;
	}

	/**
	 *
	 * @param company
	 * @param financialYear
	 */
	hasVATStatementTodo(company, financialYear) {
		const result = [];
		this.todos.forEach((todo) => {
			if (todo.id === company.id && todo.type === TodoType.VAT) {
				todo.data.forEach((rule) => {
					if (rule.id === financialYear.id) {
						result.push(rule);
					}
				});
			}
		});

		return result;
	}

	/**
	 * Returns if the User has a Declaration todo for the specified year
	 *
	 * @param year
	 * @returns {boolean}
	 */
	hasAnnualStatementTodo(year) {
		let result = false;

		this.todos.forEach((todo) => {
			if (todo.type === TodoType.ANNUAL_DECLERATION) {
				todo.data.forEach((rule) => {
					if (rule.year === year) {
						result = true;
					}
				});
			}
		});

		return result;
	}

	/**
	 * Returns an Array with all years with an ANNUAL_DECLERATION todo
	 * @returns {Array}
	 */
	getAllAnnualStatementTodoYears() {
		const result = [];

		this.todos.forEach((todo) => {
			if (todo.type === TodoType.ANNUAL_DECLERATION) {
				todo.data.forEach((rule) => {
					result.push(rule.year);
				});
			}
		});

		return result;
	}

	/**
	 * Returns if the Comapny has Financial Statement todo for the speified financialYear
	 *
	 * @param company
	 * @param financialYear
	 * @returns {boolean}
	 */
	hasFinancialStatementTodo(company, financialYear) {
		let result = false;

		this.todos.forEach((todo) => {
			if (todo.id === company.id && todo.type === TodoType.FINANCIAL_STATEMENT) {
				const rules = this._splitTodoRules(todo);
				rules.forEach((rule) => {
					if (rule.data[0].id === financialYear.id) {
						result = true;
					}
				});
			}
		});

		return result;
	}

	/**
	 * Count
	 *
	 * @returns {number}
	 */
	getTotalTodos() {
		let count = 0;
		this.todos.forEach((todo) => {
			switch (todo.type) {
				case TodoType.USER:
				case TodoType.COMPANY:
					if (!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
						count++;
					}
					break;
				case TodoType.VAT:
					if (todo.id != null) {
						count += this._splitTodoRules(todo).length;
					}
					break;
				case TodoType.FINANCIAL_STATEMENT:
					if (!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
						if (todo.id != null) {
							count += this._splitTodoRules(todo).length;
						}
					}
					break;
				case TodoType.ANNUAL_DECLERATION:
					if (!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
						count += this._splitTodoRules(todo).length;
					}
					break;
				default:
					break;
			}
		});

		return count;
	}

	/**
	 * Returns the amount of 'new' to-do's
	 * @returns {boolean}
	 */
	hasNewTodos() {
		// Wait until fetching is done and we have at least fetched once
		if (!this.hasFetched || this.fetching) {
			return false;
		}

		// Check old to-do's in previous
		const remove = this.previousTodos.map((todo) => {
			if (!this._existInTodos(todo)) {
				return todo;
			}
			return null;
		});

		// Remove old todos from previous
		remove.forEach((todo) => {
			this._removeFromPrevious(todo);
		});

		return !this._containsAllTodos(this.todos, this.previousTodos);
	}

	/**
	 * Any 'new' todos will ben set to seen and no longer contribute to 'new' todos
	 */
	seenTodos() {
		// JSON conversion to remove ObservableArray objects from todos
		this.previousTodos = JSON.parse(JSON.stringify(this.todos.concat([])));

		// Save previousTodos
		CookieController.setCookie(COOKIE_TYPES.TODOS_COOKIE_ID, this.previousTodos);

		// Force update triggers
		this.todos = this.todos.concat([]);
	}

	/**
	 *
	 * @param todo
	 * @private
	 */
	_existInTodos(todo) {
		let exists = false;
		this.todos.forEach((todo2) => {
			if (this._isSameTodo(todo, todo2)) {
				exists = true;
			}
		});

		return exists;
	}

	/**
	 *
	 * @param todo
	 * @private
	 */
	_removeFromPrevious(todo) {
		if (!todo) {
			return;
		}

		// Find
		let index = -1;

		this.previousTodos.forEach((todo2, i) => {
			if (this._isSameTodo(todo, todo2)) {
				index = i;
			}
		});

		// Remove
		if (index >= 0) {
			this.previousTodos.splice(index, 1);
		}

		// Update previous to cookie
		CookieController.setCookie(COOKIE_TYPES.TODOS_COOKIE_ID, this.previousTodos);
	}

	/**
	 *
	 * @param todo1
	 * @param todo2
	 * @return {boolean}
	 * @private
	 */
	_isSameTodo(todo1, todo2) {
		let result = false;
		switch (todo1.type) {
			case TodoType.USER:
				result = todo2.type === TodoType.USER;
				break;
			case TodoType.COMPANY:
				result = todo2.type === TodoType.COMPANY && todo1.id === todo2.id;
				break;
			case TodoType.VAT:
			case TodoType.FINANCIAL_STATEMENT:
			case TodoType.ANNUAL_DECLERATION:
			default:
				result = JSON.stringify(todo1).localeCompare(JSON.stringify(todo2)) === 0;
		}

		return result;
	}

	/**
	 *
	 * @param todos1 The array with todos to check against todos2
	 * @param todos2 The array with the todos to check
	 * @return {boolean}
	 * @private
	 */
	_containsAllTodos(todos1, todos2) {
		for (let i = 0; i < todos1.length; i++) {
			let found = false;
			for (let j = 0; j < todos2.length; j++) {
				if (this._isSameTodo(todos1[i], todos2[j])) {
					found = true;
					break;
				}
			}

			if (!found) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Get value based on todo type, used for sorting
	 *
	 * @param type
	 * @returns {number}
	 * @private
	 */
	_getTodoWeight(type) {
		switch (type) {
			case TodoType.USER:
				return 1;
			case TodoType.COMPANY:
				return 2;
			case TodoType.VAT:
				return 3;
			case TodoType.FINANCIAL_STATEMENT:
				return 4;
			case TodoType.ANNUAL_DECLERATION:
				return 5;
			default:
				return 6;
		}
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		return this._getTodoWeight(a.type) - this._getTodoWeight(b.type);
	}

	/**
	 * Create new Todo Objects for each rule in the data attribute
	 *
	 * @param todo
	 * @private
	 */
	_splitTodoRules(todo) {
		const result = [];
		todo.data.forEach((todoRule) => {
			if (todoRule.id != null || todo.type === TodoType.ANNUAL_DECLERATION) {
				const newTodo = {};

				// Clone basic data, except for data attribute
				for (const key in todo) {
					if (todo.hasOwnProperty(key)) {
						if (key !== 'data') {
							newTodo[key] = todo[key];
						}
					}
				}

				// Create data attribute with single todoRule
				newTodo.data = [todoRule];

				result.push(newTodo);
			}
		});

		return result;
	}
}
