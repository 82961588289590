import { observable, action } from 'mobx';

import CompanyLatestInvoiceNumber from './models/CompanyLatestInvoiceNumber';

/**
 *
 */
export default class CompanyLatestInvoiceNumberStore {
	@observable companyLatestInvoiceNumbers = [];

	/**
	 *
	 */
	reset() {
		this.companyLatestInvoiceNumbers = [];
	}

	/**
	 *
	 * @param response
	 */
	@action update(response) {
		// Reset values
		this.companyLatestInvoiceNumbers = [];

		// Parse data
		for (const year in response) {
			if (response.hasOwnProperty(year)) {
				const companyLatestInvoiceNumber = new CompanyLatestInvoiceNumber(
					year,
					response[year].latestInvoiceNr,
					response[year].suggestedNextInvoiceNr
				);
				this.companyLatestInvoiceNumbers.push(companyLatestInvoiceNumber);
			}
		}

		// Sort by year
		this.companyLatestInvoiceNumbers.sort((a, b) => {
			return a.year - b.year;
		});
	}

	/**
	 * @param year
	 * @return {CompanyLatestInvoiceNumber|null}
	 */
	getLatestInvoiceNumber(year = null) {
		if (year) {
			let companyInvoiceNumber = null;
			this.companyLatestInvoiceNumbers.forEach((invoiceNumber) => {
				if (invoiceNumber.year === year) {
					companyInvoiceNumber = invoiceNumber;
				}
			});

			if (companyInvoiceNumber) {
				return companyInvoiceNumber;
			}
		}

		if (this.companyLatestInvoiceNumbers.length > 0) {
			return this.companyLatestInvoiceNumbers[this.companyLatestInvoiceNumbers.length - 1];
		}

		return null;
	}
}
