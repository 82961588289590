import React from 'react';
import PropTypes from 'prop-types';
import MollieConnectSVG from './mollie_connect.svg';

/**
 *
 */
export default class MollieConnectButton extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { onClick } = this.props;
		return (
			<div className="mollie-connect-button" onClick={onClick}>
				<img src={MollieConnectSVG} title="Mollie connect" alt="Mollie connect" />
			</div>
		);
	}
}

MollieConnectButton.propTypes = {
	onClick: PropTypes.func.isRequired
};

MollieConnectButton.defaultProps = {
	// eslint-disable-next-line react/default-props-match-prop-types
	onClick: () => {}
};
