import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { RouteUtil } from '../../../data/Routes';

/**
 *
 */
class MenuItem extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menuItem = null;
	}

	/**
	 *
	 */
	componentWillMount() {}

	/**
	 *
	 */
	componentDidMount() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.props.hidden) {
			return null;
		}

		const currentRoute = RouteUtil.getRoute(this.props.location.pathname);

		if (!currentRoute) {
			return null;
		}

		const currentParams = currentRoute.getVariables(this.props.location.pathname);
		const selected = this.props.matchExact
			? currentRoute.getPath(currentParams) === this.props.route
			: currentRoute.containsRoute(this.props.routeToMatch);

		let classes = classNames({
			'menu-item--selected': selected
		});

		let contentClasses = '';

		// Add icon
		if (this.props.iconName) contentClasses += ` icon icon--left icon--${this.props.iconName}`;

		if (this.props.className) {
			classes += ` ${this.props.className}`;
		}

		return (
			<Link
				className={this.props.disabled ? 'menu-item--disabled' : null}
				// eslint-disable-next-line react/no-unused-class-component-methods
				ref={(menuItem) => (this.menuItem = menuItem)}
				to={this.props.route}>
				<li className={`menu-item ${classes}`}>
					<div className={`menu-item__content ${contentClasses}`} id={this.props.id}>
						{this.props.children}
					</div>
				</li>
			</Link>
		);
	}
}

MenuItem.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	}),
	className: PropTypes.string,
	route: PropTypes.string.isRequired,
	routeToMatch: PropTypes.object.isRequired,
	matchExact: PropTypes.bool,
	iconName: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
	hidden: PropTypes.bool,
	id: PropTypes.string
};

export default withRouter(MenuItem);
