/* eslint-disable no-case-declarations */
/* eslint-disable no-bitwise */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { compressMimeType } from '../../../utils/fileUtils';

import DraftTypes from '../../../data/DraftTypes';
import DraftUploadWorker, {
	DRAFT_WORKER_STATE,
	DRAFT_UPLOAD_VALIDATION_ERRORS
} from '../../../stores/models/DraftUploadWorker';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import IncomeModel from '../../../stores/models/IncomeModel';
import Signals from '../../../signals/Signals';
import IncomeInputPanel from '../../../pages/Company/Income/components/modals/IncomeInputPanel';
import ExpenseModel from '../../../stores/models/ExpenseModel';
import ExpenseInputPanel from '../../../pages/Company/Expenses/components/modals/ExpenseInputPanel';
import { uniqueKey } from '../../../utils/ReactUtils';

/**
 *
 */
@observer
export default class DraftFileUpload extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onCancel = this.onCancel.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onDraftDeleted = this.onDraftDeleted.bind(this);

		this.state = { draftRemoved: false };
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.DraftDeleted.add(this.onDraftDeleted);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.DraftDeleted.remove(this.onDraftDeleted);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { draftUploadWorker } = this.props;
		const { draftRemoved } = this.state;
		const { file } = draftUploadWorker;

		const classes = classNames({
			'draft-file-upload': true,

			'draft-file-upload--removed': draftRemoved,
			'draft-file-upload--waiting':
				this.props.draftUploadWorker.state === DRAFT_WORKER_STATE.WAITING,
			'draft-file-upload--started':
				this.props.draftUploadWorker.state === DRAFT_WORKER_STATE.STARTED,
			'draft-file-upload--failed': this.props.draftUploadWorker.state === DRAFT_WORKER_STATE.FAILED,
			'draft-file-upload--success':
				this.props.draftUploadWorker.state === DRAFT_WORKER_STATE.SUCCESS,

			'draft-file-upload--validation-error':
				this.props.draftUploadWorker.validationErrors & DRAFT_UPLOAD_VALIDATION_ERRORS.SIZE ||
				this.props.draftUploadWorker.validationErrors & DRAFT_UPLOAD_VALIDATION_ERRORS.TYPE,
			'draft-file-upload--validation-size':
				this.props.draftUploadWorker.validationErrors & DRAFT_UPLOAD_VALIDATION_ERRORS.SIZE,
			'draft-file-upload--validation-type':
				this.props.draftUploadWorker.validationErrors & DRAFT_UPLOAD_VALIDATION_ERRORS.TYPE
		});

		return (
			<div className={classes}>
				<div className="draft-file-upload__row" onClick={this.onEdit}>
					<div className="draft-file-upload__icon">{this._getIconOrProgress()}</div>
					<span className="draft-file-upload__filename" title={file.name}>
						{file.name}
					</span>
					<span className="draft-file-upload__mime-type">{compressMimeType(file.type)}</span>
					<span className="draft-file-upload__document-type">
						&nbsp;- <FormattedMessage id={`draft.type.${draftUploadWorker.type.name}`} />
					</span>
					<div
						className="draft-file-upload__close icon icon icon--close-black"
						onClick={this.onCancel}
					/>
					<div className="draft-file-upload__edit icon icon icon--chevron-right-black" />
				</div>
				<div className="draft-file-upload__error-container">
					<span className="draft-file-upload__error draft-file-upload__error__size">
						<FormattedMessage id="error.file.size" />
					</span>
					<span className="draft-file-upload__error draft-file-upload__error__type">
						<FormattedMessage id="error.file.type" />
					</span>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onCancel() {
		this.props.draftUploadWorker.cancelAndRemove();
	}

	/**
	 *
	 */
	onEdit() {
		const { draftUploadWorker } = this.props;
		const { draft } = draftUploadWorker;

		// Only allow when successfully uploaded
		if (draftUploadWorker.state !== DRAFT_WORKER_STATE.SUCCESS) {
			return;
		}

		switch (draft.type) {
			case DraftTypes.EXPENSE.name:
				// Create copy to prevent unwanted changes to original
				const expenseModel = ExpenseModel.createNewExpenseFromDraft(draft);

				// Show overlay
				Signals.ShowOverlay.dispatch(
					<ExpenseInputPanel key={uniqueKey('eip-')} expenseModel={expenseModel} draft={draft} />
				);
				break;
			case DraftTypes.INCOME.name:
				// Create copy to prevent unwanted changes to original
				const incomeModel = IncomeModel.createNewIncomeFromDraft(draft);

				// Show overlay
				Signals.ShowOverlay.dispatch(
					<IncomeInputPanel key={uniqueKey('iip-')} incomeModel={incomeModel} draft={draft} />
				);
				break;
			case DraftTypes.DOCUMENT.name:
				// TODO:
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param draft
	 */
	onDraftDeleted(draft) {
		const { draftUploadWorker } = this.props;
		const workerDraft = draftUploadWorker.getDraft();

		if (draft === workerDraft) {
			this.setState({ draftRemoved: true });
		}
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getIconOrProgress() {
		const { progress, state } = this.props.draftUploadWorker;

		switch (state) {
			case DRAFT_WORKER_STATE.WAITING:
			case DRAFT_WORKER_STATE.STARTED:
				return <ProgressCircle percentage={progress} />;
			case DRAFT_WORKER_STATE.SUCCESS:
				return <div className="icon icon--check-success" />;
			case DRAFT_WORKER_STATE.FAILED:
				return <div className="icon icon--close-danger" />;
			default:
				return null;
		}
	}
}

DraftFileUpload.propTypes = {
	draftUploadWorker: PropTypes.instanceOf(DraftUploadWorker).isRequired
};
