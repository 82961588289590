import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ToggleButtonOption from './ToggleButtonOption';
import Signals from '../../../signals/Signals';

/**
 *
 */
export default class ToggleButton extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { expanded: false };
		this.buttonRef = null;
		this.optionsRef = null;

		this.onResize = this.onResize.bind(this);
		this.onOptionClick = this.onOptionClick.bind(this);
		this.toggle = this.toggle.bind(this);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.setMaxWidth();
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(_prevProps, _prevState, _snapshot) {
		this.setMaxWidth();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.RootClick.remove(this.close);
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'toggle-button': true,
			'toggle-button--expanded': this.state.expanded
		});

		const buttonClassObject = {};
		buttonClassObject['icon icon--right icon--color icon--chevron-down'] = !this.state.expanded;
		buttonClassObject['icon icon--right icon--color icon--chevron-up'] = this.state.expanded;
		buttonClassObject[this.props.buttonClassName] = !!this.props.buttonClassName;

		const buttonClasses = classNames(buttonClassObject);

		return (
			<div className={`${classes} ${this.props.className}`}>
				<button
					type="button"
					ref={(ref) => {
						this.buttonRef = ref;
					}}
					className={`${buttonClasses} ${this.props.buttonClassName}`}
					onClick={this.toggle}>
					{this.props.label}
				</button>
				<div
					ref={(ref) => {
						this.optionsRef = ref;
					}}
					className="toggle-button__options">
					{this.generateOptions()}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.setMaxWidth();
	}

	/**
	 *
	 * @param e
	 */
	onOptionClick(e) {
		this.close();
		if (this.props.onChange) {
			this.props.onChange(e.currentTarget.dataset.value);
		}
	}

	/**
	 *
	 * @param e
	 */
	toggle(e) {
		e.preventDefault();

		if (!this.state.expanded) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		this.setState({ expanded: true }, () => {
			Signals.RootClick.add(this.close);
		});
	}

	/**
	 *
	 */
	close() {
		Signals.RootClick.remove(this.close);
		this.setState({ expanded: false });
	}

	/**
	 *
	 * @return {any[]}
	 */
	generateOptions() {
		return this.props.options.map((option, index) => {
			if (option.hidden) {
				return null;
			}

			return (
				<div
					key={`tbo-${index}`}
					className={`toggle-button-option border--top ${option.classes}`}
					data-value={option.value}
					data-disabled={option.disabled}
					onClick={this.onOptionClick}>
					<span className="text--overflow-ellipsis">{option.label}</span>
				</div>
			);
		});
	}

	/**
	 *
	 */
	setMaxWidth() {
		//
		if (!this.optionsRef || !this.optionsRef.children) {
			return;
		}

		// Gather elements, including toggle button button
		const elements = [this.buttonRef];
		let minWidth = 0;
		let i;

		for (i = 0; i < this.optionsRef.children.length; i++) {
			elements.push(this.optionsRef.children[i]);
		}

		// Clear min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			el.style['min-width'] = null;
		}

		// Determine min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			const width = el.offsetWidth;
			minWidth = width > minWidth ? width : minWidth;
		}

		// Set min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			if (el.tagName === 'button') {
				el.style['min-width'] = `${minWidth + 2}px`; // To cancel out border from options
			} else {
				el.style['min-width'] = `${minWidth}px`;
			}
		}
	}
}

ToggleButton.propTypes = {
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.instanceOf(ToggleButtonOption)).isRequired,
	onChange: PropTypes.func
};

ToggleButton.defaultProps = {
	className: '',
	buttonClassName: '',
	// eslint-disable-next-line react/default-props-match-prop-types
	value: null
};
