// Replace dynamic values
export const dynamicValueTypes = {
	USER: 'user',
	COMPANY: 'company',
	CLIENT: 'client',
	INVOICE: 'invoice'
};

const dynamicValues = {
	CLIENT_NAME: {
		tag: '{CONTACT_NAME}',
		labelId: 'invoice.dynamic.value.client_name.label',
		translationTag: '{client.contactPersonName}',
		type: dynamicValueTypes.CLIENT
	},
	CLIENT_COMPANY: {
		tag: '{CLIENT_COMPANY}',
		labelId: 'invoice.dynamic.value.client_company.label',
		translationTag: '{client.company}',
		type: dynamicValueTypes.CLIENT
	},
	INVOICE_NR: {
		tag: '{INVOICE_NR}',
		labelId: 'invoice.dynamic.value.invoice_nr.label',
		translationTag: '{invoice.invoiceNr}',
		type: dynamicValueTypes.INVOICE
	},
	INVOICE_SUBJECT: {
		tag: '{INVOICE_SUBJECT}',
		labelId: 'invoice.dynamic.value.invoice_subject.label',
		translationTag: '{invoice.subject}',
		type: dynamicValueTypes.INVOICE
	},
	INVOICE_ISSUE_DATE: {
		tag: '{INVOICE_ISSUE_DATE}',
		labelId: 'invoice.dynamic.value.invoice_date.label',
		translationTag: '{nvoice.date}',
		type: dynamicValueTypes.INVOICE
	},
	INVOICE_EXPIRATION_DATE: {
		tag: '{INVOICE_EXPIRATION_DATE}',
		labelId: 'invoice.dynamic.value.invoice_expiration_date.label',
		translationTag: '{invoice.expirationDate}',
		type: dynamicValueTypes.INVOICE
	},
	INVOICE_PO_NR: {
		tag: '{INVOICE_PO_NR}',
		labelId: 'invoice.dynamic.value.invoice_po_nr.label',
		translationTag: '{invoice.poNr}',
		type: dynamicValueTypes.INVOICE
	},
	INVOICE_AMOUNT_TOTAL: {
		tag: '{INVOICE_AMOUNT_TOTAL}',
		labelId: 'invoice.dynamic.value.invoice_amount_total.label',
		translationTag: '{invoice.amountTotal}',
		type: dynamicValueTypes.INVOICE
	},
	COMPANY_NAME: {
		tag: '{MY_COMPANY_NAME}',
		labelId: 'invoice.dynamic.value.company_name.label',
		translationTag: '{company.name}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_IBAN: {
		tag: '{MY_COMPANY_IBAN}',
		labelId: 'invoice.dynamic.value.company_iban.label',
		translationTag: '{company.iban}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_BTW: {
		tag: '{MY_COMPANY_BTW}',
		labelId: 'invoice.dynamic.value.company_btw.label',
		translationTag: '{company.btw}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_KVK: {
		tag: '{MY_COMPANY_KVK}',
		labelId: 'invoice.dynamic.value.company_kvk.label',
		translationTag: '{company.kvk}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_WEBSITE: {
		tag: '{MY_COMPANY_WEBSITE}',
		labelId: 'invoice.dynamic.value.company_website.label',
		translationTag: '{company.website}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_PHONE: {
		tag: '{MY_COMPANY_PHONE}',
		labelId: 'invoice.dynamic.value.company_phone.label',
		translationTag: '{company.phoneNumber}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_CITY: {
		tag: '{MY_COMPANY_CITY}',
		labelId: 'invoice.dynamic.value.company_city.label',
		translationTag: '{company.city}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_ADDRESS: {
		tag: '{MY_COMPANY_ADDRESS}',
		labelId: 'invoice.dynamic.value.company_address.label',
		translationTag: '{company.address}',
		type: dynamicValueTypes.COMPANY
	},
	COMPANY_POSTALCODE: {
		tag: '{MY_COMPANY_POSTALCODE}',
		labelId: 'invoice.dynamic.value.company_postalcode.label',
		translationTag: '{company.postalCode}',
		type: dynamicValueTypes.COMPANY
	},
	USER_FIRSTNAME: {
		tag: '{MY_FIRSTNAME}',
		labelId: 'invoice.dynamic.value.user_firstname.label',
		translationTag: '{user.firstName}',
		type: dynamicValueTypes.USER
	},
	USER_LASTNAME: {
		tag: '{MY_LASTNAME}',
		labelId: 'invoice.dynamic.value.user_lastname.label',
		translationTag: '{user.lastName}',
		type: dynamicValueTypes.USER
	}
};

export default dynamicValues;

/**
 *
 * @param user
 * @param company
 * @param invoiceConceptModel
 * @param intl
 */
export function createDynamicValues(user, company, invoiceConceptModel, intl) {
	const values = {};

	values[dynamicValues.CLIENT_NAME.translationTag] =
		invoiceConceptModel.companyCustomer.contactPersonName;
	values[dynamicValues.CLIENT_COMPANY.translationTag] =
		invoiceConceptModel.companyCustomer.getCustomerName();

	values[dynamicValues.INVOICE_NR.translationTag] = invoiceConceptModel.invoiceNr;
	values[dynamicValues.INVOICE_SUBJECT.translationTag] = invoiceConceptModel.subject;
	values[dynamicValues.INVOICE_ISSUE_DATE.translationTag] = intl.formatDate(
		invoiceConceptModel.date
	);
	values[dynamicValues.INVOICE_EXPIRATION_DATE.translationTag] = intl.formatDate(
		invoiceConceptModel.expirationDate
	);
	values[dynamicValues.INVOICE_PO_NR.translationTag] = invoiceConceptModel.projectcode;
	values[dynamicValues.INVOICE_AMOUNT_TOTAL.translationTag] = intl.formatNumber(
		invoiceConceptModel.getTotal(),
		{ style: 'currency', currency: 'EUR' }
	);

	values[dynamicValues.COMPANY_NAME.translationTag] = company.name;
	values[dynamicValues.COMPANY_IBAN.translationTag] = company.iban;

	if (company.useBTWId(invoiceConceptModel.date)) {
		values[dynamicValues.COMPANY_BTW.translationTag] = company.vatId;
	} else {
		values[dynamicValues.COMPANY_BTW.translationTag] = company.vatNumber;
	}

	values[dynamicValues.COMPANY_KVK.translationTag] = company.kvkNumber;
	values[dynamicValues.COMPANY_WEBSITE.translationTag] = company.website;
	values[dynamicValues.COMPANY_PHONE.translationTag] = company.phoneNumber;
	values[dynamicValues.COMPANY_CITY.translationTag] = company.address.city;
	values[dynamicValues.COMPANY_ADDRESS.translationTag] = company.address.address;
	values[dynamicValues.COMPANY_POSTALCODE.translationTag] = company.address.postalCode;

	values[dynamicValues.USER_FIRSTNAME.translationTag] = user.firstName;
	values[dynamicValues.USER_LASTNAME.translationTag] = user.lastName;

	return values;
}

/**
 *
 * @param text
 * @param values
 * @param intl
 * @param showUnmatchedTags
 *
 * @returns {*}
 * @private
 */
export function addDynamicValues(text, values, intl, showUnmatchedTags = false) {
	// Convert translation object to String
	if (typeof text === 'object') {
		text = intl.formatMessage(text);
	}

	const dynamicValuesArray = Object.keys(dynamicValues).map((key) => dynamicValues[key]);

	for (const key in values) {
		if (values.hasOwnProperty(key)) {
			console.log('key', key);
			const value = values[key]
				? values[key]
				: showUnmatchedTags
				? dynamicValuesArray.find((dynamicValue) => {
						return key === dynamicValue.translationTag;
				  }).tag
				: ''; // filter out undefined or null, or keep tag
			text = text.split(`${key}`).join(value);
		}
	}

	return text;
}

/**
 *
 * @param value
 * @private
 */
export function convertTranslationsToTags(value) {
	for (const key in dynamicValues) {
		if (dynamicValues.hasOwnProperty(key)) {
			value = value.split(dynamicValues[key].translationTag).join(dynamicValues[key].tag);
		}
	}
	return value;
}

/**
 *
 * @return {*}
 * @private
 */
export function convertTagsToTranslations(value) {
	if (typeof value !== 'string') {
		return value;
	}

	for (const key in dynamicValues) {
		if (dynamicValues.hasOwnProperty(key)) {
			value = value.split(dynamicValues[key].tag).join(dynamicValues[key].translationTag);
		}
	}
	return value;
}
