import React from 'react';
import AbstractCommand from '../AbstractCommand';
import SendTypes from '../../data/SendTypes';
import Signals from '../../signals/Signals';

import offerUpdateMarkAsSend from '../../requests/offers/offerUpdateMarkAsSend';
import offerUpdateSendEmail from '../../requests/offers/offerUpdateSendEmail';
import offerSendReminder from '../../requests/offers/offerSendReminder';

import {FormattedHTMLMessage} from 'react-intl';
import OfferStatus from '../../data/OfferStatus';

const OFFER_STATE_KEYS = [
	'senderEmail',
	'recipientEmail',
	'ccRecipientEmail',
	'bccRecipientEmail',
	'emailSubject',
	'emailMessage',
	'remindCompanyUserInDays',
	'remindCompanyCustomerInDays'
];

/**
 *
 */
export default class SendOfferCommand extends AbstractCommand {

	/**
	 *
	 * @param applicationStore ApplicationStore
	 * @param offerModel OfferModel
	 * @param company Company
	 * @param data object
	 * @param sendType string
	 */
	constructor(applicationStore, offerModel, company, data, sendType) {
		super();

		this.applicationStore = applicationStore;
		this.offerModel = offerModel;
		this.company = company;
		this.data = data;
		this.sendType = sendType;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		let request = null;
		let requestBody = {};

		switch (this.sendType) {
			case SendTypes.TYPE_MARKED_AS_SEND:
				request = offerUpdateMarkAsSend;
				requestBody = this._createJSONData();
				break;
			case SendTypes.TYPE_SEND_EMAIL:
				request = offerUpdateSendEmail;
				requestBody = this._createJSONData();
				break;
			case SendTypes.TYPE_SEND_REMINDER:
				request = offerSendReminder;
				requestBody = {
					subject: this.data.reminderSubject,
					message: this.data.reminderMessage,
					to: this.data.recipientEmail
				};
				break;
		}

		if (request) {
			request(this.company.id, this.offerModel.id, JSON.stringify(requestBody))
				.then((response) => {
					this._onSuccess();
					if (success) {
						success(response);
					}
				})
				.catch((err) => {
					if (error) {
						error(err);
					}
				});
		}

	}

	/**
	 *
	 * @private
	 */
	_onSuccess() {
		// Show status message
		let messageId;
		switch (this.sendType) {
			case SendTypes.TYPE_SEND_EMAIL:
				this.offerModel.status = OfferStatus.SENT;
				messageId = 'offer.sendemail.message';
				break;
			case SendTypes.TYPE_SEND_REMINDER:
				this.offerModel.status = OfferStatus.SENT_REMINDER;
				messageId = 'offer.sendreminder.message';
				break;
			case SendTypes.TYPE_MARKED_AS_SEND:
				this.offerModel.status = OfferStatus.SENT_MANUALLY;
				messageId = 'offer.markedassend.message';
				break;
		}

		if (messageId) {
			Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id={messageId} values={{
				offerNr: this.offerModel.offerNr,
				email: this.data.recipientEmail}}/>));
		}
	}

	/**
	 *
	 * @returns {string}
	 * @private
	 */
	_createJSONData() {

		// Get initial OfferModel state
		const jsonData = JSON.parse(this.offerModel.toJSON());

		// Add changed state keys, filter out state keys which are not in OFFER_STATE_KEYS
		OFFER_STATE_KEYS.forEach((key) => {

				switch(key) {
					case 'remindCompanyUserInDays':
						// Ignore `remindCompanyUserInDays` if no ExpirationDate has been set
						if (this.offerModel.hasExpirationDate()) {
							jsonData[key] = this.data[key];
						} else {
							jsonData[key] = null;
						}
						break;
					default:
						jsonData[key] = this.data[key] ? this.data[key] : undefined;
				}
		});

		return jsonData;
	}
}
