import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import User from '../../../stores/models/User';
import Company from '../../../stores/models/Company';
import CommunityProfile from '../../../stores/models/CommunityProfile';

import NotificationNumber from '../NotificationNumber/NotificationNumber';
import { ApplicationContext } from '../../../ApplicationContext';
import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';
import ProfileAvatarPlaceholder from './ProfileAvatarPlaceholder';
import { getShortName } from '../../../utils/StringUtils';

/**
 *
 */
class ProfileItem extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.onSettingsClick = this.onSettingsClick.bind(this);
	}

	/**
	 *
	 */
	render() {
		const { applicationStore } = this.context;

		if (!applicationStore.isLoggedIn) {
			return null;
		}

		const { hideSettings, userCompany, todoPosition, todoCount, className, intl } = this.props;
		let companyIndex = -1;
		let name;
		let image;
		let logoAsset;
		let showCustom = false;
		let isUser = false;

		if (userCompany instanceof User) {
			name = intl.formatMessage({ id: 'profile.personal' }); // userCompany.getFullName();
			image = `${window.config.imageRoot}KdB_lamp_on.svg`;
			isUser = true;
		}

		if (userCompany instanceof Company) {
			companyIndex = applicationStore.user.getCompanyIndex(userCompany);
			name = userCompany.name;
			logoAsset = CommunityProfile.buildAsset(
				null,
				userCompany.communityProfile.logoAssetUuid,
				80,
				80
			);
			showCustom = !!userCompany.communityProfile.logoAssetUuid;
			image = showCustom ? logoAsset : `${window.config.imageRoot}KdB_rocket_on.svg`;
		}

		const todoCountLeftClasses = classNames({
			'notification-number--hidden': todoPosition !== 'left'
		});
		const imageClasses = classNames({
			'profile-item__image': true,
			'profile-item__image--custom': showCustom
		});

		return (
			<div className={`profile-item grid ${className}`}>
				{showCustom || isUser ? (
					<div className={imageClasses} style={{ backgroundImage: `url("${image}")` }}>
						<NotificationNumber
							className={`notification-number--top-right ${todoCountLeftClasses}`}
							count={todoCount}
						/>
					</div>
				) : (
					<ProfileAvatarPlaceholder index={companyIndex} label={getShortName(name)} />
				)}

				<div className="profile-item__labels">
					<div className="profile-item__name">{name}</div>
				</div>

				{!hideSettings ? (
					<div className="profile-item__settings-wrapper">
						<div className="profile-item__settings" onClick={this.onSettingsClick}>
							<span className="icon icon--left icon--gear-smoke" />
						</div>
					</div>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onSettingsClick(e) {
		e.preventDefault();

		const { userCompany } = this.props;

		if (userCompany instanceof User) {
			Signals.RequestRoute.dispatch(Routes.USER_SETTINGS_INFO.getPath({}));
		}

		if (userCompany instanceof Company) {
			const { id } = userCompany;
			Signals.RequestRoute.dispatch(Routes.COMPANY_SETTINGS_INFO.getPath({ id }));
		}
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onLogout() {
		this.context.applicationStore.logout();
	}
}

ProfileItem.propTypes = {
	className: PropTypes.string,
	userCompany: PropTypes.object,
	todoCount: PropTypes.number,
	todoPosition: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	typeLabel: PropTypes.string,
	hideSettings: PropTypes.bool,
	intl: PropTypes.object
};

ProfileItem.contextType = ApplicationContext;

ProfileItem.defaultProps = {
	className: '',
	todoCount: 0,
	todoPosition: 'right'
};

export default injectIntl(ProfileItem);
