import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SignaturePad from 'signature_pad';
import SignOfferModel from '../../../stores/models/SignOfferModel';

/**
 *
 */
export default class Signature extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.canvas = null;
		this.signaturePad = null;

		this.state = {};

		this.onResize = this.onResize.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.signaturePad = new SignaturePad(this.canvas, {
			penColor: '#40525e',
			maxWidth: 2
		});

		this.props.signOfferModel.signaturePadReference = this.signaturePad;

		this.onResize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextState
	 * @param nextContext
	 */
	componentWillUpdate() {
		this.onResize();
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {
		this.onResize();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			signature: true
		});

		return (
			<div className={`${classes} ${this.props.className}`}>
				<canvas
					ref={(ref) => {
						this.canvas = ref;
						this.onResize();
					}}
				/>
			</div>
		);
	}

	/**
	 *
	 */
	onResize() {
		console.log('Signature.onResize', this.canvas);
		if (this.canvas) {
			const ratio = 2;

			console.log('\tclientWidth', this.canvas.clientWidth);

			this.canvas.width = this.canvas.clientWidth * ratio;
			this.canvas.height = this.canvas.clientWidth * 0.2608 * ratio;
			this.canvas.getContext('2d').scale(ratio, ratio);
		}
	}
}

Signature.propTypes = {
	className: PropTypes.string,
	signOfferModel: PropTypes.instanceOf(SignOfferModel).isRequired
};

Signature.defaultProps = {
	className: ''
};
