/* eslint-disable no-eval */
import React from 'react';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';

import classNames from 'classnames';
import { _addZeros } from '../../../utils/objectToFormData';
import WalkthroughStep, { WALKTHROUGH_ARROW } from '../../../stores/models/WalkthroughStep';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';
import Signals from '../../../signals/Signals';
import ScrollController from '../../../controllers/ScrollController';
import ModalConfirm from '../Modal/ModalConfirm';

/**
 *
 */
class WalkthroughPopup extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.screenPadding = 16;
		this.el = null;
		this.arrow = null;
		this.target = null;

		this.state = { shown: false };

		this.onNext = this.onNext.bind(this);
		this.onPrevious = this.onPrevious.bind(this);
		this.onPause = this.onPause.bind(this);
		this.onResize = this.onResize.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const { walkthroughStep } = this.props;

		setTimeout(() => {
			if (walkthroughStep.targetPreScript) {
				eval(walkthroughStep.targetPreScript);
			}

			if (walkthroughStep.scroll > 0) {
				ScrollController.scrollPageTo(walkthroughStep.scroll);
			}

			setTimeout(
				() => {
					this.setState({ shown: true }, () => {
						this.updatePosition();
					});
				},
				walkthroughStep.scroll > 0 ? 300 : 0
			);
		}, 250);

		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(_prevProps, _prevState, _snapshot) {
		console.log('componentDidUpdate');
		this.updatePosition();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		const { walkthroughStep } = this.props;
		this.removeShadowElement();
		window.removeEventListener('resize', this.onResize);

		setTimeout(() => {
			if (walkthroughStep.targetPostScript) {
				eval(walkthroughStep.targetPostScript);
			}
		}, 250);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { walkthroughStep, walkthroughStore } = this.props;
		const { shown } = this.state;

		const classes = classNames({
			'walkthrough-popup': true,
			'walkthrough-popup--shown': shown,
			'walkthrough-popup--no-target': !walkthroughStep.targetSelector,
			'walkthrough-popup--no-arrow': walkthroughStep.hideArrow,
			'walkthrough-popup--clickable': walkthroughStep.clickable
		});

		const arrowClasses = classNames({
			'walkthrough-popup__arrow': true,
			'walkthrough-popup__arrow--left': walkthroughStep.arrow === WALKTHROUGH_ARROW.LEFT,
			'walkthrough-popup__arrow--right': walkthroughStep.arrow === WALKTHROUGH_ARROW.RIGHT
		});

		return (
			<div
				className={classes}
				ref={(ref) => {
					this.el = ref;
				}}>
				<div className="panel  box-shadow-heavy padding">
					<div
						className={arrowClasses}
						ref={(ref) => {
							this.arrow = ref;
						}}
					/>
					<div className="walkthrough-popup__header">
						<h2 className="walkthrough-popup__title font--title">
							<FormattedMessage id={walkthroughStep.titleId} />
						</h2>
						<span className="icon icon--left icon--close cursor-pointer" onClick={this.onPause} />
					</div>
					<div className="walkthrough-popup__body">
						<div className="walkthrough-popup__graphic" />
						<FormattedHTMLMessage id={walkthroughStep.bodyId} />
					</div>
					<div className="walkthrough-popup__controls">
						<div className="walkthrough-popup__steps">
							{_addZeros(walkthroughStep.step)}/{_addZeros(walkthroughStore.steps.length)}
						</div>

						{/*	Normal start */}
						{walkthroughStore.isFirstStep ? (
							<button type="button" className="walkthrough-popup__start" onClick={this.onNext}>
								<FormattedMessage id="walkthrough.start" />
							</button>
						) : null}

						{/*	Normal done */}
						{walkthroughStore.isLastStep ? (
							<button type="button" className="walkthrough-popup__close" onClick={this.onNext}>
								<FormattedMessage id="walkthrough.close" />
							</button>
						) : null}

						{/*	Normal previous and next */}
						{!(walkthroughStore.isLastStep || walkthroughStore.isFirstStep)
							? [
									<button
										type="button"
										className="walkthrough-popup__previous"
										onClick={this.onPrevious}>
										<FormattedMessage id="walkthrough.previous" />
									</button>,
									<button type="button" className="walkthrough-popup__next" onClick={this.onNext}>
										<FormattedMessage id="walkthrough.next" />
									</button>
							  ]
							: null}
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onNext() {
		const { walkthroughStore } = this.props;
		walkthroughStore.next();
	}

	/**
	 *
	 */
	onPrevious() {
		const { walkthroughStore } = this.props;
		walkthroughStore.previous();
	}

	/**
	 *
	 */
	onPause() {
		const { intl } = this.props;
		Signals.ShowModal.dispatch(
			<ModalConfirm
				icon="icon--info-black"
				title={intl.formatMessage({ id: 'walkthrough.confirm.title' })}
				body={intl.formatMessage({ id: 'walkthrough.confirm.body' })}
				onConfirm={() => {
					const { walkthroughStore } = this.props;
					walkthroughStore.pause();
				}}
				yesLabel={intl.formatMessage({ id: 'walkthrough.confirm.yes' })}
				noLabel={intl.formatMessage({ id: 'walkthrough.confirm.no' })}
			/>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.updatePosition();
	}

	/**
	 *
	 */
	updatePosition() {
		const { targetSelector, walkthroughStep } = this.props;
		this.target = document.querySelector(targetSelector);
		const offsetTop = walkthroughStep.targetOffsetY;
		const offsetLeft = walkthroughStep.targetOffsetX;

		/**
		 *
		 */
		if (walkthroughStep.clickable) {
			this.createShadowElement(this.target);
		}

		try {
			if (this.el && this.arrow) {
				const elBounds = this.el.getBoundingClientRect();
				let px;
				let py;

				// Ideal point for tooltip to 'point' at
				if (this.target) {
					const targetBounds = this.target.getBoundingClientRect();
					px = targetBounds.left + targetBounds.width * 0.5;
					py = targetBounds.bottom + offsetTop; // + Math.round(targetBounds.height * 0.5);

					// Set center of screen
				} else {
					px = Math.round((window.innerWidth - elBounds.width) / 2) + offsetLeft;
					py = Math.round((window.innerHeight - elBounds.height) / 2) + offsetTop;
				}

				// Center tooltip by substracting half its width
				let x = px - elBounds.width * 0.5;

				const curX = x;
				let movedX = 0;
				const rightSide = x + elBounds.width + this.screenPadding;

				if (rightSide > window.innerWidth) {
					x = window.innerWidth - elBounds.width - this.screenPadding;
					movedX = Math.round(x - curX);
				} else if (x - this.screenPadding < 0) {
					x = this.screenPadding;
					movedX = Math.round(x - curX);
				}

				this.arrow.style.left = `calc(50% - ${movedX}px)`;

				// Set position
				this.el.style.top = 0;
				this.el.style.left = 0;
				px = Math.round(x + offsetLeft);
				py = Math.round(py);

				if (walkthroughStep.arrow === WALKTHROUGH_ARROW.LEFT) {
					py -= elBounds.height / 2;
					px += elBounds.width / 2 + 8;

					Signals.WalkthroughUpdatedPosition.dispatch(
						px - 8,
						py + Math.round(elBounds.height / 2) - 9
					);
				}

				if (walkthroughStep.arrow === WALKTHROUGH_ARROW.RIGHT) {
					if (this.target) {
						py -= elBounds.height / 2;
						px -= elBounds.width / 2 + 8;
					} else {
						px -= 8;
					}

					Signals.WalkthroughUpdatedPosition.dispatch(
						px + elBounds.width + 8,
						py + Math.round(elBounds.height / 2) - 9
					);
				}

				if (walkthroughStep.arrow === WALKTHROUGH_ARROW.NORMAL) {
					if (!this.target) {
						px += elBounds.width / 2;
						// py += elBounds.height / 2;
					}

					Signals.WalkthroughUpdatedPosition.dispatch(px + elBounds.width / 2 - movedX, py - 5);
				}

				TweenLite.set(this.el, { x: px, y: py });
			}
		} catch (e) {
			console.log(e);
		}

		this.updateShadowElement(this.target);
	}

	/**
	 *
	 */
	createShadowElement() {
		if (!this.shadowElement) {
			this.shadowElement = document.createElement('div');
			this.shadowElement.classList.add('shadow-element');
			document.querySelector('.walkthrough').appendChild(this.shadowElement);

			this.shadowElement.addEventListener('mouseover', () => {
				this.target.classList.toggle('hover', true);
			});
			this.shadowElement.addEventListener('mouseleave', () => {
				this.target.classList.toggle('hover', false);
			});
			this.shadowElement.addEventListener('click', () => {
				this.onNext();
			});
		}
	}

	/**
	 *
	 * @param target
	 */
	updateShadowElement(target) {
		if (this.shadowElement) {
			const targetBounds = target.getBoundingClientRect();
			this.shadowElement.style.width = `${targetBounds.width}px`;
			this.shadowElement.style.height = `${targetBounds.height}px`;
			this.shadowElement.style.transform = `translate(${targetBounds.left}px,${targetBounds.top}px)`;
		}
	}

	/**
	 *
	 */
	removeShadowElement() {
		if (this.shadowElement) {
			this.shadowElement.parentNode.removeChild(this.shadowElement);
			this.shadowElement = null;

			this.target.classList.toggle('hover', false);
		}
	}
}

WalkthroughPopup.contextType = ApplicationContext;

WalkthroughPopup.propTypes = {
	walkthroughStep: PropTypes.instanceOf(WalkthroughStep),
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore),
	targetSelector: PropTypes.string,
	intl: PropTypes.object.isRequired
};

WalkthroughPopup.defaultProps = {
	targetSelector: null
};

export default injectIntl(WalkthroughPopup);
