import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import InvoiceConceptStatus from '../../data/InvoiceConceptStatus';
import invoiceConceptsArchive from '../../requests/invoiceConceptsArchive';

/**
 *
 */
export default class ArchiveInvoiceConceptsCommand extends AbstractCommand {

	/**
	 *
	 * @param company Company
	 * @param year Number
	 * @param invoicesConceptStore InvoicesConceptStore
	 */
	constructor(company, year, invoicesConceptStore) {
		super();

		this.company = company;
		this.year = year;
		this.invoicesConceptStore = invoicesConceptStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return invoiceConceptsArchive(this.company.id, this.year)
			.then((response) => {
				this.invoicesConceptStore.invoices.forEach((invoiceConcept) => {
					console.log(invoiceConcept, invoiceConcept.date.getFullYear(), invoiceConcept.status, invoiceConcept.date.getFullYear() === this.year && invoiceConcept.status !== InvoiceConceptStatus.DELETED);
					if(invoiceConcept.date.getFullYear() === this.year && invoiceConcept.status !== InvoiceConceptStatus.DELETED) {
						invoiceConcept.status = InvoiceConceptStatus.ARCHIVED;
					}
				});

				this.invoicesConceptStore.update();
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="invoices.archived.message"
								   values={{year: this.year}}/>)
		);
	}
}
