import { observable } from 'mobx';

export default class CommunityCategorie {
	@observable id;

	@observable name;

	constructor(id, name) {
		this.id = id;
		this.name = name;
	}

	getName(lang = 'nl') {
		return this.name[lang];
	}
}
