/* eslint-disable default-case */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import classNames from 'classnames';
import BTWPeriod from '../../../data/BTWPeriod';

import Signals from '../../../signals/Signals';

import TodoType from '../../../data/TodoType';
import { Routes } from '../../../data/Routes';
import CommunityProfile from '../../../stores/models/CommunityProfile';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
class TodoRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @returns {null}
	 */
	componentWillMount() {
		if (!this.context.applicationStore.user) {
			return null;
		}

		this.route = null;
		this.todoTitle = '-';
		this.subTitleElement = null;

		const todo = this.props.todo;

		let translationValues;
		let todoRule;
		let financialYear;
		let company;
		let subTitle;

		// Create title and subtiles for each different todo type
		switch (todo.type) {
			// Company data
			case TodoType.COMPANY:
				company = this.context.applicationStore.user.getCompanyById(todo.id);

				this.route = Routes.COMPANY_SETTINGS_INFO.getPath({ id: company.id });
				this.todoTitle = this.props.intl.formatMessage(
					{ id: 'todo.company.info.needed' },
					{ name: company.name }
				);

				// Build sub title based on missing fields
				subTitle = todo.data.map((todoRule, index) => {
					// To lowercase and strip underscores of fieldnames
					let ruleName = todoRule.todoRule.toLowerCase();
					ruleName = ruleName.split('_').join('');

					// adjust ruleNames to match translation fields
					switch (ruleName) {
						case 'postcode':
						case 'address.postalcode':
							ruleName = 'postalcode';
							break;
						case 'address.city':
							ruleName = 'city';
							break;
						case 'address.address':
							ruleName = 'address';
							break;
					}

					//
					return (
						this.props.intl.formatMessage({ id: `company.profile.field.${ruleName}` }) +
						(index < todo.data.length - 1 ? ', ' : '')
					);
				});

				this.subTitleElement = <div className="font--meta">{subTitle}</div>;
				break;

			// User data
			case TodoType.USER:
				this.route = Routes.USER_SETTINGS_INFO.getPath();
				this.todoTitle = this.props.intl.formatMessage({ id: 'todo.user.info.needed' });

				// Build subtitle based on missing fields
				subTitle = todo.data.map((todoRule, index) => {
					// To lowercase and strip underscores of fieldnames
					let ruleName = todoRule.todoRule.toLowerCase();
					ruleName = ruleName.split('_').join('');

					// adjust ruleNames to match translation fields
					switch (ruleName) {
						case 'birthday':
							ruleName = 'birthdate';
							break;
						case 'address.postalCode':
							ruleName = 'postalcode';
							break;
						case 'address.city':
							ruleName = 'city';
							break;
						case 'address.address':
							ruleName = 'address';
							break;
					}

					return (
						this.props.intl.formatMessage({ id: `user.profile.field.${ruleName}` }) +
						(index < todo.data.length - 1 ? ', ' : '')
					);
				});

				this.subTitleElement = <div className="font--meta">{subTitle}</div>;
				break;

			// BTW deadline
			case TodoType.VAT:
				company = this.context.applicationStore.user.getCompanyById(todo.id);
				todoRule = todo.data[0];
				financialYear = company.getFinancialYearById(todoRule.id);

				if (!financialYear) {
					console.log('could not find year for VAT todo:', todoRule);
					return null;
				}

				translationValues = {
					year: financialYear.year,
					term: BTWPeriod.getTermTypeLabel(todoRule.termType, financialYear.year, this.props.intl),
					companyName: company.name
				};

				// Ignore year translation value for vatTerm YEAR because it would be redundant
				if (company.vatTerm === BTWPeriod.YEAR.name) {
					translationValues.year = '';
				}

				this.route = Routes.COMPANY_DEADLINES.getPath({ id: company.id, year: financialYear.id });
				this.todoTitle = this.props.intl.formatMessage(
					{ id: 'todo.btw.deadline.title' },
					translationValues
				);
				this.subTitleElement = (
					<div className="font--meta">
						{this.props.intl.formatMessage({ id: 'todo.btw.deadline.subtitle' }, translationValues)}
					</div>
				);
				break;

			// Financial statement
			case TodoType.FINANCIAL_STATEMENT:
				company = this.context.applicationStore.user.getCompanyById(todo.id);
				todoRule = todo.data[0];
				financialYear = company.getFinancialYearById(todoRule.id);

				if (!financialYear) {
					console.log('could not find financial year for FINANCIAL_STATEMENT todo:', todo);
					return null;
				}

				translationValues = { year: financialYear.year, companyName: company.name };

				this.route = Routes.COMPANY_DEADLINES.getPath({ id: company.id, year: financialYear.id });
				this.todoTitle = this.props.intl.formatMessage(
					{ id: 'todo.financial.statement.deadline.title' },
					translationValues
				);
				this.subTitleElement = (
					<div className="font--meta">
						{this.props.intl.formatMessage(
							{ id: 'todo.financial.statement.deadline.subtitle' },
							translationValues
						)}
					</div>
				);
				break;

			// IB-aangifte
			case TodoType.ANNUAL_DECLERATION:
				todoRule = todo.data[0];

				if (!todoRule.year) {
					console.log('could not find year for ANNUAL_DECLERATION todo:', todoRule);
					return null;
				}

				company = this.context.applicationStore.user.getFirstCompany();
				financialYear = company.getFinancialYearByYear(todoRule.year);
				if (!financialYear) {
					console.log('could not find financial year for ANNUAL_DECLERATION todo:', todo);
					return null;
				}

				translationValues = { year: todoRule.year };

				this.route = Routes.COMPANY_DEADLINES.getPath({ id: company.id, year: financialYear.id });
				this.todoTitle = this.props.intl.formatMessage(
					{ id: 'todo.annual.statement.deadline.title' },
					translationValues
				);
				this.subTitleElement = (
					<div className="font--meta">
						{this.props.intl.formatMessage(
							{ id: 'todo.annual.statement.deadline.subtitle' },
							translationValues
						)}
					</div>
				);
				break;
		}
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		if (!this.route) {
			return null;
		}

		const classes = classNames({
			'todo-row padding grid grid--spread border--bottom': true,
			'todo-row--no-profile-image': !this.props.showProfileImage
		});

		return (
			<div className={`${classes} ${this.props.className}`} onClick={this.onClick}>
				{this._getProfileImage()}
				<div className="todo-row__wrapper grid grid--spread">
					<div className="todo-row__content">
						<h3 className="font--body">{this.todoTitle}</h3>
						{this._showSecondaryText() ? this.subTitleElement : null}
					</div>
					<div className="todo-row__arrow icon icon--chevron-right-smoke" />
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onClick() {
		Signals.RequestRoute.dispatch(this.route);
	}

	/**
	 *
	 * @private
	 *
	 *  * @returns {bool}
	 */
	_showSecondaryText() {
		return (
			this.props.showSecondaryText ||
			this.props.todo.type === TodoType.USER ||
			this.props.todo.type === TodoType.COMPANY
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getProfileImage() {
		let company;
		let logoAsset;
		let image = null;
		let showCustom = false;

		switch (this.props.todo.type) {
			case TodoType.FINANCIAL_STATEMENT:
			case TodoType.VAT:
			case TodoType.COMPANY:
				company = this.context.applicationStore.user.getCompanyById(this.props.todo.id);
				showCustom = !!company.communityProfile.logoAssetUuid;
				logoAsset = CommunityProfile.buildAsset(
					null,
					company.communityProfile.logoAssetUuid,
					80,
					80
				);
				image = showCustom ? logoAsset : `${window.config.imageRoot}KdB_rocket_on.svg`;
				break;
			case TodoType.USER:
			case TodoType.ANNUAL_DECLERATION:
				image = `${window.config.imageRoot}KdB_lamp_on.svg`;
		}

		const classes = classNames({
			'todo-row__profile-image': true,
			'todo-row__profile-image--custom': showCustom
		});
		return <div className={classes} style={{ backgroundImage: `url("${image}")` }} />;
	}
}

TodoRow.contextType = ApplicationContext;

TodoRow.propTypes = {
	intl: PropTypes.object,
	todo: PropTypes.object.isRequired,
	showProfileImage: PropTypes.bool,
	showSecondaryText: PropTypes.bool,
	className: PropTypes.string
};

TodoRow.defaultProps = {
	className: '',
	showProfileImage: false,
	showSecondaryText: true
};

export default injectIntl(TodoRow);
