import companyInvoiceSettings from '../requests/companyInvoiceSettings';
import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';

/**
 *
 */
export default class GetCompanyInvoiceSettingsCommand extends AbstractCommand {
	/**
	 * @param company Company
	 */
	constructor(company) {
		super();

		this.company = company;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, _error) {
		console.log('GetCompanyInvoiceSettingsCommand.execute');

		companyInvoiceSettings(this.company.id)
			.then((response) => {
				this.company.invoicesSettings.update(this.company, response.data);
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				this.company.invoicesSettings.update(this.company, {});

				// Result can be 404 when a company is new and doesn't have any invoice settings set,
				// this still needs to call the success callback
				if (err.error && err.error.statusCode === 404) {
					if (success) {
						success(null);
					}
				} else {
					Signals.Error.dispatch(err);
				}
			});
	}
}
