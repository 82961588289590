import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import FileUpload from './FileUpload';
import CompanyAssetType from '../../../data/CompanyAssetType';
import { formatBytes } from '../../../utils/StringUtils';

import companyAssetCreate from '../../../requests/companyAssetCreate';
import FileAsset from '../../../stores/models/FileAsset';
import Signals from '../../../signals/Signals';
import ModalAlert from '../Modal/ModalAlert';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
class FileUploadList extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			uniqueFileUploadKey: 0,
			fileAssets: props.fileAssets ? props.fileAssets : []
		};

		this.onFileChange = this.onFileChange.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);
		this.onAssetError = this.onAssetError.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className={`file-upload-list ${this.props.className}`}>
				{this._generateFileUploads()}
			</div>
		);
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 * @param fieldName
	 */
	onFileChange(file, fileName, fieldName) {
		const fileIndex = parseInt(fieldName.split('.')[1], 10);

		// Add asset
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', fileName);
			formData.append('type', this.props.assetType);

			// Upload asset to the server first
			const company = this.context.applicationStore.getSelectedCompany();
			companyAssetCreate(company.id, formData).then(this.onAssetCreated).catch(this.onAssetError);
			// Remove asset
		} else {
			this._removeFile(fileIndex);
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		if (response.data) {
			// push data
			const fileAsset = FileAsset.createFromObject(response.data);

			if (this._exceedsTotalFileSize(fileAsset.sizeInBytes)) {
				const values = { maximum: formatBytes(this.props.maxTotalFileSize) };
				Signals.ShowModal.dispatch(
					<ModalAlert
						title={this.props.intl.formatMessage({ id: 'fileuploadlist.exceedsize.alert.title' })}
						body={this.props.intl.formatMessage(
							{ id: 'fileuploadlist.exceedsize.alert.body' },
							values
						)}
					/>
				);
			} else {
				this._addFile(fileAsset);
			}
		}
	}

	/**
	 *
	 * @param e
	 */
	onAssetError(e) {
		Signals.Error.dispatch(e);
	}

	/**
	 *
	 * @param fileAsset FileAsset
	 * @private
	 */
	_addFile(fileAsset) {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ fileAssets: this.state.fileAssets.concat([fileAsset]) });
		this._onChange(this.state.fileAssets);
	}

	/**
	 *
	 * @param index
	 * @private
	 */
	_removeFile(index) {
		this.state.fileAssets.splice(index, 1);
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ fileAssets: this.state.fileAssets.concat([]) });
		this._onChange(this.state.fileAssets);
	}

	/**
	 *
	 * @private
	 */
	_generateFileUploads() {
		let fileUploads = [];

		if (this.state.fileAssets) {
			fileUploads = this.state.fileAssets.map((fileAsset, index) => {
				return (
					<FileUpload
						className="col--12"
						key={`trfu-${this.state.uniqueFileUploadKey++}`}
						name={`file.${index}`}
						showFilePreview={false}
						filePreview={undefined}
						fileName={fileAsset.filename}
						label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
						disabled={this.props.disabled}
						onChange={this.onFileChange}
					/>
				);
			});
		}

		if (fileUploads.length < this.props.maxFiles) {
			fileUploads.push(
				<FileUpload
					className="col--12"
					key={`trfu-${this.state.uniqueFileUploadKey++}`}
					name={`file.${fileUploads.length}`}
					showFilePreview={false}
					filePreview={undefined}
					fileName={undefined}
					label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
					disabled={this.props.disabled}
					onChange={this.onFileChange}
				/>
			);
		}

		return fileUploads;
	}

	/**
	 *
	 * @private
	 */
	_onChange() {
		if (this.props.onChange) {
			this.props.onChange(this.state.fileAssets);
		}
	}

	/**
	 *
	 * @private
	 */
	_exceedsTotalFileSize(bytes) {
		let total = bytes;
		this.state.fileAssets.forEach((fileAsset) => {
			total += fileAsset.sizeInBytes;
		});

		return this.props.maxTotalFileSize >= 0 && this.props.maxTotalFileSize < total;
	}
}

FileUploadList.contextType = ApplicationContext;

FileUploadList.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	fileAssets: PropTypes.array,
	maxFiles: PropTypes.number,
	maxTotalFileSize: PropTypes.number,
	assetType: PropTypes.string
};

FileUploadList.defaultProps = {
	className: '',
	fileAssets: [],
	maxFiles: 5,
	maxTotalFileSize: -1,
	assetType: CompanyAssetType.INVOICECONCEPT_ATTACHMENT
};

export default injectIntl(FileUploadList);
