/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';

import Page from '../../Page';
import SearchField from '../../../components/ui/SearchField/SearchField';
import OffersRow from './components/OffersRow';
import AddButton from '../../../components/ui/AddButton/AddButton';

import { ApplicationContext } from '../../../ApplicationContext';
import OffersStore from '../../../stores/OffersStore';
import GetOffersCommand from '../../../commands/offers/GetOffersCommand';
import TableWrapper from '../../../components/ui/TableWrapper/TableWrapper';
import FilterButtonOption from '../../../components/ui/FilterButton/FilterButtonOption';
import FilterButton from '../../../components/ui/FilterButton/FilterButton';

/**
 *
 */
@observer
class Offers extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.addNew = this.addNew.bind(this);
		this.onSortClick = this.onSortClick.bind(this);
		this.onBookyearFilterChange = this.onBookyearFilterChange.bind(this);

		this.state = { bookyearFilter: [] };

		this.fieldHeaderSettings = [
			{
				id: 'status',
				label: this.props.intl.formatMessage({ id: 'offers.header.status' }),
				isDefault: true,
				minimal: true
			},
			// {id: 'expirationDate', label: this.props.intl.formatMessage({id:'offers.header.expires'}), minimal:true},
			{
				id: 'date',
				label: this.props.intl.formatMessage({ id: 'offers.header.date' }),
				minimal: true
			},
			{
				id: 'offerNr',
				label: this.props.intl.formatMessage({ id: 'offers.header.offernr' }),
				minimal: true
			},
			{ id: 'companyName', label: this.props.intl.formatMessage({ id: 'offers.header.client' }) },
			{ id: 'subject', label: this.props.intl.formatMessage({ id: 'offers.header.subject' }) },
			{
				id: 'amount',
				label: this.props.intl.formatMessage({ id: 'offers.header.amount' }),
				right: true,
				minimal: true
			},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'offers.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];
	}

	/**
	 *
	 */
	componentWillMount() {
		console.log('Offers.componentWillMount');
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		console.log('Offers.componentWillReceiveProps');
		this._initialize();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (!company) {
			return null;
		}

		const { bookyearFilter } = this.state;

		const params = { id: company ? company.id : null };

		// Create invoices rows
		let totalAmount = 0;
		let totalAmountExclVat = 0;
		const rows = this.props.offersStore.filteredAndSortedOffers.map((offer) => {
			// Ignore if not an 'open' invoice
			if (this.props.showOpenOnly && !offer.isOpen()) {
				return null;
			}

			// Add to total and create DOM
			totalAmount += offer.getTotal();
			totalAmountExclVat += offer.getSubtotal();
			return <OffersRow key={`i-${offer.id}-${offer.offerNr}`} offerModel={offer} />;
		});

		return (
			<Page pageName="offers">
				<div className="offers__wrapper border border--dark">
					<div className="offers__options box-shadow-small grid grid--spread">
						<div className="grid offers__options__left">
							<SearchField
								name="search"
								className="btn-margin-right"
								// eslint-disable-next-line react/jsx-no-bind
								onChange={this.onSearch.bind(this)}
								placeholder={this.props.intl.formatMessage({ id: 'offers.search' })}
								value={
									this.props.offersStore.searchFilter
										? this.props.offersStore.searchFilter
										: undefined
								}
							/>

							<FilterButton
								name="bookyearFilter"
								className="filter-button--primary"
								label={this.props.intl.formatMessage({ id: 'invoices.filter.bookyear' })}
								options={bookyearFilter}
								onChange={this.onBookyearFilterChange}
								showToggle
							/>
						</div>
						<div className="grid offers__options-buttons">
							<button
								type="button"
								className="offers__add-new icon icon--left icon--big-plus icon--color"
								onClick={this.addNew}>
								<FormattedMessage id="offers.add.offer" />
							</button>
						</div>
					</div>

					<TableWrapper scrollableX>
						<table className="table">
							<thead className="table__headers walkthrough-step-offers-all">
								<tr>{this._getTableHeaders()}</tr>
							</thead>
							<tbody className="table__body">{rows}</tbody>
						</table>
					</TableWrapper>

					<div className="offers__footer-extra-options grid grid--center border--top">
						<Link to={Routes.COMPANY_OFFERS_ARCHIVE.getPath(params)}>
							<FormattedMessage
								id="offers.showarchives"
								values={{ count: this.props.offersStore.getArchivedOfferCount() }}
							/>
						</Link>
					</div>

					<div className="table__footer options-footer grid grid--right border--top">
						<div className="offers__total_ammounts">
							<div>
								<div className="offers__total">
									<FormattedMessage id="offers.add.total.excl.vat" />
								</div>
								<FormattedNumber
									style="currency"
									currency="EUR"
									value={totalAmountExclVat}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							</div>
							<div>
								<div className="offers__total">
									<FormattedMessage id="offers.add.total" />
								</div>
								<FormattedNumber
									style="currency"
									currency="EUR"
									value={totalAmount}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							</div>
						</div>
					</div>

					<AddButton
						onClick={this.addNew}
						title={this.props.intl.formatMessage({ id: 'offers.add.offer' })}
					/>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.props.offersStore.searchFilter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.props.offersStore.sortBy === field) {
			this.props.offersStore.sortDirection = -this.props.offersStore.sortDirection;
		} else {
			this.props.offersStore.sortBy = field;
			this.props.offersStore.sortDirection = 1;
		}
	}

	/**
	 *
	 * @param name
	 * @param value
	 * @param checked
	 * @param ignoreUpdate
	 */
	onBookyearFilterChange(name, value, checked, ignoreUpdate) {
		const { offersStore } = this.props;
		const { bookyearFilter } = this.state;
		let options;

		// Update filter button option state
		switch (name) {
			case 'bookyearFilter':
				options = bookyearFilter.filter((filterButtonOption) => {
					return filterButtonOption.value === value;
				});

				if (
					options.forEach((option) => {
						option.checked = checked;
					})
				)
					break;
				break;
			default:
				break;
		}

		// Apply filters
		offersStore.bookyearFilter.value = bookyearFilter
			.map((filterOption) => {
				return filterOption.checked ? filterOption.value : false;
			})
			.filter((val) => {
				return val !== false;
			});

		if (!ignoreUpdate) {
			offersStore.update();
		}
	}

	/**
	 *
	 */
	addNew() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_OFFERS_EDIT.getPath({ id: company.id, offerId: 'new' })
			);
		}
	}

	/**
	 *
	 * @return {any[]}
	 * @private
	 */
	_getTableHeaders() {
		return this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`offers__header--${`${fieldData.id}`.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!this.props.offersStore.sortBy && fieldData.isDefault,
						'table__header--sorted': this.props.offersStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							this.props.offersStore.sortBy === fieldData.id &&
							this.props.offersStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}

	/**
	 *
	 * @private
	 */
	_initialize() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const getOffersCommand = new GetOffersCommand(this.props.offersStore, company.id);
			getOffersCommand.execute();

			this.setState({
				bookyearFilter: company.financialYears.map((financialYear) => {
					return new FilterButtonOption(financialYear.year, financialYear.year, true);
				})
			});
		}
	}
}

Offers.contextType = ApplicationContext;

Offers.propTypes = {
	intl: PropTypes.object,
	offersStore: PropTypes.instanceOf(OffersStore).isRequired,
	showOpenOnly: PropTypes.bool
};

Offers.defaultProps = {
	showOpenOnly: false
};

export default injectIntl(Offers);
