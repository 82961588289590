import { observable } from 'mobx';

/**
 *
 */
export default class Draft {
	@observable id;

	@observable modified;

	@observable createdAt;

	@observable assetUuid;

	@observable data; // JSON encoded

	@observable source;

	@observable type;

	@observable user;

	@observable company;

	@observable boekjaarId;

	@observable isNew = false;

	/**
	 *
	 * @param data
	 */
	constructor(data) {
		this.update(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}
}
