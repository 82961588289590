import AbstractCommand from '../AbstractCommand';
import companyOfferSettingsUpdate from '../../requests/companyOfferSettingsUpdate';
import PaymentTerm from '../../data/PaymentTerm';
import { convertTagsToTranslations } from '../../data/OfferDynamicValues';

/**
 *
 */
export default class UpdateOfferSettingsCommand extends AbstractCommand {
	/**
	 *
	 * @param intl
	 * @param company
	 * @param settings Updated settings
	 */
	constructor(intl, company, settings) {
		super();

		this.intl = intl;
		this.defaults = {};
		this.data = {};
		this.company = company;
		this.settings = settings;
		this.offersSettings = this.company.offersSettings;

		this.setDefault();
		this.applyCurrentSettings();
		this.applyUpdatedSettings();
	}

	/**
	 *
	 */
	setDefault() {
		// Set default subject and messages if necessary
		this.defaults.senderEmail = this.company.email;
		this.defaults.remindCompanyUserInDays = 0;
		this.defaults.remindCompanyCustomerInDays = PaymentTerm.THIRTY_DAYS.days;

		this.defaults.reminderMessage = this.intl.formatMessage({
			id: 'offer.settings.remindermessage.default.nl'
		});
		this.defaults.reminderSubject = this.intl.formatMessage({
			id: 'offer.settings.remindersubject.default.nl'
		});
		this.defaults.defaultOfferEmailMessage = this.intl.formatMessage({
			id: 'offer.settings.emailmessage.default.nl'
		});
		this.defaults.defaultOfferEmailSubject = this.intl.formatMessage({
			id: 'offer.settings.emailsubject.default.nl'
		});
		this.defaults.defaultNotes = this.intl.formatMessage({
			id: 'offer.settings.notes.default.nl',
			defaultMessage: ' '
		});

		this.defaults.reminderMessageEN = this.intl.formatMessage({
			id: 'offer.settings.remindermessage.default.en'
		});
		this.defaults.reminderSubjectEN = this.intl.formatMessage({
			id: 'offer.settings.remindersubject.default.en'
		});
		this.defaults.defaultOfferEmailMessageEN = this.intl.formatMessage({
			id: 'offer.settings.emailmessage.default.en'
		});
		this.defaults.defaultOfferEmailSubjectEN = this.intl.formatMessage({
			id: 'offer.settings.emailsubject.default.en'
		});
		this.defaults.defaultNotesEN = this.intl.formatMessage({
			id: 'offer.settings.notes.default.en',
			defaultMessage: ' '
		});

		/**
		 	These are set by this.company.offersSettings:
		 		this.defaults.logoAssetUuid = null;
		 		this.defaults.bccRecipientEmail = '';
		 */
	}

	/**
	 *
	 */
	applyCurrentSettings() {
		// Update data, loop through setting fields and update `data` with values from `settings`, use `defaults` as fallback
		for (const key in this.company.offersSettings) {
			if (this.company.offersSettings.hasOwnProperty(key)) {
				// Is it a translation object? use default value instead
				if (typeof this.company.offersSettings[key] === 'object') {
					this.data[key] = this.defaults[key];
					// Otherwise use invoice settings value
				} else if (key === 'senderEmail') {
					// Don't update if `senderEmail` is null or undefined, use the default instead
					if (this.company.offersSettings.senderEmail) {
						this.data[key] = this.company.offersSettings.senderEmail;
					}
				} else {
					this.data[key] = this.company.offersSettings[key];
				}
			}
		}
	}

	/**
	 *
	 */
	applyUpdatedSettings() {
		const noTagFields = [
			'logoAssetUuid',
			'senderEmail',
			'remindCompanyUserInDays',
			'remindCompanyCustomerInDays'
		];
		const ignoreFields = ['errors', 'submitting'].concat(noTagFields);

		// Update data, loop through setting fields and update `data` with values from `settings`
		for (const key in this.settings) {
			// Ignore 'objects' as they are the same as the defaults
			if (
				this.settings.hasOwnProperty(key) &&
				typeof this.settings[key] !== 'object' &&
				ignoreFields.indexOf(key) < 0
			) {
				this.data[key] = convertTagsToTranslations(this.settings[key]);
			}
		}

		// Update fields without translation/tags
		noTagFields.forEach((key) => {
			this.data[key] = this.settings[key];
		});
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		companyOfferSettingsUpdate(this.company.id, JSON.stringify(this.data))
			.then((response) => {
				this._updateOfferSettings();

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 * Save data values to OfferSettings of Company
	 * @private
	 */
	_updateOfferSettings() {
		// Update company offersSettings
		const companyOfferSettings = this.company.offersSettings;
		for (const key in this.data) {
			if (this.data.hasOwnProperty(key)) {
				companyOfferSettings[key] = this.data[key];
			}
		}
	}
}
