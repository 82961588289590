import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedDate, FormattedHTMLMessage } from 'react-intl';

import PeriodicInvoiceConceptModel from '../../../../stores/models/PeriodicInvoiceConceptModel';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class PeriodicInvoiceStatus extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentDidMount() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { periodicInvoiceConceptModel } = this.props;
		if (!periodicInvoiceConceptModel) return null;

		const { periodicSchedule } = periodicInvoiceConceptModel;
		const { interval, startDate, nextDate, lastExecutionDate, amount } = periodicSchedule;
		const { intl } = this.props;

		/**
		 * 	@observable interval = PeriodicScheduleInterval.MONTHLY;
		 @observable startDate = new Date();
		 @observable endDate = null;
		 @observable nextDate = null;
		 @observable enabled = true;
		 @observable amount = 1;
		 @observable lastAttemptStatus;
		 @observable lastExecutionDate;
		 @observable totalExecutions;
		 @observable executionsLeft;
		 */
		const intervalText = intl
			.formatMessage({ id: `periodicscheduleinterval.${interval}.count` }, { count: amount })
			.toLowerCase();
		return (
			<div className="periodic-invoice-status col--12">
				<div className="periodic-invoice-status__title grid grid--center">
					<div>
						<FormattedHTMLMessage
							id="invoice.periodic.status.interval.description.automatic"
							values={{ count: amount, interval: intervalText }}
						/>
					</div>
				</div>
				<div className="periodic-invoice-status__dates grid grid--spread">
					<div className="font--body-medium">
						<FormattedDate value={startDate} day="2-digit" month="2-digit" year="numeric" />
					</div>
					<div className="periodic-invoice-status__seperator" />
					{nextDate
						? [
								<div className="font-primary-bold">
									<FormattedDate value={nextDate} day="2-digit" month="2-digit" year="numeric" />
								</div>,
								<div className="periodic-invoice-status__seperator" />
						  ]
						: null}
					{lastExecutionDate ? (
						<div className="font--body-medium">
							<FormattedDate
								day="2-digit"
								month="2-digit"
								year="numeric"
								value={lastExecutionDate}
							/>
						</div>
					) : null}
				</div>
				<div className="periodic-invoice-status__dates-labels grid grid--spread">
					<span className="font--body-medium">
						<FormattedMessage id="invoice.settings.startdate" />
					</span>
					{nextDate ? (
						<span className="font-primary-bold">
							<FormattedMessage id="invoices.periodic.header.next" />
						</span>
					) : null}
					{lastExecutionDate ? (
						<span className="font--body-medium">
							<FormattedMessage id="invoices.periodic.header.last" />
						</span>
					) : null}
				</div>
			</div>
		);
	}
}

PeriodicInvoiceStatus.contextType = ApplicationContext;

PeriodicInvoiceStatus.propTypes = {
	periodicInvoiceConceptModel: PropTypes.instanceOf(PeriodicInvoiceConceptModel),
	intl: PropTypes.object
};

export default injectIntl(PeriodicInvoiceStatus);
