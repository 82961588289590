/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedNumber } from 'react-intl';

/**
 *
 */
class OfferSummaryRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.props.amount === 0 && this.props.hideWhenZero) {
			return null;
		}

		return (
			<tr className="offer-summary-row">
				<td />
				<td />
				<td className="offer-summary-row__label" colSpan={2}>
					{this.props.label}
				</td>
				<td className="offer-summary-row__amount">
					{this.props.amount !== null ? (
						<FormattedNumber
							style="currency"
							currency="EUR"
							value={this.props.amount ? this.props.amount : 0}
							minimumFractionDigits={2}
							maximumFractionDigits={2}
						/>
					) : null}
				</td>
			</tr>
		);
	}
}

OfferSummaryRow.propTypes = {
	hideWhenZero: PropTypes.bool,
	label: PropTypes.any,
	amount: PropTypes.number,
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

OfferSummaryRow.defaultProos = {
	hideWhenZero: false
};

export default injectIntl(OfferSummaryRow);
