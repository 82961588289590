import { observable, action } from 'mobx';

import CompanyLatestOfferNumber from './models/CompanyLatestOfferNumber';

/**
 *
 */
export default class CompanyLatestOfferNumberStore {
	@observable companyLatestOfferNumbers = [];

	/**
	 *
	 */
	reset() {
		this.companyLatestOfferNumbers = [];
	}

	/**
	 *
	 * @param response
	 */
	@action update(response) {
		this.companyLatestOfferNumbers = [];
		for (const year in response) {
			if (response.hasOwnProperty(year)) {
				const companyLatestOfferNumber = new CompanyLatestOfferNumber(
					year,
					response[year].latestOfferNr,
					response[year].suggestedNextOfferNr
				);
				this.companyLatestOfferNumbers.push(companyLatestOfferNumber);
			}
		}

		// Sort by year
		this.companyLatestOfferNumbers.sort((a, b) => {
			return a.year - b.year;
		});
	}

	/**
	 * @param year
	 * @return {CompanyLatestOfferNumber|null}
	 */
	getLatestOfferNumber(year = null) {
		if (year) {
			let companyOfferNumber = null;
			this.companyLatestOfferNumbers.forEach((offerNumber) => {
				if (offerNumber.year === year) {
					companyOfferNumber = offerNumber;
				}
			});

			if (companyOfferNumber) {
				return companyOfferNumber;
			}
		}

		if (this.companyLatestOfferNumbers.length > 0) {
			return this.companyLatestOfferNumbers[this.companyLatestOfferNumbers.length - 1];
		}

		return null;
	}
}
