import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Switch from '../../../components/ui/Switch/Switch';
import useBankAccountStore from '../../../utils/useBankAccountStore';
import useCompany from '../../../utils/useCompany';
import useBankRecordStore from '../../../utils/useBankRecordStore';
import { formatIBAN } from '../../../utils/ibanUtils';
import { getAbsoluteDays } from '../../../utils/dateUtils';

/**
 *
 * @param {BankAccount} bankAccount
 * @returns
 */
function BankAccountItem({ bankAccount }) {
	const company = useCompany();
	const bankAccountStore = useBankAccountStore();
	const bankRecordStore = useBankRecordStore();
	const showWarningFromReaminingDays = 30;
	const showErrorFromReaminingDays = 5;

	const toggleBankAccount = () => {
		if (bankAccountStore && company) {
			console.log('toggleBankAccount', bankAccount);
			bankAccountStore.toggleBankAccount(company.id, bankAccount).then(() => {
				bankRecordStore.resetStore();
			});
		}
	};

	return (
		<Observer>
			{() => {
				const reaminingDays = getAbsoluteDays(bankAccount.expirationDate, new Date());
				return (
					<div className="flex flex-col border-solid border-0 border-t-2 border-gray-100 bg-white">
						<div className="flex flex-row space-x-2 p-5 min-h-5">
							<div className="basis-1/6 w-full">
								<Switch
									name={bankAccount.id}
									label=""
									checked={bankAccount.enabled}
									onChange={toggleBankAccount}
								/>
							</div>
							<div className="basis-2/6 w-full">{formatIBAN(bankAccount.reference)}</div>
							<div className="basis-3/6  w-full flex flex-row space-x-2 justify-end text-gray-400">
								<FormattedMessage id="bankbook.settings.accounts.last_update" />
								<FormattedDate value={bankAccount.synchronizedAt} weekday="long" />
								<FormattedMessage id="bankbook.settings.accounts.at" />
								<FormattedTime value={bankAccount.synchronizedAt} />
							</div>
						</div>
						{bankAccount.expirationDate && reaminingDays <= showWarningFromReaminingDays && (
							<div
								className={`${
									showErrorFromReaminingDays <= reaminingDays
										? 'bg-warning-100 border-warning text-warning'
										: 'bg-error-100 border-error text-error'
								} py-2 text-sm flex justify-center border-2 border-solid  mb-2`}>
								{' '}
								<FormattedMessage
									id="bankbook.settings.accounts.expire"
									values={{ day: reaminingDays }}
								/>{' '}
							</div>
						)}
					</div>
				);
			}}
		</Observer>
	);
}

BankAccountItem.propTypes = {
	bankAccount: PropTypes.object.isRequired
};

export default BankAccountItem;
