import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
// eslint-disable-next-line no-unused-vars
import BankRecordStore from '../stores/BankRecordStore';
/**
 * This hook returns the bank record store.
 * @return {BankRecordStore}
 */
const useBankRecordStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { bankRecordStore } = applicationStore;

	return bankRecordStore;
};

export default useBankRecordStore;
