import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { Routes } from '../../data/Routes';
import { getPrivateDocumentTypes, getCompanyDocumentTypes } from '../../data/DocumentTypes';

import Signals from '../../signals/Signals';
import DraftTypes from '../../data/DraftTypes';

import PropertiesController from '../../controllers/PropertiesController';

import Page from '../Page';
import { ApplicationContext } from '../../ApplicationContext';
import FetchDocumentsCommand from '../../commands/FetchDocumentsCommand';
import DocumentStore from '../../stores/DocumentStore';
import CollapsablePanel from '../../components/ui/CollapsablePanel/CollapsablePanel';
import DocumentRow from './components/DocumentRow';
import AddDocumentInput from './AddDocumentInput';
import DocumentDraftRow from './components/DocumentDraftRow';

/**
 *
 */
@observer
class Documents extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { documentStore: new DocumentStore() };

		this.onFetchError = this.onFetchError.bind(this);
		this.onFetchSuccess = this.onFetchSuccess.bind(this);
		this.onDocumentYearChanged = this.onDocumentYearChanged.bind(this);
		this.onDocumentSubmitted = this.onDocumentSubmitted.bind(this);
		this.addNew = this.addNew.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.DocumentYearChanged.add(this.onDocumentYearChanged);
	}

	/**
	 *
	 */
	componentDidMount() {
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.state = { documentStore: new DocumentStore() };
		this._initialize();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.DocumentYearChanged.remove(this.onDocumentYearChanged);
		Signals.DocumentSubmitted.remove(this.onDocumentSubmitted);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (
			this.context.applicationStore.isLoggedIn &&
			this.context.applicationStore.currentRouteParams
		) {
			const selectedYear = this.context.applicationStore.getSelectedDocumentYear();

			let isUser = true;
			this.context.applicationStore.user.companies.forEach((company) => {
				if (`${this.context.applicationStore.currentRouteParams.id}` === `${company.id}`) {
					isUser = false;
				}
			});

			// TODO: make switch between private en company
			const documentTypes = isUser ? getPrivateDocumentTypes() : getCompanyDocumentTypes();

			return (
				<Page pageName="documents">
					{this._getDocumentDraftsTypePanel()}

					{documentTypes.map((type) => {
						return this._getDocumentTypePanel(selectedYear, type);
					})}
				</Page>
			);
		}

		return null;
	}

	/**
	 *
	 * @param response
	 */
	onFetchSuccess(_response) {
		this.forceUpdate();
	}

	/**
	 *
	 * @param error
	 */
	onFetchError(error) {
		console.log('Documents.onFetchError', error);
	}

	/**
	 *
	 * @param year
	 */
	onDocumentYearChanged(_year) {
		this.forceUpdate();
	}

	/**
	 *
	 * @param data
	 */
	onDocumentSubmitted(data) {
		this.state.documentStore.addFromData(data);
	}

	/**
	 *
	 */
	addNew() {
		// Show IncomeInput overlay
		Signals.ShowOverlay.dispatch(<AddDocumentInput key="add-document-input" />);
	}

	/**
	 *
	 * @private
	 */
	_getDocumentDraftsTypePanel() {
		if (this.context.applicationStore.isLoggedIn) {
			// Select company, or null if user
			const currentRoute = this.context.applicationStore.currentRoute;
			const currentRouteParams = this.context.applicationStore.currentRouteParams;
			const selectedCompany = this.context.applicationStore.user.getCompanyById(
				parseInt(`${currentRouteParams.id}`, 10)
			);

			const target =
				currentRoute === Routes.COMPANY_DOCUMENTS
					? selectedCompany
					: this.context.applicationStore.user;

			const { draftsStore } = target;
			const drafts = draftsStore.getDraftsOfType(DraftTypes.DOCUMENT);
			const count = drafts.length > 0 ? ` (${drafts.length})` : '';
			const title = this.props.intl.formatMessage({ id: 'document.type.UNCATEGORIZED' }) + count;

			const type = 'uncategorised';
			return (
				<CollapsablePanel
					key={type}
					iconName="info"
					title={title}
					className="collapsable-panel--completed"
					collapsed
					canCollapse={drafts.length > 0}
					alwaysShowTodo>
					{drafts.map((draft) => {
						return this._getDocumentDraftRow(draft, draftsStore);
					})}
				</CollapsablePanel>
			);
		}
	}

	/**
	 *
	 * @param year
	 * @param type DocumentTypes
	 * @return {*[]}
	 * @private
	 */
	_getDocumentTypePanel(year, type) {
		const documents = this.state.documentStore.getDocumentsByYearByType(year, type);
		const count = documents.length > 0 ? ` (${documents.length})` : '';
		const title = this.props.intl.formatMessage({ id: `document.type.${type.name}` }) + count;

		return (
			<CollapsablePanel
				key={`${year}-${type.name}`}
				iconName={type.name}
				title={title}
				className="collapsable-panel--completed"
				collapsed
				canCollapse={documents.length > 0}
				alwaysShowTodo>
				{documents.map((document) => {
					return this._getDocumentRow(document);
				})}
			</CollapsablePanel>
		);
	}

	/**
	 *
	 * @param draft
	 * @param draftStore
	 * @return {*}
	 * @private
	 */
	_getDocumentDraftRow(draft, draftStore) {
		return (
			<DocumentDraftRow draft={draft} draftsStore={draftStore} key={`doc-draft-${draft.id}`} />
		);
	}

	/**
	 *
	 * @param document
	 * @return {*}
	 * @private
	 */
	_getDocumentRow(document) {
		return (
			<DocumentRow
				key={`doc-${document.id}`}
				document={document}
				documentStore={this.state.documentStore}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_initialize() {
		if (this.context.applicationStore.isLoggedIn) {
			// Select company, or null if user
			const currentRoute = this.context.applicationStore.currentRoute;
			const currentRouteParams = this.context.applicationStore.currentRouteParams;
			const selectedCompany = this.context.applicationStore.user.getCompanyById(
				parseInt(`${currentRouteParams.id}`, 10)
			);

			const company = currentRoute === Routes.COMPANY_DOCUMENTS ? selectedCompany : null;
			const command = new FetchDocumentsCommand(this.state.documentStore, company);
			command.execute(this.onFetchSuccess, this.onFetchError);
		}
	}
}

Documents.contextType = ApplicationContext;

Documents.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Documents);
