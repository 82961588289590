import React from 'react';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';

import Page from '../Page';

import { Routes, RouteUtil } from '../../data/Routes';

import OnBoardingSteps from './OnBoardingSteps';

import OnBoardingIntro from './OnBoardingIntro';
import OnBoardingUserPhone from './OnBoardingUserPhone';
import OnBoardingUserAddress from './OnBoardingUserAddress';
import OnBoardingUserBSN from './OnBoardingUserBSN';
import OnBoardingPassport from './OnBoardingUserPassport';
import OnBoardingCompanyAddress from './OnBoardingCompanyAddress';
import OnBoardingUserBirthdate from './OnBoardingUserBirthdate';
import OnBoardingCompanyBTW from './OnBoardingCompanyBTW';
import OnBoardingCompanyKVK from './OnBoardingCompanyKVK';
import OnBoardingCompanyContact from './OnBoardingCompanyContact';
import { ApplicationContext } from '../../ApplicationContext';

/**
 *
 */
export default class OnBoarding extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.stepData = [];
		this.state = {
			step: -1,
			previousStep: 0
		};

		this.successCallback = this.successCallback.bind(this);
		this.skipAllCallback = this.skipAllCallback.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		let step = 0;
		const user = this.context.applicationStore.user;

		if (!user) return null;

		this.stepData = [];

		// Intro
		const introComponents = (
			<OnBoardingIntro
				key="onin-00"
				className={this._stepClassNames(++step, true)}
				successCallback={this.successCallback}
				skipAllCallback={this.skipAllCallback}
			/>
		);

		// User data
		const userComponents = [
			<OnBoardingUserAddress
				key="onuadd"
				className={this._stepClassNames(
					++step,
					!user.address || !user.address.address || !user.address.postalCode || !user.address.city
				)}
				successCallback={this.successCallback}
				user={user}
			/>,
			<OnBoardingUserPhone
				key="onusph"
				className={this._stepClassNames(++step, !user.phoneNumber)}
				successCallback={this.successCallback}
				user={user}
			/>,
			<OnBoardingUserBirthdate
				key="onubd"
				className={this._stepClassNames(++step, !user.birthDay)}
				successCallback={this.successCallback}
				user={user}
			/>,
			<OnBoardingUserBSN
				key="onusbs"
				className={this._stepClassNames(++step, !user.bsn)}
				successCallback={this.successCallback}
				user={user}
			/>,
			<OnBoardingPassport
				key="onuspa"
				className={this._stepClassNames(++step, !user.passportFile)}
				successCallback={this.successCallback}
				user={user}
			/>
		];

		// Company data
		const companyComponents = [];
		user.companies.forEach((company, index) => {
			let addressRequired = true;
			if (company.address) {
				addressRequired =
					!company.address.address || !company.address.postalCode || !company.address.city;
			}

			companyComponents.push(
				<OnBoardingCompanyAddress
					key={`onca-${index}`}
					className={this._stepClassNames(++step, addressRequired)}
					successCallback={this.successCallback}
					company={company}
				/>
			);
			companyComponents.push(
				<OnBoardingCompanyContact
					key={`oncc-${index}`}
					className={this._stepClassNames(++step, !company.email || !company.phoneNumber)}
					successCallback={this.successCallback}
					company={company}
				/>
			);
			companyComponents.push(
				<OnBoardingCompanyKVK
					key={`onckvk-${index}`}
					className={this._stepClassNames(
						++step,
						!company.kvkNumber || !company.startCompany || !company.activity
					)}
					successCallback={this.successCallback}
					company={company}
				/>
			);

			if (!company.ignoreBTWNumber()) {
				companyComponents.push(
					<OnBoardingCompanyBTW
						key={`oncvat-${index}`}
						className={this._stepClassNames(
							++step,
							!company.vatNumber || (!company.ignoreBTWId() && !company.vatId)
						)}
						successCallback={this.successCallback}
						company={company}
					/>
				);
			}
		});

		// Check how many steps are still required
		let requiredSteps = 0;
		this.stepData.forEach((data) => {
			requiredSteps += data.isRequired ? 1 : 0;
		});

		// Redirect if skipped, no more steps, or no required steps are found, or only intro is required
		if (
			this.state.previousStep === Number.MAX_VALUE ||
			step < this.state.step ||
			this.state.step === -1 ||
			requiredSteps === 1
		) {
			const company = this.context.applicationStore.getSelectedCompany(true);
			const deeplink = this.context.applicationStore.getDeeplink(true);
			const deeplinkRoute = deeplink ? RouteUtil.getRoute(deeplink.split('#')[0]) : null; // Strip off hash tag
			let pathName;

			// Use deeplink if not LOGIN, FORGOT_PASSWORD or RESET_PASSWORD
			if (deeplinkRoute && !deeplinkRoute.ignoreDeeplink) {
				pathName = deeplink;
				console.log('Onboarding use deeplink', pathName);
				// Else just jump to COMPANY_DASHBOARD
			} else {
				pathName = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
				console.log('Onboarding default', pathName);
			}

			// Reload todos
			this.context.applicationStore.todoStore.fetch();

			// Return Redirect
			return <Redirect to={pathName} />;
		}

		// Render
		return (
			<Page pageName="on-boarding page--logo">
				<OnBoardingSteps stepData={this.stepData} />
				<div className="on-boarding__panel-container vertically-center grid">
					{introComponents}
					{userComponents}
					{companyComponents}
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param panel
	 */
	skipAllCallback(_panel) {
		this.setState({ previousStep: Number.MAX_VALUE, step: -1 });
	}

	/**
	 *
	 * @param panel
	 */
	successCallback(_panel) {
		this.lockStep();
		this.forceUpdate();
	}

	/**
	 *
	 */
	lockStep() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ previousStep: this.state.step, step: -1 });
	}

	/**
	 *
	 * @param _index
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	showStep(_index) {
		// TODO:
	}

	/**
	 *
	 * @param step
	 * @param isRequired
	 * @return {*}
	 * @private
	 */
	_stepClassNames(step, isRequired = true) {
		if (this.state.step < 0 && step > this.state.previousStep && isRequired) {
			this.state.step = step;
		}

		this.stepData.push({
			index: step,
			isActive: step === this.state.step,
			isRequired
		});
		return classNames({
			'on-boarding--hidden': this.state.step !== step,
			'on-boarding--visible': this.state.step === step
		});
	}
}

OnBoarding.contextType = ApplicationContext;

OnBoarding.propTypes = {};
