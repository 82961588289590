import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
export default class CheckboxButton extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			checked: this.props.checked
		};

		this.onYesClick = this.onYesClick.bind(this);
		this.onNoClick = this.onNoClick.bind(this);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const classes = classNames({
			'checkbox-button--checked': this.state.checked,
			'checkbox-button--disabled': this.props.disabled,
			'checkbox-button--has-selection': !(
				this.state.checked === undefined || this.state.checked === null
			)
		});

		const yesClasses = classNames({
			'button--tertiary':
				this.state.checked === undefined || this.state.checked === null || !this.state.checked
		});
		const noClasses = classNames({
			'button--tertiary':
				this.state.checked === undefined || this.state.checked === null || this.state.checked
		});

		if (!this.props.reversed) {
			return (
				<div
					className={`checkbox-button ${
						this.props.className ? this.props.className : ''
					} ${classes}`}>
					<button
						type="button"
						className={`checkbox-button__no ${noClasses}`}
						onClick={this.onNoClick}
						disabled={this.props.disabled}>
						{this.props.noLabel}
					</button>
					<button
						type="button"
						className={`checkbox-button__yes ${yesClasses}`}
						onClick={this.onYesClick}
						disabled={this.props.disabled}>
						{this.props.yesLabel}
					</button>
				</div>
			);
		}
		return (
			<div
				className={`checkbox-button ${
					this.props.className ? this.props.className : ''
				} ${classes}`}>
				<button
					type="button"
					className={`checkbox-button__yes ${yesClasses}`}
					onClick={this.onYesClick}
					disabled={this.props.disabled}>
					{this.props.yesLabel}
				</button>
				<button
					type="button"
					className={`checkbox-button__no ${noClasses}`}
					onClick={this.onNoClick}
					disabled={this.props.disabled}>
					{this.props.noLabel}
				</button>
			</div>
		);
	}

	/**
	 *
	 */
	onYesClick() {
		this.state.checked = true;
		this.forceUpdate();
		this.onChange();
	}

	/**
	 *
	 */
	onNoClick() {
		this.state.checked = false;
		this.forceUpdate();
		this.onChange();
	}

	/**
	 *
	 */
	onChange() {
		this.props.onChange(this.state.checked);
	}
}

CheckboxButton.propTypes = {
	onChange: PropTypes.func.isRequired,
	yesLabel: PropTypes.string.isRequired,
	noLabel: PropTypes.string.isRequired,
	className: PropTypes.string,
	checked: PropTypes.bool,
	reversed: PropTypes.bool,
	disabled: PropTypes.bool
};

CheckboxButton.defaultProps = {
	reversed: false,
	disabled: false
};
