import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Signals from '../../../signals/Signals';

import { Routes } from '../../../data/Routes';
import CommunityProfileStore from '../../../stores/CommunityProfileStore';

import Page from '../../Page';
import CommunityProfileBlock from '../../../components/ui/Community/CommunityProfileBlock';
import SearchField from '../../../components/ui/SearchField/SearchField';
import DropDown from '../../../components/ui/DropDown/DropDown';

import debounce from '../../../utils/debounce';
import { ApplicationContext } from '../../../ApplicationContext';

@observer
/**
 *
 */
class CommunityOverview extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false
		};

		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.onLoadNext = this.onLoadNext.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onCompanyProfile = this.onCompanyProfile.bind(this);
		this.onCompanyChanged = this.onCompanyChanged.bind(this);
		this.fetchResults = debounce(this.fetchResults.bind(this), 400);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.reload();
		Signals.CompanyChanged.add(this.onCompanyChanged);
	}

	/**
	 *
	 */
	componentWillReceiveProps() {
		this.reload();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.onCompanyChanged);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		if (!this.company || !this.context.applicationStore.communityCategoriesStore.fetched) {
			return null;
		}

		const profiles = this.props.communityProfileStore.communityProfiles.map(
			(communityProfile, index) => {
				return (
					<CommunityProfileBlock
						key={`cp-${index}`}
						company={this.company}
						communityProfile={communityProfile}
					/>
				);
			}
		);

		return (
			<Page pageName="community-overview">
				<div className="community-overview__options grid grid--spread border--bottom border--dark">
					<div className="grid">
						{this._getCategoryDropDown()}

						<SearchField
							name="search"
							value={
								this.props.communityProfileStore.searchQuery !== '*'
									? this.props.communityProfileStore.searchQuery
									: undefined
							}
							onChange={this.onSearch}
							placeholder={this.props.intl.formatMessage({ id: 'community.search.placeholder' })}
						/>
					</div>

					<button
						type="button"
						className="button--tertiary icon icon--left icon--eye2-black btn-margin-left"
						onClick={this.onCompanyProfile}>
						<FormattedMessage id="community.showprofile.button" />
					</button>
				</div>
				<div className="community-overview__profiles-container grid">
					{profiles}

					{this._getLoadMore()}
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	onCompanyChanged() {
		this.state.isLoaded = false;
		this.reload();
	}

	/**
	 *
	 */
	onCompanyProfile() {
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_SETTINGS_COMMUNITY_PROFILE.getPath({ id: this.company.id, profileId: 'edit' })
		);
	}

	/**
	 *
	 * @param event
	 */
	onCategoryChange(event) {
		this.props.communityProfileStore.category =
			event.target.value !== '*' ? event.target.value : null;
		this.fetchResults();
	}

	/**
	 *
	 * @param event
	 */
	onSearch(event) {
		let searchQuery = event ? event.target.value : '*';
		if (searchQuery.trim() === '') {
			searchQuery = '*'; // default
		}

		this.props.communityProfileStore.searchQuery = encodeURIComponent(searchQuery);
		this.fetchResults();
	}

	/**
	 *
	 */
	onLoadNext() {
		this.props.communityProfileStore.loadNext();
	}

	/**
	 *
	 */
	reload() {
		this.company = this.context.applicationStore.getSelectedCompany();

		if (this.company && !this.state.isLoaded) {
			this.state.isLoaded = true;

			// Force change trigger, even if already loaded. Always clearing previous results anyway
			this.props.communityProfileStore.fetch(true, true);

			// Make sure categories are retrieved
			Signals.CommunityCategoriesFetched.addOnce(this.forceUpdate.bind(this));
			this.context.applicationStore.communityCategoriesStore.fetch();
		}
	}

	/**
	 *
	 */
	fetchResults() {
		this.props.communityProfileStore.reset(); // clear results and reset pageIndex and totalResults
		this.props.communityProfileStore.fetch(true, false);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getCategoryDropDown() {
		const user = this.context.applicationStore.user;
		if (user) {
			const options = this.context.applicationStore.communityCategoriesStore.categories.map(
				(category, index) => {
					return (
						<option key={`comcat-${index}`} value={category.id}>
							{category.getName(user.language)}
						</option>
					);
				}
			);

			// Sort by label
			options.sort((a, b) => {
				return a.props.children.localeCompare(b.props.children);
			});

			options.unshift(
				<option key="comcat-*" value="*">
					{this.props.intl.formatMessage({ id: 'community.category.default' })}
				</option>
			);

			return (
				<DropDown
					name="category"
					value={this.props.communityProfileStore.category}
					onChange={this.onCategoryChange}>
					{options}
				</DropDown>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getLoadMore() {
		if (
			!this.context.applicationStore.communityProfileStore.hasNext() ||
			this.context.applicationStore.communityProfileStore.fetching
		) {
			return null;
		}

		return (
			<div className="community-overview__load-more grid grid--center">
				<button type="button" onClick={this.onLoadNext}>
					<FormattedMessage id="community.loadmore.button" />
				</button>
			</div>
		);
	}
}

CommunityOverview.contextType = ApplicationContext;

CommunityOverview.propTypes = {
	intl: PropTypes.object,
	communityProfileStore: PropTypes.instanceOf(CommunityProfileStore)
};

export default injectIntl(CommunityOverview);
