/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../signals/Signals';

import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';

import userUpdate from '../../requests/userUpdate';

/**
 *
 */
class OnBoardingUserBSN extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { errors: null, submitting: false };

		this.onSkip = this.onSkip.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);

		this.onInputChange = this.onInputChange.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const formClasses = classNames({ 'form--submitting': this.state.submitting });

		return (
			<div className={`panel border border--dark ${this.props.className}`}>
				<form
					className={`on-boarding__form ${formClasses}`}
					name="on-boarding"
					onSubmit={this.onSubmit}>
					<div className="on-boarding__header padding border--bottom border--dark">
						<h2>
							<FormattedMessage id="onboarding.user.bsn.title" />
						</h2>
					</div>

					<div className="padding-small">
						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="onboarding.field.user.bsn" />
								</label>
								<input
									type="text"
									name="bsn"
									required
									value={this.state.bsn !== undefined ? this.state.bsn : this.props.user.bsn}
									onChange={this.onInputChange}
								/>
							</FormField>
						</FormGroup>
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<button
							type="button"
							className="on-boarding__skip-button button--tertiary"
							onClick={this.onSkip}>
							{this.props.intl.formatMessage({ id: 'onboarding.form.skip' })}
						</button>
						<input
							className="on-boarding__submit-button"
							type="submit"
							value={this.props.intl.formatMessage({ id: 'onboarding.form.submit' })}
						/>
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		this.state[e.target.name] = e.target.value;
		this.forceUpdate();
	}

	/**
	 *
	 */
	onSkip() {
		this.props.successCallback();
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		if (this.state.submitting) {
			return;
		}

		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'submitting':
					case 'errors':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		this.setState({ errors: null, submitting: true });
		userUpdate(data).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		this.setState({ submitting: false });
		this.props.user.update(response.data);
		this.props.successCallback(this);
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		this.setState({ submitting: false });
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}
}

OnBoardingUserBSN.propTypes = {
	intl: PropTypes.object,
	successCallback: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	className: PropTypes.string
};

export default injectIntl(OnBoardingUserBSN);
