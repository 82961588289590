import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_OFFERS_SETTINGS.replace(':id', companyId),
		null,
		headers,
		true
	);
};
