import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import TodoRow from './TodoRow';
import { ApplicationContext } from '../../../ApplicationContext';
import { uniqueKey } from '../../../utils/ReactUtils';

@observer
/**
 *
 */
export default class TodoList extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();

		if (!company) return null;

		const todoRows = this.props.todos.map((todo) => {
			return (
				<TodoRow
					className="col--12"
					key={uniqueKey('tr-')}
					todo={todo}
					showProfileImage={this.props.showProfileImages}
					showSecondaryText={this.props.showSecondaryText}
				/>
			);
		});

		let emptyList = null;
		if (this.props.todos.length <= 0 && !this.context.applicationStore.todoStore.fetching) {
			const type = this.context.applicationStore.todoStore.todoImageIndex;
			emptyList = (
				<div className="todo-list__empty">
					<div className="todo-list__empty-content-wrapper">
						<div className="todo-list__empty-image" data-imagetype={type} />
						<h2>
							<FormattedMessage id="todo.empty" />
						</h2>
					</div>
				</div>
			);
		}

		return (
			<div className="todo-list border--top border--dark">
				{todoRows}
				{emptyList}
			</div>
		);
	}
}

TodoList.contextType = ApplicationContext;

TodoList.propTypes = {
	todos: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	todoStore: PropTypes.object.isRequired,
	showProfileImages: PropTypes.bool,
	showSecondaryText: PropTypes.bool
};

TodoList.defaultProps = {
	showSecondaryText: true
};
