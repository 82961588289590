import React from 'react';
import PropTypes from 'prop-types';

import Signals from '../../../signals/Signals';

export default class ModalError extends React.Component {
	render() {
		return (
			<div className="modal-error grid grid--center">
				<h1 className="col--12 text--center">{this.props.title}</h1>
				<p className="col--12 text--center">{this.props.body}</p>

				<button type="button" onClick={this.onClick.bind(this)}>
					Ok
				</button>
			</div>
		);
	}

	onClick() {
		Signals.HideModal.dispatch();
	}
}

ModalError.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired
};

ModalError.defaultProps = {};
