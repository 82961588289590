import { observable } from 'mobx';

import Question from './models/Question';

import Signals from '../signals/Signals';
import financialYearFinancialStatement from '../requests/financialYearFinancialStatement';
import financialStatement from '../requests/financialStatement';
import financialStatementFilesDelete from '../requests/financialStatementFilesDelete';

/**
 *
 */
export default class FinancialStatementStore {
	@observable fetching = false;

	@observable saving = false;

	@observable id;

	@observable submitted = false;

	@observable question1 = new Question();

	@observable question2 = new Question();

	@observable question3 = new Question();

	/**
	 *
	 * @param financialYearId
	 * @returns {*|Promise<T>}
	 */
	fetch(financialYearId) {
		this.fetching = true;
		return financialYearFinancialStatement(financialYearId)
			.then((response) => {
				this._parseData(response.data);
				this.fetching = false;
			})
			.catch((error) => {
				this.fetching = false;
				Signals.Error.dispatch(error);
			});
	}

	/**
	 *
	 */
	reset() {
		this.fetching = false;
		this.saving = false;

		this.id = undefined;
		this.submitted = false;

		this.question1 = new Question();
		this.question2 = new Question();
		this.question3 = new Question();
	}

	/**
	 *
	 * @param onSaveSuccess
	 * @param submit
	 */
	save(onSaveSuccess, submit = false) {
		if (this.saving) {
			return;
		}

		this.saving = true;

		const fileIdsToRemove = this.question1.removedFileIds
			.concat(this.question2.removedFileIds)
			.concat(this.question3.removedFileIds);
		const formData = this._createFormData();
		if (submit) {
			formData.append('submitted', true);
		}

		// Save with file removal
		if (fileIdsToRemove.length > 0) {
			// Delete files first
			financialStatementFilesDelete(fileIdsToRemove)
				.then(() => {
					// Clear removed files array
					this._clearRemovedFiles();

					// Update FinancialStatement with latest data
					financialStatement(formData)
						.then((response) => {
							// Update data with latest results
							this._updateData(response.data);
							this.saving = false;
							onSaveSuccess();
							// Handle error
						})
						.catch((error) => {
							this.saving = false;
							Signals.Error.dispatch(error);
						});
					// Handle error
				})
				.catch((error) => {
					this.saving = false;
					Signals.Error.dispatch(error);
				});
			// Just save
		} else {
			// Update FinancialStatement with latest data
			financialStatement(formData)
				.then((response) => {
					// Update data with latest results
					this._updateData(response.data);
					this.saving = false;
					onSaveSuccess();
					// Handle error
				})
				.catch((error) => {
					this.saving = false;
					Signals.Error.dispatch(error);
				});
		}
	}

	/**
	 *
	 * @returns {boolean}
	 */
	isComplete() {
		return (
			this.question1.isComplete() && this.question2.isComplete() && this.question3.isComplete()
		);
	}

	/**
	 *
	 * @param data
	 * @private
	 */
	_parseData(data) {
		this.id = data.id;
		this.submitted = data.submitted;

		this.question1 = new Question(
			data.question1 == null ? undefined : data.question1,
			data.question1Files,
			data.question1FileInfos
		);
		this.question2 = new Question(
			data.question2 == null ? undefined : data.question2,
			data.question2Files,
			data.question2FileInfos
		);
		this.question3 = new Question(
			data.question3 == null ? undefined : data.question3,
			data.question3Files,
			data.question3FileInfos
		);
	}

	/**
	 *
	 * @param data
	 * @private
	 */
	_updateData(data) {
		this.question1.update(data.question1Files, data.question1FileInfos);
		this.question2.update(data.question2Files, data.question2FileInfos);
		this.question3.update(data.question3Files, data.question3FileInfos);
	}

	/**
	 *
	 * @private
	 */
	_clearRemovedFiles() {
		this.question1.removedFileIds = [];
		this.question2.removedFileIds = [];
		this.question3.removedFileIds = [];
	}

	/**
	 *
	 * @returns {FormData}
	 * @private
	 */
	_createFormData() {
		const formData = new FormData();
		formData.append('id', this.id);

		this.question1.addToFormData(formData, 'question1');
		this.question2.addToFormData(formData, 'question2');
		this.question3.addToFormData(formData, 'question3');

		return formData;
	}
}
