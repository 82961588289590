import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { injectIntl } from 'react-intl';

/**
 *
 */
class NumberInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { value: undefined };
		this.input = null;

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		const value = this.formatCurrency(this.input.value);
		this.setState({ value });
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { disabled, required, className, showIcon, characterIcon, name, placeholder } =
			this.props;
		const { value, selectionEnd, selectionStart } = this.state;
		return (
			<div
				className={`number-input ${className || ''} ${classNames({
					'number-input__icon--hidden': !showIcon
				})}`}>
				{showIcon ? <div className="number-input__icon">{characterIcon}</div> : null}
				<input
					className="number-input__input"
					type="text"
					ref={(input) => {
						this.input = input;

						// Make sure React remembers cursor position, see https://github.com/facebook/react/issues/955
						if (input && this.state && !isNaN(parseInt(selectionStart, 10))) {
							input.selectionStart = selectionStart;
							input.selectionEnd = selectionEnd || selectionStart;
						}
					}}
					autoComplete="off"
					required={required}
					disabled={disabled}
					name={name}
					value={value !== undefined ? value : this.props.value}
					placeholder={placeholder}
					onChange={this.onChange}
					onKeyUp={this.onChange}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
				/>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		this.state.value = e.target.value.replace(/[^0-9,.-]/, '');

		// Remember cursor position
		// this.state.selectionStart = this.input.selectionStart;
		// this.state.selectionEnd = this.input.selectionEnd;

		this.setState({
			selectionStart: this.input.selectionStart,
			selectionEnd: this.input.selectionEnd
		});

		// this.forceUpdate();
		this._update(e);
	}

	/**
	 *
	 * @param e
	 */
	onBlur(e) {
		this.state.value = this.formatCurrency(e.target.value);

		// Erase cursor position
		this.state.selectionStart = null;
		this.state.selectionEnd = null;

		this._update(e);
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {}

	/**
	 *
	 * @param n
	 * @return {*}
	 */
	formatCurrency(n) {
		if (n === null || n === undefined || n === '') {
			return null;
		}

		n = n.replace(',', '.');
		n = parseFloat(n);
		n = isNaN(n) ? '0.00' : n.toFixed(2);
		return n.replace('.', ',');
	}

	/**
	 *
	 * @param e
	 * @private
	 */
	_update(e) {
		if (this.props.onChange) {
			e.target.value = this.state.value;
			this.props.onChange(e);
		}
	}
}

NumberInput.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	showIcon: PropTypes.bool,
	characterIcon: PropTypes.string
};

NumberInput.defaultProps = {
	placeholder: '0,00',
	required: true,
	showIcon: true,
	characterIcon: '€'
};

export default injectIntl(NumberInput);
