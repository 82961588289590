import React from 'react';
import { FormattedDate, FormattedHTMLMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { forceDownload } from '../../../utils/fileUtils';

import { ApplicationContext } from '../../../ApplicationContext';

import Document from '../../../stores/models/Document';
import Signals from '../../../signals/Signals';
import DocumentActions from '../../../components/ui/ToolTip/tooltips/DocumentActions';
import EndPoints from '../../../data/EndPoints';
import documentsUserDelete from '../../../requests/documentsUserDelete';
import documentsCompanyDelete from '../../../requests/documentsCompanyDelete';
import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import DocumentStore from '../../../stores/DocumentStore';

/**
 *
 */
class DocumentRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.confirmRemove = this.confirmRemove.bind(this);
		this.showActions = this.showActions.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onRemoveError = this.onRemoveError.bind(this);
		this.onRemoveSuccess = this.onRemoveSuccess.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {}

	/**
	 *
	 */
	componentDidMount() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div
				className={`document-row grid grid--spread grid--vcenter padding border-top border--dark ${this.props.className}`}>
				<div className="document-row__info" onClick={() => this.open()}>
					<div className="document-row__filename">{this.props.document.fileName}</div>
					<span className="document-row__filedate">
						{this.props.document.modified ? (
							<FormattedDate value={this.props.document.getModifiedAsDate()} />
						) : null}
					</span>
				</div>
				<div
					className="document-row__actions grid grid--center grid--vcenter"
					onClick={this.showActions}>
					<span>...</span>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'open':
				this.open();
				break;
			case 'download':
				this.download();
				break;
			case 'remove':
				this.remove();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param e
	 */
	showActions(e) {
		e.preventDefault();

		const { canRemove } = this.props;
		const hideTypes = [];

		if (!canRemove) {
			hideTypes.push('remove');
		}

		Signals.ShowToolTip.dispatch(
			<DocumentActions onSelect={this.onActionSelect} hideTypes={hideTypes} />,
			e.target,
			-16
		);
	}

	/**
	 *
	 */
	open(_e) {
		window.open(window.config.apiPrefix + this._getBaseFileUrl());
	}

	/**
	 *
	 */
	download() {
		const fileUrl = `${window.config.apiPrefix + this._getBaseFileUrl()}?download=true`;
		forceDownload(fileUrl);
	}

	/**
	 *
	 */
	remove() {
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={this.props.intl.formatMessage(
					{ id: 'documents.remove.confirm.title' },
					{ fileName: this.props.document.fileName }
				)}
				body={this.props.intl.formatMessage(
					{ id: 'documents.remove.confirm.body' },
					{ fileName: this.props.document.fileName }
				)}
				onConfirm={this.confirmRemove}
			/>
		);
	}

	/**
	 *
	 */
	confirmRemove() {
		const currentRouteParams = this.context.applicationStore.currentRouteParams;

		const documentId = this.props.document.id;
		const companyId = currentRouteParams.id;

		// Company
		if (currentRouteParams.id !== null && currentRouteParams.id !== undefined) {
			const company = this.context.applicationStore.user.getCompanyById(
				parseInt(currentRouteParams.id, 10)
			);
			const financialYear = company.getFinancialYearByYear(parseInt(this.props.document.year, 10));

			documentsCompanyDelete(companyId, financialYear.id, documentId)
				.then(this.onRemoveSuccess)
				.catch(this.onRemoveError);

			// User
		} else {
			documentsUserDelete(documentId).then(this.onRemoveSuccess).catch(this.onRemoveError);
		}
	}

	/**
	 *
	 * @param e
	 */
	onRemoveSuccess(_e) {
		this.props.documentStore.remove(this.props.document);

		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="documents.removed.message"
				values={{ fileName: this.props.document.fileName }}
			/>
		);
	}

	/**
	 *
	 * @param error
	 */
	onRemoveError(error) {
		Signals.Error.dispatch(error);
	}

	/**
	 *
	 * @return {string}
	 * @private
	 */
	_getBaseFileUrl() {
		const documentId = this.props.document.id;

		const currentRouteParams = this.context.applicationStore.currentRouteParams;
		let fileURL = null;

		// Company
		if (currentRouteParams.id !== null && currentRouteParams.id !== undefined) {
			const company = this.context.applicationStore.user.getCompanyById(
				parseInt(currentRouteParams.id, 10)
			);
			const financialYear = company.getFinancialYearByYear(parseInt(this.props.document.year, 10));
			fileURL = EndPoints.DOCUMENT_COMPANY.replace(':id', company.id)
				.replace(':year', financialYear.id)
				.replace(':documentId', documentId);

			// User
		} else {
			fileURL = EndPoints.DOCUMENT_USER.replace(':documentId', documentId);
		}

		return fileURL;
	}
}

export default injectIntl(DocumentRow);

DocumentRow.contextType = ApplicationContext;

DocumentRow.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	document: PropTypes.instanceOf(Document).isRequired,
	documentStore: PropTypes.instanceOf(DocumentStore).isRequired,
	canRemove: PropTypes.bool
};

DocumentRow.defaultProps = {
	className: '',
	canRemove: true
};
