import AbstractCommand from '../AbstractCommand';
import InvoiceConceptModel from '../../stores/models/InvoiceConceptModel';
import invoiceConceptGet from '../../requests/invoiceConceptGet';

/**
 *
 */
export default class GetInvoiceConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param invoiceConceptModel InvoiceConceptModel
	 * @param company Company
	 */
	constructor(invoiceConceptModel, company) {
		super();

		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return invoiceConceptGet(this.company.id, this.invoiceConceptModel.id)
			.then((response) => {
				InvoiceConceptModel.updateInvoiceConcept(response.data, this.invoiceConceptModel);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
