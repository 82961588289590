import { observable, action, computed } from 'mobx';
import { BankAccount } from './models/BankAccount';
import Store from './Store';
import GetBankAccountsCommand from '../commands/bank/GetBankAccountsCommand';
import ToggleBankAccountCommand from '../commands/bank/ToggleBankAccountCommand';

export default class BankAccountStore extends Store {
	@observable isFetched = false;

	constructor() {
		super(BankAccount);
	}

	@action reset() {
		super.reset();
		this.isFetched = false;
	}

	@action fetch(companyId) {
		const command = new GetBankAccountsCommand();
		this.isFetched = false;
		return command.execute(companyId).then((response) => {
			if (response) {
				this.addAll(response, true);
				this.isFetched = true;
			} else {
				this.reset();
			}
		});
	}

	/**
	 *
	 * @param {BankAccount} bankAccount
	 * @returns
	 */
	@action toggleBankAccount(companyId, bankAccount) {
		console.log('BankAccountStore.toggleBankAccount', bankAccount);
		const command = new ToggleBankAccountCommand();
		return command.execute(companyId, bankAccount).then((response) => {
			if (response) {
				bankAccount.update(response);
			}
		});
	}

	@computed get enabledBankAccounts() {
		return this.list
			.filter((bankAccount) => bankAccount.enabled)
			.map((bankAccount) => bankAccount.reference);
	}
}
