/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import classNames from 'classnames';
import Signals from '../../../signals/Signals';

import SearchField from '../../../components/ui/SearchField/SearchField';
import Page from '../../Page';
import InvoicesRow from './components/InvoicesRow';

import { ApplicationContext } from '../../../ApplicationContext';
import InvoicesConceptStore from '../../../stores/InvoicesConceptStore';
import GetArchivedInvoicesConceptCommand from '../../../commands/invoiceConcepts/GetArchivedInvoicesConceptCommand';
import TableWrapper from '../../../components/ui/TableWrapper/TableWrapper';

@observer
/**
 *
 */
class InvoicesArchive extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onSortClick = this.onSortClick.bind(this);
		this.onCompanyChanged = this.onCompanyChanged.bind(this);

		this.fieldHeaderSettings = [
			{
				id: 'status',
				label: this.props.intl.formatMessage({ id: 'invoices.header.status' }),
				isDefault: true,
				minimal: true
			},
			{
				id: 'expirationDate',
				label: this.props.intl.formatMessage({ id: 'invoices.header.expirationdate' }),
				right: true,
				minimal: true
			},
			{
				id: 'invoiceNr',
				label: this.props.intl.formatMessage({ id: 'invoices.header.invoicenr' }),
				minimal: true
			},
			{ id: 'companyName', label: this.props.intl.formatMessage({ id: 'invoices.header.client' }) },
			{ id: 'subject', label: this.props.intl.formatMessage({ id: 'invoices.header.subject' }) },
			{
				id: 'amount',
				label: this.props.intl.formatMessage({ id: 'invoices.header.amount' }),
				right: true,
				minimal: true
			},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'invoices.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.CompanyChanged.add(this.onCompanyChanged);
		this.onCompanyChanged();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.onCompanyChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (!company) {
			return null;
		}

		// Create table headers
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`invoices__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!this.props.invoicesConceptArchiveStore.sortBy && fieldData.isDefault,
						'table__header--sorted': this.props.invoicesConceptArchiveStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							this.props.invoicesConceptArchiveStore.sortBy === fieldData.id &&
							this.props.invoicesConceptArchiveStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});

		// Create invoices rows
		const rows = this.props.invoicesConceptArchiveStore.filteredAndSortedInvoiceConcepts.map(
			(invoiceConcept) => {
				// create DOM
				return (
					<InvoicesRow
						key={`i-${invoiceConcept.id}-${invoiceConcept.invoiceNr}`}
						invoiceConceptModel={invoiceConcept}
					/>
				);
			}
		);

		return (
			<Page pageName="invoices">
				<div className="invoices__wrapper border border--dark">
					<div className="invoices__options grid grid--spread">
						<SearchField
							name="search"
							// eslint-disable-next-line react/jsx-no-bind
							onChange={this.onSearch.bind(this)}
							placeholder={this.props.intl.formatMessage({ id: 'invoices.search' })}
							value={
								this.props.invoicesConceptArchiveStore.searchFilter
									? this.props.invoicesConceptArchiveStore.searchFilter
									: undefined
							}
						/>
					</div>

					<TableWrapper scrollableX>
						<table className="table">
							<thead className="table__headers">
								<tr>{headers}</tr>
							</thead>
							<tbody className="table__body">{rows}</tbody>
						</table>
					</TableWrapper>

					<div className="table__footer options-footer grid grid--right border--top" />
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	onCompanyChanged() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new GetArchivedInvoicesConceptCommand(
				this.props.invoicesConceptArchiveStore,
				company.id
			);
			command.execute();
		}
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.props.invoicesConceptArchiveStore.searchFilter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.props.invoicesConceptArchiveStore.sortBy === field) {
			this.props.invoicesConceptArchiveStore.sortDirection =
				-this.props.invoicesConceptArchiveStore.sortDirection;
		} else {
			this.props.invoicesConceptArchiveStore.sortBy = field;
			this.props.invoicesConceptArchiveStore.sortDirection = 1;
		}
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}
}

InvoicesArchive.contextType = ApplicationContext;

InvoicesArchive.propTypes = {
	intl: PropTypes.object,
	// eslint-disable-next-line react/no-unused-prop-types
	showOpenOnly: PropTypes.bool,
	invoicesConceptArchiveStore: PropTypes.instanceOf(InvoicesConceptStore).isRequired
};

InvoicesArchive.defaultProps = {
	showOpenOnly: false
};

export default injectIntl(InvoicesArchive);
