import AbstractCommand from '../AbstractCommand';
import incomeCreate from '../../requests/incomeCreate';
import objectToFormData from '../../utils/objectToFormData';
import {AutocompleteTypes} from '../../data/AutocompleteSearchObject';
import Signals from '../../signals/Signals';
import {FormattedHTMLMessage} from 'react-intl';

import React from 'react';

/**
 *
 */
export default class CreateIncomeCommand extends AbstractCommand {

	/**

	 */
	constructor(applicationStore, incomeModel, file = null) {
		super();

		this.applicationStore = applicationStore;
		this.incomeStore = this.applicationStore.incomeStore;
		this.autocompleteStore = this.applicationStore.autocompleteStore;
		this.financialYear = this.applicationStore.getSelectedFinancialYear();

		this.incomeModel = incomeModel;
		this.file = file;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		const ignoreFields = [];
		if(!this.file) {
			ignoreFields.push('fileName');
		}

		// Create FormData
		const formData = objectToFormData(this.incomeModel, true, ignoreFields);
		console.log(formData, this.incomeModel);

		// Add or remove file
		if(this.file) {
			formData.append('file', this.file);
		}

		//data, financialYearId
		return incomeCreate(formData, this.financialYear.id).then((response) => {
				const invoiceModel = this.incomeStore.addIncome(response.data);
				this.autocompleteStore.add(invoiceModel.clone(), AutocompleteTypes.TYPE_INVOICES);

				Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="income.added.message" values={{invoiceId:response.data.invoiceNr}}/>));

				if(success) {
					success(response);
				}
			}).catch((err) => {
				if(error) {
					error(err);
				}
			});
	}
}
