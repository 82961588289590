/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import { buildAssetURL } from '../../../utils/assetUtils';
import UserMessageModel from '../../../stores/models/UserMessage';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
class UserMessage extends React.Component {
	getMarkedBody() {
		return { __html: marked(this.props.message.body) };
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { type, title, subTitle, assetUuid } = this.props.message;

		// Fallback image
		let imageSrc = `${window.config.imageRoot}usermessages/${type}.png`;

		// If an image has been uploaded
		if (assetUuid) {
			imageSrc = buildAssetURL(assetUuid);
		}

		return (
			<div className="user-message grid">
				<div className="user-message__left col--5">
					<img src={imageSrc} alt={type} />
				</div>
				<div className="user-message__right col--7 grid">
					<div>
						<h1>{title}</h1>
						<label>{subTitle}</label>
						{/* eslint-disable-next-line react/no-danger */}
						<p className="user-message__body" dangerouslySetInnerHTML={this.getMarkedBody()} />
					</div>
				</div>
			</div>
		);
	}
}

UserMessage.contextType = ApplicationContext;

UserMessage.propTypes = {
	message: PropTypes.instanceOf(UserMessageModel)
};

export default UserMessage;
