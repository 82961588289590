import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { autorun } from 'mobx';
import useBankAccountStore from '@/utils/useBankAccountStore';
import useBankRecordStore from '@/utils/useBankRecordStore';
import useBankbookAnimationStore from '@/utils/useBankbookAnimationStore';
import { hideIBANExceptLastN } from '@/utils/ibanUtils';
import FormattedMessageWithClass from '@/components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import { Observer } from 'mobx-react';
import BulletItem from './BulletItem';

function InsightsAnimation({ intl }) {
	const [currentAccountIndex, setCurrentAccountIndex] = React.useState(0);
	const bankbookAnimationStore = useBankbookAnimationStore();
	const bankAccountStore = useBankAccountStore();
	const bankRecordStore = useBankRecordStore();
	const animationDuration = Math.floor(bankbookAnimationStore.duration * 0.75);
	const animationFinishDelay = bankbookAnimationStore.duration - animationDuration;

	useEffect(() => {
		const loadingCancel = autorun(() => {
			if (
				bankRecordStore.isFetched &&
				bankAccountStore.isFetched &&
				bankbookAnimationStore.isLoading
			) {
				bankbookAnimationStore.stopLoading();
			}
		});
		return () => {
			loadingCancel();
			bankbookAnimationStore.reset();
		};
	}, [bankRecordStore, bankAccountStore, bankbookAnimationStore]);

	useEffect(() => {
		const animationCancel = autorun(() => {
			if (bankbookAnimationStore.isLoading || !bankbookAnimationStore.isAnimating) return;
			if (bankbookAnimationStore.currentStep !== 1) return;
			if (bankAccountStore.enabledBankAccounts.length === 0) {
				bankbookAnimationStore.stopAnimationImmediately();
				return;
			}
			const accountSwitchInteval = setInterval(() => {
				setCurrentAccountIndex((prevIndex) => {
					if (
						prevIndex === bankAccountStore.enabledBankAccounts.length - 1 &&
						bankbookAnimationStore.currentStep === 1
					) {
						bankbookAnimationStore.incrementCurrentStep();
						bankbookAnimationStore.resetAccountSwitchInterval();
						const finishTimeout = setTimeout(() => {
							bankbookAnimationStore.stopAnimation();
						}, animationFinishDelay);
						bankbookAnimationStore.setTimeoutInterval(finishTimeout);
						return prevIndex;
					}
					if (
						bankAccountStore.enabledBankAccounts.length > 0 &&
						prevIndex < bankAccountStore.enabledBankAccounts.length - 1
					) {
						return prevIndex + 1;
					}
					return prevIndex;
				});
			}, animationDuration / bankAccountStore.enabledBankAccounts.length);
			if (!currentAccountIndex) {
				bankbookAnimationStore.resetAccountSwitchInterval();
				setCurrentAccountIndex(0);
			}
			bankbookAnimationStore.setAccountSwitchInterval(accountSwitchInteval);
		});
		return () => {
			bankbookAnimationStore.resetAccountSwitchInterval();
			animationCancel();
		};
	}, [bankbookAnimationStore, bankAccountStore, currentAccountIndex]);

	return (
		<Observer>
			{() => (
				<div className="flex flex-col justify-center items-center content-center">
					<FormattedMessageWithClass
						className="font-bold text-xl"
						id="company.bankbook.transactions.loading.title"
					/>

					<div className="flex flex-col gap-y-4 relative mt-8">
						<BulletItem
							isLoading={bankbookAnimationStore.isLoading}
							label={intl.formatMessage(
								{
									id: 'company.bankbook.transactions.loading.bullet.1.label'
								},
								{
									account: bankbookAnimationStore.isLoading
										? '****'
										: hideIBANExceptLastN(
												bankAccountStore.enabledBankAccounts[currentAccountIndex]
										  ),
									count: bankbookAnimationStore.isLoading ? '....' : bankRecordStore.list.length
								}
							)}
						/>
						<div
							className={classNames(
								'absolute w-0.5 h-6 left-[14px] -z-10 top-6 bg-secondary-darker',
								{
									'bg-zinc-300': bankbookAnimationStore.currentStep === 1
								}
							)}
						/>
						<BulletItem
							label={intl.formatMessage(
								{
									id: 'company.bankbook.transactions.loading.bullet.2.label'
								},
								{
									count:
										bankbookAnimationStore.currentStep !== 2
											? '...'
											: bankRecordStore.automatchedCount
								}
							)}
							isLoading={bankbookAnimationStore.currentStep !== 2}
						/>
					</div>
				</div>
			)}
		</Observer>
	);
}

InsightsAnimation.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(InsightsAnimation);
