import request from 'superagent';
import Methods from '../data/Methods';
import RequestController from '../controllers/RequestController';

/**
 *
 * @param method
 * @param url
 * @param fetchOptions FetchOptions
 * @return {Promise<any> | Promise}
 */
export default (method, url, fetchOptions = {}) =>
	new Promise((resolve, reject) => {
		const apiCall = request(
			method,
			!fetchOptions.ignorePrefix ? window.config.apiPrefix + url : url
		).set('Accept', 'application/json');

		// Store request object
		fetchOptions.setRequest(apiCall);

		// Apply timeout
		if (!fetchOptions.ignoreResponseTimeout) {
			apiCall.timeout({
				response: 180000, // Wait 3 seconds for the server to start sending,
				deadline: 180000 // but allow 3 minutes for the request to finish.
			});
		}

		// Add to blocking queue
		if (fetchOptions.blockUI) {
			RequestController.addRequest(apiCall);
		}

		// Set optional headers
		if (fetchOptions.headers) {
			for (const key in fetchOptions.headers) {
				if (fetchOptions.headers.hasOwnProperty(key)) {
					apiCall.set(key, fetchOptions.headers[key]);

					if (key === 'Accept' && fetchOptions.headers[key] === 'application/pdf') {
						apiCall.responseType('blob');
					}
				}
			}
		}

		// Use credentials
		if (fetchOptions.withCredentials) {
			apiCall.withCredentials();
		}

		// Add parameters and do request
		if (fetchOptions.parameters) {
			// eslint-disable-next-line no-unused-expressions
			method === Methods.GET
				? apiCall.query(fetchOptions.parameters)
				: apiCall.send(fetchOptions.parameters);
		}

		// Handle result or error
		apiCall.end((error, result) => {
			// remove from blocking queue
			if (fetchOptions.blockUI) {
				RequestController.removeRequest(apiCall);
			}

			// handle response
			if (error) {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject({ error, response: result });
			} else {
				resolve(result.body);
			}
		});
	});
