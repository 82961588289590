import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, brandId) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.DELETE,
		EndPoints.COMPANY_BRANDS_ID.replace(':companyId', companyId).replace(':id', brandId),
		null,
		headers,
		true
	);
};
