import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ActionModel from '../../../../stores/models/ActionModel';

/**
 * Generic Actions, uses ActionModel input to display options
 */
export default class ActionsToolTip extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.options = props.options;

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const options = this.options;
		const { className } = this.props;

		const rows = options.map((option, index) => {
			return (
				<div
					key={`ac-${index}`}
					className={`actions__action ${className}`}
					data-type={option.type}
					onClick={this.onClick}>
					{option.icon ? <i className={`icon icon--${option.icon}-black`} /> : null}
					<FormattedMessage id={option.label} />
				</div>
			);
		});

		return <div className="actions">{rows}</div>;
	}

	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.type;
		this.props.onSelect(action);
	}
}

ActionsToolTip.propTypes = {
	onSelect: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(ActionModel).isRequired,
	className: PropTypes.string
};

ActionsToolTip.defaultProps = {
	className: ''
};
