import { autorun, observable, action, computed, toJS } from 'mobx';
import { BankRecordType } from '../data/BankRecordType';
import BankRecordMatchType from '../data/BankRecordMatchType';
// eslint-disable-next-line
import BankRecord from './models/BankRecord';
// eslint-disable-next-line
import IncomeModel from './models/IncomeModel';
// eslint-disable-next-line
import ExpenseModel from './models/ExpenseModel';
// eslint-disable-next-line
import IncomeStore from './IncomeStore';
// eslint-disable-next-line
import ExpenseStore from './ExpenseStore';
// eslint-disable-next-line
import BankRecordStore from './BankRecordStore';
// eslint-disable-next-line
import FinancialYear from './models/FinancialYear';
import BankRecordMatchedResource from './models/BankRecordMatchedResource';

export default class BankRecordMatchStore {
	/**
	 * @type {BankRecord}
	 */
	@observable bankRecordInEdit = null;

	/**
	 * @type {IncomeModel|ExpenseModel}
	 */
	@observable bankRecordMatchedResourceInEdit = null;

	/**
	 * @type {bool}
	 */
	@observable isEditted = false;

	/**
	 * Set the bank record in edit and set the matched resource in edit
	 * If the bank record is matched than find and set the matched resource
	 * Else set the matched resource to null
	 * @param {BankRecord} bankRecordInEdit
	 * @param {IncomeStore} incomeStore
	 * @param {ExpenseStore} expenseStore
	 * @param {BankRecordStore} bankRecordStore
	 * @param {FinancialYear} fiscalYear
	 */
	constructor(bankRecordInEdit, incomeStore, expenseStore, bankRecordStore, fiscalYear) {
		this.incomeStore = incomeStore;
		this.expenseStore = expenseStore;
		this.bankRecordStore = bankRecordStore;
		this.fiscalYear = fiscalYear;
		this.isEditted = false;
		this._fetchIncomeExpenseStore();
		this.reset();
		this.setBankRecordInEdit(bankRecordInEdit);
	}

	@action reset() {
		this.fiscalYear = null;
		this.bankRecordInEdit = null;
		this.bankRecordMatchedResourceInEdit = null;
		this.isEditted = false;
		this._resetIncomeExpenseStore();
	}

	/**
	 * Set the bank record in edit and set the matched resource in edit.
	 */
	@action setBankRecordInEdit(bankRecordInEdit) {
		// Use the observale to create a new instance of the model
		if (bankRecordInEdit) {
			this.bankRecordInEdit = new BankRecord(bankRecordInEdit);
		}
		autorun(() => {
			if (
				this.bankRecordInEdit &&
				this.bankRecordInEdit.matchStatus === BankRecordMatchType.MATCHED
			) {
				if (
					this.bankRecordInEdit.matchedResource.type === BankRecordType.INCOME &&
					this.incomeStore.fetched
				) {
					this.setBankRecordMatchedResourceInEdit(
						this.incomeStore.findById(this.bankRecordInEdit.matchedResource.id)
					);
				} else if (
					this.bankRecordInEdit.matchedResource.type === BankRecordType.EXPENSE &&
					this.expenseStore.fetched
				) {
					this.setBankRecordMatchedResourceInEdit(
						this.expenseStore.findById(this.bankRecordInEdit.matchedResource.id)
					);
				}
			} else {
				this.setBankRecordMatchedResourceInEdit(null);
			}
		});
	}

	/**
	 * Set the matched resource in edit.
	 * If the bank record is matched than set the matched resource
	 * Else set the matched resource to null
	 * @param {IncomeModel|ExpenseModel} bankRecordMatchedResourceInEdit
	 */
	@action setBankRecordMatchedResourceInEdit(bankRecordMatchedResourceInEdit) {
		// Set the matched resource
		// Use the observale to create a new instance of the model
		this.bankRecordMatchedResourceInEdit =
			bankRecordMatchedResourceInEdit && observable(bankRecordMatchedResourceInEdit);
		// If the bank record is matched than ignore the matched id in the expense or income store.
		// Thus user won't see the matched resource in the expense or income table.
		if (bankRecordMatchedResourceInEdit && bankRecordMatchedResourceInEdit.id) {
			const ignoreIds = [this.bankRecordMatchedResourceInEdit.id];
			if (bankRecordMatchedResourceInEdit instanceof IncomeModel) {
				this.incomeStore.updateIgnoreIds(ignoreIds);
			} else {
				this.expenseStore.updateIgnoreIds(ignoreIds);
			}
		} else {
			this.bankRecordMatchedResourceInEdit = null;
			this.incomeStore.updateIgnoreIds([]);
			this.expenseStore.updateIgnoreIds([]);
		}
	}

	/**
	 * Update the original bankRecord on Save.
	 */
	@action save(bankRecordResponse) {
		if (this.bankRecordInEdit) {
			const originalRecord = this.bankRecordStore.find('id', this.bankRecordInEdit.id);
			if (!originalRecord) return;
			originalRecord.update(bankRecordResponse);
		}
	}

	@computed get matchedResource() {
		return BankRecordMatchedResource.createBankRecordMatchedResourceFromModel(
			this.bankRecordMatchedResourceInEdit
		);
	}

	@computed get bankRecord() {
		return {
			...toJS({
				...this.bankRecordInEdit,
				executionDate: new Date(this.bankRecordInEdit.executionDate)
			}),
			matchedResource:
				this.matchedResource &&
				toJS({ ...this.matchedResource, date: new Date(this.matchedResource.date) })
		};
	}

	@computed get bankRecordInEditMatchStatus() {
		return this.bankRecordMatchedResourceInEdit !== null
			? BankRecordMatchType.MATCHED
			: BankRecordMatchType.UNMATCHED;
	}

	@action setIsEditted(isEditted) {
		this.isEditted = isEditted;
	}

	/**
	 * Resets the filters on the stores.
	 */
	@action _resetIncomeExpenseStore() {
		this.incomeStore.updateIgnoreIds([]);
		this.expenseStore.updateIgnoreIds([]);
		this.incomeStore.filter = '';
		this.expenseStore.filter = '';
	}

	/**
	 * Fetch the incomes and expenses from the server if not fetched already.
	 */
	@action _fetchIncomeExpenseStore() {
		autorun(() => {
			if (this.fiscalYear && this.fiscalYear.id && this.incomeStore && this.expenseStore) {
				// eslint-disable-next-line no-unused-expressions
				!this.incomeStore.fetched && this.incomeStore.fetch(this.fiscalYear.id);
				// eslint-disable-next-line no-unused-expressions
				!this.expenseStore.fetched && this.expenseStore.fetch(this.fiscalYear.id);
			}
		});
	}
}
