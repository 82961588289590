import React from 'react';
import AbstractCommand from '../AbstractCommand';
import SendTypes from '../../data/SendTypes';
import Signals from '../../signals/Signals';

import invoiceConceptUpdateMarkAsSend from '../../requests/invoiceConceptUpdateMarkAsSend';
import invoiceConceptUpdateSendEmail from '../../requests/invoiceConceptUpdateSendEmail';
import invoiceConceptUpdateSendReminder from '../../requests/invoiceConceptUpdateSendReminder';
import {FormattedHTMLMessage} from 'react-intl';
import InvoiceConceptStatus from '../../data/InvoiceConceptStatus';

const INVOICE_STATE_KEYS = [
	'senderEmail',
	'recipientEmail',
	'ccRecipientEmail',
	'bccRecipientEmail',
	'emailSubject',
	'emailMessage',
	'remindCompanyUserInDays',
	'remindCompanyCustomerInDays'
];

/**
 *
 */
export default class SendInvoiceConceptCommand extends AbstractCommand {

	/**
	 *
	 * @param applicationStore ApplicationStore
	 * @param invoiceConceptModel InvoiceConceptModel
	 * @param company Company
	 * @param data object
	 * @param sendType string
	 */
	constructor(applicationStore, invoiceConceptModel, company, data, sendType) {
		super();

		this.applicationStore = applicationStore;
		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
		this.data = data;
		this.sendType = sendType;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		let request = null;
		let requestBody = {};

		switch (this.sendType) {
			case SendTypes.TYPE_MARKED_AS_SEND:
				request = invoiceConceptUpdateMarkAsSend;
				requestBody = this._createJSONData();
				break;
			case SendTypes.TYPE_SEND_EMAIL:
				request = invoiceConceptUpdateSendEmail;
				requestBody = this._createJSONData();
				break;
			case SendTypes.TYPE_SEND_REMINDER:
				request = invoiceConceptUpdateSendReminder;
				requestBody = {
					subject: this.data.reminderSubject,
					message: this.data.reminderMessage,
					to: this.data.recipientEmail
				};
				break;
		}

		if (request) {
			request(this.company.id, this.invoiceConceptModel.id, JSON.stringify(requestBody))
				.then((response) => {
					this._onSuccess();
					if (success) {
						success(response);
					}
				})
				.catch((err) => {
					if (error) {
						error(err);
					}
				});
		}

	}

	/**
	 *
	 * @private
	 */
	_onSuccess() {
		// Show status message
		let messageId;
		switch (this.sendType) {
			case SendTypes.TYPE_SEND_EMAIL:
				this.invoiceConceptModel.status = InvoiceConceptStatus.SENT_BY_EMAIL_KDB;
				messageId = 'invoice.sendemail.message';
				break;
			case SendTypes.TYPE_SEND_REMINDER:
				this.invoiceConceptModel.status = InvoiceConceptStatus.SENT_REMINDER_KDB;
				messageId = 'invoice.sendreminder.message';
				break;
			case SendTypes.TYPE_MARKED_AS_SEND:
				this.invoiceConceptModel.status = InvoiceConceptStatus.SENT_MANUALLY;
				messageId = 'invoice.markedassend.message';
				break;
		}

		if (messageId) {
			Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id={messageId} values={{
				invoiceNr: this.invoiceConceptModel.invoiceNr,
				email: this.data.recipientEmail}}/>));
		}

		// Navigate to overview
		//Signals.RequestRoute.dispatch(Routes.COMPANY_INVOICES_ALL.getPath({id: this.company.id}));
	}

	/**
	 *
	 * @returns {string}
	 * @private
	 */
	_createJSONData() {

		// Get initial InvoiceConceptModel state
		const jsonData = JSON.parse(this.invoiceConceptModel.toJSON());

		// Add changed state keys, filter out state keys which are not in INVOICE_STATE_KEYS
		INVOICE_STATE_KEYS.forEach((key) => {
			switch (key) {
				case 'remindCompanyUserInDays':
					jsonData[key] = this.data[key];
					break;
				default:
					jsonData[key] = this.data[key] ? this.data[key] : undefined;
			}
		});

		return jsonData;
	}
}
