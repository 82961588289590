import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Routes } from '../../data/Routes';

import Signals from '../../signals/Signals';

import ModalAlert from '../../components/ui/Modal/ModalAlert';

import Page from '../Page';

class ExpiredMessage extends React.Component {
	/**
	 *
	 */
	constructor() {
		super();

		this.state = {
			errors: null
		};
	}

	/**
	 *
	 */
	componentDidMount() {
		setTimeout(() => {
			const translationTitleId = 'generic.notfoundorexpired.title';
			const translationId = 'generic.notfoundorexpired.description';
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: translationTitleId })}
					body={this.props.intl.formatMessage({ id: translationId })}
					onConfirm={() => Signals.RequestRoute.dispatch(Routes.LOGIN.pathName)}
				/>
			);
		}, 500);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<Page pageName="expired-message page--logo">
				<div className="vertically-center" />
			</Page>
		);
	}
}

ExpiredMessage.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(ExpiredMessage);
