/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedNumber, FormattedMessage, injectIntl } from 'react-intl';

import Signals from '../../../../../signals/Signals';

import BTW from '../../../../../data/BTW';
import LedgerCodes from '../../../../../data/LedgerCodes';

import InfoIcon from '../../../../../components/ui/InfoIcon/InfoIcon';
import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import DropDown from '../../../../../components/ui/DropDown/DropDown';
import NumberInput from '../../../../../components/ui/NumberInput/NumberInput';
import ModalAlert from '../../../../../components/ui/Modal/ModalAlert';

const SHOW_VERLEGD = [
	BTW.VERLEGD.name,
	BTW.BINNENLAND.name,
	BTW.BUITENLAND.name,
	BTW.EU.name,
	BTW.BUITEN_EU.name,
	BTW.MMOS.name
];
const SHOW_BUITENLAND = [BTW.BUITENLAND.name, BTW.EU.name, BTW.BUITEN_EU.name, BTW.MMOS.name];
const SHOW_BTW_NUMBER = [BTW.EU.name];

const LEDGER_CODES = [
	LedgerCodes.GC_4501,
	LedgerCodes.GC_7100,
	LedgerCodes.GC_4601,
	LedgerCodes.GC_4300,
	LedgerCodes.GC_4401,
	LedgerCodes.GC_4201,
	LedgerCodes.GC_0101,
	LedgerCodes.GC_4100,
	LedgerCodes.GC_4602
];
const TRAVEL_LEDGER_CODES = [LedgerCodes.GC_4301, LedgerCodes.GC_4302];
const PRODUCTION_LEDGER_CODES = [LedgerCodes.GC_7101, LedgerCodes.GC_7102];
const PERSONNEL_LEDGER_CODES = [LedgerCodes.GC_4103, LedgerCodes.GC_4101];

const SHOW_INVESTMENT = [LedgerCodes.GC_0101];
const SHOW_TRAVEL = [LedgerCodes.GC_4300, LedgerCodes.GC_4301, LedgerCodes.GC_4302];
const SHOW_PRODUCTION = [LedgerCodes.GC_7100, LedgerCodes.GC_7101, LedgerCodes.GC_7102];
const SHOW_PERSONNEL = [LedgerCodes.GC_4100, LedgerCodes.GC_4103, LedgerCodes.GC_4101];

/**
 *
 */
class ExpenseRowInput extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = {
			id: props.id,
			amount: props.amount,
			btw: props.btw,
			ledgerCode: props.ledgerCode
				? props.ledgerCode
				: LedgerCodes.getCodeByObject(LEDGER_CODES[0]),
			disinvestDate: null,
			investYears: props.investYears,
			investResidualValue: props.investResidualValue,
			rowIndex: props.rowIndex
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onRemoveClick = this.onRemoveClick.bind(this);
		this.showTypeInfo = this.showTypeInfo.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		if (this.props.onChange) {
			const data = {};
			for (const key in this.state) {
				if (this.state.hasOwnProperty(key)) {
					switch (key) {
						case 'rowIndex':
							// Ignore
							break;
						default:
							data[key] = this.state[key];
							break;
					}
				}
			}

			// Trigger first change
			this.props.onChange(this.props.rowIndex, data);
		}
	}

	/**
	 *
	 */
	render() {
		// Expense Type
		const ledgerCodeObject = LedgerCodes.getObjectByCode(this.state.ledgerCode);
		const adjustedCode = LedgerCodes.getAdjustedCode(ledgerCodeObject);

		// VAT
		const btwType = SHOW_VERLEGD.indexOf(this.state.btw) >= 0 ? BTW.VERLEGD.name : this.state.btw;
		const btwCountry =
			SHOW_BUITENLAND.indexOf(this.state.btw) >= 0 ? BTW.BUITENLAND.name : undefined;
		const btwContinent = SHOW_BTW_NUMBER.indexOf(this.state.btw) >= 0 ? this.state.btw : undefined;

		const amount = this.state.amount;
		const amountExclBTW = BTW.getAmountExclBTW(
			this.state.amount,
			this.state.btw,
			this.props.expenseData.date
		);
		const amountBTW = amount - amountExclBTW;

		// Display fields or not
		const showInvestment = SHOW_INVESTMENT.indexOf(ledgerCodeObject) >= 0;
		const showTravel = SHOW_TRAVEL.indexOf(ledgerCodeObject) >= 0;
		const showProduction = SHOW_PRODUCTION.indexOf(ledgerCodeObject) >= 0;
		const showPersonnel = SHOW_PERSONNEL.indexOf(ledgerCodeObject) >= 0;
		const showVerlegd = SHOW_VERLEGD.indexOf(this.state.btw) >= 0;
		const showBuitenland = SHOW_BUITENLAND.indexOf(this.state.btw) >= 0;

		// Options
		const ledgerCodeOptions = LEDGER_CODES.map((ledgerCode, index) => {
			return (
				<option key={`c-${index}`} value={LedgerCodes.getCodeByObject(ledgerCode)}>
					{this.props.intl.formatMessage({ id: ledgerCode.translationId })}
				</option>
			);
		});

		const travelLedgerCodeOptions = TRAVEL_LEDGER_CODES.map((ledgerCode, index) => {
			return (
				<option key={`c-${index}`} value={LedgerCodes.getCodeByObject(ledgerCode)}>
					{this.props.intl.formatMessage({ id: ledgerCode.translationId })}
				</option>
			);
		});

		const productionLedgerCodeOptions = PRODUCTION_LEDGER_CODES.map((ledgerCode, index) => {
			return (
				<option key={`c-${index}`} value={LedgerCodes.getCodeByObject(ledgerCode)}>
					{this.props.intl.formatMessage({ id: ledgerCode.translationId })}
				</option>
			);
		});

		const personnelLedgerCodeOptions = PERSONNEL_LEDGER_CODES.map((ledgerCode, index) => {
			return (
				<option key={`c-${index}`} value={LedgerCodes.getCodeByObject(ledgerCode)}>
					{this.props.intl.formatMessage({ id: ledgerCode.translationId })}
				</option>
			);
		});

		// Normalise errors, strip off `expenseRows[0].`
		const rowKey = `expenseRows[${this.props.rowIndex}]`;
		const rowErrors = this.props.errors
			? // eslint-disable-next-line array-callback-return
			  this.props.errors.map((error) => {
					if (error[0].indexOf(rowKey) >= 0) {
						return [error[0].split(`${rowKey}.`)[1], error[1]];
					}
			  })
			: [];

		const { lockInput } = this.props;

		// Determine classes
		const classes = classNames({
			'expense-row-input': true,
			'expense-row-input__can-remove': this.state.rowIndex > 0,
			'expense-row-input__locked': lockInput
		});

		return (
			<div className={classes}>
				<FormGroup errors={rowErrors}>
					<FormField className="col--6">
						<label className="col--12">
							<FormattedMessage id="expense.input.ledgercode" />
							<InfoIcon onClick={this.showTypeInfo} />
						</label>
						<DropDown
							name="ledgerCode"
							className="col--12"
							value={LedgerCodes.getCodeByObject(adjustedCode)}
							onChange={this.onInputChange}
							disabled={lockInput}>
							{ledgerCodeOptions}
						</DropDown>
					</FormField>

					{/* Travel options */}
					{showTravel ? (
						<FormField className="col--6">
							<label className="col--12">
								<FormattedMessage id="expense.input.travelcost.type" />
							</label>
							<DropDown
								name="travelCode"
								className="col--12"
								value={this.state.ledgerCode}
								onChange={this.onInputChange}
								disabled={lockInput}>
								{travelLedgerCodeOptions}
							</DropDown>
						</FormField>
					) : null}

					{/* Personnel options */}
					{showPersonnel ? (
						<FormField className="col--6">
							<label className="col--12">
								<FormattedMessage id="expense.input.personnelcost.type" />
							</label>
							<DropDown
								name="personnelCode"
								className="col--12"
								value={this.state.ledgerCode}
								onChange={this.onInputChange}
								disabled={lockInput}>
								{personnelLedgerCodeOptions}
							</DropDown>
						</FormField>
					) : null}

					{/* Production options */}
					{showProduction ? (
						<FormField className="col--6">
							<label className="col--12">
								<FormattedMessage id="expense.input.productioncost.type" />
							</label>
							<DropDown
								name="productionCode"
								className="col--12"
								value={this.state.ledgerCode}
								onChange={this.onInputChange}
								disabled={lockInput}>
								{productionLedgerCodeOptions}
							</DropDown>
						</FormField>
					) : null}

					{/* Investment options */}
					{showInvestment ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="expense.input.years" />
							</label>
							<input
								type="number"
								name="investYears"
								className="col--12 "
								value={this.state.investYears}
								min="0"
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					) : null}

					{showInvestment ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="expense.input.residual.value" />
							</label>
							<NumberInput
								type="text"
								name="investResidualValue"
								className="col--12"
								value={this.state.investResidualValue ? this.state.investResidualValue : 0}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
					) : null}
				</FormGroup>

				<FormGroup errors={this.props.errors}>
					{/* BTW */}
					<FormField className="col--3">
						<label className="col--12">
							<FormattedMessage id="expense.input.btw" />
						</label>

						<DropDown
							name="btwType"
							className="col--12"
							value={btwType}
							onChange={this.onInputChange}
							disabled={lockInput || this.isBTWDisabled(ledgerCodeObject)}>
							<option value={BTW.HOOG.name}>
								{this.props.intl.formatMessage({ id: BTW.HOOG.translationId() })}
							</option>
							<option value={BTW.LAAG.name}>
								{this.props.intl.formatMessage({
									id: BTW.LAAG.translationId(this.props.expenseData.date)
								})}
							</option>
							<option value={BTW.GEEN.name}>
								{this.props.intl.formatMessage({ id: BTW.GEEN.translationId() })}
							</option>
							<option value={BTW.VERLEGD.name}>
								{this.props.intl.formatMessage({ id: BTW.VERLEGD.translationId() })}
							</option>
						</DropDown>
					</FormField>

					<FormField className="col--3">
						<label className="col--12 label--no-wrap">
							<FormattedMessage id="expense.input.amount.incl.btw" />
						</label>
						<NumberInput
							name="amount"
							value={amount}
							onChange={this.onInputChange}
							disabled={lockInput}
						/>
					</FormField>

					<FormField className="col--3">
						<label className="col--12 label--no-wrap">
							<FormattedMessage id="expense.input.amount.excl.btw" />
						</label>
						<div className="form-field--fake-component">
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={amountExclBTW || 0}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</FormField>

					<FormField className="col--3">
						<label className="col--12 label--no-wrap">
							<FormattedMessage id="expense.input.amount.btw" />
						</label>
						<div className="form-field--fake-component">
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={amountBTW || 0}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</FormField>

					{/* Verlegd */}
					{showVerlegd ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="expense.input.btw.verlegd" />
							</label>

							<DropDown
								name="btwCountry"
								className="col--12"
								value={btwCountry}
								onChange={this.onInputChange}
								disabled={lockInput}>
								<option value={BTW.BUITENLAND.name}>
									{this.props.intl.formatMessage({ id: BTW.BUITENLAND.translationId() })}
								</option>
								<option value={BTW.BINNENLAND.name}>
									{this.props.intl.formatMessage({ id: BTW.BINNENLAND.translationId() })}
								</option>
							</DropDown>
						</FormField>
					) : null}

					{/* Verlegd buitenland */}
					{showBuitenland ? (
						<FormField className="col--3">
							<label className="col--12">
								<FormattedMessage id="expense.input.btw.outside" />
							</label>

							<DropDown
								name="btwContinent"
								value={btwContinent}
								className="col--12"
								onChange={this.onInputChange}
								disabled={lockInput}>
								<option value={BTW.BUITEN_EU.name}>
									{this.props.intl.formatMessage({ id: BTW.BUITEN_EU.translationId() })}
								</option>
								<option value={BTW.EU.name}>
									{this.props.intl.formatMessage({ id: BTW.EU.translationId() })}
								</option>
							</DropDown>
						</FormField>
					) : null}

					{/* Normal remove */}
					<div
						className="expense-row-input__remove icon icon--delete-black"
						onClick={this.onRemoveClick}
					/>

					{/* Mobile remove */}
					<div className="grid grid--right col--12 expense-row-input__remove-mobile">
						<button
							className="button--tertiary icon icon--left icon--delete-black"
							type="button"
							onClick={this.onRemoveClick}>
							<FormattedMessage id="label.remove" />
						</button>
					</div>
				</FormGroup>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'ledgerCode':
			case 'travelCode':
			case 'productionCode':
			case 'personnelCode':
				this.state.ledgerCode = e.target.value;

				// Override ledgerCode, Set default personnel code
				if (e.target.value === LedgerCodes.getCodeByObject(LedgerCodes.GC_4100)) {
					this.state.ledgerCode = LedgerCodes.getCodeByObject(LedgerCodes.GC_4103);
				}

				// Override ledgerCode, Set default production code
				if (e.target.value === LedgerCodes.getCodeByObject(LedgerCodes.GC_7100)) {
					this.state.ledgerCode = LedgerCodes.getCodeByObject(LedgerCodes.GC_7101);
				}

				// Override ledgerCode, Set default travel code
				if (e.target.value === LedgerCodes.getCodeByObject(LedgerCodes.GC_4300)) {
					this.state.ledgerCode = LedgerCodes.getCodeByObject(LedgerCodes.GC_4301);
				}

				// Set default investment values
				if (e.target.value === LedgerCodes.getCodeByObject(LedgerCodes.GC_0101)) {
					this.state.investYears = this.props.investYears ? this.props.investYears : 5;
					this.state.investResidualValue = this.props.investResidualValue
						? this.props.investResidualValue
						: 0;
				}

				// Set btw if Consumption ledgerCode
				if (this.state.ledgerCode === LedgerCodes.getCodeByObject(LedgerCodes.GC_4601)) {
					this.state.btw = BTW.GEEN.name;
				}

				// Set btw if Personnel salary
				if (this.state.ledgerCode === LedgerCodes.getCodeByObject(LedgerCodes.GC_4103)) {
					this.state.btw = BTW.GEEN.name;
				}

				break;
			case 'btwType':
			case 'btwCountry':
			case 'btwContinent':
				this.state.btw = BTW.getAdjustedBTWType(e.target.value);
				break;
			case 'amount':
				// Replace , with ., then parse to number
				this.state.amount = parseFloat(e.target.value.replace(/,/g, '.'));
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}

		if (this.props.onChange) {
			this.props.onChange(this.props.rowIndex, this.state);
		} else {
			// eslint-disable-next-line react/no-access-state-in-setstate
			this.setState(this.state);
		}
	}

	/**
	 *
	 */
	onRemoveClick() {
		if (this.props.onRemove) {
			this.props.onRemove(this.props.rowIndex);
		}
	}

	/**
	 *
	 */
	showTypeInfo() {
		const translationTitleId = LedgerCodes.getObjectByCode(this.state.ledgerCode).translationId;
		const translationId = `expense.info.ledgercode.${this.state.ledgerCode}`;
		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatMessage({ id: translationTitleId })}
				body={this.props.intl.formatMessage({ id: translationId })}
			/>
		);
	}

	/**
	 *
	 */
	isBTWDisabled(ledgerCodeObject) {
		return ledgerCodeObject === LedgerCodes.GC_4601 || ledgerCodeObject === LedgerCodes.GC_4103;
	}
}

ExpenseRowInput.propTypes = {
	id: PropTypes.number,
	expenseData: PropTypes.object.isRequired,
	rowIndex: PropTypes.number.isRequired,
	amount: PropTypes.number,
	btw: PropTypes.string.isRequired,
	ledgerCode: PropTypes.string,
	investYears: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	investResidualValue: PropTypes.number,

	onRemove: PropTypes.func,
	onChange: PropTypes.func,
	errors: PropTypes.array,

	lockInput: PropTypes.bool,

	intl: PropTypes.object
};

export default injectIntl(ExpenseRowInput);
