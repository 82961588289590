import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Routes } from '../../../data/Routes';

import { ApplicationContext } from '../../../ApplicationContext';

import Page from '../../Page';
import DashboardPanel from '../../../components/ui/DashboardPanel/DashboardPanel';
import InvoiceFeed from '../../../components/ui/Invoices/InvoiceFeed';
import BlogFeed from '../../../components/ui/Blog/BlogFeed';
import CommunityFeed from '../../../components/ui/Community/CommunityFeed';
import TodoList from '../../../components/ui/Todo/TodoList';

import GetUserMessagesCommand from '../../../commands/userMessages/GetUserMessagesCommand';

import Support from '../../../components/ui/Support/Support';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
class CompanyDashboard extends React.Component {
	/**
	 *
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentDidMount() {
		const { userMessagesStore } = this.context.applicationStore;
		const command = new GetUserMessagesCommand(userMessagesStore);
		command.execute(() => {
			const unreadMessages = userMessagesStore.latestRelevantMessages;
			if (unreadMessages.length > 0) {
				Signals.ShowUserMessagesDialog.dispatch(
					unreadMessages.slice(0, Math.min(unreadMessages.length, 3))
				);
			}
		});
	}

	/**
	 *
	 */
	render() {
		const user = this.context.applicationStore.user;
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company) return null;

		const params = {
			id: company ? company.id : null,
			year: financialYear ? financialYear.id : null
		};
		const todos = this.props.todoStore.getAllTodos(user);

		return (
			<Page pageName="company-dashboard" className="grid">
				{/* Invoices */}
				<DashboardPanel
					className="dashboard-panel__invoices"
					number="01"
					title={this.props.intl.formatMessage({ id: 'dashboard.panel.invoices.title' })}
					link={financialYear ? Routes.COMPANY_INVOICES_ALL.getPath(params) : null}
					linkLabel={this.props.intl.formatMessage({ id: 'dashboard.view.invoices' })}>
					<InvoiceFeed invoicesConceptStore={this.context.applicationStore.invoicesConceptStore} />
				</DashboardPanel>

				{/* TD's */}
				<DashboardPanel
					className="dashboard-panel__todos"
					number="02"
					title="To-do's"
					link={financialYear ? Routes.COMPANY_RESULTS.getPath(params) : null}
					linkLabel={this.props.intl.formatMessage({ id: 'dashboard.view.bookkeeping' })}
					notifications={todos.length}>
					<TodoList todos={todos} todoStore={this.props.todoStore} />
				</DashboardPanel>

				{/* Blog */}
				<DashboardPanel
					title="Blog"
					number="03"
					className="dashboard__panel--no-scroll dashboard-panel__blogs"
					link={user.language === 'en' ? process.env.BLOG_LINK_EN : process.env.BLOG_LINK_NL}
					isExternal
					linkLabel={this.props.intl.formatMessage({ id: 'dashboard.view.blog' })}>
					<BlogFeed blogStore={this.context.applicationStore.blogStore} />
				</DashboardPanel>

				{/* Community / Network feature */}
				<DashboardPanel
					title={this.props.intl.formatMessage({ id: 'dashboard.panel.community.title' })}
					number="04"
					className="dashboard__panel--no-scroll dashboard-panel__profiles"
					link={Routes.COMMUNITY_OVERVIEW.getPath(params)}
					linkLabel={this.props.intl.formatMessage({ id: 'dashboard.view.community' })}>
					<CommunityFeed
						communityProfileStore={this.context.applicationStore.communityProfileStore}
					/>
				</DashboardPanel>

				{/* Support email */}
				<Support />
			</Page>
		);
	}
}

CompanyDashboard.contextType = ApplicationContext;

CompanyDashboard.propTypes = {
	intl: PropTypes.object,
	todoStore: PropTypes.object.isRequired
};

export default injectIntl(CompanyDashboard);
