import { observable, action } from 'mobx';

/**
 *
 */
export default class DraftsStore {
	@observable drafts = [];

	owner = null;

	/**
	 *
	 * @param owner
	 */
	constructor(owner) {
		this.owner = owner;
	}

	/**
	 *
	 */
	@action reset() {
		this.drafts = [];
	}

	/**
	 *
	 * @param draft
	 */
	@action add(draft) {
		this.drafts.push(draft);
	}

	/**
	 *
	 * @param draft
	 */
	@action remove(draft) {
		for (let i = this.drafts.length - 1; i >= 0; i--) {
			if (this.drafts[i] === draft) {
				this.drafts.splice(i, 1);
			}
		}
	}

	/**
	 *
	 * @param fieldName
	 * @param value
	 * @return {*}
	 */
	find(fieldName, value) {
		return this.drafts.find((model) => {
			return model[fieldName] === value;
		});
	}

	/**
	 *
	 * @param type
	 * @param financialYearId
	 * @return {*[]}
	 */
	getDraftsOfType(type, financialYearId = null) {
		const typeName = type.name;
		return this.drafts
			.filter((draft) => {
				const isType = draft.type === typeName;
				return isType && (financialYearId ? draft.boekjaarId === financialYearId : true);
			})
			.sort((a, b) => {
				return b.id - a.id;
			});
	}
}
