import AbstractCommand from '../AbstractCommand';
import offerUpdate from '../../requests/offers/offerUpdate';
import offerCreate from '../../requests/offers/offerCreate';
import { Routes } from '../../data/Routes';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class SaveOfferCommand extends AbstractCommand {
	/**
	 *
	 * @param applicationStore ApplicationStore
	 * @param offerModel OfferModel
	 * @param company Company
	 * @param data object
	 * @param preventNavigation boolean
	 */
	constructor(applicationStore, offerModel, company, data, preventNavigation) {
		super();

		this.applicationStore = applicationStore;
		this.offerModel = offerModel;
		this.company = company;
		this.data = data;
		this.preventNavigation = preventNavigation;

		// Log command
		console.log('SaveOfferCommand.constructor');
		console.log('SaveOfferCommand.constructor.offerModel', offerModel);
		console.log('SaveOfferCommand.constructor.company', company);
		console.log('SaveOfferCommand.constructor.data', data);
		console.log('SaveOfferCommand.constructor.preventNavigation', preventNavigation);
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		// Update
		if (this.offerModel.id !== undefined && this.offerModel.id !== null) {
			return offerUpdate(this.company.id, this.offerModel.id, this.data)
				.then((response) => {
					this._updateOfferModel(response);

					if (success) {
						success(response);
					}
				})
				.catch((err) => {
					if (error) {
						error(err);
					}
				});

			// Create new
		}
		return offerCreate(this.company.id, this.data)
			.then((response) => {
				this._updateOfferModel(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_updateOfferModel(response) {
		// Update model
		this.offerModel.update(response.data);

		// Prevent navigation
		if (this.preventNavigation) return;

		// Add if offerId was a `new` offer or that it needs to be `duplicated`
		if (
			this.applicationStore.currentRouteParams.offerId === 'new' ||
			this.applicationStore.currentRoute === Routes.COMPANY_OFFERS_DUPLICATE
		) {
			// Add to OfferStore
			this.applicationStore.offersStore.addOffer(this.offerModel.clone());

			// change current route if this was a `new` offer to the route with this saved offer id
			window.history.replaceState(
				{},
				document.title,
				Routes.COMPANY_OFFERS_EDIT.getPath({ id: this.company.id, offerId: this.offerModel.id })
			);
		}

		// Navigate to offer 'send' screen
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_OFFERS_OFFER.getPath({ id: this.company.id, offerId: this.offerModel.id })
		);
	}
}
