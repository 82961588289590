/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../../signals/Signals';

import Company from '../../../../stores/models/Company';
import CompanyAssetType from '../../../../data/CompanyAssetType';

import companyUpdate from '../../../../requests/companyUpdate';
import FileUpload from '../../../../components/ui/FileUpload/FileUpload';
import { buildCompanyAssetURL } from '../../../../requests/companyAssetGet';
import companyAssetCreate from '../../../../requests/companyAssetCreate';
import UpdateInvoiceSettingsCommand from '../../../../commands/invoiceConcepts/UpdateInvoiceSettingsCommand';

import { ApplicationContext } from '../../../../ApplicationContext';
import { FormErrorContext } from '../../../../FormErrorContext';

import FormGroup from '../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../components/ui/FormField/FormField';
import BTWInput from '../../../../components/ui/BTWInput/BTWInput';
import IBANInput from '../../../../components/ui/IBANInput/IBANInput';

/**
 *
 */
class CompanyInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		// Save companyCustomerData on load
		this.el = null;
		const { company } = this.props;

		this.state = {
			errors: [],
			submitting: false,
			vatId: company.vatId, // preset vatId number so it is always saved
			vatNumber: company.vatNumber // preset vatId number so it is always saved
		};

		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onKeyDown = this.onKeyDown.bind(this);

		this.onCancel = this.onCancel.bind(this);

		this.onInputChange = this.onInputChange.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		// Focus field with short delay, so all elements are mounted and rendered
		setTimeout(() => {
			if (this.el && this.props.fieldToFocus) {
				const field = this.el.querySelector(`[name="${this.props.fieldToFocus}"]`);
				field.focus();
				const cursorPosition = field.value ? field.value.length : 0;

				try {
					field.setSelectionRange(cursorPosition, cursorPosition);
				} catch (e) {
					// Ignore
				}
			}
		}, 100);

		Signals.KeyDown.add(this.onKeyDown);
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		//
		const { company } = this.props;
		const { errors } = this.state;

		const logoAssetUuid = company.invoicesSettings ? company.invoicesSettings.logoAssetUuid : null;
		const logoPreview = buildCompanyAssetURL(company.id, logoAssetUuid);

		// Render
		const formClasses = classNames({ 'form--submitting': this.state.submitting });
		return (
			<div
				ref={(el) => {
					this.el = el;
				}}
				className="company-input">
				<FormErrorContext.Provider value={{ errors }}>
					<form className={formClasses} onSubmit={this.onSubmit} onChange={this.onInputChange}>
						<div className="company-input__header padding border--bottom border--dark grid grid--spread">
							<h2 className="pad-left">
								<FormattedMessage id="company.input.edit.title" />
							</h2>
							<div
								className="company-input__close icon icon icon--close-black"
								onClick={this.onCancel}
							/>
						</div>

						<div className="company-input__content padding-small">
							<FormGroup>
								<FormField className="col--12 ">
									<label>
										<FormattedMessage id="invoice.settings.logo" />
									</label>
									<FileUpload
										className="col--12"
										name="logo"
										fileName={logoPreview ? 'logo.png' : null}
										filePreview={logoPreview}
										showFilePreview
										accept=".png,.jpg,.jpeg"
										label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
										onChange={this.onFileChange}
									/>
								</FormField>

								<FormField className="col--12">
									<label>
										<FormattedMessage id="company.profile.field.name" />
									</label>
									<input
										name="name"
										type="text"
										value={this.state.name !== undefined ? this.state.name : company.name}
										disabled
									/>
								</FormField>

								<FormField className="col--5">
									<label>
										<FormattedMessage id="company.profile.field.address" />
									</label>
									<input
										name="address"
										type="text"
										value={
											this.state.address !== undefined
												? this.state.address
												: company.address.address
										}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--3 company-info__postalcode-field">
									<label>
										<FormattedMessage id="company.profile.field.postalcode" />
									</label>
									<input
										name="postalCode"
										type="text"
										value={
											this.state.postalCode !== undefined
												? this.state.postalCode
												: company.address.postalCode
										}
										maxLength="7"
										required
									/>
								</FormField>

								<FormField className="col--4 company-info__city-field">
									<label>
										<FormattedMessage id="company.profile.field.city" />
									</label>
									<input
										name="city"
										type="text"
										value={this.state.city !== undefined ? this.state.city : company.address.city}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.phonenumber" />
									</label>
									<input
										name="phoneNumber"
										type="tel"
										value={
											this.state.phoneNumber !== undefined
												? this.state.phoneNumber
												: company.phoneNumber
										}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.email" />
									</label>

									<input style={{ display: 'none' }} type="email" name="email" />
									<input
										name="email"
										ref={(ref) => (this.email = ref)}
										className="col--12"
										type="email"
										maxLength="255"
										autoComplete="off"
										value={this.state.email !== undefined ? this.state.email : company.email}
										readOnly
										onFocus={() => this.email.removeAttribute('readonly')}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.website" />
									</label>
									<input
										name="website"
										className="col--12"
										type="text"
										maxLength="255"
										value={this.state.website !== undefined ? this.state.website : company.website}
									/>
								</FormField>

								{this.props.type !== CompanyInput.CONTACT ? this._getCompanyDetails() : null}
							</FormGroup>
						</div>

						<div className="options-footer border--top border--dark grid grid--spread">
							<button className="button--tertiary" onClick={this.onCancel} type="button">
								<FormattedMessage id="label.cancel" />
							</button>
							<input type="submit" value={this.props.intl.formatMessage({ id: 'label.save' })} />
						</div>
					</form>
				</FormErrorContext.Provider>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		console.log('onInputChange', e.target.name, e.target.value);
		const newState = {};
		switch (e.target.name) {
			case 'email':
				newState[e.target.name] = `${e.target.value}`.trim();
				break;
			default:
				newState[e.target.name] = e.target.value;
		}

		this.setState(newState);
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 */
	onFileChange(file, fileName) {
		const { company } = this.props;

		// Create
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', fileName);
			formData.append('type', CompanyAssetType.INVOICECONCEPT_LOGO);

			companyAssetCreate(company.id, formData)
				.then(this.onAssetCreated)
				.catch((error) => {
					console.log(error);
				});

			// Remove
		} else {
			const { intl } = this.props;
			const command = new UpdateInvoiceSettingsCommand(intl, company, { logoAssetUuid: null });
			command.execute();
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		if (response.data) {
			const { intl, company } = this.props;
			const command = new UpdateInvoiceSettingsCommand(intl, company, {
				logoAssetUuid: response.data.uuid
			});
			command.execute();
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		// Modify data
		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'city':
						data['address.city'] = this.state[key];
						break;
					case 'address':
						data['address.address'] = this.state[key];
						break;
					case 'postalCode':
						data['address.postalCode'] = this.state[key];
						break;
					case 'errors':
					case 'submitting':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		if (Object.keys(data).length > 0) {
			this.setState({ errors: null, submitting: true });
			companyUpdate(this.props.company.id, data)
				.then(this.onSubmitSuccess)
				.catch(this.onSubmitError);
		} else {
			// Just show success message
			Signals.HideOverlay.dispatch();
			Signals.ShowMessageDialog.dispatch(<FormattedMessage id="company.profile.success.body" />);
		}
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		this.props.company.update(response.data);

		// Reload todos
		this.context.applicationStore.todoStore.fetch();

		//
		Signals.ShowMessageDialog.dispatch(<FormattedMessage id="company.profile.success.body" />);

		// Reset state
		this.state = {
			errors: null
		};

		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();

		// Trigger update
		if (this.props.onUpdate) {
			this.props.onUpdate(this.props.company);
		}
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 27:
				this.onCancel();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param e
	 */
	onCancel(e) {
		if (e) {
			e.preventDefault();
		}

		this.close();
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getCompanyDetails() {
		const company = this.props.company;

		return [
			<FormField className="col--6">
				<label>
					<FormattedMessage id="company.profile.field.kvknumber" />
				</label>
				<input
					name="kvkNumber"
					className="col--12"
					type="text"
					maxLength="10"
					value={this.state.kvkNumber !== undefined ? this.state.kvkNumber : company.kvkNumber}
					onChange={this.onInputChange}
				/>
			</FormField>,

			!company.ignoreBTWNumber() ? (
				<FormField className="col--6">
					<label>
						<FormattedMessage id="company.profile.field.vatnumber" />
					</label>

					<BTWInput
						name="vatNumber"
						value={this.state.vatNumber !== undefined ? this.state.vatNumber : company.vatNumber}
						onChange={this.onInputChange}
					/>
				</FormField>
			) : null,

			!company.ignoreBTWId() ? (
				<FormField className="col--6">
					<label>
						<FormattedMessage id="company.profile.field.vatid" />
					</label>
					<BTWInput
						name="vatId"
						className="company-info__vatid col--12"
						value={this.state.vatId !== undefined ? this.state.vatId : company.vatId}
						onChange={this.onInputChange}
					/>
				</FormField>
			) : null,

			<FormField className="col--6">
				<label>
					<FormattedMessage id="company.profile.field.iban" />
				</label>
				<IBANInput
					name="iban"
					value={this.state.iban !== undefined ? this.state.iban : company.iban}
					onChange={this.onInputChange}
				/>
			</FormField>
		];
	}
}

CompanyInput.contextType = ApplicationContext;

CompanyInput.propTypes = {
	type: PropTypes.string,
	intl: PropTypes.object,
	fieldToFocus: PropTypes.string,
	company: PropTypes.instanceOf(Company),
	onUpdate: PropTypes.func
};

CompanyInput.defaultProps = {
	type: CompanyInput.DEFAULT
};

const companyInput = injectIntl(CompanyInput);
companyInput.DEFAULT = CompanyInput.DEFAULT = 'DEFAULT';
companyInput.CONTACT = CompanyInput.CONTACT = 'CONTACT';

export default companyInput;
