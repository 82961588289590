import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
/**
 * This hook returns the bank record store.
 * @return {BankAccountStore}
 */
const useBankAccountStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { bankAccountStore } = applicationStore;

	return bankAccountStore;
};

export default useBankAccountStore;
