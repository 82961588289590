export default class ToggleButtonOption {
	/**
	 *
	 * @param label
	 * @param value
	 * @param classes
	 * @param disabled
	 * @param hidden
	 */
	constructor(label, value, classes = '', disabled = false, hidden = false) {
		this.label = label;
		this.value = value;
		this.classes = classes || '';
		this.disabled = disabled;
		this.hidden = hidden;
	}
}
