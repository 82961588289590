/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedDate, FormattedNumber } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../../signals/Signals';
import { defaultDateFormatOptions } from '../../../../data/Settings';
import { Routes } from '../../../../data/Routes';
import InvoiceConceptStatus from '../../../../data/InvoiceConceptStatus';
import InvoiceConceptModel from '../../../../stores/models/InvoiceConceptModel';

import Tag from '../../../../components/ui/Tag/Tag';
import InvoiceActions from '../../../../components/ui/ToolTip/tooltips/InvoiceActions';
import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import Truncate from '../../../../components/ui/Truncate/Truncate';

import { ApplicationContext } from '../../../../ApplicationContext';
import ArchiveInvoiceConceptCommand from '../../../../commands/invoiceConcepts/ArchiveInvoiceConceptCommand';
import UnarchiveInvoiceConceptCommand from '../../../../commands/invoiceConcepts/UnarchiveInvoiceConceptCommand';
import DeleteInvoiceConceptCommand from '../../../../commands/invoiceConcepts/DeleteInvoiceConceptCommand';
import ToggleInvoiceConceptPaidStatusCommand from '../../../../commands/invoiceConcepts/ToggleInvoiceConceptPaidStatusCommand';
import PropertiesController from '../../../../controllers/PropertiesController';
import ActionsToolTip from '../../../../components/ui/ToolTip/tooltips/ActionsToolTip';
import ActionModel from '../../../../stores/models/ActionModel';
import { getRelativeDays } from '../../../../utils/dateUtils';

@observer
/**
 *
 */
class InvoicesRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onFieldClick = this.onFieldClick.bind(this);

		this.onPaidStatusChange = this.onPaidStatusChange.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.invoicesStore = this.props.invoiceConceptModel.isArchived()
			? this.context.applicationStore.invoicesConceptArchiveStore
			: this.context.applicationStore.invoicesConceptStore;
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const sortBy = this.invoicesStore.sortBy;
		const sortByClasses = sortBy ? `invoices-row--sorted-by--${`${sortBy}`.toLowerCase()}` : '';
		const status = `${this.props.invoiceConceptModel.status}`.toLowerCase();

		const classes = classNames({
			'table__row--can-edit table-row--can-edit': this.props.invoiceConceptModel.canEdit()
		});

		return (
			<tr className={`table-row ${sortByClasses} invoices-row--${status} ${classes}`}>
				<td
					className="table-row__column invoices-row__status"
					onClick={(event) => this.onFieldClick('status', event)}>
					<div className="grid">
						<Tag labelId={`tag-label.${status}`} type={status} />
						{PropertiesController.getProperty(PropertiesController.FEATURE_DEMO) ? null : (
							<div className="invoices-row__status-date">{this._getStatusDateValue()}</div>
						)}
					</div>
				</td>
				<td
					className="table-row__column table-row__column--editable invoices-row__date"
					onClick={() => this.onFieldClick('date')}>
					<FormattedDate
						value={this.props.invoiceConceptModel.date}
						{...defaultDateFormatOptions}
					/>
				</td>
				<td
					className="table-row__column table-row__column--editable invoices-row__invoicenr"
					onClick={() => this.onFieldClick('invoiceNr')}>
					{this.props.invoiceConceptModel.invoiceNr}
				</td>
				<td
					className="table-row__column table-row__column--editable invoices-row__companyname"
					onClick={() => this.onFieldClick('companyCustomer')}>
					<Truncate>
						{this.props.invoiceConceptModel.companyCustomer
							? this.props.invoiceConceptModel.companyCustomer.getCustomerName()
							: '-'}
					</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable invoices-row__subject"
					onClick={() => this.onFieldClick('subject')}>
					<Truncate>{this.props.invoiceConceptModel.subject}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable invoices-row__amount text--right"
					onClick={() => this.onFieldClick('unitPrice')}>
					<FormattedNumber
						style="currency"
						currency="EUR"
						value={this.props.invoiceConceptModel.getTotal()}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</td>
				<td
					className="table-row__column text--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param e
	 */
	onPaidStatusChange(_e) {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new ToggleInvoiceConceptPaidStatusCommand(
			this.props.invoiceConceptModel,
			company,
			this.props.intl
		);
		command.execute();
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();

		const hideTypes = [];

		/**
		 {type:'edit', icon:'edit', label:'label.edit'},
		 {type:'duplicate', icon:'file', label:'label.duplicate'},
		 {type:'prepare', icon:'upload', label:'label.prepare'},
		 {type:'view', icon:'eye2', label:'label.view'},
		 {type:'remove', icon:'delete', label:'label.remove'},
		 {type:'archive', icon:'folder2', label:'label.archive'}
		 */
		switch (this.props.invoiceConceptModel.status) {
			case InvoiceConceptStatus.ARCHIVED:
				hideTypes.push('edit'); // Cannot edit this Invoice
				hideTypes.push('prepare'); // Invoice already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this invoice
				hideTypes.push('archive'); // Cannot archive this invoice
				break;
			case InvoiceConceptStatus.DELETED:
			case InvoiceConceptStatus.PAID:
				hideTypes.push('edit'); // Cannot edit this Invoice
				hideTypes.push('prepare'); // Invoice already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this invoice
				hideTypes.push('unarchive'); // Cannot unarchive this invoice
				break;
			case InvoiceConceptStatus.LATE:
			case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
			case InvoiceConceptStatus.SENT_MANUALLY:
			case InvoiceConceptStatus.SENT_REMINDER_KDB:
				hideTypes.push('edit'); // Cannot edit this Invoice
				hideTypes.push('prepare'); // Invoice already send, can't prepare
				// hideTypes.push('remove'); // Cannot remove this Invoice
				hideTypes.push('archive'); // Invoice pending, can't archive
				hideTypes.push('unarchive'); // Invoice pending, can't archive
				break;
			case InvoiceConceptStatus.CREATED:
				hideTypes.push('view'); // Hide view, Invoice has to be send first
				hideTypes.push('archive'); // Hide view, Invoice has to be send first
				hideTypes.push('unarchive'); // Hide view, Invoice has to be send first
				break;
			default:
				break;
		}

		Signals.ShowToolTip.dispatch(
			<InvoiceActions onSelect={this.onActionSelect} hideTypes={hideTypes} />,
			e.currentTarget,
			-16
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'prepare':
			case 'view':
				this._prepare();
				break;
			case 'remove':
				this._remove();
				break;
			case 'edit':
				this._edit();
				break;
			case 'duplicate':
				this._duplicate();
				break;
			case 'archive':
				this._archive();
				break;
			case 'unarchive':
				this._unarchive();
				break;
			case 'paid':
			case 'unpaid':
				// eslint-disable-next-line no-case-declarations
				const company = this.context.applicationStore.getSelectedCompany();
				// eslint-disable-next-line no-case-declarations
				const command = new ToggleInvoiceConceptPaidStatusCommand(
					this.props.invoiceConceptModel,
					company,
					this.props.intl
				);
				command.execute(() => {
					this.forceUpdate();
				});
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param field
	 * @param event
	 */
	onFieldClick(field, event) {
		const { intl, invoiceConceptModel } = this.props;
		if (field === 'status' && invoiceConceptModel.canTogglePaid()) {
			const actions =
				invoiceConceptModel.status === InvoiceConceptStatus.PAID
					? [
							new ActionModel({
								type: 'unpaid',
								label: intl.formatMessage({ id: 'invoice.paidswitch.label.no' })
							})
					  ]
					: [
							new ActionModel({
								type: 'paid',
								label: intl.formatMessage({ id: 'invoice.paidswitch.label.yes' })
							})
					  ];

			Signals.ShowToolTip.dispatch(
				<ActionsToolTip
					className="text--center"
					options={actions}
					onSelect={this.onActionSelect}
				/>,
				event.currentTarget.querySelector('.tag'),
				0
			);
		} else {
			this._prepare();
		}
	}

	/**
	 *
	 * @private
	 */
	_remove() {
		const title = this.props.intl.formatMessage(
			{ id: 'invoice.remove.alert.title' },
			{
				description: this.props.invoiceConceptModel.description,
				customer: this.props.invoiceConceptModel.companyCustomer
					? this.props.invoiceConceptModel.companyCustomer.companyName
					: '-'
			}
		);

		let body = null;
		if (this.props.invoiceConceptModel.status !== InvoiceConceptStatus.CREATED) {
			body = this.props.intl.formatMessage({ id: 'invoice.remove.alert.body' });
		}

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				// eslint-disable-next-line react/jsx-no-bind
				onConfirm={this._doRemove.bind(this)}
				body={body}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeleteInvoiceConceptCommand(
				this.context.applicationStore.invoicesConceptStore,
				this.props.invoiceConceptModel,
				company
			);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_prepare() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_INVOICE.getPath({
					id: company.id,
					invoiceId: this.props.invoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @param field
	 * @private
	 */
	_edit(_field = null) {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// Jump to send screen
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_EDIT.getPath({
					id: company.id,
					invoiceId: this.props.invoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @private
	 */
	_duplicate() {
		const company = this.context.applicationStore.getSelectedCompany();
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_INVOICES_DUPLICATE.getPath({
				id: company.id,
				invoiceId: this.props.invoiceConceptModel.id
			})
		);
	}

	/**
	 *
	 * @private
	 */
	_archive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new ArchiveInvoiceConceptCommand(this.props.invoiceConceptModel, company);
			command.execute();
		}
	}

	/**
	 *
	 * @private
	 */
	_unarchive() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new UnarchiveInvoiceConceptCommand(this.props.invoiceConceptModel, company);
			command.execute();
		}
	}

	/**
	 *
	 * @return {string}
	 * @private
	 */
	_getStatusDateValue() {
		let value = '';

		const settings = {
			unit: 'day',
			options: {
				style: 'long',
				localeMatcher: 'best fit'
			}
		};

		switch (this.props.invoiceConceptModel.status) {
			case InvoiceConceptStatus.CREATED:
			case InvoiceConceptStatus.PAID:
			case InvoiceConceptStatus.DELETED:
				value = null;
				break;
			case InvoiceConceptStatus.SENT_REMINDER_KDB:
			case InvoiceConceptStatus.LATE:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.invoiceConceptModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'invoices.statusdate.late' }, { date: value });
				break;
			case InvoiceConceptStatus.SENT_MANUALLY:
			case InvoiceConceptStatus.SENT_BY_EMAIL_KDB:
				value = this.props.intl.formatRelativeTime(
					getRelativeDays(new Date(), this.props.invoiceConceptModel.expirationDate),
					settings.unit,
					settings.options
				);
				value = this.props.intl.formatMessage({ id: 'invoices.statusdate.send' }, { date: value });
				break;
			default:
		}

		return value;
	}
}

InvoicesRow.contextType = ApplicationContext;

InvoicesRow.propTypes = {
	invoiceConceptModel: PropTypes.instanceOf(InvoiceConceptModel).isRequired,
	isArchive: PropTypes.bool,
	intl: PropTypes.object
};

export default injectIntl(InvoicesRow);
