import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 *
 */
export default class DocumentActions extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { hideTypes } = this.props;
		console.log(hideTypes);
		return (
			<div className="document-actions actions">
				{!hideTypes.includes('open') ? (
					<div className="actions__action" data-action="open" onClick={this.onClick}>
						<i className="icon icon--eye2-black" />
						<FormattedMessage id="label.open" />
					</div>
				) : null}
				{!hideTypes.includes('download') ? (
					<div className="actions__action" data-action="download" onClick={this.onClick}>
						<i className="icon icon--download2-black" />
						<FormattedMessage id="label.download" />
					</div>
				) : null}
				{!hideTypes.includes('remove') ? (
					<div className="actions__action" data-action="remove" onClick={this.onClick}>
						<i className="icon icon--delete-black" />
						<FormattedMessage id="label.remove" />
					</div>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.action;
		this.props.onSelect(action);
	}
}

DocumentActions.propTypes = {
	hideTypes: PropTypes.array,
	onSelect: PropTypes.func.isRequired
};

DocumentActions.defaultProps = {
	hideTypes: []
};
