import BTW from '../../data/BTW';

let INVOICE_CONCEPT_ROW_UID = 0;

/**
 *
 */
export default class InvoiceConceptRowModel {
	/**
	 *
	 * @param data
	 * @param referenceDate
	 */
	constructor(data = {}, referenceDate = new Date()) {
		this._uid = INVOICE_CONCEPT_ROW_UID++;

		this.id = data.id ? data.id : undefined;
		this.units = data.units !== undefined && data.units !== null ? data.units : 1;
		this.unitPrice = data.unitPrice ? data.unitPrice : 0; // undefined;
		this.amount = data.amount ? data.amount : 0;
		this.btw = data.btw ? data.btw : BTW.HOOG.name;
		this.description = data.description ? data.description : '';
		this.sortOrder = data.sortOrder ? data.sortOrder : 0;
		this.inclVat = data.inclVat ? data.inclVat : false;

		this.update(referenceDate);
	}

	/**
	 *
	 * @return {*}
	 */
	getSubtotal(referenceDate = new Date()) {
		// Remove VAT from amount to get subtotal if inclVat
		if (this.inclVat) {
			return this.amount - this.getVATTotal(referenceDate);

			// VAT is already excluded from amount
		}
		return this.amount;
	}

	/**
	 *
	 * @return {*}
	 */
	getVATTotal(referenceDate = new Date()) {
		const btwHoogPercentage = BTW.HOOG.percentage(referenceDate) / 100;
		const btwLaagPercentage = BTW.LAAG.percentage(referenceDate) / 100;

		// If amount is incl VAT, amount - subtotal = vat amount
		if (this.inclVat) {
			switch (this.btw) {
				case BTW.HOOG.name:
					return (this.amount / (1 + btwHoogPercentage)) * btwHoogPercentage;
				case BTW.LAAG.name:
					return (this.amount / (1 + btwLaagPercentage)) * btwLaagPercentage;
				default:
					return 0;
			}

			// Else multiply VAT percentage with amount
		} else {
			switch (this.btw) {
				case BTW.HOOG.name:
					return this.amount * btwHoogPercentage;
				case BTW.LAAG.name:
					return this.amount * btwLaagPercentage;
				default:
					return 0;
			}
		}
	}

	/**
	 *
	 */
	getTotal(referenceDate = new Date()) {
		if (this.inclVat) {
			return this.amount;
		}
		return this.getSubtotal(referenceDate) + this.getVATTotal(referenceDate);
	}

	/**
	 *
	 * @param _referenceDate
	 */
	update(_referenceDate = new Date()) {
		this.amount = this._getAmount();

		if (isNaN(this.amount)) {
			this.amount = 0;
		}
	}

	/**
	 *
	 * @return {InvoiceConceptRowModel}
	 */
	clone() {
		const clone = new InvoiceConceptRowModel();

		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				clone[key] = this[key];
			}
		}

		return clone;
	}

	/**
	 * Replaces linebreaks like \r and \n with <br>
	 *
	 * @return {String}
	 */
	getDescriptionAsHTML() {
		return this.description ? this.description.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
	}

	/**
	 *
	 * @return {number}
	 * @private
	 */
	_getAmount() {
		let amount = this.unitPrice * this.units;
		if (isNaN(amount)) {
			amount = 0;
		}

		return amount;
	}
}
