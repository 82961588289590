import AbstractCommand from './AbstractCommand';
import Signals from '../signals/Signals';
import companyGet from '../requests/companyGet';

/**
 *
 */
export default class GetCompanyCommand extends AbstractCommand {
	/**
	 * @param company Company
	 */
	constructor(company) {
		super();

		this.company = company;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetCompanyCommand.execute');

		companyGet(this.company.id)
			.then((response) => {
				// Update Company data
				this.company.parseAdditionalData(response.data);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}

				Signals.Error.dispatch(err);
			});
	}
}
