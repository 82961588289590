import { observable } from 'mobx';
import InvoiceConceptRowModel from './InvoiceConceptRowModel';
import InvoiceConceptModel from './InvoiceConceptModel';
import PeriodicScheduleModel from './PeriodicScheduleModel';
import { _formatDate } from '../../utils/objectToFormData';

/**
 *
 */
export default class PeriodicInvoiceConceptModel extends InvoiceConceptModel {
	@observable sendInvoiceToCustomerAutomatically = true;

	@observable periodicSchedule = new PeriodicScheduleModel();

	/**
	 *
	 */
	constructor() {
		super();

		this.date = undefined;
		this.invoiceNr = undefined;
	}

	/*
	 * @return {InvoiceConceptModel}
	 */
	clone(_copyDate = false, ignoreUniqueFields = false) {
		const clone = new PeriodicInvoiceConceptModel();

		for (const key in this) {
			if (this.hasOwnProperty(key) && key !== 'invoiceConceptRows' && key !== 'history') {
				switch (key) {
					case 'date':
					case 'expirationDate':
					case 'invoiceNr':
						// Ignore
						break;
					case 'periodicSchedule':
						clone[key] = new PeriodicScheduleModel(this[key]);
						break;
					default:
						clone[key] = this[key];
				}
			}
		}

		// Reset all unique fields
		if (ignoreUniqueFields) {
			clone.id = null;
			clone.invoiceNr = null;
			clone.status = null;
			clone.statusDateChanged = null;
			clone.history = [];

			clone.publicAccessId = null;
			clone.offerId = null;

			clone.recipientEmail = null;
			clone.senderEmail = null;
			clone.emailMessage = null;
			clone.emailSubject = null;
			clone.ccRecipientEmail = null;
			clone.bccRecipientEmail = null;
		}

		//
		this.invoiceConceptRows.forEach((row) => {
			clone.invoiceConceptRows.push(row.clone());
		});

		// Reset invoiceConceptRows id's
		if (ignoreUniqueFields) {
			clone.invoiceConceptRows.forEach((row) => {
				row.id = null;
			});
		}

		return clone;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		super.update(data);

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'date':
					case 'invoiceNr':
						// Ignore
						break;
					case 'periodicSchedule':
						this[key] = new PeriodicScheduleModel(data[key]);
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	isActive() {
		return this.periodicSchedule.enabled;
	}

	/**
	 *
	 * @param data
	 * @returns {InvoiceConceptModel}
	 */
	static parsePeriodicInvoiceConceptData(data) {
		const model = new PeriodicInvoiceConceptModel();
		PeriodicInvoiceConceptModel.updatePeriodicInvoiceConcept(data, model);
		return model;
	}

	/**
	 *
	 * @param data
	 * @param periodicInvoiceConcept
	 */
	static updatePeriodicInvoiceConcept(data, periodicInvoiceConcept) {
		periodicInvoiceConcept.update(data);
		periodicInvoiceConcept.invoiceConceptRows = [];

		if (!data.invoiceConceptRows) {
			data.invoiceConceptRows = [];
		}

		data.invoiceConceptRows.forEach((invoiceRow) => {
			periodicInvoiceConcept.invoiceConceptRows.push(
				new InvoiceConceptRowModel(invoiceRow, periodicInvoiceConcept.date)
			);
		});
	}

	/**
	 *
	 * @param value
	 * @return {*}
	 * @private
	 */
	_formatJSONData(value) {
		if (value instanceof Date) {
			return _formatDate(value);
		}
		if (value instanceof PeriodicScheduleModel) {
			return JSON.parse(value.toJSON());
		}
		return value;
	}
}
