import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 * @param companyId
 * @param financialYearId
 * @param documentId
 * @return {Promise}
 */
export default (companyId, financialYearId, documentId) => {
	return fetch(
		Methods.DELETE,
		EndPoints.DOCUMENT_COMPANY.replace(':id', companyId)
			.replace(':year', financialYearId)
			.replace(':documentId', documentId),
		null,
		null,
		true
	);
};
