import { observable, computed } from 'mobx';

import ExpenseRowModel from './ExpenseRowModel';
import Paid from '../../data/Paid';
import BTW from '../../data/BTW';
import LedgerCodes from '../../data/LedgerCodes';
import EditState from '../../data/EditState';

import {
	getFinancialRowExclBTW,
	getFinancialRowInclBTW,
	getBTWTypes,
	getTotalAmountInclBTW,
	getTotalAmountExclBTW
} from '../../utils/btwUtils';
import Draft from './Draft';

/**
 *
 */
export default class ExpenseModel {
	@observable id = undefined;

	@observable expenseNr = undefined;

	@observable date = undefined;

	@observable state = Paid.PAID_WITHIN_FYSCAL_YEAR;

	@observable removed = undefined;

	@observable customerName = undefined;

	@observable description = undefined;

	@observable projectcode = undefined;

	@observable fileName = undefined;

	@observable assetUuid = undefined;

	@observable editState = EditState.READ_WRITE_DELETE;

	expenseRows = [];

	/**
	 * Copy data from provided expenseModel,
	 * but excludes id, fileName and expenseNr
	 *
	 * @param expenseModel
	 */
	copy(expenseModel) {
		this.state = expenseModel.state;
		this.customerName = expenseModel.customerName;
		this.description = expenseModel.description;
		this.projectcode = expenseModel.projectcode;
		this.expenseRows = [];

		// eslint-disable-next-line no-unused-expressions
		expenseModel.expenseRows
			? expenseModel.expenseRows.forEach((row) => {
					this.expenseRows.push(row.clone(true));
			  })
			: null;

		return this;
	}

	/**
	 * Create new ExpenseModel based on this object
	 *
	 * @param unique
	 */
	clone(unique = false) {
		const clone = ExpenseModel.createNewExpense();

		clone.id = unique ? undefined : this.id;
		clone.expenseNr = unique ? undefined : this.expenseNr;
		clone.date = this.date ? new Date(this.date.getTime()) : undefined; // + 43200000
		clone.state = this.state;
		clone.removed = this.removed;
		clone.customerName = this.customerName;
		clone.description = this.description;
		clone.projectcode = this.projectcode;
		clone.fileName = unique ? undefined : this.fileName;
		clone.assetUuid = this.assetUuid;
		clone.editState = this.editState;
		clone.expenseRows = [];

		this.expenseRows.forEach((row) => {
			clone.expenseRows.push(row.clone(unique));
		});

		return clone;
	}

	/**
	 *
	 * @return {boolean}
	 */
	get mayBeChanged() {
		return (
			this.editState == null ||
			this.editState === undefined ||
			this.editState === EditState.READ_WRITE_DELETE
		);
	}

	/**
	 *
	 * @param expenseModel
	 * @return {boolean}
	 */
	equals(expenseModel) {
		for (const key in expenseModel) {
			if (expenseModel.hasOwnProperty(key)) {
				if (key === 'expenseRows') {
					if (
						this.expenseRows.length !== expenseModel.expenseRows.length ||
						!this.expenseRows.every((row, index) => {
							return row.equals(expenseModel.expenseRows[index]);
						})
					) {
						return false;
					}
				} else {
					const original = JSON.stringify(this[key]);
					const modified = JSON.stringify(expenseModel[key]);

					if (original !== modified) {
						return false;
					}
				}
			}
		}

		return true;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isNew() {
		return !this.id;
	}

	/**
	 *
	 * @return {string}
	 */
	getBTWTranslationId() {
		const btwTypes = getBTWTypes(this.expenseRows);
		let translationId = '-';

		// If multiple btw types
		if (btwTypes.length > 1) {
			translationId = BTW.MIXED.translationId();
			// If one btw type
		} else if (btwTypes.length === 1) {
			translationId = btwTypes[0].translationId(this.date);
		}

		return translationId;
	}

	/**
	 *
	 * @param intl
	 * @param year
	 * @param _useEuroSymbol
	 * @return {Array}
	 */
	toArray(intl, year, _useEuroSymbol = false) {
		const result = [];
		this.expenseRows.forEach((expenseRow) => {
			let row = [];

			// Shared information
			row = row.concat([
				this.expenseNr,
				new Date(this.date.getTime() + 43200000), // Half day offset to prevent jumping date in excel/csv viewers
				this.customerName,
				this.description,
				this.projectcode ? this.projectcode : '',
				intl.formatMessage({ id: `tag-label.${this.state}`.toLowerCase() }, { year }) // State
			]);

			const inclBTW = getFinancialRowInclBTW(expenseRow); // Incl BTW
			const exclBTW = getFinancialRowExclBTW(expenseRow, this.date); // Excl BTW

			// Row specific information
			row = row.concat([
				intl.formatMessage({
					id: LedgerCodes.getObjectByCode(expenseRow.ledgerCode).translationId
				}), // Ledgercode
				intl.formatMessage({ id: this.getBTWTranslationId() }), // BTW
				inclBTW,
				exclBTW
			]);

			// Add to result
			result.push(row);
		});

		return result;
	}

	/**
	 *
	 * @param autocompleteSearchObject
	 * @param ignoreCase
	 * @return {boolean}
	 */
	matches(autocompleteSearchObject, ignoreCase = true) {
		let match = true;

		autocompleteSearchObject.fields.forEach((autocompleteFieldData) => {
			let value = this[autocompleteFieldData.name];
			let searchQuery = autocompleteFieldData.searchQuery;

			if (!value) {
				match = false;
				return;
			}

			value = ignoreCase ? value.toLowerCase() : value;
			searchQuery = ignoreCase ? searchQuery.toLowerCase() : searchQuery;

			if (value.indexOf(searchQuery) < 0) {
				match = false;
			}
		});

		return match;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get totalAmountInclBTW() {
		return getTotalAmountInclBTW(this.expenseRows);
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get totalAmountExclBTW() {
		return getTotalAmountExclBTW(this.expenseRows);
	}

	/**
	 *
	 * @return {string}
	 */
	@computed get searchString() {
		let searchString = '';
		searchString += this.expenseNr ? `${this.expenseNr} ` : '';
		searchString += this.customerName ? `${this.customerName} ` : '';
		searchString += this.description ? `${this.description} ` : '';
		searchString += this.projectcode ? `${this.projectcode} ` : '';
		try {
			searchString += new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
				this.totalAmountInclBTW
			);
		} catch (e) {
			console.log('searchString error:', e);
		}
		return searchString.toLowerCase();
	}

	/**
	 *
	 * @param data
	 * @returns {ExpenseModel}
	 */
	static parseExpense(data) {
		const expense = new ExpenseModel();
		ExpenseModel.updateExpense(data, expense);
		return expense;
	}

	/**
	 *
	 * @param data
	 * @param expense
	 * @param forceExpenseRow
	 * @param unique
	 */
	static updateExpense(data, expense, forceExpenseRow = false, unique = false) {
		if (data) {
			expense.id = unique ? undefined : data.id;
			expense.expenseNr = data.expenseNr;
			expense.date = data.date ? new Date(data.date) : undefined;
			if (expense.date) {
				expense.date = new Date(expense.date.getTime());
			}
			expense.state = data.state;
			expense.removed = data.removed;
			expense.customerName = data.customerName;
			expense.description = data.description;
			expense.projectcode = data.projectcode;
			expense.fileName = data.fileName;
			expense.assetUuid = data.assetUuid;
			expense.editState = data.editState;
			expense.expenseRows = [];

			// eslint-disable-next-line no-unused-expressions
			data.expenseRows
				? data.expenseRows.forEach((expenseRow) => {
						const expenseRowModel = new ExpenseRowModel(expenseRow);
						if (unique) {
							expenseRowModel.id = undefined;
						}
						expense.expenseRows.push(expenseRowModel);
				  })
				: null;

			if (forceExpenseRow && !data.expenseRows) {
				expense.expenseRows = [];
				expense.expenseRows.push(new ExpenseRowModel());
			}
		}
	}

	/**
	 *
	 * @return {number}
	 */
	getTotalAmount() {
		let amount = 0;
		this.expenseRows.forEach((row) => {
			amount += row.amount;
		});
		return amount;
	}

	/**
	 *
	 * @return {*}
	 */
	getBTW() {
		if (this.expenseRows.length > 0) {
			return this.expenseRows[0].btw;
		}

		return null;
	}

	/**
	 *
	 * @return {*}
	 */
	getLedgerCode() {
		if (this.expenseRows.length > 0) {
			if (this.expenseRows.length === 1) {
				return this.expenseRows[0].ledgerCode;
			}
			return LedgerCodes.getCodeByObject(LedgerCodes.GC_XXXX);
		}

		return null;
	}

	/**
	 *
	 * @return {ExpenseModel}
	 */
	static createNewExpense() {
		const expenseModel = new ExpenseModel();
		expenseModel.expenseRows = [new ExpenseRowModel()];
		return expenseModel;
	}

	/**
	 * @param draft Draft
	 *
	 * @return {ExpenseModel}
	 */
	static createNewExpenseFromDraft(draft) {
		const expenseModel = new ExpenseModel();
		let data = {};
		try {
			data = JSON.parse(draft.data);
		} catch (err) {
			console.log('Error parsing draft data:', err);
		}

		// Update data with draft assetUuid
		data.assetUuid = data.assetUuid ? data.assetUuid : draft.assetUuid;

		// set defaults
		data.id = undefined;
		data.state = data.state ? data.state : Paid.PAID_WITHIN_FYSCAL_YEAR;

		this.updateExpense(data, expenseModel, true, true);

		return expenseModel;
	}

	/**
	 *
	 * @param {BankRecord} bankRecord
	 */
	static createDraftExpenseFromBankRecord(bankRecord) {
		const draft = new Draft();

		const expenseModel = new ExpenseModel();
		expenseModel.customerName = bankRecord.counterpartName;
		expenseModel.description = bankRecord.description;

		const expenseRow = new ExpenseRowModel();
		expenseRow.amount = Math.abs(bankRecord.amount);
		expenseModel.expenseRows.push(expenseRow);

		draft.data = JSON.stringify(expenseModel);
		return draft;
	}
}
