import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import Signals from '../../signals/Signals';

import AbstractCommand from '../AbstractCommand';
import companyDraftUpdate from '../../requests/drafts/companyDraftUpdate';

/**
 *
 */
export default class UpdateCompanyDraftsCommand extends AbstractCommand {

	/**
	 *
	 * @param companyId Number
	 * @param draft Draft
	 */
	constructor(companyId, draft) {
		super();

		this.companyId = companyId;
		this.draft = draft;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		const data = JSON.stringify(this.draft);

		return companyDraftUpdate(this.companyId, this.draft.id, data).then((response) => {

			// Show message
			Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="draft.edited.message" values={{id:this.draft.id}}/>);

			if(success) {
				success(response);
			}

		}).catch(err => {
			if(error) {
				error(err)
			}
		});
	}
}
