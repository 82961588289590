import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (uuid, data) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		EndPoints.PUBLIC_OFFER_SIGN.replace(':uuid', uuid),
		data,
		headers,
		false
	);
};
