import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Notification({ type, children }) {
	const classes = classNames('notification', {
		'notification-success': type === 'success',
		'notification-error': type === 'error',
		'notification-warning': type === 'warning'
	});
	return <div className={classes}> {children} </div>;
}

Notification.propTypes = {
	type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
	children: PropTypes.node.isRequired
};

export default Notification;
