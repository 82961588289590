import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 *
 */
export default class ClientActions extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className="client-actions actions">
				<div className="actions__action" data-type="edit" onClick={this.onClick}>
					<i className="icon icon--edit-black" />
					<FormattedMessage id="label.edit" />
				</div>
				<div className="actions__action" data-type="remove" onClick={this.onClick}>
					<i className="icon icon--delete-black" />
					<FormattedMessage id="label.remove" />
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.type;
		this.props.onSelect(action);
	}
}

ClientActions.propTypes = {
	onSelect: PropTypes.func.isRequired
};
