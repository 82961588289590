// https://www.npmjs.com/package/xlsexport, https://jsfiddle.net/3xvb2g5w/
// import XlsExport from 'xlsexport';
// http://sheetjs.com/
// https://docs.sheetjs.com/#number-formats
import XLSX from 'xlsx';
import { _formatDate } from './objectToFormData';

/**
 *
 * @param rows
 * @param format
 * @param fileName
 * @param formats
 */
function exportToFile(rows, format, fileName, formats = []) {
	const date = new Date();
	const sheetName = 'Sheet 1';
	let tmpFileName = fileName;
	if (!tmpFileName) {
		tmpFileName = `export-${_formatDate(date.getTime())}`;
	}

	const ws = XLSX.utils.aoa_to_sheet(rows);

	// Apply format from second row (1st - header)
	for (let i = 2; i <= rows.length; i++) {
		formats.forEach((it) => {
			if (it.format) {
				ws[it.column + i].z = it.format;
			}
			if (it.type) {
				ws[it.column + i].t = it.type;
			}
		});
	}

	const wb = XLSX.utils.book_new();
	wb.SheetNames.push(sheetName);
	wb.Sheets[sheetName] = ws;

	XLSX.writeFile(wb, `${tmpFileName}.${format}`);
}

/**
 *
 * @param rows
 * @param fileName
 * @param formats
 * @returns {string}
 */
export function toXLSX(rows, fileName, formats = []) {
	exportToFile(rows, 'xlsx', fileName, formats);
}

/**
 *
 * @param rows
 * @param fileName
 * @param formats
 * @returns {string}
 */
export function toCSV(rows, fileName, formats = []) {
	exportToFile(rows, 'csv', fileName, formats);
}
