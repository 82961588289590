import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId) => {
	return fetch(
		Methods.GET,
		EndPoints.COMPANY_EXPENSES.replace(':companyId', companyId),
		null,
		null,
		true
	);
};
