import FilterRule from './FilterRule';

/**
 *
 */
export default class FilterRuleHasOneOrMoreValues extends FilterRule {
	/**
	 *
	 * @param param
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(param) {
		super(param);
	}

	/**
	 *
	 * @param object
	 * @return {boolean}
	 */
	apply(object) {
		if (!object) {
			return false;
		}

		if (!this.value || !this.parameter) {
			return true;
		}

		if (!Array.isArray(this.value)) {
			return true;
		}

		const objectValue = object[this.parameter];
		if (objectValue === null || objectValue === undefined) {
			return false;
		}

		return this.value.findIndex((value) => value === objectValue) >= 0;
	}
}
