import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 *
 */
export default class DocumentDraftActions extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className="document-draft-actions actions">
				<div className="actions__action" data-action="edit" onClick={this.onClick}>
					<i className="icon icon--edit-black" />
					<FormattedMessage id="label.edit" />
				</div>
				<div className="actions__action" data-action="open" onClick={this.onClick}>
					<i className="icon icon--eye2-black" />
					<FormattedMessage id="label.open" />
				</div>
				{/* <div className="actions__action" data-action="download" onClick={this.onClick}> */}
				{/*	<i className="icon icon--download2-black"/> */}
				{/*	<FormattedMessage id="label.download"/> */}
				{/* </div> */}
				<div className="actions__action" data-action="remove" onClick={this.onClick}>
					<i className="icon icon--delete-black" />
					<FormattedMessage id="label.remove" />
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.action;
		this.props.onSelect(action);
	}
}

DocumentDraftActions.propTypes = {
	onSelect: PropTypes.func.isRequired
};
