import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (id, data) => {
	let headers = null;
	if (!(data instanceof FormData)) {
		headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
	}
	return fetch(Methods.PATCH, EndPoints.COMPANY.replace(':id', id), data, headers, true);
};
