/**
 *
 */
export default class Child {
	initials = null;

	birthDate = null;

	/**
	 *
	 * @param initials
	 * @param birthDate
	 */
	constructor(initials = null, birthDate = null) {
		this.initials = initials;
		this.birthDate = birthDate;
	}

	/**
	 *
	 * @return {string}
	 */
	toString() {
		return `Child: ${this.initials}, ${this.birthDate}`;
	}

	/**
	 *
	 * @param data
	 * @return {Child}
	 */
	static fromJSON(data) {
		const child = new Child();
		const childData = JSON.parse(data);

		for (const key in childData) {
			if (childData.hasOwnProperty(key)) {
				child[key] = childData[key];
			}
		}

		return child;
	}
}
