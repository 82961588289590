import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FacebookSVG from './facebook.svg';
import InstagramSVG from './instagram.svg';
import LinkedinSVG from './linkedin.svg';
import TwitterSVG from './twitter.svg';

import { stripProtocolFromUrl } from '../../../utils/objectToFormData';

export default class SocialLink extends React.Component {
	constructor() {
		super();

		this.state = { focus: false };
	}

	render() {
		console.log('SocialLink', this.props.inactive);

		const classes = classNames({ 'social-link--inactive': this.props.inactive });

		return (
			<a
				href={`//${stripProtocolFromUrl(this.props.url)}`}
				target="_blank"
				className={`social-link social-link--${this.props.type} ${classes}`}
				rel="noreferrer">
				<img alt="social-link" className="social-link__image" src={this._getSVG()} />
				<span className="social-link__label">{this._getLabel()}</span>
			</a>
		);
	}

	_getLabel() {
		switch (this.props.type) {
			case SocialLink.FACEBOOK:
				return 'Facebook';
			case SocialLink.INSTAGRAM:
				return 'Instagram';
			case SocialLink.LINKEDIN:
				return 'Linkedin';
			case SocialLink.TWITTER:
				return 'Twitter';
			default:
				return '';
		}
	}

	_getSVG() {
		switch (this.props.type) {
			case SocialLink.FACEBOOK:
				return FacebookSVG;
			case SocialLink.INSTAGRAM:
				return InstagramSVG;
			case SocialLink.LINKEDIN:
				return LinkedinSVG;
			case SocialLink.TWITTER:
				return TwitterSVG;
			default:
				return null;
		}
	}
}

SocialLink.FACEBOOK = 'facebook';
SocialLink.INSTAGRAM = 'instagram';
SocialLink.LINKEDIN = 'linkedin';
SocialLink.TWITTER = 'twitter';

SocialLink.propTypes = {
	inactive: PropTypes.bool,
	url: PropTypes.string,
	type: PropTypes.string.isRequired
};

SocialLink.defaulProps = {
	inactive: false
};
