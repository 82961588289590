/**
 * Created by hugo on 10/10/17.
 */
export default {
	NONE: { name: 'NONE', translationId: 'btwperiod.none', priority: 0 },
	MONTH: { name: 'MONTH', translationId: 'btwperiod.month', priority: 1 },
	QUARTER: { name: 'QUARTER', translationId: 'btwperiod.quarter', priority: 2 },
	YEAR: { name: 'YEAR', translationId: 'btwperiod.year', priority: 3 },
	VRIJGESTELD: { name: 'VRIJGESTELD', translationId: 'btwperiod.vrijgesteld', priority: 4 },

	getObjectByCode(code) {
		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (key === code) {
					return this[key];
				}
			}
		}

		return null;
	},

	getTermTypeLabel(termType, year, intl) {
		let termTitle = '';

		switch (termType) {
			case 'KWARTAAL1':
				termTitle = intl.formatMessage({ id: 'deadlines.quarter.1' });
				break;
			case 'KWARTAAL2':
				termTitle = intl.formatMessage({ id: 'deadlines.quarter.2' });
				break;
			case 'KWARTAAL3':
				termTitle = intl.formatMessage({ id: 'deadlines.quarter.3' });
				break;
			case 'KWARTAAL4':
				termTitle = intl.formatMessage({ id: 'deadlines.quarter.4' });
				break;
			default:
				// Month
				if (termType && termType.indexOf('MAAND') >= 0) {
					const month = parseInt(termType.split('MAAND')[1] - 1, 10);
					termTitle = intl.formatDate(new Date(year, month, 1, 0, 0, 0, 0), { month: 'long' });
					// Year
				} else {
					termTitle = intl.formatDate(new Date(year, 1, 1, 0, 0, 0), { year: 'numeric' });
				}
		}

		return termTitle;
	}
};
