import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';

/**
 *
 */
@observer
class WalkthroughEndScreen extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClose = this.onClose.bind(this);
		this.onPause = this.onPause.bind(this);

		this.state = { initialized: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ initialized: true });
		}, 300);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { walkthroughStore } = this.props;
		const { initialized } = this.state;

		const classes = classNames({
			'walkthrough-end-screen': true,
			'walkthrough-end-screen--showing':
				initialized && walkthroughStore.playing && walkthroughStore.isLastStep
		});

		return (
			<div className={classes}>
				<div className="walkthrough-end-screen__panel">
					<div className="walkthrough-end-screen__panel-content">
						<h1>
							<FormattedMessage id="walkthrough.end.title" />
						</h1>

						<p className="walkthrough-end-screen__description">
							<FormattedMessage id="walkthrough.end.description" />
						</p>

						<div className="walkthrough-end-screen__controls">
							<button type="button" className="button--primary" onClick={this.onPause}>
								<FormattedMessage id="walkthrough.end.closebutton" />
							</button>
						</div>
					</div>
					<div
						className="walkthrough-end-screen__close icon icon--left icon--close cursor-pointer"
						onClick={this.onPause}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onClose() {
		const { walkthroughStore } = this.props;
		walkthroughStore.stop();
	}

	/**
	 *
	 */
	onPause() {
		const { walkthroughStore } = this.props;
		walkthroughStore.pause();
	}
}

WalkthroughEndScreen.contextType = ApplicationContext;

WalkthroughEndScreen.propTypes = {
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore),
	// eslint-disable-next-line react/no-unused-prop-types
	showing: PropTypes.bool
};

WalkthroughEndScreen.defaultProps = {};

export default injectIntl(WalkthroughEndScreen);
