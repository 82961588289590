import AbstractCommand from '../AbstractCommand';
import authorizationsGet from '../../requests/oauth/authorizationsGet';

/**
 *
 */
export default class GetAuthorizations extends AbstractCommand {
	/**
	 * @param company Company
	 * @param authorizationStore AuthorizationStore
	 */
	constructor(company, authorizationStore) {
		super();

		this.company = company;
		this.authorizationStore = authorizationStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		authorizationsGet(this.company.id)
			.then((response) => {
				this.authorizationStore.setAuthorisations(response.data);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
