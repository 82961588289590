import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../ApplicationContext';

const useCompany = () => {
	const [company, setCompany] = useState(null);
	const { applicationStore } = useContext(ApplicationContext);
	useEffect(() => {
		setCompany(applicationStore.getSelectedCompany());
	}, [applicationStore]);
	return company;
};

export default useCompany;
