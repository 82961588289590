import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 *
 * @param companyId
 * @param uuid
 * @return {Promise}
 */
export default (companyId, uuid) => {
	const headers = { Accept: 'image/*' };
	return fetch(
		Methods.GET,
		EndPoints.COMPANY_ASSETS_UUID.replace(':id', companyId).replace(':uuid', uuid),
		null,
		headers,
		true
	);
};

/**
 *
 * @param uuid
 * @param width
 * @param height
 * @return {*}
 */
export function buildAssetURL(uuid, width = null, height = null) {
	if (!uuid) {
		return null;
	}

	let url = window.config.apiPrefix + EndPoints.ASSETS_UUID.replace(':uuid', uuid);

	if (width) {
		if (url.indexOf('?') <= 0) {
			url += '?';
		}

		url += `width=${width}`;
	}

	if (height) {
		if (url.indexOf('?') <= 0) {
			url += '?';
		} else {
			url += '&';
		}

		url += `height=${height}`;
	}

	return url;
}

/**
 *
 * @param companyId
 * @param uuid
 * @param download
 * @return {*}
 */
export function buildCompanyAssetURL(companyId, uuid, download = false) {
	if (!companyId || !uuid || uuid === '') {
		return null;
	}

	let url =
		window.config.apiPrefix +
		EndPoints.COMPANY_ASSETS_UUID.replace(':id', companyId).replace(':uuid', uuid);
	if (download) {
		url += '?download=true';
	}

	return url;
}
