import { observable, action } from 'mobx';

import WalkthroughStep from './models/WalkthroughStep';
import Signals from '../signals/Signals';

/**
 *
 */
export default class WalkthroughStore {
	@observable stopped = false;

	@observable playing = true;

	@observable current = 0;

	@observable steps = [];

	/**
	 *
	 * @param data
	 */
	@action parseData(data) {
		this.steps = [];

		if (data) {
			data.forEach((stepData) => {
				const step = new WalkthroughStep(stepData);
				this.steps.push(step);
			});
		}
	}

	/**
	 *
	 * @return {*}
	 */
	get currentStep() {
		return this.current < this.steps.length ? this.steps[this.current] : null;
	}

	/**
	 *
	 * @return {*}
	 */
	get nextStep() {
		return this.current < this.steps.length - 1 ? this.steps[this.current - 1] : null;
	}

	/**
	 *
	 */
	get isFirstStep() {
		return this.current <= 0;
	}

	/**
	 *
	 */
	get isLastStep() {
		return this.current >= this.steps.length - 1;
	}

	/**
	 *
	 */
	@action next() {
		this.current++;
		this.current = this.current >= this.steps.length ? this.steps.length : this.current;
		Signals.WalkthroughUpdate.dispatch();
		Signals.RootClick.dispatch();
	}

	/**
	 *
	 */
	@action previous() {
		this.current--;
		this.current = this.current < 0 ? 0 : this.current;

		// Skip clickable steps
		if (this.steps[this.current].clickable) {
			this.current--;
			this.current = this.current < 0 ? 0 : this.current;
		}

		Signals.WalkthroughUpdate.dispatch();
		Signals.RootClick.dispatch();
	}

	/**
	 *
	 */
	@action pause() {
		this.playing = false;
		Signals.WalkthroughPaused.dispatch();
		Signals.RootClick.dispatch();
	}

	/**
	 *
	 */
	@action play() {
		this.playing = true;
		Signals.WalkthroughUpdate.dispatch();
	}

	/**
	 *
	 */
	@action stop() {
		this.playing = false;
		this.stopped = true;
		Signals.WalkthroughUpdate.dispatch();
		Signals.RootClick.dispatch();
	}

	/**
	 *
	 * @param index
	 */
	@action setStep(index) {
		this.current = index;
		this.play();
		Signals.WalkthroughUpdate.dispatch();
	}
}
