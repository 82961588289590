import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId) => {
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_OFFER_LATEST_OFFER_NR.replace(':companyId', companyId),
		null,
		null,
		true
	);
};
