/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Routes } from '../../../data/Routes';
import Signals from '../../../signals/Signals';
import { getStateValue } from '../../../utils/ReactUtils';

import Page from '../../Page';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import DropDown from '../../../components/ui/DropDown/DropDown';
import Switch from '../../../components/ui/Switch/Switch';
import DateInput from '../../../components/ui/DateInput/DateInput';

import userUpdate from '../../../requests/userUpdate';
import { _formatValue } from '../../../utils/objectToFormData';
import { extractErrorMessage } from '../../../utils/extractErrorMessage';
import { ApplicationContext } from '../../../ApplicationContext';
import RadioButton from '../../../components/ui/RadioButton/RadioButton';
import PartnerDeclarationType from '../../../data/PartnerDeclarationType';

/**
 *
 */
class UserInfo extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			submitting: false
		};

		this.onDateChange = this.onDateChange.bind(this);
		this.onPartnerDateChange = this.onPartnerDateChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	render() {
		const user = this.context.applicationStore.user;
		if (!user) {
			return null;
		}

		const { intl } = this.props;
		const { errors } = this.state;

		// Render
		const formClasses = classNames({
			'form--submitting': this.state.submitting
		});
		return (
			<Page pageName="user-info">
				<div className="user-info__panel panel border border--dark">
					<div className="user-info__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="user.profile.title" />
						</h2>
					</div>

					<form
						className={`user-info__content ${formClasses}`}
						onSubmit={this.onSubmit}
						onChange={this.onInputChange}>
						<div className="padding">
							<FormGroup className="grid" errors={errors}>
								<FormField className="col--5 user-info__first-name-field">
									<label>
										<FormattedMessage id="user.profile.field.firstname" />
									</label>
									<input
										name="firstName"
										type="text"
										value={getStateValue(this.state.firstName, user.firstName)}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--2 user-info__initials-field">
									<label>
										<FormattedMessage id="user.profile.field.initials" />
									</label>
									<input
										name="initials"
										type="text"
										value={getStateValue(this.state.initials, user.initials)}
										maxLength="255"
									/>
								</FormField>

								<FormField className="col--5">
									<label>
										<FormattedMessage id="user.profile.field.lastname" />
									</label>
									<input
										name="lastName"
										type="text"
										value={getStateValue(this.state.lastName, user.lastName)}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField
									className="col--5"
									error={extractErrorMessage(errors, 'address.address')}>
									<label>
										<FormattedMessage id="user.profile.field.address" />
									</label>
									<input
										name="address"
										type="text"
										value={getStateValue(this.state.address, user.address.address)}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField
									className="col--3 user-info__postalcode-field"
									error={extractErrorMessage(errors, 'address.postalCode')}>
									<label>
										<FormattedMessage id="user.profile.field.postalcode" />
									</label>
									<input
										name="postalCode"
										type="text"
										placeholder={intl.formatMessage({
											id: 'placeholder.postalcode'
										})}
										value={getStateValue(this.state.postalCode, user.address.postalCode)}
										maxLength="6"
										required
									/>
								</FormField>

								<FormField
									className="col--4 user-info__city-field"
									error={extractErrorMessage(errors, 'address.city')}>
									<label>
										<FormattedMessage id="user.profile.field.city" />
									</label>
									<input
										name="city"
										type="text"
										value={getStateValue(this.state.city, user.address.city)}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="user.profile.field.phonenumber" />
									</label>
									<input
										name="phoneNumber"
										type="tel"
										value={getStateValue(this.state.phoneNumber, user.phoneNumber)}
										maxLength="255"
										required
									/>
								</FormField>

								<FormField className="col--6 form-field__date-picker">
									<label>
										<FormattedMessage id="user.profile.field.birthdate" />
									</label>
									<DateInput
										name="birthday"
										value={getStateValue(this.state.birthday, user.birthDay)}
										required
										onChange={this.onDateChange}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="user.profile.field.bsn" />
									</label>
									<input
										name="bsn"
										type="text"
										value={getStateValue(this.state.bsn, user.bsn)}
										maxLength="9"
										required
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="user.profile.field.language" />
									</label>
									<DropDown
										name="language"
										className="col--12"
										value={getStateValue(this.state.language, user.language)}
										onChange={this.onInputChange}>
										<option name="nl" value="nl">
											{intl.formatMessage({
												id: 'user.profile.field.language.dutch'
											})}
										</option>
										<option name="en" value="en">
											{intl.formatMessage({
												id: 'user.profile.field.language.english'
											})}
										</option>
									</DropDown>
								</FormField>

								<FormField className="col--12">
									<label>
										<FormattedMessage id="user.profile.field.email" />
									</label>
									<input
										name="email"
										className="col--12"
										type="email"
										value={getStateValue(this.state.email, user.email)}
										maxLength="255"
										required
									/>
								</FormField>

								{/* Show password form when email adres changes, is required */}
								{this.state.email && this.state.email !== user.email ? (
									<FormField className="col--12">
										<label>
											<FormattedMessage id="user.profile.field.passwordcheck" />
										</label>
										<input
											name="password"
											className="col--12"
											type="password"
											value={getStateValue(this.state.password, user.password)}
											maxLength="255"
											autoComplete="off"
										/>
									</FormField>
								) : null}
							</FormGroup>

							<FormGroup className="grid">
								<FormField className="col--12">
									<label className="col--12">
										<FormattedMessage id="user.profile.field.fiscalpartner" />
									</label>
									<p className="font--meta col--12">
										<FormattedHTMLMessage id="user.profile.field.fiscalpartner.description" />
									</p>
									<Switch
										name="fiscalPartner"
										className="col--6"
										label={intl.formatMessage({
											id: 'user.profile.field.fiscalpartner.label'
										})}
										checked={getStateValue(this.state.fiscalPartner, user.fiscalPartner)}
										onChange={this.onInputChange}
									/>
								</FormField>
							</FormGroup>

							{this.getFiscalPartnerDeclarationOptionForm()}
							{this.getFiscalPartnerWantsDeclarationForm()}
						</div>
						<div className="options-footer user-info__footer border--top border--dark grid grid--spread">
							<div className="grid user-info__footer-buttons">
								<Link to={Routes.USER_SETTINGS_PASSPORT.pathName}>
									<button className="user-info__passport-button button" type="button">
										<FormattedMessage id="user.profile.passport" />
									</button>
								</Link>
							</div>
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		this.state.birthday = date;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onPartnerDateChange(e, date) {
		this.state.partnerBirthday = date;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'birthday':
			case 'partnerBirthday':
				// ignore
				break;
			case 'fiscalPartner':
				this.state[e.target.name] = e.target.checked;
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		const user = this.context.applicationStore.user;
		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'city':
						data['address.city'] = this.state[key];
						break;
					case 'address':
						data['address.address'] = this.state[key];
						break;
					case 'postalCode':
						data['address.postalCode'] = this.state[key];
						break;
					case 'language':
						data[key] = this.state[key].toUpperCase();
						break;
					case 'birthday':
					case 'partnerBirthday':
						data[key] = _formatValue(this.state[key]);
						break;
					case 'email':
						data[key] = this.state[key] === user.email ? null : this.state[key];
						break;
					case 'errors':
					case 'submitting':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		if (Object.keys(data).length > 0) {
			this.setState({ errors: null, submitting: true });
			userUpdate(data).then(this.onSubmitSuccess).catch(this.onSubmitError);
		} else {
			// Just show success message
			Signals.ShowMessageDialog.dispatch(<FormattedMessage id="user.profile.success.body" />);
		}
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		// Update user object
		this.context.applicationStore.user.update(response.data);

		// Reload todos
		this.context.applicationStore.todoStore.fetch();

		// Reset bankbook store
		this.context.applicationStore.bankbookStatusStore.reset();
		this.context.applicationStore.bankCategoryStore.reset();

		// Show updated message
		Signals.ShowMessageDialog.dispatch(<FormattedMessage id="user.profile.success.body" />);

		// Reset state
		this.setState({
			submitting: false,
			errors: null
		});
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	getFiscalPartnerDeclarationOptionForm() {
		const user = this.context.applicationStore.user;
		const { intl } = this.props;
		const { errors } = this.state;

		const partnerDeclarationType = getStateValue(
			this.state.partnerDeclarationType,
			user.partnerDeclarationType
		);

		const fiscalPartner = getStateValue(this.state.fiscalPartner, user.fiscalPartner);
		if (fiscalPartner) {
			return (
				<FormGroup error={extractErrorMessage(errors, 'partnerDeclarationType')}>
					<FormField className="col--12">
						<RadioButton
							className="col--12"
							value={PartnerDeclarationType.PARTNER_IS_CUSTOMER}
							name="partnerDeclarationType"
							label={intl.formatMessage({
								id: 'user.profile.partnerdeclarationtype.partner_is_customer'
							})}
							checked={partnerDeclarationType === PartnerDeclarationType.PARTNER_IS_CUSTOMER}
							onChange={this.onInputChange}
						/>

						<RadioButton
							className="col--12"
							value={PartnerDeclarationType.DECLARATION_VIA_KDB}
							name="partnerDeclarationType"
							label={intl.formatMessage({
								id: 'user.profile.partnerdeclarationtype.declaration_via_kdb'
							})}
							checked={partnerDeclarationType === PartnerDeclarationType.DECLARATION_VIA_KDB}
							onChange={this.onInputChange}
						/>

						<RadioButton
							className="col--12"
							value={PartnerDeclarationType.DECLARATION_SELF_SERVICE}
							name="partnerDeclarationType"
							label={intl.formatMessage({
								id: 'user.profile.partnerdeclarationtype.declaration_self_service'
							})}
							checked={partnerDeclarationType === PartnerDeclarationType.DECLARATION_SELF_SERVICE}
							onChange={this.onInputChange}
						/>
					</FormField>
				</FormGroup>
			);
		}
	}

	/**
	 *
	 */
	getFiscalPartnerWantsDeclarationForm() {
		/**
		 private String partnerInitials;
		 private String partnerLastName;
		 private Date partnerBirthday;
		 private String partnerBsn;
		 private String partnerAddress;
		 private String partnerCity;
		 private String partnerPostalCode;
		 */
		const user = this.context.applicationStore.user;
		const {
			partnerBsn,
			partnerInitials,
			partnerLastName,
			partnerBirthday,
			partnerAddress,
			partnerCity,
			partnerPostalCode,
			errors
		} = this.state;

		const fiscalPartner = getStateValue(this.state.fiscalPartner, user.fiscalPartner);
		const partnerDeclarationType = getStateValue(
			this.state.partnerDeclarationType,
			user.partnerDeclarationType
		);

		//
		if (fiscalPartner && partnerDeclarationType === PartnerDeclarationType.DECLARATION_VIA_KDB) {
			return (
				<FormGroup>
					<FormField className="col--6 user-info__first-name-field">
						<label>
							<FormattedMessage id="user.profile.field.firstname" />
						</label>
						<input
							name="partnerInitials"
							type="text"
							value={getStateValue(partnerInitials, user.partnerInitials)}
							maxLength="255"
							required
						/>
					</FormField>

					<FormField className="col--6">
						<label>
							<FormattedMessage id="user.profile.field.lastname" />
						</label>
						<input
							name="partnerLastName"
							type="text"
							value={getStateValue(partnerLastName, user.partnerLastName)}
							maxLength="255"
							required
						/>
					</FormField>

					<FormField className="col--6 form-field__date-picker">
						<label>
							<FormattedMessage id="user.profile.field.birthdate" />
						</label>
						<DateInput
							name="partnerBirthday"
							value={getStateValue(partnerBirthday, user.partnerBirthday)}
							required
							onChange={this.onPartnerDateChange}
						/>
					</FormField>

					<FormField
						className="col--6"
						error={
							extractErrorMessage(errors, 'partnerBsn')
								? this.props.intl.formatMessage({
										id: 'errors.partnerBsn'
								  })
								: ''
						}>
						<label>
							<FormattedMessage id="user.profile.field.bsn" />
						</label>
						<input
							name="partnerBsn"
							type="text"
							value={getStateValue(partnerBsn, user.partnerBsn)}
							maxLength="9"
							required
						/>
					</FormField>

					<FormField className="col--12">
						<label>
							<FormattedMessage id="user.profile.field.address" />
						</label>
						<input
							name="partnerAddress"
							type="text"
							value={getStateValue(partnerAddress, user.partnerAddress)}
							maxLength="255"
							required
						/>
					</FormField>

					<FormField
						className="col--4"
						error={
							extractErrorMessage(errors, 'partnerPostalCode')
								? this.props.intl.formatMessage({
										id: 'errors.partnerPostalCode'
								  })
								: ''
						}>
						<label>
							<FormattedMessage id="user.profile.field.postalcode" />
						</label>
						<input
							name="partnerPostalCode"
							type="text"
							value={getStateValue(partnerPostalCode, user.partnerPostalCode)}
							maxLength="6"
							required
						/>
					</FormField>

					<FormField className="col--8">
						<label>
							<FormattedMessage id="user.profile.field.city" />
						</label>
						<input
							name="partnerCity"
							type="text"
							value={getStateValue(partnerCity, user.partnerCity)}
							maxLength="255"
							required
						/>
					</FormField>
				</FormGroup>
			);
		}

		//
		if (fiscalPartner && partnerDeclarationType === PartnerDeclarationType.PARTNER_IS_CUSTOMER) {
			return (
				<FormGroup>
					<FormField className="col--6">
						<label>
							<FormattedMessage id="user.profile.field.bsn" />
						</label>
						<input
							name="partnerBsn"
							type="text"
							value={getStateValue(partnerBsn, user.partnerBsn)}
							maxLength="9"
							required
						/>
					</FormField>
				</FormGroup>
			);
		}
	}
}

UserInfo.contextType = ApplicationContext;

UserInfo.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(UserInfo);
