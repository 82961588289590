import AbstractCommand from './AbstractCommand';

import countriesGet from '../requests/countriesGet';
import Signals from '../signals/Signals';
import Country from '../stores/models/Country';

/**
 *
 */
export default class GetCountriesCommand extends AbstractCommand {
	/**
	 * @param company Company
	 * @param countryStore CountryStore
	 */
	constructor(company, countryStore) {
		super();

		this.company = company;
		this.countryStore = countryStore;

		this.onCountriesLoaded = this.onCountriesLoaded.bind(this);
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		// Load from server, or use from memory
		if (!this.countryStore.loaded) {
			this.countryStore.loading = true;
			countriesGet()
				.then(this.onCountriesLoaded)
				.catch((err) => {
					this.countryStore.loading = false;
					Signals.Error.dispatch(err);

					if (error) {
						error(err);
					}
				});
			// If loaded, just re-use what is in memory
		} else {
			// Trigger mobx change
			this.countryStore.countries = this.countryStore.countries.concat();
			if (success) {
				success();
			}
		}
	}

	/**
	 *
	 * @param response
	 */
	onCountriesLoaded(response) {
		const data = response.data.countries;
		const countries = [];
		for (const iso in data) {
			if (data.hasOwnProperty(iso)) {
				countries.push(new Country(iso, data[iso]));
			}
		}

		this.countryStore.countries = countries;
		this.countryStore.loading = false;
		this.countryStore.loaded = true;
	}
}
