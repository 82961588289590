import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import OverlayPanel from '../../components/ui/Panel/OverlayPanel';
import Signals from '../../signals/Signals';

import Draft from '../../stores/models/Draft';
import Document from '../../stores/models/Document';
import DraftsStore from '../../stores/DraftsStore';

import { ApplicationContext } from '../../ApplicationContext';

import AssetUpload from '../../components/ui/AssetUpload/AssetUpload';
import PanelHeader from '../../components/ui/Panel/PanelHeader';
import PanelFooter from '../../components/ui/Panel/PanelFooter';

import DocumentEditor from './DocumentEditor';
import CreateUserDocumentCommand from '../../commands/documents/CreateUserDocumentCommand';
import CreateCompanyDocumentCommand from '../../commands/documents/CreateCompanyDocumentCommand';
import DeleteUserDraftCommand from '../../commands/drafts/DeleteUserDraftCommand';
import DeleteCompanyDraftCommand from '../../commands/drafts/DeleteCompanyDraftCommand';
import User from '../../stores/models/User';
import Company from '../../stores/models/Company';
import ModalConfirm from '../../components/ui/Modal/ModalConfirm';

/**
 *
 */
@observer
class DocumentInputPanel extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onRemove = this.onRemove.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onAssetFile = this.onAssetFile.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitHandled = this.onSubmitHandled.bind(this);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.el = null;
		this.content = null;
		this.state = {
			document: null
		};
	}

	/**
	 *
	 */
	componentWillMount() {
		const { document, draft, draftsStore } = this.props;

		// Use Document as base
		if (document) {
			this.setState({
				document: document.clone()
			});

			// Use Draft as base
		} else if (draft) {
			const document = Document.createNewDocumentFromDraft(draft);
			document.owner = draftsStore.owner instanceof Company ? draftsStore.owner.id : 'user';

			console.log('DocumentInputPanel.componentWillMount', document, document.owner);

			this.setState({
				document
			});
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.KeyDown.add(this.onKeyDown);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 */
	render() {
		const { intl, className, draft } = this.props;
		const { document, submitting, errors } = this.state;
		const { applicationStore } = this.context;

		const financialYear = applicationStore.getSelectedFinancialYear();
		const company = applicationStore.getSelectedCompany();

		if (!financialYear || !company || !document) {
			return null;
		}

		const classes = classNames({
			'document-input-panel': true,
			'document-input-panel--scrollable':
				this.content && this.content.scrollHeight > this.content.clientHeight
		});
		const formClasses = classNames({
			'document-input-panel__right': true,
			'form--submitting': submitting
		});

		let titleId = document.id ? 'document.input.edit.title' : 'document.input.new.title';
		titleId = draft ? 'document.input.draft.title' : titleId;

		const hasPreview = !!(draft && draft.assetUuid);

		return (
			<OverlayPanel className={`${classes} ${className}`}>
				{/* Image/PDF/File */}
				<div className="document-input-panel__left">
					<AssetUpload
						assetUuid={draft.assetUuid}
						onChange={this.onAssetFile}
						hideContentArea={hasPreview}
					/>
				</div>

				{/* Input/Data */}
				<form className={formClasses} onSubmit={this.onSubmit}>
					<PanelHeader>
						<h2 className="pad-left">
							<FormattedMessage id={titleId} />
						</h2>
						<div
							className="overlay-panel__close icon icon icon--close-black"
							onClick={this.onCancel}
						/>
					</PanelHeader>

					<div
						ref={(ref) => {
							this.content = ref;
						}}
						className="document-input-panel__form-container">
						<DocumentEditor document={document} submitting={submitting} errors={errors} />
					</div>

					<PanelFooter>
						<div className="document-input-panel__footer__left grid">
							{/* <button className="button--tertiary" type="button" onClick={this.onCancel}> */}
							{/*	<FormattedMessage id="label.cancel"/> */}
							{/* </button> */}
							<button className="button button-small--danger" type="button" onClick={this.onRemove}>
								<FormattedMessage id="label.remove" />
							</button>
						</div>
						<div className="document-input-panel__footer__right grid">
							<input
								className="button--tertiary btn-margin-left"
								type="submit"
								value={intl.formatMessage({ id: 'drafts.label.save.document' })}
							/>
						</div>
					</PanelFooter>
				</form>
			</OverlayPanel>
		);
	}

	/**
	 *
	 */
	onRemove() {
		const { intl, draft } = this.props;
		const title = intl.formatMessage({ id: 'draft.remove.alert.title' }, { id: draft.id });
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				yesLabel={intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
				onConfirm={() => this.doRemove()}
			/>
		);
	}

	/**
	 *
	 */
	onCancel() {
		// TODO: check for changes
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		// esc
		if (e.keyCode === 27) {
			this.onCancel();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			Signals.HideOverlay.dispatch();
		}
	}

	/**
	 *
	 * @param file
	 */
	onAssetFile(_file) {}

	/**
	 *
	 */
	onSubmit(e) {
		const { document } = this.state;

		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		this.doSubmitDocument();
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		const { draft, draftsStore } = this.props;

		// Delete Draft when succesfully saved as Company or User Document
		if (draft) {
			if (draftsStore.owner instanceof User) {
				const command = new DeleteUserDraftCommand(draftsStore, draft.user.id, draft, true);
				command.execute(this.onSubmitSuccess, this.onSubmitError);
			}

			if (draftsStore.owner instanceof Company) {
				const company = draftsStore.owner;
				const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft, true);
				command.execute(this.onSubmitHandled);
			}
		} else {
			this.onSubmitHandled();
		}
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	onSubmitHandled() {
		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 */
	doRemove() {
		/**
		 * draftsStore, companyId, draft
		 * @type {DeleteCompanyDraftCommand}
		 */
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		const { draft } = this.props;
		const { draftsStore } = company;

		const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft);
		command.execute(() => Signals.HideOverlay.dispatch());
	}

	/**
	 *
	 */
	doSubmitDocument() {
		const { applicationStore } = this.context;
		const { document } = this.state;
		const { owner, year } = document;

		this.setState({ errors: null, submitting: true });

		// Build requests
		if (document.owner === 'user') {
			const command = new CreateUserDocumentCommand(
				document,
				applicationStore.documentStore,
				applicationStore
			);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
		} else {
			const companyId = parseInt(owner, 10);
			const company = this.context.applicationStore.user.getCompanyById(companyId);
			const financialYear = company.getFinancialYearByYear(parseInt(year, 10));

			const command = new CreateCompanyDocumentCommand(
				document,
				applicationStore.documentStore,
				companyId,
				financialYear.id,
				applicationStore
			);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
		}
	}
}

DocumentInputPanel.contextType = ApplicationContext;

DocumentInputPanel.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	document: PropTypes.instanceOf(Document),
	draft: PropTypes.instanceOf(Draft),
	draftsStore: PropTypes.instanceOf(DraftsStore)
};

DocumentInputPanel.defaultProps = {
	className: ''
};

export default injectIntl(DocumentInputPanel);
