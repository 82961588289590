import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class FileExports extends React.Component {
	constructor() {
		super();

		this.onClick = this.onClick.bind(this);
	}

	render() {
		return (
			<div className="file-exports actions">
				<div className="actions__action " data-type="csv" onClick={this.onClick}>
					<i className="icon icon--file-black" />
					<FormattedMessage id="file.csv" />
				</div>
				<div className="actions__action " data-type="xlsx" onClick={this.onClick}>
					<i className="icon icon--file-black" />
					<FormattedMessage id="file.xlsx" />
				</div>
			</div>
		);
	}

	onClick(e) {
		e.preventDefault();
		const action = e.currentTarget.dataset.type;
		this.props.onSelect(action);
	}
}

FileExports.propTypes = {
	onSelect: PropTypes.func.isRequired
};
