export default {
	UNKNOWN: 'UNKNOWN',
	PAID: 'PAID',

	/**
	 * can still be paid
	 */
	OPEN: 'OPEN',
	EXPIRED: 'EXPIRED',
	FAILED: 'FAILED',
	PENDING: 'PENDING',
	AUTHORIZED: 'AUTHORIZED',
	CANCELED: 'CANCELED'
};
