import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, year) => {
	return fetch(
		Methods.GET,
		`${EndPoints.COMPANIES_BANKBOOK_GET_TRANSACTIONS}?companyId=${companyId}&year=${year}`,
		null,
		null,
		true
	);
};
