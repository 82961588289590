/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import objectToFormData from '../../utils/objectToFormData';

import documentsUserCreate from '../../requests/documentsUserCreate';
import documentsCompanyCreate from '../../requests/documentsCompanyCreate';
import { getPrivateDocumentTypes, getCompanyDocumentTypes } from '../../data/DocumentTypes';

import { ApplicationContext } from '../../ApplicationContext';
import FormGroup from '../../components/ui/FormGroup/FormGroup';
import FormField from '../../components/ui/FormField/FormField';
import Signals from '../../signals/Signals';
import FileUpload from '../../components/ui/FileUpload/FileUpload';
import DropDown from '../../components/ui/DropDown/DropDown';
import IconRadioButton from '../../components/ui/IconRadioButton/IconRadioButton';
import { Routes } from '../../data/Routes';

/**
 *
 */
class AddDocumentInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onCancel = this.onCancel.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this._reset();
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.OverlayBackgroundClick.add(this.onCancel);
		//
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this._reset();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.OverlayBackgroundClick.remove(this.onCancel);
	}

	/**
	 *
	 */
	render() {
		const formClasses = classNames({ 'form--submitting': this.state.submitting });

		return (
			<div className="add-document-input col--12">
				<div className="add-document__header padding border--bottom border--dark grid grid--spread">
					<h2 className="pad-left">
						<FormattedMessage id="documents.add.title" />
					</h2>
					<div
						className="income-input__close icon icon icon--close-black"
						onClick={this.onCancel}
					/>
				</div>

				<form onSubmit={this.onSubmit} className={formClasses}>
					<div className="add-document-input__content padding-small">
						<FormGroup errors={this.state.errors}>
							<FormField className="col--6">
								<label>
									<FormattedMessage id="documents.year" />
								</label>
								<DropDown
									name="year"
									className="col--12"
									value={this.state.year}
									onChange={this.onInputChange}>
									{this._getYearOptions()}
								</DropDown>
							</FormField>
							<FormField className="col--6">
								<label>
									<FormattedMessage id="documents.owner" />
								</label>
								<DropDown
									name="owner"
									className="col--12"
									value={this.state.owner}
									onChange={this.onInputChange}>
									{this._getOwnerOptions()}
								</DropDown>
							</FormField>
						</FormGroup>

						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="documents.category" />
								</label>
								{this._getCategoryOptions()}
							</FormField>
						</FormGroup>

						<FormGroup errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="documents.file" />
								</label>
								<FileUpload
									className="col--12"
									name="file"
									filePreview={undefined}
									fileName={this.state.fileName}
									label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
									showFilePreview
									required
									onChange={this.onFileChange}
								/>
							</FormField>
						</FormGroup>
					</div>

					<div className="options-footer border--top border--dark grid grid--spread">
						<button onClick={this.onCancel} className="button" type="button">
							<FormattedMessage id="label.cancel" />
						</button>

						{this._getOptionButtons()}
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 */
	/**
	 *
	 * @param e
	 */
	onCancel(e) {
		if (e) {
			e.preventDefault();
		}

		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 */
	onFileChange(file, fileName) {
		this.setState({ file, fileName });
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		this.doSubmit(e);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		Signals.DocumentSubmitted.dispatch(response.data);

		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="documents.added.message"
				values={{ fileName: this.state.fileName }}
			/>
		);

		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();

		// Preset year
		this.context.applicationStore.setSelectedDocumentYear(this.state.year);

		// Navigate to owner documents
		if (this.state.owner === 'user') {
			Signals.RequestRoute.dispatch(Routes.USER_DOCUMENTS.getPath({}));
		} else {
			Signals.RequestRoute.dispatch(Routes.COMPANY_DOCUMENTS.getPath({ id: this.state.owner }));
		}

		//
		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const newState = {};

		switch (e.target.name) {
			case 'sendToBookkeeper':
				newState.sendToBookkeeper = e.target.checked;
				break;
			default:
				newState[e.target.name] = e.target.value;
		}

		this.setState(newState);
	}

	/**
	 *
	 */
	doSubmit(e) {
		console.log('AddDocumentInput.doSubmit', e, this.state);

		// Create FormData
		const ignoreFields = ['errors', 'submitting', 'owner', 'sendToBookkeeper'];
		const formData = objectToFormData(this.state, true, ignoreFields);

		const owner = `${this.state.owner}`;
		const year = `${this.state.year}`;

		this.setState({ errors: null, submitting: true });

		// Build requests
		if (owner === 'user') {
			documentsUserCreate(formData, this.state.sendToBookkeeper)
				.then(this.onSubmitSuccess)
				.catch(this.onSubmitError);
		} else {
			const companyId = parseInt(owner, 10);
			const company = this.context.applicationStore.user.getCompanyById(companyId);
			const financialYear = company.getFinancialYearByYear(parseInt(year, 10));
			documentsCompanyCreate(formData, companyId, financialYear.id, this.state.sendToBookkeeper)
				.then(this.onSubmitSuccess)
				.catch(this.onSubmitError);
		}
	}

	/**
	 *
	 * @private
	 */
	_reset() {
		let defaultOwnerValue = 'user';
		this.context.applicationStore.user.companies.forEach((company) => {
			if (`${this.context.applicationStore.currentRouteParams.id}` === `${company.id}`) {
				defaultOwnerValue = company.id;
			}
		});

		this.state = {
			owner: defaultOwnerValue,
			year: this.context.applicationStore.getSelectedDocumentYear()
		};
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getYearOptions() {
		// Check which company, or which user is selected
		const now = new Date();
		let availableYears = [now.getFullYear()];
		this.context.applicationStore.user.companies.forEach((company) => {
			// eslint-disable-next-line array-callback-return
			company.financialYears.map((year) => {
				availableYears.push(parseInt(year.year, 10));
			});
		});

		// Only unique years
		availableYears = availableYears.filter((year, index) => {
			return availableYears.indexOf(year) === index;
		});
		availableYears.sort((a, b) => {
			return b - a;
		});

		return availableYears.map((year, index) => {
			return (
				<option key={`fy-${index}`} value={year}>
					{year}
				</option>
			);
		});
	}

	/**
	 *
	 * @private
	 */
	_getOwnerOptions() {
		const result = [];
		result.push(
			<option key="udo" value="user">
				{this.context.applicationStore.user.getFullName()}
			</option>
		);
		this.context.applicationStore.user.companies.forEach((company, index) => {
			result.push(
				<option key={`cdo-${index}`} value={company.id}>
					{company.name}
				</option>
			);
		});

		return result;
	}

	/**
	 *
	 * @private
	 */
	_getCategoryOptions() {
		const documents =
			this.state.owner === 'user' ? getPrivateDocumentTypes() : getCompanyDocumentTypes();

		return documents.map((type) => {
			const label = this.props.intl.formatMessage({ id: `document.type.${type.name}` });
			const iconName = type.iconName;
			return (
				<IconRadioButton
					key={`document.type.${type.name}`}
					className="icon-radio-button--border"
					name="type"
					checked={type.name === this.state.type}
					value={type.name}
					label={label}
					iconName={iconName}
					onChange={this.onInputChange}
					required
				/>
			);
		});
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getOptionButtons() {
		return (
			<div className="add-document-input__save-buttons grid">
				<button onClick={this.onSubmitSingle} className="button--primary" type="submit">
					<FormattedMessage id="label.save" />
				</button>
			</div>
		);
	}
}

AddDocumentInput.contextType = ApplicationContext;

AddDocumentInput.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(AddDocumentInput);
