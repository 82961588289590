import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import OfferModel from '../../../../stores/models/OfferModel';
import OfferHistoryRow from './OfferHistoryRow';
import { ApplicationContext } from '../../../../ApplicationContext';
import PropertiesController from '../../../../controllers/PropertiesController';

/**
 *
 */
class OfferHistory extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Always hide on demo account
		if (PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
			return null;
		}

		const rows = this.props.offerModel.history.map((historyData, index) => {
			return (
				<OfferHistoryRow
					key={`inv-his-${index}`}
					description={historyData.description}
					date={historyData.date}
				/>
			);
		});

		return (
			<div className="offer-history panel grid col--12 box-shadow border border--dark">
				<div className="offer-history__header padding grid grid--spread">
					<h2>
						<FormattedMessage id="offer.history.title" />
					</h2>
				</div>
				<div className="offer-history__rows grid">{rows}</div>
			</div>
		);
	}
}

OfferHistory.contextType = ApplicationContext;

OfferHistory.propTypes = {
	offerModel: PropTypes.instanceOf(OfferModel),
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(OfferHistory);
