import BTW from '../../data/BTW';

let UID = 0;

/**
 *
 */
export default class ExpenseRowModel {
	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		this._uid = UID++;

		this.amount = null;
		this.btw = BTW.HOOG.name;
		this.ledgerCode = 'GC_4501';
		this.disinvestDate = null;
		this.investYears = undefined;
		this.investResidualValue = undefined;

		if (data) {
			this.update(data);
		}
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in this) {
			if (key === 'amount' && typeof data.amount === 'string') {
				this.amount = parseFloat(data.amount.replace(',', '.'));
			} else if (this.hasOwnProperty(key) && key !== '_uid') {
				this[key] = data[key];
			}
		}
	}

	/**
	 * @param unique
	 * @return {ExpenseRowModel}
	 */
	clone(unique = false) {
		const expenseRowModel = new ExpenseRowModel(this);
		if (unique) {
			expenseRowModel.id = undefined;
		}
		return expenseRowModel;
	}

	/**
	 *
	 * @param expenseRowModel
	 * @return {boolean}
	 */
	equals(expenseRowModel) {
		for (const key in expenseRowModel) {
			if (expenseRowModel.hasOwnProperty(key)) {
				if (key !== '_uid') {
					const original = JSON.stringify(this[key]);
					const modified = JSON.stringify(expenseRowModel[key]);

					if (original !== modified) {
						console.log('expenseRowModel.equals', key, original, modified);
						return false;
					}
				}
			}
		}

		return true;
	}
}
