/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Routes } from '../../../../data/Routes';

import FormGroup from '../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../components/ui/FormField/FormField';
import ProfileItem from '../../../../components/ui/Profiles/ProfileItem';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class TaxReturnCompanyChecklist extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		let infoContent;
		if (this.props.hasDeadlines) {
			infoContent = (
				<Link
					to={Routes.COMPANY_DEADLINES.getPath({
						id: this.props.company.id,
						year: this.props.financialYear.id
					})}>
					<button type="button">
						<FormattedMessage id="tax-return-year-company-checklist.to.deadlines" />
					</button>
				</Link>
			);
		} else {
			infoContent = (
				<div className="tax-return-company-checklist__deadlines-check icon icon--check-black" />
			);
		}

		return (
			<div className="tax-return-company-checklist border--top border--dark padding-small">
				<FormGroup className="col--12">
					<FormField className="col--12">
						<label className="col--12">
							<FormattedMessage id="taxreturn.status.label" />
						</label>

						<div className="col--12 grid grid--spread grid--vcenter">
							<ProfileItem
								userCompany={this.props.company}
								typeLabel={this.props.intl.formatMessage({ id: 'profile.business' })}
							/>
							{infoContent}
						</div>
					</FormField>
				</FormGroup>
			</div>
		);
	}
}

TaxReturnCompanyChecklist.contextType = ApplicationContext;

TaxReturnCompanyChecklist.propTypes = {
	intl: PropTypes.object,
	company: PropTypes.object.isRequired,
	financialYear: PropTypes.object.isRequired,
	hasDeadlines: PropTypes.bool.isRequired
};

export default injectIntl(TaxReturnCompanyChecklist);
