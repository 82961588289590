import AbstractCommand from '../AbstractCommand';
import { Routes } from '../../data/Routes';
import Signals from '../../signals/Signals';
import invoiceConceptUpdate from '../../requests/invoiceConceptUpdate';
import invoiceConceptCreate from '../../requests/invoiceConceptCreate';

/**
 *
 */
export default class SaveInvoiceConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param applicationStore
	 * @param invoiceConceptModel
	 * @param company
	 * @param data
	 * @param preventNavigation
	 */
	constructor(applicationStore, invoiceConceptModel, company, data, preventNavigation = false) {
		super();

		this.applicationStore = applicationStore;
		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
		this.data = data;
		this.preventNavigation = preventNavigation;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		// Update
		if (this.invoiceConceptModel.id !== undefined && this.invoiceConceptModel.id !== null) {
			return invoiceConceptUpdate(this.company.id, this.invoiceConceptModel.id, this.data)
				.then((response) => {
					this._updateInvoiceConceptModel(response);

					if (success) {
						success(response);
					}
				})
				.catch((err) => {
					if (error) {
						error(err);
					}
				});

			// Create new
		}
		return invoiceConceptCreate(this.company.id, this.data)
			.then((response) => {
				this._updateInvoiceConceptModel(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_updateInvoiceConceptModel(response) {
		// Update model
		this.invoiceConceptModel.update(response.data);

		// Don't navigate
		if (this.preventNavigation) return;

		// Add if invoiceId was a `new` invoice or that it needs to be `duplicated`
		if (
			this.applicationStore.currentRouteParams.invoiceId === 'new' ||
			this.applicationStore.currentRoute === Routes.COMPANY_INVOICES_DUPLICATE
		) {
			// change current route if this was a `new` invoice to the route with this saved invoice id
			window.history.replaceState(
				{},
				document.title,
				Routes.COMPANY_INVOICES_EDIT.getPath({
					id: this.company.id,
					invoiceId: this.invoiceConceptModel.id
				})
			);
		}

		// Navigate to invoice 'send' screen
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_INVOICES_INVOICE.getPath({
				id: this.company.id,
				invoiceId: this.invoiceConceptModel.id
			})
		);
	}
}
