import { observable } from 'mobx';

import DeadlineState from '../../data/DeadlineState';

export default class Deadline {
	@observable id = undefined;

	@observable date = undefined; // When is it submitted

	@observable submitStart = undefined; // When can it be submitted

	@observable submitEnd = undefined; // Until when can it be submitted

	@observable deadline = undefined; // Until when can it be submitted

	@observable state = undefined; // null, INGEDIEND, VERZONDEN”,

	@observable termType = undefined; // KWARTAAL1, MONTH3

	@observable btwAmount = 0; // KWARTAAL1, MONTH3

	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'submitStart':
					case 'date':
						this[key] = new Date(data[key]);
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	isLocked() {
		return this.state === DeadlineState.DEADLINE_INGEDIEND;
	}

	canSubmit() {
		const now = Date.now();
		return (
			now >= this.submitStart.getTime() &&
			this.submitEnd >= now &&
			this.state !== DeadlineState.DEADLINE_INGEDIEND &&
			this.state !== DeadlineState.DEADLINE_VERZONDEN
		);
	}

	isSubmitted() {
		return (
			this.state === DeadlineState.DEADLINE_INGEDIEND ||
			this.state === DeadlineState.DEADLINE_VERZONDEN
		);
	}

	static parseDeadline(data) {
		const deadline = new Deadline();

		if (!data) {
			return deadline;
		}

		deadline.id = data.id;
		deadline.date = new Date(data.date);
		deadline.submitStart = new Date(data.submitStart);
		deadline.submitEnd = new Date(data.submitEnd);
		deadline.deadline = new Date(data.deadline);
		deadline.state = data.state;
		deadline.termType = data.termType;
		deadline.btwAmount = data.btwAmount;

		return deadline;
	}
}
