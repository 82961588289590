import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId) => {
	// const headers = {'content-type': 'application/json'};
	return fetch(
		Methods.GET,
		EndPoints.COMPANY_BRANDS.replace(':companyId', companyId),
		null,
		null,
		true
	);
};
