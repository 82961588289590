import { observable } from 'mobx';
import { compareStrings } from '../utils/compareUtils';

/**
 *
 */
export default class CountryStore {
	@observable countries = [];

	/**
	 *
	 */
	constructor() {
		this.loading = false;
		this.loaded = false;
	}

	/**
	 *
	 * @param iso
	 * @return {null}
	 */
	getCountryByISO(iso) {
		const result = this.countries.filter((country) => {
			return country.iso === iso;
		});

		return result.length > 0 ? result[0] : null;
	}

	/**
	 *
	 * @param vatCode
	 * @return {null}
	 */
	getCountryByVatCode(vatCode) {
		const result = this.countries.filter((country) => {
			return country.vatCode === vatCode;
		});

		return result.length > 0 ? result[0] : null;
	}

	/**
	 *
	 * @param lang
	 * @return {Array}
	 */
	getCountriesSortedByName(lang = 'nl') {
		return this.countries.sort((a, b) => {
			const nameA = a.getName(lang);
			const nameB = b.getName(lang);
			return compareStrings(nameA, nameB);
		});
	}
}
