import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import ResultGraphPanel from './ResultsGraphPanel';

@observer
/**
 *
 */
export default class ResultsGraph extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = { expandedQuarter: null };
		this.onGraphPanelChange = this.onGraphPanelChange.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Calculate graph points
		const yearIncomeDataPoints = [];
		const yearExpensesDataPoints = [];

		let highestAmount = 0;
		this.props.yearResultStore.months.forEach((month, index) => {
			let revenue;
			let expenses = 0;

			// Ignore last month of the year
			if (month.month !== 0) {
				revenue = this.props.yearResultStore.getTotalRevenue(index, index);
				expenses = this.props.yearResultStore.getTotalExpenses(index, index);
			}

			yearIncomeDataPoints.push(revenue);
			yearExpensesDataPoints.push(expenses);

			if (revenue > highestAmount) {
				highestAmount = revenue;
			}

			if (expenses > highestAmount) {
				highestAmount = expenses;
			}
		});

		const step = 2500;
		const steps = Math.ceil(highestAmount / step);
		highestAmount = steps * step;

		this.props.yearResultStore.months.forEach((month, index) => {
			yearIncomeDataPoints[index] = 1 - yearIncomeDataPoints[index] / highestAmount;
			yearExpensesDataPoints[index] = 1 - yearExpensesDataPoints[index] / highestAmount;

			if (isNaN(yearIncomeDataPoints[index])) {
				yearIncomeDataPoints[index] = 1;
			}

			if (isNaN(yearExpensesDataPoints[index])) {
				yearExpensesDataPoints[index] = 1;
			}
		});

		return (
			<div className="results-graph panel grid">
				<ResultGraphPanel
					className="col--3"
					steps={steps}
					expanded={this.state.expandedQuarter === 1}
					quarter={1}
					incomeData={yearIncomeDataPoints.slice(0, 4)}
					expensesData={yearExpensesDataPoints.slice(0, 4)}
					onChange={this.onGraphPanelChange}
					yearResultStore={this.props.yearResultStore}
				/>

				<ResultGraphPanel
					className="col--3"
					steps={steps}
					expanded={this.state.expandedQuarter === 2}
					quarter={2}
					incomeData={yearIncomeDataPoints.slice(3, 7)}
					expensesData={yearExpensesDataPoints.slice(3, 7)}
					onChange={this.onGraphPanelChange}
					yearResultStore={this.props.yearResultStore}
				/>

				<ResultGraphPanel
					className="col--3 walkthrough-step-results"
					steps={steps}
					expanded={this.state.expandedQuarter === 3}
					quarter={3}
					incomeData={yearIncomeDataPoints.slice(6, 10)}
					expensesData={yearExpensesDataPoints.slice(6, 10)}
					onChange={this.onGraphPanelChange}
					yearResultStore={this.props.yearResultStore}
				/>

				<ResultGraphPanel
					className="col--3"
					steps={steps}
					expanded={this.state.expandedQuarter === 4}
					quarter={4}
					incomeData={yearIncomeDataPoints.slice(9, 13)}
					expensesData={yearExpensesDataPoints.slice(9, 13)}
					onChange={this.onGraphPanelChange}
					yearResultStore={this.props.yearResultStore}
				/>
			</div>
		);
	}

	/**
	 *
	 * @param panel
	 * @param isExpanded
	 */
	onGraphPanelChange(panel, isExpanded) {
		this.setState({ expandedQuarter: isExpanded ? panel.props.quarter : null });
	}
}

ResultsGraph.propTypes = {
	yearResultStore: PropTypes.object.isRequired
};
