import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (uuid, data) => {
	return fetch(
		Methods.POST,
		EndPoints.PUBLIC_ASSET_UUID.replace(':uuid', uuid),
		data,
		null,
		false,
		true,
		false,
		false,
		true
	);
};
