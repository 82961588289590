/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import BTWPeriod from '../../../data/BTWPeriod';

import Signals from '../../../signals/Signals';

import ModalConfirm from '../Modal/ModalConfirm';
import FormGroup from '../FormGroup/FormGroup';
import FormField from '../FormField/FormField';
import ModalAlert from '../Modal/ModalAlert';

import financialYearDeadlineSubmit from '../../../requests/financialYearDeadlineSubmit';
import { ApplicationContext } from '../../../ApplicationContext';
import { Routes } from '../../../data/Routes';
import PropertiesController from '../../../controllers/PropertiesController';

/**
 *
 */
class Deadline extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		const refDeadlineIcon = React.createRef();

		this.state = {
			submitting: false,
			refDeadlineIcon
		};

		this.submit = this.submit.bind(this);
		this.confirmSubmit = this.confirmSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onHoverDeadline = this.onHoverDeadline.bind(this);
		this.onMouseLeaveDeadline = this.onMouseLeaveDeadline.bind(this);
	}

	/**
	 *
	 * */
	render() {
		const deadline = this.props.deadline;
		const termTitle = BTWPeriod.getTermTypeLabel(
			deadline.termType,
			this.props.financialyear.year,
			this.props.intl
		);
		const canSubmit = deadline.canSubmit();
		const isSubmitted = deadline.isSubmitted();

		const classes = classNames({
			'deadline--can-submit': !isSubmitted && canSubmit,
			'deadline--cannot-submit': !isSubmitted && !canSubmit,
			'deadline--submitted': isSubmitted
		});

		return (
			<FormGroup className={`deadline col--12 padding border--top border--dark ${classes}`}>
				<FormField className="col--12 grid grid--spread grid--vcenter">
					<div className="col--5 deadline__details" data-term={deadline.termType}>
						<h3 className="font--body">{termTitle}</h3>
						<div
							className="font--meta deadlines__deadline-date"
							onMouseOver={this.onHoverDeadline}
							onMouseLeave={this.onMouseLeaveDeadline}>
							<div>
								Deadline{' '}
								<FormattedDate
									value={deadline.deadline}
									day="numeric"
									month="long"
									year="numeric"
								/>
							</div>
							<span
								className="icon icon--info-smoke icon--right"
								ref={this.state.refDeadlineIcon}
							/>
						</div>
						<div className="font--meta deadlines__deadline-date" />
					</div>

					<div className="col--7 font--meta deadline__submitted grid grid--spread">
						<div>
							<FormattedMessage id="deadline.submitted.on" />
							&nbsp;
							<FormattedDate value={deadline.date} day="numeric" month="long" year="numeric" />
							&nbsp;
							<FormattedMessage id="deadline.submitted.at" />
							&nbsp;
							<FormattedTime value={deadline.date} />
						</div>
						<div className="deadline__check icon icon--check-black" />
					</div>

					<div className="col--7 font--meta deadline__can-submit grid grid--right">
						<button
							type="button"
							className="deadline__submit button--primary"
							onClick={this.submit}>
							<FormattedMessage id="deadline.submit" />
						</button>
					</div>

					<div className="col--7 font--meta deadline__cannot-submit grid grid--right">
						<button type="button" className="deadline__submit button--primary" disabled>
							<FormattedMessage id="deadline.submit" />
						</button>
					</div>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		this.props.deadline.update(response.data);
		this.setState({ submitting: false });

		// Thanks alert message
		const values = { name: this.context.applicationStore.user.bookKeeper.firstName };
		const title = this.props.intl.formatMessage({ id: 'deadlines.submitted.alert.title' });
		const message = this.props.intl.formatMessage(
			{ id: 'deadlines.submitted.alert.message' },
			values
		);
		Signals.ShowModal.dispatch(<ModalAlert title={title} body={message} />);

		// Update todos
		this.context.applicationStore.todoStore.fetch();
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		Signals.Error.dispatch(error);
		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	submit() {
		// Ignore if already submitting
		if (this.state.submitting) {
			return;
		}

		// Show message in demo, can't submit deadlines
		if (PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)) {
			const title = this.props.intl.formatMessage({ id: 'deadline.submit.demo.title' });
			const body = this.props.intl.formatMessage({ id: 'deadline.submit.demo.body' });
			Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
			return;
		}

		// Validate if company has VAT number (OB-nummer, not ID)
		const company = this.context.applicationStore.getSelectedCompany();
		if (
			(company.vatTerm !== BTWPeriod.VRIJGESTELD || BTWPeriod.NONE) &&
			(!company.vatNumber || company.vatNumber.trim().length <= 2)
		) {
			const link = Routes.COMPANY_SETTINGS_INFO.getPath({ id: company.id });
			const title = this.props.intl.formatMessage({ id: 'invoice.novat.alert.title' });
			const body = this.props.intl.formatMessage(
				{ id: 'invoice.novat.alert.bodywithsettingslink' },
				{ link }
			);
			Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
			return;
		}

		// Show confirm modal
		const title = this.props.intl.formatMessage({ id: 'deadline.submit.confirm.title' });
		const yesLabel = this.props.intl.formatMessage({ id: 'deadline.submit.confirm.yes' });
		const noLabel = this.props.intl.formatMessage({ id: 'deadline.submit.confirm.no' });
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={this.confirmSubmit}
				yesLabel={yesLabel}
				noLabel={noLabel}
			/>
		);
	}

	/**
	 *
	 */
	confirmSubmit() {
		this.state.submitting = true;
		financialYearDeadlineSubmit(this.props.financialyear.id, this.props.deadline.termType)
			.then(this.onSubmitSuccess)
			.catch(this.onSubmitError);
	}

	onHoverDeadline(e) {
		const deadline = this.props.deadline;
		e.preventDefault();
		Signals.ShowToolTip.dispatch(
			<div className="deadline__cannot-submit-tooltip">
				{this.props.intl.formatMessage(
					{
						id: 'deadlines.submit-from'
					},
					{
						date: this.props.intl.formatDate(deadline.submitStart, {
							day: 'numeric',
							month: 'long',
							year: 'numeric'
						})
					}
				)}
			</div>,
			this.state.refDeadlineIcon.current,
			-80,
			-5,
			'down'
		);
	}

	onMouseLeaveDeadline() {
		Signals.HideToolTip.dispatch();
	}
}

Deadline.contextType = ApplicationContext;

Deadline.propTypes = {
	className: PropTypes.string,
	deadline: PropTypes.object,
	financialyear: PropTypes.object,
	index: PropTypes.number,
	intl: PropTypes.object
};

export default injectIntl(Deadline);
