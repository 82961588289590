import React from 'react';
import PropTypes from 'prop-types';

export default class PeriodicInvoiceHistoryRow extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="periodic-invoice-history-row grid border--top">
				<h3 className="periodic-invoice-history-row__description font--body">
					{this.props.description}
				</h3>
				<span className="periodic-invoice-history-row__date font--meta">{this.props.date}</span>
			</div>
		);
	}
}

PeriodicInvoiceHistoryRow.propTypes = {
	description: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired
};
