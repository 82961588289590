/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { toCSV, toXLSX } from '../../../utils/exportToFile';

import Signals from '../../../signals/Signals';
import CompanyCustomer from '../../../stores/models/CompanyCustomer';

import Page from '../../Page';
import SearchField from '../../../components/ui/SearchField/SearchField';
import ClientsRow from './components/ClientsRow';
import ClientInput from './components/modals/ClientInput';
import AddButton from '../../../components/ui/AddButton/AddButton';
import { ApplicationContext } from '../../../ApplicationContext';
import FileExports from '../../../components/ui/ToolTip/tooltips/FileExports';
import { EmptyRow } from '../../../components/ui/EmptyRow/EmptyRow';

@observer
/**
 *
 */
class Clients extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.addNew = this.addNew.bind(this);
		this.showExportTooltip = this.showExportTooltip.bind(this);
		this.onSortClick = this.onSortClick.bind(this);
		this.onExportClick = this.onExportClick.bind(this);

		this.fieldHeaderSettings = [
			{
				id: 'contactPersonName',
				label: this.props.intl.formatMessage({ id: 'clients.header.contactpersonname' }),
				isDefault: true,
				minimal: true
			},
			{
				id: 'contactPersonEmail',
				label: this.props.intl.formatMessage({ id: 'clients.header.contactpersonemail' }),
				minimal: true
			},
			{
				id: 'companyName',
				label: this.props.intl.formatMessage({ id: 'clients.header.companyname' }),
				minimal: false
			},
			{
				id: 'address',
				label: this.props.intl.formatMessage({ id: 'clients.header.address' }),
				minimal: true
			},
			{ id: 'info', label: this.props.intl.formatMessage({ id: 'clients.header.info' }) },
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'clients.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const companyCustomerStore = this.context.applicationStore.companyCustomerStore;

		// Create table headers
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`clients__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted': companyCustomerStore.sortBy === fieldData.id,
						'table__header--sorted-default table__header--sorted':
							!companyCustomerStore.sortBy && fieldData.isDefault,
						'table__header--sorted-up':
							companyCustomerStore.sortBy === fieldData.id &&
							companyCustomerStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});

		// Create invoices rows
		const rows = companyCustomerStore.filteredAndSortedCompanyCustomers.map((companyCustomer) => {
			return (
				<ClientsRow
					key={`i-${companyCustomer.id}-${companyCustomer.companyName}`}
					companyCustomerModel={companyCustomer}
				/>
			);
		});

		// Render DOM
		return (
			<Page pageName="clients">
				<div className="clients__wrapper border border--dark">
					<div className="clients__options grid grid--spread">
						<SearchField
							name="search"
							// eslint-disable-next-line react/jsx-no-bind
							onChange={this.onSearch.bind(this)}
							placeholder={this.props.intl.formatMessage({ id: 'clients.search' })}
							value={companyCustomerStore.filter ? companyCustomerStore.filter : undefined}
						/>
						<div className="grid clients__options-buttons">
							<button
								type="button"
								className="icon icon--left icon--download2-black button--tertiary income__export"
								onClick={this.showExportTooltip}>
								<FormattedMessage id="label.export" />
							</button>
							<button
								type="button"
								className="clients__add-new icon icon--left icon--big-plus icon--color"
								onClick={this.addNew}>
								<FormattedMessage id="clients.add.client" />
							</button>
						</div>
					</div>

					<table className="table clients__rows">
						<thead className="table__headers">
							<tr>{headers}</tr>
						</thead>
						<tbody className="table__body">
							{rows.length === 0 ? <EmptyRow /> : null}
							{rows}
						</tbody>
					</table>

					<AddButton
						onClick={this.addNew}
						title={this.props.intl.formatMessage({ id: 'clients.add.client' })}
					/>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.context.applicationStore.companyCustomerStore.filter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.context.applicationStore.companyCustomerStore.sortBy === field) {
			this.context.applicationStore.companyCustomerStore.sortDirection =
				-this.context.applicationStore.companyCustomerStore.sortDirection;
		} else {
			this.context.applicationStore.companyCustomerStore.sortBy = field;
			this.context.applicationStore.companyCustomerStore.sortDirection = 1;
		}
	}

	/**
	 *
	 * @param type
	 */
	onExportClick(type = 'csv') {
		// Create headers
		let rows = [
			[
				this._getHeaderLabel('contactPersonName'), // A
				this._getHeaderLabel('contactPersonEmail'), // B
				this._getHeaderLabel('companyName'), // C
				this._getHeaderLabel('address'), // D
				this._getHeaderLabel('info') // E
			]
		];

		// Generate data
		const companyCustomerStore = this.context.applicationStore.companyCustomerStore;
		companyCustomerStore.filteredAndSortedCompanyCustomers.forEach((companyCustomer) => {
			rows = rows.concat(companyCustomer.toArray(this.props.intl));
		});

		// Formats
		const formats = [];

		switch (type) {
			case 'csv':
				toCSV(rows, this.props.intl.formatMessage({ id: 'submenu.invoices.clients' }), formats);
				break;
			case 'xlsx':
				toXLSX(rows, this.props.intl.formatMessage({ id: 'submenu.invoices.clients' }), formats);
				break;
			default:
				toCSV(rows, this.props.intl.formatMessage({ id: 'submenu.invoices.clients' }), formats);
		}
	}

	/**
	 *
	 */
	addNew() {
		Signals.ShowOverlay.dispatch(<ClientInput companyCustomer={new CompanyCustomer()} />);
	}

	/**
	 *
	 * @param e
	 */
	showExportTooltip(e) {
		e.preventDefault();
		Signals.ShowToolTip.dispatch(<FileExports onSelect={this.onExportClick} />, e.target, 16);
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}
}

Clients.contextType = ApplicationContext;

Clients.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Clients);
