import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';

import Signals from '../../signals/Signals';

import {dataURItoBlob} from '../../utils/objectToFormData';

import CompanyAssetType from '../../data/CompanyAssetType';
import OfferStatus from '../../data/OfferStatus';

import publicAssetCreate from '../../requests/assets/publicAssetCreate';
import offerSign from '../../requests/offers/offerSign';

/**
 *
 */
export default class SignOfferCommand extends AbstractCommand {

	/**
	 *
	 * @param offerModel OfferModel
	 * @param signOfferModel SignOfferModel
	 */
	constructor(offerModel, signOfferModel) {
		super();

		this.offerModel = offerModel;
		this.signOfferModel = signOfferModel;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		const assetData = this.signOfferModel.signaturePadReference.toDataURL();
		const assetBlob = dataURItoBlob(assetData);

		const formData = new FormData();
		formData.append('filename', 'signature.png');
		formData.append('type', CompanyAssetType.OFFER_SIGNATURE);
		formData.append('file', assetBlob, 'signature.png');

		publicAssetCreate(this.offerModel.publicAccessId, formData).then((response) => {

			// Set signatureAssetId on signOfferModel
			this.signOfferModel.signatureAssetId = response.data.uuid;

			offerSign(this.offerModel.publicAccessId, this.signOfferModel.toJSON())
				.then((response) => {
					this.offerModel.status = OfferStatus.SIGNED;
					this.offerModel.signOfferData = this.signOfferModel;
					this._showSuccessMessage();

					if(success) {
						success(response);
					}
				}).catch((err) => {
					Signals.Error.dispatch(err);

					if(error) {
						error(err);
					}
				})
		}).catch((err) => {
			Signals.Error.dispatch(err);

			if(error) {
				error(err);
			}
		});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="offer.signed.message"
								   values={{offerNr: this.offerModel.offerNr}}/>)
		);
	}
}
