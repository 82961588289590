import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import { FormattedMessage } from 'react-intl';
import { useScroll } from 'react-use';
import classNames from 'classnames';
import { uniqueKey } from '../../../utils/ReactUtils';

function DocumentIFrame({ text, checks, onCheck }) {
	const scrollRef = useRef(null);
	const { y } = useScroll(scrollRef);
	const termsContainer = document.getElementById('terms-content');
	const [isVisible, setIsVisible] = useState(true);

	const [options, setOptions] = useState(
		checks
			? checks.map((check) => {
					return { ...check, accepted: false, id: uniqueKey() };
			  })
			: []
	);
	let interval = null;

	// Check if user at the bottom of the the component
	const isAtTheBottom = () => {
		if (termsContainer) {
			return (
				termsContainer.scrollHeight - termsContainer.clientHeight - termsContainer.scrollTop < 10
			);
		}
		return false;
	};

	// Scroll to the bottom of the div with transition
	const scrollToBottom = () => {
		if (termsContainer) {
			// Update with transition
			interval = setInterval(() => {
				if (isAtTheBottom()) {
					clearInterval(interval);
				} else {
					termsContainer.scrollTop += 100;
				}
			}, 1);
		}
	};

	// Toggle of scroll to bottom on bottom of page
	useEffect(() => {
		if (termsContainer) {
			clearInterval(interval);
			if (isAtTheBottom()) {
				setIsVisible(false);
			} else {
				setIsVisible(true);
			}
		}
	}, [y]);

	// Clear interval on unmount
	useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	/**
	 * Update the options state
	 * @param {string} id
	 * @param {Event} event
	 */
	const handleChange = (id, event) => {
		setOptions(
			options.map((option) => {
				if (option.id === id) {
					return { ...option, accepted: event.target.checked };
				}
				return option;
			})
		);
	};

	useEffect(() => {
		if (onCheck) {
			if (options && options.every((option) => option.accepted)) {
				onCheck(true);
			} else {
				onCheck(false);
			}
		}
	}, [options]);

	return (
		<div className="document-iframe-container">
			<div className="document-iframe-container__content" id="terms-content">
				<div
					className="document-iframe-text"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: marked.parse(text, {
							sanitize: true,
							breaks: true
						})
					}}
				/>
				<div className="document-iframe-option-container">
					{options &&
						options.map((option) => {
							return (
								<div className="document-iframe-option" key={option.id}>
									<label
										className="mt-auto cursor-pointer select-none inline-block whitespace-nowrap"
										htmlFor={option.id}>
										<input
											className="align-middle !mr-2 appearance-none h-4 w-4 border border-zinc-200 rounded-sm bg-zinc-100 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer checked:bg-secondary-darker"
											type="checkbox"
											name="termsOfService"
											id={option.id}
											checked={option.accepted}
											onChange={(event) => handleChange(option.id, event)}
										/>
										<span>{option.title}</span>
									</label>
								</div>
							);
						})}
				</div>
			</div>
			<div className={classNames('scroll-button', { visible: isVisible })} onClick={scrollToBottom}>
				<FormattedMessage id="scrool.to.bottom" />
			</div>
		</div>
	);
}

DocumentIFrame.propTypes = {
	text: PropTypes.string.isRequired,
	checks: PropTypes.array,
	onCheck: PropTypes.func
};

export default DocumentIFrame;
