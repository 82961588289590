import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import invoiceConceptTogglePaid from '../../requests/invoiceConceptTogglePaid';

/**
 *
 */
export default class ToggleInvoiceConceptPaidStatusCommand extends AbstractCommand {

	/**
	 * @param invoiceConceptModel InvoiceConceptModel
	 * @param company Company
	 * @param intl object
	 */
	constructor(invoiceConceptModel, company, intl) {
		super();

		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
		this.intl = intl;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		if(!this.invoiceConceptModel.updatingStatus) {
			this.invoiceConceptModel.updatingStatus = true;
			invoiceConceptTogglePaid(this.company.id, this.invoiceConceptModel.id)
				.then((response) => {
				this._updateModel(response);

				if(success) {
					success(response);
				}
			}).catch((err) => {
				this._handleError(err);

				if(error) {
					error(err);
				}
			});
		}
	}

	/**
	 *
	 * @param response
	 */
	_updateModel(response) {
		this.invoiceConceptModel.updatingStatus = false;

		this.invoiceConceptModel.update(response.data);

		const statusLabelId = (`tag-label.${this.invoiceConceptModel.status}`).toLowerCase();
		const statusLabel = this.intl.formatMessage({id:statusLabelId});
		Signals.ShowMessageDialog.dispatch((<FormattedHTMLMessage id="invoice.updated.message"
																  values={{invoiceNr: this.invoiceConceptModel.invoiceNr, status:statusLabel}}/>));
	}

	/**
	 * @param error
	 */
	_handleError(error) {
		this.invoiceConceptModel.updatingStatus = false;
		Signals.Error.dispatch(error);
	}
}
