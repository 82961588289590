/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import { toCSV, toXLSX } from '../../../utils/exportToFile';
import PropertiesController from '../../../controllers/PropertiesController';

import IncomeModel from '../../../stores/models/IncomeModel';

import FileExports from '../../../components/ui/ToolTip/tooltips/FileExports';

import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import TableWrapper from '../../../components/ui/TableWrapper/TableWrapper';

import Page from '../../Page';
import IncomeRow from './components/IncomeRow';
import SearchField from '../../../components/ui/SearchField/SearchField';
import AddButton from '../../../components/ui/AddButton/AddButton';
import { ApplicationContext } from '../../../ApplicationContext';
import DraftTypes from '../../../data/DraftTypes';
import IncomeDraftRow from './components/IncomeDraftRow';
import FileUploadArea from '../../../components/ui/FileUploadArea/FileUploadArea';
import IncomeInputPanel from './components/modals/IncomeInputPanel';
import { uniqueKey } from '../../../utils/ReactUtils';
import { EmptyRow } from '../../../components/ui/EmptyRow/EmptyRow';
import MoveDraftsToYearCommand from '../../../commands/drafts/MoveDraftsToYearCommand';
import ToggleButton from '../../../components/ui/ToggleButton/ToggleButton';
import ToggleButtonOption from '../../../components/ui/ToggleButton/ToggleButtonOption';

@observer
class Income extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.addNew = this.addNew.bind(this);
		this.showExportTooltip = this.showExportTooltip.bind(this);
		this.onSortClick = this.onSortClick.bind(this);
		this.onExportClick = this.onExportClick.bind(this);
		this.onDeadlinesFetched = this.onDeadlinesFetched.bind(this);
		this.onFiles = this.onFiles.bind(this);
		this.update = this.update.bind(this);

		this.onDraftSelected = this.onDraftSelected.bind(this);
		this.onDraftDeselected = this.onDraftDeselected.bind(this);
		this.onMoveToBookyear = this.onMoveToBookyear.bind(this);

		this.fieldHeaderSettings = [
			{
				id: 'invoiceNr',
				label: this.props.intl.formatMessage({ id: 'income.header.invoicenr' }),
				minimal: true
			},
			{
				id: 'date',
				label: this.props.intl.formatMessage({ id: 'income.header.date' }),
				isDefault: true,
				minimal: true
			},
			{ id: 'customerName', label: this.props.intl.formatMessage({ id: 'income.header.client' }) },
			{ id: 'description', label: this.props.intl.formatMessage({ id: 'income.header.subject' }) },
			{
				id: 'btw',
				label: this.props.intl.formatMessage({ id: 'income.header.btw' }),
				center: true,
				minimal: true
			},
			{
				id: 'exclBTW',
				label: this.props.intl.formatMessage({ id: 'income.header.excl.btw' }),
				right: true,
				minimal: true
			},
			{
				id: 'inclBTW',
				label: this.props.intl.formatMessage({ id: 'income.header.incl.btw' }),
				right: true,
				minimal: true
			},
			{
				id: 'fileName',
				label: this.props.intl.formatMessage({ id: 'income.header.file' }),
				center: true,
				minimal: true
			},
			{
				id: 'state',
				label: this.props.intl.formatMessage({ id: 'income.header.state' }),
				center: true,
				minimal: true
			},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'income.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];

		this.state = { selectedDraftIds: [] };
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.CompanyChanged.add(this.update);
		Signals.FinancialYearChanged.add(this.update);
		Signals.DeadlinesFetched.add(this.onDeadlinesFetched);

		this.update();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.update);
		Signals.FinancialYearChanged.remove(this.update);
		Signals.DeadlinesFetched.remove(this.onDeadlinesFetched);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const applicationStore = this.context.applicationStore;
		const company = applicationStore.getSelectedCompany();
		const financialYear = applicationStore.getSelectedFinancialYear();

		if (!financialYear || !company) {
			return null;
		}

		const hasLockedDeadlines = applicationStore.yearResultStore.hasLockedDeadlines();
		const financialYearLocked = hasLockedDeadlines || !financialYear.isActive();

		const incomeStore = applicationStore.incomeStore;
		const draftsStore = company.draftsStore;
		const { selectedDraftIds } = this.state;

		// Create table headers
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`income__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!incomeStore.sortBy && fieldData.isDefault,
						'table__header--sorted': incomeStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							incomeStore.sortBy === fieldData.id && incomeStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});

		// Create Draft rows
		const incomeDrafts = draftsStore.getDraftsOfType(DraftTypes.INCOME, financialYear.id);
		const draftRows = incomeDrafts.map((draft) => {
			return (
				<IncomeDraftRow
					key={`inc-d-${draft.id}`}
					draft={draft}
					draftsStore={draftsStore}
					onSelect={this.onDraftSelected}
					onDeselect={this.onDraftDeselected}
				/>
			);
		});

		// Create Income rows
		const rows = incomeStore.filteredAndSortedIncome.map((invoice) => {
			return (
				<IncomeRow
					key={`inc-${invoice.id}-${invoice.invoiceNr}`}
					incomeModel={invoice}
					incomeStore={incomeStore}
				/>
			);
		});

		// Check if year is active
		const disabledProp = {};

		if (!financialYear.isActive() || hasLockedDeadlines) {
			disabledProp.disabled = true;
		}

		const pageClasses = classNames({
			income: true,
			'income__year-inactive': !financialYear.isActive() || hasLockedDeadlines
		});

		const incomeWrapperClasses = classNames({
			'income__wrapper border border--dark': true
		});

		// Render page
		return (
			<Page pageName={pageClasses}>
				{!financialYearLocked ? (
					<FileUploadArea onChange={this.onFiles} />
				) : null}

				<div className={incomeWrapperClasses}>
					<div className="income__options box-shadow-small grid grid--spread">
						<div className="grid grid--no-wrap">
							<SearchField
								name="search"
								// eslint-disable-next-line react/jsx-no-bind
								onChange={this.onSearch.bind(this)}
								placeholder={this.props.intl.formatMessage({ id: 'income.search' })}
								value={incomeStore.filter ? incomeStore.filter : undefined}
							/>

							{selectedDraftIds.length > 0 ? (
								<ToggleButton
									className=""
									onChange={this.onMoveToBookyear}
									label={this.props.intl.formatMessage({ id: 'label.moveto' })}
									options={company.financialYears
										.filter((fy) => fy.id !== financialYear.id)
										.map((fy) => {
											return new ToggleButtonOption(fy.year, fy.id, false);
										})}
								/>
							) : null}
						</div>
						<div className="grid income__options-buttons">
							<button
								type="button"
								className="icon icon--left icon--download2-black button--tertiary button--no-shadow income__export"
								onClick={this.showExportTooltip}>
								<FormattedMessage id="label.export" />
							</button>
							<button
								type="button"
								className="income__add-new icon icon--left icon--big-plus icon--color"
								onClick={this.addNew}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...disabledProp}>
								<FormattedMessage id="income.add.income" />
							</button>
						</div>
					</div>

					<TableWrapper scrollableX>
						<table className="table income__rows">
							<thead className="table__headers walkthrough-step-income">
								<tr>{headers}</tr>
							</thead>
							<tbody className="table__body">
								{draftRows}
								{rows.length === 0 ? <EmptyRow /> : null}
								{rows}
							</tbody>
						</table>
					</TableWrapper>

					<div className="table__footer incomes__footer options-footer grid grid--spread border--top">
						<div>
							<div className="incomes__total_excld_vat">
								<FormattedMessage id="income.add.total.excluding.vat" />
							</div>
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={incomeStore.totalAmountExclBTW}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
						<div>
							<div className="incomes__total_incld_vat">
								<FormattedMessage id="income.add.total.including.vat" />
							</div>
							<FormattedNumber
								style="currency"
								currency="EUR"
								value={incomeStore.totalAmountInclBTW}
								minimumFractionDigits={2}
								maximumFractionDigits={2}
							/>
						</div>
					</div>

					<AddButton
						onClick={this.addNew}
						title={this.props.intl.formatMessage({ id: 'income.add.income' })}
						{...disabledProp}
					/>
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	onDeadlinesFetched() {
		const hasLockedDeadlines = this.context.applicationStore.yearResultStore.hasLockedDeadlines();
		if (hasLockedDeadlines) {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'alert.income.locked.deadline.title' })}
					body={this.props.intl.formatMessage({ id: 'alert.income.locked.deadline.description' })}
				/>
			);
		}
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.context.applicationStore.incomeStore.filter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.context.applicationStore.incomeStore.sortBy === field) {
			this.context.applicationStore.incomeStore.sortDirection =
				-this.context.applicationStore.incomeStore.sortDirection;
		} else {
			this.context.applicationStore.incomeStore.sortBy = field;
			this.context.applicationStore.incomeStore.sortDirection = 1;
		}
	}

	/**
	 *
	 * @param type
	 */
	onExportClick(type = 'csv') {
		// Financial Year
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		// Create headers
		let rows = [
			[
				this._getHeaderLabel('invoiceNr'), // A
				this._getHeaderLabel('date'), // B
				this._getHeaderLabel('customerName'), // C
				this._getHeaderLabel('description'), // D
				this._getHeaderLabel('projectcode'), // E
				this._getHeaderLabel('state'), // F
				this._getHeaderLabel('btw'), // G
				this._getHeaderLabel('inclBTW'), // H
				this._getHeaderLabel('exclBTW')
			] // I
		];

		// Generate data
		const incomeStore = this.context.applicationStore.incomeStore;
		incomeStore.filteredAndSortedIncome.forEach((incomeModel) => {
			rows = rows.concat(incomeModel.toArray(this.props.intl, financialYear.year));
		});

		// Format incl/excl btw column
		const formats = [
			{ column: 'H', format: '#,##0.00;(#,##0.00)' },
			{ column: 'I', format: '#,##0.00;(#,##0.00)' }
		];

		switch (type) {
			case 'csv':
				toCSV(rows, this.props.intl.formatMessage({ id: 'submenu.income' }), formats);
				break;
			case 'xlsx':
				toXLSX(rows, this.props.intl.formatMessage({ id: 'submenu.income' }), formats);
				break;
			default:
				toCSV(rows, this.props.intl.formatMessage({ id: 'submenu.income' }), formats);
		}
	}

	/**
	 *
	 * @param files
	 */
	onFiles(files) {
		const { applicationStore } = this.context;
		const { draftsFileUploadStore } = applicationStore;

		// Set types and targets
		draftsFileUploadStore.draftTarget = applicationStore.getSelectedCompany();
		draftsFileUploadStore.draftType = DraftTypes.INCOME;

		const financialYear = applicationStore.getSelectedFinancialYear();

		for (let i = 0; i < files.length; i++) {
			draftsFileUploadStore.add(files[i], financialYear.id);
		}
	}

	/**
	 *
	 * @param draft
	 */
	onDraftSelected(draft) {
		this.state.selectedDraftIds.push(draft.id);
		this.forceUpdate();
	}

	/**
	 *
	 * @param draft
	 */
	onDraftDeselected(draft) {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const selectedDraftIds = this.state.selectedDraftIds.filter((id) => {
			return draft.id !== id;
		});

		this.setState({ selectedDraftIds });
	}

	/**
	 *
	 */
	onMoveToBookyear(financialYearId) {
		const { applicationStore } = this.context;
		const { selectedDraftIds } = this.state;

		const currentFinancialYear = applicationStore.getSelectedFinancialYear();
		const company = applicationStore.getSelectedCompany();
		const toFinancialYear = company.getFinancialYearById(parseInt(financialYearId, 10));

		const command = new MoveDraftsToYearCommand(
			company.draftsStore,
			selectedDraftIds,
			currentFinancialYear.id,
			toFinancialYear.id
		);
		command.execute(() => {
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage
					id="draft.moved.message"
					values={{ count: selectedDraftIds.length, year: toFinancialYear.year }}
				/>
			);
			this.setState({ selectedDraftIds: [] });
		});
	}

	/**
	 *
	 * @param e
	 */
	showExportTooltip(e) {
		e.preventDefault();
		Signals.ShowToolTip.dispatch(<FileExports onSelect={this.onExportClick} />, e.target, 16);
	}

	/**
	 *
	 */
	update() {
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (financialYear) {
			this.setState({ selectedDraftIds: [] });
			this.context.applicationStore.yearResultStore.fetchDeadlines(financialYear.id);
			this.context.applicationStore.financialStatementStore.fetch(financialYear.id);
			this.context.applicationStore.incomeStore.fetch(financialYear.id);
		}

		this.forceUpdate();
	}

	/**
	 *
	 */
	addNew() {
		// Show IncomeInput overlay
		Signals.ShowOverlay.dispatch(
			<IncomeInputPanel
				key={uniqueKey('iip-')}
				incomeModel={IncomeModel.createNewIncome()}
				suggestInvoiceNr
			/>
		);
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}
}

Income.contextType = ApplicationContext;

Income.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Income);
