/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';

import SearchField from '../../../components/ui/SearchField/SearchField';
import Page from '../../Page';
import InvoicesRow from './components/InvoicesRow';
import AddButton from '../../../components/ui/AddButton/AddButton';

import { ApplicationContext } from '../../../ApplicationContext';
import GetInvoicesConceptCommand from '../../../commands/invoiceConcepts/GetInvoicesConceptCommand';
import InvoicesConceptStore from '../../../stores/InvoicesConceptStore';
import ToggleButton from '../../../components/ui/ToggleButton/ToggleButton';
import ToggleButtonOption from '../../../components/ui/ToggleButton/ToggleButtonOption';
import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import ArchiveInvoiceConceptsCommand from '../../../commands/invoiceConcepts/ArchiveInvoiceConceptsCommand';
import FilterButtonOption from '../../../components/ui/FilterButton/FilterButtonOption';
import InvoiceConceptStatus from '../../../data/InvoiceConceptStatus';
import FilterButton from '../../../components/ui/FilterButton/FilterButton';
import TableWrapper from '../../../components/ui/TableWrapper/TableWrapper';

/**
 *
 */
@observer
class Invoices extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.addNew = this.addNew.bind(this);
		this.onSortClick = this.onSortClick.bind(this);
		this.onCompanyChanged = this.onCompanyChanged.bind(this);
		this.onBatchArchiveAction = this.onBatchArchiveAction.bind(this);
		this.onBookyearFilterChange = this.onBookyearFilterChange.bind(this);
		this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
		const { intl } = this.props;

		//
		this.fieldHeaderSettings = [
			{
				id: 'status',
				label: this.props.intl.formatMessage({ id: 'invoices.header.status' }),
				isDefault: true,
				minimal: true
			},
			// {id: 'paid', label: this.props.intl.formatMessage({id:'invoices.header.paid'}), minimal:true},
			{
				id: 'date',
				label: this.props.intl.formatMessage({ id: 'invoices.header.date' }),
				minimal: true
			},
			// {id: 'expirationDate', label: this.props.intl.formatMessage({id:'invoices.header.expirationdate'}), right:true, minimal:true},
			{
				id: 'invoiceNr',
				label: this.props.intl.formatMessage({ id: 'invoices.header.invoicenr' }),
				minimal: true
			},
			{ id: 'companyName', label: this.props.intl.formatMessage({ id: 'invoices.header.client' }) },
			{ id: 'subject', label: this.props.intl.formatMessage({ id: 'invoices.header.subject' }) },
			{
				id: 'amount',
				label: this.props.intl.formatMessage({ id: 'invoices.header.amount' }),
				right: true,
				minimal: true
			},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'invoices.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];

		//
		this.state = {
			bookyearFilter: [],
			statusFilter: [
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.created' }),
					InvoiceConceptStatus.CREATED,
					true
				),
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.late' }),
					InvoiceConceptStatus.LATE,
					true
				),
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.paid' }),
					InvoiceConceptStatus.PAID,
					true
				),
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.sent_by_email_kdb' }),
					InvoiceConceptStatus.SENT_BY_EMAIL_KDB,
					true
				),
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.sent_manually_explicit' }),
					InvoiceConceptStatus.SENT_MANUALLY,
					true
				),
				new FilterButtonOption(
					intl.formatMessage({ id: 'tag-label.sent_reminder_kdb' }),
					InvoiceConceptStatus.SENT_REMINDER_KDB,
					true
				)
			]
		};
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.CompanyChanged.add(this.onCompanyChanged);
		this.onCompanyChanged();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.onCompanyChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { intl } = this.props;
		const company = this.context.applicationStore.getSelectedCompany();
		if (!company) {
			return null;
		}

		const params = { id: company ? company.id : null };
		const { statusFilter, bookyearFilter } = this.state;

		// Create table headers
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`invoices__header--${`${fieldData.id}`.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!this.props.invoicesConceptStore.sortBy && fieldData.isDefault,
						'table__header--sorted': this.props.invoicesConceptStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							this.props.invoicesConceptStore.sortBy === fieldData.id &&
							this.props.invoicesConceptStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});

		// Create invoices rows
		let totalAmount = 0;
		let totalAmountExclVat = 0;
		const rows = this.props.invoicesConceptStore.filteredAndSortedInvoiceConcepts.map(
			(invoiceConcept) => {
				// Ignore if not an 'open' invoice
				if (this.props.showOpenOnly && !invoiceConcept.isOpen()) {
					return null;
				}

				// Add to total and create DOM
				totalAmount += invoiceConcept.getTotal();
				totalAmountExclVat += invoiceConcept.getSubtotal();
				return (
					<InvoicesRow
						key={`i-${invoiceConcept.id}-${invoiceConcept.invoiceNr}`}
						invoiceConceptModel={invoiceConcept}
					/>
				);
			}
		);

		return (
			<Page pageName="invoices">
				<div className="invoices__wrapper border border--dark">
					<div className="invoices__options box-shadow-small grid grid--spread">
						<div className="grid invoices__options__left">
							<SearchField
								name="search"
								className="btn-margin-right"
								onChange={this.onSearch.bind(this)}
								placeholder={this.props.intl.formatMessage({ id: 'invoices.search' })}
								value={
									this.props.invoicesConceptStore.searchFilter
										? this.props.invoicesConceptStore.searchFilter
										: undefined
								}
							/>

							<FilterButton
								name="bookyearFilter"
								className="filter-button--primary"
								label={this.props.intl.formatMessage({ id: 'invoices.filter.bookyear' })}
								options={bookyearFilter}
								onChange={this.onBookyearFilterChange}
								showToggle
							/>

							<FilterButton
								name="statusFilter"
								className="filter-button--primary btn-margin-left"
								label={intl.formatMessage({ id: 'invoices.filter.status' })}
								options={statusFilter}
								onChange={this.onStatusFilterChange}
								showToggle
							/>
						</div>
						<div className="grid invoices__options-buttons">
							<button
								type="button"
								className="invoices__add-new icon icon--left icon--big-plus icon--color"
								onClick={this.addNew}>
								<FormattedMessage id="invoices.add.invoice" />
							</button>
						</div>
					</div>

					<TableWrapper scrollableX>
						<table className="table">
							<thead className="table__headers walkthrough-step-invoices-all">
								<tr>{headers}</tr>
							</thead>
							<tbody className="table__body">{rows}</tbody>
						</table>
					</TableWrapper>

					<div className="invoices__footer-extra-options grid grid--center border--top">
						<Link to={Routes.COMPANY_INVOICES_ARCHIVE.getPath(params)}>
							<FormattedMessage id="invoices.showarchives" />
						</Link>
					</div>

					<div className="table__footer invoices__footer options-footer grid grid--spread border--top">
						<div>
							{this.props.invoicesConceptStore.getUnarchivedYears().length > 0 ? (
								<ToggleButton
									className="btn-margin-right"
									onChange={this.onBatchArchiveAction}
									label={this.props.intl.formatMessage({ id: 'label.archive' })}
									options={this.props.invoicesConceptStore.getUnarchivedYears().map((year) => {
										return new ToggleButtonOption(
											`${year} ${this.props.intl.formatMessage({ id: 'label.archive' })}`,
											year,
											'icon icon--left icon--folder2-black',
											false
										);
									})}
								/>
							) : null}
						</div>
						<div className="invoices__total_amounts">
							<div>
								<div className="invoices__total">
									<FormattedMessage id="invoices.add.excl.vat.total" />
								</div>
								<FormattedNumber
									style="currency"
									currency="EUR"
									value={totalAmountExclVat}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							</div>
							<div>
								<div className="invoices__total">
									<FormattedMessage id="invoices.add.total" />
								</div>
								<FormattedNumber
									style="currency"
									currency="EUR"
									value={totalAmount}
									minimumFractionDigits={2}
									maximumFractionDigits={2}
								/>
							</div>
						</div>
					</div>

					<AddButton
						onClick={this.addNew}
						title={this.props.intl.formatMessage({ id: 'invoices.add.invoice' })}
					/>
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	onCompanyChanged() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new GetInvoicesConceptCommand(this.props.invoicesConceptStore, company.id);
			command.execute();

			this.setState({
				bookyearFilter: company.financialYears.map((financialYear) => {
					return new FilterButtonOption(financialYear.year, financialYear.year, true);
				})
			});
		}
	}

	/**
	 *
	 * @param year
	 */
	onBatchArchiveAction(year) {
		const { intl, invoicesConceptStore } = this.props;
		const title = intl.formatMessage(
			{ id: 'invoice.batch.archive.title' },
			{
				year
			}
		);

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={() => {
					const company = this.context.applicationStore.getSelectedCompany();
					const command = new ArchiveInvoiceConceptsCommand(
						company,
						parseInt(year, 10),
						invoicesConceptStore
					);
					command.execute();
				}}
				yesLabel={intl.formatMessage({ id: 'label.yes.archive' })}
				noLabel={intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 * @param name
	 * @param value
	 * @param checked
	 * @param ignoreUpdate
	 */
	onBookyearFilterChange(name, value, checked, ignoreUpdate) {
		const { invoicesConceptStore } = this.props;
		const { bookyearFilter } = this.state;
		let options;

		// Update filter button option state
		// eslint-disable-next-line default-case
		switch (name) {
			case 'bookyearFilter':
				options = bookyearFilter.filter((filterButtonOption) => {
					return filterButtonOption.value === value;
				});

				if (
					options.forEach((option) => {
						option.checked = checked;
					})
				)
					break;
		}

		// Apply filters
		invoicesConceptStore.bookyearFilter.value = bookyearFilter
			.map((filterOption) => {
				return filterOption.checked ? filterOption.value : false;
			})
			.filter((val) => {
				return val !== false;
			});

		if (!ignoreUpdate) {
			invoicesConceptStore.update();
		}
	}

	/**
	 *
	 * @param name
	 * @param value
	 * @param checked
	 * @param ignoreUpdate
	 */
	onStatusFilterChange(name, value, checked, ignoreUpdate) {
		const { invoicesConceptStore } = this.props;
		const { statusFilter } = this.state;
		let options;

		// Update filter button option state
		switch (name) {
			case 'statusFilter':
				options = statusFilter.filter((filterButtonOption) => {
					return filterButtonOption.value === value;
				});

				if (
					options.forEach((option) => {
						option.checked = checked;
					})
				)
					break;
				break;
			default:
				break;
		}

		// Apply filters
		invoicesConceptStore.statusFilterRule.value = statusFilter
			.map((filterOption) => {
				return filterOption.checked ? filterOption.value : false;
			})
			.filter((val) => {
				return val !== false;
			});

		if (!ignoreUpdate) {
			invoicesConceptStore.update();
		}
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		this.props.invoicesConceptStore.searchFilter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		if (this.props.invoicesConceptStore.sortBy === field) {
			this.props.invoicesConceptStore.sortDirection =
				-this.props.invoicesConceptStore.sortDirection;
		} else {
			this.props.invoicesConceptStore.sortBy = field;
			this.props.invoicesConceptStore.sortDirection = 1;
		}
	}

	/**
	 *
	 */
	addNew() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_EDIT.getPath({ id: company.id, invoiceId: 'new' })
			);
		}
	}

	/**
	 *
	 * @param field
	 * @return {*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getHeaderLabel(field) {
		const result = this.fieldHeaderSettings.find((fieldData) => {
			return fieldData.id === field;
		});

		return result ? result.label : field;
	}
}

Invoices.contextType = ApplicationContext;

Invoices.propTypes = {
	intl: PropTypes.object,
	showOpenOnly: PropTypes.bool,
	invoicesConceptStore: PropTypes.instanceOf(InvoicesConceptStore).isRequired
};

Invoices.defaultProps = {
	showOpenOnly: false
};

export default injectIntl(Invoices);
