import React from 'react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class Modal extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.el = null;
		this.state = {
			showModal: false,
			content: null
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.ShowModal.add(this.show);
		Signals.HideModal.add(this.hide);
	}

	/**
	 *
	 */
	componentDidMount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = true;
	}

	/**
	 *
	 */
	componentWillUnmount() {
		// eslint-disable-next-line react/no-unused-class-component-methods
		this._isMounted = false;

		Signals.ShowModal.remove(this.show);
		Signals.HideModal.remove(this.hide);
	}

	/**
	 *
	 */
	render() {
		const classes = classNames({
			'modal--show': this.state.showModal,
			'modal--hide': !this.state.showModal
		});

		return (
			<div
				ref={(el) => {
					this.el = el;
				}}
				className={`modal ${classes}`}>
				<div className="modal__background" />
				<div className="modal__scroll-panel">
					<div className="modal__content-wrapper box-shadow-heavy">{this.state.content}</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	show(content) {
		if (this.el && !this.state.showModal) {
			clearTimeout(this.hideTimeout);
			this.el.style.display = 'block';
			this.setState({ content, showModal: true });
		}
	}

	/**
	 *
	 */
	hide() {
		if (this.state.showModal) {
			clearTimeout(this.hideTimeout);
			this.setState({ showModal: false });

			this.hideTimeout = setTimeout(() => {
				this.el.style.display = 'none';
				this.setState({ content: null });
			}, 300);
		}
	}
}
