import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function TableWrapper(props) {
	const classes = classNames({
		'table-wrapper': true,
		'table-wrapper--scrollable-x': props.scrollableX,
		'table-wrapper--scrollable-y': props.scrollableY
	});

	return <div className={classes}>{props.children}</div>;
}

TableWrapper.propTypes = {
	scrollableX: PropTypes.bool,
	scrollableY: PropTypes.bool,
	children: PropTypes.any
};

export default TableWrapper;
