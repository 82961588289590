import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import DocumentTypes from '../../data/DocumentTypes';

import Signals from '../../signals/Signals';
import { getHashParameters } from '../../utils/urlUtils';

import Page from '../Page';
import { ApplicationContext } from '../../ApplicationContext';
import FetchDocumentsCommand from '../../commands/FetchDocumentsCommand';
import DocumentStore from '../../stores/DocumentStore';
import CollapsablePanel from '../../components/ui/CollapsablePanel/CollapsablePanel';
import DocumentRow from './components/DocumentRow';
import EndPoints from '../../data/EndPoints';
import {
	getPayrollDocumentRunPeriodDate,
	getPayrollDocumentRunRanAtDate,
	getPayrollDocumentsTitle
} from '../../utils/payrollUtils';

/**
 *
 */
@observer
class PayrollDocuments extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { documentStore: new DocumentStore() };

		this.onFetchError = this.onFetchError.bind(this);
		this.onFetchSuccess = this.onFetchSuccess.bind(this);
		this.onDocumentYearChanged = this.onDocumentYearChanged.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.DocumentYearChanged.add(this.onDocumentYearChanged);
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.state = { documentStore: new DocumentStore() };
		this._initialize();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.DocumentYearChanged.remove(this.onDocumentYearChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (
			this.context.applicationStore.isLoggedIn &&
			this.context.applicationStore.currentRouteParams
		) {
			const selectedYear = this.context.applicationStore.getSelectedDocumentYear();

			return <Page pageName="payroll-documents">{this._getPanels(selectedYear)}</Page>;
		}

		return null;
	}

	/**
	 *
	 * @param response
	 */
	onFetchSuccess(_response) {
		this.forceUpdate();
	}

	/**
	 *
	 * @param error
	 */
	onFetchError(error) {
		console.log('Documents.onFetchError', error);
	}

	/**
	 *
	 * @param year
	 */
	onDocumentYearChanged(_year) {
		this.forceUpdate();
	}

	/**
	 *
	 * @param year
	 * @return {*[]}
	 * @private
	 */
	_getPanels(year) {
		const payDeclarations = this.state.documentStore.getDocumentsByYearByType(
			year,
			DocumentTypes.LOONAANGIFTE
		);
		const payslips = this.state.documentStore.getDocumentsByYearByType(
			year,
			DocumentTypes.SALARISSTROOK
		);
		const annualStatements = this.state.documentStore.getDocumentsByYearByType(
			year,
			DocumentTypes.JAAROPGAVE
		);
		const journalEntries = this.state.documentStore.getDocumentsByYearByType(
			year,
			DocumentTypes.CUMULATIEVE_JOURNAALPOSTEN
		);
		const documents = payDeclarations
			.concat(payslips)
			.concat(annualStatements)
			.concat(journalEntries);
		const hashParams = getHashParameters(window.location.hash);

		// Split documents in months
		const months = [];
		documents.forEach((document) => {
			try {
				// Extract document month
				const documentPeriodDate = getPayrollDocumentRunPeriodDate(document);
				let month = 0;
				if (documentPeriodDate) {
					month = documentPeriodDate.getMonth();
				} else {
					const documentRunRanAtDate = getPayrollDocumentRunRanAtDate(document);
					month = documentRunRanAtDate.getMonth();
				}

				// Create month if it doesn't exist
				if (!months[month]) {
					months[month] = [];
				}

				// Add month to moths array
				months[month].push(document);
			} catch (e) {
				// Ignore
			}
		});

		return months.map((documents, index) => {
			if (!documents || documents.length === 0) {
				return null;
			}

			const title = getPayrollDocumentsTitle(documents, this.props.intl);
			const panelTitle = `${title} (${documents.length})`;
			const downloadAllfileName = `${title}.zip`;
			const isCollapsed = hashParams.period !== documents[0].metadata.runPeriod;

			return (
				<CollapsablePanel
					key={`${year}-${index}`}
					iconName="calendar"
					title={panelTitle}
					className="collapsable-panel--completed"
					collapsed={isCollapsed}
					canCollapse={documents.length > 0}>
					{documents.map((document) => {
						return (
							<DocumentRow
								key={`doc-${document.id}`}
								document={document}
								documentStore={this.state.documentStore}
								canRemove={false}
							/>
						);
					})}
					<div className="collapsable-panel__footer border border-top text--uppercase text--right">
						<a
							className="link"
							target="_blank"
							href={this._buildDownloadAllUrl(documents, downloadAllfileName)}
							rel="noreferrer">
							<FormattedMessage id="label.download.all" />
						</a>
					</div>
				</CollapsablePanel>
			);
		});
	}

	/**
	 *
	 * @private
	 */
	_buildDownloadAllUrl(documents, fileName = 'archive.zip') {
		const uuids = documents
			.map((document) => {
				return document.assetUuid;
			})
			.join(',');
		return (
			window.config.apiPrefix +
			EndPoints.URL_ASSETS_ZIP.replace(':uuids', encodeURIComponent(uuids)).replace(
				':fileName',
				encodeURIComponent(fileName)
			)
		);
	}

	/**
	 *
	 * @private
	 */
	_initialize() {
		if (this.context.applicationStore.isLoggedIn) {
			const selectedCompany = this.context.applicationStore.getSelectedCompany();
			const command = new FetchDocumentsCommand(this.state.documentStore, selectedCompany);
			command.execute(this.onFetchSuccess, this.onFetchError);
		}
	}
}

PayrollDocuments.contextType = ApplicationContext;

PayrollDocuments.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(PayrollDocuments);
