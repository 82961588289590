import { observable, computed } from 'mobx';

export const PROGRESS_STATUS = {
	WAITING: 'PROGRESS_STATUS_STATE_WAITING',
	STARTED: 'PROGRESS_STATUS_STATE_STARTED',
	SUCCESS: 'PROGRESS_STATUS_STATE_SUCCESS',
	FAILED: 'PROGRESS_STATUS_STATE_FAILED'
};

let ID = 0;

export default class ProgressStatusModel {
	_uid = ID++;

	@observable source;

	@observable state;

	@observable total;

	@observable current;

	/**
	 *
	 * @param label String
	 * @param source Object
	 * @param total Number
	 * @param current Number
	 */
	constructor(label, source, total, current = 0) {
		this.label = label;
		this.source = source;
		this.total = total;
		this.current = current;
		this.state = PROGRESS_STATUS.WAITING;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get progress() {
		let p = this.current / this.total;
		if (isNaN(p)) {
			p = 0;
		}

		if (p > 1) {
			p = 1;
		}

		return p;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isWaiting() {
		return this.state === PROGRESS_STATUS.WAITING;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isBusy() {
		return this.state === PROGRESS_STATUS.STARTED;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDone() {
		return this.state === PROGRESS_STATUS.FAILED || this.state === PROGRESS_STATUS.SUCCESS;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasFailed() {
		return this.state === PROGRESS_STATUS.FAILED;
	}
}
