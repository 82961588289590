import React from 'react';
import { FormattedDate, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { ApplicationContext } from '../../../ApplicationContext';

import { buildAssetURL } from '../../../utils/assetUtils';

import Signals from '../../../signals/Signals';

import assetDataGet from '../../../requests/assets/assetDataGet';

import Draft from '../../../stores/models/Draft';
import DraftsStore from '../../../stores/DraftsStore';
import Asset from '../../../stores/models/Asset';

import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import DocumentDraftActions from '../../../components/ui/ToolTip/tooltips/DocumentDraftActions';
import { SourceIcon } from '../../../components/ui/SourceIcon/SourceIcon';
import DocumentInputPanel from '../DocumentInputPanel';
import DeleteCompanyDraftCommand from '../../../commands/drafts/DeleteCompanyDraftCommand';
import DeleteUserDraftCommand from '../../../commands/drafts/DeleteUserDraftCommand';
import Company from '../../../stores/models/Company';

/**
 *
 */
@observer
class DocumentDraftRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.confirmRemove = this.confirmRemove.bind(this);
		this.showActions = this.showActions.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);

		this.state = { asset: null };
	}

	/**
	 *
	 */
	componentWillMount() {}

	/**
	 *
	 */
	componentDidMount() {
		this.loadAsset();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { draft } = this.props;
		const { asset } = this.state;

		return (
			<div
				className={`document-draft-row grid grid--spread grid--vcenter padding border-top border--dark ${this.props.className}`}>
				<div className="document-draft-row__info" onClick={() => this.edit()}>
					<div className="document-draft-row__filename grid grid--no-wrap grid--vcenter">
						<SourceIcon type={draft.source} />
						<span>{asset ? asset.filename : '...'}</span>
					</div>
					<span className="document-draft-row__filedate">
						<FormattedDate value={draft.createdAt} />
					</span>
				</div>
				<div
					className="document-draft-row__actions grid grid--center grid--vcenter"
					onClick={this.showActions}>
					<span>...</span>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'edit':
				this.edit();
				break;
			case 'open':
				this.open();
				break;
			case 'remove':
				this.remove();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param e
	 */
	showActions(e) {
		e.preventDefault();

		Signals.ShowToolTip.dispatch(
			<DocumentDraftActions onSelect={this.onActionSelect} hideTypes={[]} />,
			e.target,
			-16
		);
	}

	/**
	 *
	 */
	edit() {
		const { draft, draftsStore } = this.props;
		Signals.ShowOverlay.dispatch(<DocumentInputPanel draft={draft} draftsStore={draftsStore} />);
	}

	/**
	 *
	 */
	open() {
		const { draft } = this.props;
		const url = buildAssetURL(draft.assetUuid);
		window.open(url);
	}

	/**
	 *
	 */
	remove() {
		const { asset } = this.state;
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={this.props.intl.formatMessage(
					{ id: 'documents.remove.confirm.title' },
					{ fileName: asset.filename }
				)}
				body={this.props.intl.formatMessage(
					{ id: 'documents.remove.confirm.body' },
					{ fileName: asset.filename }
				)}
				onConfirm={this.confirmRemove}
			/>
		);
	}

	/**
	 *
	 */
	confirmRemove() {
		/**
		 * draftsStore, companyId, draft
		 * @type {DeleteCompanyDraftCommand}
		 */
		const { draft, draftsStore } = this.props;
		const { applicationStore } = this.context;

		if (draftsStore.owner instanceof Company) {
			const company = draftsStore.owner;
			const command = new DeleteCompanyDraftCommand(draftsStore, company.id, draft);
			command.execute();
		} else {
			const command = new DeleteUserDraftCommand(draftsStore, applicationStore.user.id, draft);
			command.execute();
		}
	}

	/**
	 *
	 */
	loadAsset() {
		const { draft } = this.props;
		const { assetUuid } = draft;

		assetDataGet(assetUuid).then(
			(response) => {
				this.setState({ asset: new Asset(response.data) });
			},
			(err) => {
				console.log(err);
			}
		);
	}
}

export default injectIntl(DocumentDraftRow);

DocumentDraftRow.contextType = ApplicationContext;

DocumentDraftRow.propTypes = {
	intl: PropTypes.object,
	className: PropTypes.string,
	draft: PropTypes.instanceOf(Draft).isRequired,
	draftsStore: PropTypes.instanceOf(DraftsStore).isRequired
};

DocumentDraftRow.defaultProps = {
	className: ''
};
