import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Company from '../../../stores/models/Company';

import { ApplicationContext } from '../../../ApplicationContext';
import CompanyBrandModel from '../../../stores/models/CompanyBrandModel';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';

@observer
/**
 *
 */
export default class CompanyBrand extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { company, companyBrand } = this.props;

		const logoPreview = buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid);

		return (
			<div className="company-brand grid">
				<div className="company-brand__address grid col--6">
					<div className="company-brand__line company-brand__name col--12">{companyBrand.name}</div>
					<div className="company-brand__line company-brand__address col--12">
						{companyBrand.address}
					</div>
					<div className="company-brand__line company-brand__postalcode col--12">
						{companyBrand.postalCode} {companyBrand.city}
					</div>
					<div className="company-brand__line company-brand__country col--12">
						<FormattedMessage id="country.thenetherlands" />
					</div>

					<div className="company-brand__line col--12">&nbsp;</div>

					<div className="company-brand__line company-brand__phonenumber col--12">
						{companyBrand.phoneNumber}
					</div>
					<div className="company-brand__line company-brand__email col--12">
						{companyBrand.email}
					</div>
					<div className="company-brand__line company-brand__website col--12">
						{companyBrand.website}
					</div>

					<div className="company-brand__line col--12">&nbsp;</div>

					<div className="company-brand__line company-brand__kvk col--12">
						<FormattedMessage id="company.address.label.kvk" />
						{companyBrand.kvkNr}
					</div>

					{!company.ignoreBTWNumber() ? (
						<div className="company-brand__line company-brand__vat col--12">
							<FormattedMessage id="company.address.label.vat" />
							NL{company.getVatNrOrId()}
						</div>
					) : null}

					{companyBrand.hasIBAN() ? (
						<div className="company-brand__line company-brand__iban col--12">
							<FormattedMessage id="company.address.label.iban" />
							{companyBrand.iban}
						</div>
					) : null}
				</div>

				<div className="company-brand__logo col--6">
					{logoPreview ? <img src={logoPreview} alt="logo" title={companyBrand.name} /> : null}
				</div>
			</div>
		);
	}
}

CompanyBrand.contextType = ApplicationContext;

CompanyBrand.propTypes = {
	company: PropTypes.instanceOf(Company).isRequired,
	companyBrand: PropTypes.instanceOf(CompanyBrandModel).isRequired
};

CompanyBrand.defaultProps = {};
