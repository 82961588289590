import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { ApplicationContext } from '../../../ApplicationContext';
import GetAuthorizations from '../../../commands/oauth/GetAuthorizations';
import AuthorizationStore from '../../../stores/AuthorizationStore';
import MollieIntegrationSettings from './components/MollieIntegrationSettings';

/**
 *
 */
class InvoicesSettingsIntegrations extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			authorizationsRetrieved: false,
			authorizationStore: new AuthorizationStore()
		};
	}

	/**
	 *
	 */
	componentWillMount() {
		// Set initial state
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.setState(company.invoicesSettings.toObject());
		}
	}

	/**
	 *
	 */
	componentDidMount() {
		const company = this.context.applicationStore.getSelectedCompany();
		const { authorizationStore } = this.state;

		// Retrieve integrations
		if (company) {
			const command = new GetAuthorizations(company, authorizationStore);
			command.execute(() => {
				this.setState({ authorizationsRetrieved: true });
			});
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		const { authorizationsRetrieved, authorizationStore } = this.state;

		if (!this.context.applicationStore.isLoggedIn || !company) {
			return null;
		}

		return (
			<div className="invoices-settings__panel panel grid col--12 box-shadow border border--dark">
				<div className="invoices-settings__header padding border--bottom border--dark">
					<h2 className="pad-left">
						<FormattedMessage id="invoices.settings.title.integrations" />
					</h2>
				</div>
				<div className="invoices-settings__content">
					{authorizationsRetrieved ? (
						<MollieIntegrationSettings authorizationStore={authorizationStore} />
					) : null}
				</div>
			</div>
		);
	}
}

InvoicesSettingsIntegrations.contextType = ApplicationContext;

InvoicesSettingsIntegrations.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(InvoicesSettingsIntegrations);
