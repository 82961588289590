import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (transactionId, resourceId, resourceType, companyId, date) => {
	return fetch(
		Methods.DELETE,
		`${EndPoints.COMPANIES_BANKBOOK_UNMATCH_TRANSACTION.replace(
			':transactionId',
			transactionId
		)}?companyId=${companyId}&resourceId=${resourceId}&resourceType=${resourceType}&transactionExecutionDate=${date.toISOString()}`,
		null,
		null,
		true
	);
};
