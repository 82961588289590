import AbstractCommand from '../AbstractCommand';
import offerGet from '../../requests/offers/offerGet';
import Signals from '../../signals/Signals';
import OfferModel from '../../stores/models/OfferModel';

/**
 *
 */
export default class GetOfferCommand extends AbstractCommand {
	/**
	 *
	 * @param offerModel OfferModel
	 * @param company Company
	 */
	constructor(offerModel, company) {
		super();

		this.offerModel = offerModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return offerGet(this.company.id, this.offerModel.id)
			.then((response) => {
				OfferModel.updateOfferModel(response.data, this.offerModel);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				Signals.Error.dispatch(err);

				if (error) {
					error(err);
				}
			});
	}
}
