import { observable } from 'mobx';
import Model from './Model';

export class BankAccount extends Model {
	@observable id;

	@observable currency;

	@observable description;

	@observable reference;

	@observable referenceType;

	@observable currentBalance;

	@observable availableBalance;

	@observable enabled;

	@observable expirationDate;

	@observable synchronizedAt;

	constructor(data = null) {
		super(data);
	}

	update(data) {
		console.log('BankAccount.update', data);
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'expirationDate':
						this.expirationDate = new Date(data[key]);
						break;
					case 'synchronizedAt':
						this[key] = new Date(data[key]);
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}
}
