import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (transactionId, companyId, year, data) => {
	return fetch(
		Methods.PUT,
		`${EndPoints.COMPANIES_BANKBOOK_MATCH_TRANSACTION.replace(
			':transactionId',
			transactionId
		)}?companyId=${companyId}&year=${year}`,
		data,
		null,
		true
	);
};
