/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import { ApplicationContext } from '../../../ApplicationContext';

import CompanyBrandInput from '../../../pages/Company/GenericSettings/modals/CompanyBrandInput';

import { uniqueKey } from '../../../utils/ReactUtils';
import Signals from '../../../signals/Signals';

import CompanyBrandModel from '../../../stores/models/CompanyBrandModel';

import DropDownIndicator from './DropDownIndicator';
import CompanyBrandOption from './CompanyBrandOption';
import CompanyInput from '../../../pages/Company/Info/modals/CompanyInput';
import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';
import Company from '../../../stores/models/Company';

/**
 *
 */
@observer
export default class CompanyBrandSearch extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onAddBrand = this.onAddBrand.bind(this);
		this.onEditBrand = this.onEditBrand.bind(this);
		this.onToggleChangeOnly = this.onToggleChangeOnly.bind(this);

		this.state = { showChangeOnly: null };
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { changeOnly, value, initialCompanyBrand } = this.props;
		const { showChangeOnly } = this.state;

		let showChange = showChangeOnly == null ? changeOnly : showChangeOnly;

		// Never show 'change' state if no initial brand has been specified
		if (!initialCompanyBrand) {
			showChange = false;
		}

		return (
			<div className={`company-brand-search ${this.props.className}`}>
				<div className="company-brand-search__input-wrapper">
					{showChange ? (
						<input
							type="text"
							value={initialCompanyBrand ? initialCompanyBrand.getLabel() : null}
							disabled
						/>
					) : (
						<Select
							className="company-brand-search__select"
							classNamePrefix="react-select"
							placeholder={this.context.intl.formatMessage({ id: 'company.brand.search' })}
							noOptionsMessage={() => {
								return this.context.intl.formatMessage({ id: 'company.brand.noresults' });
							}}
							components={{ DropdownIndicator: DropDownIndicator, Option: CompanyBrandOption }}
							value={this._getOptionByValue(value)}
							isSearchable
							isDisabled={showChange}
							options={this._createOptions()}
							onChange={this.onChange}
						/>
					)}
				</div>

				<div className="grid col--12 grid--spread">
					{showChange ? (
						<div className="company-brand-search__add link" onClick={this.onToggleChangeOnly}>
							<FormattedMessage id="company.brand.search.change" />
						</div>
					) : null}

					{!showChange ? (
						<div className="company-brand-search__add link" onClick={this.onAddBrand}>
							<FormattedMessage id="company.brand.search.add" />
						</div>
					) : null}

					{!showChange && value ? (
						<div className="company-brand-search__edit link" onClick={this.onEditBrand}>
							<FormattedMessage id="company.brand.search.edit" />
						</div>
					) : null}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onToggleChangeOnly() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ showChangeOnly: !(this.state.showChangeOnly == null) }, () => {
			if (!this.state.showChangeOnly) {
				const list = this._getCompanyBrands();
				if (list.length > 0) {
					this.onUpdate(list[0]);
				}
			}
		});
	}

	/**
	 *
	 */
	onAddBrand() {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput
				key={uniqueKey('cbi-')}
				companyBrand={company.defaultCompanyBrand}
				isNew
				onUpdate={this.onUpdate}
			/>
		);
	}

	/**
	 *
	 */
	onEditBrand() {
		if (this.props.value) {
			const id = parseInt(`${this.props.value}`, 10);
			const { applicationStore } = this.context;
			const company = applicationStore.getSelectedCompany();
			const companyBrand = applicationStore.companyBrandStore.find(id);

			if (id !== -1) {
				Signals.ShowOverlay.dispatch(
					<CompanyBrandInput
						key={uniqueKey('cbi-')}
						companyBrand={companyBrand}
						onUpdate={this.onUpdate}
					/>
				);
			} else {
				Signals.ShowOverlay.dispatch(
					<CompanyInput key={uniqueKey('ci-')} company={company} onUpdate={this.onUpdate} />
				);
			}
		}
	}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onClick() {
		this.select.click();
	}

	/**
	 *
	 * @param option
	 */
	onChange(option) {
		if (this.props.onChange) {
			const { applicationStore } = this.context;
			const company = applicationStore.getSelectedCompany();

			let companyBrand = applicationStore.companyBrandStore.find(parseInt(option.value, 10));
			if (!companyBrand) {
				companyBrand = company.defaultCompanyBrand;
			}

			this.props.onChange(companyBrand);
		}
	}

	/**
	 *
	 * @param companyBrand
	 */
	onUpdate(companyBrand) {
		if (this.props.onChange) {
			// When using CompanyInput, the result can be of Company and needs to change to CompanyBrandModel
			if (companyBrand instanceof Company) {
				companyBrand = companyBrand.defaultCompanyBrand;
			}

			this.props.onChange(companyBrand);
		}
	}

	/**
	 *
	 * @param value
	 * @private
	 */
	_getOptionByValue(value) {
		const options = this._createOptions();

		if (!value && options.length > 0) {
			return options[0];
		}

		return options.find((searchItem) => {
			return searchItem.value === value;
		});
	}

	/**
	 *
	 * @return {Array|*}
	 * @private
	 */
	_getCompanyBrands() {
		const { companyBrandStore } = this.context.applicationStore;
		if (companyBrandStore) {
			const { includeDefault } = this.props;
			const company = this.context.applicationStore.getSelectedCompany();

			// Create list
			const list = companyBrandStore.list.concat([]);
			if (includeDefault) {
				list.unshift(company.defaultCompanyBrand);
			}

			return list;
		}

		return [];
	}

	/**
	 *
	 * @return {{label: (*|string), value: *}[]|Array}
	 * @private
	 */
	_createOptions() {
		const { companyBrandStore } = this.context.applicationStore;
		if (companyBrandStore) {
			const company = this.context.applicationStore.getSelectedCompany();

			// Create list
			const list = this._getCompanyBrands();

			// Hide logo when no logo's are in the list
			const hideLogoPreview = !list.find((companyBrand) => {
				return !!buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid);
			});

			return list.map((companyBrand) => {
				const logoPreview = buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid);
				return {
					value: companyBrand.id,
					label: companyBrand.getLabel(),
					logoPreview,
					hideLogoPreview,
					brandName: companyBrand.name,
					brandEmail: companyBrand.email
				};
			});
		}

		return [];
	}
}

CompanyBrandSearch.contextType = ApplicationContext;

CompanyBrandSearch.propTypes = {
	name: PropTypes.string,
	value: PropTypes.number,
	initialCompanyBrand: PropTypes.instanceOf(CompanyBrandModel),
	className: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	changeOnly: PropTypes.bool,
	includeDefault: PropTypes.bool
};

CompanyBrandSearch.defaultProps = {
	className: '',
	placeholder: 'Selecteer afzender'
};
