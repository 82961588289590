/**
 *
 * @param value
 * @param intl
 * @param replacementMessageId
 * @return {boolean|*}
 */
export function replaceEmptyValue(value, intl, replacementMessageId = 'label.empty') {
	if (value) {
		return value;
	}
	return intl.formatMessage({ id: replacementMessageId });
}

export function amountWithSignFromParts(amount, parts) {
	if (!amount || !parts) {
		return null;
	}
	const values = parts.map((part) => part.value);
	if (amount >= 0) {
		values.unshift('+');
	}
	return values.join('');
}
