import AbstractCommand from '../AbstractCommand';
import userMessageSeen from '../../requests/userMessages/userMessageSeen';

/**
 *
 */
export default class SeenUserMessageCommand extends AbstractCommand {
	/**
	 *
	 * @param userMessage
	 * @param userMessageStore
	 */
	constructor(userMessage, userMessageStore) {
		super();
		this.userMessage = userMessage;
		this.userMessageStore = userMessageStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 * @return {Promise<any | never>}
	 */
	execute(success, error) {
		return userMessageSeen(this.userMessage.id)
			.then((response) => {
				this.userMessage.readAt = new Date();

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
