import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { formatIBAN } from '../../../utils/ibanUtils';

import Company from '../../../stores/models/Company';

import { ApplicationContext } from '../../../ApplicationContext';
import { getTranslations } from '../../../utils/Translations';

@observer
/**
 *
 */
export default class CompanyAddress extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { company, onEdit, useDate } = this.props;

		const edit =
			onEdit && applicationStore.user ? (
				<IntlProvider
					locale={applicationStore.user.language}
					messages={getTranslations(applicationStore.user.language)}>
					<div className="company-address__edit link link--primary col--12" onClick={onEdit}>
						<FormattedMessage id="company.address.edit" />
					</div>
				</IntlProvider>
			) : null;

		const vatNumberOrId = useDate && company.useBTWId(useDate) ? company.vatId : company.vatNumber;

		return (
			<div className="company-address grid">
				<div className="company-address__line company-address__name col--12">{company.name}</div>
				<div className="company-address__line company-address__address col--12">
					{company.address.address}
				</div>
				<div className="company-address__line company-address__postalcode col--12">
					{company.address.postalCode} {company.address.city}
				</div>
				<div className="company-address__line company-address__country col--12">
					<FormattedMessage id="country.thenetherlands" />
				</div>

				<div className="company-address__line col--12">&nbsp;</div>

				<div className="company-address__line company-address__phonenumber col--12">
					{company.phoneNumber}
				</div>
				<div className="company-address__line company-address__email col--12">{company.email}</div>
				<div className="company-address__line company-address__website col--12">
					{company.website}
				</div>

				<div className="company-address__line col--12">&nbsp;</div>

				<div className="company-address__line company-address__kvk col--12">
					<FormattedMessage id="company.address.label.kvk" />
					{company.kvkNumber}
				</div>

				{!company.ignoreBTWNumber() && vatNumberOrId && vatNumberOrId.length > 0 ? (
					<div className="company-address__line company-address__vat col--12">
						<FormattedMessage id="company.address.label.vat" />
						NL{vatNumberOrId}
					</div>
				) : null}

				{company.hasIBAN() ? (
					<div className="company-address__line company-address__iban col--12">
						<FormattedMessage id="company.address.label.iban" />
						{formatIBAN(company.iban)}
					</div>
				) : null}
				{edit}
			</div>
		);
	}
}

CompanyAddress.contextType = ApplicationContext;

CompanyAddress.propTypes = {
	useDate: PropTypes.instanceOf(Date),
	onEdit: PropTypes.func,
	company: PropTypes.instanceOf(Company).isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

CompanyAddress.defaultProps = {
	useDate: null
};
