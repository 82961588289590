/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

import IBAangifteStatus from '../../../data/IBAangifteStatus';
import Child from '../../../data/Child';

import Question from '../../../components/ui/Question/Question';
import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import TaxReturnCompanyChecklist from './TaxReturnCompanyChecklist/TaxReturnCompanyChecklist';
import ChildrenInput from './components/ChildrenInput';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import { ApplicationContext } from '../../../ApplicationContext';
import PartnerDeclarationType from '../../../data/PartnerDeclarationType';

@observer
/**
 *
 */
class TaxReturnYear extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.form = null;

		this.state = {
			submitting: false
		};

		this.onQuestionChanged = this.onQuestionChanged.bind(this);
		this.onQuestionChildrenChanged = this.onQuestionChildrenChanged.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSaveSuccess = this.onSaveSuccess.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onError = this.onError.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {}

	/**
	 *
	 */
	render() {
		//
		const { applicationStore } = this.context;
		const { annualStatementModel, disabled, locked, intl } = this.props;
		const annualStatementIsOpened = applicationStore.todoStore.hasAnnualStatementTodo(
			annualStatementModel.year
		);
		const annualStatementIsSubmitted = annualStatementModel.isSubmitted();
		this._prefillQuestions();

		// Classes
		const classes = classNames({
			'tax-return-year--locked': !annualStatementIsOpened && !annualStatementIsSubmitted,
			'tax-return-year--submitted': annualStatementIsSubmitted,
			'tax-return-year--disabled': disabled
		});

		// Translation variables
		const capitalCapAmount = this._getCapitalCapAmount();
		const translationVariables = {
			bookKeeper: applicationStore.user.bookKeeper.firstName,
			year: annualStatementModel.year,
			previousYear: annualStatementModel.year - 1,
			lastDateYear: intl.formatDate(new Date(annualStatementModel.year, 11, 31)),
			firstDateYear: intl.formatDate(new Date(annualStatementModel.year, 0, 1)),
			capitalCapSingle: capitalCapAmount.capitalCapSingle,
			capitalCapPartner: capitalCapAmount.capitalCapPartner,
			capitalCapSingleExceed: capitalCapAmount.capitalCapSingleExceed,
			capitalCapPartnerExceed: capitalCapAmount.capitalCapPartnerExceed
		};

		return (
			<div
				className={`tax-return-year tax-return-year__${annualStatementModel.year} ${classes} border--top border--dark`}>
				{!locked ? (
					<div className="tax-return-year-body__intro grid grid--spread padding">
						<div className="tax-return-year-body__text">
							<FormattedMessage id="taxreturn.intro" values={translationVariables} />
						</div>
					</div>
				) : null}

				{/* Show state for other companies */}
				{/* {!annualStatementIsSubmitted ? this._getOtherCompanies() : null} */}

				{/* Get locked state */}
				{locked ? this._getLockedState('taxreturn.panel.locked', translationVariables) : null}

				{/* Companies checklist */}
				{!locked ? (
					<form
						ref={(ref) => (this.form = ref)}
						className="tax-return-year__questions"
						onSubmit={this.onSubmit}>
						{/* Question 1 | Hours */}
						<Question
							className="tax-return-year__question tax-return-year__question-no-border "
							label={intl.formatMessage({ id: 'taxreturn.question1.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question1.description' },
								translationVariables
							)}
							questionModel={annualStatementModel.question1}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question1.extrainfo.title"
							extraInfoBodyId="taxreturn.question1.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 2 | Other companies */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question2.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question2.description' },
								translationVariables
							)}
							allowedFiles={25}
							checked={applicationStore.user.companies.length > 1 ? true : undefined}
							questionModel={annualStatementModel.question2}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question2.extrainfo.title"
							extraInfoBodyId="taxreturn.question2.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 3 | Employment */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question3.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question3.description' },
								translationVariables
							)}
							allowedFiles={25}
							questionModel={annualStatementModel.question3}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question3.extrainfo.title"
							extraInfoBodyId="taxreturn.question3.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 4 | House (1/2) */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question4.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question4.description' },
								translationVariables
							)}
							yesDescription={
								<FormattedHTMLMessage
									id="taxreturn.question4.yesdescription"
									values={translationVariables}
								/>
							}
							allowedFiles={25}
							questionModel={annualStatementModel.question4}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question4.extrainfo.title"
							extraInfoBodyId="taxreturn.question4.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 5 | House (2/2), if Question 4 is a NO, hide this question */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question5.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question5.description' },
								translationVariables
							)}
							yesDescription={
								<FormattedHTMLMessage
									id="taxreturn.question5.yesdescription"
									values={translationVariables}
								/>
							}
							allowedFiles={25}
							questionModel={annualStatementModel.question5}
							onChange={this.onQuestionChanged}
							hidden={!annualStatementModel.question4.checked}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question5.extrainfo.title"
							extraInfoBodyId="taxreturn.question5.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 6 | Financial capital */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question6.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question6.description' },
								translationVariables
							)}
							// checked={applicationStore.user.fiscalPartner}
							allowedFiles={25}
							questionModel={annualStatementModel.question6}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question6.extrainfo.title"
							extraInfoBodyId="taxreturn.question6.extrainfo.body"
							yearSpecificInfoId={
								annualStatementModel.year === 2021
									? 'taxreturn.question6.extrainfo.body.2021'
									: null
							}
							extraInfoValues={translationVariables}
						/>

						{/* Question 7 | Tax partner (1/2) */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question7.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question7.description' },
								translationVariables
							)}
							allowedFiles={0}
							questionModel={annualStatementModel.question7}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question7.extrainfo.title"
							extraInfoBodyId="taxreturn.question7.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 8 | Tax partner (2/2) */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question8.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question8.description' },
								translationVariables
							)}
							noDescription={
								<FormattedHTMLMessage
									id="taxreturn.question8.nodescription"
									values={translationVariables}
								/>
							}
							allowedFiles={25}
							showFilesWhenFalse
							hidden={!annualStatementModel.question7.checked}
							questionModel={annualStatementModel.question8}
							onChange={this.onQuestionChanged}
							disabled={disabled}
						/>

						{/* Question 9 | Children */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question9.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question9.description' },
								translationVariables
							)}
							yesDescription={
								<FormattedHTMLMessage
									id="taxreturn.question9.yesdescription"
									values={translationVariables}
								/>
							}
							allowedFiles={0}
							questionModel={annualStatementModel.question9}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question9.extrainfo.title"
							extraInfoBodyId="taxreturn.question9.extrainfo.body"
							extraInfoValues={translationVariables}>
							{annualStatementModel.question9.checked ? (
								<ChildrenInput
									onChange={this.onQuestionChildrenChanged}
									// eslint-disable-next-line react/no-children-prop
									children={this._getQuestionChildren()}
									disabled={disabled}
								/>
							) : null}
						</Question>

						{/* Question 10 | Provisional Assessment */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question10.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question10.description' },
								translationVariables
							)}
							allowedFiles={25}
							questionModel={annualStatementModel.question10}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question10.extrainfo.title"
							extraInfoBodyId="taxreturn.question10.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Question 11 | Other */}
						<Question
							className="tax-return-year__question"
							label={intl.formatMessage({ id: 'taxreturn.question11.title' }, translationVariables)}
							description={intl.formatMessage(
								{ id: 'taxreturn.question11.description' },
								translationVariables
							)}
							allowedFiles={25}
							questionModel={annualStatementModel.question11}
							onChange={this.onQuestionChanged}
							disabled={disabled}
							extraInfoTitleId="taxreturn.question11.extrainfo.title"
							extraInfoBodyId="taxreturn.question11.extrainfo.body"
							extraInfoValues={translationVariables}
						/>

						{/* Create footer content, save and submit buttons, omit when already submitted */}
						{!annualStatementModel.isSubmitted() ? (
							<div className="options-footer tax-return-year__section-footer padding-small grid grid--right border--top border--dark">
								{this._getSaveButton()}
								{this._getSubmitButton()}
							</div>
						) : null}
					</form>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @param children
	 */
	onQuestionChildrenChanged(children) {
		const { annualStatementModel } = this.props;
		annualStatementModel.question9.meta = JSON.stringify(children);
		this.onQuestionChanged();
	}

	/**
	 *
	 */
	onQuestionChanged() {
		this.forceUpdate();
	}

	/**
	 *
	 */
	onSave() {
		if (!this.state.submitting) {
			this.state.submitting = true;
			this.props.annualStatementModel.save(this.onSaveSuccess, this.onError);
		}
	}

	/**
	 *
	 */
	onSubmit(e) {
		if (e) {
			// Is valid? If not allow auto validation message to trigger
			if (this.form && !this.form.checkValidity()) {
				return;
			}

			// Otherwise preven event and continue submitting through xhr
			e.preventDefault();
		}

		if (!this.state.submitting) {
			this.state.submitting = true;

			const title = this.props.intl.formatMessage({ id: 'tax-return-year.submit.confirm.title' });
			const yesLabel = this.props.intl.formatMessage({ id: 'tax-return-year.submit.confirm.yes' });
			const noLabel = this.props.intl.formatMessage({ id: 'tax-return-year.submit.confirm.no' });

			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={title}
					onCancel={() => (this.state.submitting = false)}
					onConfirm={() => {
						this.props.annualStatementModel.save(this.onSubmitSuccess, this.onError, true);
					}}
					yesLabel={yesLabel}
					noLabel={noLabel}
				/>
			);
		}
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		this.state.submitting = false;
		Signals.Error.dispatch(error);
	}

	/**
	 *
	 */
	onSaveSuccess() {
		this.state.submitting = false;
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage
				id="tax-return-year.saved.message"
				values={{ year: this.props.annualStatementModel.year }}
			/>
		);

		this.forceUpdate();
	}

	/**
	 *
	 */
	onSubmitSuccess() {
		this.state.submitting = false;
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage
				id="tax-return-year.submitted.message"
				values={{ year: this.props.annualStatementModel.year }}
			/>
		);

		// TODO: payoff
		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatMessage({ id: 'taxreturn.panel.submitted.title' })}
				body={this.props.intl.formatMessage({ id: 'taxreturn.panel.submitted.submitted_client' })}
			/>
		);

		// Force rerender of component
		this.props.annualStatementModel.ibAangifteStatus = IBAangifteStatus.SUBMITTED_CLIENT;

		// Close
		this.context.applicationStore.closeFinancialYear(this.props.annualStatementModel.year);

		// Update todos
		this.context.applicationStore.todoStore.fetch();
	}

	/**
	 *
	 * @return {Array}
	 * @private
	 */
	_getQuestionChildren() {
		try {
			const children = JSON.parse(this.props.annualStatementModel.question9.meta);

			if (!children) {
				return [];
			}

			return children.map((data) => {
				return new Child(data.initials, data.birthDate ? new Date(data.birthDate) : null);
			});
		} catch (exception) {
			// Ignore
		}

		return [];
	}

	/**
	 *
	 * @private
	 */
	_prefillQuestions() {
		// Has fiscal partner?
		const { annualStatementModel } = this.props;
		const { applicationStore } = this.context;

		if (annualStatementModel.question1.isUnanswered() && applicationStore.user.ignoreHours()) {
			annualStatementModel.question1.checked = true;
			annualStatementModel.question1.disabled = true;
		}

		if (annualStatementModel.question7.isUnanswered()) {
			annualStatementModel.question7.checked = applicationStore.user.fiscalPartner;
		}

		if (annualStatementModel.question8.isUnanswered()) {
			console.log('applicationStore.user.partnerDeclarationType');
			annualStatementModel.question8.checked =
				applicationStore.user.partnerDeclarationType === PartnerDeclarationType.PARTNER_IS_CUSTOMER;
			console.log(
				applicationStore.user.partnerDeclarationType,
				annualStatementModel.question8.checked
			);
		}
	}

	/**
	 *
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getOtherCompanies() {
		// Create companies checklists
		const { applicationStore } = this.context;
		const { annualStatementModel } = this.props;
		const selectedCompany = applicationStore.getSelectedCompany();

		return applicationStore.user.companies.map((company) => {
			const financialYear = company.getFinancialYearByYear(annualStatementModel.year);
			if (!financialYear || company === selectedCompany) return null;

			return (
				<TaxReturnCompanyChecklist
					key={`trcc-${company.id}`}
					hasDeadlines={!financialYear.financialStatementSubmitted}
					company={company}
					financialYear={financialYear}
				/>
			);
		});
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getSaveButton() {
		// In progress of saving
		const { annualStatementModel } = this.props;
		if (annualStatementModel.saving) {
			return (
				<button className="tax-return-year__save button--tertiary" disabled onClick={this.onSave}>
					<FormattedMessage id="tax-return-year.save" />
				</button>
			);

			// Is form completed and are there no deadlines open?
		}
		if (annualStatementModel.isComplete()) {
			return (
				<button className="tax-return-year__save button--tertiary" onClick={this.onSave}>
					<FormattedMessage id="tax-return-year.save" />
				</button>
			);

			// If form not complete or if there are open deadlines, disable submit button
		}
		return (
			<button className="tax-return-year__save button--tertiary" onClick={this.onSave}>
				<FormattedMessage id="tax-return-year.save" />
			</button>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getSubmitButton() {
		// In progress of saving
		const { annualStatementModel } = this.props;
		if (annualStatementModel.saving) {
			return (
				<button className="tax-return-year__submit" disabled onClick={this.onSubmit}>
					<FormattedMessage id="tax-return-year.submit" />
				</button>
			);

			// Is form completed and are there no deadlines open?
		}
		if (annualStatementModel.isComplete()) {
			return (
				<button className="tax-return-year__submit" type="submit" onClick={this.onSubmit}>
					<FormattedMessage id="tax-return-year.submit" />
				</button>
			);

			// If form not complete or if there are open deadlines, disable submit button
		}
		return (
			<button className="tax-return-year__submit" disabled onClick={this.onSubmit}>
				<FormattedMessage id="tax-return-year.submit" />
			</button>
		);
	}

	/**
	 * @param descriptionTranslationId
	 * @param translationValues
	 * @returns {*}
	 * @private
	 */
	_getLockedState(descriptionTranslationId, translationValues) {
		return (
			<div className="grid grid--spread padding">
				<div className="deadlines-locked__icon icon icon--lock-smoke" />
				<div className="deadlines-locked__text">
					<FormattedMessage id={descriptionTranslationId} values={translationValues} />
				</div>
			</div>
		);
	}

	/**
	 * 	@returns {capitalCapSingle, capitalCapPartner}
	 *  @private
	 *	Returns capital cap values for the current year.
	 * */
	_getCapitalCapAmount() {
		const { annualStatementModel } = this.props;
		let capitalCapSingle = null;
		let capitalCapPartner = null;
		let capitalCapSingleExceed = null;
		let capitalCapPartnerExceed = null;

		if (annualStatementModel.year >= 2021) {
			capitalCapSingle = '31.340';
			capitalCapPartner = '62.680';
			capitalCapSingleExceed = '50.000';
			capitalCapPartnerExceed = '100.000';
		} else if (annualStatementModel.year >= 2020) {
			capitalCapSingle = '30.846';
			capitalCapPartner = '61.692';
		} else if (annualStatementModel.year >= 2018) {
			capitalCapSingle = '30.000';
			capitalCapPartner = '60.000';
		} else {
			capitalCapSingle = '25.000';
			capitalCapPartner = '50.000';
		}

		return { capitalCapSingle, capitalCapPartner, capitalCapSingleExceed, capitalCapPartnerExceed };
	}
}

TaxReturnYear.contextType = ApplicationContext;

TaxReturnYear.propTypes = {
	annualStatementModel: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	locked: PropTypes.bool,
	intl: PropTypes.object
};
export default injectIntl(TaxReturnYear);
