import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PropertiesController from '../../../../controllers/PropertiesController';
import Signals from '../../../../signals/Signals';
import { Routes, RouteUtil } from '../../../../data/Routes';
import DraftTypes from '../../../../data/DraftTypes';
import MenuItem from '../../MenuItem/MenuItem';
import DropDown from '../../DropDown/DropDown';
import { ApplicationContext } from '../../../../ApplicationContext';
import Context from '../../../../data/Context';
import CompanyProperties from '../../../../data/CompanyProperties';

/**
 *
 */
@observer
class BookKeepingSubMenu extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;

		this.onYearChanged = this.onYearChanged.bind(this);
	}

	componentWillMount() {}

	componentWillUnmount() {}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company || !financialYear) return null;

		const params = {
			id: company ? company.id : null,
			year: financialYear ? financialYear.id : null
		};

		const financialYearOptions = company.financialYears.map((year, index) => {
			return (
				<option key={`fy-${index}`} value={year.id}>
					{year.year}
				</option>
			);
		});

		return (
			<ul className="sub-menu__item-wrapper">
				<DropDown
					color={PropertiesController.getContext() === Context.FOUNDERS ? 'smoke' : 'navy'}
					value={financialYear.id}
					onChange={this.onYearChanged}>
					{financialYearOptions}
				</DropDown>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_RESULTS.getPath(params)}
					routeToMatch={Routes.COMPANY_RESULTS}
					iconName="graph">
					<FormattedMessage id="submenu.results" />
				</MenuItem>
				<MenuItem
					className="submenu-item walkthrough-step-revenues"
					route={Routes.COMPANY_REVENUES.getPath(params)}
					routeToMatch={Routes.COMPANY_REVENUES}
					iconName="download">
					<FormattedMessage id="submenu.income" />{' '}
					<span className="count">{this.getDraftsCount(DraftTypes.INCOME)}</span>
				</MenuItem>
				<MenuItem
					className="submenu-item walkthrough-step-expenses"
					route={Routes.COMPANY_EXPENSES.getPath(params)}
					routeToMatch={Routes.COMPANY_EXPENSES}
					iconName="upload">
					<FormattedMessage id="submenu.expenses" />{' '}
					<span className="count">{this.getDraftsCount(DraftTypes.EXPENSE)}</span>
				</MenuItem>
				{company.isFeatureEnabled(CompanyProperties.FEATURE_BANKBOOK) ? (
					<MenuItem
						className="submenu-item"
						id="bankbook-page-button"
						route={Routes.COMPANY_PONTO_ONBOARDING.getPath(params)}
						routeToMatch={Routes.COMPANY_BANK}
						iconName="layers">
						<FormattedMessage id="submenu.bank" />
					</MenuItem>
				) : null}
				<MenuItem
					className="submenu-item walkthrough-step-deadlines"
					route={Routes.COMPANY_DEADLINES.getPath(params)}
					routeToMatch={Routes.COMPANY_DEADLINES}
					iconName="clock">
					<FormattedMessage id="submenu.deadlines" />
				</MenuItem>
			</ul>
		);
	}

	/**
	 *
	 * @param e
	 */
	onYearChanged(e) {
		const currentRoute = RouteUtil.getRoute(this.props.location.pathname);
		const selectedCompany = this.context.applicationStore.getSelectedCompany();
		Signals.RequestRoute.dispatch(
			currentRoute.getPath({ id: selectedCompany.id, year: e.target.value })
		);
	}

	/**
	 *
	 * @param draftType string
	 * @return {string|null}
	 */
	getDraftsCount(draftType) {
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		const count = company.draftsStore.getDraftsOfType(draftType, financialYear.id).length;
		if (count > 0) {
			return `(${count})`;
		}

		return null;
	}
}

BookKeepingSubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(BookKeepingSubMenu);

BookKeepingSubMenu.contextType = ApplicationContext;
