import AbstractCommand from '../AbstractCommand';
import publicInvoicePaymentStatus from '../../requests/invoices/publicInvoicePaymentStatus';

/**
 *
 */
export default class GetPublicInvoicePaymentStatusCommand extends AbstractCommand {
	/**
	 *
	 * @param uuid string
	 */
	constructor(uuid) {
		super();

		this.uuid = uuid;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return publicInvoicePaymentStatus(this.uuid)
			.then((response) => {
				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
