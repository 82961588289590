import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Signals from '../signals/Signals';

import { ApplicationContext } from '../ApplicationContext';

// import * as Sentry from '@sentry/browser';
import ModalConfirm from '../components/ui/Modal/ModalConfirm';
import ModalError from '../components/ui/Modal/ModalError';

/**
 *
 */
class ErrorController extends Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);
		console.log('ErrorController.constructor');

		this.onGlobalError = this.onGlobalError.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		console.log('ErrorController.componentDidMount');
		Signals.Error.add(this.onGlobalError);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		console.log('ErrorController.componentWillUnmount');
		Signals.Error.remove(this.onGlobalError);
	}

	/**
	 *
	 * @returns {null}
	 */
	render() {
		return null;
	}

	/**
	 *
	 * @param err
	 * @param suppressMessage
	 */
	onGlobalError(err, suppressMessage = false) {
		console.log('onGlobalError:', err, suppressMessage);

		const error = err.error;
		let title = 'Error';
		let message = 'An error occurred';
		let redirectToLogin = false;

		if (error && error.message && error.status) {
			title = `Error status: ${error.status}`;
			message = error.rawResponse ? error.rawResponse : error.message;

			switch (error.status) {
				case 401:
				case 403:
				case 405:
					title = this.props.intl.formatMessage({ id: 'error.40x.title' });
					message = this.props.intl.formatMessage({ id: 'error.40x.description' });
					redirectToLogin = true;
					break;
				case 404:
					if (error.response && error.response.req && error.response.req.url) {
						title = this.props.intl.formatMessage({ id: 'error.404.title' });
						message = this.props.intl.formatMessage(
							{ id: 'error.404.description' },
							{ url: error.response.req.url }
						);
					} else {
						title = this.props.intl.formatMessage({ id: 'error.40x.title' });
						message = this.props.intl.formatMessage({ id: 'error.40x.description' });
						redirectToLogin = true;
					}
					break;
				case 422:
					if (
						error.rawResponse &&
						error.rawResponse.indexOf('Did not save file, because provided file is empty') >= 0
					) {
						title = this.props.intl.formatMessage({ id: 'error.422.emptyfile.title' });
						message = this.props.intl.formatMessage({ id: 'error.422.emptyfile.description' });
						redirectToLogin = false;
					} else {
						redirectToLogin = false;
					}
					break;
				case 500:
					title = this.props.intl.formatMessage({ id: 'error.500.title' });
					message = this.props.intl.formatMessage({ id: 'error.500.description' });
					redirectToLogin = false;
					break;
				default:
					redirectToLogin = false;
			}
		} else {
			// No status error object, probably logged out
			title = this.props.intl.formatMessage({ id: 'error.40x.title' });
			if (error && error.message) {
				message = error.message;
			} else {
				message = `${this.props.intl.formatMessage({ id: 'error.40x.description' })}`;
			}

			// Check if user is onLine, if not, show ModalConfirm with explanation
			if (!window.navigator.onLine) {
				suppressMessage = true;

				title = `${this.props.intl.formatMessage({ id: 'error.offline.title' })}`;
				message = `${this.props.intl.formatMessage({ id: 'error.offline.description' })}`;
				const yesLabel = `${this.props.intl.formatMessage({ id: 'error.offline.yes' })}`;
				const noLabel = `${this.props.intl.formatMessage({ id: 'error.offline.no' })}`;

				Signals.ShowModal.dispatch(
					<ModalConfirm
						title={title}
						body={message}
						yesLabel={yesLabel}
						noLabel={noLabel}
						onConfirm={() => {
							this.logout();
						}}
					/>
				);
			}
		}

		// Show error message when not suppressing
		if (!suppressMessage) {
			Signals.ShowModal.dispatch(<ModalError title={title} body={message} />);

			// Sentry
			// if(!window.config.debug) {
			// 	Sentry.withScope(scope => {
			// 		scope.setExtra('debug', window.config.debug);
			// 		Sentry.captureMessage(err);
			// 	});
			// }
		}

		// Logout if necessary
		if (redirectToLogin) {
			this.context.applicationStore.logout();
		}
	}
}

ErrorController.contextType = ApplicationContext;

ErrorController.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(ErrorController);
