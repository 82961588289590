import { observable, action } from 'mobx';
import Store from './Store';
import GetBankRecordCategoriesCommand from '../commands/bank/GetBankRecordCategoriesCommand';
import BankCategoryOption from './models/BankCategoryOption';

export default class BankCategoryStore extends Store {
	@observable bankCategoryLabels = {};

	@observable bankCategoryMap = {};

	@observable fetched = false;

	isFetching = false;

	constructor() {
		super(BankCategoryOption);
	}

	@action fetchCategories() {
		const command = new GetBankRecordCategoriesCommand();
		if (!this.fetched && !this.isFetching) {
			this.isFetching = true;
			return command.execute().then((response) => {
				if (response) {
					this.addAll(this._formatToOptions(response), true);
					this.bankCategoryLabels = this._createLookUpTable(response);
					this.bankCategoryMap = this._flaten(this.list);
					this.fetched = true;
					this.isFetching = false;
				} else {
					this.isFetching = false;
					this.reset();
				}
			});
		}
		return Promise.resolve();
	}

	getCategoryById(id) {
		return this.bankCategoryMap[id];
	}

	getLabelById(id) {
		if (!id) return '';
		return this.bankCategoryLabels[id] ? this.bankCategoryLabels[id] : '';
	}

	_formatToOptions(bankRecordCategories) {
		return bankRecordCategories.map((bankRecordCategory) =>
			BankCategoryOption.createFromBankRecordCategory(bankRecordCategory)
		);
	}

	_createLookUpTable(categories) {
		return categories.reduce((acc, category) => {
			acc[category.id] = category.label;
			if (category.children.length > 0) {
				acc = {
					...acc,
					...this._createLookUpTable(category.children)
				};
			}
			return acc;
		}, {});
	}

	_flaten(categories) {
		return categories.reduce((acc, category) => {
			acc[category.value] = category;
			if (category.options.length > 0) {
				acc = {
					...acc,
					...this._flaten(category.options)
				};
			}
			return acc;
		}, {});
	}

	@action reset() {
		this.fetched = false;
		this.isFetching = false;
		super.reset();
	}
}
