import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ApplicationContext } from '../../../ApplicationContext';
import UserMessage from './UserMessage';

import SeenUserMessageCommand from '../../../commands/userMessages/SeenUserMessageCommand';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
class UserMessages extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.close = this.close.bind(this);
		this.onDotClick = this.onDotClick.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.setState({
			index: 0,
			userMessagesStore: this.context.applicationStore.userMessagesStore
		});
	}

	/**
	 *
	 * @param nextProps
	 * @param nextState
	 * @param nextContext
	 */
	componentWillUpdate(_nextProps, _nextState, _nextContext) {
		const { messages } = this.props;
		if (this.state.index !== 0 && this.state.index >= messages.length) {
			// eslint-disable-next-line react/no-will-update-set-state
			this.setState({ index: 0 });
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (!this.state.userMessagesStore) {
			return null;
		}

		const messages = this._generateMessages();
		const dots = this._generateDots();
		const classes = classNames({
			'user-messages': true,
			'user-messages--has-fewer': this.state.index > 0,
			'user-messages--has-more': this.state.index < this.props.messages.length - 1
		});

		return (
			<div className={classes}>
				<div className="user-messages__message-wrapper">
					<div className="user-messages__message-container">{messages}</div>
				</div>
				<div className="user-messages__dots grid">{dots}</div>
				<div
					className="user-messages__arrow-left icon icon--chevron-left-smoke"
					onClick={this.previous}
				/>
				<div
					className="user-messages__arrow-right icon icon--chevron-right-smoke"
					onClick={this.next}
				/>
				<div className="user-messages__close icon icon icon--close-black" onClick={this.close} />
			</div>
		);
	}

	/**
	 *
	 * @param event
	 */
	onDotClick(event) {
		this.state.index = parseInt(event.target.dataset.index, 10);
		this.update();
	}

	/**
	 *
	 */
	close() {
		const { messages } = this.props;
		// Mark all messages as seen
		messages.forEach((userMessage) => {
			const command = new SeenUserMessageCommand(userMessage, this.state.userMessagesStore);
			command.execute();
		});

		//
		Signals.HideUserMessagesDialog.dispatch();
	}

	/**
	 *
	 */
	next() {
		this.state.index++;
		this.update();
	}

	/**
	 *
	 */
	previous() {
		this.state.index--;
		this.update();
	}

	/**
	 *
	 */
	update() {
		const { messages } = this.props;
		// eslint-disable-next-line react/no-access-state-in-setstate
		let index = this.state.index;

		if (isNaN(index)) {
			index = 0;
		}

		if (index < 0) {
			index += messages.length;
		}

		if (index > messages.length - 1) {
			index -= messages.length;
		}

		this.setState({ index });
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_generateMessages() {
		const { messages } = this.props;
		return messages.map((userMessage, index) => {
			return (
				<div
					key={`mesc-${index}`}
					className={`user-messages__message ${
						index === this.state.index ? 'user-messages__message--selected' : ''
					}`}>
					<UserMessage message={userMessage} />
				</div>
			);
		});
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_generateDots() {
		const { messages } = this.props;
		return messages.map((image, index) => {
			return (
				<div
					key={`imgd-${index}`}
					className={`user-messages__dot ${
						index === this.state.index ? 'user-messages__dot--selected' : ''
					}`}
					data-index={index}
					onClick={this.onDotClick}
				/>
			);
		});
	}
}

UserMessages.contextType = ApplicationContext;

UserMessages.propTypes = {
	messages: PropTypes.array
};

UserMessages.defaultProps = {
	messages: []
};

export default injectIntl(UserMessages);
