import AbstractCommand from '../AbstractCommand';
import companyInvoiceSettingsUpdate from '../../requests/companyInvoiceSettingsUpdate';
import { convertTagsToTranslations } from '../../data/InvoiceDynamicValues';
import PaymentTerm from '../../data/PaymentTerm';

/**
 *
 */
export default class UpdateInvoiceSettingsCommand extends AbstractCommand {
	/**
	 *
	 * @param intl
	 * @param company
	 * @param settings Updated settings
	 */
	constructor(intl, company, settings) {
		super();

		this.intl = intl;
		this.data = {};
		this.company = company;
		this.settings = settings;
		this.invoicesSettings = this.company.invoicesSettings;

		this.setDefaults();
		this.applyCurrentSettings();
		this.applyUpdatedSettings();
	}

	/**
	 *
	 */
	setDefaults() {
		// Set default subject and messages if necessary
		this.data.logoAssetUuid = null;
		this.data.molliePaymentProfile = null;
		this.data.bccRecipientEmail = '';

		this.data.senderEmail = this.company.email;
		this.data.remindCompanyUserInDays = 0;
		this.data.remindCompanyCustomerInDays = PaymentTerm.FOURTEEN_DAYS.days;

		this.data.reminderMessage = this.intl.formatMessage({
			id: 'invoice.settings.remindermessage.default.nl'
		});
		this.data.reminderSubject = this.intl.formatMessage({
			id: 'invoice.settings.remindersubject.default.nl'
		});
		this.data.defaultInvoiceConceptEmailMessage = this.intl.formatMessage({
			id: 'invoice.settings.emailmessage.default.nl'
		});
		this.data.defaultInvoiceConceptEmailSubject = this.intl.formatMessage({
			id: 'invoice.settings.emailsubject.default.nl'
		});
		this.data.defaultNotes = this.intl.formatMessage({ id: 'invoice.settings.notes.default.nl' });

		this.data.reminderMessageEN = this.intl.formatMessage({
			id: 'invoice.settings.remindermessage.default.en'
		});
		this.data.reminderSubjectEN = this.intl.formatMessage({
			id: 'invoice.settings.remindersubject.default.en'
		});
		this.data.defaultInvoiceConceptEmailMessageEN = this.intl.formatMessage({
			id: 'invoice.settings.emailmessage.default.en'
		});
		this.data.defaultInvoiceConceptEmailSubjectEN = this.intl.formatMessage({
			id: 'invoice.settings.emailsubject.default.en'
		});
		this.data.defaultNotesEN = this.intl.formatMessage({ id: 'invoice.settings.notes.default.en' });
	}

	/**
	 *
	 */
	applyCurrentSettings() {
		// Update data, loop through setting fields and update `data` with values from `settings`, use `defaults` as fallback
		for (const key in this.company.invoicesSettings) {
			if (this.company.invoicesSettings.hasOwnProperty(key)) {
				// Is it a translation object? ingore, default is used instead
				if (typeof this.company.invoicesSettings[key] !== 'object') {
					if (key === 'senderEmail') {
						// Don't update if `senderEmail` is null or undefined, use the default instead
						if (this.company.invoicesSettings.senderEmail) {
							this.data[key] = this.company.invoicesSettings.senderEmail;
						}
					} else {
						this.data[key] = this.company.invoicesSettings[key];
					}
				}
			}
		}
	}

	/**
	 *
	 */
	applyUpdatedSettings() {
		const ignoreFields = ['errors', 'submitting'];
		const noTagFields = [
			'logoAssetUuid',
			'senderEmail',
			'remindCompanyUserInDays',
			'remindCompanyCustomerInDays'
		];

		// Update data, loop through setting fields and update `data` with values from `settings`
		for (const key in this.settings) {
			if (this.settings.hasOwnProperty(key) && !ignoreFields.includes(key)) {
				//
				if (noTagFields.includes(key)) {
					this.data[key] = this.settings[key];
					// Ignore 'objects' as they are the same as the defaults
				} else if (typeof this.settings[key] !== 'object') {
					this.data[key] = convertTagsToTranslations(this.settings[key]);
				}
			}
		}
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		companyInvoiceSettingsUpdate(this.company.id, JSON.stringify(this.data))
			.then((response) => {
				this._updateInvoiceSettings();

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 * Save data values to InvoiceSettings of Company
	 * @private
	 */
	_updateInvoiceSettings() {
		// Update company invoiceSettings
		const companyInvoiceSettings = this.company.invoicesSettings;
		for (const key in this.data) {
			if (this.data.hasOwnProperty(key)) {
				companyInvoiceSettings[key] = this.data[key];
			}
		}
	}
}
