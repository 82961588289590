import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class PanelFooter extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	render() {
		const { className } = this.props;
		const classes = classNames({ 'panel-footer': true });
		return <footer className={`${classes} ${className}`}>{this.props.children}</footer>;
	}
}

PanelFooter.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

PanelFooter.defaultProps = {
	className: ''
};
