/**
 * Created by hugo on 10/10/17.
 */
export default {
	GET: 'GET',
	POST: 'POST',
	DELETE: 'DELETE',
	PUT: 'PUT',
	PATCH: 'PATCH'
};
