import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';
import mollieProfilesGet from '../../requests/mollie/mollieProfilesGet';
import MollieProfile from '../../stores/models/MollieProfile';

/**
 *
 */
export default class GetMollieProfiles extends AbstractCommand {
	/**
	 * @param company Company
	 */
	constructor(company) {
		super();

		this.company = company;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('GetMollieProfiles.execute');

		mollieProfilesGet(this.company.id)
			.then((response) => {
				/**
			 profiles: [{name: "Hugo Dechesne", mode: "live", id: "pfl_7rVrfhjTm3", status: "verified"}]
				 0: {name: "Hugo Dechesne", mode: "live", id: "pfl_7rVrfhjTm3", status: "verified"}
			 */

				if (success) {
					const profiles = this.parseData(response.data.profiles);
					success(profiles);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}

				Signals.Error.dispatch(err);
			});
	}

	/**
	 *
	 */
	parseData(profiles) {
		return profiles.map((data) => {
			return new MollieProfile(data);
		});
	}
}
