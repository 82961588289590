import AbstractCommand from '../AbstractCommand';
import companyDraftsGet from '../../requests/drafts/companyDraftsGet';
import Draft from '../../stores/models/Draft';

/**
 *
 */
export default class GetCompanyDraftsCommand extends AbstractCommand {
	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param companyId Number
	 */
	constructor(draftsStore, companyId) {
		super();

		this.draftsStore = draftsStore;
		this.companyId = companyId;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return companyDraftsGet(this.companyId)
			.then((response) => {
				this._parseResult(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResult(response) {
		this.draftsStore.reset();
		const drafts = [];
		response.list.forEach((data) => {
			const draft = new Draft(data);
			drafts.push(draft);
		});

		this.draftsStore.drafts = drafts;
	}
}
