import { computed } from 'mobx';

export default class Model {
	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		this.update(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		if (!data) return;

		const keys = Object.keys(data);
		keys.forEach((key) => {
			// TODO: check if key is in this.attributes
			this[key] = data[key];
		});
	}

	/**
	 *
	 * @return {string}
	 */
	@computed get filterString() {
		let s = '';
		const keys = Object.keys(this);

		// Attributes
		keys.forEach((key) => {
			s += `${this[key]}\n`;
		});

		return s.toLowerCase();
	}

	/**
	 *
	 * @return {*}
	 */
	toString() {
		// Class name
		let s = this.constructor.name;
		const keys = Object.keys(this);

		// Attributes
		keys.forEach((key) => {
			s += `\n\t${key}: ${this[key]}`;
		});

		return s;
	}
}
