import React from 'react';
import { FormattedMessage } from 'react-intl';
import Page from '../../Page';
import FormattedMessageWithClass from '../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import PontoConnectModal from './PontoConnectModal';
import Signals from '../../../signals/Signals';
import Gadget from '../../../components/icons/Gadget';

function PontoOnboarding() {
	return (
		<Page pageName="onboarding">
			<div className="flex flex-col justify-center items-center mt-8">
				<Gadget className="mb-2" />
				<FormattedMessageWithClass
					className="text-2xl text-bold"
					id="company.bankbook.onboarding.text.header1"
				/>
				<FormattedMessageWithClass className="mt-3" id="company.bankbook.onboarding.text.header2" />
				<button
					type="button"
					className="button--primary mt-8"
					onClick={() => Signals.ShowModal.dispatch(<PontoConnectModal />)}>
					<FormattedMessage id="company.bankbook.onboarding.connect" />
				</button>
			</div>
		</Page>
	);
}

export default PontoOnboarding;
