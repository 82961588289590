import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import revokeOauthApplication from '../../requests/oauth/revokeOauthApplication';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class RevokeOauthApplication extends AbstractCommand {

	/**
	 * @param company Company
	 * @param application string
	 * @param authorizationStore AuthorizationStore
	 * @param intl object
	 */
	constructor(company, application, authorizationStore, intl) {
		super();

		this.company = company;
		this.application = application;
		this.authorizationStore = authorizationStore;
		this.intl = intl;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		revokeOauthApplication(this.company.id, this.application).then((response) => {
			this.authorizationStore.revokeAuthorization(this.application);

			// Show message
			Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="invoice.settings.integrations.revoke.success.message" values={{application: this.application}}/>);

			if(success) {
				success(response);
			}
		}).catch((err) => {
			if(error) {
				error(err);
			}
		});

	}
}
