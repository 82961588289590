export default class QuestionModel {
	/**
	 *
	 * @param checked
	 * @param files
	 * @param filesInfo
	 * @param meta
	 * @param disabled
	 */
	constructor(checked = undefined, files = [], filesInfo = [], meta = null, disabled = false) {
		this.checked = checked;
		this.files = files || []; // Contains File objects from client side
		this.filesInfo = filesInfo || []; // Contains File data from server side from already uploaded files
		this.meta = meta; // Contains any wildcard extra data that for questions which require extra data
		this.disabled = disabled; // Is this Question disabled buy default?

		this.removedFileIds = []; // Contains array of server side files which need to be removed
	}

	/**
	 *
	 * @param files
	 * @param filesInfo
	 * @param meta
	 */
	update(files, filesInfo, meta) {
		this.files = files || [];
		this.filesInfo = filesInfo || [];
		this.meta = meta || null;
	}

	/**
	 *
	 * @param file
	 */
	addFile(file) {
		this.files.push(file);
	}

	/**
	 *
	 * @param index
	 */
	removeFile(index) {
		// First check filesInfo, try to remove from there, add id to removedFileIds for sync purposes
		if (index < this.filesInfo.length) {
			// Add file id to removedFileIds list
			this.removedFileIds.push(this.filesInfo[index].id);

			// Remove file from uploaded filesInfo array
			this.filesInfo.splice(index, 1);

			// Remove from files array
		} else if (index - this.filesInfo.length < this.files.length) {
			this.files.splice(index - this.filesInfo.length, 1);
		}
	}

	/**
	 * Returns mixed array of File objects and faked File objects with `name` and `id`
	 * @returns {Array}
	 */
	getFiles() {
		const result = [];
		if (this.filesInfo) {
			this.filesInfo.forEach((file, index) => {
				result.push({ name: file.fileName, index, id: file.id });
			});
		}

		if (this.files) {
			this.files.forEach((file) => {
				result.push(file);
			});
		}

		return result;
	}

	/**
	 *
	 * @param formData
	 * @param baseName
	 */
	addToFormData(formData, baseName = 'question1') {
		if (this.checked != null && this.checked !== undefined) {
			formData.append(baseName, this.checked);
			formData.append(`${baseName}Meta`, this.meta);

			if (this.files) {
				this.files.forEach((file, index) => {
					formData.append(`${baseName}Files[${index}]`, file);
				});
			}
		}
	}

	/**
	 *
	 */
	isUnanswered() {
		return this.checked === null || this.checked === undefined;
	}

	/**
	 *
	 */
	isAnswered() {
		return !this.isUnanswered();
	}

	/**
	 *
	 * @param ignoreFiles
	 * @param requireMeta
	 * @return {*}
	 */
	isComplete(ignoreFiles = false, requireMeta = false) {
		// Doe this question have an answer set at all?
		if (typeof this.checked === 'boolean') {
			//! == undefined && this.checked !== null

			// question has answer
			let complete = true;

			// Does question need files?
			if (this.checked && !ignoreFiles) {
				complete = this.getFiles().length > 0;
			}

			// Doe question require meta data if answer is yes?
			if (this.checked && requireMeta) {
				complete = complete && this.meta !== null;
			}

			return complete;
		}

		// No answer has been set
		return false;
	}

	/**
	 *
	 * @return {string}
	 */
	toString() {
		return `QuestionModel existing:${this.filesInfo} / new: ${this.files} / removed:${this.removedFileIds} ]`;
	}
}
