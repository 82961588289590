/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../../signals/Signals';

import { getStateValue } from '../../../../utils/ReactUtils';

import CompanyBrandModel from '../../../../stores/models/CompanyBrandModel';

import { ApplicationContext } from '../../../../ApplicationContext';
import { FormErrorContext } from '../../../../FormErrorContext';

import CreateCompanyBrandCommand from '../../../../commands/brands/CreateCompanyBrandCommand';

import FormGroup from '../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../components/ui/FormField/FormField';
import FileUpload from '../../../../components/ui/FileUpload/FileUpload';
import { buildCompanyAssetURL } from '../../../../requests/companyAssetGet';
import CompanyAssetType from '../../../../data/CompanyAssetType';
import companyAssetCreate from '../../../../requests/companyAssetCreate';
import UpdateCompanyBrandCommand from '../../../../commands/brands/UpdateCompanyBrandCommand';
import IBANInput from '../../../../components/ui/IBANInput/IBANInput';

import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';

/**
 *
 */
class CompanyBrandInput extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			submitting: false
		};

		this.onRouteChanged = this.onRouteChanged.bind(this);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.onFileChange = this.onFileChange.bind(this);
		this.onAssetCreated = this.onAssetCreated.bind(this);

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.KeyDown.add(this.onKeyDown);
		Signals.OverlayBackgroundClick.add(this.onCancel);
		Signals.RouteChanged.add(this.onRouteChanged);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
		Signals.OverlayBackgroundClick.remove(this.onCancel);
		Signals.RouteChanged.remove(this.onRouteChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		// Check if logged in
		const { applicationStore } = this.context;
		if (!applicationStore.isLoggedIn) {
			return null;
		}

		const { companyBrand, updateLocalOnly, isNew } = this.props;
		const { errors } = this.state;
		const company = applicationStore.getSelectedCompany();
		const logoPreview = buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid);

		// Render
		const formClasses = classNames({ 'form--submitting': this.state.submitting });
		return (
			<div className="company-brand-input box-shadow-heavy">
				<FormErrorContext.Provider value={{ errors }}>
					<form className={formClasses} onSubmit={this.onSubmit} onChange={this.onInputChange}>
						<div className="company-brand-input__header padding border--bottom border--dark grid grid--spread">
							<h2 className="pad-left">
								{!updateLocalOnly && isNew ? (
									<FormattedMessage id="company.brand.add.title" />
								) : (
									<FormattedMessage id="company.brand.edit.title" />
								)}
							</h2>
							<div
								className="company-brand-input__close icon icon icon--close-black"
								onClick={this.onCancel}
							/>
						</div>

						<div className="company-brand-input__content padding-small">
							<FormGroup>
								<FormField className="col--12 ">
									<label>
										<FormattedMessage id="invoice.settings.logo" />
									</label>
									<FileUpload
										className="col--12"
										name="logo"
										fileName={logoPreview ? 'logo.png' : null}
										filePreview={logoPreview}
										showFilePreview
										accept=".png,.jpg,.jpeg"
										onChange={this.onFileChange}
										label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
									/>
								</FormField>

								<FormField className="col--12">
									<label>
										<FormattedMessage id="company.profile.field.name" />
									</label>
									<input
										name="name"
										type="text"
										value={getStateValue(this.state.name, companyBrand.name)}
										maxLength="255"
									/>
								</FormField>

								<FormField className="col--5">
									<label>
										<FormattedMessage id="company.profile.field.address" />
									</label>
									<input
										name="address"
										type="text"
										value={getStateValue(this.state.address, companyBrand.address)}
										maxLength="255"
									/>
								</FormField>

								<FormField className="col--3 company-info__postalcode-field">
									<label>
										<FormattedMessage id="company.profile.field.postalcode" />
									</label>
									<input
										name="postalCode"
										type="text"
										value={getStateValue(this.state.postalCode, companyBrand.postalCode)}
										maxLength="7"
									/>
								</FormField>

								<FormField className="col--4 company-info__city-field">
									<label>
										<FormattedMessage id="company.profile.field.city" />
									</label>
									<input
										name="city"
										type="text"
										value={getStateValue(this.state.city, companyBrand.city)}
										maxLength="255"
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.phonenumber" />
									</label>
									<input
										name="phoneNumber"
										type="tel"
										value={getStateValue(this.state.phoneNumber, companyBrand.phoneNumber)}
										maxLength="255"
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.email" />
									</label>

									<input
										name="email"
										ref={(ref) => (this.email = ref)}
										className="col--12"
										type="email"
										maxLength="255"
										autoComplete="off"
										value={getStateValue(this.state.email, companyBrand.email)}
										readOnly
										onFocus={() => this.email.removeAttribute('readonly')}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.website" />
									</label>
									<input
										name="website"
										className="col--12"
										type="text"
										maxLength="255"
										value={getStateValue(this.state.website, companyBrand.website)}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.kvknumber" />
									</label>
									<input
										name="kvkNr"
										className="col--12"
										type="text"
										maxLength="10"
										required
										value={getStateValue(this.state.kvkNr, companyBrand.kvkNr)}
									/>
								</FormField>

								<FormField className="col--6">
									<label>
										<FormattedMessage id="company.profile.field.iban" />
									</label>
									<IBANInput
										name="iban"
										value={getStateValue(this.state.iban, companyBrand.iban)}
									/>
								</FormField>
							</FormGroup>
						</div>

						<div className="options-footer border--top border--dark grid grid--spread">
							<button onClick={this.onCancel} className="button" type="button">
								<FormattedMessage id="label.cancel" />
							</button>
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</FormErrorContext.Provider>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'email':
				this.state[e.target.name] = `${e.target.value}`.trim();
				break;
			default:
				this.state[e.target.name] = e.target.value;
		}
		this.forceUpdate();
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 */
	onFileChange(file, fileName) {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', fileName);
			formData.append('type', CompanyAssetType.INVOICECONCEPT_LOGO);

			companyAssetCreate(company.id, formData)
				.then(this.onAssetCreated)
				.catch((error) => {
					console.log(error);
				});
		} else {
			this.setState({ logoAssetUuid: null });
		}
	}

	/**
	 *
	 * @param response
	 */
	onAssetCreated(response) {
		if (response.data) {
			this.setState({ logoAssetUuid: response.data.uuid });
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Modify data
		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'errors':
					case 'submitting':
						// Ignore
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		// Apply update fields to clone of companyBrand
		const { applicationStore } = this.context;
		const { companyBrand, updateLocalOnly, onUpdate, isNew } = this.props;
		const company = applicationStore.getSelectedCompany();
		const updatedCompanyBrand = companyBrand.clone();
		updatedCompanyBrand.update(data);

		this.setState({ errors: null, submitting: true });

		// Update supplied companybrand
		if (updateLocalOnly) {
			companyBrand.update(this.state);

			if (onUpdate) {
				onUpdate(companyBrand);
			}

			Signals.HideOverlay.dispatch();
			// Update existing, or create new
		} else if (!isNew) {
			const command = new UpdateCompanyBrandCommand(company, updatedCompanyBrand);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
			// Create new companybrand
		} else {
			const command = new CreateCompanyBrandCommand(company, updatedCompanyBrand);
			command.execute(this.onSubmitSuccess, this.onSubmitError);
		}
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		const { applicationStore } = this.context;
		const { companyBrandStore } = applicationStore;
		const { companyBrand, onUpdate, isNew } = this.props;

		// Update props model
		let updatedCompanyBrand = null;
		if (isNew) {
			companyBrand.update(response.data);
			companyBrandStore.add(companyBrand);
			Signals.CompanyBrandAdded.dispatch(companyBrand.id);
			updatedCompanyBrand = companyBrand;

			// Save in store
		} else {
			updatedCompanyBrand = companyBrandStore.find(companyBrand.id).update(this.state);
			companyBrandStore.update();
		}

		//
		if (onUpdate) {
			onUpdate(updatedCompanyBrand);
		}

		// Diplay MessageDialog
		if (isNew) {
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage id="company.brand.added.message" values={updatedCompanyBrand} />
			);
		} else {
			Signals.ShowMessageDialog.dispatch(
				<FormattedHTMLMessage id="company.brand.edited.message" values={updatedCompanyBrand} />
			);
		}

		// Reset state
		this.state = {
			errors: null
		};

		// Just hide overlay, keep submitting:true to prevent multiple entries on trigger happy user
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		console.log('onSubmitError', error);

		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 27:
				this.onCancel();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param e
	 */
	onCancel(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.hasChanged()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage({ id: 'company.brand.discard.alert' })}
					yesLabel={this.props.intl.formatMessage({ id: 'label.yes.discard' })}
					noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
					onConfirm={() => this.close()}
				/>
			);
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	onRouteChanged() {
		if (this.context.applicationStore.isLoggedIn) {
			this.onCancel();
		} else {
			this.close();
		}
	}

	/**
	 *
	 */
	close() {
		Signals.HideOverlay.dispatch();
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasChanged() {
		const { companyBrand } = this.props;
		const editedBrand = new CompanyBrandModel(companyBrand);
		editedBrand.update(this.state);
		return !companyBrand.isEqual(editedBrand);
	}
}

CompanyBrandInput.contextType = ApplicationContext;

CompanyBrandInput.propTypes = {
	intl: PropTypes.object,
	companyBrand: PropTypes.instanceOf(CompanyBrandModel),
	onUpdate: PropTypes.func,
	isNew: PropTypes.bool,
	updateLocalOnly: PropTypes.bool // Only update supplied companyBrand object
};

CompanyBrandInput.defaultProps = {};

const companyInput = injectIntl(CompanyBrandInput);

export default companyInput;
