import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { TweenLite, Cubic } from 'gsap';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
export default class WalkthroughPointer extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			expanded: false
		};

		this.el = null;

		this.onWalkthroughUpdatedPosition = this.onWalkthroughUpdatedPosition.bind(this);
		this.onWalkthroughPaused = this.onWalkthroughPaused.bind(this);
		this.onToggleStepList = this.onToggleStepList.bind(this);
		this.onStart = this.onStart.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.WalkthroughUpdatedPosition.add(this.onWalkthroughUpdatedPosition);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.WalkthroughUpdatedPosition.remove(this.onWalkthroughUpdatedPosition);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		if (applicationStore && applicationStore.isLoggedIn && !walkthroughStore.stopped) {
			const classes = classNames({
				'walkthrough-pointer': true,
				'walkthrough-pointer--showing':
					walkthroughStore.current > 0 &&
					walkthroughStore.current < walkthroughStore.steps.length - 1,
				'walkthrough-pointer--paused': !walkthroughStore.playing
			});

			return (
				<div className={classes} ref={(ref) => (this.el = ref)}>
					<div className="walkthrough-pointer__circle">
						<div className="walkthrough-pointer__pulse" />
					</div>
				</div>
			);
		}
		return null;
	}

	/**
	 *
	 */
	onStart() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;
		walkthroughStore.play();
	}

	/**
	 *
	 */
	onToggleStepList() {
		const { expanded } = this.state;
		this.setState({ expanded: !expanded });
	}

	/**
	 *
	 */
	onWalkthroughUpdatedPosition(x, y) {
		if (this.el) {
			TweenLite.to(this.el, 0.6, { x, y, ease: Cubic.easeInOut });
		}
	}

	/**
	 *
	 */
	onWalkthroughPaused() {
		this.setState({ expanded: false });
	}
}

WalkthroughPointer.contextType = ApplicationContext;

WalkthroughPointer.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore)
};

WalkthroughPointer.defaultProps = {};
