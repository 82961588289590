import AbstractCommand from '../AbstractCommand';
import companyBrandDelete from '../../requests/brands/companyBrandDelete';

/**
 *
 */
export default class DeleteCompanyBrandCommand extends AbstractCommand {
	/**
	 *
	 * @param company
	 * @param brandId
	 */
	constructor(company, brandId) {
		super();
		this.company = company;
		this.brandId = brandId;
	}

	/**
	 *
	 * @param successCallback
	 * @param failCallback
	 */
	execute(successCallback, failCallback) {
		companyBrandDelete(this.company.id, this.brandId)
			.then((response) => {
				successCallback(response);
			})
			.catch((err) => {
				if (failCallback) {
					failCallback(err);
				}
			});
	}
}
