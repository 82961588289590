/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import InvoiceDynamicValues, {
	convertTranslationsToTags
} from '../../../data/InvoiceDynamicValues';

import FormField from '../../../components/ui/FormField/FormField';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import TagTextArea, { TYPE_TEXTINPUT } from '../../../components/ui/TagTextArea/TagTextArea';

import { ApplicationContext } from '../../../ApplicationContext';
import { FormErrorContext } from '../../../FormErrorContext';

import UpdateInvoiceSettingsCommand from '../../../commands/invoiceConcepts/UpdateInvoiceSettingsCommand';

/**
 *
 */
class InvoicesSettingsEnglish extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		//
		this.dynamicTags = [];
		for (const key in InvoiceDynamicValues) {
			if (InvoiceDynamicValues.hasOwnProperty(key)) {
				this.dynamicTags.push(InvoiceDynamicValues[key]);
			}
		}
	}

	/**
	 *
	 */
	componentWillMount() {
		// Set initial state
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.state = company.invoicesSettings.toObject();
			this.state.errors = [];

			// Apply intl translations when object, and convert translation variables to InvoiceDynamicValues
			for (const key in this.state) {
				if (this.state.hasOwnProperty(key)) {
					switch (key) {
						// Convert translations / store strings to TagTextarea formatted strings
						case 'defaultInvoiceConceptEmailMessageEN':
						case 'defaultInvoiceConceptEmailSubjectEN':
						case 'reminderMessageEN':
						case 'reminderSubjectEN':
						case 'defaultNotesEN':
							if (typeof this.state[key] === 'object') {
								this.state[key] = convertTranslationsToTags(
									this.props.intl.formatMessage(this.state[key])
								);
							} else {
								this.state[key] = convertTranslationsToTags(this.state[key]);
							}
							break;
						default:
							break;
					}
				}
			}
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();

		if (!this.context.applicationStore.isLoggedIn || !company) {
			return null;
		}

		//
		const { intl } = this.props;
		const { submitting, errors } = this.state;
		const formClasses = classNames({ 'form--submitting': submitting });

		return (
			<FormErrorContext.Provider value={{ errors }}>
				<form
					className={`invoices-settings__panel panel grid col--12 box-shadow border border--dark ${formClasses}`}
					onSubmit={this.onSubmit}>
					<div className="invoices-settings__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="invoices.settings.title.english" />
						</h2>
					</div>

					<div className="invoices-settings__content">
						{this._getSendSettings()}
						{this._getRemindSettings()}
						{this._getNotes()}

						<div className="options-footer invoices-settings__footer border--top border--dark grid grid--spread">
							<div className="grid user-info__footer-buttons" />
							<input type="submit" value={intl.formatMessage({ id: 'label.save' })} />
						</div>
					</div>
				</form>
			</FormErrorContext.Provider>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const newState = {};
		newState[e.target.name] = e.target.value;
		this.setState(newState);
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		this.setState({ errors: null, submitting: true });

		const company = this.context.applicationStore.getSelectedCompany();
		const command = new UpdateInvoiceSettingsCommand(this.props.intl, company, this.state);
		command.execute(this.onSubmitSuccess, this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(_response) {
		// Display success message
		Signals.ShowMessageDialog.dispatch(
			<FormattedMessage id="invoice.settings.update.success.message" />
		);

		// Reset state
		this.setState({
			submitting: false,
			errors: null
		});
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @private
	 */
	_getSendSettings() {
		return (
			<FormGroup className="padding padding-none--bottom">
				<FormField>
					<h2 className="font--title">
						<FormattedMessage id="invoice.settings.subtitle.send" />
					</h2>
				</FormField>
				<FormField className="col--12 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.invoiceconcept.subject.title" />
					</label>
					<TagTextArea
						name="defaultInvoiceConceptEmailSubjectEN"
						type={TYPE_TEXTINPUT}
						value={this.state.defaultInvoiceConceptEmailSubjectEN}
						tags={this.dynamicTags}
						onChange={this.onInputChange}
					/>
				</FormField>

				<FormField className="col--12 margin-none--bottom">
					<label>
						<FormattedMessage id="invoice.settings.invoiceconcept.message.title" />
					</label>
					<TagTextArea
						name="defaultInvoiceConceptEmailMessageEN"
						value={this.state.defaultInvoiceConceptEmailMessageEN}
						tags={this.dynamicTags}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @private
	 */
	_getRemindSettings() {
		return (
			<FormGroup className="padding padding-none--bottom">
				<FormField>
					<h2 className="font--title ">
						<FormattedMessage id="invoice.settings.subtitle.remind" />
					</h2>
				</FormField>
				<FormField className="col--12 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.invoiceconcept.remindersubject.title" />
					</label>
					<TagTextArea
						name="reminderSubjectEN"
						type={TYPE_TEXTINPUT}
						value={this.state.reminderSubjectEN}
						tags={this.dynamicTags}
						onChange={this.onInputChange}
					/>
				</FormField>

				<FormField className="col--12 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.remind.message.title" />
					</label>
					<TagTextArea
						name="reminderMessageEN"
						value={this.state.reminderMessageEN}
						tags={this.dynamicTags}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getNotes() {
		return (
			<FormGroup className="padding">
				<FormField>
					<h2 className="font--title ">
						<FormattedMessage id="invoice.settings.notes" />
					</h2>
				</FormField>

				<FormField className="col--12 form-field__padding-bottom">
					<label>
						<FormattedMessage id="invoice.settings.defaultnotes.message.title" />
					</label>
					<textarea
						name="defaultNotesEN"
						value={this.state.defaultNotesEN}
						onChange={this.onInputChange}
					/>
				</FormField>
			</FormGroup>
		);
	}
}

InvoicesSettingsEnglish.contextType = ApplicationContext;

InvoicesSettingsEnglish.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(InvoicesSettingsEnglish);
