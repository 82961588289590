import { observable, action, computed } from 'mobx';

import { PROGRESS_STATUS } from './models/ProgressStatusModel';

/**
 *
 */
export default class ProgressStatusStore {
	@observable list = [];

	/**
	 *
	 * @return {Array}
	 */
	@computed get sorted() {
		return this.list.sort(ProgressStatusStore._sort);
	}

	/**
	 *
	 */
	@action reset() {
		this.list = [];
	}

	/**
	 * progressStatusModel ProgressStatusModel
	 */
	@action add(progressStatusModel) {
		this.list.push(progressStatusModel);
	}

	/**
	 *
	 * @param progressStatusModel ProgressStatusModel
	 */
	@action remove(progressStatusModel) {
		const index = this.list.findIndex((model) => model === progressStatusModel);
		if (index >= 0) {
			this.list.splice(index, 1);
		}
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get percentage() {
		let totalProgress = 0;
		this.list.forEach((progressStatusModel) => {
			totalProgress += progressStatusModel.progress;
		});

		return totalProgress / this.list.length;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get incompleteCount() {
		return this.list.filter((progressModel) => {
			return progressModel.isWaiting() || progressModel.isBusy();
		}).length;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get completeCount() {
		return this.list.filter((progressModel) => {
			return progressModel.isDone();
		}).length;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @return {number}
	 * @private
	 */
	static _sort(a, b) {
		return ProgressStatusStore._getSortWeight(a) - ProgressStatusStore._getSortWeight(b);
	}

	/**
	 *
	 * @param worker
	 * @return {number}
	 * @private
	 */
	static _getSortWeight(worker) {
		switch (worker.state) {
			case PROGRESS_STATUS.STARTED:
				return 1;
			case PROGRESS_STATUS.FAILED:
				return 2;
			case PROGRESS_STATUS.WAITING:
				return 3;
			case PROGRESS_STATUS.SUCCESS:
				return 4;
			default:
				return 5;
		}
	}
}
