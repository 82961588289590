import AbstractCommand from '../AbstractCommand';
import moveDraftsToYear from '../../requests/drafts/moveDraftsToYear';

/**
 *
 */
export default class MoveDraftsToYearCommand extends AbstractCommand {

	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param draftIds Array
	 * @param fromFinancialYearId Number
	 * @param toFinancialYearId Number
	 */
	constructor(draftsStore, draftIds, fromFinancialYearId, toFinancialYearId) {
		super();

		this.draftsStore = draftsStore;
		this.draftIds = draftIds;
		this.fromFinancialYearId = fromFinancialYearId;
		this.toFinancialYearId = toFinancialYearId;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		moveDraftsToYear(this.fromFinancialYearId, this.toFinancialYearId, this.draftIds).then((response) => {

			this.draftIds.forEach((id) => {
				const draft = this.draftsStore.find('id', id);
				draft.boekjaarId = this.toFinancialYearId;
				// this.draftsStore.remove(draft);
			});

			if(success) {
				success(response);
			}
		}).catch((err) => {
			console.log(err);
			if(error) {
				error(err);
			}
		});
	}
}
