import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import Signals from '../../../signals/Signals';

import { RouteUtil, Routes } from '../../../data/Routes';
import Breakpoints from '../../../data/Breakpoints';

import MainMenuCompany from './MainMenuCompany';
import MainMenuProfileSettings from './MainMenuProfileSettings';
import MainMenuCompanySettings from './MainMenuCompanySettings';
import MainMenuUser from './MainMenuUser';

import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
class MainMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.state = { opened: false };
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;

		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);

		Signals.RootClick.add(this.closeMenu);
	}

	/**
	 *
	 */
	render() {
		const isCompany = RouteUtil.isCompanyPath(this.props.location.pathname);
		const isUser = RouteUtil.isUserPath(this.props.location.pathname);
		const currentRoute = RouteUtil.getRoute(this.props.location.pathname);

		let menuContent = '';

		const isMobile = window.innerWidth < Breakpoints.TABLET;
		const menuItemWrapperClasses = classNames({ 'box-shadow': this.state.opened && isMobile });
		const menuItemClasses = classNames({
			'border--bottom border--dark': this.state.opened && isMobile
		});
		const menuClasses = classNames({ 'main-menu--opened': this.state.opened });

		// Company settings
		if (currentRoute === Routes.COMPANY_SETTINGS_INFO) {
			const company = this.context.applicationStore.getSelectedCompany(true);
			const params = { id: company ? company.id : null, companyId: company ? company.id : null };

			menuContent = company ? (
				<MainMenuCompanySettings
					menuItemWrapperClasses={menuItemWrapperClasses}
					menuItemClasses={menuItemClasses}
					params={params}
				/>
			) : null;

			//	Profile settings
		} else if (currentRoute === Routes.COMPANY_SETTINGS_COMMUNITY_PROFILE) {
			const company = this.context.applicationStore.getSelectedCompany(true);
			const params = { id: company ? company.id : null, companyId: company ? company.id : null };

			menuContent = company ? (
				<MainMenuProfileSettings
					menuItemWrapperClasses={menuItemWrapperClasses}
					menuItemClasses={menuItemClasses}
					params={params}
				/>
			) : null;
		}

		// Company
		else if (isCompany) {
			const company = this.context.applicationStore.getSelectedCompany(true);
			const financialYear = this.context.applicationStore.getSelectedFinancialYear();
			const params = {
				id: company ? company.id : null,
				companyId: company ? company.id : null,
				year: financialYear ? financialYear.id : null
			};

			menuContent = company ? (
				<MainMenuCompany
					menuItemWrapperClasses={menuItemWrapperClasses}
					menuItemClasses={menuItemClasses}
					params={params}
				/>
			) : null;

			// User
		} else if (isUser) {
			const company = this.context.applicationStore.getSelectedCompany(true);
			const params = { id: company ? company.id : null, companyId: company ? company.id : null };

			menuContent = (
				<MainMenuUser
					menuItemWrapperClasses={menuItemWrapperClasses}
					menuItemClasses={menuItemClasses}
					params={params}
				/>
			);
		}

		//
		return (
			<nav className={`main-menu ${menuClasses}`}>
				<div className="hamburger-icon" onClick={this.toggleMenu} />

				{menuContent}
			</nav>
		);
	}

	/**
	 *
	 */
	toggleMenu() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ opened: !this.state.opened });
	}

	/**
	 *
	 */
	closeMenu() {
		if (this.state.opened) {
			this.setState({ opened: false });
		}
	}
}

MainMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default injectIntl(withRouter(MainMenu));

MainMenu.contextType = ApplicationContext;
