import AbstractCommand from '../AbstractCommand';
import invoiceConceptsGet from '../../requests/invoiceConceptsGet';
import InvoiceConceptModel from '../../stores/models/InvoiceConceptModel';

/**
 *
 */
export default class GetInvoicesConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param invoicesConceptStore InvoicesConceptStore
	 * @param companyId Number
	 */
	constructor(invoicesConceptStore, companyId) {
		super();

		this.invoicesConceptStore = invoicesConceptStore;
		this.companyId = companyId;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		// When request already executing, don't execute again
		if (this.invoicesConceptStore.fetching) {
			return this.invoicesConceptStore.fetching;
		}
		this.invoicesConceptStore.fetching = invoiceConceptsGet(this.companyId);
		this.invoicesConceptStore.fetching
			.then((response) => {
				this._parseResult(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});

		return this.invoicesConceptStore.fetching;
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResult(response) {
		this.invoicesConceptStore.reset();
		const invoices = [];
		response.list.forEach((data) => {
			invoices.push(InvoiceConceptModel.parseInvoiceConceptData(data));
		});

		this.invoicesConceptStore.addAll(invoices);
	}
}
