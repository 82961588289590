import EndPoints from '../data/EndPoints';

/**
 *
 * @param assetUuid
 * @param width
 * @param height
 * @return {string|null}
 */
export function buildAssetURL(assetUuid, width = null, height = null) {
	if (!assetUuid) {
		return null;
	}

	let url = window.config.apiPrefix + EndPoints.ASSETS_UUID.replace(':uuid', assetUuid);

	if (width) {
		if (url.indexOf('?') <= 0) {
			url += '?';
		}

		url += `width=${width}`;
	}

	if (height) {
		if (url.indexOf('?') <= 0) {
			url += '?';
		} else {
			url += '&';
		}

		url += `height=${height}`;
	}

	return url;
}

/**
 *
 * @param companyId
 * @param uuid
 * @return {*}
 */
export function buildCompanyAssetURL(companyId, uuid) {
	if (!companyId || !uuid) {
		return null;
	}

	return (
		window.config.apiPrefix +
		EndPoints.COMPANY_ASSETS_UUID.replace(':id', companyId).replace(':uuid', uuid)
	);
}

/**
 * @param accessUuid
 * @param assetUuid
 * @return {*}
 */
export function buildPublicAssetURL(accessUuid, assetUuid) {
	if (!accessUuid || !assetUuid) {
		return null;
	}

	return (
		window.config.apiPrefix +
		EndPoints.PUBLIC_ASSETS_UUID.replace(':assetUuid', assetUuid).replace(':uuid', accessUuid)
	);
}

/**
 *
 * @param accessUuid
 * @return {string|null}
 */
export function buildPublicOfferPdfURL(accessUuid) {
	if (!accessUuid) {
		return null;
	}

	return window.config.apiPrefix + EndPoints.PUBLIC_OFFER_PDF.replace(':uuid', accessUuid);
}
/**
 *
 * @param accessUuid
 * @return {string|null}
 */
export function buildPublicInvoicePdfURL(accessUuid) {
	if (!accessUuid) {
		return null;
	}

	return window.config.apiPrefix + EndPoints.PUBLIC_INVOICE_PDF.replace(':uuid', accessUuid);
}
