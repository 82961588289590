import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (email) => {
	const params = { email };
	const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	return fetch(Methods.POST, EndPoints.FORGOT_PASSWORD, params, headers);
};
