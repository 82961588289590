/**
 *
 */
export default class AutocompleteFieldData {
	name = null;

	searchQuery = null;

	/**
	 *
	 * @param name
	 * @param searchQuery
	 */
	constructor(name = null, searchQuery = null) {
		this.name = name;
		this.searchQuery = searchQuery;
	}
}
