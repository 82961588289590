import CompanyProperties from './CompanyProperties';
import PropertyType from './PropertyType';
import RouteObject from './RouteObject';

/**
 *
 * @type {{COMPANY_OFFERS_CLIENT: RouteObject, COMPANY_DASHBOARD: RouteObject, USER_MESSAGES: RouteObject, COMPANY_INVOICES_INVOICE: RouteObject, COMPANY_DEADLINES: RouteObject, COMPANY_INVOICES_OFFER: RouteObject, COMPANY_OFFERS_CLIENTS: RouteObject, USER_SETTINGS: RouteObject, COMPANY_OFFERS_EDIT: RouteObject, COMMUNITY_OVERVIEW: RouteObject, COMPANY_INVOICES_CLIENT: RouteObject, COMPANY_INVOICES_ARCHIVE: RouteObject, COMPANY_INVOICES_EDIT: RouteObject, COMPANY_INVOICES: RouteObject, USER_TAX_RETURN: RouteObject, COMPANY_INVOICES_ALL: RouteObject, COMPANY_BOOKKEEPING: RouteObject, COMPANY_OFFERS_ARCHIVE: RouteObject, COMPANY_OFFERS_DUPLICATE: RouteObject, COMPANY_REVENUES: RouteObject, FORGOT_PASSWORD: RouteObject, COMPANY_SETTINGS_INFO: RouteObject, COMPANY_SETTINGS_INVOICES: RouteObject, LOGIN: RouteObject, COMPANY_OFFERS: RouteObject, COMPANY_INVOICES_DUPLICATE: RouteObject, ON_BOARDING: RouteObject, COMPANY_COMMUNITY_PROFILE: RouteObject, STYLEGUIDE: RouteObject, EXPIRED_UUID: RouteObject, USER_DASHBOARD: RouteObject, COMPANY_OFFERS_ALL: RouteObject, COMPANY_SETTINGS_OFFERS: RouteObject, RESET_PASSWORD: RouteObject, COMPANY_SETTINGS: RouteObject, COMPANY_DOCUMENTS: RouteObject, USER_SETTINGS_PASSPORT: RouteObject, COMPANY_SETTINGS_COMMUNITY_PROFILE: RouteObject, USER_SETTINGS_INFO: RouteObject, USER_SETTINGS_CHANGEPASSWORD: RouteObject, COMPANY_INVOICES_CLIENTS: RouteObject, COMPANY_EXPENSES: RouteObject, COMPANY_OFFERS_OFFER: RouteObject, COMPANY_RESULTS: RouteObject, USER_DOCUMENTS: RouteObject, HOME: RouteObject}}
 */
export const Routes = {
	HOME: new RouteObject('/', false),

	LOGIN: new RouteObject('/login', false, true),
	FORGOT_PASSWORD: new RouteObject('/forgotpassword', false, true),
	RESET_PASSWORD: new RouteObject('/resetpassword/:uuid', false, true),
	EXPIRED_UUID: new RouteObject('/expired/:uuid', false, true),

	COMPANY_OFFERS_CLIENT: new RouteObject('/client/quote/:uuid', false, false, true),
	COMPANY_INVOICES_CLIENT: new RouteObject('/client/invoice/:uuid', false, false, true),

	ON_BOARDING: new RouteObject('/on-boarding'),
	POLICY_TERMS: new RouteObject('/policy-terms'),

	USER_DOCUMENTS: new RouteObject('/user/documents'),
	COMPANY_DOCUMENTS: new RouteObject('/company/:id/documents'),
	COMPANY_PAYROLL_DOCUMENTS: new RouteObject('/company/:id/documents/payroll'),

	USER_MESSAGES: new RouteObject('/messages'),
	USER_DASHBOARD: new RouteObject('/user/dashboard'),
	USER_TAX_RETURN: new RouteObject('/user/taxreturn'),

	COMPANY_DASHBOARD: new RouteObject('/company/:id/dashboard'),
	COMPANY_BOOKKEEPING: new RouteObject('/company/:id/bookkeeping'),

	COMPANY_INVOICES: new RouteObject('/company/:id/invoices'),
	COMPANY_INVOICES_ALL: new RouteObject('/company/:id/invoices/all'),
	COMPANY_INVOICES_ARCHIVE: new RouteObject('/company/:id/invoices/archive'),
	COMPANY_INVOICES_EDIT: new RouteObject('/company/:id/invoices/edit/:invoiceId'),
	COMPANY_INVOICES_DUPLICATE: new RouteObject('/company/:id/invoices/duplicate/:invoiceId'),
	COMPANY_INVOICES_OFFER: new RouteObject('/company/:id/invoices/quote/:offerId'),
	COMPANY_INVOICES_INVOICE: new RouteObject('/company/:id/invoices/invoice/:invoiceId'),
	COMPANY_INVOICES_CLIENTS: new RouteObject('/company/:id/invoices/clients'),

	COMPANY_INVOICES_PERIODIC: new RouteObject('/company/:id/invoices/periodic'),
	COMPANY_INVOICES_PERIODIC_ALL: new RouteObject('/company/:id/invoices/periodic/all'),
	COMPANY_INVOICES_PERIODIC_EDIT: new RouteObject('/company/:id/invoices/periodic/edit/:invoiceId'),
	COMPANY_INVOICES_PERIODIC_INVOICE: new RouteObject(
		'/company/:id/invoices/periodic/invoice/:invoiceId'
	),

	COMPANY_OFFERS: new RouteObject('/company/:id/quotes'),
	COMPANY_OFFERS_ALL: new RouteObject('/company/:id/quotes/all'),
	COMPANY_OFFERS_ARCHIVE: new RouteObject('/company/:id/quotes/archive'),
	COMPANY_OFFERS_EDIT: new RouteObject('/company/:id/quotes/edit/:offerId'),
	COMPANY_OFFERS_DUPLICATE: new RouteObject('/company/:id/quotes/duplicate/:offerId'),
	COMPANY_OFFERS_OFFER: new RouteObject('/company/:id/quotes/quote/:offerId'),
	COMPANY_OFFERS_CLIENTS: new RouteObject('/company/:id/quotes/clients'),

	COMPANY_RESULTS: new RouteObject('/company/:id/bookkeeping/:year/results'),
	COMPANY_REVENUES: new RouteObject('/company/:id/bookkeeping/:year/revenues'),
	COMPANY_EXPENSES: new RouteObject('/company/:id/bookkeeping/:year/expenses'),
	COMPANY_BANK: new RouteObject(
		'/company/:id/bookkeeping/:year/bank',
		true,
		false,
		false,
		null,
		true,
		{ value: CompanyProperties.FEATURE_BANKBOOK, type: PropertyType.COMPANY }
	),
	COMPANY_PONTO_ONBOARDING: new RouteObject(
		'/company/:id/bookkeeping/:year/bank/onboarding',
		true,
		false,
		false,
		null,
		true,
		{ value: CompanyProperties.FEATURE_BANKBOOK, type: PropertyType.COMPANY }
	),
	COMPANY_PONTO_CALLBACK: new RouteObject('/ponto/callback', true, false, false, null, true, {
		value: CompanyProperties.FEATURE_BANKBOOK,
		type: PropertyType.COMPANY
	}),
	COMPANY_PONTO_SETTINGS: new RouteObject(
		'/company/:id/bookkeeping/:year/bank/settings',
		true,
		false,
		false,
		null,
		true,
		{ value: CompanyProperties.FEATURE_BANKBOOK, type: PropertyType.COMPANY }
	),
	COMPANY_DEADLINES: new RouteObject('/company/:id/bookkeeping/:year/deadlines'),

	COMMUNITY_OVERVIEW: new RouteObject('/company/:id/profiles'),

	COMPANY_SETTINGS: new RouteObject('/company/settings'),
	COMPANY_SETTINGS_COMMUNITY_PROFILE: new RouteObject('/company/settings/:id/profiles/:profileId'),
	COMPANY_SETTINGS_INFO: new RouteObject('/company/settings/:id/info'),

	COMPANY_SETTINGS_INVOICES: new RouteObject('/company/:id/invoices/settings'),
	COMPANY_SETTINGS_OFFERS: new RouteObject('/company/:id/quotes/settings'),

	COMPANY_COMMUNITY_PROFILE: new RouteObject('/company/:id/profiles/:profileId'),

	USER_SETTINGS: new RouteObject('/user/settings'),
	USER_SETTINGS_INFO: new RouteObject('/user/settings/info'),
	USER_SETTINGS_CHANGEPASSWORD: new RouteObject('/user/settings/changepassword'),
	USER_SETTINGS_PASSPORT: new RouteObject('/user/settings/passport'),

	STYLEGUIDE: new RouteObject('/styleguide/', false)
};

/**
 *
 * @type {{_routes: *[], isUserPath(*): *, getRoute(*): (null|*), isCompanyPath(*): *}}
 */
export const RouteUtil = {
	_routes: Object.keys(Routes).map((key) => Routes[key]),

	/**
	 *
	 * @param path
	 * @return {*}
	 */
	getRoute(path) {
		if (path == null) {
			return null;
		}

		const segments = path.toLowerCase().split('/');
		let result = null;

		// Check all routes for match
		this._routes.forEach((route) => {
			const routeSegments = route.pathName.split('/');

			// Can only match if equal number of segments
			if (routeSegments.length === segments.length) {
				let isMatch = true;

				// Compare segments
				routeSegments.forEach((routeSegment, index) => {
					// Ignore if parameter
					if (routeSegment.indexOf(':') < 0) {
						if (routeSegment !== segments[index]) {
							isMatch = false;
							return isMatch;
						}
					}
				});

				// Set result if match
				if (isMatch) {
					result = route;

					// Stop forEach loop
					return false;
				}
			}
		});

		return result;
	},

	/**
	 *
	 * @param path
	 * @return {boolean}
	 */
	isMessages(path) {
		return path.indexOf('/messages') >= 0;
	},

	/**
	 *
	 * @param path
	 * @return {boolean}
	 */
	isDocuments(path) {
		return path.indexOf('/documents/') >= 0;
	},

	/**
	 *
	 * @param path
	 * @return {boolean}
	 */
	isUserPath(path) {
		return path.indexOf('/user') >= 0 && path.indexOf('/company/') < 0;
	},

	/**
	 *
	 * @param path
	 * @return {boolean}
	 */
	isCompanyPath(path) {
		return (
			path.indexOf('/company') >= 0 && path.indexOf('/messages') < 0 && path.indexOf('/user/') < 0
		);
	},

	/**
	 *
	 * @param path
	 * @return {boolean}
	 */
	shouldHeaderRender(path) {
		return path.indexOf('policy-terms') === -1;
	}
};
