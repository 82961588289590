import { useCallback, useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../ApplicationContext';
import Signals from '../signals/Signals';
// eslint-disable-next-line no-unused-vars
import FinancialYear from '../stores/models/FinancialYear';

/**
 *
 * @returns {FinancialYear}
 */
const useFiscalYear = () => {
	const [fiscalYear, setFiscalYear] = useState(null);
	const { applicationStore } = useContext(ApplicationContext);

	const onFiscalYearChanged = useCallback(() => {
		setFiscalYear(applicationStore.getSelectedFinancialYear());
	}, [applicationStore]);

	useEffect(() => {
		onFiscalYearChanged();
		Signals.FinancialYearChanged.add(onFiscalYearChanged);
		return () => {
			Signals.FinancialYearChanged.remove(onFiscalYearChanged);
		};
	}, [applicationStore, onFiscalYearChanged]);

	return fiscalYear;
};

export default useFiscalYear;
