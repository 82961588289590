import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId, invoiceId, data) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		`${EndPoints.COMPANIES_INVOICE_CONCEPT_ID.replace(':id', companyId).replace(
			':invoiceconceptid',
			invoiceId
		)}?additionalAction=send_email`,
		data,
		headers,
		true
	);
};
