import React from 'react';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

const MESSAGE_DIALOG_DURATION = 4000;
const MESSAGE_UPDATE_INTERVAL = 1000 / 60;

export default class MessageDialog extends React.Component {
	constructor(props) {
		super(props);

		// eslint-disable-next-line no-unused-expressions
		this.timer;
		// eslint-disable-next-line no-unused-expressions
		this.timerUpdateInterval;
		// eslint-disable-next-line no-unused-expressions
		this.hideDelay;

		this.state = {
			showMessageDialog: false,
			content: null
		};

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.updateTimer = this.updateTimer.bind(this);
		this.onClose = this.onClose.bind(this);
	}

	componentWillMount() {
		Signals.ShowMessageDialog.add(this.show);
		Signals.HideMessageDialog.add(this.hide);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;

		Signals.ShowMessageDialog.remove(this.show);
		Signals.HideMessageDialog.remove(this.hide);
	}

	render() {
		const classes = classNames({
			'message-dialog--show': this.state.showMessageDialog,
			'message-dialog--hide': !this.state.showMessageDialog
		});

		return (
			<div className={`message-dialog ${classes} grid grid--spread`}>
				<div
					className="message-dialog__timer"
					ref={(ref) => {
						this.timer = ref;
					}}
				/>
				<div className="message-dialog__content-wrapper">{this.state.content}</div>
				<div className="message-dialog__close icon icon--close-white" onClick={this.onClose} />
			</div>
		);
	}

	onClose() {
		Signals.HideMessageDialog.dispatch();
	}

	show(content) {
		this.state.content = content;
		this.state.showMessageDialog = true;

		clearTimeout(this.hideTimeout);
		this.startTimer();

		if (this._isMounted) {
			this.forceUpdate();
		}
	}

	hide() {
		if (this.state.showMessageDialog) {
			this.resetTimer();

			this.state.showMessageDialog = false;

			this.hideTimeout = setTimeout(() => {
				this.state.content = null;
				this.forceUpdate();
			}, 300);

			if (this._isMounted) {
				this.forceUpdate();
			}
		}
	}

	startTimer() {
		this.resetTimer();
		this.hideDelay = setTimeout(this.hide, MESSAGE_DIALOG_DURATION);
		this.timerUpdateInterval = setInterval(this.updateTimer, MESSAGE_UPDATE_INTERVAL);
		this.timeLeft = MESSAGE_DIALOG_DURATION;
	}

	resetTimer() {
		clearTimeout(this.hideDelay);
		clearInterval(this.timerUpdateInterval);
	}

	updateTimer() {
		this.timeLeft -= MESSAGE_UPDATE_INTERVAL;
		let percentage = (this.timeLeft / MESSAGE_DIALOG_DURATION) * 100;
		percentage = Math.max(percentage, 0);
		this.timer.style.width = `${percentage}%`;
	}
}
