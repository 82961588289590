import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import classNames from 'classnames';

import CompanyCustomer from '../../../stores/models/CompanyCustomer';

import { ApplicationContext } from '../../../ApplicationContext';
import GetCountriesCommand from '../../../commands/GetCountriesCommand';
import { getTranslations } from '../../../utils/Translations';

@observer
/**
 *
 */
export default class ClientAddress extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentWillMount() {
		const { applicationStore } = this.context;
		if (applicationStore.isLoggedIn) {
			const company = applicationStore.getSelectedCompany();
			const command = new GetCountriesCommand(company, applicationStore.countryStore);
			command.execute();
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { companyCustomer, language, onEdit } = this.props;

		// If no customer is found at all, don't render
		if (!companyCustomer) {
			return null;
		}

		const lang = language || applicationStore.user.language;
		const edit =
			onEdit && applicationStore.user ? (
				<IntlProvider
					locale={applicationStore.user.language}
					messages={getTranslations(applicationStore.user.language)}>
					<div className="client-address__edit link link--primary col--12" onClick={onEdit}>
						<FormattedMessage id="client.address.edit" />
					</div>
				</IntlProvider>
			) : null;

		return (
			<div className="client-address grid">
				<div
					className={`client-address__line client-address__company-name col--12 ${classNames({
						'client-address__line--hidden': companyCustomer.privatePerson,
						'client-address__line--bold': !companyCustomer.privatePerson
					})}`}>
					{companyCustomer.companyName}
				</div>

				<div
					className={`client-address__line client-address__person-name col--12 ${classNames({
						'client-address__line--bold': companyCustomer.privatePerson
					})}`}>
					{companyCustomer.contactPersonName}
				</div>

				<div className="client-address__line client-address__address col--12">
					{companyCustomer.address}
				</div>
				<div className="client-address__line client-address__postalcode col--12">
					{companyCustomer.postalCode} {companyCustomer.city}
				</div>
				<div className="client-address__line client-address__country col--12">
					{this._getCountryName(companyCustomer, lang)}
				</div>

				<div
					className={`client-address__line client-address__fill col--12 ${classNames({
						'client-address__line--hidden':
							companyCustomer.privatePerson ||
							!(companyCustomer.btwNr && companyCustomer.btwNr.length > 0)
					})}`}>
					&nbsp;
				</div>
				<div
					className={`client-address__line client-address__btw col--12 ${classNames({
						'client-address__line--hidden':
							companyCustomer.privatePerson ||
							!(companyCustomer.btwNr && companyCustomer.btwNr.length > 0)
					})}`}>
					<FormattedMessage id="company.address.label.vat" />
					{companyCustomer.btwNr}
				</div>

				{edit}
			</div>
		);
	}

	/**
	 *
	 * @param companyCustomer
	 * @param lang
	 * @return {string|*}
	 * @private
	 */
	_getCountryName(companyCustomer, lang) {
		const country = this.context.applicationStore.countryStore.getCountryByISO(
			companyCustomer.countryCode
		);
		if (country) {
			return country.getName(lang);
		}
		if (companyCustomer.country && companyCustomer.country.name) {
			return companyCustomer.country.name[lang];
		}

		return '';
	}
}

ClientAddress.contextType = ApplicationContext;

ClientAddress.propTypes = {
	language: PropTypes.string,
	companyCustomer: PropTypes.instanceOf(CompanyCustomer),
	// eslint-disable-next-line react/no-unused-prop-types
	onUpdate: PropTypes.func,
	onEdit: PropTypes.func
};

ClientAddress.defaultProps = {
	// eslint-disable-next-line react/default-props-match-prop-types
	canEdit: true
};
