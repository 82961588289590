import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

function FormattedMessageWithClass({ id, className, ...props }) {
	return (
		<FormattedMessage id={id} {...props}>
			{(msg) => <span className={className}>{msg}</span>}
		</FormattedMessage>
	);
}

FormattedMessageWithClass.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default FormattedMessageWithClass;
