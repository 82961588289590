import { FormattedMessage } from 'react-intl';
import React from 'react';

export function EmptyRow() {
	return (
		<tr className="empty-row">
			<td colSpan="100%">
				<FormattedMessage id="no.results" />
			</td>
		</tr>
	);
}
