import { TweenLite, Sine } from 'gsap';

class ScrollController {
	constructor() {
		window.addEventListener('scroll', this.onScroll.bind(this));

		this.scrollY = 0;
		this.isScrolling = false;
	}

	onScroll() {}

	scrollPageTo(toY = 0, duration = 0.3) {
		this.scrollY = window.scrollY ? window.scrollY : 0;
		this.isScrolling = true;
		TweenLite.to(this, duration, {
			scrollY: toY,
			ease: Sine.easeInOut,
			onUpdate: this.onUpdate,
			onUpdateScope: this,
			onComplete: this.onComplete,
			onCompleteScope: this
		});
	}

	scrollPageToElement(el, offsetY = 0, duration = 0.3, timeout = 1) {
		let element = el;
		let top = 0;
		do {
			top += element.offsetTop || 0;
			element = element.offsetParent;
		} while (element);

		const target = top - offsetY;

		clearTimeout(this.scrollPageToElementTimeout);
		this.scrollPageToElementTimeout = setTimeout(() => {
			this.scrollPageTo(target, duration);
		}, timeout);
	}

	onComplete() {
		this.isScrolling = false;
	}

	onUpdate() {
		window.scrollTo(0, this.scrollY);
	}
}

const scrollController = new ScrollController();
export default scrollController;
