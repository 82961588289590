import { observable, toJS, computed } from 'mobx';

import BTWPeriod from '../data/BTWPeriod';
import LedgerCodes from '../data/LedgerCodes';
import Signals from '../signals/Signals';

import Deadline from './models/Deadline';

import yearResult from '../requests/yearResult';
import yearVAT from '../requests/yearVAT';
import financialYearDeadlines from '../requests/financialYearDeadlines';

/**
 *
 */
export default class YearResultStore {
	@observable months = [];

	@observable vatTerms = [];

	@observable deadlines = [];

	@observable fetchingDeadlines = false;

	/**
	 * Retrieves all Invoices from the server for the specified financialYear
	 * and replaces any current invoices in the invoices array.
	 *
	 * @param financialYearId
	 * @returns {Promise.<T>}
	 */
	fetch(financialYearId) {
		this.months = [];

		return yearResult(financialYearId)
			.then((response) => {
				this.months = response.data.months;
			})
			.catch((error) => {
				Signals.Error.dispatch(error);
			});
	}

	/**
	 * Retrieve all VAT data for a specific financialYear
	 *
	 * @param financialYearId
	 * @returns {*}
	 */
	fetchVAT(financialYearId) {
		this.vatTerms = [];
		return yearVAT(financialYearId)
			.then((response) => {
				this.vatTerms = response.data.terms;
			})
			.catch((error) => {
				Signals.Error.dispatch(error);
			});
	}

	/**
	 * Retrieve all deadlines for a financialYear
	 *
	 * @param financialYearId
	 * @returns {*}
	 */
	fetchDeadlines(financialYearId) {
		this.deadlines = [];
		this.fetchingDeadlines = true;
		return financialYearDeadlines(financialYearId)
			.then((response) => {
				this.deadlines = response.list.map((data) => {
					return Deadline.parseDeadline(data);
				});
				this.fetchingDeadlines = false;

				Signals.DeadlinesFetched.dispatch();
			})
			.catch((error) => {
				Signals.Error.dispatch(error);
				this.fetchingDeadlines = false;
			});
	}

	/**
	 *
	 */
	reset() {
		this.months = [];
		this.vatTerms = [];
		this.deadlines = [];

		this.fetchingDeadlines = false;
	}

	/**
	 * Returns the total for the specified ledgercodes and range
	 *
	 * @param monthStart
	 * @param monthEnd
	 * @param ledgerCodes
	 */
	// eslint-disable-next-line default-param-last
	getTotalForLedgerCodes(monthStart = 0, monthEnd = 12, ledgerCodes) {
		let total = 0;
		const l = Math.min(this.months.length - 1, monthEnd);
		for (let i = monthStart; i <= l; i++) {
			const month = toJS(this.months[i]);
			const ledgerAmounts = month.ledgerAmounts;

			// eslint-disable-next-line no-loop-func
			ledgerAmounts.forEach((ledgerAmountObject) => {
				if (this._hasLedgerCode(ledgerCodes, ledgerAmountObject.ledgerCode)) {
					total += ledgerAmountObject.ledgerAmount;
				}
			});
		}

		return total;
	}

	/**
	 * Returns total VAT for the specified range
	 *
	 * @param monthStart
	 * @param monthEnd
	 */
	getTotalVAT(monthStart = 0, monthEnd = 12) {
		let total = 0;
		const l = Math.min(this.months.length - 1, monthEnd);
		for (let i = monthStart; i <= l; i++) {
			total += this.months[i].vat;
		}

		return -total;
	}

	/**
	 *Returns revenue total for specified range
	 *
	 * @param monthStart
	 * @param monthEnd
	 */
	getTotalRevenue(monthStart = 0, monthEnd = 12) {
		let total = 0;
		const l = Math.min(this.months.length - 1, monthEnd);
		for (let i = monthStart; i <= l; i++) {
			total += this.months[i].revenue;
		}

		return total;
	}

	/**
	 * Returns total expenses for all ledgercodes for the specified range
	 *
	 * @param monthStart
	 * @param monthEnd
	 */
	getTotalExpenses(monthStart = 0, monthEnd = 12) {
		return this.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4100,
			LedgerCodes.GC_4101,
			LedgerCodes.GC_4103,
			LedgerCodes.GC_4200,
			LedgerCodes.GC_4201,
			LedgerCodes.GC_4400,
			LedgerCodes.GC_4401,
			LedgerCodes.GC_4500,
			LedgerCodes.GC_4501,
			LedgerCodes.GC_4300,
			LedgerCodes.GC_4301,
			LedgerCodes.GC_4302,
			LedgerCodes.GC_7100,
			LedgerCodes.GC_7101,
			LedgerCodes.GC_7102,
			LedgerCodes.GC_4600,
			LedgerCodes.GC_4602,
			LedgerCodes.GC_0100,
			LedgerCodes.GC_0101
		]);
	}

	/**
	 * Returns if the financial year has deadlines which are in 'locked' state, between submitted and being send.
	 */
	hasLockedDeadlines() {
		let isLocked = false;
		this.deadlines.forEach((deadline) => {
			if (deadline.isLocked()) {
				isLocked = true;
			}
		});

		return isLocked;
	}

	/**
	 * Returns wether one or more deadlines still need to be submitted. (No submitted or send state)
	 *
	 * @returns {boolean}
	 */
	hasIncompleteDeadlines() {
		let inComplete = false;
		this.deadlines.forEach((deadline) => {
			if (!deadline.isSubmitted()) {
				inComplete = true;
			}
		});

		return inComplete;
	}

	/**
	 * Indicates wheter user/bookkeeper have used the system,
	 * if false the BTW submits have probably been done by the user or another bookkeeper
	 */
	didUseDeadlines() {
		let didUse = false;
		this.deadlines.forEach((deadline) => {
			if (deadline.isSubmitted()) {
				didUse = true;
			}
		});

		return didUse;
	}

	/**
	 *
	 * @returns {Array}
	 */
	@computed get sortedVatTerms() {
		return this.vatTerms.sort((a, b) => {
			const aPeriod = BTWPeriod.getObjectByCode(a.taxPeriod);
			const bPeriod = BTWPeriod.getObjectByCode(b.taxPeriod);

			if (!bPeriod && !aPeriod) {
				return 0;
			}

			if (!aPeriod && bPeriod) {
				return -1;
			}

			if (!bPeriod && aPeriod) {
				return 1;
			}

			if (aPeriod.priority === bPeriod.priority) {
				return a.start - b.start;
			}

			const pa = aPeriod.priority ? aPeriod.priority : Number.MAX_VALUE;
			const pb = bPeriod.priority ? bPeriod.priority : Number.MAX_VALUE;

			return pa - pb;
		});
	}

	/**
	 *
	 * @param ledgerCodes
	 * @param ledgerCode
	 * @returns {boolean}
	 * @private
	 */
	_hasLedgerCode(ledgerCodes, ledgerCode) {
		let found = false;
		ledgerCodes.forEach((lc) => {
			if (LedgerCodes.getCodeByObject(lc) === ledgerCode) {
				found = true;
			}
		});

		return found;
	}
}
