import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function BulletItem({ label, isLoading }) {
	return (
		<div className="flex flex-row  gap-x-5 content-center justify-start items-center">
			<div
				className={classNames(
					'w-8 h-8 bg-secondary-darker rounded-full flex items-center content-center justify-center',
					{
						'bg-zinc-300 w-6 h-6 ml-1 mr-1': isLoading
					}
				)}>
				{!isLoading && <ImCheckmark className="text-white w-4 h-4" />}
			</div>
			<span
				className={classNames({
					'text-zinc-400': isLoading
				})}>
				{label}
			</span>
		</div>
	);
}

BulletItem.propTypes = {
	label: PropTypes.string.isRequired,
	isLoading: PropTypes.bool
};

BulletItem.defaultProps = {
	isLoading: false
};

export default BulletItem;
