export default class Filter {
	rules = [];

	constructor(rules = []) {
		this.rules = rules;
	}

	addRule(rule) {
		this.rules.push(rule);
		return this;
	}

	reset() {
		this.rules = [];
		return this;
	}

	apply(object) {
		for (let i = 0; i < this.rules.length; i++) {
			if (!this.rules[i].apply(object)) {
				return false;
			}
		}

		return true;
	}
}
