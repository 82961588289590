import AbstractCommand from '../AbstractCommand';
import companyCustomerUpdate from '../../requests/customers/companyCustomerUpdate';

/**
 *
 */
export default class UpdateCompanyCustomerCommand extends AbstractCommand {
	/**
	 *
	 * @param company
	 * @param companyCustomer
	 */
	constructor(company, companyCustomer) {
		super();
		this.company = company;
		this.companyCustomer = companyCustomer;
	}

	/**
	 *
	 * @param successCallback
	 * @param failCallback
	 */
	execute(successCallback, failCallback) {
		companyCustomerUpdate(this.company.id, this.companyCustomer.id, this.companyCustomer)
			.then((response) => {
				successCallback(response);
			})
			.catch((err) => {
				if (failCallback) {
					failCallback(err);
				}
			});
	}
}
