import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 */
export default class InfoIcon extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<span
				className={`info-icon ${this.props.className} icon ${this.props.iconClass}`}
				onClick={this.onClick}
			/>
		);
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}
}

InfoIcon.propTypes = {
	className: PropTypes.string,
	iconClass: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

InfoIcon.defaultProps = {
	className: '',
	// eslint-disable-next-line react/default-props-match-prop-types
	iconClass: 'icon--info-black'
};
