import { observable, action, computed } from 'mobx';

import DraftUploadWorker, { DRAFT_WORKER_STATE } from './models/DraftUploadWorker';
import DraftTypes from '../data/DraftTypes';
import User from './models/User';
import Company from './models/Company';

/**
 *
 */
export default class DraftsFileUploadStore {
	@observable workers = [];

	@observable maxWorkers = 4;

	@observable activeWorkers = 0;

	@observable draftTarget = null; // User or Company

	@observable draftType = DraftTypes.DOCUMENT; // DraftsType

	/**
	 *
	 * @return {Array}
	 */
	@computed get sortedWorkers() {
		return this.workers.sort(DraftsFileUploadStore._sort);
	}

	/**
	 *
	 */
	@action reset() {
		this.workers.forEach((worker) => {
			worker.completeSignal.removeAll();
			worker.removeSignal.removeAll();
			worker.cancelAndRemove();
		});

		this.activeWorkers = 0;
		this.workers = [];
	}

	/**
	 *
	 * @param file
	 * @param financialYearId
	 * @param maxSize
	 * @param accept
	 *
	 * @return {DraftUploadWorker}
	 */
	@action add(
		file,
		financialYearId = null,
		maxSize = 1024 * 1024 * 20,
		accept = '.gif,.png,.jpg,.jpeg,.pdf,.txt,.csv'
	) {
		if (!this.draftTarget) {
			throw new Error('no draft target set');
		}

		if (!this.draftType) {
			throw new Error('no draft type set');
		}

		// draftTarget is either a User or Company object
		const { draftsStore, id } = this.draftTarget;
		const isUser = this.draftTarget instanceof User;
		const isCompany = this.draftTarget instanceof Company;

		// Create worker
		const worker = new DraftUploadWorker(
			file,
			this.draftType,
			maxSize,
			accept,
			draftsStore,
			isUser ? id : null,
			isCompany ? id : null,
			isCompany ? financialYearId : null
		);
		// eslint-disable-next-line no-shadow
		worker.completeSignal.add((worker) => {
			this.onWorkerComplete(worker);
		});
		// eslint-disable-next-line no-shadow
		worker.removeSignal.add((worker) => {
			this.onWorkerCancelledOrRemoved(worker);
		});
		this.workers.push(worker);

		// Start workers
		this.startWorkers();

		return worker;
	}

	/**
	 *
	 * @param worker
	 */
	onWorkerComplete(worker) {
		console.log('onWorkerComplete', worker.file.state, worker.file.fileName);

		this.activeWorkers--;
		this.startWorkers();
	}

	/**
	 *
	 * @param worker
	 */
	onWorkerCancelledOrRemoved(worker) {
		const index = this.workers.findIndex((w) => {
			return w === worker;
		});

		this.workers.splice(index, 1);
	}

	/**
	 *
	 */
	startWorkers() {
		console.log('startWorkers', this.activeWorkers, this.maxWorkers);

		// Are new workers allowed
		while (this.activeWorkers < Math.min(this.workers.length, this.maxWorkers)) {
			// Find first pending worker
			// eslint-disable-next-line no-shadow
			const worker = this.workers.find((worker) => {
				return worker.isWaiting();
			});

			// Start worker
			if (worker) {
				this.activeWorkers++;

				worker.start();

				// No workers pending
			} else {
				console.log('no more pending workers found!');
				break;
			}
		}
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get incompleteWorkerCount() {
		return this.workers.filter((worker) => {
			return worker.isWaiting() || worker.isBusy();
		}).length;
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get completeWorkerCount() {
		return this.workers.filter((worker) => {
			return worker.isDone();
		}).length;
	}

	/**
	 *
	 * @return {boolean}
	 */
	@computed get hasFailedUploads() {
		return !!this.workers.find((worker) => {
			return worker.hasFailed();
		});
	}

	/**
	 *
	 * @return {number}
	 */
	@computed get percentage() {
		let totalProgress = 0;
		this.workers.forEach((worker) => {
			totalProgress += worker.progress;
		});

		return totalProgress / this.workers.length;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @return {number}
	 * @private
	 */
	static _sort(a, b) {
		return DraftsFileUploadStore._getSortWeight(a) - DraftsFileUploadStore._getSortWeight(b);
	}

	/**
	 *
	 * @param worker
	 * @return {number}
	 * @private
	 */
	static _getSortWeight(worker) {
		switch (worker.state) {
			case DRAFT_WORKER_STATE.STARTED:
				return 1;
			case DRAFT_WORKER_STATE.FAILED:
				return 2;
			case DRAFT_WORKER_STATE.WAITING:
				return 3;
			case DRAFT_WORKER_STATE.SUCCESS:
				return 4;
			default:
				return 5;
		}
	}
}
