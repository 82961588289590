import AbstractCommand from './AbstractCommand';
import GetCompanyCommand from './GetCompanyCommand';
import GetCompanyInvoiceSettingsCommand from './GetCompanyInvoiceSettingsCommand';
import GetCompanyOfferSettingsCommand from './GetCompanyOfferSettingsCommand';
import GetCompanyCommunityProfileCommand from './GetCompanyCommunityProfileCommand';
import GetCompanyDraftsCommand from './drafts/GetCompanyDraftsCommand';

/**
 * Fetchs all company data, invoice settings and community profile data
 */
export default class FetchCompanyCommand extends AbstractCommand {
	/**
	 * @param company Company
	 */
	constructor(company) {
		super();

		this.company = company;
	}

	/**
	 *
	 * @param success Function
	 * @param error Function
	 */
	execute(success, error) {
		console.log('FetchCompanyCommand.execute');

		const getCompanyCommand = new GetCompanyCommand(this.company);
		const getCompanyInvoiceSettingsCommand = new GetCompanyInvoiceSettingsCommand(this.company);
		const getCompanyOfferSettingsCommand = new GetCompanyOfferSettingsCommand(this.company);
		const getCompanyCommunityProfileCommand = new GetCompanyCommunityProfileCommand(this.company);
		const getCompanyDraftsCommand = new GetCompanyDraftsCommand(
			this.company.draftsStore,
			this.company.id
		);

		const promises = [];

		// Get company data
		promises.push(
			new Promise((resolve, reject) => {
				getCompanyCommand.execute(resolve, reject);
			})
		);

		// Get invoice settings
		promises.push(
			new Promise((resolve, reject) => {
				getCompanyInvoiceSettingsCommand.execute(resolve, reject);
			})
		);

		// Get offer settings
		promises.push(
			new Promise((resolve, reject) => {
				getCompanyOfferSettingsCommand.execute(resolve, reject);
			})
		);

		// Get profile settings
		promises.push(
			new Promise((resolve, reject) => {
				getCompanyCommunityProfileCommand.execute(resolve, reject);
			})
		);

		// Get profile settings
		promises.push(
			new Promise((resolve, reject) => {
				getCompanyDraftsCommand.execute(resolve, reject);
			})
		);

		// Execute all requests
		Promise.all(promises)
			.then(() => {
				if (success) {
					success();
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
