import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { FormattedHTMLMessage } from 'react-intl';
import useBankAccountStore from '../../../utils/useBankAccountStore';
import useCompany from '../../../utils/useCompany';
import BankAccountItem from './BankAccountItem';

function BankAccounts() {
	const company = useCompany();
	const bankAccountStore = useBankAccountStore();
	const rowClassess =
		'flex flex-row bg-white space-x-2 px-3 py-5 min-h-5 border-solid border-0 border-t-2 border-gray-100';
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		if (company && bankAccountStore && !bankAccountStore.isFetched) {
			bankAccountStore.fetch(company.id).then(() => {
				setLoading(false);
			});
		} else if (bankAccountStore.isFetched) {
			setLoading(false);
		}
	}, [company, bankAccountStore]);

	return (
		<Observer>
			{() => {
				if (bankAccountStore.isFetched && !loading) {
					if (bankAccountStore.list.length > 0) {
						return (
							bankAccountStore.isFetched &&
							bankAccountStore.list.map((bankAccount) => (
								<BankAccountItem key={bankAccount.id} bankAccount={bankAccount} />
							))
						);
					}
					return (
						<div className={`${rowClassess} justify-center text-gray-500`}>
							<FormattedHTMLMessage id="bankbook.settings.accounts.no_accounts" />
						</div>
					);
				}
				if (loading && !bankAccountStore.isFetched) {
					return new Array(2).fill(0).map((_, index) => (
						<div key={index} className={rowClassess}>
							<div className="basis-1/6 w-full h-5 rounded-[8px] bg-gray-200 animate-pulse" />
							<div className="basis-3/6 w-full h-5 rounded-[8px] bg-gray-200 animate-pulse" />
							<div className="basis-2/6  w-full h-5 rounded-[8px] bg-gray-200 animate-pulse" />
						</div>
					));
				}
				return null;
			}}
		</Observer>
	);
}

export default BankAccounts;
