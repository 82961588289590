import { observable } from 'mobx';

export default class Address {
	@observable address = undefined;

	@observable city = undefined;

	@observable postalCode = undefined;

	toString() {
		return `Address: ${this.address}, ${this.city}, ${this.postalCode}`;
	}

	static parseAddress(data) {
		const address = new Address();

		if (!data) {
			return address;
		}

		address.address = Address._normalizeValue(data.address);
		address.city = Address._normalizeValue(data.city);
		address.postalCode = Address._normalizeValue(data.postalCode);

		return address;
	}

	static _normalizeValue(value) {
		return value || undefined;
	}
}
