/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { extractErrorMessage } from '../../../../../utils/extractErrorMessage';

import Paid from '../../../../../data/Paid';

import IncomeModel from '../../../../../stores/models/IncomeModel';
import IncomeRowModel from '../../../../../stores/models/IncomeRowModel';
import Company from '../../../../../stores/models/Company';
import FinancialYear from '../../../../../stores/models/FinancialYear';

import FormGroup from '../../../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../../../components/ui/FormField/FormField';
import RadioButton from '../../../../../components/ui/RadioButton/RadioButton';
import DateInput from '../../../../../components/ui/DateInput/DateInput';

import IncomeRowInput from './IncomeRowInput';

import { ApplicationContext } from '../../../../../ApplicationContext';

import AutocompleteResults from '../../../../../components/ui/AutocompleteResult/AutocompleteResults';
import AutocompleteResultRow from '../../../../../components/ui/AutocompleteResult/AutocompleteResultRow';
import AutocompleteSearchObject, {
	AutocompleteTypes
} from '../../../../../data/AutocompleteSearchObject';
import AutocompleteInvoiceResult from '../../../../../components/ui/AutocompleteResult/AutocompleteInvoiceResult';
import GetLatestInvoiceNumberCommand from '../../../../../commands/invoiceConcepts/GetLatestInvoiceNumberCommand';

/**
 *
 */
@observer
class IncomeEditor extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.onDateChange = this.onDateChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.addIncomeRow = this.addIncomeRow.bind(this);
		this.changeIncomeRow = this.changeIncomeRow.bind(this);
		this.removeIncomeRow = this.removeIncomeRow.bind(this);

		this.state = {};
	}

	/**
	 *
	 */
	componentWillMount() {
		const { suggestInvoiceNr } = this.props;
		this.company = this.context.applicationStore.getSelectedCompany();
		this.financialYear = this.context.applicationStore.getSelectedFinancialYear();

		this.setState({
			invoiceNrChangedManually: !suggestInvoiceNr,
			companyLatestInvoiceNumberStore: this.context.applicationStore.companyLatestInvoiceNumberStore
		});
	}

	/**
	 *
	 */
	componentDidMount() {
		// Focus field with short delay, so all elements are mounted and rendered
		setTimeout(() => {
			if (this.el && this.props.fieldToFocus) {
				this.el.querySelector(`[name="${this.props.fieldToFocus}"]`).focus();
			}
		}, 100);

		// Fetch latest invoice numbers for all bookyears
		if (this.company) {
			const command = new GetLatestInvoiceNumberCommand(
				this.state.companyLatestInvoiceNumberStore,
				this.company
			);
			command.execute();
		}
	}

	componentWillUpdate(_nextProps, _nextState, _nextContext) {
		const { incomeModel, originalIncomeModel, financialYear } = this.props;
		const { companyLatestInvoiceNumberStore, invoiceNrChangedManually } = this.state;

		// Invoice Number suggestions and defaults
		const latestInvoiceNumber = companyLatestInvoiceNumberStore.getLatestInvoiceNumber(
			financialYear.year
		);
		const suggestedInvoiceNumber = latestInvoiceNumber
			? latestInvoiceNumber.suggestedNextInvoiceNr
			: '';

		// Set, override invoice nr
		if (!incomeModel.id && !invoiceNrChangedManually && latestInvoiceNumber) {
			originalIncomeModel.invoiceNr = incomeModel.invoiceNr = suggestedInvoiceNumber;
		}
	}

	/**
	 *
	 */
	render() {
		//
		const { intl, incomeModel, financialYear, errors, lockInput } = this.props;
		const { autocompleteSearchObject } = this.state;

		// Render
		return (
			<div
				ref={(el) => {
					this.el = el;
				}}
				className="income-editor">
				<div className="income-editor__content padding-small">
					{/* Common settings for each row */}
					<FormGroup className="grid" errors={errors}>
						<FormField className="col--6">
							<label>
								<FormattedMessage id="income.input.invoicenr" /> (
								{this._getLastUsedInvoiceNrPlaceholder()})
							</label>
							<input
								name="invoiceNr"
								type="text"
								required
								maxLength="255"
								autoComplete="off"
								value={incomeModel ? incomeModel.invoiceNr : ''}
								placeholder={this._getLastUsedInvoiceNrPlaceholder()}
								onChange={this.onInputChange}
								disabled={lockInput}
							/>
						</FormField>
						<FormField className="col--6 form-field__date-picker">
							<label>
								<FormattedMessage id="income.input.date" />
							</label>
							<DateInput
								name="date"
								value={incomeModel.date}
								required
								autoComplete="off"
								openToDate={new Date()}
								disabled={lockInput}
								onChange={this.onDateChange}
							/>
						</FormField>
					</FormGroup>

					<FormGroup className="grid" errors={errors}>
						<FormField className="col--6">
							<label>
								<FormattedMessage id="income.input.client" />
							</label>
							<input
								name="customerName"
								type="text"
								required
								autoComplete="off"
								maxLength="255"
								value={incomeModel ? incomeModel.customerName : null}
								disabled={lockInput}
								onChange={this.onInputChange}
							/>
						</FormField>
						<FormField className="col--6">
							<label>
								<FormattedMessage id="income.input.projectnumber" />
							</label>
							<input
								name="projectcode"
								type="text"
								maxLength="255"
								autoComplete="off"
								value={incomeModel ? incomeModel.projectcode : null}
								disabled={lockInput}
								onChange={this.onInputChange}
							/>
						</FormField>
					</FormGroup>

					<FormGroup className="grid" errors={errors}>
						<FormField className="col--12">
							<label htmlFor="income-description">
								<FormattedMessage id="income.input.subject" />
							</label>
							<input
								name="description"
								autoComplete="off"
								type="text"
								value={incomeModel ? incomeModel.description : null}
								required
								maxLength={250}
								disabled={lockInput}
								onChange={this.onInputChange}
							/>
						</FormField>
					</FormGroup>
				</div>

				{/* Autocomplete suggestions */}
				<AutocompleteResults autocompleteSearchObject={autocompleteSearchObject}>
					{this._createAutocompleteResults()}
				</AutocompleteResults>

				{/* Rows */}
				<div className="income-editor__rows">
					{this._getInvoiceRows()}
					{lockInput ? null : (
						<div
							className="income-editor__add-income-row border--top border--dark icon icon--left icon--add-black"
							onClick={this.addIncomeRow}>
							<FormattedMessage id="income.input.add.row" />
						</div>
					)}
				</div>

				{/* Common settings, paid before/after/not paid */}
				<div className="income-editor__receipt">
					<FormGroup error={extractErrorMessage(errors, 'state')}>
						<label className="col--12 margin-small--left">
							<FormattedMessage id="income.input.paid" />
						</label>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.PAID_WITHIN_FYSCAL_YEAR}
								name="state"
								label={intl.formatMessage({ id: 'paid.within.year' }, { year: financialYear.year })}
								checked={incomeModel.state === Paid.PAID_WITHIN_FYSCAL_YEAR}
								onChange={this.onInputChange}
							/>
						</FormField>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.PAID_AFTER_FYSCAL_YEAR}
								name="state"
								label={intl.formatMessage({ id: 'paid.after.year' }, { year: financialYear.year })}
								checked={incomeModel.state === Paid.PAID_AFTER_FYSCAL_YEAR}
								onChange={this.onInputChange}
							/>
						</FormField>
						<FormField className="col--6">
							<RadioButton
								className="col--12 radio-button--border"
								value={Paid.UNPAID}
								name="state"
								label={intl.formatMessage({ id: 'paid.unpaid' })}
								checked={incomeModel.state === Paid.UNPAID}
								onChange={this.onInputChange}
							/>
						</FormField>
					</FormGroup>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		const { incomeModel } = this.props;
		incomeModel.date = date;
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const { incomeModel } = this.props;

		switch (e.target.name) {
			case 'invoiceNr':
				this.state.invoiceNrChangedManually = true;
				incomeModel[e.target.name] = e.target.value;
				break;
			default:
				incomeModel[e.target.name] = e.target.value;
		}

		// Update autocomplete only on customerName and description changes
		switch (e.target.name) {
			case 'customerName':
			case 'description':
				this._updateAutocomplete();
				break;
			default:
				break;
		}

		this.forceUpdate();
	}

	/**
	 *
	 */
	addIncomeRow() {
		const { incomeModel } = this.props;
		incomeModel.invoiceRows.push(new IncomeRowModel());
		this.forceUpdate();
	}

	/**
	 *
	 * @param rowIndex
	 */
	removeIncomeRow(rowIndex) {
		const { incomeModel } = this.props;
		incomeModel.invoiceRows.splice(rowIndex, 1);
		this.forceUpdate();
	}

	/**
	 *
	 * @param rowIndex
	 * @param data
	 */
	changeIncomeRow(rowIndex, data) {
		const { incomeModel } = this.props;
		incomeModel.invoiceRows[rowIndex].update(data);
		this.forceUpdate();
		this.props.resetBTWErrors();
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getInvoiceRows() {
		const { incomeModel, lockInput } = this.props;
		const { errors } = this.props;

		return incomeModel.invoiceRows.map((invoiceRow, index) => {
			const key = `ir-${index}-${invoiceRow._uid}`;
			return (
				<IncomeRowInput
					key={key}
					incomeData={incomeModel}
					errors={errors}
					rowIndex={index}
					amount={invoiceRow.amount}
					btw={invoiceRow.btw}
					lockInput={lockInput}
					btwCustomerNr={invoiceRow.btwCustomerNr}
					isValidBTW={invoiceRow.isValidBTW}
					onChange={this.changeIncomeRow}
					onRemove={this.removeIncomeRow}
				/>
			);
		});
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getLastUsedInvoiceNrPlaceholder() {
		const { intl } = this.props;
		const { companyLatestInvoiceNumberStore } = this.state;

		// Invoice Number suggestions and defaults
		const latestInvoiceNumber = companyLatestInvoiceNumberStore.getLatestInvoiceNumber(
			this.financialYear.year
		);

		if (latestInvoiceNumber && latestInvoiceNumber.latestInvoiceNr) {
			return intl.formatMessage(
				{ id: 'invoice.invoicenr.placeholder' },
				{ invoiceNr: latestInvoiceNumber.latestInvoiceNr }
			);
		}
		return intl.formatMessage(
			{ id: 'invoice.invoicenr.kdbformat' },
			{ year: new Date().getFullYear() }
		);
	}

	/**
	 *
	 */
	_updateAutocomplete() {
		const { incomeModel } = this.props;
		const { applicationStore } = this.context;
		const autocompleteSearchObject = new AutocompleteSearchObject(AutocompleteTypes.TYPE_INVOICES);

		const fields = ['customerName', 'description'];
		fields.forEach((field) => {
			autocompleteSearchObject.addField(field, incomeModel[field]);
		});

		const autocompleteResults = applicationStore.autocompleteStore.filter(autocompleteSearchObject);
		this.setState({
			autocompleteSearchObject,
			autocompleteResults
		});
	}

	/**
	 *
	 * @private
	 */
	_createAutocompleteResults() {
		const { autocompleteResults, autocompleteSearchObject } = this.state;

		if (autocompleteResults) {
			return autocompleteResults.map((incomeModel) => {
				return (
					<AutocompleteResultRow
						key={`arr-${incomeModel.id}`}
						rowData={incomeModel}
						onSelect={(rowData) => {
							this._autoFill(rowData);
						}}>
						<AutocompleteInvoiceResult
							incomeModel={incomeModel}
							autocompleteSearchObject={autocompleteSearchObject}
						/>
					</AutocompleteResultRow>
				);
			});
		}

		return null;
	}

	/**
	 *
	 * @param incomeModel
	 * @private
	 */
	_autoFill(incomeModel) {
		this.props.incomeModel.copy(incomeModel);

		// Clear autocomplete suggestion results
		this.setState({
			autocompleteSearchObject: null,
			autocompleteResults: []
		});
	}
}

IncomeEditor.contextType = ApplicationContext;

IncomeEditor.propTypes = {
	intl: PropTypes.object,
	errors: PropTypes.array,
	fieldToFocus: PropTypes.string,
	originalIncomeModel: PropTypes.instanceOf(IncomeModel).isRequired,
	incomeModel: PropTypes.instanceOf(IncomeModel).isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	company: PropTypes.instanceOf(Company).isRequired,
	financialYear: PropTypes.instanceOf(FinancialYear).isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	onRowsChanged: PropTypes.func,
	suggestInvoiceNr: PropTypes.bool,
	lockInput: PropTypes.bool,
	resetBTWErrors: PropTypes.func
};

export default injectIntl(IncomeEditor);
