import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (fileIdArray) => {
	const commaSeperatedIds = fileIdArray.join(',');
	return fetch(
		Methods.DELETE,
		EndPoints.ANNUAL_STATEMENT_FILES.replace(':fileids', commaSeperatedIds),
		null,
		null,
		true
	);
};
