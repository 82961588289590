import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueKey } from '../../../utils/ReactUtils';

export default class RadioButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = { focus: false };

		this.uid = uniqueKey('rb-');
		this.radioButton = null;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.label = null;

		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	render() {
		const { disabled, className, checked, name, value } = this.props;

		const classes = classNames({
			'radio-button--focus': this.state.focus,
			'radio-button--disabled': disabled
		});

		return (
			<div className={`radio-button ${classes} ${className || ''}`} onClick={this.onClick}>
				<input
					id={this.uid}
					type="radio"
					ref={(radioButton) => (this.radioButton = radioButton)}
					name={name}
					value={value}
					defaultChecked={checked}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					disabled={disabled}
				/>
				<div className="radio-button__check" />

				{/* eslint-disable-next-line react/no-unused-class-component-methods */}
				<label className="no-select" ref={(label) => (this.label = label)} htmlFor={this.uid}>
					{this.props.label}
				</label>
			</div>
		);
	}

	onClick() {
		this.radioButton.click();
	}

	onChange(e) {
		if (this.props.onChange) this.props.onChange(e);
	}

	onFocus() {
		this.setState({ focus: true });
	}

	onBlur() {
		this.setState({ focus: false });
	}
}

RadioButton.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool
};
