import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
export default class OnBoardingSteps extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onClick.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const steps = this.props.stepData.map((step, index) => {
			if (index === 0) {
				return;
			}

			const classes = classNames({
				'on-boarding-steps__step--active': step.isActive,
				'on-boarding-steps__step--required': step.isRequired
			});

			const stepClasses = classNames({
				'icon icon--check-white': !step.isRequired
			});

			return (
				<div key={`os-${index}`}>
					<div className={`on-boarding-steps__step ${classes}`} onClick={this.onClick}>
						<div className={`on-boarding-steps__step-number ${stepClasses}`}>
							<span>{step.index - 1}</span>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className="on-boarding-steps">
				<div className="on-boarding-steps__line" />
				{steps}
			</div>
		);
	}

	/**
	 *
	 */
	onClick() {}
}

OnBoardingSteps.propTypes = {
	stepData: PropTypes.array.isRequired
};
