import AbstractCommand from '../AbstractCommand';
import userMessagesGet from '../../requests/userMessages/userMessagesGet';

/**
 *
 */
export default class GetUserMessagesCommand extends AbstractCommand {
	/**
	 *
	 * @param userMessagesStore
	 */
	constructor(userMessagesStore) {
		super();
		this.userMessagesStore = userMessagesStore;
	}

	/**
	 *
	 * @param success
	 * @param error
	 * @return {Promise<any | never>}
	 */
	execute(success, error) {
		return userMessagesGet()
			.then((response) => {
				this.userMessagesStore.parseData(response.list);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
