import { observable } from 'mobx';
import Model from './Model';
import { _formatDate, _formatStringToDate } from '../../utils/objectToFormData';
import PeriodicScheduleInterval from '../../data/PeriodicScheduleInterval';

/**
 *
 */
export default class PeriodicScheduleModel extends Model {
	@observable interval = PeriodicScheduleInterval.MONTHLY;

	@observable startDate = new Date();

	@observable endDate = null;

	@observable nextDate = null;

	@observable lastExecutionDate;

	@observable enabled = false;

	@observable amount = 1;

	@observable lastAttemptStatus;

	@observable totalExecutions;

	@observable executionsLeft;

	/**
	 *
	 * @param data
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(data) {
		super(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		if (!data) return;

		console.log('PeriodicScheduleModel.update', data);

		const keys = Object.keys(data);
		// eslint-disable-next-line array-callback-return
		keys.map((key) => {
			switch (key) {
				case 'startDate':
				case 'endDate':
				case 'nextDate':
				case 'lastExecutionDate':
					if (typeof data[key] === 'string') {
						this[key] = _formatStringToDate(data[key], true);
					} else {
						this[key] = data[key];
					}
					console.log(`\t${key}`, this[key]);
					break;
				default:
					this[key] = data[key];
			}
		});
	}

	/**
	 *
	 * @param defaults
	 * @param ignoreFields
	 * @return {string}
	 */
	toJSON(defaults = {}, ignoreFields = { status: true }) {
		const data = {};

		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (typeof this[key] !== 'function' && !(ignoreFields && ignoreFields[key])) {
					let value = this[key];

					// Try to set defaults when value is not set
					if (!value) {
						value = defaults[key] !== null && defaults[key] !== undefined ? defaults[key] : null;
					}

					// Force format of specific types and objects (like `Date`)
					data[key] = this._formatJSONData(value);
				}
			}
		}

		return JSON.stringify(data);
	}

	/**
	 *
	 */
	get statusTagType() {
		return this.enabled ? 'paid' : 'sent';
	}

	/**
	 *
	 * @param value
	 * @return {*}
	 * @private
	 */
	_formatJSONData(value) {
		if (value instanceof Date) {
			return _formatDate(value);
		}
		return value;
	}
}
