import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId) => {
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_PERIODIC_INVOICE_CONCEPTS.replace(':id', companyId),
		null,
		null,
		true
	);
};
