import Signals from '../signals/Signals';

/**
 *
 * @param element
 * @private
 */
export function captureDeeplinkClick(element) {
	// Capture link click and add to history push state
	const deeplinkAnchor = element ? element.querySelector('a[href]') : null;
	if (deeplinkAnchor) {
		deeplinkAnchor.addEventListener('click', (event) => {
			event.preventDefault();
			const href = event.currentTarget.href;
			const route = href.replace(`${window.location.protocol}//${window.location.host}`, '');
			Signals.RequestRoute.dispatch(route);
		});
	}
}
