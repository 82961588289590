import { observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import Company from './Company';

export default class CompanyBrandModel {
	@observable id = null;

	@observable name = null;

	@observable address = null;

	@observable postalCode = null;

	@observable city = null;

	@observable website = null;

	@observable phoneNumber = null;

	@observable email = null;

	@observable kvkNr = null;

	@observable iban = null;

	@observable logoAssetUuid = null;

	/**
	 *
	 * @param data
	 */
	constructor(data = {}) {
		this.id = null; // Necessary otherwise observables won't be created
		this.update(data);
	}

	/**
	 *
	 * @return {CompanyBrandModel}
	 */
	clone() {
		const model = new CompanyBrandModel();
		model.update(this);
		return model;
	}

	/**
	 * Is this object the same as `companyBrand`, ignores id
	 */
	isEqual(companyBrand) {
		if (!companyBrand) {
			return false;
		}

		const keys = Object.keys(this);
		let isSame = true;
		keys.forEach((key) => {
			if (key !== 'id') {
				if (this[key] !== companyBrand[key]) {
					console.log('\t', key, this[key], companyBrand[key]);
					isSame = false;
				}
			}
		});

		return isSame;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		const keys = Object.keys(data);
		keys.forEach((key) => {
			switch (key) {
				default:
					this[key] = data[key];
			}
		});

		return this;
	}

	/**
	 *
	 * @return {string}
	 */
	getLabel() {
		return `${this.name} - ${this.email}`;
	}

	/**
	 *
	 * @param mergeWithCompany
	 * @return {Company}
	 */
	toCompany(mergeWithCompany) {
		const company = new Company();
		company.update(mergeWithCompany);
		company.address = {};

		const keys = Object.keys(this);
		keys.forEach((key) => {
			switch (key) {
				case 'id':
					// ignore
					break;
				case 'address':
					company.address.address = this.address;
					break;
				case 'city':
					company.address.city = this.city;
					break;
				case 'postalCode':
					company.address.postalCode = this.postalCode;
					break;
				case 'kvkNr':
					company.kvkNumber = this.kvkNr;
					break;
				default:
					company[key] = this[key];
			}
		});

		return company;
	}

	/**
	 *
	 * @return {*|boolean}
	 */
	hasIBAN() {
		return this.iban && this.iban.length > 0;
	}

	/**
	 *
	 * @param company
	 * @return {CompanyBrandModel}
	 */
	static fromCompany(company) {
		console.log('CompanyBrandModel.fromCompany', company);

		const companyBrand = new CompanyBrandModel();

		const keys = Object.keys(companyBrand);
		keys.forEach((key) => {
			switch (key) {
				case 'id':
					// ignore
					break;
				case 'address':
					companyBrand.address = company.address ? company.address.address : null;
					break;
				case 'postalCode':
					companyBrand.postalCode = company.address ? company.address.postalCode : null;
					break;
				case 'city':
					companyBrand.city = company.address ? company.address.city : null;
					break;
				case 'kvkNr':
					companyBrand.kvkNr = company.kvkNumber;
					break;
				default:
					companyBrand[key] = company[key];
			}
		});

		// User settings logo asset uuid
		if (company.invoicesSettings) {
			companyBrand.logoAssetUuid = company.invoicesSettings.logoAssetUuid;
		}

		console.log('CompanyBrandModel.fromCompany', companyBrand);
		return companyBrand;
	}
}
