class TrackingController {
	constructor(_options) {
		this.googleAnalytics = window.ga;
	}

	trackPageView(page) {
		this._ga('send', {
			hitType: 'pageview',
			page
		});
	}

	/**
	 * @param {string} eventCategory - Typically the object that was interacted with (e.g. 'Video')
	 * @param {string} eventAction - The type of interaction (e.g. 'play')
	 * @param {string|null} [eventLabel] - Useful for categorizing events (e.g. 'Fall Campaign')
	 * @param {integer|null} [eventValue] - A numeric value associated with the event (e.g. 42)
	 */
	trackEvent(eventCategory, eventAction, eventLabel = null, eventValue = null) {
		this._ga('send', {
			hitType: 'event',
			eventCategory,
			eventAction,
			eventLabel,
			eventValue
		});
	}

	/**
	 * @private
	 */
	_ga() {
		// eslint-disable-next-line prefer-rest-params
		const args = Array.prototype.slice.call(arguments);
		const isDebug = window.config.debug;

		if (!isDebug && typeof this.googleAnalytics !== 'undefined') {
			// eslint-disable-next-line prefer-spread
			this.googleAnalytics.apply(this, args);
		} else {
			console.log('debug tracking:', args);
		}
	}
}

const trackingController = new TrackingController();
export default trackingController;
