/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getTotalAmountInclBTW } from '../../../utils/btwUtils';

import { ApplicationContext } from '../../../ApplicationContext';
import ExpenseModel from '../../../stores/models/ExpenseModel';
import AutocompleteSearchObject from '../../../data/AutocompleteSearchObject';

/**
 *
 */
export default class AutocompleteExpenseResult extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	render() {
		const { expenseModel } = this.props;
		return (
			<div className="autocomplete-expense-result grid grid--no-wrap col--12">
				<div className="autocomplete-expense-result__name" title={expenseModel.customerName}>
					<div
						className="autocomplete-expense-result__value"
						dangerouslySetInnerHTML={{ __html: this._applySearchHighlights('customerName') }}
					/>
				</div>
				<div className="autocomplete-expense-result__description" title={expenseModel.customerName}>
					<div
						className="autocomplete-expense-result__value"
						dangerouslySetInnerHTML={{ __html: this._applySearchHighlights('description') }}
					/>
				</div>
				<div className="autocomplete-expense-result__vat">
					<div className="autocomplete-expense-result__value">
						<FormattedMessage id={expenseModel.getBTWTranslationId()} />
					</div>
				</div>
				<div className="autocomplete-expense-result__amount">
					<FormattedNumber
						// eslint-disable-next-line react/style-prop-object
						style="currency"
						currency="EUR"
						value={getTotalAmountInclBTW(expenseModel.expenseRows)}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param fieldName
	 * @return {string|null}
	 * @private
	 */
	_applySearchHighlights(fieldName) {
		const value = this.props.expenseModel[fieldName];
		const autocompleteFieldData = this.props.autocompleteSearchObject.getField(fieldName);
		if (!autocompleteFieldData || !value) {
			return value;
		}

		const searchQuery = autocompleteFieldData.searchQuery;

		if (searchQuery && searchQuery.length > 0) {
			const startIndex = value.toLowerCase().indexOf(searchQuery.toLowerCase());
			const endIndex = startIndex + searchQuery.length;
			const result = [];

			if (startIndex !== 0) {
				result.push(value.slice(0, startIndex));
			}

			const highlightText = value.slice(startIndex, endIndex);

			result.push(`<span class="autocomplete-result-row__highlight">${highlightText}</span>`);
			result.push(value.slice(endIndex));
			return result.join('');
		}

		return value;
	}
}

AutocompleteExpenseResult.contextType = ApplicationContext;

AutocompleteExpenseResult.propTypes = {
	expenseModel: PropTypes.instanceOf(ExpenseModel).isRequired,
	autocompleteSearchObject: PropTypes.instanceOf(AutocompleteSearchObject).isRequired
};
