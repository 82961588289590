import bankRecordMatchDelete from '../../requests/bank/bankRecordMatchDelete';
import Signals from '../../signals/Signals';
// eslint-disable-next-line no-unused-vars
import BankRecord from '../../stores/models/BankRecord';

/**
 *
 */
export default class DeleteBankRecordsMatchCommand {
	/**
	 *
	 */
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
	}

	/**
	 *
	 * @param {BankRecord} bankRecord
	 * @param {number} companyId
	 * @return {Promise<any | never>}
	 */
	execute(bankRecord, companyId) {
		this.bankRecord = bankRecord;
		return bankRecordMatchDelete(
			bankRecord.id,
			bankRecord.matchedResource.id,
			bankRecord.matchedResource.type,
			companyId,
			bankRecord.executionDate
		)
			.then(this.then)
			.catch(this.catch);
	}

	then() {
		this.bankRecord.unMatch();
	}

	/**
	 *
	 * @param error
	 */
	catch(error) {
		Signals.Error.dispatch(error);
	}
}
