import AutocompleteFieldData from './AutocompleteFieldData';

export const AutocompleteTypes = {
	TYPE_INVOICES: 'invoices',
	TYPE_EXPENSES: 'expenses'
};

/**
 *
 */
export default class AutocompleteSearchObject {
	fields = [];

	type = AutocompleteTypes.TYPE_EXPENSES;

	/**
	 *
	 * @param type
	 */
	constructor(type) {
		this.type = type;
	}

	/**
	 *
	 */
	clear() {
		this.fields = [];
	}

	/**
	 *
	 * @param fieldName
	 * @param searchQuery
	 * @return {boolean}
	 */
	addField(fieldName, searchQuery) {
		if (fieldName && searchQuery) {
			const fieldData = new AutocompleteFieldData(fieldName, searchQuery);
			this.fields.push(fieldData);
			return true;
		}

		return false;
	}

	/**
	 *
	 * @param fieldName
	 */
	getField(fieldName) {
		return this.fields.find((fieldData) => {
			return fieldData.name === fieldName;
		});
	}
}
