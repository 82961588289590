import React from 'react';
import PropTypes from 'prop-types';

function ProfileAvatarPlaceholder(props) {
	// There are currently 4 different colors supported for the ProfileAvatarPlaceholder,
	// these are defined in the file `profile-avatar-placeholder.scss`. The data-index attribute
	// defines which of these colors should be used depending on the property `index`
	const colorVariationCount = 4;
	return (
		<div
			className="profile-avatar-placeholder font-primary-medium"
			data-index={props.index % colorVariationCount}>
			<span>{props.label}</span>
		</div>
	);
}

export default ProfileAvatarPlaceholder;

ProfileAvatarPlaceholder.propTypes = {
	index: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired
};
