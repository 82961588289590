/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
	IntlProvider,
	injectIntl,
	FormattedMessage,
	FormattedHTMLMessage,
	FormattedDate
} from 'react-intl';
import classNames from 'classnames';
import Page from '../../Page';

import { getTranslations } from '../../../utils/Translations';

import { Routes } from '../../../data/Routes';

import Signals from '../../../signals/Signals';
import BTW from '../../../data/BTW';
import { createDynamicValues, addDynamicValues } from '../../../data/InvoiceDynamicValues';
import InvoiceConceptStatus from '../../../data/InvoiceConceptStatus';

import InvoiceRow from '../Invoice/components/InvoiceRow';
import CompanyAddress from '../../../components/ui/CompanyAddress/CompanyAddress';
import ClientAddress from '../../../components/ui/ClientAddress/ClientAddress';

import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import Checkbox from '../../../components/ui/Checkbox/Checkbox';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import ToggleButton from '../../../components/ui/ToggleButton/ToggleButton';
import ToggleButtonOption from '../../../components/ui/ToggleButton/ToggleButtonOption';

import ModalConfirm from '../../../components/ui/Modal/ModalConfirm';
import InvoiceSummaryRow from '../Invoice/components/InvoiceSummaryRow';
import PeriodicInvoiceHistory from './components/PeriodicInvoiceHistory';

import { buildCompanyAssetURL } from '../../../requests/companyAssetGet';
import Locale from '../../../data/Locale';
import InvoiceAttachments from '../Invoice/components/InvoiceAttachments';
import { ApplicationContext } from '../../../ApplicationContext';

import CompanyBrandModel from '../../../stores/models/CompanyBrandModel';

import CompanyBrandInput from '../GenericSettings/modals/CompanyBrandInput';
import ClientInput from '../Clients/components/modals/ClientInput';
import CompanyCustomer from '../../../stores/models/CompanyCustomer';
import { uniqueKey } from '../../../utils/ReactUtils';
import PropertiesController from '../../../controllers/PropertiesController';
import PeriodicInvoiceStatus from './components/PeriodicInvoiceStatus';
import PeriodicInvoiceConceptModel from '../../../stores/models/PeriodicInvoiceConceptModel';

import SavePeriodicInvoiceConceptCommand from '../../../commands/periodicInvoiceConcepts/SavePeriodicInvoiceConceptCommand';
import GetPeriodicInvoiceConceptCommand from '../../../commands/periodicInvoiceConcepts/GetPeriodicInvoiceConceptCommand';
import DeletePeriodicInvoiceConceptCommand from '../../../commands/periodicInvoiceConcepts/DeletePeriodicInvoiceConceptCommand';
import { FormErrorContext } from '../../../FormErrorContext';

/**
 *
 */
@observer
class PeriodicInvoice extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.locale = null;
		this.translations = null;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.sendType = -1;
		this.state = {
			settingsExpanded: false,
			errors: null
		};

		this.onCancel = this.onCancel.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onActivateInvoice = this.onActivateInvoice.bind(this);
		this.onDeactivateInvoice = this.onDeactivateInvoice.bind(this);
		this.onAction = this.onAction.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onError = this.onError.bind(this);

		this.onSaveSuccess = this.onSaveSuccess.bind(this);
		this.onToggleSettings = this.onToggleSettings.bind(this);

		this.onEditClient = this.onEditClient.bind(this);
		this.onClientUpdate = this.onClientUpdate.bind(this);

		this.onEditSender = this.onEditSender.bind(this);
		this.onSenderUpdate = this.onSenderUpdate.bind(this);

		this.remove = this.remove.bind(this);
		this.edit = this.edit.bind(this);

		this._doRemove = this._doRemove.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.componentWillReceiveProps();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this.resetFieldHeaderSettings();
		this.getInvoiceConceptModel();
	}

	/**
	 *
	 */
	render() {
		// Check if user logged in
		const { applicationStore } = this.context;
		if (!applicationStore.isLoggedIn || !this.periodicInvoiceConceptModel) {
			return null;
		}

		const company = applicationStore.getSelectedCompany();
		const companyBrand = this.periodicInvoiceConceptModel.companyBrand
			? this.periodicInvoiceConceptModel.companyBrand
			: CompanyBrandModel.fromCompany(company);
		const companyCustomer = this.periodicInvoiceConceptModel.companyCustomer
			? this.periodicInvoiceConceptModel.companyCustomer
			: applicationStore.companyCustomerStore.find(
					this.periodicInvoiceConceptModel.companyCustomerId
			  );

		// Can't render
		if (!company || !companyCustomer) {
			return null;
		}

		// Build logo URL
		const logoPreviewURL = companyBrand.logoAssetUuid
			? buildCompanyAssetURL(company.id, companyBrand.logoAssetUuid)
			: null;
		const deviatingUnits = this.periodicInvoiceConceptModel.hasDeviatingUnits();

		if (!deviatingUnits) {
			// eslint-disable-next-line array-callback-return
			this.fieldHeaderSettings.map((fieldData) => {
				if (fieldData.id === 'units') {
					fieldData.hide = true;
				}

				if (fieldData.id === 'unitprice') {
					fieldData.labelId = fieldData.labelIdSingle;
				}
			});
		}

		// Create table headers
		const headers = this.fieldHeaderSettings.map((fieldData, index) => {
			return (
				<td
					key={`invh-${index}`}
					className={`invoice__header--${`${fieldData.id}`.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}>
					{fieldData.hide ? null : <FormattedMessage id={fieldData.labelId} />}
				</td>
			);
		});

		//
		const hasDeviatingUnits = this.periodicInvoiceConceptModel.hasDeviatingUnits();
		const detailsWrapperClasses = classNames({
			'invoice__details-wrapper': true,
			'invoice__details-wrapper--no-units': !hasDeviatingUnits
		});

		// Create invoices concept rows
		const rows = this.periodicInvoiceConceptModel.invoiceConceptRows.map(
			(invoiceConceptRowModel, index) => {
				return (
					<InvoiceRow
						key={`i-${index}-${invoiceConceptRowModel._uid}`}
						hideUnits={!hasDeviatingUnits}
						invoiceConceptModel={this.periodicInvoiceConceptModel}
						invoiceConceptRowModel={invoiceConceptRowModel}
					/>
				);
			}
		);

		return (
			<Page pageName="invoice grid">
				<div className="invoice__panel panel grid col--12 box-shadow border border--dark">
					{/* Option buttons */}
					{this._getOptions()}

					{/* Expanding/Collapsing settings */}
					<div
						className={`invoice__settings col--12 grid ${classNames({
							'invoice__settings--expanded': this.state.settingsExpanded
						})}`}>
						{this._getSettings()}
					</div>

					{/* */}
					<PeriodicInvoiceStatus periodicInvoiceConceptModel={this.periodicInvoiceConceptModel} />

					{/* Invoice status */}
					{/* <div className={'invoice__status invoice__status--' + (this.periodicInvoiceConceptModel.periodicSchedule.statusTagType + '').toLowerCase()}> */}
					{/*	<label><FormattedMessage id={`invoice.status.${this.periodicInvoiceConceptModel.status ? (this.periodicInvoiceConceptModel.status + '').toLowerCase() : ''}`}/> */}
					{/*	</label> */}
					{/* </div> */}

					{/* Invoice layout + localised translation */}
					<IntlProvider locale={this.locale} messages={this.translations}>
						<div className="invoice__layout-wrapper padding grid">
							<div className="invoice__information grid col--12">
								<div className="invoice__logo col--6">
									{logoPreviewURL ? (
										<img className="invoice__logo-img" src={logoPreviewURL} alt="logo" />
									) : null}
								</div>

								<div className="col--6 grid">
									<label className="text--right invoice__information-label col--5">
										<FormattedMessage id="invoice.label.sender" />
									</label>
									<div className="col--7">
										<CompanyAddress
											company={companyBrand.toCompany(company)}
											useDate={this.periodicInvoiceConceptModel.date}
											onEdit={this.periodicInvoiceConceptModel.canEdit() ? this.onEditSender : null}
										/>
									</div>
								</div>

								<div className="invoice__information-detail-labels grid col--6">
									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.invoice" />
									</label>
									<div className="invoice__information-value invoice__information-value--bold col--8">
										{this.periodicInvoiceConceptModel.subject}
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.invoicenr" />
									</label>
									<div className="invoice__information-value col--8" />
									<label
										className={`invoice__information-label col--4 ${classNames({
											hidden: !this.periodicInvoiceConceptModel.projectcode
										})}`}>
										<FormattedMessage id="invoice.info.projectcode" />
									</label>
									<div
										className={`invoice__information-value col--8 ${classNames({
											hidden: !this.periodicInvoiceConceptModel.projectcode
										})}`}>
										{this.periodicInvoiceConceptModel.projectcode}
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.date" />
									</label>
									<div className="invoice__information-value col--8">
										<FormattedDate
											value={this.periodicInvoiceConceptModel.date}
											day="numeric"
											month="long"
											year="numeric"
										/>
									</div>

									<label className="invoice__information-label col--4">
										<FormattedMessage id="invoice.info.expirationdate" />
									</label>
									<div className="invoice__information-value col--8">
										{/* Calculate expirationDate from date + expirationDays to cancel out discrepantie of 1 day */}
										<FormattedDate
											value={this.periodicInvoiceConceptModel.getExpirationDateFromDate()}
											day="numeric"
											month="long"
											year="numeric"
										/>
									</div>
								</div>

								<div className="col--6 grid">
									<label className="text--right invoice__information-label col--5">
										<FormattedMessage id="invoice.label.client" />
									</label>
									<div className="col--7">
										<ClientAddress
											companyCustomer={this.periodicInvoiceConceptModel.companyCustomer}
											onUpdate={this.onClientUpdate}
											onEdit={this.periodicInvoiceConceptModel.canEdit() ? this.onEditClient : null}
											language={this.locale}
										/>
									</div>
								</div>
							</div>

							<div className={detailsWrapperClasses}>
								<table className="table table--wide invoice__details col--12">
									<thead className="table__headers">
										<tr>{headers}</tr>
									</thead>
									<tbody className="table__body">
										{rows}

										{/* Empty row */}
										<tr className="invoice-summary-row">
											<td colSpan={5}>&nbsp;</td>
										</tr>

										{this._getSubtotal()}
										{this._getVATLow()}
										{this._getVATHigh()}
										{this._getVATReversed()}
										{this._getVATExempt()}
										{this._getTotal()}
									</tbody>
								</table>
							</div>

							{/* Optional notes */}
							{this.periodicInvoiceConceptModel.hasNotes() ? (
								<div className="invoice__notes col--12 grid padding border--top padding--bottom">
									<label>
										<FormattedMessage id="invoice.label.notes" />
									</label>
									<div
										// eslint-disable-next-line react/no-danger
										dangerouslySetInnerHTML={{
											__html: this.periodicInvoiceConceptModel.getNotesAsHTML()
										}}
									/>
								</div>
							) : null}
						</div>
					</IntlProvider>
				</div>

				{/* Attachments */}
				<InvoiceAttachments
					invoiceConceptModel={this.periodicInvoiceConceptModel}
					company={company}
				/>

				{/* Invoice history */}
				<PeriodicInvoiceHistory periodicInvoiceConceptModel={this.periodicInvoiceConceptModel} />
			</Page>
		);
	}

	/**
	 *
	 */
	onToggleSettings() {
		// eslint-disable-next-line react/no-access-state-in-setstate
		this.setState({ settingsExpanded: !this.state.settingsExpanded });
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		const company = this.context.applicationStore.getSelectedCompany();
		switch (e.target.name) {
			case 'sendToSelf':
				this.setState({ bccRecipientEmail: e.target.checked ? company.email : '' });
				break;
			case 'remindCompanyUserInDays':
				this.setState({ remindCompanyUserInDays: e.target.checked ? 0 : null });
				break;
			default:
				// eslint-disable-next-line no-case-declarations
				const nextState = {};
				nextState[e.target.name] = e.target.value;
				this.setState(nextState);
		}
	}

	/**
	 *
	 */
	onEditClient() {
		const companyCustomer = this.periodicInvoiceConceptModel.companyCustomer;
		Signals.ShowOverlay.dispatch(
			<ClientInput
				key={uniqueKey('cc-')}
				companyCustomer={companyCustomer}
				updateLocalOnly
				onUpdate={this.onClientUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onClientUpdate(companyCustomer) {
		this.setState({ recipientEmail: companyCustomer.contactPersonEmail });

		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		this.periodicInvoiceConceptModel.companyCustomer = new CompanyCustomer(companyCustomer);
		this.forceUpdate();

		const command = new SavePeriodicInvoiceConceptCommand(
			applicationStore,
			this.periodicInvoiceConceptModel,
			company,
			this.periodicInvoiceConceptModel.toJSON(this.state),
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 */
	onEditSender() {
		const company = this.context.applicationStore.getSelectedCompany();
		const companyBrand = this.periodicInvoiceConceptModel.companyBrand
			? this.periodicInvoiceConceptModel.companyBrand
			: company.defaultCompanyBrand;
		Signals.ShowOverlay.dispatch(
			<CompanyBrandInput
				key={uniqueKey('cbi-')}
				companyBrand={companyBrand}
				updateLocalOnly
				onUpdate={this.onSenderUpdate}
			/>
		);
	}

	/**
	 *
	 * @param companyBrand
	 */
	onSenderUpdate(companyBrand) {
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();
		this.periodicInvoiceConceptModel.companyBrand = new CompanyBrandModel(companyBrand);
		this.forceUpdate();

		const command = new SavePeriodicInvoiceConceptCommand(
			applicationStore,
			this.periodicInvoiceConceptModel,
			company,
			this.state,
			true
		);
		command.execute(this.onSaveSuccess);
	}

	/**
	 *
	 * @param response
	 */
	onSaveSuccess(_response) {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			<FormattedHTMLMessage
				id="invoice.periodic.saved.message"
				values={this.periodicInvoiceConceptModel}
			/>
		);

		// Update store
		const { applicationStore } = this.context;
		const storedInvoiceConcept = applicationStore.invoicesConceptStore.getInvoiceConceptById(
			this.periodicInvoiceConceptModel.id
		);
		if (storedInvoiceConcept) {
			storedInvoiceConcept.update(this.periodicInvoiceConceptModel);
		}
	}

	/**
	 *
	 */
	onCancel() {
		this.setState({ settingsExpanded: false });
		this.forceUpdate();
	}

	/**
	 *
	 */
	onEdit() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_PERIODIC_EDIT.getPath({
					id: company.id,
					invoiceId: this.periodicInvoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param option
	 */
	onAction(option) {
		switch (option) {
			case 'edit':
				this.edit();
				break;
			case 'duplicate':
				this.duplicate();
				break;
			case 'remove':
				this.remove();
				break;
			case 'archive':
				this.onArchive();
				break;
			case 'unarchive':
				this.onUnarchive();
				break;
			default:
				break;
		}
	}

	/**
	 *
	 */
	onActivateInvoice() {
		const { applicationStore } = this.context;
		this.periodicInvoiceConceptModel.periodicSchedule.enabled = true;
		const command = new SavePeriodicInvoiceConceptCommand(
			applicationStore,
			this.periodicInvoiceConceptModel,
			applicationStore.getSelectedCompany(),
			this.state,
			true
		);
		command
			.execute()
			.then(() => {
				Signals.ShowMessageDialog.dispatch(
					<FormattedHTMLMessage
						id="invoice.periodic.activated.message"
						values={this.periodicInvoiceConceptModel}
					/>
				);
				this.setState({ settingsExpanded: false });
			})
			.catch((err) => {
				this.periodicInvoiceConceptModel.periodicSchedule.enabled = false;
				this.onError(err);
			});
	}

	/**
	 *
	 */
	onDeactivateInvoice() {
		const { applicationStore } = this.context;
		this.periodicInvoiceConceptModel.periodicSchedule.enabled = false;
		const command = new SavePeriodicInvoiceConceptCommand(
			applicationStore,
			this.periodicInvoiceConceptModel,
			applicationStore.getSelectedCompany(),
			this.state,
			true
		);
		command
			.execute()
			.then(() => {
				Signals.ShowMessageDialog.dispatch(
					<FormattedHTMLMessage
						id="invoice.periodic.deactivated.message"
						values={this.periodicInvoiceConceptModel}
					/>
				);
				this.setState({ settingsExpanded: false });
			})
			.catch((err) => {
				this.periodicInvoiceConceptModel.periodicSchedule.enabled = true;
				this.onError(err);
			});
	}

	/**
	 *
	 */
	resetFieldHeaderSettings() {
		this.fieldHeaderSettings = [
			{ id: 'description', labelId: 'invoice.header.description' },
			{ id: 'units', labelId: 'invoice.header.units', right: true },
			{
				id: 'unitprice',
				labelId: 'invoice.header.unitprice',
				labelIdSingle: 'invoice.header.price',
				right: true
			},
			{ id: 'btw', labelId: 'invoice.header.btw', right: true },
			{ id: 'amount', labelId: 'invoice.header.amount', right: true }
		];
	}

	/**
	 *
	 * @returns {boolean}
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	validate() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			// BTW check, ignore this check always for VRIJGESTELD
			if (
				!company.ignoreBTWNumber() &&
				!this.periodicInvoiceConceptModel.containsVATType(BTW.VRIJGESTELD, true)
			) {
				const useDate = this.periodicInvoiceConceptModel.date;
				const useVatId = useDate && company.useBTWId(useDate);

				let title = '';
				let body = '';

				if (useVatId && !company.hasVATId()) {
					title = this.props.intl.formatMessage({ id: 'invoice.novatid.alert.title' });
					body = this.props.intl.formatMessage({ id: 'invoice.novatid.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
				if (!company.hasVATNumber()) {
					title = this.props.intl.formatMessage({ id: 'invoice.novat.alert.title' });
					body = this.props.intl.formatMessage({ id: 'invoice.novat.alert.body' });
					Signals.ShowModal.dispatch(<ModalAlert title={title} body={body} />);
					return false;
				}
			}
		}

		return true;
	}

	/**
	 *
	 * @private
	 */
	remove() {
		// Don't allow when still submitting data
		if (this.state.submitting) {
			return;
		}

		const title = this.props.intl.formatMessage(
			{ id: 'invoice.remove.alert.title' },
			{
				description: this.periodicInvoiceConceptModel.description,
				customer: this.periodicInvoiceConceptModel.companyCustomer.companyName
			}
		);

		let body = null;
		if (this.periodicInvoiceConceptModel.status !== InvoiceConceptStatus.CREATED) {
			body = this.props.intl.formatMessage({ id: 'invoice.remove.alert.body' });
		}

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				onConfirm={this._doRemove}
				body={body}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	/**
	 *
	 */
	edit() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_PERIODIC_EDIT.getPath({
					id: company.id,
					invoiceId: this.periodicInvoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new DeletePeriodicInvoiceConceptCommand(
				this.context.applicationStore.periodicInvoicesConceptStore,
				this.periodicInvoiceConceptModel,
				company
			);
			command.execute(() => {
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_INVOICES_PERIODIC_EDIT.getPath({ id: company.id })
				);
			});
		}
	}

	/**
	 * Returns the right option button depending on the status of this invoice
	 *
	 * @returns {*}
	 * @private
	 */
	_getOptions() {
		return (
			<div className="invoice__options-top padding-small border--bottom border--dark grid grid--spread col--12">
				{this._getOptionsLeft()}
				{this._getOptionsRight()}
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsLeft() {
		return (
			<div className="invoice__options-left grid">
				{this.periodicInvoiceConceptModel.canTogglePaid() ? this._getPaidToggle() : null}

				<ToggleButton
					label={this.props.intl.formatMessage({ id: 'label.actions' })}
					chevronColor="black"
					options={this._generateActionOptions()}
					onChange={this.onAction}
				/>
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getOptionsRight() {
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		return (
			<div className="invoice__options-right grid grid--right">
				{this.periodicInvoiceConceptModel.canSend() ? (
					<button
						type="button"
						className="invoice__btn-toggle-settings button--primary btn-margin-left button icon icon--right icon--chevron-down icon--color"
						disabled={!financialYear}
						onClick={this.onToggleSettings}>
						<FormattedMessage
							id={
								this.periodicInvoiceConceptModel.isActive()
									? 'invoice.periodic.deactivate'
									: 'invoice.periodic.activate'
							}
						/>
					</button>
				) : null}
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	_getSettings() {
		return this._getConceptSettings();
	}

	/**
	 * Settings shown when invoice has can be send
	 *
	 * @returns {*}
	 * @private
	 */
	_getConceptSettings() {
		const company = this.context.applicationStore.getSelectedCompany();
		const { errors } = this.state;

		return (
			<div className="invoice__settings-wrapper col--12 grid">
				{/* Form */}
				<FormErrorContext.Provider value={{ errors }}>
					<FormGroup className="col--12">
						<FormField className="col--6 ">
							<label>
								<FormattedMessage id="invoice.settings.replyto" />
							</label>
							<input
								className="col--12"
								name="senderEmail"
								type="email"
								value={this.state.senderEmail}
								onChange={this.onInputChange}
								// required
								maxLength={255}
							/>
						</FormField>

						<FormField className="col--6 ">
							<label>
								<FormattedMessage id="invoice.settings.recipient" />
							</label>
							<input
								name="recipientEmail"
								type="email"
								value={this.state.recipientEmail}
								disabled
							/>
						</FormField>
						<FormField className="col--6 ">
							<label>
								<FormattedMessage id="invoice.settings.ccrecipient" />
							</label>
							<input
								name="ccRecipientEmail"
								type="email"
								value={this.state.ccRecipientEmail}
								onInput={this.onInputChange}
							/>
						</FormField>

						<FormField className="col--6 form-field__desktop-filler" />

						<FormField className="col--6 ">
							<label>
								<FormattedMessage id="invoice.settings.email.subject" />
							</label>
							<input
								className="col--12"
								name="emailSubject"
								type="text"
								// required
								value={this.state.emailSubject}
								onChange={this.onInputChange}
								maxLength={255}
							/>
						</FormField>

						<FormField className="col--12">
							<label>
								<FormattedMessage id="invoice.settings.email.message" />
							</label>
							<textarea
								className="col--12"
								name="emailMessage"
								value={this.state.emailMessage}
								required
								onChange={this.onInputChange}
							/>
						</FormField>

						<FormField className="col--12">
							<Checkbox
								className="col--12"
								name="sendToSelf"
								label={
									<FormattedHTMLMessage
										id="invoice.settings.sendcopy"
										values={{ email: company.email }}
									/>
								}
								checked={this.state.bccRecipientEmail !== ''}
								onChange={this.onInputChange}
							/>
							<Checkbox
								className="col--12"
								name="remindCompanyUserInDays"
								label={
									<FormattedHTMLMessage
										id="invoice.settings.remind.user.label"
										values={{ email: this.state.senderEmail }}
									/>
								}
								checked={this.state.remindCompanyUserInDays !== null}
								onChange={this.onInputChange}
							/>
						</FormField>
					</FormGroup>
				</FormErrorContext.Provider>

				<div className="invoice__send-options grid col--12">
					<FormField className="col--12 grid--spread">
						<button type="button" className="button--tertiary" onClick={this.onCancel}>
							<FormattedMessage id="label.cancel" />
						</button>

						{this.periodicInvoiceConceptModel.isActive() ? (
							<div className="grid grid--right col--6">
								<button
									type="button"
									className="invoice__btn-send button--primary button"
									onClick={this.onDeactivateInvoice}>
									<FormattedMessage id="invoice.periodic.deactivate" />
								</button>
							</div>
						) : (
							<div className="grid grid--right col--6">
								<button
									type="button"
									className="invoice__btn-send button--primary button"
									onClick={this.onActivateInvoice}>
									<FormattedMessage id="invoice.periodic.activate" />
								</button>
							</div>
						)}
					</FormField>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @private
	 */
	onGetPeriodicInvoiceConceptModel() {
		const company = this.context.applicationStore.getSelectedCompany();
		const user = this.context.applicationStore.user;

		// Load localised translations
		this.locale = user.language;
		switch (this.periodicInvoiceConceptModel.locale) {
			case Locale.en_US:
				this.locale = 'en';
				break;
			case Locale.nl_NL:
				this.locale = 'nl';
				break;
			default:
				this.locale = 'nl';
		}

		// Get translations for selected locale
		this.translations = getTranslations(this.locale);

		// Replace dynamic values
		const dynamicValues = createDynamicValues(
			user,
			company,
			this.periodicInvoiceConceptModel,
			this.props.intl
		);
		this._applyDefaults(company, dynamicValues);

		// Update render
		this.forceUpdate();
	}

	/**
	 * Fetch InvoiceConceptModel based on parameter invoiceId
	 *
	 * @private
	 */
	getInvoiceConceptModel() {
		if (
			this.context.applicationStore &&
			this.context.applicationStore.currentRouteParams &&
			this.context.applicationStore.currentRouteParams.invoiceId
		) {
			const company = this.context.applicationStore.getSelectedCompany();

			if (company) {
				this.periodicInvoiceConceptModel = new PeriodicInvoiceConceptModel();
				this.periodicInvoiceConceptModel.id = parseInt(
					this.context.applicationStore.currentRouteParams.invoiceId,
					10
				);
				const command = new GetPeriodicInvoiceConceptCommand(
					this.periodicInvoiceConceptModel,
					company
				);
				command.execute(() => {
					this.onGetPeriodicInvoiceConceptModel();
				});
			}
		}
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getSubtotal() {
		return (
			<InvoiceSummaryRow
				label={<FormattedMessage id="invoice.summary.subtotal" />}
				amount={this.periodicInvoiceConceptModel.getSubtotal()}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATLow() {
		const label = (
			<FormattedMessage
				id="invoice.summary.btw"
				values={{
					percentage: this.props.intl.formatMessage({
						id: BTW.LAAG.translationId(this.periodicInvoiceConceptModel.date)
					})
				}}
			/>
		);

		return (
			<InvoiceSummaryRow
				hideWhenZero={!this.periodicInvoiceConceptModel.containsVATType(BTW.LAAG)}
				label={label}
				amount={this.periodicInvoiceConceptModel.getBTWTotal(BTW.LAAG.name)}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getVATHigh() {
		const label = (
			<FormattedMessage
				id="invoice.summary.btw"
				values={{ percentage: this.props.intl.formatMessage({ id: BTW.HOOG.translationId() }) }}
			/>
		);
		return (
			<InvoiceSummaryRow
				hideWhenZero={!this.periodicInvoiceConceptModel.containsVATType(BTW.HOOG)}
				label={label}
				amount={this.periodicInvoiceConceptModel.getBTWTotal(BTW.HOOG.name)}
			/>
		);
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.periodicInvoiceConceptModel.containsVATType(BTW.VERLEGD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.reversed.summary" />}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.periodicInvoiceConceptModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<InvoiceSummaryRow
					label={<FormattedMessage id="invoice.vat.exempt.summary" />}
					amount={null}
				/>
			);
		}
		return null;
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getTotal() {
		return (
			<InvoiceSummaryRow
				label={<FormattedMessage id="invoice.summary.total" />}
				amount={this.periodicInvoiceConceptModel.getTotal()}
			/>
		);
	}

	/**
	 *
	 * @private
	 */
	_generateActionOptions() {
		const result = [];

		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.edit' }),
				'edit',
				'icon icon--left icon--edit-black',
				false,
				!this.periodicInvoiceConceptModel.canEdit()
			)
		);
		// result.push(new ToggleButtonOption(this.props.intl.formatMessage({id: 'label.duplicate'}), 'duplicate', 'icon icon--left icon--file-black'));
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'label.remove' }),
				'remove',
				'icon icon--left icon--delete-black',
				false,
				!this.periodicInvoiceConceptModel.canRemove()
			)
		);

		// result.push(new ToggleButtonOption(this.props.intl.formatMessage({id: 'label.archive'}), 'archive', 'icon icon--left icon--folder2-black', false, !this.periodicInvoiceConceptModel.canArchive()));
		// result.push(new ToggleButtonOption(this.props.intl.formatMessage({id: 'label.unarchive'}), 'unarchive', 'icon icon--left icon--folder2-black', false, !this.periodicInvoiceConceptModel.isArchived()));

		return result;
	}

	/**
	 *
	 * @private
	 */
	/**
	 *
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_generateStateOptions() {
		const result = [];

		const isOpen = this.periodicInvoiceConceptModel.isOpen();

		// SENT states, always hide when isSigned
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'invoice.paidswitch.label.no' }),
				null,
				'toggle-button-option--color-warning',
				isOpen
			)
		);
		result.push(
			new ToggleButtonOption(
				this.props.intl.formatMessage({ id: 'invoice.paidswitch.label.yes' }),
				InvoiceConceptStatus.PAID,
				'toggle-button-option--color-success',
				!isOpen
			)
		);

		return result;
	}

	/**
	 *
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_generateViewOptions() {
		const result = [];

		if (
			this.periodicInvoiceConceptModel.isPublic() &&
			!PropertiesController.getProperty(PropertiesController.FEATURE_DEMO)
		) {
			result.push(new ToggleButtonOption('Online', 'web'));
		}

		result.push(new ToggleButtonOption('PDF', 'pdf'));

		return result;
	}

	/**
	 *
	 * @private
	 */
	_applyDefaults(company, dynamicValues) {
		this.state.recipientEmail = this.periodicInvoiceConceptModel.recipientEmail
			? this.periodicInvoiceConceptModel.recipientEmail
			: this.periodicInvoiceConceptModel.companyCustomer.contactPersonEmail;
		this.state.senderEmail = this.periodicInvoiceConceptModel.senderEmail
			? this.periodicInvoiceConceptModel.senderEmail
			: company.invoicesSettings.senderEmail
			? company.invoicesSettings.senderEmail
			: company.email;
		this.state.bccRecipientEmail = this.periodicInvoiceConceptModel.bccRecipientEmail;
		this.state.ccRecipientEmail = this.periodicInvoiceConceptModel.ccRecipientEmail;

		// Pick prefilled messaged based on InvoiceConceptModel.locale
		if (this.periodicInvoiceConceptModel.locale === Locale.en_US) {
			this.state.emailSubject = this.periodicInvoiceConceptModel.emailSubject
				? this.periodicInvoiceConceptModel.emailSubject
				: addDynamicValues(
						company.invoicesSettings.defaultInvoiceConceptEmailSubjectEN,
						dynamicValues,
						this.props.intl,
						true
				  );
			this.state.emailMessage = this.periodicInvoiceConceptModel.emailMessage
				? this.periodicInvoiceConceptModel.emailMessage
				: addDynamicValues(
						company.invoicesSettings.defaultInvoiceConceptEmailMessageEN,
						dynamicValues,
						this.props.intl,
						true
				  );
			this.state.reminderSubject = addDynamicValues(
				company.invoicesSettings.reminderSubjectEN,
				dynamicValues,
				this.props.intl,
				true
			);
			this.state.reminderMessage = addDynamicValues(
				company.invoicesSettings.reminderMessageEN,
				dynamicValues,
				this.props.intl,
				true
			);
		} else {
			this.state.emailSubject = this.periodicInvoiceConceptModel.emailSubject
				? this.periodicInvoiceConceptModel.emailSubject
				: addDynamicValues(
						company.invoicesSettings.defaultInvoiceConceptEmailSubject,
						dynamicValues,
						this.props.intl,
						true
				  );
			this.state.emailMessage = this.periodicInvoiceConceptModel.emailMessage
				? this.periodicInvoiceConceptModel.emailMessage
				: addDynamicValues(
						company.invoicesSettings.defaultInvoiceConceptEmailMessage,
						dynamicValues,
						this.props.intl,
						true
				  );
			this.state.reminderSubject = addDynamicValues(
				company.invoicesSettings.reminderSubject,
				dynamicValues,
				this.props.intl,
				true
			);
			this.state.reminderMessage = addDynamicValues(
				company.invoicesSettings.reminderMessage,
				dynamicValues,
				this.props.intl,
				true
			);
		}

		this.state.remindCompanyUserInDays = company.invoicesSettings.remindCompanyUserInDays;
		this.state.bccRecipientEmail = company.invoicesSettings.bccRecipientEmail; // Send to self
	}
}

PeriodicInvoice.contextType = ApplicationContext;

PeriodicInvoice.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(PeriodicInvoice);
