import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

export const TYPE_TEXTAREA = 'TEXTAREA';
export const TYPE_TEXTINPUT = 'TEXTINPUT';

/**
 *
 */
export class TagTextArea extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.textarea = null;

		this.state = {
			value: this.props.value,
			scrollTop: 0,
			scrollLeft: 0,
			isFocused: false,
			selectionStart: -1,
			selectionEnd: -1
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const classes = classNames({
			'tag-textarea': true,
			'tag-textarea--focused': this.state.isFocused,
			'tag-textarea--type-input': this.props.type === TYPE_TEXTINPUT
		});

		// Input props
		const inputProps = {
			onChange: this.onChange,
			onKeyDown: this.onKeyDown,
			onKeyUp: this.onKeyUp,
			onFocus: this.onFocus,
			onScroll: this.onScroll,
			onBlur: this.onBlur,
			name: this.props.name,
			value: this.state.value
		};

		return (
			<div className={classes}>
				<div className="tag-textarea__container">
					{this.props.type === TYPE_TEXTINPUT ? (
						<input
							type="text"
							className="tag-textarea__textarea"
							placeholder={this.props.placeholder}
							maxLength={this.props.maxLength}
							required={this.props.required}
							autoComplete="off"
							ref={(ref) => {
								this.textarea = ref;
							}}
							{...inputProps}
						/>
					) : (
						<textarea
							className="tag-textarea__textarea"
							placeholder={this.props.placeholder}
							required={this.props.required}
							autoComplete="off"
							ref={(ref) => {
								this.textarea = ref;
							}}
							{...inputProps}
						/>
					)}
				</div>

				<div
					className="tag-textarea__tags"
					onClick={(e) => {
						this._focus();
						e.preventDefault();
					}}>
					{this._generateTags()}
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 8: // backspace
			case 46: // delete
				// eslint-disable-next-line no-case-declarations
				const found = this._isCursorOnTag();
				if (found) {
					this.textarea.setRangeText('', found.startIndex, found.endIndex);
					e.preventDefault();
				}
				break;
			case 37:
				break;
			default:
				break;
		}
	}

	/**
	 *
	 * @param e
	 */
	onKeyUp(e) {
		this.onChange(e);
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		this.setState({
			value: e.target.value,
			selectionStart: this.textarea.selectionStart,
			selectionEnd: this.textarea.selectionEnd
		});

		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}

	/**
	 *
	 * @param e
	 */
	onBlur(_e) {
		console.log('onBlur');
		this.setState({ isFocused: false });
	}

	/**
	 *
	 * @param e
	 */
	onFocus(_e) {
		console.log('onFocus');
		this.setState({ isFocused: true });
		// this._updateTextSelection();
	}

	/**
	 *
	 * @param e
	 */
	onScroll(_e) {
		// this.setState({
		// 	scrollTop:this.textarea.scrollTop,
		// 	scrollLeft: this.textarea.scrollLeft
		// });
	}

	/**
	 *
	 * @private
	 */
	_focus() {
		if (this.textarea) {
			this.textarea.focus();
		}
	}

	/**
	 *
	 * @private
	 */
	_generateTags() {
		return this.props.tags.map((tag, index) => {
			return (
				<div
					key={`tag-${index}`}
					className="tag tag--default"
					data-tagindex={index}
					data-tagtype={tag.type}
					title={this.props.intl.formatMessage({ id: tag.labelId })}
					onClick={(e) => {
						e.preventDefault();
						this._addTagToText(this.props.tags[e.currentTarget.dataset.tagindex]);
						this._focus();
					}}>
					<span>{tag.tag}</span>
				</div>
			);
		});
	}

	/**
	 *
	 * @param tag
	 * @private
	 */
	_addTagToText(tag) {
		const insert = `${tag.tag} `;
		const cursorPosition = this.textarea.selectionStart + insert.length;

		this.textarea.setRangeText(insert);
		this.textarea.setSelectionRange(cursorPosition, cursorPosition);
		this.setState({ value: this.textarea.value });

		this._triggerChange();
	}

	/**
	 *
	 * @private
	 */
	_triggerChange() {
		// Trigger change
		if (this.props.onChange) {
			this.props.onChange({
				target: {
					name: this.textarea.name,
					value: this.textarea.value
				}
			});
		}
	}

	/**
	 *
	 * @private
	 */
	_isCursorOnTag() {
		const pos = this.state.selectionEnd;
		let found = null;
		let startIndex = -1;
		let endIndex = -1;

		this.props.tags.forEach((tag) => {
			startIndex = pos - tag.tag.length;
			endIndex = pos;

			const sub = this.state.value.substring(startIndex, endIndex);
			if (sub === tag.tag) {
				found = { tag: tag.tag, startIndex, endIndex };
			}
		});

		return found;
	}

	/**
	 *
	 * @param searchStr
	 * @param haystack
	 * @param caseSensitive
	 * @return {Array}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getIndicesOf(searchStr, haystack, caseSensitive = true) {
		console.log(searchStr, haystack);

		const searchStrLen = searchStr.length;
		if (searchStrLen === 0) {
			return [];
		}
		let startIndex = 0;
		let index;
		const indices = [];
		if (!caseSensitive) {
			// str = haystack.toLowerCase();
			searchStr = searchStr.toLowerCase();
		}

		// eslint-disable-next-line no-cond-assign
		while ((index = haystack.indexOf(searchStr, startIndex)) > -1) {
			indices.push(index);
			startIndex = index + searchStrLen;
		}

		return indices;
	}
}

export default injectIntl(TagTextArea);

TagTextArea.propTypes = {
	intl: PropTypes.object,
	tags: PropTypes.array,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	maxLength: PropTypes.number,
	required: PropTypes.bool,
	placeholder: PropTypes.string
};

TagTextArea.defaultProps = {
	tags: [],
	value: '',
	type: TYPE_TEXTAREA,
	maxLength: 255,
	required: false
};
