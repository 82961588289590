import { action, observable } from 'mobx';
import GetPontoIntegrationSettingsCommand from '../commands/bank/GetPontoIntegrationSettingsCommand';

export default class PontoIntegrationStore {
	@observable clientId = null;

	@observable codeChallenge = null;

	@observable redirectUri = null;

	@observable fetched = false;

	fetching = false;

	constructor() {
		this.fetched = false;
		this.clientId = null;
		this.codeChallenge = null;
		this.redirectUri = null;
	}

	@action fetch({ companyId }) {
		if (!this.fetching && !this.fetched) {
			console.log('PontoIntegrationStore.fetch');
			const command = new GetPontoIntegrationSettingsCommand();
			this.fetched = false;
			this.fetching = true;
			return command.execute(companyId).then((response) => {
				if (response) {
					this.clientId = response.clientId;
					this.codeChallenge = response.codeChallenge;
					this.redirectUri = response.redirectUri;
					this.fetched = true;
					this.fetching = false;
				} else {
					this.fetching = false;
					this.reset();
				}
			});
		}
		return Promise.resolve();
	}

	@action reset() {
		this.fetched = false;
		this.clientId = null;
		this.codeChallenge = null;
		this.redirectUri = null;
		this.fetching = false;
	}
}
