export default {
	INVOICECONCEPT_LOGO: 'INVOICECONCEPT_LOGO',
	INVOICECONCEPT_ATTACHMENT: 'INVOICECONCEPT_ATTACHMENT',

	OFFER_LOGO: 'OFFER_LOGO',
	OFFER_ATTACHMENT: 'OFFER_ATTACHMENT',
	OFFER_SIGNATURE: 'OFFER_SIGNATURE',

	COMPANY_PROFILE_LOGO: 'COMPANY_PROFILE_LOGO',
	COMPANY_PROFILE_COVER: 'COMPANY_PROFILE_COVER',
	COMPANY_PROFILE_PHOTO: 'COMPANY_PROFILE_PHOTO',

	DRAFT: 'DRAFT'
};
