import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';
// eslint-disable-next-line no-unused-vars
import ExpenseStore from '../stores/ExpenseStore';
/**
 * This hook returns the income store.
 * @return {ExpenseStore}
 */
const useExpenseStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;
	const { expenseStore } = applicationStore;

	return expenseStore;
};

export default useExpenseStore;
