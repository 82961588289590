import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Signals from '../../../signals/Signals';

import InvoiceEntry from './InvoiceEntry';
import { ApplicationContext } from '../../../ApplicationContext';
import GetInvoicesConceptCommand from '../../../commands/invoiceConcepts/GetInvoicesConceptCommand';

/**
 *
 */
@observer
export default class InvoiceFeed extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.onCompanyChanged = this.onCompanyChanged.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.CompanyChanged.add(this.onCompanyChanged);
		this.onCompanyChanged();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.onCompanyChanged);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (!company || !!this.props.invoicesConceptStore.fetching) return null;

		const entries = this.props.invoicesConceptStore.importantInvoiceConcepts.map(
			(invoiceConceptModel, index) => {
				return (
					<InvoiceEntry
						key={`ie-${invoiceConceptModel.id}-${index}`}
						className="col--12"
						invoiceConceptModel={invoiceConceptModel}
						company={company}
					/>
				);
			}
		);

		let emptyList = null;
		if (entries.length <= 0 && !this.props.invoicesConceptStore.fetching) {
			emptyList = (
				<div className="invoice-feed__empty">
					<div className="invoice-feed__empty-content-wrapper">
						<div className="invoice-feed__empty-image" />
						<h2>
							<FormattedMessage id="invoice.feed.empty" />
						</h2>
					</div>
				</div>
			);
		}

		return (
			<div className="invoice-feed border--top border--dark grid">
				{entries}
				{emptyList}
			</div>
		);
	}

	/**
	 *
	 */
	onCompanyChanged() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			const command = new GetInvoicesConceptCommand(this.props.invoicesConceptStore, company.id);
			command.execute();
		}
	}
}

InvoiceFeed.contextType = ApplicationContext;

InvoiceFeed.propTypes = {
	invoicesConceptStore: PropTypes.object.isRequired
};
