import React from 'react';
import { injectIntl } from 'react-intl';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useBankRecordStore from '../../../../utils/useBankRecordStore';

function TransactionTableHeader({ intl }) {
	const bankRecordStore = useBankRecordStore();

	const isSorted = (fieldName) => {
		return bankRecordStore.sortField === fieldName;
	};

	const tableSortedClassName = (fieldName) => {
		if (isSorted(fieldName)) {
			const classes = ['table__header--sorted'];
			if (bankRecordStore.sortDirection === -1) {
				classes.push('table__header--sorted-up');
			}
			return classes.join(' ');
		}
		return '';
	};

	const handleSort = (fieldName) => {
		if (fieldName !== 'transactionNr') {
			if (isSorted(fieldName) && fieldName) {
				if (bankRecordStore.sortDirection === 1) {
					bankRecordStore.sortDirection = -1;
				} else {
					bankRecordStore.sortDirection = 1;
				}
			} else {
				bankRecordStore.sortField = fieldName;
				bankRecordStore.sortDirection = 1;
			}
			bankRecordStore.update();
		}
	};

	return (
		<Observer>
			{() => {
				return (
					<div className="flex flex-row bg-zinc-100">
						<div
							className={`table__header transaction-table-header transaction-date ${tableSortedClassName(
								'executionDate'
							)}`}
							onClick={() => handleSort('executionDate')}>
							{intl.formatMessage({
								id: 'transactions.header.date'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-name ${tableSortedClassName(
								'counterpartName'
							)}`}
							onClick={() => handleSort('counterpartName')}>
							{intl.formatMessage({
								id: 'transactions.header.name'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-description ${tableSortedClassName(
								'description'
							)}`}
							onClick={() => handleSort('description')}>
							{intl.formatMessage({
								id: 'transactions.header.description'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-category-label ${tableSortedClassName(
								'categoryId'
							)}`}
							onClick={() => handleSort('categoryId')}>
							{intl.formatMessage({
								id: 'transactions.header.category'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-status ${tableSortedClassName(
								'matchStatus'
							)}`}
							onClick={() => handleSort('matchStatus')}>
							{intl.formatMessage({
								id: 'transactions.header.status'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-processed ${tableSortedClassName(
								'isProcessed'
							)}`}
							onClick={() => handleSort('isProcessed')}>
							{intl.formatMessage({
								id: 'transactions.header.processed'
							})}
						</div>
						<div
							className={`table__header transaction-table-header transaction-amount ${tableSortedClassName(
								'amount'
							)}`}
							onClick={() => handleSort('amount')}>
							{intl.formatMessage({
								id: 'transactions.header.amount'
							})}
						</div>
						<div className="table__header transaction-table-header transaction-actions not-sortable">
							{intl.formatMessage({
								id: 'company.bankbook.header.actions'
							})}
						</div>
					</div>
				);
			}}
		</Observer>
	);
}

TransactionTableHeader.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(TransactionTableHeader);
