import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function useOutsideClickHandler(ref, handler) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handler();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

function OutSideClickHandler(props) {
	const wrapperRef = useRef(null);
	useOutsideClickHandler(wrapperRef, props.onClickOutside);

	return <div ref={wrapperRef}>{props.children}</div>;
}

OutSideClickHandler.propTypes = {
	children: PropTypes.element.isRequired,
	onClickOutside: PropTypes.func.isRequired
};

export default OutSideClickHandler;
