import Signals from '../../signals/Signals';
import getBankRecordCategories from '../../requests/bank/getBankRecordCategories';

export default class GetBankRecordCategoriesCommand {
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.companyId = null;
	}

	execute(companyId) {
		this.companyId = companyId;
		return getBankRecordCategories(companyId).then(this.then).catch(this.catch);
	}

	then(response) {
		return response;
	}

	catch(error) {
		Signals.Error.dispatch(error);
	}
}
