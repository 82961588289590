import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import FilterButtonOption from './FilterButtonOption';
import Signals from '../../../signals/Signals';
/**
 *
 */
export default class FilterButton extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { expanded: false };
		this.buttonRef = null;
		this.optionsRef = null;

		this.onResize = this.onResize.bind(this);
		this.onToggleAllFilterOptionsClick = this.onToggleAllFilterOptionsClick.bind(this);
		this.toggle = this.toggle.bind(this);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		this.setMaxWidth();
		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {
		this.setMaxWidth();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.RootClick.remove(this.close);
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { showToggle } = this.props;
		const classes = classNames({
			'filter-button': true,
			'filter-button--expanded': this.state.expanded,
			'filter-button--no-toggle': !showToggle
		});

		const buttonClassObject = {};
		buttonClassObject['icon icon--right icon--color icon--chevron-down'] = !this.state.expanded;
		buttonClassObject['icon icon--right icon--color icon--chevron-up'] = this.state.expanded;
		buttonClassObject[this.props.buttonClassName] = !!this.props.buttonClassName;

		const buttonClasses = classNames(buttonClassObject);

		return (
			<div className={`${classes} ${this.props.className}`}>
				<button
					type="button"
					ref={(ref) => {
						this.buttonRef = ref;
					}}
					className={`${buttonClasses} ${this.props.buttonClassName}`}
					onClick={this.toggle}>
					{this.props.label}
				</button>
				<div
					ref={(ref) => {
						this.optionsRef = ref;
					}}
					className="filter-button__options">
					{this.generateOptions()}

					{showToggle ? (
						<div
							className="filter-button__toggle text-center border--top"
							onClick={this.onToggleAllFilterOptionsClick}>
							<FormattedMessage id="filter.button.toggle" />
						</div>
					) : null}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.setMaxWidth();
	}

	/**
	 *
	 */
	onToggleAllFilterOptionsClick(e) {
		e.stopPropagation();
		e.preventDefault();

		const { options, name, onChange } = this.props;
		if (this.allOptionUnchecked) {
			options.forEach((option) => {
				if (!option.checked) {
					onChange(name, option.value, true, true);
				}
			});
		} else {
			options.forEach((option) => {
				if (option.checked) {
					onChange(name, option.value, false, true);
				}
			});
		}

		onChange(name);
	}

	/**
	 *
	 * @return {boolean}
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	get allOptionChecked() {
		const { options } = this.props;
		return !options.find((option) => !option.checked);
	}

	/**
	 *
	 * @return {boolean}
	 */
	get allOptionUnchecked() {
		const { options } = this.props;
		return !options.find((option) => option.checked);
	}

	/**
	 *
	 * @param e
	 */
	toggle(e) {
		console.log('toggle', e);
		e.preventDefault();
		// e.stopPropagation();

		if (!this.state.expanded) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		console.log('open');
		this.setState({ expanded: true }, () => {
			Signals.RootClick.add(this.close);
		});
	}

	/**
	 *
	 */
	close() {
		console.log('close');
		Signals.RootClick.remove(this.close);
		this.setState({ expanded: false });
	}

	/**
	 *
	 * @return {any[]}
	 */
	generateOptions() {
		const { name, options, onChange } = this.props;

		return options.map((option, index) => {
			if (option.hidden) {
				return null;
			}

			return (
				<div
					key={`fbo-${index}`}
					className={`filter-button-option border--top ${option.classes}`}
					data-name={option.value}
					data-value={option.value}
					data-checked={option.checked}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						onChange(name, option.value, !option.checked);
					}}>
					<div className="filter-button-option__check icon icon--check-white" />
					<span className="filter-button-option__label">{option.label}</span>
				</div>
			);
		});
	}

	/**
	 *
	 */
	setMaxWidth() {
		//
		if (!this.optionsRef || !this.optionsRef.children) {
			return;
		}

		// Gather elements, including toggle button button
		const elements = [this.buttonRef];
		let minWidth = 0;
		let i;

		for (i = 0; i < this.optionsRef.children.length; i++) {
			elements.push(this.optionsRef.children[i]);
		}

		// Clear min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			el.style['min-width'] = null;
		}

		// Determine min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			const width = el.offsetWidth;
			minWidth = width > minWidth ? width : minWidth;
		}

		// Set min width
		for (i = 0; i < elements.length; i++) {
			const el = elements[i];
			if (el.tagName === 'button') {
				el.style['min-width'] = `${minWidth + 2}px`; // To cancel out border from options
			} else {
				el.style['min-width'] = `${minWidth}px`;
			}
		}
	}
}

FilterButton.propTypes = {
	className: PropTypes.string,
	buttonClassName: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.instanceOf(FilterButtonOption)).isRequired,
	onChange: PropTypes.func,
	showToggle: PropTypes.bool
};

FilterButton.defaultProps = {
	className: '',
	buttonClassName: '',
	// eslint-disable-next-line react/default-props-match-prop-types
	value: null
};
