import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import NotificationNumber from '../NotificationNumber/NotificationNumber';
import Signals from '../../../signals/Signals';
import TodoTooltip from '../Todo/TodoTooltip';
import { ApplicationContext } from '../../../ApplicationContext';

@observer
/**
 *
 */
export default class NotificationButton extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.el = null;
		this.onClick = this.onClick.bind(this);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const totalTodos = this.props.todoStore.getTotalTodos();
		const classes = classNames({
			'notification-button': true,
			'notification-button--has-todos': totalTodos > 0,
			'notification-button--has-new': this.props.todoStore.hasNewTodos()
		});

		const iconClasses = classNames({
			icon: true,
			'icon--left': true,
			'icon--notification': true
		});

		return (
			<div ref={(ref) => (this.el = ref)} className={classes} onClick={this.onClick}>
				<div className="notification-button__content">
					<span className={iconClasses} />
				</div>
				<NotificationNumber count={totalTodos} />
			</div>
		);
	}

	/**
	 *
	 */
	onClick() {
		if (!this.context.applicationStore.showToolTip) {
			Signals.ShowToolTip.dispatch(
				<TodoTooltip todoStore={this.props.todoStore} />,
				this.el,
				16,
				-8
			);
			Signals.HideToolTip.addOnce(() => {
				// Reset 'new' todo state
				this.props.todoStore.seenTodos();
			});
		}
	}
}

NotificationButton.contextType = ApplicationContext;

NotificationButton.propTypes = {
	todoStore: PropTypes.object.isRequired
};

NotificationButton.defaultProps = {};
