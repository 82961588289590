import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import SVG from 'svg.js';

import LedgerCodes from '../../../../data/LedgerCodes';

import ResultRow from './ResultRow';
import { uniqueKey } from '../../../../utils/ReactUtils';

const LINE_THICKNESS = 2;

/**
 *
 */
class ResultsGraphPanel extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.uid = uniqueKey('grph-');
		this.graphWrapper = null;

		this.onResize = this.onResize.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		// SVG object
		this.svg = SVG(this.uid).size('100%', '100%');

		// Amount lines buffer elements
		this.amountLines = [];
		for (let i = 0; i < 32; i++) {
			this.amountLines.push(
				this.svg.line(0, -1, 1, -1).stroke({ width: 1, color: window.COLORS.LIGHT_GREY })
			);
		}

		// Income graph line
		this.gradientIncome = this.svg
			// eslint-disable-next-line func-names
			.gradient('linear', function (stop) {
				stop.at(0, window.COLORS.RESULTS.PRIMARY, 1);
				stop.at(1, window.COLORS.RESULTS.PRIMARY, 1);
			})
			.from(0, 0)
			.to(0, 1);

		this.income = this.svg.polyline([]);
		this.income.attr({ fill: this.gradientIncome });
		this.income.stroke({
			color: window.COLORS.RESULTS.PRIMARY_LINE,
			width: LINE_THICKNESS,
			linecap: 'round',
			linejoin: 'round'
		});

		// Expenses graph line
		this.gradientExpense = this.svg
			// eslint-disable-next-line func-names
			.gradient('linear', function (stop) {
				stop.at(0, window.COLORS.RESULTS.SECONDARY, 0.4);
				stop.at(1, window.COLORS.RESULTS.SECONDARY, 0.7);
			})
			.from(0, 0)
			.to(0, 1);

		this.expenses = this.svg.polyline([]);
		this.expenses.attr({ fill: this.gradientExpense });
		this.expenses.stroke({
			color: window.COLORS.RESULTS.SECONDARY_LINE,
			width: LINE_THICKNESS,
			linecap: 'round',
			linejoin: 'round'
		});

		this.redrawGraph();

		window.addEventListener('resize', this.onResize);
	}

	/**
	 *
	 */
	componentDidUpdate() {
		this.redrawGraph();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const yearResultStore = this.props.yearResultStore;
		const monthStart = (this.props.quarter - 1) * 3 + 1; // Ignore month 0 because it contains the results from the last month of the previous month, also from previous year
		const monthEnd = this.props.quarter * 3;

		const housingTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4200,
			LedgerCodes.GC_4201
		]);
		const salesTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4400,
			LedgerCodes.GC_4401
		]);
		const officeTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4500,
			LedgerCodes.GC_4501
		]);
		const travelTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4300,
			LedgerCodes.GC_4301,
			LedgerCodes.GC_4302
		]);
		const productionTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_7100,
			LedgerCodes.GC_7101,
			LedgerCodes.GC_7102
		]);
		const consumptionTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4600
		]);
		const personnelTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4100
		]);
		const otherTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4602
		]);

		const investmentTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_0100,
			LedgerCodes.GC_0101
		]);

		const revenueTotal = yearResultStore.getTotalRevenue(monthStart, monthEnd);
		const expensesTotal =
			housingTotal +
			salesTotal +
			officeTotal +
			travelTotal +
			productionTotal +
			consumptionTotal +
			personnelTotal +
			otherTotal; // always exclude investmentTotal from expenses
		const profit = revenueTotal - expensesTotal;

		const classes = classNames({
			'results-graph-panel--expanded': this.props.expanded,
			'icon icon--right icon--chevron-up-smoke': this.props.expanded,
			'icon icon--right icon--chevron-down-smoke': !this.props.expanded
		});

		return (
			<div
				className={`results-graph-panel ${this.props.className} ${classes}`}
				onClick={this.toggle}>
				<div className="results-graph-panel__header border--bottom border--dark padding">
					<h2>Q{this.props.quarter}</h2>
				</div>

				<div
					ref={(graphWrapper) => (this.graphWrapper = graphWrapper)}
					className="results-graph-panel__graph-wrapper">
					<div id={this.uid} className="results-graph-panel__graph" />
				</div>

				<div className="results-graph-panel__info grid grid--spread">
					<ResultRow
						className="col--12 results-graph-panel__revenue-row"
						label="results.revenue"
						value={revenueTotal}
						labelSuffix={<b>—</b>}
					/>
					<ResultRow
						className="col--12 results-graph-panel__costs-row"
						label="results.total.costs"
						value={expensesTotal}
						labelSuffix={<b>—</b>}
					/>

					<div className="results-graph-panel__info-extra grid">
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.productie"
							value={productionTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.kantoor"
							value={officeTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.reis"
							value={travelTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.huisvesting"
							value={housingTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.verkoop"
							value={salesTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.consumptie"
							value={consumptionTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.personnel"
							value={personnelTotal}
							isLight
						/>
						<ResultRow
							className="col--12"
							labelPrefix
							label="results.overig"
							value={otherTotal}
							isLight
						/>
					</div>

					{/* <ResultRow className="col--12" label="results.profit" value={profit}/> */}
					<ResultRow className="col--12" label="results.investering" value={investmentTotal} />
				</div>

				<div className="results-graph-panel__last-row grid">
					<ResultRow className="col--12" label="results.profit" value={profit} isDark />
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onResize() {
		this.forceUpdate();
	}

	/**
	 *
	 */
	redrawGraph() {
		const w = this.graphWrapper.offsetWidth;
		const h = this.graphWrapper.offsetHeight;
		const padding = 100;

		const startPoint = [-padding, h + padding];
		const endPoint = [w + padding, h + padding];

		// Build income array based on incomeData
		const incomeArray = [startPoint];
		this.props.incomeData.forEach((value, index) => {
			incomeArray.push([(index / (this.props.incomeData.length - 1)) * w, value * h]);
		});
		incomeArray.push(endPoint);

		// Build expense array based on expensesData
		const expenseArray = [startPoint];
		this.props.expensesData.forEach((value, index) => {
			expenseArray.push([(index / (this.props.expensesData.length - 1)) * w, value * h]);
		});
		expenseArray.push(endPoint);

		// Grid lines background
		this.amountLines.forEach((line, index) => {
			const percentage = index <= this.props.steps ? (index + 1) / this.props.steps : -1;
			const y = h * percentage;
			line.plot(0, y, w, y);
		});

		//
		this.income.plot(incomeArray);
		this.expenses.plot(expenseArray);
	}

	/**
	 *
	 */
	toggle() {
		if (this.props.onChange) {
			this.props.onChange(this, !this.props.expanded);
		}
	}
}

ResultsGraphPanel.propTypes = {
	className: PropTypes.string,
	steps: PropTypes.number,
	incomeData: PropTypes.array.isRequired,
	expensesData: PropTypes.array.isRequired,
	quarter: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	expanded: PropTypes.bool.isRequired,
	yearResultStore: PropTypes.object.isRequired
};

ResultsGraphPanel.defaultProps = {
	// eslint-disable-next-line react/default-props-match-prop-types
	expanded: false,
	steps: 1
};

export default injectIntl(ResultsGraphPanel);
