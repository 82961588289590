import { observable } from 'mobx';

import CookieController, { COOKIE_TYPES } from '../../controllers/CookieController';

// eslint-disable-next-line import/no-cycle
import Company from './Company';
import Address from './Address';
import BookKeeper from './BookKeeper';
import DraftsStore from '../DraftsStore';

/**
 *
 */
export default class User {
	@observable id = undefined;

	@observable firstName = undefined;

	@observable lastName = undefined;

	@observable username = undefined;

	@observable language = 'nl';

	@observable fiscalPartner = false;

	@observable initials = undefined;

	@observable phoneNumber = undefined;

	@observable email = undefined;

	@observable bsn = undefined;

	@observable birthDay = undefined;

	@observable address = undefined;

	@observable passportFile = undefined;

	@observable acceptedPrivacyTerms = undefined;

	@observable companies = [];

	@observable bookKeeper = undefined;

	@observable partnerDeclarationType;

	@observable partnerInitials;

	@observable partnerLastName;

	@observable partnerBirthday;

	@observable partnerBsn;

	@observable partnerAddress;

	@observable partnerCity;

	@observable partnerPostalCode;

	draftsStore = new DraftsStore(this);

	/**
	 *
	 * @returns {string}
	 */
	getFullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	/**
	 *
	 * @returns {null}
	 */
	getFirstCompany() {
		return this.companies.length > 0 ? this.companies[0] : null;
	}

	/**
	 *
	 * @param id
	 * @returns {*}
	 */
	getCompanyById(id) {
		if (id === null) return null;

		let r = null;

		this.companies.map((company) => {
			if (company.id === id) {
				r = company;
				return true;
			}
			return false;
		});

		return r;
	}

	/**
	 *
	 * @param company
	 * @return {number}
	 */
	getCompanyIndex(company) {
		return this.companies.findIndex((value) => value === company);
	}

	/**
	 *
	 */
	isEnglish() {
		return this.language === 'en';
	}

	/**
	 *
	 * @return {boolean}
	 */
	ignoreHours() {
		let ignoreHours = true;
		this.companies.forEach((company) => {
			if (!company.ignoreHours()) {
				ignoreHours = false;
			}
		});
		return ignoreHours;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		const dateOffset = 12 * 3600 * 1000; // Add 12 hours to prevent rounding issues Edge

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'birthday':
						this.birthDay = data.birthday ? new Date(data.birthday + dateOffset) : undefined;
						break;
					case 'partnerBirthday':
						this.partnerBirthday = data.partnerBirthday
							? new Date(data.partnerBirthday + dateOffset)
							: undefined;
						break;
					case 'address':
						this[key] = Address.parseAddress(data[key]);
						break;
					case 'language':
						this[key] = data[key] ? data[key].toLowerCase() : 'nl';
						break;
					case 'companies':
					case 'bookkeeper':
						// ignore
						break;
					default:
						this[key] = data[key] == null ? undefined : data[key];
				}
			}
		}
	}

	/**
	 *
	 * @returns {boolean}
	 */
	requiresOnBoarding() {
		const fields = [
			this.birthDay,
			this.address.address,
			this.address.postalCode,
			this.address.city,
			this.phoneNumber,
			this.bsn,
			this.passportFileName
		];

		let isRequired = false;
		fields.forEach((field) => {
			if (!field) {
				isRequired = true;
			}
		});

		// Check companies if User doesn't require on boarding
		if (!isRequired) {
			this.companies.forEach((company) => {
				if (company.requiresOnBoarding()) {
					isRequired = true;
				}
			});
		}

		return isRequired;
	}

	/**
	 *
	 * @returns {string}
	 */
	toString() {
		let s = `User: ${this.firstName}, ${this.lastName}, ${this.username}, ${this.language}`;
		this.companies.forEach((company) => {
			s += `\n${company.toString()}`;
		});

		return s;
	}

	/**
	 *
	 * @param data
	 * @returns {User}
	 */
	static parseUserData(data) {
		const user = new User();

		if (!data) {
			return user;
		}

		const dateOffset = 12 * 3600 * 1000; // Add 12 hours to prevent rounding issues Edge

		user.id = data.id;
		user.firstName = User._normalizeValue(data.firstName);
		user.lastName = User._normalizeValue(data.lastName);
		user.username = User._normalizeValue(data.username);
		user.language = data.language ? data.language.toLowerCase() : 'nl';

		user.fiscalPartner = data.fiscalPartner;
		user.initials = User._normalizeValue(data.initials);
		user.phoneNumber = User._normalizeValue(data.phoneNumber);
		user.email = User._normalizeValue(data.email);
		user.bsn = User._normalizeValue(data.bsn);
		user.birthDay = data.birthday ? new Date(data.birthday + dateOffset) : undefined;

		user.address = Address.parseAddress(data.address);
		user.bookKeeper = BookKeeper.parseBookKeeper(data.bookkeeper);

		user.acceptedPrivacyTerms = User._normalizeValue(data.acceptedPrivacyTerms);

		user.partnerDeclarationType = User._normalizeValue(data.partnerDeclarationType);
		user.partnerInitials = User._normalizeValue(data.partnerInitials);
		user.partnerLastName = User._normalizeValue(data.partnerLastName);
		user.partnerBirthday = User._normalizeValue(data.partnerBirthday);
		user.partnerBsn = User._normalizeValue(data.partnerBsn);
		user.partnerAddress = User._normalizeValue(data.partnerAddress);
		user.partnerCity = User._normalizeValue(data.partnerCity);
		user.partnerPostalCode = User._normalizeValue(data.partnerPostalCode);

		// Sort companies by name
		if (data.companies) {
			// Sort by name
			data.companies.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});

			// Get last selected company, sort companies again

			const previousSelectedId = parseInt(
				`${CookieController.getCookie(COOKIE_TYPES.SELECTED_COMPANY_COOKIE_ID)}`,
				10
			);
			if (!isNaN(previousSelectedId)) {
				data.companies.sort((a, b) => {
					// eslint-disable-next-line no-nested-ternary
					return a.id === previousSelectedId ? -1 : b.id === previousSelectedId ? 1 : 0;
				});
			}

			// Parse data per company
			user.companies = data.companies
				? data.companies.map((companyData) => {
						return Company.parseCompany(companyData);
				  })
				: [];
		} else {
			user.companies = [];
		}

		return user;
	}

	/**
	 *
	 * @param value
	 * @returns {undefined}
	 * @private
	 */
	static _normalizeValue(value) {
		return value || undefined;
	}

	/**
	 * Used to modify values before turning into JSON
	 * and prevent circular structure errors
	 *
	 * @param key
	 * @param value
	 * @return {boolean}
	 */
	static jsonReplacer(key, value) {
		switch (key) {
			case 'draftsStore':
				return undefined;
			default:
				return value;
		}
	}
}
