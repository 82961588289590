/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
export function getTranslations(locale = 'nl') {
	console.log('getTranslations for locale: ', locale);

	const result = {};

	for (const id in window.translations[0]) {
		if (window.translations[0].hasOwnProperty(id)) {
			result[id] = window.translations[0][id][locale];
		}
	}

	return result;
}

export function createTranslationJson() {
	const keys = [];
	for (const id in window.translations[0]) {
		if (window.translations[0].hasOwnProperty(id)) {
			keys.push(id);
		}
	}

	keys.sort();
	const json = {};
	keys.forEach((id) => {
		json[id] = window.translations[0][id];
	});

	console.log(JSON.stringify(json));
}
