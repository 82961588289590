import React from 'react';

import AbstractCommand from '../AbstractCommand';
import userDraftsCreate from '../../requests/drafts/userDraftsCreate';
import objectToFormData from '../../utils/objectToFormData';
import DraftTypes from '../../data/DraftTypes';
import Draft from '../../stores/models/Draft';
import FetchOptions from '../../requests/FetchOptions';

/**
 *
 */
export default class CreateUserDraftCommand extends AbstractCommand {

	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param userId Number
	 * @param file File
	 */
	constructor(draftsStore, userId, file = null) {
		super();

		// nescessary to allow cancel and progress tracking
		this.options = new FetchOptions();

		this.draft = new Draft();

		this.draftsStore = draftsStore;
		this.userId = userId;
		this.file = file;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		// Prepare request data
		const formData = objectToFormData(this.draft);
		formData.append('type', DraftTypes.DOCUMENT);

		if(this.file) {
			formData.append('file', this.file);
		}

		// Start request
		return userDraftsCreate(this.userId, formData, this.options).then((response) => {

			// Parse data and add to store
			this.draft.update(response.data);
			this.draft.isNew = true;
			this.draftsStore.add(this.draft);

			if(success) {
				success(response, this.draft);
			}

		}).catch(err => {
			if(error) {
				error(err)
			}
		});
	}
}
