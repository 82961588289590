import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PropertiesController from '../../../controllers/PropertiesController';
import Context from '../../../data/Context';

/**
 *
 */
export default class DropDown extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { focus: false };

		this.select = null;
		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const useLight = PropertiesController.getContext() !== Context.FOUNDERS;

		const classes = classNames({
			'icon--chevron-down-navy': useLight,
			'icon--chevron-down-black': !this.props.color,
			'icon--chevron-down-white': !useLight && this.props.color === 'navy',
			'icon--chevron-down-smoke': !useLight && this.props.color === 'smoke',
			'drop-down--navy': this.props.color === 'navy' || this.props.color === 'smoke',
			'drop-down--focus': this.state.focus,
			'drop-down--disabled': this.props.disabled,
			'icon icon--right': !this.props.disabled
		});

		return (
			<div
				className={`drop-down  ${classes} ${this.props.className ? this.props.className : ''}`}
				onClick={this.onClick}>
				<select
					ref={(select) => (this.select = select)}
					name={this.props.name}
					value={this.props.value}
					required={this.props.required}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					disabled={this.props.disabled}>
					{this.props.children}
				</select>
			</div>
		);
	}

	/**
	 *
	 */
	onClick() {
		this.select.click();
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}

	/**
	 *
	 * @param e
	 */
	onFocus() {
		this.setState({ focus: true });
	}

	/**
	 *
	 * @param e
	 */
	onBlur() {
		this.setState({ focus: false });
	}
}

DropDown.propTypes = {
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	required: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool
};
