import React from 'react';
import AbstractCommand from '../AbstractCommand';
import offerDecline from '../../requests/offers/offerDecline';
import OfferStatus from '../../data/OfferStatus';
import Signals from '../../signals/Signals';
import {FormattedHTMLMessage} from 'react-intl';

/**
 *
 */
export default class DeclineOfferCommand extends AbstractCommand {

	/**
	 *
	 * @param offerModel OfferModel
	 */
	constructor(offerModel) {
		super();

		this.offerModel = offerModel;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return offerDecline(this.offerModel.publicAccessId)
			.then((response) => {

				// Set declines state on offerModel
				this.offerModel.status = OfferStatus.DECLINED;
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {
				Signals.Error.dispatch(err);

				if(error) {
					error(err)
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="offer.declined.message"
								   values={{offerNr: this.offerModel.offerNr}}/>)
		);
	}
}
