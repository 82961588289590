import React, { useContext, useEffect } from 'react';
import Page from '../../Page';
import { ApplicationContext } from '../../../ApplicationContext';
import Signals from '../../../signals/Signals';
import { Routes } from '../../../data/Routes';
import SavePontoAuthCode from '../../../commands/bank/SavePontoAuthCode';
import useBankbookStatusStore from '../../../utils/useBankbookStatusStore';

function PontoCallback() {
	const command = new SavePontoAuthCode();
	const bankbookStatusStore = useBankbookStatusStore();
	const { applicationStore } = useContext(ApplicationContext);
	const [routeParams, setRouteParams] = React.useState({
		id: null,
		year: null
	});
	const [code, setCode] = React.useState(null);
	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		const params = applicationStore.getSearchParams();
		if (params) {
			setCode(params.get('code'));
			if (params.get('state')) {
				const state = JSON.parse(params.get('state'));
				setRouteParams((prevState) => ({
					...prevState,
					id: state.companyId,
					year: state.year
				}));
			}
			setError(params.get('error'));
		} else {
			Signals.RequestRoute.dispatch(Routes.COMPANY_PONTO_ONBOARDING.getPath(routeParams));
		}
	}, [applicationStore]);

	console.log('PontoCallback routeParams', routeParams);

	useEffect(() => {
		if (error && !code) {
			console.log('Ponto Callback:', error);
			console.log('Ponto Callback:', routeParams);
			Signals.RequestRoute.dispatch(Routes.COMPANY_PONTO_ONBOARDING.getPath(routeParams));
			Signals.Error.dispatch(new Error(error));
		} else if (code) {
			setLoading(true);
			command
				.execute(routeParams.id, code)
				.then(() => {
					setLoading(false);
					bankbookStatusStore.connected = true;
					Signals.RequestRoute.dispatch(Routes.COMPANY_PONTO_ONBOARDING.getPath(routeParams));
				})
				.catch((error) => {
					setLoading(false);
					Signals.Error.dispatch(error);
				});
		}
	}, [error, code, routeParams]);

	return (
		<Page pageName="ponto-callback">
			{loading ? (
				<div className="loading-screen loading-screen--show">
					<div className="loading-screen__background" />
					<div className="loading-screen__animation" />
				</div>
			) : null}
		</Page>
	);
}

export default PontoCallback;
