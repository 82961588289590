import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProgressStatusModel, { PROGRESS_STATUS } from '../../../stores/models/ProgressStatusModel';
import ProgressCircle from '../ProgressCircle/ProgressCircle';

/**
 *
 */
@observer
export default class ProgressStatusRow extends React.Component {
	/**
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = { draftRemoved: false };
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { progressStatusModel } = this.props;
		const { draftRemoved } = this.state;
		const { label } = progressStatusModel;

		const classes = classNames({
			'progress-status-row': true,
			'progress-status-row--removed': draftRemoved,
			'progress-status-row--waiting': progressStatusModel.state === PROGRESS_STATUS.WAITING,
			'progress-status-row--started': progressStatusModel.state === PROGRESS_STATUS.STARTED,
			'progress-status-row--failed': progressStatusModel.state === PROGRESS_STATUS.FAILED,
			'progress-status-row--success': progressStatusModel.state === PROGRESS_STATUS.SUCCESS
		});

		return (
			<div className={classes}>
				<div className="progress-status-row__row">
					<div className="progress-status-row__icon">{this._getIconOrProgress()}</div>
					<span className="progress-status-row__label" title={label}>
						{label}
					</span>
					<span className="progress-status-row__mime-type" />
					{/* <div className="progress-status-row__close icon icon icon--close-black" onClick={this.onCancel}/> */}
					{/* <div className="progress-status-row__edit icon icon icon--chevron-right-black"/> */}
				</div>
			</div>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getIconOrProgress() {
		const { progress, state } = this.props.progressStatusModel;

		switch (state) {
			case PROGRESS_STATUS.WAITING:
			case PROGRESS_STATUS.STARTED:
				return <ProgressCircle percentage={progress} />;
			case PROGRESS_STATUS.SUCCESS:
				return <div className="icon icon--check-success" />;
			case PROGRESS_STATUS.FAILED:
				return <div className="icon icon--close-danger" />;
			default:
				return null;
		}
	}
}

ProgressStatusRow.propTypes = {
	progressStatusModel: PropTypes.instanceOf(ProgressStatusModel).isRequired
};
