import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import OfferModel from '../../../../stores/models/OfferModel';
import Company from '../../../../stores/models/Company';
import OfferAttachmentRow from './OfferAttachmentRow';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class OfferAttachments extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.props.offerModel.assetAttachments.length <= 0) {
			return null;
		}

		return (
			<div className="offer-attachments offer__panel panel grid col--12 box-shadow border border--dark">
				<div className="offer-attachments__header padding grid grid--spread">
					<h2>
						<FormattedMessage id="offer.attachments.title" />
					</h2>
				</div>
				<div className="offer-attachments__rows grid">{this._getRows()}</div>
			</div>
		);
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getRows() {
		return this.props.offerModel.assetAttachments.map((fileAsset, index) => {
			return (
				<OfferAttachmentRow
					key={`inv-att-${index}`}
					company={this.props.company}
					fileAsset={fileAsset}
				/>
			);
		});
	}
}

OfferAttachments.contextType = ApplicationContext;

OfferAttachments.propTypes = {
	offerModel: PropTypes.instanceOf(OfferModel),
	company: PropTypes.instanceOf(Company),
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(OfferAttachments);
