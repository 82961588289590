/**
 *
 */
export default class FileAsset {
	/**
	 *
	 * @param uuid
	 * @param filename
	 * @param contentType
	 * @param sizeInBytes
	 * @param type
	 */
	constructor(uuid, filename, contentType, sizeInBytes, type) {
		this.uuid = uuid;
		this.filename = filename;
		this.contentType = contentType;
		this.sizeInBytes = sizeInBytes;
		this.type = type;
	}

	/**
	 * @param obj
	 * @returns {FileAsset}
	 */
	static createFromObject(obj) {
		return new FileAsset(obj.uuid, obj.filename, obj.contentType, obj.sizeInBytes, obj.type);
	}
}
