import AbstractCommand from '../AbstractCommand';
import companyDraftsCreate from '../../requests/drafts/companyDraftsCreate';
import objectToFormData from '../../utils/objectToFormData';
import Draft from '../../stores/models/Draft';
import FetchOptions from '../../requests/FetchOptions';

/**
 *
 */
export default class CreateCompanyDraftCommand extends AbstractCommand {

	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param companyId Number
	 * @param type String
	 * @param file File
	 * @param financialYearId Number
	 * @param draft Number
	 */
	constructor(draftsStore, companyId, type, file = null, financialYearId = null, draft = new Draft()) {
		super();

		this.options = new FetchOptions();
		this.draft = draft;
		this.draft.boekjaarId = financialYearId;

		this.draftsStore = draftsStore;
		this.companyId = companyId;
		this.type = type;
		this.file = file;

	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {

		// Prepare request data
		const formData = objectToFormData(this.draft);
		formData.append('type', this.type);
		if(this.file) {
			formData.append('file', this.file);
		}

		// Start request
		return companyDraftsCreate(this.companyId, formData, this.options).then((response) => {

			// Parse data and add to store
			this.draft.update(response.data);
			this.draft.isNew = true;
			this.draftsStore.add(this.draft);

			if(success) {
				success(response, this.draft);
			}

		}).catch(err => {
			if(error) {
				error(err)
			}
		});
	}
}
