import React from 'react';
import PropTypes from 'prop-types';
import FileAsset from '../../../../stores/models/FileAsset';
import { buildCompanyAssetURL } from '../../../../requests/companyAssetGet';
import { formatBytes } from '../../../../utils/StringUtils';
import { buildPublicAssetURL } from '../../../../utils/assetUtils';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
export default class InvoiceAttachmentRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { fileAsset, company } = this.props;
		const { applicationStore } = this.context;
		const uuid = applicationStore.currentRouteParams.uuid;
		const filePreview = uuid
			? buildPublicAssetURL(uuid, fileAsset.uuid)
			: buildCompanyAssetURL(company.id, fileAsset.uuid);

		return (
			<div className="invoice-attachment-row grid border--top">
				<a
					className="invoice-attachment-row__description"
					href={filePreview}
					target="_blank"
					rel="noreferrer">
					{this.props.fileAsset.filename}
				</a>
				<span className="invoice-attachment-row__size font--meta">
					{formatBytes(this.props.fileAsset.sizeInBytes)}
				</span>
			</div>
		);
	}
}

InvoiceAttachmentRow.contextType = ApplicationContext;

InvoiceAttachmentRow.propTypes = {
	company: PropTypes.object.isRequired,
	fileAsset: PropTypes.instanceOf(FileAsset).isRequired
};
