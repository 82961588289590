/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { ApplicationContext } from '../../../ApplicationContext';
import ProgressStatusRow from './ProgressStatusRow';
import Signals from '../../../signals/Signals';

/**
 *
 */
@observer
export default class ProgressStatus extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = { collapsed: false, progressStatusStore: null };

		this.closeWhenFinished = this.closeWhenFinished.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		Signals.CompanyChanged.add(this.closeWhenFinished);
		Signals.FinancialYearChanged.add(this.closeWhenFinished);

		this.setState({ progressStatusStore: this.context.applicationStore.progressStatusStore });
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate() {}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.closeWhenFinished);
		Signals.FinancialYearChanged.remove(this.closeWhenFinished);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { progressStatusStore } = this.state;
		if (!progressStatusStore) {
			return null;
		}

		const hasActive = progressStatusStore.list.length > 0;

		const classes = classNames({
			'progress-status': true,
			'box-shadow-heavy': true,
			'progress-status--collapsed': this.state.collapsed,
			'progress-status--show': hasActive
		});

		return (
			<div className={classes}>
				<header>
					<div className="progress-status__info">{this._getTitle()}</div>
					<div className="progress-status__actions">
						{/* {this._getCollapseExpandButton()} */}
						{/* {this._getCloseButton()} */}
					</div>
				</header>
				<div className="progress-status__container">
					<div className="progress-status__wrapper">
						{progressStatusStore.sorted.map((progressStatusModel) => {
							return (
								<ProgressStatusRow
									key={`dfu-${progressStatusModel._uid}`}
									progressStatusModel={progressStatusModel}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	collapse() {
		this.setState({ collapsed: true });
	}

	/**
	 *
	 */
	expand() {
		this.setState({ collapsed: false });
	}

	/**
	 *
	 */
	close() {
		this.state.progressStatusStore.reset();
	}

	/**
	 *
	 */
	closeWhenFinished() {
		this.setState({ closeWhenFinished: true });
	}

	/**
	 *
	 * @return {null|*}
	 * @private
	 */
	_getTitle() {
		const progressStatusStore = this.state.progressStatusStore;

		const count = progressStatusStore.list.length;
		const incompleteCount = progressStatusStore.incompleteCount;
		const completeCount = count - incompleteCount;
		const hasIncomplete = incompleteCount > 0;
		const percentage = Math.round(progressStatusStore.percentage * 100);

		if (hasIncomplete) {
			return (
				<div className="progress-status__title">
					<label>
						<FormattedMessage
							id="progressstatus.title.busy"
							values={{
								complete: `${completeCount}`,
								count: `${count}`,
								percentage: `${percentage}`
							}}
						/>
					</label>
				</div>
			);
		}
		return (
			<div className="progress-status__title">
				<div className="progress-status__icon">
					<i className="icon icon--check-success" />
				</div>
				<label>
					<FormattedMessage id="progressstatus.title.done" />
				</label>
			</div>
		);
	}

	/**
	 * @return {*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getCollapseExpandButton() {
		const hasActive = this.state.progressStatusStore.list.length > 0;
		const hasIncomplete = this.state.progressStatusStore.incompleteCount > 0;

		// Only show when not completed
		if (hasActive && hasIncomplete) {
			if (this.state.collapsed && hasActive) {
				return (
					<button type="button" className="button-small--grey" onClick={() => this.expand()}>
						<FormattedMessage id="progressstatus.view" />
					</button>
				);
			}
			return (
				<button type="button" className="button-small--grey" onClick={() => this.collapse()}>
					<FormattedMessage id="progressstatus.hide" />
				</button>
			);
		}

		return null;
	}

	/**
	 *
	 * @return {null|*}
	 * @private
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	_getCloseButton() {
		const hasActive = this.state.progressStatusStore.list.length > 0;
		const hasIncomplete = this.state.progressStatusStore.incompleteCount > 0;

		if (hasActive && !hasIncomplete) {
			return (
				<button type="button" className="button-small--grey" onClick={() => this.close()}>
					<FormattedMessage id="progressstatus.close" />
				</button>
			);
		}
		return null;
	}
}

ProgressStatus.contextType = ApplicationContext;
