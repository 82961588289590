export function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, '\\$&');

	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) return null;

	if (!results[2]) return '';

	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 *
 * @param hash
 * @return {{}}
 */
export function getHashParameters(hash) {
	if (hash) {
		const segements = hash.split('#').join('').split('&');
		const result = {};
		segements.forEach((segment) => {
			const keyValue = segment.split('=');
			result[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : true;
		});

		return result;
	}

	return {};
}
