import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, offerId) => {
	const headers = { Accept: 'application/pdf' };
	return fetch(
		Methods.GET,
		EndPoints.COMPANIES_OFFER_PDF.replace(':companyId', companyId).replace(
			':invoiceconceptid',
			offerId
		),
		null,
		headers,
		true
	);
};

export function buildCompanyOfferPDFURL(companyId, offerId) {
	if (!companyId || !offerId) {
		return null;
	}

	return (
		window.config.apiPrefix +
		EndPoints.COMPANIES_OFFER_PDF.replace(':companyId', companyId).replace(':offerId', offerId)
	);
}
