/* eslint-disable react/prop-types */
import React from 'react';

function DropDownIndicator({ innerProps, isDisabled }) {
	return !isDisabled ? (
		<div className="drop-down-indicator icon icon--chevron-down-black" {...innerProps} />
	) : null;
}

export default DropDownIndicator;
