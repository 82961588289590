import Signals from '../signals/Signals';

import heartBeat from '../requests/heartBeat';
import { Routes, RouteUtil } from '../data/Routes';

/**
 *
 */
class SessionController {
	/**
	 *
	 */
	constructor() {
		this.rootElement = document.body;

		this.intervalDuration = 60000; // time between checks in ms
		this.sessionTimeLeft = -1;

		this.onInteraction = this.onInteraction.bind(this);
		this.onWindowActivated = this.onWindowActivated.bind(this);
		this.onHeartBeat = this.onHeartBeat.bind(this);
		this.onError = this.onError.bind(this);

		this.check = this.check.bind(this);
	}

	/**
	 *
	 */
	enable() {
		console.log('SessionController.enable');

		this.enabled = true;
		this.sessionTimeLeft = 1800000;
		this.doHeartBeat();

		// Listen to user input
		this.rootElement.addEventListener('click', this.onInteraction);
		this.rootElement.addEventListener('touchstart', this.onInteraction);
		window.addEventListener('focus', this.onWindowActivated);

		Signals.KeyDown.add(this.onInteraction);

		this.start();
	}

	/**
	 *
	 */
	disable() {
		console.log('SessionController.disable');

		this.enabled = false;
		clearInterval(this.checkInterval);

		// Stop listening to user input
		this.rootElement.removeEventListener('click', this.onInteraction);
		this.rootElement.removeEventListener('touchstart', this.onInteraction);
		window.removeEventListener('focus', this.onWindowActivated);

		Signals.KeyDown.remove(this.onInteraction);
	}

	/**
	 *
	 */
	start() {
		console.log('SessionController.start');
		clearInterval(this.checkInterval);
		this.checkInterval = setInterval(this.check, this.intervalDuration);
		this.didInteract = false;
	}

	/**
	 *
	 */
	check() {
		const currentRoute = RouteUtil.getRoute(window.location.pathname);
		const neverExpiringRoute =
			currentRoute === Routes.COMPANY_INVOICES_EDIT || currentRoute === Routes.COMPANY_OFFERS_EDIT;

		console.log(
			'check heartbeat',
			`didinteract: ${this.didInteract}`,
			`neverExpiringRoute:${neverExpiringRoute}`
		);

		// If user interacted, send heartbeat
		if (this.didInteract || neverExpiringRoute) {
			this.doHeartBeat();
			// Check if session expired
		} else {
			this.sessionTimeLeft = this.sessionTimeEnds.getTime() - Date.now();
			console.log(`session ends: ${this.sessionTimeEnds}`, `time left: ${this.sessionTimeLeft}`);

			if (this.sessionTimeLeft <= 0) {
				this.doLogout();
			}
		}
	}

	/**
	 *
	 */
	onWindowActivated(_event) {
		// check if session should end
		if (this.sessionTimeEnds) {
			this.sessionTimeLeft = this.sessionTimeEnds.getTime() - Date.now();

			// logout if session has expired
			if (this.sessionTimeLeft <= 0) {
				this.doLogout();
			}

			// extend session
		} else {
			this.doHeartBeat();
		}
	}

	/**
	 *
	 */
	onInteraction() {
		this.didInteract = true;
	}

	/**
	 *
	 */
	doHeartBeat() {
		heartBeat().then(this.onHeartBeat).catch(this.onError);
	}

	/**
	 *
	 * @param response
	 */
	onHeartBeat(response) {
		if (this.enabled && response.data) {
			this.sessionTimeLeft = response.data.aliveTimeInSeconds * 1000; // set session time in ms
			this.sessionTimeEnds = new Date(Date.now() + this.sessionTimeLeft); // set end Date
			this.didInteract = false; // reset interaction
		} else {
			//
			console.log('session appears to be invalidated');
			this.doLogout();
		}
	}

	/**
	 *
	 */
	doLogout() {
		Signals.Logout.dispatch();
		Signals.SessionExpired.dispatch();
	}

	/**
	 * \
	 * @param error
	 */
	onError(error) {
		if (window.navigator.onLine) {
			console.log('Session error occurred, server probably down, throw error');
			Signals.Error.dispatch(error, false);
		} else {
			// Ignore
			console.log('Session error, user is offline');
		}
	}
}

const sessionController = new SessionController();
export default sessionController;
