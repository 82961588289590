import { observable } from 'mobx';

import AnnualStatement from './models/AnnualStatement';

/**
 *
 */
export default class AnnualStatementStore {
	@observable fetching = false;

	@observable annualStatements = [];

	/**
	 *
	 */
	reset() {
		this.fetching = false;
		this.annualStatements = [];
	}

	/**
	 *
	 * @param year
	 * @param forceYears
	 * @return {*}
	 */
	getAnnualStatementByYear(year, forceYears = []) {
		console.log('getAnnualStatementByYear', year, forceYears);
		let result = null;
		this.annualStatements.forEach((annualStatement) => {
			if (annualStatement.year === year) {
				result = annualStatement;
			}
		});

		// Always add current year + the year before
		const currentYear = new Date().getFullYear();
		const lastYear = currentYear - 1;
		if (!result && (year === currentYear || year === lastYear)) {
			result = new AnnualStatement();
			result.year = year;
			this.annualStatements.push(result);
		}

		// Check in `forceYears` when still no result
		if (!result) {
			console.log(forceYears.indexOf(year));
			if (forceYears.indexOf(year) >= 0) {
				result = new AnnualStatement();
				result.year = year;
				this.annualStatements.push(result);
			}
		}

		this._sort();
		return result;
	}

	/**
	 *
	 * @param list
	 * @private
	 */
	parseData(list) {
		list.forEach((data) => {
			const year = data.year;
			const annualStatement = this.getAnnualStatementByYear(year, [year]);
			if (annualStatement) {
				annualStatement.parseData(data);
			}
		});
	}

	/**
	 *
	 * @private
	 */
	_sort() {
		this.annualStatements.sort((a, b) => {
			return b.year - b.year;
		});
	}
}
