import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TodoList from './TodoList';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
export default class TodoTooltip extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			el: null
		};
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		// Check if logged in
		if (!this.context.applicationStore.isLoggedIn) {
			return null;
		}

		//
		let scrollingEnabled = false;
		if (this.state.el) {
			const content = this.state.el.querySelector('.todo-tooltip__list-wrapper');
			scrollingEnabled = content && content.scrollHeight > content.clientHeight;
		}

		const classes = classNames({
			'todo-tooltip': true,
			'todo-tooltip--content-shadow': scrollingEnabled
		});

		return (
			<div
				ref={(ref) => {
					this._setElement(ref);
				}}
				className={classes}>
				<h2 className="todo-tooltip__title">
					<span>TO-DO&apos;S</span>
				</h2>
				<div className="todo-tooltip__list-wrapper border--top border--dark">
					<TodoList
						todos={this.props.todoStore.getAllTodos(this.context.applicationStore.user)}
						todoStore={this.props.todoStore}
						showProfileImages
						showSecondaryText={false}
					/>
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param ref
	 * @private
	 */
	_setElement(ref) {
		if (!this.state.el) {
			this.setState({ el: ref });
		}
	}
}

TodoTooltip.contextType = ApplicationContext;

TodoTooltip.propTypes = {
	todoStore: PropTypes.object.isRequired
};

TodoTooltip.defaultProps = {};
