import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import periodicInvoiceConceptDelete from '../../requests/periodicInvoiceConcepts/periodicInvoiceConceptDelete';

/**
 *
 */
export default class DeletePeriodicInvoiceConceptCommand extends AbstractCommand {

	/**
	 *
	 * @param periodicInvoicesConceptStore periodicInvoicesConceptStore
	 * @param periodicInvoiceConceptModel PeriodicInvoiceConceptModel
	 * @param company Company
	 */
	constructor(periodicInvoicesConceptStore, periodicInvoiceConceptModel, company) {
		super();

		this.periodicInvoicesConceptStore = periodicInvoicesConceptStore;
		this.periodicInvoiceConceptModel = periodicInvoiceConceptModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return periodicInvoiceConceptDelete(this.company.id, this.periodicInvoiceConceptModel.id)
			.then((response) => {

				this._removeInvoiceFromStore();
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_removeInvoiceFromStore() {
		this.periodicInvoicesConceptStore.removeInvoiceConcept(this.periodicInvoiceConceptModel.id);
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="invoice.removed.message"
								   values={{invoiceNr: this.periodicInvoiceConceptModel.invoiceNr}}/>)
		);
	}
}
