import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, offerId) => {
	const headers = { 'content-type': 'application/json' };
	return fetch(
		Methods.POST,
		EndPoints.COMPANIES_OFFER_ARCHIVE.replace(':companyId', companyId).replace(':offerId', offerId),
		null,
		headers,
		true
	);
};
