import { observable } from 'mobx';

/**
 *
 */
export default class SignOfferModel {
	@observable fullName;

	@observable companyName;

	@observable email;

	@observable signatureAssetId;

	@observable signaturePadReference;

	/**
	 *
	 * @return {boolean}
	 */
	isValid() {
		if (this.signaturePadReference) {
			return !this.signaturePadReference.isEmpty();
		}

		return false;
	}

	/**
	 *
	 * @param data
	 */
	fromData(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}

	/**
	 *
	 * @return {string}
	 */
	toJSON() {
		const jsonObject = {
			fullName: this.fullName,
			companyName: this.companyName,
			email: this.email,
			signatureAssetId: this.signatureAssetId
		};

		return JSON.stringify(jsonObject);
	}
}
