export default {
	EENMANSZAAK: { id: 'EENMANSZAAK', translationId: 'company.type.eenmanszaak' },
	VOF: { id: 'VOF', translationId: 'company.type.vof' },
	CV: { id: 'CV', translationId: 'company.type.cv' },
	BV: { id: 'BV', translationId: 'company.type.bv' },
	BVHOLDING: { id: 'BVHOLDING', translationId: 'company.type.bvholding' },
	STICHTING: { id: 'STICHTING', translationId: 'company.type.stichting' },
	PARTICULIER: { id: 'PARTICULIER', translationId: 'company.type.particulier' },

	DEFAULT: { id: 'DEFAULT', translationId: 'company.type.default' },

	getObjectByID(id) {
		let type = this[id];

		if (!type) {
			type = this.DEFAULT;
		}

		return type;
	}
};
