/**
 *
 */
class PropertiesController {
	documentContext = null;

	/**
	 *
	 */
	constructor() {
		this.documentContext = '';
		const classList = document.documentElement.classList;
		for (let i = 0; i < classList.length; i++) {
			const cls = classList.item(i);
			if (cls.indexOf('-context') > 0) {
				this.documentContext = cls.trim();
			}
		}

		console.log('documentContext', this.documentContext);
	}

	/**
	 *
	 * @param config
	 */
	setConfig(config) {
		this.config = config;
	}

	/**
	 *
	 * @param properties
	 */
	setAdditionalProperties(properties) {
		this.properties = properties;
	}

	/**
	 *
	 * @return {*}
	 */
	getContext() {
		return this.documentContext;
	}

	/**
	 *
	 * @param property
	 * @param context
	 *
	 * @return {*}
	 */
	getProperty(property, context = null) {
		let value = null;
		switch (property) {
			case PropertiesController.FEATURE_PERIODIC_INVOICE_CONCEPTS:
				value = this._value('periodicInvoiceConcepts');
				break;
			case PropertiesController.FEATURE_MOLLIE:
				value = this._value('mollie');
				break;
			case PropertiesController.FEATURE_PAYROLLADMINISTRATION:
				value = this._value('payrollAdministration');
				break;
			case PropertiesController.FEATURE_DEMO:
				if (context) {
					// Make exception for demo template accounts
					return context.applicationStore.user.email === 'claire@keesdeboekhouder.nl' ||
						context.applicationStore.user.email === 'claire+demoenglish@keesdeboekhouder.nl'
						? false
						: this._value('demoMode');
				}
				value = this._value('demoMode');

				break;
			case PropertiesController.IS_DEMO_ENV:
				return this._value('demoMode');
			case PropertiesController.FEATURE_BANKBOOK:
				value = this._value('bankBook');
				break;
			case PropertiesController.TERMS_AND_CONDITIONS:
				return process.env.FEATURE_TERMS_AND_CONDITIONS === 'true';
			default:
				break;
		}

		return value;
	}

	/**
	 *
	 * @param propertyName
	 * @return {*}
	 * @private
	 */
	_value(propertyName) {
		const property = this._properties(propertyName);
		const config = this._config(propertyName);

		// First look in additional properties
		if (property) {
			return property !== 'false';
		}

		// Try to return config settings as default/fallback
		return config;
	}

	/**
	 *
	 * @param key
	 * @return {*}
	 * @private
	 */
	_config(key) {
		return this.config[key];
	}

	/**
	 *
	 * @param key
	 * @return {null}
	 * @private
	 */
	_properties(key) {
		return this.properties ? this.properties[key] : null;
	}
}

const propertiesController = new PropertiesController();

propertiesController.FEATURE_PERIODIC_INVOICE_CONCEPTS =
	PropertiesController.FEATURE_PERIODIC_INVOICE_CONCEPTS = 'FEATURE_PERIODIC_INVOICE_CONCEPTS';
propertiesController.FEATURE_MOLLIE = PropertiesController.FEATURE_MOLLIE = 'FEATURE_MOLLIE';
propertiesController.FEATURE_PAYROLLADMINISTRATION =
	PropertiesController.FEATURE_PAYROLLADMINISTRATION = 'PAYROLL_ADMINISTRATION';
propertiesController.FEATURE_DEMO = PropertiesController.FEATURE_DEMO = 'FEATURE_DEMO';
propertiesController.IS_DEMO_ENV = PropertiesController.IS_DEMO_ENV = 'IS_DEMO_ENV'; // no exceptions for emails
propertiesController.FEATURE_BANKBOOK = PropertiesController.FEATURE_BANKBOOK = 'FEATURE_BANKBOOK';
propertiesController.TERMS_AND_CONDITIONS = PropertiesController.TERMS_AND_CONDITIONS =
	'FEATURE_TERMS_AND_CONDITIONS';

export default propertiesController;
