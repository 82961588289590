import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { injectIntl } from 'react-intl';
import { FcCheckmark } from 'react-icons/fc';
import { useDebounce } from 'react-use';
import classNames from 'classnames';
// eslint-disable-next-line no-unused-vars
import BankCategoryOption from '../../../../../stores/models/BankCategoryOption';

/**
 *
 * @param {BankCategoryOption} category
 * @param {function} onSelect
 * @param {BankCategoryOption} selectedCategory
 * @returns
 */
function Category({ category, onSelect, selectedCategory, open }) {
	const [isOpen, setIsOpen] = useState(open);
	const toggle = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsOpen(!isOpen);
	};
	const onSelectCategory = () => {
		onSelect(category);
	};

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	if (category.isHidden) {
		return null;
	}

	return (
		<div className="category">
			<div className="category-label" onClick={onSelectCategory}>
				<div
					className={classNames('flex', {
						'basis-2/3': category.isExpandable,
						'w-full': !category.isExpandable
					})}>
					<div>{category.label}</div>
					{selectedCategory && selectedCategory.value === category.value && (
						<div className="max-w-fit">
							<FcCheckmark className="ml-2" />
						</div>
					)}
				</div>
				{category.isExpandable ? (
					<div className="expand-icon" onClick={toggle}>
						{isOpen ? <BsChevronUp /> : <BsChevronDown />}
					</div>
				) : null}
			</div>
			{isOpen && (
				<div className="category-options">
					{category.isExpandable &&
						category.options.map((option) => (
							<Category
								key={option.value}
								category={option}
								onSelect={onSelect}
								selectedCategory={selectedCategory}
								open={open}
							/>
						))}
				</div>
			)}
		</div>
	);
}

Category.propTypes = {
	category: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	selectedCategory: PropTypes.object,
	open: PropTypes.bool
};

const searchCategories = (categories, search, bankCategoryMap, bankCategoryLabels) => {
	const filteredCategories = new Set();
	if (search.length > 0) {
		const filteredCategoryIds = Object.entries(bankCategoryLabels)
			.filter((category) => category[1].toLowerCase().includes(search.toLowerCase()))
			.map((category) => category[0]);
		if (filteredCategoryIds.length > 0 && bankCategoryMap) {
			filteredCategoryIds.forEach((categoryId) => {
				const category = bankCategoryMap[categoryId];
				if (category) {
					if (category.parent) {
						filteredCategories.add(category.parent);
					} else {
						filteredCategories.add(category);
					}
				}
			});
		}
	} else {
		return [categories, false];
	}
	return [[...filteredCategories], true];
};

function CategoryList({
	categories,
	selectedCategory,
	onSelect,
	search,
	bankCategoryMap,
	bankCategoryLabels,
	intl
}) {
	const [filteredCategories, isFiltered] = useMemo(
		() => searchCategories(categories, search, bankCategoryMap, bankCategoryLabels),
		[categories, search]
	);

	return (
		<div className="category-container">
			{filteredCategories.map((category) => {
				const isSelectedParent =
					selectedCategory &&
					selectedCategory.parent &&
					selectedCategory.parent.value === category.value;
				const open = isFiltered || isSelectedParent;
				return (
					<Category
						key={category.value}
						category={category}
						onSelect={onSelect}
						selectedCategory={selectedCategory}
						open={open}
					/>
				);
			})}
			{filteredCategories.length === 0 && (
				<div className="category-empty">{intl.formatMessage({ id: 'category.select.empty' })}</div>
			)}
		</div>
	);
}

CategoryList.propTypes = {
	categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	selectedCategory: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	search: PropTypes.string,
	bankCategoryMap: PropTypes.object,
	bankCategoryLabels: PropTypes.object,
	intl: PropTypes.object
};

function CategoryPicker({
	categories,
	selectedCategoryId,
	intl,
	bankCategoryMap,
	bankCategoryLabels,
	onSelect
}) {
	const [search, setSearch] = useState('');
	const [category, setCategory] = useState(
		selectedCategoryId ? bankCategoryMap[selectedCategoryId] : null
	);
	const [debouncedValue, setDebouncedValue] = React.useState('');

	const [, cancel] = useDebounce(
		() => {
			setDebouncedValue(search);
		},
		300,
		[search]
	);

	useEffect(() => {
		return () => {
			cancel();
		};
	}, [cancel]);

	const handleCategorySelect = useCallback(
		(selectedCategory) => {
			let categoryToUpdate = selectedCategory;
			if (category) {
				if (category.value === selectedCategory.value) {
					categoryToUpdate = null;
				} else {
					categoryToUpdate = selectedCategory;
				}
			}
			setCategory(categoryToUpdate);
			onSelect(categoryToUpdate);
		},
		[category]
	);

	return (
		<div
			className="select-container"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>
			<div className="select-search">
				<input
					id="select-input"
					type="text"
					onChange={(e) => setSearch(e.target.value)}
					value={search}
					placeholder={intl.formatMessage({
						id: 'category.select.search'
					})}
				/>
				{search.length > 0 && (
					<FaTimes className="select-search-close" onClick={() => setSearch('')} />
				)}
			</div>
			<CategoryList
				search={debouncedValue}
				categories={categories}
				selectedCategory={category}
				onSelect={handleCategorySelect}
				bankCategoryMap={bankCategoryMap}
				bankCategoryLabels={bankCategoryLabels}
				intl={intl}
			/>
		</div>
	);
}

CategoryPicker.propTypes = {
	categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	bankCategoryMap: PropTypes.object,
	bankCategoryLabels: PropTypes.object,
	selectedCategoryId: PropTypes.string,
	onSelect: PropTypes.func,
	intl: PropTypes.object.isRequired
};

export default injectIntl(CategoryPicker);
