import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 */
export default class OfferHistoryRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className="offer-history-row grid border--top">
				<h3 className="offer-history-row__description font--body">{this.props.description}</h3>
				<span className="offer-history-row__date font--meta">{this.props.date}</span>
			</div>
		);
	}
}

OfferHistoryRow.propTypes = {
	description: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired
};
