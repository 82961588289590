import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
export default class SearchField extends React.Component {
	constructor(props) {
		super(props);

		this.input = null;

		this.state = {
			hasFocus: false,
			value: props.value
		};

		this.onClick = this.onClick.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.value !== prevProps.value) {
			this.setState({ ...prevState, value: this.props.value || '' });
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { className, focusColorChange } = this.props;
		const hasValue = !!this.state.value;
		const isIdle = (!this.state.hasFocus || !focusColorChange) && !hasValue;
		const isActive = this.state.hasFocus && !hasValue && focusColorChange;
		const isFocused = this.state.hasFocus || hasValue;
		const classes = classNames({
			'search-field': true,
			icon: true,
			'icon--search2-black': isIdle,
			'icon--search2-navy': isActive,
			'icon--close-black': hasValue,
			'search-field--focus': isFocused
		});

		return (
			<div className={`${classes} ${className}`} onClick={this.onClick}>
				<input
					ref={(input) => (this.input = input)}
					type="text"
					name={this.props.name}
					value={this.state.value}
					placeholder={this.props.placeholder}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					autoComplete="off"
				/>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onChange(e) {
		this.setState({ value: e.target.value });
		this.props.onChange(e);
	}

	/**
	 *
	 */
	onFocus() {
		this.setState({ hasFocus: true });
	}

	/**
	 *
	 */
	onBlur() {
		this.setState({ hasFocus: false });
	}

	/**
	 *
	 * @param e
	 */
	onKeyDown(e) {
		if (e.keyCode === 27 && this.input) {
			this.setState({ value: '' });
			this.props.onChange(null);
			this.input.blur();
		}
	}

	/**
	 *
	 * @param e
	 */
	onClick(e) {
		if (e.target !== this.input && this.state.value !== undefined) {
			this.setState({ value: '' });
			this.props.onChange(null);
		} else if (!this.value || this.value === '') {
			this.input.focus();
		}
	}
}

SearchField.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	// eslint-disable-next-line react/no-unused-prop-types
	type: PropTypes.string,
	className: PropTypes.string,
	// If this property is set to true, the search icon color will change when the field is focused
	focusColorChange: PropTypes.bool
};

SearchField.defaultProps = {
	className: '',
	focusColorChange: true
};
