import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import PropertiesController from '../../../controllers/PropertiesController';

import Page from '../../Page';
import { ApplicationContext } from '../../../ApplicationContext';
import InvoicesSettingsGeneric from './InvoicesSettingsGeneric';
import InvoicesSettingsDutch from './InvoicesSettingsDutch';
import InvoicesSettingsEnglish from './InvoicesSettingsEnglish';
import InvoicesSettingsIntegrations from './InvoicesSettingsIntegrations';
import GenericSettingsBrands from '../GenericSettings/GenericSettingsBrands';

const SETTINGS_FORM_TYPE = {
	GENERIC: 'generic',
	DUTCH: 'dutch',
	ENGLISH: 'english',
	INTEGRATIONS: 'integrations',
	BRANDS: 'addresses'
};

/**
 *
 */
class InvoicesSettings extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			formType: SETTINGS_FORM_TYPE.GENERIC
		};

		this.onHashChange = this.onHashChange.bind(this);
	}

	componentWillMount() {}

	/**
	 *
	 */
	componentDidMount() {
		window.addEventListener('hashchange', this.onHashChange);
		this.onHashChange();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		console.log('InvoicesSettings.componentWillReceiveProps', window.location.hash);
		this.onHashChange();
	}

	/**
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(_prevProps, _prevState, _snapshot) {
		console.log('InvoicesSettings.componentDidUpdate', window.location.hash);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		window.removeEventListener('hashchange', this.onHashChange);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();

		if (!this.context.applicationStore.isLoggedIn || !company) {
			return null;
		}

		//

		const genericButtonClasses = classNames({
			'invoices-settings__form-type-button col--12': true,
			'invoices-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.GENERIC
		});
		const addressesButtonClasses = classNames({
			'invoices-settings__form-type-button col--12': true,
			'invoices-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.BRANDS
		});
		const dutchButtonClasses = classNames({
			'invoices-settings__form-type-button col--12': true,
			'invoices-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.DUTCH
		});
		const englishButtonClasses = classNames({
			'invoices-settings__form-type-button col--12': true,
			'invoices-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.ENGLISH
		});
		const integrationButtonClasses = classNames({
			'invoices-settings__form-type-button col--12': true,
			'invoices-settings__form-type-button--active':
				this.state.formType === SETTINGS_FORM_TYPE.INTEGRATIONS
		});

		return (
			<Page pageName="invoices-settings">
				<div className="invoices-settings__content-wrapper grid">
					{this._getSelectedForm()}

					{/* Menu */}
					<div className="invoices-settings__menu grid">
						<div
							className={genericButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.GENERIC);
							}}>
							<FormattedMessage id="invoices.settings.title.generic" />
						</div>
						<div
							className={addressesButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.BRANDS);
							}}>
							<FormattedMessage id="invoices.settings.title.brands" />
						</div>
						<div
							className={dutchButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.DUTCH);
							}}>
							<FormattedMessage id="invoices.settings.title.dutch" />
						</div>
						<div
							className={englishButtonClasses}
							onClick={() => {
								this._setSelectedForm(SETTINGS_FORM_TYPE.ENGLISH);
							}}>
							<FormattedMessage id="invoices.settings.title.english" />
						</div>

						{PropertiesController.getProperty(PropertiesController.FEATURE_MOLLIE) ? (
							<div
								className={integrationButtonClasses}
								onClick={() => {
									this._setSelectedForm(SETTINGS_FORM_TYPE.INTEGRATIONS);
								}}>
								<FormattedMessage id="invoices.settings.title.integrations" />
							</div>
						) : null}
					</div>
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	onHashChange() {
		// Deeplink
		if (window.location.hash) {
			switch (window.location.hash) {
				case '#integrations':
					this.setState({ formType: SETTINGS_FORM_TYPE.INTEGRATIONS });
					break;
				default:
					this.setState({ formType: SETTINGS_FORM_TYPE.GENERIC });
			}
		}
	}

	/**
	 *
	 * @param formType
	 * @private
	 */
	_setSelectedForm(formType) {
		this.setState({ formType });
	}

	/**
	 *
	 * @private
	 */
	_getSelectedForm() {
		switch (this.state.formType) {
			case SETTINGS_FORM_TYPE.GENERIC:
				return <InvoicesSettingsGeneric />;
			case SETTINGS_FORM_TYPE.BRANDS:
				return <GenericSettingsBrands />;
			case SETTINGS_FORM_TYPE.DUTCH:
				return <InvoicesSettingsDutch />;
			case SETTINGS_FORM_TYPE.ENGLISH:
				return <InvoicesSettingsEnglish />;
			case SETTINGS_FORM_TYPE.INTEGRATIONS:
				return <InvoicesSettingsIntegrations />;
			default:
				return null;
		}
	}
}

InvoicesSettings.contextType = ApplicationContext;

InvoicesSettings.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(InvoicesSettings);
