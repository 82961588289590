import Signal from 'signals';

export default {
	Logout: new Signal(), // Do logout

	RootClick: new Signal(),
	KeyDown: new Signal(),

	//
	ShowModal: new Signal(), // content
	HideModal: new Signal(),

	//
	ShowOverlay: new Signal(), // content
	HideOverlay: new Signal(),
	OverlayBackgroundClick: new Signal(),

	//
	ShowToolTip: new Signal(),
	HideToolTip: new Signal(),

	//
	ShowLoadingScreen: new Signal(),
	HideLoadingScreen: new Signal(),
	ProgressUpload: new Signal(),

	//
	ShowMessageDialog: new Signal(), // content
	HideMessageDialog: new Signal(),

	//
	DraftDeleted: new Signal(), //

	//
	Error: new Signal(),

	//
	RequestRoute: new Signal(),
	RouteChanged: new Signal(), // pathName

	//
	LoggedIn: new Signal(),
	CompanyFetched: new Signal(),
	// UserPassportFetched: new Signal(),

	//
	OffersFetched: new Signal(),

	// AnnualStatementsFetched: new Signal(),
	CommunityFetched: new Signal(),
	CommunityCategoriesFetched: new Signal(),

	//
	CompanyChanged: new Signal(),
	FinancialYearChanged: new Signal(),
	DeadlinesFetched: new Signal(),
	TodosFetched: new Signal(),
	CompanyCustomerAdded: new Signal(),
	CompanyBrandAdded: new Signal(),

	//
	DocumentYearChanged: new Signal(), // param: year
	DocumentSubmitted: new Signal(), // param: data

	//
	ShowUserMessagesDialog: new Signal(),
	HideUserMessagesDialog: new Signal(),

	//
	SessionExpired: new Signal(),

	//
	ForceAppRender: new Signal(),

	//
	DragStart: new Signal(),
	DragOver: new Signal(),
	Drop: new Signal(),
	DragEnter: new Signal(),
	DragLeave: new Signal(),

	//
	WalkthroughUpdatedPosition: new Signal(),
	WalkthroughUpdate: new Signal(),
	WalkthroughPaused: new Signal(),

	//
	IncomeTableUpdated: new Signal(),
	ExpenseTableUpdated: new Signal()
};
