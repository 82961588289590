import PaymentTerm from '../../data/PaymentTerm';

/** \
 *
 */
export default class CompanyInvoiceSettings {
	/**
	 *
	 * @param company
	 */
	setDefaults(company) {
		// Set defaults
		this.senderEmail = company.email;
		this.remindCompanyUserInDays = 0;
		this.remindCompanyCustomerInDays = PaymentTerm.FOURTEEN_DAYS.days;

		// Dutch
		this.reminderMessage = { id: 'invoice.settings.remindermessage.default.nl' };
		this.reminderSubject = { id: 'invoice.settings.remindersubject.default.nl' };
		this.defaultInvoiceConceptEmailMessage = { id: 'invoice.settings.emailmessage.default.nl' };
		this.defaultInvoiceConceptEmailSubject = { id: 'invoice.settings.emailsubject.default.nl' };
		this.defaultNotes = { id: 'invoice.settings.notes.default.nl' };

		// English
		this.reminderMessageEN = { id: 'invoice.settings.remindermessage.default.en' };
		this.reminderSubjectEN = { id: 'invoice.settings.remindersubject.default.en' };
		this.defaultInvoiceConceptEmailMessageEN = { id: 'invoice.settings.emailmessage.default.en' };
		this.defaultInvoiceConceptEmailSubjectEN = { id: 'invoice.settings.emailsubject.default.en' };
		this.defaultNotesEN = { id: 'invoice.settings.notes.default.en' };

		//
		this.logoAssetUuid = null;
		this.bccRecipientEmail = '';

		// Mollie
		this.molliePaymentProfile = null;
		this.canPayOnlineStatus = null;
	}

	/**
	 * @param company
	 * @param data
	 */
	update(company, data) {
		this.setDefaults(company);

		//

		// Update values
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				// Temporary fix for #2625: https://github.com/KeesdeBoekhouder/KdB-mijn-web/issues/2625
				// If translationId (without `.nl` or `.en` at the end) is store in db, use default
				if (this[key] && this[key].id) {
					const split = this[key].id.split('.');
					split.pop(); // pop .nl or .en
					const id = split.join('.');

					// If id is not equal to data value, update this[key] with data value
					if (id !== data[key]) {
						this[key] = data[key];
						// Do nothing, keep default, when saving settings the correct values will be stored
					} else {
						console.log(`ignoring: [${key}] as it is a translation id`);
					}
					// Normal usage
				} else {
					this[key] = data[key];
				}
			}
		}
	}

	/**
	 *
	 * @param defaults
	 * @param ignoreFields
	 * @return {string}
	 */
	toJSON(defaults = {}, ignoreFields = {}) {
		const data = {};
		for (const key in this) {
			if (this.hasOwnProperty(key) && !(ignoreFields && ignoreFields[key])) {
				let value = this[key];

				// Try to set defaults when value is not set
				if (value === null || value === undefined) {
					value = defaults[key] ? defaults[key] : null;
				}

				// Force format of specific types and objects (like `Date`)
				data[key] = value;
			}
		}

		return JSON.stringify(data);
	}

	/**
	 *
	 * @return {any}
	 */
	toObject() {
		return JSON.parse(this.toJSON());
	}
}
