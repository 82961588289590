import { computed, observable } from 'mobx';
import Signals from '../signals/Signals';

import CompanyCustomer from './models/CompanyCustomer';
import GetCompanyCustomersCommand from '../commands/companyCustomers/GetCompanyCustomersCommand';

/**
 *
 */
export default class CompanyCustomerStore {
	@observable companyCustomers = [];

	@observable companyId = null;

	@observable filter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	/**
	 *
	 */
	constructor() {
		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);
	}

	/**
	 *
	 * @param companyId
	 * @return {*|Promise<T>}
	 */
	fetch(companyId) {
		this.companyId = companyId;
		this.companyCustomers = [];

		const command = new GetCompanyCustomersCommand(companyId);
		command.execute(
			(response) => {
				response.list.forEach((companyCustomerData) => {
					this.companyCustomers.push(new CompanyCustomer(companyCustomerData));
				});

				this.update();
			},
			(err) => {
				Signals.Error.dispatch(err);
			}
		);
	}

	/**
	 *
	 * @param companyCustomer
	 */
	add(companyCustomer) {
		this.companyCustomers.push(companyCustomer);
		this.update();
	}

	/**
	 *
	 * @param companyCustomer
	 */
	remove(companyCustomer) {
		this.companyCustomers.remove(companyCustomer);
		this.update();
	}

	/**
	 *
	 */
	reset() {
		this.companyCustomers = [];
		this.companyId = null;

		this.filter = null;
		this.sortBy = null;
		this.sortDirection = 1;
	}

	/**
	 *
	 * @param id
	 * @returns {CompanyCustomer|null}
	 */
	find(id) {
		return this.companyCustomers.find((i) => {
			return i.id === id;
		});
	}

	/**
	 *
	 */
	update() {
		this.companyCustomers = this.companyCustomers.concat([]);
	}

	/**
	 *
	 * @return {*[]}
	 */
	@computed get filteredAndSortedCompanyCustomers() {
		return this.companyCustomers
			.filter((companyCustomer) => {
				return this._filter(companyCustomer);
			})
			.sort(this._sort);
	}

	/**
	 *
	 * @return {Array}
	 */
	@computed get sortedCompanyCustomers() {
		return this.companyCustomers.sort(this._sort);
	}

	/**
	 *
	 * @param id
	 */
	_removeById(id) {
		const companyCustomer = this.companyCustomers.find((i) => {
			return i.id === id;
		});

		this.companyCustomers.remove(companyCustomer);
	}

	/**
	 *
	 * @param companyCustomer
	 * @returns {boolean}
	 * @private
	 */
	_filter(companyCustomer) {
		if (!this.filter || !companyCustomer) {
			return true;
		}

		let searchString = '';
		searchString += companyCustomer.companyName ? `${companyCustomer.companyName} ` : '';
		searchString += companyCustomer.contactPersonName
			? `${companyCustomer.contactPersonName} `
			: '';
		searchString += companyCustomer.contactPersonEmail
			? `${companyCustomer.contactPersonEmail} `
			: '';
		searchString += companyCustomer.info ? `${companyCustomer.info} ` : '';
		searchString += companyCustomer.city ? `${companyCustomer.city} ` : '';
		searchString += companyCustomer.postalCode ? `${companyCustomer.postalCode} ` : '';
		searchString += companyCustomer.address ? `${companyCustomer.address} ` : '';
		searchString = searchString.toLowerCase();

		const filter = this.filter.toLowerCase();
		return searchString.indexOf(filter) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'companyName':
				if (!b.companyName) {
					result = 1;
				} else if (!a.companyName) {
					result = -1;
				} else {
					result = a.companyName.localeCompare(b.companyName);
				}
				break;
			case 'contactPersonEmail':
				result = a.contactPersonEmail.localeCompare(b.contactPersonEmail);
				break;
			case 'address':
				result = a.getAddress().localeCompare(b.getAddress());
				break;
			case 'info':
				if (!b.info) {
					result = 1;
				} else if (!a.info) {
					result = -1;
				} else {
					result = a.info.localeCompare(b.info);
				}
				break;
			case 'contactPersonName':
			default:
				result = a.contactPersonName.localeCompare(b.contactPersonName);
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}
}
