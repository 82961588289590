import EndPoints from '@/data/EndPoints';
import Methods from '@/data/Methods';

export default () => {
	const headers = { 'content-type': 'application/json' };
	return fetch(`${process.env.BACKEND_URL}${EndPoints.FEATURES}`, {
		method: Methods.GET,
		headers
	}).then((response) => response.json());
};
