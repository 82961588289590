import { observable, computed } from 'mobx';
import Model from './Model';
import { BankRecordType } from '../../data/BankRecordType';
import BankRecordMatchedResourceStatusType from '../../data/BankRecordMatchedResourseStatusType';
import IncomeModel from './IncomeModel';
// eslint-disable-next-line import/no-cycle
import ExpenseModel from './ExpenseModel';

export default class BankRecordMatchedResource extends Model {
	/**
	 * @type {number}
	 */
	@observable id;

	/**
	 * @type {BankRecordType}
	 */
	@observable type;

	/**
	 * @type {number}
	 */
	@observable number;

	/**
	 * @type {string}
	 */
	@observable name;

	/**
	 * @type {string}
	 */
	@observable date;

	/**
	 * @type {number}
	 */
	@observable amount;

	/**
	 *
	 *
	 *
	 * @type {BankRecordMatchedResourceStatusType}
	 */
	@observable status;

	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		super(data);
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				switch (key) {
					case 'date':
						this[key] = new Date(data[key]);
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	/**
	 * Create a new BankRecordMatchedResource instance from an income or expense model
	 * @param {IncomeModel|ExpenseModel} data
	 * @param {IncomeModel|ExpenseModel} data
	 * @param {IncomeModel|ExpenseModel} data
	 * @returns {null|BankRecordMatchedResource}
	 */
	static createBankRecordMatchedResourceFromModel(data) {
		if (!data) return null;
		const resource = new BankRecordMatchedResource();
		resource.id = data.id;
		resource.date = data.date;
		resource.name = data.customerName;
		resource.description = data.description;
		resource.amount = data.totalAmountInclBTW;
		resource.status = BankRecordMatchedResourceStatusType.MANUAL_MATCH;
		if (data instanceof ExpenseModel) {
			resource.type = BankRecordType.EXPENSE;
			resource.number = parseInt(data.expenseNr, 10);
		} else if (data instanceof IncomeModel) {
			resource.type = BankRecordType.INCOME;
			resource.number = parseInt(data.invoiceNr, 10);
		}
		return resource;
	}

	/**
	 * Returns true if record is matched
	 * @returns {boolean}
	 * @readonly
	 * @memberof BankRecordMatchedResource
	 */
	@computed get matchStatus() {
		return this.status && this.status !== BankRecordMatchedResourceStatusType.BAD_AUTO_MATCH;
	}
}
