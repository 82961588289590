/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Routes } from '../../../data/Routes';
import DocumentTypes from '../../../data/DocumentTypes';
import Signals from '../../../signals/Signals';

import Page from '../../Page';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';
import FileUpload from '../../../components/ui/FileUpload/FileUpload';

import userDocumentCreate from '../../../requests/userDocumentCreate';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
class UserChangePassword extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: null,
			submitting: false
		};

		this.onFileChange = this.onFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.setState({
			fileName: this.context.applicationStore.user.passportFile
				? this.context.applicationStore.user.passportFile.fileName
				: null
		});
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const formClasses = classNames({ 'form--submitting': this.state.submitting });

		return (
			<Page pageName="user-info">
				<div className="user-info__panel panel border border--dark">
					<div className="user-info__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="user.passport.title" />
						</h2>
					</div>

					<form className={`user-info__content ${formClasses}`} onSubmit={this.onSubmit}>
						<FormGroup className="grid padding" errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="user.passport.field.passport" />
								</label>
								<FileUpload
									className="col--12"
									name="file"
									filePreview={undefined}
									fileName={this.state.fileName}
									label={this.props.intl.formatMessage({ id: 'fileupload.drag.file' })}
									onChange={this.onFileChange}
								/>
							</FormField>
						</FormGroup>

						<div className="options-footer border--top border--dark grid grid--spread">
							<div className="grid">
								<Link to={Routes.USER_SETTINGS_INFO.pathName}>
									<button type="button">
										<FormattedMessage id="user.passport.backtoprofile" />
									</button>
								</Link>
							</div>
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 */
	onFileChange(file, fileName) {
		this.setState({ file, fileName });
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		if (this.state.submitting || !this.validate()) {
			return;
		}

		const formData = new FormData();
		formData.append('file', this.state.file);
		formData.append('type', DocumentTypes.PASPOORT.name);
		formData.append('year', new Date().getFullYear());

		this.setState({ errors: null, submitting: true });
		userDocumentCreate(formData).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 */
	onSubmitSuccess() {
		// Update user object
		this.context.applicationStore.user.passportFile = { fileName: this.state.file.name };

		// Reload todos
		this.context.applicationStore.todoStore.fetch();

		// Show success message
		Signals.ShowMessageDialog.dispatch(
			<div>
				<FormattedMessage id="user.passport.success.body" />
				<Link to={Routes.USER_SETTINGS_INFO.pathName}>
					<FormattedMessage id="user.passport.backtoprofile" />
				</Link>
			</div>
		);

		// Update state and redirect to user info
		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		this.setState({ submitting: false });
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}
	}

	/**
	 *
	 * @return {boolean}
	 */
	validate() {
		if (!this.state.file) {
			const errorMessage = this.props.intl.formatMessage({ id: 'onboarding.error.nopassportfile' });
			this.setState({ errors: [['file', errorMessage]] });
			return false;
		}

		return true;
	}
}

UserChangePassword.contextType = ApplicationContext;

UserChangePassword.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(UserChangePassword);
