import React from 'react';
import PropTypes from 'prop-types';

function CompanyBrandOption(props) {
	const { innerProps, data, isDisabled } = props;
	return !isDisabled ? (
		<div className="company-brand-option" {...innerProps}>
			{data.hideLogoPreview ? null : (
				<div className="company-brand-option__logo">
					{data.logoPreview ? <img alt="logo" src={data.logoPreview} /> : null}
				</div>
			)}

			<div>
				<span className="company-brand-option__brand-name">{data.brandName}</span>
				<span className="company-brand-option__brand-email">{data.brandEmail}</span>
			</div>
		</div>
	) : null;
}

CompanyBrandOption.propTypes = {
	innerProps: PropTypes.any,
	data: PropTypes.any,
	isDisabled: PropTypes.bool
};

export default CompanyBrandOption;
