export default {
	SEVEN_DAYS: { days: 7, translationId: 'invoice.payment.term.7.days' },
	FOURTEEN_DAYS: { days: 14, translationId: 'invoice.payment.term.14.days' },
	THIRTY_DAYS: { days: 30, translationId: 'invoice.payment.term.30.days' },
	SIXTY_DAYS: { days: 60, translationId: 'invoice.payment.term.60.days' },

	getArray() {
		return [this.SEVEN_DAYS, this.FOURTEEN_DAYS, this.THIRTY_DAYS, this.SIXTY_DAYS];
	},

	getClosest(days) {
		const options = this.getArray();
		let closest = null;
		let difference = Number.MAX_VALUE;
		options.forEach((option) => {
			const dif = Math.abs(option.days - days);
			if (dif < difference) {
				closest = option;
				difference = dif;
			}
		});

		if (!closest) {
			closest = this.FOURTEEN_DAYS;
		}

		return closest;
	}
};
