import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { _addZeros } from '../../../utils/objectToFormData';
import Signals from '../../../signals/Signals';
import { ApplicationContext } from '../../../ApplicationContext';
import WalkthroughStore from '../../../stores/WalkthroughStore';

/**
 *
 */
@observer
class WalkthroughStepList extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onStepClick = this.onStepClick.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	componentDidMount() {
		Signals.WalkthroughUpdate.add(this.onUpdate);
	}

	componentWillUnmount() {
		Signals.WalkthroughUpdate.remove(this.onUpdate);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		return (
			<div
				className="walkthrough-step-list"
				ref={(ref) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					this.el = ref;
				}}>
				<div className="walkthrough-step-list-content">
					{walkthroughStore.steps.map((walkthroughStep, index) => {
						if (index === 0 || index === walkthroughStore.steps.length - 1) {
							return null;
						}

						const rowClasses = classNames({
							'walkthrough-step-list-row': true,
							'walkthrough-step-list-row--selected':
								walkthroughStep.index === walkthroughStore.current
						});
						return (
							<div className="walkthrough-step-list-row__wrapper">
								<div
									className={rowClasses}
									onClick={this.onStepClick}
									data-index={walkthroughStep.index}>
									<div className="walkthrough-step-list-row__left">
										<span className="walkthrough-step-list-row__number">
											{_addZeros(walkthroughStep.step)}.
										</span>
										<span className="walkthrough-step-list-row--title">
											<FormattedMessage id={walkthroughStep.titleId} />
										</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onUpdate() {}

	/**
	 *
	 * @param event
	 */
	onStepClick(event) {
		const { applicationStore } = this.context;
		const { walkthroughStore } = applicationStore;

		const target = event.currentTarget;
		walkthroughStore.setStep(parseInt(target.dataset.index, 10));
	}
}

WalkthroughStepList.contextType = ApplicationContext;

WalkthroughStepList.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	walkthroughStore: PropTypes.instanceOf(WalkthroughStore)
};

WalkthroughStepList.defaultProps = {};

export default injectIntl(WalkthroughStepList);
