import AbstractCommand from '../AbstractCommand';
import companyCustomers from '../../requests/customers/companyCustomers';

/**
 *
 */
export default class GetCompanyCustomersCommand extends AbstractCommand {
	constructor(companyId) {
		super();
		this.companyId = companyId;
	}

	/**
	 *
	 * @param successCallback
	 * @param failCallback
	 */
	execute(successCallback, failCallback) {
		companyCustomers(this.companyId)
			.then((response) => {
				successCallback(response);
			})
			.catch((err) => {
				if (failCallback) {
					failCallback(err);
				}
			});
	}
}
