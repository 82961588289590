import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId) => {
	return fetch(
		Methods.GET,
		`${EndPoints.COMPANIES_PONTO_GET_ACCOUNTS}?companyId=${companyId}`,
		null,
		null,
		true
	);
};
