import AbstractCommand from '../AbstractCommand';
import { Routes } from '../../data/Routes';
import Signals from '../../signals/Signals';
import periodicInvoiceConceptCreate from '../../requests/periodicInvoiceConcepts/periodicInvoiceConceptCreate';
import periodicInvoiceConceptUpdate from '../../requests/periodicInvoiceConcepts/periodicInvoiceConceptUpdate';

const INVOICE_STATE_KEYS = [
	'senderEmail',
	'recipientEmail',
	'ccRecipientEmail',
	'bccRecipientEmail',
	'emailSubject',
	'emailMessage',
	'remindCompanyUserInDays',
	'remindCompanyCustomerInDays'
];

/**
 *
 */
export default class SavePeriodicInvoiceConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param applicationStore
	 * @param periodicInvoiceConceptModel
	 * @param company
	 * @param data
	 * @param supressRouting
	 */
	constructor(
		applicationStore,
		periodicInvoiceConceptModel,
		company,
		data,
		supressRouting = false
	) {
		super();

		this.applicationStore = applicationStore;
		this.periodicInvoicesConceptStore = this.applicationStore.periodicInvoicesConceptStore;
		this.periodicInvoiceConceptModel = periodicInvoiceConceptModel;
		this.company = company;
		this.data = data;
		this.supressRouting = supressRouting;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, _error) {
		const requestBody = this._createJSONData();

		// Update
		if (
			this.periodicInvoiceConceptModel.id !== undefined &&
			this.periodicInvoiceConceptModel.id !== null
		) {
			return periodicInvoiceConceptUpdate(
				this.company.id,
				this.periodicInvoiceConceptModel.id,
				requestBody
			).then((response) => {
				this._updateInvoiceConceptModel(response);

				if (success) {
					success(response);
				}
			});

			// Create new
		}
		return periodicInvoiceConceptCreate(this.company.id, this.data).then((response) => {
			this._updateInvoiceConceptModel(response);

			if (success) {
				success(response);
			}
		});
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_updateInvoiceConceptModel(response) {
		// Update model
		this.periodicInvoiceConceptModel.id = response.data.id;

		// Add if invoiceId was a `new` invoice or that it needs to be `duplicated`
		if (this.applicationStore.currentRouteParams.invoiceId === 'new') {
			// change current route if this was a `new` invoice to the route with this saved invoice id
			window.history.replaceState(
				{},
				document.title,
				Routes.COMPANY_INVOICES_PERIODIC_EDIT.getPath({
					id: this.company.id,
					invoiceId: this.periodicInvoiceConceptModel.id
				})
			);
		}

		// Navigate to invoice 'send' screen
		if (!this.supressRouting) {
			Signals.RequestRoute.dispatch(
				Routes.COMPANY_INVOICES_PERIODIC_INVOICE.getPath({
					id: this.company.id,
					invoiceId: this.periodicInvoiceConceptModel.id
				})
			);
		}
	}

	/**
	 *
	 * @returns {string}
	 * @private
	 */
	_createJSONData() {
		// Get initial InvoiceConceptModel state
		const jsonData = JSON.parse(this.periodicInvoiceConceptModel.toJSON());

		// Add changed state keys, filter out state keys which are not in INVOICE_STATE_KEYS
		INVOICE_STATE_KEYS.forEach((key) => {
			switch (key) {
				case 'remindCompanyUserInDays':
					jsonData[key] = this.data[key];
					break;
				default:
					jsonData[key] = this.data[key] ? this.data[key] : undefined;
			}
		});

		return jsonData;
	}
}
