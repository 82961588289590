import { observable } from 'mobx';
import Signal from 'signals';

import { validateSize, validateMimeType } from '../../utils/fileUtils';
import CreateUserDraftCommand from '../../commands/drafts/CreateUserDraftCommand';
import CreateCompanyDraftCommand from '../../commands/drafts/CreateCompanyDraftCommand';

export const DRAFT_UPLOAD_VALIDATION_ERRORS = {
	SIZE: 1,
	TYPE: 2
};

export const DRAFT_WORKER_STATE = {
	WAITING: 'DRAFT_WORKER_STATE_WAITING',
	STARTED: 'DRAFT_WORKER_STATE_STARTED',
	SUCCESS: 'DRAFT_WORKER_STATE_SUCCESS',
	FAILED: 'DRAFT_WORKER_STATE_FAILED'
};

let ID = 0;

export default class DraftUploadWorker {
	completeSignal = new Signal();

	removeSignal = new Signal();

	_uid = ID++;

	@observable file;

	@observable type;

	@observable maxSize;

	@observable accept;

	@observable draftsStore;

	@observable userId;

	@observable companyId;

	@observable state;

	@observable validationErrors = 0;

	@observable progress;

	/**
	 *
	 * @param file
	 * @param type
	 * @param maxSize
	 * @param accept
	 * @param draftsStore
	 * @param userId
	 * @param companyId
	 * @param financialYearId
	 */
	constructor(
		file,
		type,
		maxSize,
		accept,
		draftsStore,
		userId = null,
		companyId = null,
		financialYearId = null
	) {
		this.file = file;
		this.type = type;
		this.maxSize = maxSize;
		this.accept = accept;

		this.draftsStore = draftsStore;
		this.userId = userId;
		this.companyId = companyId;
		this.financialYearId = financialYearId;

		this.state = DRAFT_WORKER_STATE.WAITING;
		this.draft = null;
		this.progress = 0;

		this.onSuccess = this.onSuccess.bind(this);
		this.onError = this.onError.bind(this);
		this.onProgress = this.onProgress.bind(this);
	}

	/**
	 *
	 */
	validate() {
		const size = validateSize(this.file.size, this.maxSize);
		const type = validateMimeType(this.file.type, this.accept);

		this.validationErrors = 0;

		if (!size) {
			// eslint-disable-next-line no-bitwise
			this.validationErrors |= DRAFT_UPLOAD_VALIDATION_ERRORS.SIZE;
		}
		if (!type) {
			// eslint-disable-next-line no-bitwise
			this.validationErrors |= DRAFT_UPLOAD_VALIDATION_ERRORS.TYPE;
		}

		return size && type;
	}

	/**
	 *
	 */
	start() {
		if (this.validate()) {
			// User
			if (this.userId != null) {
				this.command = new CreateUserDraftCommand(this.draftsStore, this.userId, this.file);
				this.state = DRAFT_WORKER_STATE.STARTED;
				this.command.options.progressSignal.add(this.onProgress);
				this.command.execute(this.onSuccess, this.onError);
				// Company
			} else if (this.companyId != null) {
				this.command = new CreateCompanyDraftCommand(
					this.draftsStore,
					this.companyId,
					this.type,
					this.file,
					this.financialYearId
				);
				this.state = DRAFT_WORKER_STATE.STARTED;
				this.command.options.progressSignal.add(this.onProgress);
				this.command.execute(this.onSuccess, this.onError);
			}
		} else {
			this.state = DRAFT_WORKER_STATE.FAILED;
			this.completeSignal.dispatch(this);
		}
	}

	/**
	 *
	 * @return {Draft|null}
	 */
	getDraft() {
		if (this.command) {
			return this.command.draft;
		}

		return null;
	}

	/**
	 *
	 * @param event
	 * @param draft Draft
	 */
	onSuccess(event, draft) {
		this.progress = 1;
		this.state = DRAFT_WORKER_STATE.SUCCESS;
		this.draft = draft;

		this.completeSignal.dispatch(this);
	}

	onError() {
		this.progress = 1;
		this.state = DRAFT_WORKER_STATE.FAILED;

		this.completeSignal.dispatch(this);
	}

	/**
	 *
	 * @param event
	 */
	onProgress(event) {
		if (event.direction === 'upload') {
			this.progress = event.percent / 100;
		}
	}

	/**
	 *
	 */
	cancelAndRemove() {
		// Cancel request if already started
		if (this.command) {
			this.command.options._request.abort();
		}

		this.removeSignal.dispatch(this);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isWaiting() {
		return this.state === DRAFT_WORKER_STATE.WAITING;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isBusy() {
		return this.state === DRAFT_WORKER_STATE.STARTED;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isDone() {
		return this.state === DRAFT_WORKER_STATE.FAILED || this.state === DRAFT_WORKER_STATE.SUCCESS;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasFailed() {
		return this.state === DRAFT_WORKER_STATE.FAILED;
	}
}
