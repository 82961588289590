/**
 * Created by hugo on 10/10/17.
 */
const documentTypes = {
	// private
	PASPOORT: { name: 'PASPOORT', iconName: 'PASPOORT' },
	HYPOTHEEK: { name: 'HYPOTHEEK', iconName: 'HYPOTHEEK' },
	PENSIOEN: { name: 'PENSIOEN', iconName: 'PENSIOEN' },
	JAAROPGAVEN: { name: 'JAAROPGAVEN', iconName: 'JAAROPGAVEN' },
	VERMOGEN: { name: 'VERMOGEN', iconName: 'VERMOGEN' },

	// company
	JAARREKENING: { name: 'JAARREKENING', iconName: 'JAARREKENING' },
	BTW_AANGIFTE: { name: 'BTW_AANGIFTE', iconName: 'BTW_AANGIFTE' },
	BANK: { name: 'BANK', iconName: 'BANK' },

	SALARISSTROOK: { name: 'SALARISSTROOK', iconName: 'calendar' },
	JAAROPGAVE: { name: 'JAAROPGAVE', iconName: 'calendar' }, // COMPANY DOCUMENT for employee
	CUMULATIEVE_JOURNAALPOSTEN: { name: 'CUMULATIEVE_JOURNAALPOSTEN', iconName: 'calendar' },
	LOONAANGIFTE: { name: 'LOONAANGIFTE', iconName: 'calendar' },
	JAARWERK: { name: 'JAARWERK', iconName: 'calendar' },

	// shared
	OVERIG: { name: 'OVERIG', iconName: 'OVERIG' }
};

export default documentTypes;

/**
 *
 * @return {string[]}
 */
export function getCompanyDocumentTypes() {
	return [
		documentTypes.BTW_AANGIFTE,
		documentTypes.JAARREKENING,
		documentTypes.BANK,
		documentTypes.OVERIG
	];
}

/**
 *
 * @return {*[]}
 */
export function getPrivateDocumentTypes() {
	return [
		documentTypes.PASPOORT,
		documentTypes.HYPOTHEEK,
		documentTypes.PENSIOEN,
		documentTypes.JAAROPGAVEN,
		documentTypes.VERMOGEN,
		documentTypes.OVERIG
	];
}
