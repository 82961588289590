import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 *
 * @param data
 * @param companyId
 * @param financialYearId
 * @param sendToBookkeeper
 * @return {Promise}
 */
export default (data, companyId, financialYearId, sendToBookkeeper) => {
	const endPoint = sendToBookkeeper
		? `${EndPoints.DOCUMENT_COMPANY_NEW}?sendToBookkeeper=true`
		: EndPoints.DOCUMENT_COMPANY_NEW;
	return fetch(
		Methods.POST,
		endPoint.replace(':id', companyId).replace(':year', financialYearId),
		data,
		null,
		true
	);
};
