/* eslint-disable no-case-declarations */
import { observable, computed, action } from 'mobx';
import { compareStrings } from '../utils/compareUtils';
import financialYearIncome from '../requests/financialYearIncome';

import Signals from '../signals/Signals';

import { getBTWPercentages, getTotalAmountInclBTW, getTotalAmountExclBTW } from '../utils/btwUtils';

import IncomeModel from './models/IncomeModel';

/**
 *
 */
export default class IncomeStore {
	@observable invoices = [];

	@observable filter = null;

	@observable sortBy = null;

	@observable sortDirection = 1;

	@observable fetched = false;

	// List of ids to ignore when filtering. Those items will not returned in the filtered list.
	@observable ignoreIds = [];

	/**
	 *
	 */
	constructor() {
		this._filter = this._filter.bind(this);
		this._sort = this._sort.bind(this);
	}

	/**
	 ** @returns {IncomeModel | null}
	 */
	findById(id) {
		return this.invoices.find((incomeModel) => {
			return incomeModel.id === id;
		});
	}

	/**
	 * Retrieves all Invoices from the server for the specified financialYear
	 * and replaces any current invoices in the invoices array.
	 *
	 * @param financialYearId
	 * @returns {Promise.<T>}
	 */
	fetch(financialYearId) {
		return financialYearIncome(financialYearId)
			.then((response) => {
				// Null results, probbably loggedout
				if (response.list == null && response.data == null) {
					const error = new Error();
					error.message = 'no results';
					error.status = 401;
					Signals.Error.dispatch(error);
					return;
				}

				const invoices = [];

				response.list.forEach((data) => {
					invoices.push(IncomeModel.parseIncome(data));
				});

				this.invoices = invoices;
				this.resetFilterAndSort();
				this.fetched = true;
			})
			.catch((err) => {
				Signals.Error.dispatch(err);
			});
	}

	/**
	 *
	 */
	@action reset() {
		this.invoices = [];

		this.filter = null;
		this.sortBy = null;
		this.sortDirection = 1;
		this.fetched = false;
		this.ignoreIds = [];
	}

	/**
	 * Filter and sorted Expenses
	 *
	 * @returns {Array.<ExpenseModel>}
	 */
	@computed get sortedIncomeById() {
		return this.invoices.sort((a, b) => {
			return b.id - a.id;
		});
	}

	/**
	 * Filter Income
	 *
	 * @returns {Array.<IncomeModel>}
	 */
	@computed get filtered() {
		return this.invoices.filter((income) => {
			return this._filter(income);
		});
	}

	/**
	 * Filter and sorted Income
	 *
	 * @returns {Array.<Income>}
	 */
	@computed get filteredAndSortedIncome() {
		return this.invoices
			.filter((income) => {
				return this._filter(income);
			})
			.sort(this._sort);
	}

	@computed get totalAmountInclBTW() {
		return this.filteredAndSortedIncome.reduce((total, income) => {
			return total + income.totalAmountInclBTW;
		}, 0);
	}

	@computed get totalAmountExclBTW() {
		return this.filteredAndSortedIncome.reduce((total, income) => {
			return total + income.totalAmountExclBTW;
		}, 0);
	}

	/**
	 *
	 * @param id
	 */
	removeIncome(id) {
		const invoice = this.invoices.find((i) => {
			return i.id === id;
		});

		this.invoices.remove(invoice);
		Signals.IncomeTableUpdated.dispatch();
	}

	/**
	 *
	 * @param invoiceData
	 * @return {IncomeModel}
	 */
	addIncome(invoiceData) {
		const invoice = IncomeModel.parseIncome(invoiceData);
		this.invoices.push(invoice);
		Signals.IncomeTableUpdated.dispatch();
		return invoice;
	}

	/**
	 *
	 * @param invoiceData
	 * @return {null|*}
	 */
	@action updateIncome(invoiceData) {
		const invoice = this.invoices.find((i) => {
			return i.id === invoiceData.id;
		});

		if (invoice) {
			IncomeModel.updateIncome(invoiceData, invoice);
			Signals.IncomeTableUpdated.dispatch();

			// Force update
			this.removeIncome(invoice.id);
			this.invoices.push(invoice);

			return invoice;
		}
		console.error('invoice not found');

		return null;
	}

	@action updateIgnoreIds(ids) {
		this.ignoreIds = ids;
	}

	/**
	 *
	 */
	resetFilterAndSort() {
		this.filter = null;
		this.sortBy = null;
	}

	/**
	 *
	 * @param invoice IncomeModel
	 * @returns {boolean}
	 * @private
	 */
	_filter(invoice) {
		if (invoice && this.ignoreIds.indexOf(invoice.id) !== -1) {
			return false;
		}

		if (!this.filter || !invoice) {
			return true;
		}

		return invoice.searchString.indexOf(this.filter.toLowerCase()) > -1;
	}

	/**
	 *
	 * @param a
	 * @param b
	 * @returns {number}
	 * @private
	 */
	_sort(a, b) {
		let result = 1;
		switch (this.sortBy) {
			case 'invoiceNr':
				result = compareStrings(a.invoiceNr, b.invoiceNr);
				break;
			case 'state':
				result = a.state.localeCompare(b.state);
				break;
			case 'customerName':
				result = a.customerName.localeCompare(b.customerName);
				break;
			case 'description':
				result = a.description.localeCompare(b.description);
				break;
			case 'projectcode':
				a = a.projectcode ? a.projectcode : '';
				b = b.projectcode ? b.projectcode : '';
				result = a.localeCompare(b);
				break;
			case 'fileName':
				if (!a.fileName && !b.fileName) {
					result = 0;
				} else if (a.fileName && !b.fileName) {
					result = 1;
				} else if (!a.fileName && b.fileName) {
					result = -1;
				} else {
					result = a.fileName.localeCompare(b.fileName);
				}
				break;
			case 'btw':
				const btwA = `${getBTWPercentages(a.invoiceRows, a.date)}`;
				const btwB = `${getBTWPercentages(b.invoiceRows, b.date)}`;
				result = btwA.localeCompare(btwB);
				break;
			case 'inclBTW':
				result = getTotalAmountInclBTW(a.invoiceRows) - getTotalAmountInclBTW(b.invoiceRows);
				break;
			case 'exclBTW':
				result =
					getTotalAmountExclBTW(a.invoiceRows, a.date) -
					getTotalAmountExclBTW(b.invoiceRows, b.date);
				break;
			case 'date':
			default:
				// eslint-disable-next-line no-nested-ternary
				result = a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
		}

		// Tied
		if (result === 0) {
			result = b.id - a.id;
		}

		return result * this.sortDirection;
	}
}
