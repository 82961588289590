import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import invoiceConceptDelete from '../../requests/invoiceConceptDelete';

/**
 *
 */
export default class DeleteInvoiceConceptCommand extends AbstractCommand {

	/**
	 *
	 * @param invoicesConceptStore InvoicesConceptStore
	 * @param invoiceConceptModel InvoiceConceptModel
	 * @param company Company
	 */
	constructor(invoicesConceptStore, invoiceConceptModel, company) {
		super();

		this.invoicesConceptStore = invoicesConceptStore;
		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return invoiceConceptDelete(this.company.id, this.invoiceConceptModel.id)
			.then((response) => {

				this._removeInvoiceFromStore();
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_removeInvoiceFromStore() {
		this.invoicesConceptStore.removeInvoiceConcept(this.invoiceConceptModel.id);
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="invoice.removed.message"
								   values={{invoiceNr: this.invoiceConceptModel.invoiceNr}}/>)
		);
	}
}
