import AbstractCommand from '../AbstractCommand';
import periodicInvoiceConceptsGet from '../../requests/periodicInvoiceConcepts/periodicInvoiceConceptsGet';
import PeriodicInvoiceConceptModel from '../../stores/models/PeriodicInvoiceConceptModel';

/**
 *
 */
export default class GetPeriodicInvoicesConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param periodicInvoicesConceptStore PeriodicInvoicesConceptStore
	 * @param companyId Number
	 */
	constructor(periodicInvoicesConceptStore, companyId) {
		super();

		this.periodicInvoicesConceptStore = periodicInvoicesConceptStore;
		this.companyId = companyId;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		// When request already executing, don't execute again
		if (this.periodicInvoicesConceptStore.fetching) {
			return this.periodicInvoicesConceptStore.fetching;
		}
		this.periodicInvoicesConceptStore.fetching = periodicInvoiceConceptsGet(this.companyId);
		this.periodicInvoicesConceptStore.fetching
			.then((response) => {
				this._parseResult(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});

		return this.periodicInvoicesConceptStore.fetching;
	}

	/**
	 *
	 * @param response
	 * @private
	 */
	_parseResult(response) {
		this.periodicInvoicesConceptStore.reset();
		const periodicInvoices = [];
		response.list.forEach((data) => {
			periodicInvoices.push(PeriodicInvoiceConceptModel.parsePeriodicInvoiceConceptData(data));
		});

		this.periodicInvoicesConceptStore.addAll(periodicInvoices);
	}
}
