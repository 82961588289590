/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

import EndPoints from '../../../data/EndPoints';

import FormGroup from '../FormGroup/FormGroup';
import FormField from '../FormField/FormField';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import FileUpload from '../FileUpload/FileUpload';

import ModalAlert from '../Modal/ModalAlert';
import { uniqueKey } from '../../../utils/ReactUtils';

@observer
/**
 *
 */
class Question extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			uniqueFileUploadKey: 0,
			previewUrlBase:
				window.config.apiPrefix +
				(this.props.type === 'annual'
					? EndPoints.ANNUAL_STATEMENT_FILE
					: EndPoints.FINANCIAL_STATEMENT_FILE)
		};

		this.onChange = this.onChange.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.onExtraInfoClick = this.onExtraInfoClick.bind(this);
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps() {
		this.state = {
			uniqueFileUploadKey: 0,
			previewUrlBase:
				window.config.apiPrefix +
				(this.props.type === 'annual'
					? EndPoints.ANNUAL_STATEMENT_FILE
					: EndPoints.FINANCIAL_STATEMENT_FILE)
		};
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		const {
			questionModel,
			showFilesWhenFalse,
			intl,
			disabled,
			allowedFiles,
			children,
			hidden,
			className,
			label,
			description,
			yesDescription,
			noDescription
		} = this.props;

		let fileUploads = [];
		const files = questionModel.getFiles();

		if (
			(!showFilesWhenFalse && questionModel.isAnswered() && questionModel.checked) || // show files when checked
			(showFilesWhenFalse && questionModel.isAnswered() && !questionModel.checked)
		) {
			// show files when unchecked

			// Create FileUploads for existing files
			if (files && files.length > 0) {
				fileUploads = files.map((file, index) => {
					const filePreview = this._filePreviewUrl(file);

					return (
						<FileUpload
							className="col--12"
							key={`trfu-${this.state.uniqueFileUploadKey++}`}
							name={`file.${index}`}
							showFilePreview={false}
							filePreview={filePreview}
							fileName={file.name}
							label={intl.formatMessage({ id: 'fileupload.drag.file' })}
							disabled={disabled || questionModel.disabled}
							onChange={this.onFileChange}
						/>
					);
				});
			}

			// Show one extra empty option for more files
			if (files.length < allowedFiles && !disabled) {
				fileUploads.push(
					<FileUpload
						className="col--12"
						key={`trfu-${this.state.uniqueFileUploadKey++}`}
						name={`file.${files.length}`}
						showFilePreview={false}
						filePreview={undefined}
						fileName={undefined}
						label={intl.formatMessage({ id: 'fileupload.drag.file' })}
						disabled={disabled || questionModel.disabled}
						onChange={this.onFileChange}
					/>
				);
			}
		}

		const questionClasses = classNames({
			'question--disabled': disabled || questionModel.disabled,
			'question--hidden': hidden
		});

		const questionMetaContainerClasses = classNames({
			'question__meta-container--active': children && questionModel.checked
		});

		const questionFileContainerClasses = classNames({
			'question__files-container--active': fileUploads != null && fileUploads.length > 0
		});

		return (
			<div className={`question border--top border--dark ${questionClasses} ${className || ''}`}>
				<FormGroup className="col--12 padding-small">
					<FormField className="col--12 grid--spread">
						<div className="col--12 grid grid--spread grid--vcenter">
							<div className="col--8">
								<h3>{label}</h3>
								{/* eslint-disable-next-line react/no-danger */}
								<div dangerouslySetInnerHTML={{ __html: description }} />
								{yesDescription && questionModel.isAnswered() && questionModel.checked
									? yesDescription
									: null}
								{noDescription && questionModel.isAnswered() && !questionModel.checked
									? noDescription
									: null}
								{this._getExtraInfoLink()}
							</div>
							<CheckboxButton
								key={uniqueKey('cbb-')}
								className="col--3"
								checked={questionModel.checked}
								yesLabel={intl.formatMessage({ id: 'label.yes' })}
								noLabel={intl.formatMessage({ id: 'label.no' })}
								onChange={this.onChange}
								disabled={disabled || questionModel.disabled}
							/>
						</div>
					</FormField>
				</FormGroup>

				<div className={`question__meta-container ${questionMetaContainerClasses}`}>
					{this.props.children}
				</div>

				<div
					className={`question__files-container border--top border--dark ${questionFileContainerClasses}`}>
					<label>
						<FormattedMessage id="question.upload.files.label" />
					</label>
					{fileUploads}
				</div>
			</div>
		);
	}

	/**
	 *
	 * @param checked
	 */
	onChange(checked) {
		this.props.questionModel.checked = checked;
		this.update();
	}

	/**
	 *
	 * @param file
	 * @param fileName
	 * @param fieldName
	 */
	onFileChange(file, fileName, fieldName) {
		const questionModel = this.props.questionModel;
		const files = questionModel.getFiles();
		const fileIndex = parseInt(fieldName.split('.')[1], 10);

		// Remove file
		if (!file && fileIndex < files.length) {
			questionModel.removeFile(fileIndex);
		}

		// Add file
		if (file && fileIndex >= files.length) {
			questionModel.addFile(file);
		}

		this.update();
	}

	/**
	 *
	 */
	onExtraInfoClick() {
		let body = this.props.intl.formatHTMLMessage(
			{ id: this.props.extraInfoBodyId },
			this.props.extraInfoValues
		);
		if (this.props.yearSpecificInfoId) {
			body += this.props.intl.formatHTMLMessage(
				{ id: this.props.yearSpecificInfoId },
				this.props.extraInfoValues
			);
		}

		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatHTMLMessage(
					{ id: this.props.extraInfoTitleId },
					this.props.extraInfoValues
				)}
				body={body}
				wide
				textLeftAligned
			/>
		);
	}

	/**
	 *
	 */
	update() {
		if (this.props.onChange) {
			this.props.onChange(this.props.index, this.props.questionModel);
		}

		this.forceUpdate();
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getExtraInfoLink() {
		if (this.props.extraInfoBodyId) {
			return (
				<div className="question__extra-info link icon icon--info" onClick={this.onExtraInfoClick}>
					<FormattedMessage id="question.extrainfo.label" />
				</div>
			);
		}

		return null;
	}

	/**
	 *
	 * @param file
	 * @returns {*}
	 * @private
	 */
	_filePreviewUrl(file) {
		if (file instanceof File) {
			return undefined;
		}
		return file.id ? this.state.previewUrlBase.replace(':fileid', file.id) : undefined;
	}
}

Question.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	intl: PropTypes.object,
	className: PropTypes.string,
	index: PropTypes.number,
	label: PropTypes.string,
	description: PropTypes.node,
	yesDescription: PropTypes.node,
	noDescription: PropTypes.node,
	allowedFiles: PropTypes.number,
	showFilesWhenFalse: PropTypes.bool,
	onChange: PropTypes.func,
	questionModel: PropTypes.object,
	type: PropTypes.string,
	hidden: PropTypes.bool,
	disabled: PropTypes.bool,
	extraInfoTitleId: PropTypes.string,
	extraInfoBodyId: PropTypes.string,
	yearSpecificInfoId: PropTypes.string,
	extraInfoValues: PropTypes.object
};

Question.defaultProps = {
	children: null,
	className: '',
	allowedFiles: 0,
	index: 0,
	hidden: false,
	type: 'annual' // financial | annual
};

export default injectIntl(withRouter(Question));
