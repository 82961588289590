import { injectIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../Page';
import ResultGraph from './components/ResultsGraph';
import ResultRow from './components/ResultRow';

import Signals from '../../../signals/Signals';

import LedgerCodes from '../../../data/LedgerCodes';
import { ApplicationContext } from '../../../ApplicationContext';

@observer
/**
 *
 */
class Results extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.update = this.update.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.update();
		Signals.CompanyChanged.add(this.update);
		Signals.FinancialYearChanged.add(this.update);
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyChanged.remove(this.update);
		Signals.FinancialYearChanged.remove(this.update);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const yearResultStore = this.context.applicationStore.yearResultStore;
		const monthStart = 1; // Ignore month 0 because it contains the results from the last month of the previous year
		const monthEnd = 12;

		const housingTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4200,
			LedgerCodes.GC_4201
		]);
		const salesTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4400,
			LedgerCodes.GC_4401
		]);
		const officeTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4500,
			LedgerCodes.GC_4501
		]);
		const travelTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4300,
			LedgerCodes.GC_4301,
			LedgerCodes.GC_4302
		]);
		const productionTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_7100,
			LedgerCodes.GC_7101,
			LedgerCodes.GC_7102
		]);
		const consumptionTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4600
		]);
		const personnelTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4100
		]);
		const otherTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_4602
		]);

		const investmentTotal = yearResultStore.getTotalForLedgerCodes(monthStart, monthEnd, [
			LedgerCodes.GC_0100,
			LedgerCodes.GC_0101
		]);

		const revenueTotal = yearResultStore.getTotalRevenue(monthStart, monthEnd);
		const expensesTotal =
			housingTotal +
			salesTotal +
			officeTotal +
			travelTotal +
			productionTotal +
			consumptionTotal +
			personnelTotal +
			otherTotal; // + investmentTotal;
		const profit = revenueTotal - expensesTotal;

		// eslint-disable-next-line array-callback-return
		const vatRows = yearResultStore.sortedVatTerms.map((term, index) => {
			const period = term.taxPeriod;
			const amount = term.amount;
			const now = Date.now();
			const start = new Date(term.start);
			const status = term.status;
			const inFuture = start > now;

			let dateText = '';
			let vatText = '';

			// Check type of term
			switch (period) {
				case 'MONTH':
					dateText = this.props.intl.formatDate(start, { month: 'long' });
					break;
				case 'QUARTER':
					// eslint-disable-next-line no-case-declarations
					const quarter = Math.round((new Date(start).getMonth() + 1) / 4);
					dateText = this.props.intl.formatMessage({
						id: `results.btw.row.quarter.${quarter + 1}`
					});
					break;
				case 'YEAR':
				case 'VRIJGESTELD':
					dateText = this.props.intl.formatDate(start, { year: 'numeric' });
					break;
				default:
			}

			// Check status
			switch (status) {
				case 'SEND':
					vatText = this.props.intl.formatMessage({ id: 'results.btw.row.btwpaid' });
					break;
				case 'PREDICTION':
				case 'SUBMITTED':
				default:
					vatText = this.props.intl.formatMessage({ id: 'results.btw.row.btwforecast' });
					break;
			}

			// Check if needs to be shown
			if (inFuture && term.amount === 0) {
				// ignore
			} else {
				const text = `${vatText} ${dateText}`;
				return <ResultRow key={`rr-${index}`} className="col--12" text={text} value={amount} />;
			}
		});

		return (
			<Page pageName="results">
				<div className="grid">
					<div className="dashboard__panel grid col--12">
						<ResultGraph yearResultStore={this.context.applicationStore.yearResultStore} />
					</div>

					<div className="results__panel grid col--6">
						<div className="panel results__year-summary grid grid--spread col--12 box-shadow border border--dark">
							<div className="padding border--bottom border-box border--dark col--12">
								<h2 className="font--title-grey">
									<span className="dashboard__panel-header__number">01</span>
									<FormattedMessage id="results.year.total.title" />
								</h2>
							</div>

							<div className="results__year-info-container padding">
								<ResultRow className="col--12" label="results.revenue" value={revenueTotal} />
								<ResultRow className="col--12" label="results.total.costs" value={expensesTotal} />

								<div className="results__year-info-extra grid">
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.productie"
										value={productionTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.kantoor"
										value={officeTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.reis"
										value={travelTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.huisvesting"
										value={housingTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.verkoop"
										value={salesTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.consumptie"
										value={consumptionTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.personnel"
										value={personnelTotal}
										isLight
									/>
									<ResultRow
										className="col--12"
										labelPrefix
										label="results.overig"
										value={otherTotal}
										isLight
									/>
								</div>

								<ResultRow
									className="col--12"
									label="results.investering"
									value={investmentTotal}
								/>
							</div>

							<div className="results__year-info__last-row grid result-row--dark">
								<ResultRow className="col--12" label="results.profit" value={profit} />
							</div>
						</div>
					</div>

					<div className="results__panel grid col--6 ">
						<div className="panel results__vat-summary grid col--12 box-shadow border border--dark">
							<div className="results__vat-summary__header padding border--bottom border--dark col--12">
								<h2 className="font--title-grey">
									<span className="dashboard__panel-header__number">02</span>
									<FormattedMessage id="results.btw.title" />
								</h2>
							</div>

							<div className="results__vat-summary__content padding grid col--12">
								<div className="grid col--12">{vatRows}</div>
							</div>

							<div className="results__vat-summary__filler" />

							<div className="results__vat-summary__vat padding">
								<div className="result-row__label result-row__vat-forecast-label">
									<FormattedMessage id="results.btw.forecast.title" />
								</div>
								<p>
									<FormattedMessage id="results.btw.forecast.description" />
								</p>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}

	/**
	 *
	 */
	update() {
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();
		if (financialYear) {
			this.context.applicationStore.yearResultStore.fetch(financialYear.id);
			this.context.applicationStore.yearResultStore.fetchVAT(financialYear.id);
		}
	}
}

Results.contextType = ApplicationContext;

Results.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(Results);
