import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

/**
 *
 * @param companyId
 * @param uuid
 * @param ccw
 * @return {Promise}
 */
export default (companyId, uuid, ccw = false) => {
	let url = EndPoints.COMPANY_ASSETS_UUID_ROTATE.replace(':id', companyId).replace(':uuid', uuid);
	if (ccw) {
		url += '?ccw=true';
	}
	return fetch(Methods.POST, url, {}, null, true);
};
