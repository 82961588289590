export function forceControlledValue(value) {
	return value === undefined || value === null ? '' : value;
}

export function getStateValue(stateValue, fallbackValue) {
	return stateValue !== undefined ? stateValue : fallbackValue;
}

export function uuidv4() {
	// eslint-disable-next-line func-names
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		// eslint-disable-next-line no-bitwise
		const r = (Math.random() * 16) | 0;
		// eslint-disable-next-line no-bitwise
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function uniqueKey(prefix = '', postfix = '') {
	return prefix + uuidv4() + postfix;
}
