import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId, periodicInvoiceConceptId) => {
	return fetch(
		Methods.DELETE,
		EndPoints.COMPANIES_PERIODIC_INVOICE_CONCEPTS_ID.replace(':id', companyId).replace(
			':invoiceConceptId',
			periodicInvoiceConceptId
		),
		null,
		null,
		true
	);
};
