import { useContext } from 'react';
import { ApplicationContext } from '../ApplicationContext';

// eslint-disable-next-line no-unused-vars
import ApplicationStore from '../stores/ApplicationStore';

/**
 * This hook returns the application store.
 * @return {ApplicationStore}
 */
const useApplicationStore = () => {
	const context = useContext(ApplicationContext);
	const { applicationStore } = context;

	return applicationStore;
};

export default useApplicationStore;
