/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { observer } from 'mobx-react';

import Signals from '../../../../signals/Signals';
import CompanyCustomer from '../../../../stores/models/CompanyCustomer';

import ModalConfirm from '../../../../components/ui/Modal/ModalConfirm';
import ClientActions from '../../../../components/ui/ToolTip/tooltips/ClientActions';
import Truncate from '../../../../components/ui/Truncate/Truncate';
import ClientInput from './modals/ClientInput';
import { ApplicationContext } from '../../../../ApplicationContext';
import { uniqueKey } from '../../../../utils/ReactUtils';
import DeleteCompanyCustomerCommand from '../../../../commands/companyCustomers/DeleteCompanyCustomerCommand';

@observer
/**
 *
 */
class ClientsRow extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.onActionClick = this.onActionClick.bind(this);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onFieldClick = this.onFieldClick.bind(this);
		this._doRemove = this._doRemove.bind(this);
	}

	/**
	 *
	 */
	render() {
		const sortBy = this.context.applicationStore.companyCustomerStore.sortBy;
		const sortByClasses = sortBy ? `clients-row--sorted-by--${sortBy.toLowerCase()}` : '';

		return (
			<tr className={`table-row ${sortByClasses}`}>
				<td
					className="table-row__column table-row__column--editable clients-row__contactpersonname"
					onClick={() => this.onFieldClick('contactPersonName')}>
					{this.props.companyCustomerModel.contactPersonName}
				</td>
				<td
					className="table-row__column table-row__column--editable clients-row__contactpersonemail"
					onClick={() => this.onFieldClick('contactPersonEmail')}>
					{this.props.companyCustomerModel.contactPersonEmail}
				</td>
				<td
					className="table-row__column table-row__column--editable clients-row__companyname"
					onClick={() => this.onFieldClick('companyName')}>
					<Truncate>{this.props.companyCustomerModel.companyName}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--editable clients-row__address"
					onClick={() => this.onFieldClick('address')}>
					{this.props.companyCustomerModel.getAddress()}
				</td>
				<td
					className="table-row__column table-row__column--editable clients-row__info"
					onClick={() => this.onFieldClick('info')}>
					<Truncate>{this.props.companyCustomerModel.info}</Truncate>
				</td>
				<td
					className="table-row__column table-row__column--center table-row__actions"
					onClick={this.onActionClick}>
					<div className="table-row__actions--available icon icon--more-smoke" />
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param e
	 */
	onActionClick(e) {
		e.preventDefault();

		Signals.ShowToolTip.dispatch(
			<ClientActions onSelect={this.onActionSelect} />,
			e.currentTarget,
			-16
		);
	}

	/**
	 *
	 * @param action
	 */
	onActionSelect(action) {
		switch (action) {
			case 'remove':
				this._remove();
				break;
			case 'edit':
				this._edit();
				break;
			default:
				console.log('Action not found', action);
		}
	}

	/**
	 *
	 * @param field
	 */
	onFieldClick(field) {
		this._edit(field);
	}

	/**
	 *
	 * @private
	 */
	_remove() {
		const { intl, companyCustomerModel } = this.props;
		const { contactPersonName, companyName } = companyCustomerModel;

		const title = intl.formatMessage(
			{ id: 'client.remove.alert.title' },
			{ contactPersonName, companyName: companyName || '-' }
		);

		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={title}
				// eslint-disable-next-line react/jsx-no-bind
				onConfirm={this._executeRemove.bind(this)}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes.remove' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no.keep' })}
			/>
		);
	}

	_executeRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		const command = new DeleteCompanyCustomerCommand(company, this.props.companyCustomerModel.id);
		command.execute(this._doRemove);
	}

	/**
	 *
	 * @private
	 */
	_doRemove() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			this.context.applicationStore.companyCustomerStore.remove(
				this.props.companyCustomerModel,
				() => {
					Signals.ShowMessageDialog.dispatch(
						<FormattedHTMLMessage
							id="client.removed.message"
							values={{ companyName: this.props.companyCustomerModel.companyName }}
						/>
					);
				}
			);
		}
	}

	/**
	 *
	 * @param fieldToFocus
	 * @private
	 */
	_edit(fieldToFocus) {
		// Create copy to prevent unwanted changes to original
		const companyCustomer = this.props.companyCustomerModel.clone();

		// Show ClientInput overlay
		Signals.ShowOverlay.dispatch(
			<ClientInput
				key={uniqueKey('cc-')}
				fieldToFocus={fieldToFocus}
				companyCustomer={companyCustomer}
			/>
		);
	}
}

ClientsRow.contextType = ApplicationContext;

ClientsRow.propTypes = {
	companyCustomerModel: PropTypes.instanceOf(CompanyCustomer).isRequired,
	intl: PropTypes.object
};

export default injectIntl(ClientsRow);
