import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import LinkUnlink from '../../../../components/icons/LinkUnLink';
import DeleteBankRecordsMatchCommand from '../../../../commands/bank/DeleteBankRecordsMatchCommand';
import useCompany from '../../../../utils/useCompany';
import Signals from '../../../../signals/Signals';
import TransactionMatch from './TransactionMatch/TransactionMatch';
import BankRecordMatchType from '../../../../data/BankRecordMatchType';

/**
 *
 * @param {BankRecord} bankRecord
 * @returns
 */
function TransactionActions({ bankRecord }) {
	const company = useCompany();

	const unMatch = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (company && bankRecord) {
			const command = new DeleteBankRecordsMatchCommand();
			command.execute(bankRecord, company.id).then(() => {
				Signals.HideToolTip.dispatch();
			});
		}
	};

	const match = (e) => {
		e.preventDefault();
		e.stopPropagation();
		Signals.HideToolTip.dispatch();
		Signals.ShowOverlay.dispatch(<TransactionMatch bankRecord={bankRecord} />);
	};

	return (
		<div className="flex flex-col bg-white">
			{bankRecord.matchStatus === BankRecordMatchType.MATCHED && (
				<>
					<div className="actions__action" onClick={match}>
						<i className="icon icon--edit-black" />
						<FormattedMessage id="label.edit" />
					</div>
					<div
						className="flex flex-row space-x-3 px-5 py-4 hover:bg-zinc-100 hover:cursor-pointer"
						onClick={unMatch}>
						<LinkUnlink className="align-middle my-auto" />
						<FormattedMessage id="label.unmatch" />
					</div>
				</>
			)}
			{bankRecord.matchStatus === BankRecordMatchType.UNMATCHED && (
				<div
					className="flex flex-row space-x-3 px-5 py-4 hover:bg-zinc-100 hover:cursor-pointer"
					onClick={match}>
					<LinkUnlink className="align-middle my-auto" />
					<FormattedMessage id="label.match" />
				</div>
			)}
		</div>
	);
}

TransactionActions.propTypes = {
	bankRecord: PropTypes.object.isRequired
};

export default TransactionActions;
