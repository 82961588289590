import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	FormattedHTMLMessage,
	injectIntl,
	FormattedDate,
	FormattedNumberParts,
	FormattedNumber
} from 'react-intl';
import { useDebounce } from 'react-use';
import { FiList } from 'react-icons/fi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Observer } from 'mobx-react';
import FormattedMessageWithClass from '../../../../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import OverlayPanel from '../../../../../components/ui/Panel/OverlayPanel';
import PanelHeader from '../../../../../components/ui/Panel/PanelHeader';
import Signals from '../../../../../signals/Signals';
// eslint-disable-next-line no-unused-vars
import BankRecord from '../../../../../stores/models/BankRecord';
import SearchField from '../../../../../components/ui/SearchField/SearchField';
import { BankRecordType } from '../../../../../data/BankRecordType';
import { defaultDateFormatOptions } from '../../../../../data/Settings';
import useIncomeStore from '../../../../../utils/useIncomeStore';
import useExpenseStore from '../../../../../utils/useExpenseStore';
import useFiscalYear from '../../../../../utils/useFiscalYear';
import useCompany from '../../../../../utils/useCompany';
import { getTotalAmountInclBTW } from '../../../../../utils/btwUtils';
import IncomeExpenseTable from './IncomeExpenseTable';
import BankRecordMatchStore from '../../../../../stores/BankRecordMatchStore';
import useBankRecordStore from '../../../../../utils/useBankRecordStore';
import useBankCategoryStore from '../../../../../utils/useBankCategoryStore';
import MatchBankRecordsCommand from '../../../../../commands/bank/MatchBankRecordsCommand';
import Category from '../Category/Category';
import BankRecordMatchType from '../../../../../data/BankRecordMatchType';
import IncomeModel from '../../../../../stores/models/IncomeModel';
import CreateCompanyDraftCommand from '../../../../../commands/drafts/CreateCompanyDraftCommand';
import draftTypes from '../../../../../data/DraftTypes';
import ExpenseModel from '../../../../../stores/models/ExpenseModel';
import { amountWithSignFromParts } from '../../../../../utils/intlUtils';

/**
 *
 * @param {BankRecord} bankRecord
 * @returns
 */

function TransactionMatch({ bankRecord, intl }) {
	const [search, setSearch] = useState('');
	const [debouncedSearch, setDebouncedSearch] = React.useState('');

	const company = useCompany();
	const fiscalYear = useFiscalYear();
	const incomeStore = useIncomeStore();
	const expenseStore = useExpenseStore();
	const bankRecordStore = useBankRecordStore();
	const bankCategoryStore = useBankCategoryStore();
	const [bankRecordMatchStore, setBankRecordMatchStore] = useState(null);
	const storeRef = React.useRef(null);

	useEffect(() => {
		storeRef.current = bankRecordMatchStore;
	}, [bankRecordMatchStore]);

	const [, cancel] = useDebounce(
		() => {
			setDebouncedSearch(search);
		},
		300,
		[search]
	);

	const hideOverlay = useCallback(() => {
		Signals.HideOverlay.dispatch();
	}, []);

	const resetStore = () => {
		// Use refenrence to access store on unmount.
		// eslint-disable-next-line no-unused-expressions
		storeRef.current && storeRef.current.reset();
	};

	const onSave = () => {
		if (!bankRecordMatchStore || !company) return;
		const command = new MatchBankRecordsCommand(
			company,
			fiscalYear,
			bankRecordMatchStore,
			bankCategoryStore
		);
		command.execute(() => {
			hideOverlay();
		});
	};

	const handleUnMatchEditResouce = () => {
		bankRecordMatchStore.setBankRecordMatchedResourceInEdit(null);
		bankRecordMatchStore.setIsEditted(true);
	};

	const handleCreateConcept = () => {
		if (bankRecord.type === BankRecordType.INCOME) {
			const draft = IncomeModel.createDraftIncomeFromBankRecord(bankRecord);
			const command = new CreateCompanyDraftCommand(
				company.draftsStore,
				company.id,
				draftTypes.INCOME,
				null,
				fiscalYear.id,
				draft
			);
			command.execute(() => {
				Signals.ShowMessageDialog.dispatch(
					<FormattedHTMLMessage id="transaction.income.draft.added.message" />
				);
				onSave();
			});
		} else {
			const draft = ExpenseModel.createDraftExpenseFromBankRecord(bankRecord);
			const command = new CreateCompanyDraftCommand(
				company.draftsStore,
				company.id,
				draftTypes.EXPENSE,
				null,
				fiscalYear.id,
				draft
			);
			command.execute(() => {
				Signals.ShowMessageDialog.dispatch(
					<FormattedHTMLMessage id="transaction.expense.draft.added.message" />
				);
				onSave();
			});
		}
	};

	// Close the overlay when the user clicks outside of the overlay
	// Cancel debounced search on unmount
	// Reset filters on unmount
	useEffect(() => {
		if (fiscalYear) {
			setBankRecordMatchStore(
				new BankRecordMatchStore(bankRecord, incomeStore, expenseStore, bankRecordStore, fiscalYear)
			);
		}
		Signals.OverlayBackgroundClick.add(hideOverlay);
		return () => {
			// Reset filters on close
			Signals.OverlayBackgroundClick.remove(hideOverlay);
			cancel();
			resetStore();
		};
	}, [fiscalYear]);

	return (
		<Observer>
			{() => {
				if (!bankRecordMatchStore || !bankRecordMatchStore.bankRecordInEdit) return null;
				return (
					<OverlayPanel className="lg:min-w-[1000px] md:max-w-[768px] flex flex-col md:max-h-[80vh]">
						<PanelHeader className="shadow-md">
							<h1>
								<FormattedMessageWithClass id="transaction.match.header" />
							</h1>
							<div
								className="overlay-panel__close icon icon icon--close-black"
								onClick={() => {
									Signals.HideToolTip.dispatch();
									Signals.HideOverlay.dispatch();
									resetStore();
								}}
							/>
						</PanelHeader>
						<div className="flex flex-col ">
							<div className="px-7 py-5 flex flex-row items-center justify-between border-dashed border-0 border-b-2 border-zinc-100 space-x-2">
								<div className="relative flex justify-center items-center w-7 h-7 min-w-[1.75rem] bg-zinc-200 rounded-full text-gray-500 -z-0">
									<FiList />
								</div>
								<FormattedDate
									className="min-w-fit"
									value={bankRecordMatchStore.bankRecordInEdit.executionDate}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...defaultDateFormatOptions}
								/>
								<span className="max-w-[200px] truncate">
									{bankRecordMatchStore.bankRecordInEdit.counterpartName}
								</span>
								<span className="hidden min-w-[50px] md:flex max-w-[200px] truncate whitespace-nowrap">
									{bankRecordMatchStore.bankRecordInEdit.description}
								</span>
								<div className="hidden min-w-[200px] max-width-[340px] md:flex lg:min-w-[300px] whitespace-nowrap">
									<Category
										categoryId={bankRecordMatchStore.bankRecordInEdit.categoryId}
										onCategorySelect={(category) => {
											// eslint-disable-next-line no-unused-expressions
											category
												? (bankRecordMatchStore.bankRecordInEdit.categoryId = category.value)
												: (bankRecordMatchStore.bankRecordInEdit.categoryId = null);
										}}
										disabled={
											bankRecordMatchStore.bankRecordInEditMatchStatus ===
											BankRecordMatchType.MATCHED
										}
										bankRecordMatchStore={bankRecordMatchStore}
									/>
								</div>
								<FormattedNumberParts
									value={bankRecordMatchStore.bankRecordInEdit.amount}
									minimumFractionDigits={2}
									maximumFractionDigits={2}>
									{(parts) => <span>{amountWithSignFromParts(bankRecord.amount, parts)}</span>}
								</FormattedNumberParts>
							</div>
							{bankRecordMatchStore.bankRecordMatchedResourceInEdit && (
								<div className="px-7 py-5 flex flex-row items-center justify-between bg-zinc-100 space-x-2">
									<div className="relative flex justify-center items-center w-7 h-7 min-w-[1.75rem] bg-zinc-200 rounded-full text-gray-500 -z-0">
										<IoDocumentTextOutline />
									</div>
									<FormattedDate
										className="min-w-fit"
										value={bankRecordMatchStore.bankRecordMatchedResourceInEdit.date}
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...defaultDateFormatOptions}
									/>
									<span>{bankRecordMatchStore.bankRecordMatchedResourceInEdit.customerName}</span>
									<span className="hidden min-w-[50px] md:flex">
										{bankRecordMatchStore.bankRecordMatchedResourceInEdit.description}
									</span>
									<FormattedNumber
										// eslint-disable-next-line react/style-prop-object
										style="currency"
										currency="EUR"
										value={getTotalAmountInclBTW(
											bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.INCOME
												? bankRecordMatchStore.bankRecordMatchedResourceInEdit.invoiceRows
												: bankRecordMatchStore.bankRecordMatchedResourceInEdit.expenseRows
										)}
										minimumFractionDigits={2}
										maximumFractionDigits={2}
									/>
									<span
										className="w-[40px] h-[40px] icon icon--delete-black hover:cursor-pointer opacity-50 hover:opacity-100"
										onClick={handleUnMatchEditResouce}
									/>
								</div>
							)}
						</div>
						<div className="p-7">
							{bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.INCOME ? (
								<FormattedMessageWithClass
									className="uppercase font-semibold text-xs"
									id="transaction.match.search.income"
								/>
							) : (
								<FormattedMessageWithClass
									className="uppercase font-semibold text-xs"
									id="transaction.match.search.expense"
								/>
							)}
							<SearchField
								name="search"
								focusColorChange={false}
								className="search-field--transaction-match"
								onChange={(e) => {
									if (e && e.target) {
										setSearch(e.target.value);
									} else {
										setSearch('');
									}
								}}
								placeholder={
									bankRecordMatchStore.bankRecordInEdit.type === BankRecordType.INCOME
										? intl.formatMessage({ id: 'income.search' })
										: intl.formatMessage({ id: 'expenses.search' })
								}
								value={search}
							/>
						</div>
						{fiscalYear && bankRecordMatchStore && (
							<IncomeExpenseTable
								fiscalYear={fiscalYear}
								search={debouncedSearch}
								bankRecordMatchStore={bankRecordMatchStore}
							/>
						)}

						<footer className="match-panel-footer">
							<div className="flex justify-end p-4 gap-2">
								<button
									type="button"
									className="icon icon--left icon--big-plus icon--color button"
									disabled={
										bankRecordMatchStore.bankRecordInEditMatchStatus === BankRecordMatchType.MATCHED
									}
									onClick={handleCreateConcept}>
									{bankRecord.type === BankRecordType.INCOME
										? intl.formatMessage({ id: 'transaction.match.create.income.concept' })
										: intl.formatMessage({ id: 'transaction.match.create.expense.concept' })}
								</button>
								<button
									type="button"
									className="button button--primary"
									onClick={onSave}
									disabled={!bankRecordMatchStore.isEditted}>
									<FormattedMessageWithClass id="transaction.match.button" />
								</button>
							</div>
						</footer>
					</OverlayPanel>
				);
			}}
		</Observer>
	);
}

TransactionMatch.propTypes = {
	bankRecord: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired
};

export default injectIntl(TransactionMatch);
