import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DocumentIFrame from '../../components/ui/DocumentIFrame/DocumentIFrame';
import FormattedMessageWithClass from '../../components/ui/FormattedMessageWithClass/FormattedMessageWithClass';
import GetPolicyTermsCommand from '../../commands/policyTerms/GetPolicyTermsCommand';
import useApplicationStore from '../../utils/useApplicationStore';
import Signals from '../../signals/Signals';
import { Routes } from '../../data/Routes';

function PolicyTerms({ intl }) {
	const [accepted, setAccepted] = useState(false);
	const [policyTerms, setPolicyTerms] = useState('');
	const applicationStore = useApplicationStore();
	const command = new GetPolicyTermsCommand();
	const checks = [
		{ title: intl.formatMessage({ id: 'terms.policy.accept.privacy' }) },
		{ title: intl.formatMessage({ id: 'terms.policy.accept.terms' }) }
	];

	useEffect(() => {
		command.execute().then((response) => {
			if (response && response.policyTerms) {
				setPolicyTerms(response.policyTerms);
			}
		});
	}, []);

	useEffect(() => {
		if (applicationStore.user && applicationStore.user.acceptedPrivacyTerms) {
			Signals.RequestRoute.dispatch(Routes.COMPANY_DASHBOARD.pathName);
		}
	}, [applicationStore.user]);

	return (
		<div className="terms-page">
			<div className="terms-container">
				<div className="kdb-logo terms-logo" />
				<FormattedMessageWithClass
					className="mt-14 font-semibold capitalize text-lg"
					id="terms.policy.header"
				/>
				<FormattedMessageWithClass className="mt-9 mb-5" id="terms.policy.description" />
				<DocumentIFrame
					text={policyTerms}
					checks={checks}
					onCheck={(status) => setAccepted(status)}
				/>
				<div className="terms-actions">
					<button type="button" onClick={() => applicationStore.logout()}>
						<FormattedMessage id="terms.policy.logout" />
					</button>
					<button
						type="button"
						className="button button--primary"
						disabled={!accepted}
						onClick={() => applicationStore.acceptPolicyTerms()}>
						<FormattedMessage id="terms.policy.accept" />
					</button>
				</div>
				<FormattedMessageWithClass className="mt-9 mb-5" id="terms.policy.contact" />
			</div>
		</div>
	);
}

PolicyTerms.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(PolicyTerms);
