import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { observer } from 'mobx-react';
import { defaultDateFormatOptions } from '../../../../../data/Settings';
import { getTotalAmountInclBTW } from '../../../../../utils/btwUtils';
import { BankRecordType } from '../../../../../data/BankRecordType';
import Tag from '../../../../../components/ui/Tag/Tag';

const TableItem = observer(({ item, type, fiscalYear, bankRecordMatchStore }) => {
	const handleSelect = () => {
		bankRecordMatchStore.setBankRecordMatchedResourceInEdit(item);
		bankRecordMatchStore.setIsEditted(true);
	};
	const state = item.state ? item.state.toLowerCase() : 'default';
	return (
		<div
			className="table-item py-5 flex flex-row items-center justify-between border-solid border-0 border-b-2 border-zinc-100 hover:cursor-pointer"
			onClick={handleSelect}>
			<div className="pl-[24px] basis-1/12 hidden md:flex">
				<div className="flex justify-center items-center w-7 h-7 max-w-[2rem] bg-zinc-200 rounded-full text-gray-500 -z-0">
					<IoDocumentTextOutline />
				</div>
			</div>
			<div className="sm:basis-2/12 px-[12px] max-w-[90px] flex-1 hover-color">
				<FormattedDate
					className="min-w-fit max-w-fit"
					value={item.date}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...defaultDateFormatOptions}
				/>
			</div>
			<div className="md:basis-3/12 px-[12px] hidden sm:block sm:flex-1 truncate hover-color">
				{item.customerName}
			</div>
			<div className="basis-3/12 px-[12px] hidden md:block truncate hover-color">
				{item.description}
			</div>
			<div className="sm:basis-2/12 px-[12px] min-w-[80px] flex-1 box-border hover-color">
				<FormattedNumber
					// eslint-disable-next-line react/style-prop-object
					style="currency"
					currency="EUR"
					value={getTotalAmountInclBTW(
						type === BankRecordType.INCOME ? item.invoiceRows : item.expenseRows
					)}
					minimumFractionDigits={2}
					maximumFractionDigits={2}
				/>
			</div>
			<div className="sm:basis-1/12 px-[12px] pr-[24px] ">
				<Tag labelId={`tag-label.${state}`} type={state} values={{ year: fiscalYear.year }} />
			</div>
		</div>
	);
});

TableItem.propTypes = {
	item: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	fiscalYear: PropTypes.object.isRequired,
	bankRecordMatchStore: PropTypes.object.isRequired
};

export default TableItem;
