/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Routes } from '../../../data/Routes';
import Signals from '../../../signals/Signals';
import { getStateValue } from '../../../utils/ReactUtils';

import Page from '../../Page';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';

import userUpdate from '../../../requests/userUpdate';
import { ApplicationContext } from '../../../ApplicationContext';
import PasswordValidator from '../../../components/ui/PasswordValidator/PasswordValidator';

/**
 *
 */
class UserChangePassword extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errors: null,
			submitting: false
		};

		this.onInputChange = this.onInputChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
		this.onSubmitError = this.onSubmitError.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const formClasses = classNames({ 'form--submitting': this.state.submitting });
		const { password, confirmPassword } = this.state;

		return (
			<Page pageName="user-change-password">
				<div className="user-info__panel panel border border--dark">
					<div className="user-info__header padding border--bottom border--dark">
						<h2 className="pad-left">
							<FormattedMessage id="user.changepassword.title" />
						</h2>
					</div>

					<form className={`user-info__content ${formClasses}`} onSubmit={this.onSubmit}>
						<FormGroup className="grid padding" errors={this.state.errors}>
							<FormField className="col--12">
								<label>
									<FormattedMessage id="user.changepassword.field.newpassword" />
								</label>
								<input
									name="password"
									type="password"
									required
									autoComplete="new-password"
									value={getStateValue(password, undefined)}
									onChange={this.onInputChange}
								/>
								<PasswordValidator value={password} />
							</FormField>

							<FormField className="col--12">
								<label>
									<FormattedMessage id="user.changepassword.field.confirmpassword" />
								</label>
								<input
									name="confirmPassword"
									type="password"
									required
									autoComplete="new-password"
									value={getStateValue(confirmPassword, undefined)}
									onChange={this.onInputChange}
								/>
							</FormField>
						</FormGroup>

						<div className="options-footer border--top border--dark grid grid--spread">
							<div className="grid" />
							<button className="button--primary" type="submit">
								<FormattedMessage id="label.save" />
							</button>
						</div>
					</form>
				</div>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		this.state[e.target.name] = e.target.value;
		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		const data = {};
		for (const key in this.state) {
			if (this.state.hasOwnProperty(key)) {
				switch (key) {
					case 'city':
					case 'address':
					case 'postalCode':
						if (!data.address) {
							data.address = {};
						}
						data.address[key] = this.state[key];
						break;
					case 'errors':
					case 'submitting':
						// do nothing
						break;
					default:
						data[key] = this.state[key];
				}
			}
		}

		this.setState({ errors: null, submitting: true });
		userUpdate(data).then(this.onSubmitSuccess).catch(this.onSubmitError);
	}

	/**
	 *
	 * @param response
	 */
	onSubmitSuccess(response) {
		// Update user object
		this.context.applicationStore.user.update(response.data);

		// Show success message
		Signals.ShowMessageDialog.dispatch(
			<div>
				<FormattedMessage id="user.changepassword.success.body" />
				<Link to={Routes.USER_SETTINGS_INFO.pathName}>
					<FormattedMessage id="user.changepassword.backtoprofile" />
				</Link>
			</div>
		);

		// Update state
		this.setState({ submitting: false });
	}

	/**
	 *
	 * @param error
	 */
	onSubmitError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}
}

UserChangePassword.contextType = ApplicationContext;

UserChangePassword.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	intl: PropTypes.object
};

export default injectIntl(UserChangePassword);
