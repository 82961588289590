import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

/**
 * @param documentId
 * @return {Promise}
 */
export default (documentId) => {
	return fetch(
		Methods.DELETE,
		EndPoints.DOCUMENT_USER.replace(':documentId', documentId),
		null,
		null,
		true
	);
};
