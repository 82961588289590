import { observable } from 'mobx';
import { buildAssetURL } from '../../requests/companyAssetGet';

export default class BookKeeper {
	@observable id = -1;

	@observable firstName = '';

	@observable lastName = '';

	@observable note = '';

	@observable email;

	@observable phoneNr;

	@observable portraitAssetUuid;

	getImageURL() {
		if (this.portraitAssetUuid) {
			return buildAssetURL(this.portraitAssetUuid);
		}
		return `${window.config.imageRoot}KdB_System_icon.svg`;
	}

	getFullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	toString() {
		return `BookKeeper: ${this.id}, ${this.firstName}, ${this.lastName}`;
	}

	static parseBookKeeper(data) {
		const bookKeeper = new BookKeeper();

		if (!data) {
			return bookKeeper;
		}

		bookKeeper.id = data.id;
		bookKeeper.firstName = data.firstName;
		bookKeeper.lastName = data.lastName;
		bookKeeper.note = data.note;
		bookKeeper.email = data.email;
		bookKeeper.phoneNr = data.phoneNr;
		bookKeeper.portraitAssetUuid = data.portraitAssetUuid;

		return bookKeeper;
	}
}
