import AbstractCommand from '../AbstractCommand';
import PeriodicInvoiceConceptModel from '../../stores/models/PeriodicInvoiceConceptModel';
import periodicInvoiceConceptGet from '../../requests/periodicInvoiceConcepts/periodicInvoiceConceptGet';

/**
 *
 */
export default class GetPeriodicInvoiceConceptCommand extends AbstractCommand {
	/**
	 *
	 * @param periodicInvoiceConceptModel PeriodicInvoiceConceptModel
	 * @param company Company
	 */
	constructor(periodicInvoiceConceptModel, company) {
		super();

		this.periodicInvoiceConceptModel = periodicInvoiceConceptModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return periodicInvoiceConceptGet(this.company.id, this.periodicInvoiceConceptModel.id)
			.then((response) => {
				PeriodicInvoiceConceptModel.updatePeriodicInvoiceConcept(
					response.data,
					this.periodicInvoiceConceptModel
				);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				if (error) {
					error(err);
				}
			});
	}
}
