import { observable, action } from 'mobx';

export default class BankbookAnimationStore {
	@observable currentStep = 1;

	@observable isAnimating = false;

	@observable isLoading = false;

	@observable animationInterval = null;

	@observable timeoutInterval = null;

	@observable accountSwitchInterval = null;

	showInsightsTimeout = null;

	@observable showInsights = false;

	duration = 3000;

	delayAfterAnimation = 5000;

	constructor() {
		this.reset();
	}

	@action setCurrentStep(currentStep) {
		this.currentStep = currentStep;
	}

	@action incrementCurrentStep() {
		this.currentStep++;
	}

	@action startAnimation() {
		this.resetTimers();
		this.startLoading();
		this.setCurrentStep(1);
		this.isAnimating = true;
		this.showInsights = true;
	}

	@action stopAnimation() {
		this.isAnimating = false;
		this.stopLoading();
		this.resetAnimationInterval();
		this.resetAccountSwitchInterval();
		this.showInsightsTimeout = setTimeout(() => {
			this.showInsights = false;
		}, this.delayAfterAnimation);
	}

	@action stopAnimationImmediately() {
		this.isAnimating = false;
		this.stopLoading();
		this.resetTimers();
		this.showInsights = false;
	}

	@action startLoading() {
		this.isLoading = true;
	}

	@action stopLoading() {
		this.isLoading = false;
	}

	@action setDuration(duration) {
		this.duration = duration;
	}

	@action setTimeoutInterval(timeoutInterval) {
		this.timeoutInterval = timeoutInterval;
	}

	@action setAnimationInterval(animationInterval) {
		this.animationInterval = animationInterval;
	}

	@action setAccountSwitchInterval(accountSwitchInterval) {
		this.accountSwitchInterval = accountSwitchInterval;
	}

	@action resetAnimationInterval() {
		clearInterval(this.animationInterval);
		this.animationInterval = null;
	}

	@action resetTimeout() {
		clearTimeout(this.timeoutInterval);
		this.timeoutInterval = null;
	}

	@action resetAccountSwitchInterval() {
		clearInterval(this.accountSwitchInterval);
		this.accountSwitchInterval = null;
	}

	@action resetTimers() {
		this.resetAnimationInterval();
		this.resetTimeout();
		this.resetAccountSwitchInterval();
		clearTimeout(this.showInsightsTimeout);
	}

	@action reset() {
		this.stopAnimation();
		this.resetTimers();
		this.setCurrentStep(1);
	}
}
