import AbstractCommand from '../AbstractCommand';
import companyLatestInvoiceNrs from '../../requests/companyLatestInvoiceNrs';
import Signals from '../../signals/Signals';

/**
 *
 */
export default class GetLatestInvoiceNumberCommand extends AbstractCommand {
	/**
	 * @param companyLatestInvoiceNumberStore CompanyLatestInvoiceNumberStore
	 * @param company Company
	 */
	constructor(companyLatestInvoiceNumberStore, company) {
		super();

		this.companyLatestInvoiceNumberStore = companyLatestInvoiceNumberStore;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		companyLatestInvoiceNrs(this.company.id)
			.then((response) => {
				this.companyLatestInvoiceNumberStore.update(response);

				if (success) {
					success(response);
				}
			})
			.catch((err) => {
				Signals.Error.dispatch(err);

				if (error) {
					error(err);
				}
			});
	}
}
