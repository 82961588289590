import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Routes, RouteUtil } from '../data/Routes';
import Signals from '../signals/Signals';

import ScrollController from './ScrollController';
import TrackingController from './TrackingController';
import CookieController, { COOKIE_TYPES } from './CookieController';
import PropertiesController from './PropertiesController';
import { ApplicationContext } from '../ApplicationContext';
import PropertyType from '../data/PropertyType';

/**
 *
 */
class RoutingController extends Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		console.log('RoutingController.constructor');

		this.onRequestRoute = this.onRequestRoute.bind(this);
		this.onLoggedIn = this.onLoggedIn.bind(this);
	}

	/**
	 *
	 */
	componentDidMount() {
		console.log('RoutingController.componentDidMount');

		// Store initial path/route for deeplink purposes
		const initialPathName = `${this.props.history.location.pathname}`;

		// Always strip off applicationContext
		this.context.applicationStore.deeplinkSearch = `${this.props.history.location.search}`;
		this.context.applicationStore.deeplinkHash = `${this.props.history.location.hash}`;
		this.context.applicationStore.deeplinkPath =
			window.config.applicationContext && window.config.applicationContext.length > 0
				? initialPathName.replace(`/${window.config.applicationContext}`, '')
				: initialPathName;
		this.context.applicationStore.deeplinkRoute = RouteUtil.getRoute(
			this.context.applicationStore.deeplinkPath
		);
		if (this.context.applicationStore.deeplinkSearch) {
			this.context.applicationStore.deeplinkRoute.urlSearchParams = new URLSearchParams(
				this.context.applicationStore.deeplinkSearch
			);
		}
		console.log('\thref', this.props.history.location);
		console.log('\tinitialPathName', initialPathName);
		console.log('\tdeeplinkPath', this.context.applicationStore.deeplinkPath);
		console.log('\tdeeplinkSearch', this.context.applicationStore.deeplinkSearch);
		console.log('\tdeeplinkHash', this.context.applicationStore.deeplinkHash);
		console.log('\tdeeplinkRoute', this.context.applicationStore.deeplinkRoute);

		this.checkRoute();

		if (
			this.context.applicationStore.deeplinkRoute &&
			this.context.applicationStore.deeplinkRoute.isPublic
		) {
			console.log('\tpublic accessible url, ignore logged in state');

			// Strip context
			this.props.history.push(this.context.applicationStore.deeplinkPath);
		} else {
			Signals.RequestRoute.add(this.onRequestRoute);
			Signals.LoggedIn.add(this.onLoggedIn);
		}
	}

	/**
	 *
	 * @param prevProps
	 */
	componentWillUpdate(_prevProps) {
		console.log('RoutingController.componentWillUpdate');
		this.checkRoute();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		console.log('RoutingController.componentWillUnmount');

		Signals.RequestRoute.remove(this.onRequestRoute);
		Signals.LoggedIn.remove(this.onLoggedIn);
	}

	/**
	 *
	 * @returns {null}
	 */
	render() {
		return null;
	}

	/**
	 *
	 * @param route
	 */
	onRequestRoute(route) {
		console.log('RoutingController.onRequestRoute', route);
		this.props.history.push(route);
	}

	/**
	 *
	 */
	onLoggedIn() {
		console.log('RoutingController.onLoggedIn', this.context.applicationStore.isLoggedIn);

		if (this.context.applicationStore.isLoggedIn) {
			console.log('\tisLoggedIn', this.context.applicationStore.isLoggedIn);
			let redirect = Routes.USER_DASHBOARD.getPath({}); // default fallback

			// Check if on-boarding process needs to be shown after login
			// Onboarding trumps any deeplink, any deeplink will be handled by the OnBoarding.jsx
			const skipOnBoarding =
				!!CookieController.getCookie(COOKIE_TYPES.SKIP_ON_BOARDING_COOKIE_ID) ||
				PropertiesController.getProperty(PropertiesController.FEATURE_DEMO);
			console.log('\tskipOnBoarding', skipOnBoarding);

			if (
				!this.context.applicationStore.user.acceptedPrivacyTerms &&
				!PropertiesController.getProperty(PropertiesController.IS_DEMO_ENV) &&
				PropertiesController.getProperty(PropertiesController.TERMS_AND_CONDITIONS)
			) {
				redirect = Routes.POLICY_TERMS.pathName;
			} else if (!skipOnBoarding && this.context.applicationStore.user.requiresOnBoarding()) {
				redirect = Routes.ON_BOARDING.pathName;

				// Check if we have a deeplink or simply go to the dashboard of the first company
			} else {
				const company = this.context.applicationStore.getSelectedCompany(true);
				const deeplink = this.context.applicationStore.getDeeplink(true);

				if (!deeplink) {
					console.log('\tno deeplink');
					redirect = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
				} else {
					console.log('\tdeeplink ', deeplink.split('#')[0]);
					const deeplinkRoute = deeplink ? RouteUtil.getRoute(deeplink.split('#')[0]) : null; // Strip off hash tag
					console.log('\tdeeplinkRoute', deeplinkRoute);
					console.log('\tignoreDeeplink', deeplinkRoute && !deeplinkRoute.ignoreDeeplink);

					// Use deeplink if not LOGIN, FORGOT_PASSWORD or RESET_PASSWORD
					if (deeplinkRoute && !deeplinkRoute.ignoreDeeplink) {
						if (this.isRestrictedRoute(deeplinkRoute, company)) {
							console.log('\trestricted deeplink, feature not enabled');
							redirect = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
						} else {
							redirect = deeplink;
							if (deeplinkRoute.urlSearchParams) {
								redirect += `?${deeplinkRoute.urlSearchParams.toString()}`;
							}
						}
						// Go to default page
					} else {
						redirect = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
					}
				}
			}

			console.log('\tredirect', redirect);
			Signals.RequestRoute.dispatch(redirect);
		}
	}

	/**
	 * If route isResticted and feature is not enabled, return false
	 * @param {*} route
	 * @param {*} company
	 * @returns
	 */
	isRestrictedRoute(route, company) {
		if (!route.isRestricted) return false;
		if (route.feature) {
			if (route.feature.type === PropertyType.COMPANY && company) {
				return !company.isFeatureEnabled(route.feature.value);
			}
			if (route.feature.type === PropertyType.APPLICATION) {
				return !PropertiesController.getProperty(route.feature.value);
			}
			return false;
		}
		return false;
	}

	/**
	 *
	 */
	checkRoute() {
		console.log('RoutingController.checkRoute');

		const history = this.props.history;

		// Always strip off applicationContext
		const containsContext = window.config.useApplicationContext
			? history.location.pathname.indexOf(`/${window.config.applicationContext}`, '') >= 0
			: false;
		const currentPath = window.config.useApplicationContext
			? history.location.pathname.replace(`/${window.config.applicationContext}`, '')
			: history.location.pathname;
		const currentRoute = RouteUtil.getRoute(currentPath);
		let redirectRoute;
		let company;
		let financialYear = null;

		console.log('\tcurrentPath', currentPath);
		console.log('\tcurrentRoute', currentRoute);
		console.log('\tisLoggedIn', this.context.applicationStore.isLoggedIn);

		// Just updateState if currentRoute is public, ignore deeplinking and other checks
		if (currentRoute && currentRoute.isPublic) {
			this.updateState(currentRoute, currentRoute.getVariables(currentPath));
			return;
		}

		// Check if default path, or if Route does not exist
		if (currentRoute === Routes.HOME || currentRoute == null) {
			console.log('\t=home', currentRoute);

			if (!this.context.applicationStore.isLoggedIn) {
				redirectRoute = Routes.LOGIN.pathName;
			} else {
				// Check if user/company needs to go through on boarding steps
				company = this.context.applicationStore.getSelectedCompany(true);
				if (company) {
					redirectRoute = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
				}
			}

			// If route found and authentication is required, check if the user is logged in, otherwise redirect to Login screen
		} else if (!this.context.applicationStore.isLoggedIn && currentRoute.requiresAuth) {
			redirectRoute = Routes.LOGIN.getPath({});
		}

		// If logged in and using current route, check if any company and financial year exist, otherwise route to default
		if (
			!redirectRoute &&
			currentRoute &&
			this.context.applicationStore.isLoggedIn &&
			this.context.applicationStore.user
		) {
			const params = currentRoute.getVariables(currentPath);
			console.log('\t=params', params);
			let validRoute = true;

			// does company exist with id `id`?
			if (params.hasOwnProperty('id')) {
				company = this.context.applicationStore.user.getCompanyById(parseInt(params.id, 10));
				validRoute = !!company;

				// does financial year exist with id `year`?
				if (company && params.hasOwnProperty('year')) {
					financialYear = company.getFinancialYearById(parseInt(params.year, 10));
					validRoute = !!financialYear;
				}
			}

			// Direct to dashboard of first available Company
			console.log('\t=validRoute', validRoute);
			if (!validRoute) {
				company = this.context.applicationStore.getSelectedCompany(true);
				if (company) {
					redirectRoute = Routes.COMPANY_DASHBOARD.getPath({ id: company.id });
				}
			}
		}

		// Check if we need to redirect
		if (redirectRoute) {
			console.log('\t=redirectRoute', redirectRoute);

			this.props.history.push(redirectRoute);

			// Context has been stripped out, force update/redirect
		} else if (containsContext) {
			console.log('\t=containsContext', currentPath);

			history.replace(currentPath);

			this.updateState(currentRoute, currentRoute.getVariables(currentPath));

			// Track generic path pageview, use history.location.pathname if specific company ids are necessary
			TrackingController.trackPageView(currentRoute.pathName);
			Signals.RouteChanged.dispatch(currentRoute.pathName);

			// Proceed as normal and update the states accordingly
		} else {
			console.log('\t=update state for', currentRoute);

			if (this.isRestrictedRoute(currentRoute, company)) {
				console.log('\t=restricted route', currentRoute);
				company = this.context.applicationStore.getSelectedCompany(true);
				if (company) {
					Signals.RouteChanged.dispatch(Routes.COMPANY_DASHBOARD.getPath({ id: company.id }));
				}
			}
			// Update the application state according to url parameters
			this.updateState(currentRoute, currentRoute.getVariables(currentPath));

			// Track generic path pageview, use history.location.pathname if specific company ids are necessary
			TrackingController.trackPageView(currentRoute.pathName);
			Signals.RouteChanged.dispatch(currentRoute.pathName);
		}

		// Always scroll page to top after url change
		ScrollController.scrollPageTo();
	}

	/**
	 * Updates applicationStore settings
	 *
	 * @param route
	 * @param variables
	 */
	updateState(route, variables) {
		console.log('RoutingController.updateState', route, variables);

		const path = route.getPath(variables);
		const isCompanyPath = RouteUtil.isCompanyPath(path);

		this.context.applicationStore.currentRoute = route;
		this.context.applicationStore.currentRouteParams = variables;
		this.context.applicationStore.currentRoutePath = path;

		if (isCompanyPath) {
			// Try to update selected company
			if (variables.id) {
				const companyId = parseInt(variables.id, 10);
				this.context.applicationStore.setSelectedCompany(companyId);
			}

			// Try to update selected financial year
			if (variables.year) {
				const financialYearId = parseInt(variables.year, 10);
				this.context.applicationStore.setSelectedFinancialYear(financialYearId);
			}
		}
	}
}

RoutingController.propTypes = {
	history: PropTypes.object,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(RoutingController);

RoutingController.contextType = ApplicationContext;
