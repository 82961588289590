import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BlogEntry from './BlogEntry';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
@observer
export default class BlogFeed extends React.Component {
	/**
	 *
	 * @param props
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	/**
	 *
	 */
	componentWillMount() {
		const { applicationStore } = this.context;
		if (applicationStore.user) {
			this.props.blogStore.fetch(applicationStore.user.language);
		} else {
			this.props.blogStore.fetch();
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const entries = this.props.blogStore.entries.map((entryData) => {
			return (
				<BlogEntry
					className="col--6"
					key={entryData.link}
					link={entryData.link}
					title={entryData.title}
					date={entryData.date}
					description={entryData.description}
					image={entryData.image}
				/>
			);
		});

		return <div className="blog-feed grid border--top border--dark">{entries}</div>;
	}
}

BlogFeed.contextType = ApplicationContext;

BlogFeed.propTypes = {
	blogStore: PropTypes.object.isRequired
};
