import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import Signals from '../../signals/Signals';

import AbstractCommand from '../AbstractCommand';
import companyDraftDelete from '../../requests/drafts/companyDraftDelete';

/**
 *
 */
export default class DeleteCompanyDraftCommand extends AbstractCommand {

	/**
	 *
	 * @param draftsStore DraftsStore
	 * @param companyId Number
	 * @param draft Draft
	 * @param suppressMessage bool
	 */
	constructor(draftsStore, companyId, draft, suppressMessage = false) {
		super();

		this.draftsStore = draftsStore;
		this.companyId = companyId;
		this.draft = draft;
		this.suppressMessage = suppressMessage;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return companyDraftDelete(this.companyId, this.draft.id).then((response) => {

			this.draftsStore.remove(this.draft);

			// Show message
			if(!this.suppressMessage) {
				Signals.ShowMessageDialog.dispatch(<FormattedHTMLMessage id="draft.deleted.message"
																		 values={{id: this.draft.id}}/>);
			}

			// Signal that Draft is deleted
			Signals.DraftDeleted.dispatch(this.draft);

			if(success) {
				success(response);
			}

		}).catch(err => {
			if(error) {
				error(err)
			}
		});
	}
}
