export default class FilterButtonOption {
	/**
	 *
	 * @param label
	 * @param value
	 * @param classes
	 * @param checked
	 * @param hidden
	 */
	constructor(label, value, checked = true, hidden = false, classes = '') {
		this.label = label;
		this.value = value;
		this.classes = classes || '';
		this.checked = checked;
		this.hidden = hidden;
	}
}
