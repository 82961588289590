import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (companyId) => {
	return fetch(
		Methods.GET,
		EndPoints.AUTHORIZATIONS_COMPANY.replace(':companyId', companyId),
		{},
		null,
		true,
		true,
		false
	);
};
