import { observable, computed } from 'mobx';
import Model from './Model';

export default class BankCategoryOption extends Model {
	/**
	 * @type {string}
	 */
	@observable value;

	/**
	 * @type {string}
	 */
	@observable label;

	/**
	 *
	 * @param {Array<BankCategoryOption>} options
	 */
	@observable options = [];

	@observable parent = null;

	/**
	 *
	 * @param {bool} hidden
	 */
	@observable hidden;

	/**
	 *
	 * @param data
	 */
	constructor(data = null) {
		super(data);
	}

	static createFromBankRecordCategory(bankRecordCategory, parent = null) {
		const option = new BankCategoryOption();
		option.value = bankRecordCategory.id;
		option.label = bankRecordCategory.label;
		option.hidden = bankRecordCategory.hidden ? bankRecordCategory.hidden : false;
		option.parent = parent;
		option.options = bankRecordCategory.children.map((child) =>
			BankCategoryOption.createFromBankRecordCategory(child, option)
		);
		return option;
	}

	@computed get isExpandable() {
		return this.options.length !== 0;
	}

	@computed get isHidden() {
		return this.hidden === true;
	}
}
