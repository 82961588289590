import fetch from './fetch';
import EndPoints from '../data/EndPoints';
import Methods from '../data/Methods';

export default (companyId, profileId) => {
	return fetch(
		Methods.GET,
		EndPoints.COMMUNITY_COMPANY_PROFILE.replace(':id', companyId).replace(':profileId', profileId),
		null,
		null,
		true
	);
};
