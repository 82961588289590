/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { ApplicationContext } from '../../../ApplicationContext';

import OfferModel from '../../../stores/models/OfferModel';
import SignOfferModel from '../../../stores/models/SignOfferModel';
import Company from '../../../stores/models/Company';

import FormGroup from '../FormGroup/FormGroup';
import FormField from '../FormField/FormField';
import Signature from '../Signature/Signature';
import Signals from '../../../signals/Signals';
import ModalConfirm from '../Modal/ModalConfirm';
import ModalAlert from '../Modal/ModalAlert';

import SignOfferCommand from '../../../commands/offers/SignOfferCommand';
import DeclineOfferCommand from '../../../commands/offers/DeclineOfferCommand';

/**
 *
 */
@observer
class AcceptOfferForm extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onDecline = this.onDecline.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onResetSignature = this.onResetSignature.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		return (
			<div className="accept-offer-form offer-accept-form__panel panel grid col--12 box-shadow border border--dark">
				<div className="offer-accept-form__header padding grid grid--spread">
					<h2>
						<FormattedMessage id="public.offer.accept.form.title" />
					</h2>
				</div>

				<form onChange={this.onInputChange} onSubmit={this.onSubmit}>
					<div className="padding">
						<FormGroup className="grid col--8" errors={this.state.errors}>
							<FormField className="col--6">
								<label>
									<FormattedMessage id="public.offer.accept.fullname" />
								</label>
								<input
									name="fullName"
									type="text"
									required
									maxLength="255"
									value={this.props.signOfferModel.fullName}
								/>
							</FormField>

							<FormField className="col--6">
								<label>
									<FormattedMessage id="public.offer.accept.companyname" />
								</label>
								<input
									name="companyName"
									type="text"
									maxLength="255"
									value={this.props.signOfferModel.companyName}
								/>
							</FormField>

							<FormField className="col--6">
								<label>
									<FormattedMessage id="public.offer.accept.email" />
								</label>
								<input
									name="email"
									type="email"
									required
									maxLength="255"
									value={this.props.signOfferModel.email}
								/>
							</FormField>

							<FormField className="col--12">
								<label>
									<FormattedMessage id="public.offer.accept.signature" />
								</label>
								<Signature className="col--12" signOfferModel={this.props.signOfferModel} />
							</FormField>
							<FormField className="col--6">
								<div className="link" onClick={this.onResetSignature}>
									<FormattedMessage id="label.reset" />
								</div>
							</FormField>
						</FormGroup>
					</div>

					<div className="options-footer border--top border--dark grid grid--spread col--12">
						<div>
							<button type="button" className="button button--tertiary" onClick={this.onDecline}>
								{this.props.intl.formatMessage({ id: 'label.decline' })}
							</button>
						</div>

						<div className="accept-offer-form__options grid">
							<input
								className=""
								type="submit"
								value={this.props.intl.formatMessage({ id: 'label.sign' })}
							/>
						</div>
					</div>
				</form>
			</div>
		);
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		this.props.signOfferModel[e.target.name] = e.target.value;
	}

	/**
	 *
	 * @param e
	 */
	onResetSignature() {
		this.props.signOfferModel.signaturePadReference.clear();
	}

	/**
	 *
	 * @param e
	 */
	onDecline() {
		Signals.ShowModal.dispatch(
			<ModalConfirm
				title={this.props.intl.formatMessage({ id: 'offer.confirm.decline.title' })}
				yesLabel={this.props.intl.formatMessage({ id: 'label.yes' })}
				noLabel={this.props.intl.formatMessage({ id: 'label.no' })}
				// eslint-disable-next-line react/jsx-no-bind
				onConfirm={this.doDecline.bind(this)}
			/>
		);
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		e.preventDefault();

		if (this.props.signOfferModel.isValid()) {
			Signals.ShowModal.dispatch(
				<ModalConfirm
					title={this.props.intl.formatMessage(
						{ id: 'offer.confirm.sign.title' },
						{ offerNr: this.props.offerModel.offerNr }
					)}
					body={this.props.intl.formatMessage({ id: 'offer.confirm.sign.body' })}
					yesLabel={this.props.intl.formatMessage({ id: 'offer.confirm.sign.accept' })}
					noLabel={this.props.intl.formatMessage({ id: 'label.cancel' })}
					// eslint-disable-next-line react/jsx-no-bind
					onConfirm={this.doSubmit.bind(this)}
				/>
			);
		} else {
			Signals.ShowModal.dispatch(
				<ModalAlert
					title={this.props.intl.formatMessage({ id: 'offer.alert.emptysignature.title' })}
					body={this.props.intl.formatMessage({ id: 'offer.alert.emptysignature.body' })}
				/>
			);
		}
	}

	/**
	 *
	 */
	doSubmit() {
		const command = new SignOfferCommand(this.props.offerModel, this.props.signOfferModel);
		command.execute();
	}

	/**
	 *
	 */
	doDecline() {
		// offerModel, signOfferModel, company
		const command = new DeclineOfferCommand(this.props.offerModel);
		command.execute();
	}
}

AcceptOfferForm.contextType = ApplicationContext;

AcceptOfferForm.propTypes = {
	intl: PropTypes.object,
	// eslint-disable-next-line react/no-unused-prop-types
	company: PropTypes.instanceOf(Company).isRequired,
	offerModel: PropTypes.instanceOf(OfferModel).isRequired,
	signOfferModel: PropTypes.instanceOf(SignOfferModel).isRequired
};

export default injectIntl(AcceptOfferForm);
