import Signals from '../../signals/Signals';
import { Routes } from '../../data/Routes';
import pontoIntegrationStatusDelete from '../../requests/bank/pontoIntegrationStatusDelete';

export default class DeletePontoIntegrationStatusCommand {
	constructor() {
		this.then = this.then.bind(this);
		this.catch = this.catch.bind(this);
		this.companyId = null;
		this.bankbookStatusStore = null;
		this.fiscalYearId = null;
	}

	execute(companyId, fiscalYearId, bankbookStatusStore) {
		this.companyId = companyId;
		this.bankbookStatusStore = bankbookStatusStore;
		this.fiscalYearId = fiscalYearId;
		return pontoIntegrationStatusDelete(companyId).then(this.then).catch(this.catch);
	}

	then() {
		this.bankbookStatusStore.reset();
		Signals.RequestRoute.dispatch(
			Routes.COMPANY_PONTO_ONBOARDING.getPath({ id: this.companyId, year: this.fiscalYearId })
		);
	}

	catch(error) {
		Signals.Error.dispatch(error);
	}
}
