import Signals from '../signals/Signals';
import GetCompanyExpensesCommand from '../commands/GetCompanyExpensesCommand';
import GetCompanyIncomeCommand from '../commands/GetCompanyIncomeCommand';
import { AutocompleteTypes } from '../data/AutocompleteSearchObject';

export default class AutocompleteStore {
	income = [];

	expenses = [];

	/**
	 *
	 */
	constructor() {
		this.onCompanyChanged = this.onCompanyChanged.bind(this);
		Signals.CompanyChanged.add(this.onCompanyChanged);
	}

	/**
	 *
	 * @param companyId
	 */
	onCompanyChanged(companyId) {
		this.reset();
		this.fetchAutocompleteData(companyId);
	}

	/**
	 *
	 * @param companyId
	 */
	fetchAutocompleteData(companyId) {
		if (!isNaN(parseInt(companyId, 10))) {
			const incomeCommand = new GetCompanyIncomeCommand(this, companyId);
			const expensesCommand = new GetCompanyExpensesCommand(this, companyId);

			incomeCommand.execute();
			expensesCommand.execute();
		}
	}

	/**
	 *
	 * @param model
	 * @param type
	 */
	add(model, type) {
		switch (type) {
			case AutocompleteTypes.TYPE_INVOICES:
				this.income.unshift(model);
				break;
			case AutocompleteTypes.TYPE_EXPENSES:
				this.expenses.unshift(model);
				break;
			default:
				console.log('AutocompleteStore add() type not found', type);
		}

		this.sort(type);
	}

	/**
	 *
	 * @param type
	 */
	sort(type) {
		switch (type) {
			case AutocompleteTypes.TYPE_INVOICES:
				this.income.sort((a, b) => {
					const timeA = a.date ? a.date.getTime() : Number.NEGATIVE_INFINITY;
					const timeB = b.date ? b.date.getTime() : Number.NEGATIVE_INFINITY;
					return timeB - timeA;
				});
				break;
			case AutocompleteTypes.TYPE_EXPENSES:
				this.expenses.sort((a, b) => {
					const timeA = a.date ? a.date.getTime() : Number.NEGATIVE_INFINITY;
					const timeB = b.date ? b.date.getTime() : Number.NEGATIVE_INFINITY;
					return timeB - timeA;
				});
				break;
			default:
				console.log('AutocompleteStore sort() type not found', type);
		}
	}

	/**
	 *
	 * @param model
	 * @param type
	 */
	update(model, type) {
		switch (type) {
			case AutocompleteTypes.TYPE_INVOICES:
				// eslint-disable-next-line no-case-declarations
				const incomeModel = this.income.find((im) => {
					return im.id === model.id;
				});

				// Copy supplied model to found invoiceModel
				if (incomeModel) {
					incomeModel.copy(model);
				} else {
					console.log('AutocompleteStore IncomeModel not found', model);
				}
				break;
			case AutocompleteTypes.TYPE_EXPENSES:
				// eslint-disable-next-line no-case-declarations
				const expenseModel = this.expenses.find((em) => {
					return em.id === model.id;
				});

				// Copy supplied model to found expenseModel
				if (expenseModel) {
					expenseModel.copy(model);
				} else {
					console.log('AutocompleteStore ExpenseModel not found', model);
				}
				break;
			default:
				console.log('AutocompleteStore update() type not found', type);
		}
	}

	/**
	 *
	 */
	reset() {
		this.income = [];
		this.expenses = [];
	}

	/**
	 *
	 * @param autocompleteSearchObject
	 * @return {*[]|Array}
	 */
	filter(autocompleteSearchObject) {
		// Used to prevent adding of duplicate similar entries
		const uniqueKeys = [];

		switch (autocompleteSearchObject.type) {
			case AutocompleteTypes.TYPE_EXPENSES:
				return this.expenses.filter((expense) => {
					const uniqueKey = `${expense.customerName}`.trim() + `${expense.description}`.trim(); // Prevent double results
					const matches = !uniqueKeys[uniqueKey] && expense.matches(autocompleteSearchObject);

					if (matches) {
						uniqueKeys[uniqueKey] = true;
					}

					return matches;
				});

			case AutocompleteTypes.TYPE_INVOICES:
				return this.income.filter((invoice) => {
					const uniqueKey = `${invoice.customerName}`.trim() + `${invoice.description}`.trim(); // Prevent double results

					const matches = !uniqueKeys[uniqueKey] && invoice.matches(autocompleteSearchObject);

					if (matches) {
						uniqueKeys[uniqueKey] = true;
					}

					return matches;
				});
			default:
				console.log('AutocompleteStore filter() type not found', autocompleteSearchObject.type);
		}

		return [];
	}
}
