import { observable } from 'mobx';

import FinancialYearState from '../../data/FinancialYearState';

export default class FinancialYear {
	@observable id = -1;

	@observable year = -1;

	@observable status = undefined;

	@observable financialStatementSubmitted = false;

	isActive() {
		return !(this.isSubmitted() || this.isClosed());
	}

	isCompleted() {
		return this.status === FinancialYearState.AFGEROND;
	}

	isClosed() {
		return (
			this.status === FinancialYearState.AFGEROND || this.status === FinancialYearState.INACTIEF
		);
	}

	isSubmitted() {
		return this.financialStatementSubmitted;
	}

	setClosed() {
		this.status = FinancialYearState.AFGEROND;
	}

	toString() {
		return `FinancialYear: ${this.id}, ${this.year} ${this.status}`;
	}

	static parseFinancialYear(data) {
		const financialYear = new FinancialYear();

		financialYear.id = data.id;
		financialYear.year = data.year;
		financialYear.status = data.status;
		financialYear.financialStatementSubmitted = !!data.financialStatementSubmitted;

		return financialYear;
	}
}
