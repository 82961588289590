import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Routes } from '../../../../data/Routes';
import MenuItem from '../../MenuItem/MenuItem';
import { ApplicationContext } from '../../../../ApplicationContext';

/**
 *
 */
class OffersSubMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;
	}

	/**
	 *
	 */
	render() {
		const company = this.context.applicationStore.getSelectedCompany();
		const financialYear = this.context.applicationStore.getSelectedFinancialYear();

		if (!company || !financialYear) return null;

		const params = {
			id: company ? company.id : null,
			year: financialYear ? financialYear.id : null
		};

		return (
			<ul className="sub-menu__item-wrapper">
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_OFFERS_ALL.getPath(params)}
					routeToMatch={Routes.COMPANY_OFFERS_ALL}
					iconName="folder">
					<FormattedMessage id="submenu.offers.all" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_OFFERS_ARCHIVE.getPath(params)}
					routeToMatch={Routes.COMPANY_OFFERS_ARCHIVE}
					iconName="folder2">
					<FormattedMessage id="submenu.offers.archive" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_OFFERS_CLIENTS.getPath(params)}
					routeToMatch={Routes.COMPANY_OFFERS_CLIENTS}
					iconName="people2">
					<FormattedMessage id="submenu.offers.clients" />
				</MenuItem>
				<MenuItem
					className="submenu-item"
					route={Routes.COMPANY_SETTINGS_OFFERS.getPath(params)}
					routeToMatch={Routes.COMPANY_SETTINGS_OFFERS}
					iconName="gear">
					<FormattedMessage id="submenu.offers.settings" />
				</MenuItem>
			</ul>
		);
	}
}

OffersSubMenu.contextType = ApplicationContext;

OffersSubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(OffersSubMenu);
