import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Routes } from '../../../data/Routes';

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function MainMenuCompany(props) {
	const { menuItemWrapperClasses, menuItemClasses, params } = props;
	return (
		<ul className={`main-menu__item-wrapper ${menuItemWrapperClasses}`}>
			<MenuItem
				className={menuItemClasses}
				route={Routes.COMPANY_DASHBOARD.getPath(params)}
				routeToMatch={Routes.COMPANY_DASHBOARD}>
				<FormattedMessage id="mainmenu.company.dashboard" />
			</MenuItem>
			<MenuItem
				className={`${menuItemClasses} walkthrough-step-bookkeeping`}
				route={Routes.COMPANY_RESULTS.getPath(params)}
				routeToMatch={Routes.COMPANY_BOOKKEEPING}
				disabled={params.year == null}>
				<FormattedMessage id="mainmenu.company.bookkeeping" />
			</MenuItem>
			<MenuItem
				className={`${menuItemClasses} walkthrough-step-invoices`}
				route={Routes.COMPANY_INVOICES_ALL.getPath(params)}
				routeToMatch={Routes.COMPANY_INVOICES}
				disabled={params.year == null}>
				<FormattedMessage id="mainmenu.company.invoices" />
			</MenuItem>
			<MenuItem
				className={`${menuItemClasses} walkthrough-step-offers`}
				route={Routes.COMPANY_OFFERS_ALL.getPath(params)}
				routeToMatch={Routes.COMPANY_OFFERS}
				disabled={params.year == null}>
				<FormattedMessage id="mainmenu.company.offers" />
			</MenuItem>
			<MenuItem
				className={`${menuItemClasses} walkthrough-step-documents`}
				route={Routes.COMPANY_DOCUMENTS.getPath(params)}
				routeToMatch={Routes.COMPANY_DOCUMENTS}
				disabled={params.year == null}>
				<FormattedMessage id="mainmenu.company.documents" />
			</MenuItem>
		</ul>
	);
}

MainMenuCompany.propTypes = {
	menuItemWrapperClasses: PropTypes.string,
	menuItemClasses: PropTypes.string,
	params: PropTypes.object
};
