import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import offerChangeStatus from '../../requests/offers/offerChangeStatus';

/**
 *
 */
export default class UpdateOfferStatusCommand extends AbstractCommand {

	/**
	 *
	 * @param offerModel OfferModel
	 * @param company Company
	 * @param status OfferStatus
	 * @param intl
	 */
	constructor(offerModel, company, status, intl) {
		super();

		this.offerModel = offerModel;
		this.company = company;
		this.status = status;
		this.intl = intl;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return offerChangeStatus(this.company.id, this.offerModel.id, this.status)
			.then((response) => {

				this.offerModel.status = this.status;

				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {

		//
		const status = this.intl.formatMessage({id:'offer.state.' + this.status.toLowerCase()});

		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="offer.statuschanged.message"
								   values={{offerNr: this.offerModel.offerNr, status: status}}/>)
		);
	}

	//
}
