/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';

/**
 *
 */
export default class ModalAlert extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.captureAnchorClicks = this.captureAnchorClicks.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		Signals.KeyDown.add(this.onKeyDown);
	}

	/**
	 *
	 */
	componentDidMount() {
		// Blur active element
		if ('activeElement' in document) document.activeElement.blur();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.KeyDown.remove(this.onKeyDown);
	}

	/**
	 *
	 */
	render() {
		const classes = classNames({
			'modal-alert grid grid--center': true,
			'modal-alert--text-left-aligned': this.props.textLeftAligned,
			'modal-alert--wide': this.props.wide
		});

		const { icon } = this.props;
		const alertIcon = icon || 'icon--eye-black';

		return (
			<div className={classes} onKeyUp={this.onKeyUp}>
				<div className={`modal-alert__icon icon ${alertIcon}`} />

				<h1
					className="col--12 text--center"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: this.props.title }}
				/>
				{this.props.subTitle ? (
					<label
						className="modal-alert__subtitle col--12 text--center"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: this.props.subTitle }}
					/>
				) : null}
				<p
					ref={this.captureAnchorClicks}
					className="col--12 text--center"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: this.props.body }}
				/>

				<div className="grid grid--center">
					<button type="button" className="button--primary" onClick={this.onClick.bind(this)}>
						<FormattedMessage id="label.ok" />
					</button>
				</div>
			</div>
		);
	}

	/**
	 *
	 */
	onKeyDown(e) {
		switch (e.keyCode) {
			case 13:
			case 27:
				this.onClick();
				return false; // stop propagating
			default:
				break;
		}
	}

	/**
	 *
	 */
	onClick() {
		Signals.HideModal.dispatch();
		if (this.props.onConfirm) {
			this.props.onConfirm();
		}
	}

	/**
	 *
	 * @param ref
	 */
	captureAnchorClicks(ref) {
		if (ref) {
			const anchors = ref.getElementsByTagName('a');
			for (let i = 0; i < anchors.length; i++) {
				if (anchors[i].target === 'self') {
					anchors[i].addEventListener('click', (e) => {
						e.preventDefault();
						const href = e.currentTarget.getAttribute('href');
						Signals.HideModal.dispatch();
						Signals.RequestRoute.dispatch(href);
					});
				}
			}
		}
	}
}

ModalAlert.propTypes = {
	icon: PropTypes.string,
	wide: PropTypes.bool,
	textLeftAligned: PropTypes.bool,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	body: PropTypes.string.isRequired,
	onConfirm: PropTypes.func
};

ModalAlert.defaultProps = {
	icon: null,
	subTitle: null
};
