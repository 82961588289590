/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import classNames from 'classnames';
import { ApplicationContext } from '../../ApplicationContext';
import Page from '../Page';

import GetUserMessagesCommand from '../../commands/userMessages/GetUserMessagesCommand';
import UserMessageRow from './UserMessageRow';
import SearchField from '../../components/ui/SearchField/SearchField';

/**
 *
 */
@observer
class UserMessages extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.fieldHeaderSettings = [
			{
				id: 'publicationDate',
				label: this.props.intl.formatMessage({ id: 'usermessages.header.publicationdate' }),
				isDefault: true,
				alignTop: true,
				minimal: true
			},
			{
				id: 'type',
				label: this.props.intl.formatMessage({ id: 'usermessages.header.type' }),
				alignTop: true,
				minimal: true
			},
			{
				id: 'title',
				label: this.props.intl.formatMessage({ id: 'usermessages.header.title' }),
				alignTop: true,
				noSort: true,
				minimal: false
			},
			{
				id: 'subTitle',
				label: this.props.intl.formatMessage({ id: 'usermessages.header.subtitle' }),
				alignTop: true,
				noSort: true,
				minimal: false
			},
			// {id: 'body', label: this.props.intl.formatMessage({id:'usermessages.header.body'}), alignTop:true, noSort: true},
			{
				id: 'actions',
				label: this.props.intl.formatMessage({ id: 'usermessages.header.actions' }),
				center: true,
				noSort: true,
				minimal: true
			}
		];

		this.onSortClick = this.onSortClick.bind(this);
		this.onSearch = this.onSearch.bind(this);
	}

	/**
	 *
	 */
	componentWillMount() {
		this.state = { userMessagesStore: this.context.applicationStore.userMessagesStore };
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this._initialize();
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.context.applicationStore.isLoggedIn) {
			const { userMessagesStore } = this.state;
			return (
				<Page pageName="user-messages">
					<div className="user-messages__wrapper border border--dark">
						<div className="user-messages__options grid grid--spread">
							<SearchField
								name="search"
								// eslint-disable-next-line react/jsx-no-bind
								onChange={this.onSearch.bind(this)}
								placeholder={this.props.intl.formatMessage({ id: 'usermessages.search' })}
								value={userMessagesStore.filter ? userMessagesStore.filter : undefined}
							/>
							<div className="grid invoices__options-buttons" />
						</div>

						<table className="table user-messages__rows">
							<thead className="table__headers">
								<tr>{this._getHeaders()}</tr>
							</thead>
							<tbody className="table__body">{this._getUserMessageRows()}</tbody>
						</table>
					</div>
				</Page>
			);
		}

		return null;
	}

	/**
	 *
	 * @param e
	 */
	onSearch(e) {
		const { userMessagesStore } = this.state;
		userMessagesStore.filter = e ? e.target.value : null;
	}

	/**
	 *
	 * @param field
	 */
	onSortClick(field) {
		const { userMessagesStore } = this.state;
		if (userMessagesStore.sortBy === field) {
			userMessagesStore.sortDirection = -userMessagesStore.sortDirection;
		} else {
			userMessagesStore.sortBy = field;
			userMessagesStore.sortDirection = 1;
		}
	}

	/**
	 *
	 * @private
	 */
	_getHeaders() {
		const { userMessagesStore } = this.state;
		return this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`user-messages__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--minimal': fieldData.minimal,
						'table__header--sorted-default table__header--sorted':
							!userMessagesStore.sortBy && fieldData.isDefault,
						'table__header--sorted': userMessagesStore.sortBy === fieldData.id,
						'table__header--sorted-up':
							userMessagesStore.sortBy === fieldData.id && userMessagesStore.sortDirection === -1,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}
					onClick={() => this.onSortClick(fieldData.id)}>
					{fieldData.label}
				</td>
			);
		});
	}

	/**
	 *
	 * @return {null}
	 * @private
	 */
	_getUserMessageRows() {
		const { userMessagesStore } = this.state;
		return userMessagesStore.filteredAndSortedUserMessages.map((userMessage, index) => {
			return <UserMessageRow key={`um-${index}`} userMessage={userMessage} />;
		});
	}

	/**
	 *
	 * @private
	 */
	_initialize() {
		if (this.context.applicationStore.isLoggedIn) {
			const command = new GetUserMessagesCommand(this.context.applicationStore.userMessagesStore);
			command.execute();
		}
	}
}

UserMessages.contextType = ApplicationContext;

UserMessages.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(UserMessages);
