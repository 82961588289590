import { observable, computed } from 'mobx';

import { _formatDate, _formatStringToDate } from '../../utils/objectToFormData';

import BTW from '../../data/BTW';
import Locale from '../../data/Locale';
import PaymentTerm from '../../data/PaymentTerm';
import InvoiceConceptStatus from '../../data/InvoiceConceptStatus';
import CanPayOnlineStatus from '../../data/CanPayOnlineStatus';

import CompanyCustomer from './CompanyCustomer';

import InvoiceConceptRowModel from './InvoiceConceptRowModel';
import Company from './Company';
import CompanyBrandModel from './CompanyBrandModel';

/**
 *
 */
export default class InvoiceConceptModel {
	@observable statusDateChanged = null;

	@observable status = null;

	/**
	 *
	 */
	constructor() {
		this.id = null;
		this.companyCustomer = null;
		this.companyBrand = null;
		this.invoiceNr = null;
		this.projectcode = null;
		this.date = new Date();
		this.expirationDays = PaymentTerm.FOURTEEN_DAYS.days;
		this.expirationDate = null;
		this.recipientEmail = null;
		this.senderEmail = null;
		this.notes = null;
		this.subject = null;
		this.emailMessage = null;
		this.emailSubject = null;
		this.logoAssetUuid = null;
		this.ccRecipientEmail = null;
		this.bccRecipientEmail = null;
		this.locale = null;

		this.publicAccessId = null;
		this.canBePaid = false;
		this.canPayOnline = null;
		this.ownInvoice = false; // Is invoice From KdB or Founders?
		this.customerFactuur = false; // ?

		this.assetAttachments = [];
		this.history = [];

		this.reminderDate = null;
		this.remindCompanyUserInDays = null;
		this.remindCompanyCustomerInDays = null;

		this.company = null;

		this.offerId = null;

		this.invoiceConceptRows = [];
	}

	/**
	 *
	 * @return {number|null}
	 */
	@computed get bookyear() {
		return this.date ? this.date.getFullYear() : null;
	}

	/**
	 *
	 * @return {boolean}
	 */
	canBePaidOnline() {
		return this.canBePaid && this.canPayOnline === CanPayOnlineStatus.YES;
	}

	/**
	 *
	 */
	canTogglePaid() {
		return (
			!this.status ||
			this.status === InvoiceConceptStatus.SENT_MANUALLY ||
			this.status === InvoiceConceptStatus.SENT_BY_EMAIL_KDB ||
			this.status === InvoiceConceptStatus.SENT_REMINDER_KDB ||
			this.status === InvoiceConceptStatus.LATE ||
			this.status === InvoiceConceptStatus.PAID
		);
	}

	/**
	 * Can this InvoiceConcept be removed
	 *
	 * @returns {boolean}
	 */
	canDelete() {
		return !this.status || this.status === InvoiceConceptStatus.CREATED;
	}

	/**
	 * Can this InvoiceConcept be edited
	 *
	 * @returns {boolean}
	 */
	canEdit() {
		return this.status !== InvoiceConceptStatus.ARCHIVED; //! this.status || this.status === InvoiceConceptStatus.CREATED;
	}

	/**
	 * Can this InvoiceConcept be send to a client?
	 *
	 * @returns {boolean}
	 */
	canSend() {
		return (
			this.status !== InvoiceConceptStatus.LATE &&
			this.status !== InvoiceConceptStatus.SENT_REMINDER_KDB &&
			this.status !== InvoiceConceptStatus.ARCHIVED &&
			this.status !== InvoiceConceptStatus.PAID
		);
	}

	/**
	 * Can this InvoiceConcept be send to a client?
	 *
	 * @returns {boolean}
	 */
	canSendReminder() {
		return (
			this.status !== InvoiceConceptStatus.ARCHIVED &&
			this.status !== InvoiceConceptStatus.PAID &&
			(this.status === InvoiceConceptStatus.LATE ||
				this.status === InvoiceConceptStatus.SENT_REMINDER_KDB)
		);
	}

	/**
	 * Can this InvoiceConcept be removed?
	 * @returns {boolean}
	 */
	canRemove() {
		return this.status;
		//! this.status || this.status === InvoiceConceptStatus.CREATED;
	}

	/**
	 * Is this InvoiceConcept still open?
	 *
	 * * @returns {boolean}
	 */
	isOpen() {
		return !(
			this.status === InvoiceConceptStatus.PAID || this.status === InvoiceConceptStatus.DELETED
		);
	}

	/**
	 *
	 * @return {boolean}
	 */
	isPublic() {
		return !!this.publicAccessId;
	}

	/**
	 *
	 * @return {boolean}
	 */
	canArchive() {
		return this.status === InvoiceConceptStatus.PAID;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isArchived() {
		return this.status === InvoiceConceptStatus.ARCHIVED;
	}

	/**
	 * Does this InvoiceConcept have rows where amount of units are not default? (default = 1)
	 */
	hasDeviatingUnits() {
		let deviating = false;
		this.invoiceConceptRows.forEach((row) => {
			if (row.units !== 1) {
				deviating = true;
			}
		});

		return deviating;
	}

	/**
	 *
	 * @return {boolean}
	 */
	hasLanguage() {
		return this.locale !== null;
	}

	/**
	 *
	 * @return {boolean}
	 */
	isEnglish() {
		return this.locale === Locale.en_US;
	}

	/**
	 * Replaces linebreaks like \r and \n with <br>
	 *
	 * @return {String}
	 */
	getNotesAsHTML() {
		return this.notes ? this.notes.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
	}

	/**
	 *
	 */
	hasNotes() {
		return this.notes && this.notes.trim().length > 0;
	}

	/**
	 *
	 */
	getSubtotal() {
		if (this.getInclVat()) {
			return this.getTotal() - this.getBTWTotal(BTW.HOOG.name) - this.getBTWTotal(BTW.LAAG.name);
		}
		let subTotal = 0;
		this.invoiceConceptRows.forEach((row) => {
			subTotal += row.getSubtotal(this.date);
		});

		return subTotal;
	}

	/**
	 *
	 * @param btwType
	 *
	 * @returns {number}
	 */
	getBTWTotal(btwType) {
		let btwTotal = 0;

		this.invoiceConceptRows.forEach((row) => {
			if (btwType === row.btw) {
				btwTotal += row.getVATTotal(this.date);
			}
		});

		if (isNaN(btwTotal)) {
			btwTotal = 0;
		}

		//
		return Math.round(btwTotal * 100) / 100;
	}

	/**
	 *
	 * @returns {*}
	 */
	getTotal() {
		let total = 0;
		if (this.getInclVat()) {
			this.invoiceConceptRows.forEach((row) => {
				total += row.amount;
			});

			return total;
		}
		this.invoiceConceptRows.forEach((row) => {
			total += row.amount;
		});

		return total + this.getBTWTotal(BTW.HOOG.name) + this.getBTWTotal(BTW.LAAG.name);
	}

	/**
	 *
	 * @return {Date}
	 */
	getExpirationDateFromDate() {
		const date = new Date(this.date);
		date.setDate(date.getDate() + parseInt(this.expirationDays, 10));
		return date;
	}

	/**
	 * Returns whether this InvoiceConcept contains any VAT of type {type}
	 *
	 * @param type The BTW type
	 * @param exclusive If this BTW type can be the only one found
	 * @returns {boolean}
	 */
	containsVATType(type, exclusive = false) {
		let typeFound = false;
		let othersFound = false;

		//
		this.invoiceConceptRows.forEach((row) => {
			if (type.name === row.btw) {
				typeFound = true;
			} else {
				othersFound = true;
			}
		});

		//
		if (exclusive && othersFound) {
			return false;
		}

		return typeFound;
	}

	/**
	 * @return {Number|undefined}
	 */
	getCompanyCustomerId() {
		const id = this.companyCustomer ? this.companyCustomer.id : undefined;
		return !isNaN(id) ? id : undefined;
	}

	/**
	 * @return {Number|undefined}
	 */
	getCompanyBrandId() {
		const id = this.companyBrand ? this.companyBrand.id : undefined;
		return !isNaN(id) ? id : undefined;
	}

	/**
	 *
	 * @param offerModel OfferModel
	 */
	copyFromOfferModel(offerModel) {
		this.offerId = offerModel.id;

		this.companyCustomer = offerModel.companyCustomer.clone();
		if (offerModel.companyBrand) {
			this.companyBrand = offerModel.companyBrand.clone();
		}
		this.projectcode = offerModel.projectcode;
		this.date = new Date();
		this.subject = offerModel.subject;
		this.notes = null; // Never copy notes//offerModel.notes;
		this.logoAssetUuid = offerModel.logoAssetUuid;
		this.locale = offerModel.locale;

		this.assetAttachments = offerModel.assetAttachments;
		this.history = [];
		this.invoiceConceptRows = [];

		offerModel.offerRows.forEach((offerRowModel) => {
			JSON.stringify(offerRowModel);
			const invoiceConceptRowModel = new InvoiceConceptRowModel(offerRowModel);
			invoiceConceptRowModel.id = undefined; // reset id
			this.invoiceConceptRows.push(invoiceConceptRowModel);
		});
	}

	/**
	 *
	 * @return {InvoiceConceptModel}
	 */
	clone(copyDate = false, ignoreUniqueFields = false) {
		const clone = new InvoiceConceptModel();

		for (const key in this) {
			if (this.hasOwnProperty(key) && key !== 'invoiceConceptRows' && key !== 'history') {
				switch (key) {
					case 'date':
						clone.date = this.date && copyDate ? new Date(this.date.getTime()) : new Date();
						break;
					default:
						clone[key] = this[key];
				}
			}
		}

		// Reset all unique fields
		if (ignoreUniqueFields) {
			clone.id = null;
			clone.invoiceNr = null;
			clone.status = null;
			clone.statusDateChanged = null;
			clone.history = [];

			clone.publicAccessId = null;
			clone.offerId = null;

			clone.recipientEmail = null;
			clone.senderEmail = null;
			clone.emailMessage = null;
			clone.emailSubject = null;
			clone.ccRecipientEmail = null;
			clone.bccRecipientEmail = null;
		}

		//
		this.invoiceConceptRows.forEach((row) => {
			clone.invoiceConceptRows.push(row.clone());
		});

		// Reset invoiceConceptRows id's
		if (ignoreUniqueFields) {
			clone.invoiceConceptRows.forEach((row) => {
				row.id = null;
			});
		}

		return clone;
	}

	/**
	 *
	 * @param data
	 */
	update(data) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const company = new Company();
				switch (key) {
					case 'expirationDays':
						this[key] = parseInt(data[key], 10);
						break;
					case 'expirationDate':
						this[key] = _formatStringToDate(data[key], true, false, true);
						break;
					case 'date':
					case 'reminderDate':
						this[key] = _formatStringToDate(data[key], true);
						break;
					case 'statusDateChanged':
						this[key] = _formatStringToDate(data[key], true, true);
						break;
					case 'invoiceConceptRows':
						// do nothing
						break;
					case 'companyCustomer':
						this[key] = new CompanyCustomer(data[key]);
						break;
					case 'companyBrand':
						this[key] = new CompanyBrandModel(data[key]);
						break;
					case 'company':
						company.update(data[key]);
						this[key] = company;
						break;
					default:
						this[key] = data[key];
				}
			}
		}
	}

	/**
	 *
	 * @return {InvoiceConceptRowModel}
	 */
	addNewConceptRow() {
		const row = new InvoiceConceptRowModel();
		this.invoiceConceptRows.push(row);

		return row;
	}

	/**
	 *
	 * @param inclVat
	 */
	setInclVat(inclVat) {
		this.invoiceConceptRows.forEach((row) => {
			row.inclVat = inclVat;
		});
	}

	/**
	 *
	 */
	getInclVat() {
		let inclVat = null;
		this.invoiceConceptRows.forEach((row) => {
			// Get first found value
			if (inclVat == null) {
				inclVat = row.inclVat;

				// Update row to match first found value
			} else {
				row.inclVat = inclVat;
			}

			row.update(this.date);
		});

		return inclVat;
	}

	/**
	 *
	 * @param uid
	 */
	getConceptRowByUid(uid) {
		return this.invoiceConceptRows.find((row) => {
			return row._uid === uid;
		});
	}

	/**
	 *
	 * @param invoiceConceptRowModel
	 */
	removeConceptRow(invoiceConceptRowModel) {
		let i = 0;
		const l = this.invoiceConceptRows.length;
		for (; i < l; i++) {
			if (this.invoiceConceptRows[i] === invoiceConceptRowModel) {
				this.invoiceConceptRows.splice(i, 1);
			}
		}
	}

	/**
	 *
	 * @return {string}
	 */
	toSearchString() {
		let searchString = '';
		searchString += this.invoiceNr ? `${this.invoiceNr} ` : '';
		searchString += this.companyCustomer ? `${this.companyCustomer.toSearchString()} ` : '';
		searchString += this.subject ? `${this.subject} ` : '';
		searchString = searchString.toLowerCase();

		return searchString;
	}

	/**
	 *
	 * @param defaults
	 * @param ignoreFields
	 * @return {string}
	 */
	toJSON(defaults = {}, ignoreFields = { status: true }) {
		const data = {};

		// Apply companyCustomer id to companyCustomerId if empty
		if (this.companyCustomer && this.companyCustomerId === null) {
			this.companyCustomerId = this.companyCustomer.id;
		}

		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if (typeof this[key] !== 'function' && !(ignoreFields && ignoreFields[key])) {
					let value = this[key];

					// Try to set defaults when value is not set
					if (value === undefined || value === null) {
						value = defaults[key] !== null && defaults[key] !== undefined ? defaults[key] : null;
					}

					// Force format of specific types and objects (like `Date`)
					data[key] = this._formatJSONData(value);
				}
			}
		}

		data.invoiceConceptRows = this.invoiceConceptRows;

		return JSON.stringify(data);
	}

	/**
	 *
	 * @param value
	 * @return {*}
	 * @private
	 */
	_formatJSONData(value) {
		if (value instanceof Date) {
			return _formatDate(value);
		}
		return value;
	}

	/**
	 *
	 * @param data
	 * @returns {InvoiceConceptModel}
	 */
	static parseInvoiceConceptData(data) {
		const model = new InvoiceConceptModel();
		InvoiceConceptModel.updateInvoiceConcept(data, model);
		return model;
	}

	/**
	 *
	 * @param data
	 * @param invoiceConcept
	 */
	static updateInvoiceConcept(data, invoiceConcept) {
		invoiceConcept.update(data);
		invoiceConcept.invoiceConceptRows = [];

		if (!data.invoiceConceptRows) {
			data.invoiceConceptRows = [];
		}

		data.invoiceConceptRows.forEach((invoiceRow) => {
			invoiceConcept.invoiceConceptRows.push(
				new InvoiceConceptRowModel(invoiceRow, invoiceConcept.date)
			);
		});
	}
}
