import fetch from '../fetch';
import EndPoints from '../../data/EndPoints';
import Methods from '../../data/Methods';

export default (messageId) => {
	return fetch(
		Methods.POST,
		EndPoints.USER_MESSAGE_SEEN.replace(':messageId', messageId),
		null,
		null,
		true
	);
};
