import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import AbstractCommand from '../AbstractCommand';
import Signals from '../../signals/Signals';

import InvoiceConceptStatus from '../../data/InvoiceConceptStatus';
import invoiceConceptArchive from '../../requests/invoiceConceptArchive';

/**
 *
 */
export default class ArchiveInvoiceConceptCommand extends AbstractCommand {

	/**
	 *
	 * @param invoiceConceptModel InvoiceConceptModel
	 * @param company Company
	 */
	constructor(invoiceConceptModel, company) {
		super();

		this.invoiceConceptModel = invoiceConceptModel;
		this.company = company;
	}

	/**
	 *
	 * @param success
	 * @param error
	 */
	execute(success, error) {
		return invoiceConceptArchive(this.company.id, this.invoiceConceptModel.id)
			.then((response) => {

				this.invoiceConceptModel.status = InvoiceConceptStatus.ARCHIVED;
				this._showSuccessMessage();

				if(success) {
					success(response);
				}
			})
			.catch((err) => {

				Signals.Error.dispatch(err);

				if(error) {
					error(err);
				}
			});
	}

	/**
	 *
	 * @private
	 */
	_showSuccessMessage() {
		// Show message
		Signals.ShowMessageDialog.dispatch(
			(<FormattedHTMLMessage id="invoice.archived.message"
								   values={{invoiceNr: this.invoiceConceptModel.invoiceNr}}/>)
		);
	}
}
