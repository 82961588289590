/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Page from '../../Page';

import { Routes } from '../../../data/Routes';
import Locale from '../../../data/Locale';
import PaymentTerm from '../../../data/PaymentTerm';
import InvoiceConceptModel from '../../../stores/models/InvoiceConceptModel';
import BTW from '../../../data/BTW';

import Signals from '../../../signals/Signals';

import InvoiceConceptRow from './components/InvoiceConceptRow';
import InfoIcon from '../../../components/ui/InfoIcon/InfoIcon';
import ModalAlert from '../../../components/ui/Modal/ModalAlert';
import FormGroup from '../../../components/ui/FormGroup/FormGroup';
import FormField from '../../../components/ui/FormField/FormField';

import DropDown from '../../../components/ui/DropDown/DropDown';
import Switch from '../../../components/ui/Switch/Switch';
import InvoiceConceptSummaryRow from './components/InvoiceConceptSummaryRow';
import DateInput from '../../../components/ui/DateInput/DateInput';
import CompanyCustomerSearch from '../../../components/ui/CompanyCustomerSearch/CompanyCustomerSearch';
import CompanyBrandSearch from '../../../components/ui/CompanyBrandSearch/CompanyBrandSearch';

import FileUploadList from '../../../components/ui/FileUpload/FileUploadList';
import { ApplicationContext } from '../../../ApplicationContext';
import { FormErrorContext } from '../../../FormErrorContext';

import SaveInvoiceConceptCommand from '../../../commands/invoiceConcepts/SaveInvoiceConceptCommand';
import GetInvoicesConceptCommand from '../../../commands/invoiceConcepts/GetInvoicesConceptCommand';
import GetLatestInvoiceNumberCommand from '../../../commands/invoiceConcepts/GetLatestInvoiceNumberCommand';

import CookieController, { COOKIE_TYPES } from '../../../controllers/CookieController';
import PropertiesController from '../../../controllers/PropertiesController';
import AlertMessage from '../../../components/ui/AlertMessage/AlertMessage';
import GetArchivedInvoicesConceptCommand from '../../../commands/invoiceConcepts/GetArchivedInvoicesConceptCommand';
import GetOffersCommand from '../../../commands/offers/GetOffersCommand';

//
const localesArray = [Locale.nl_NL, Locale.en_US];

/**
 *
 */
@observer
class InvoiceConcept extends React.Component {
	/**
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);

		this.invoiceConceptModel = null;

		this.state = {
			invoiceNrChangedManually: false,
			submitting: false,
			dragging: null
		};

		this.onCancel = this.onCancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onError = this.onError.bind(this);

		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragUpdate = this.onDragUpdate.bind(this);

		this.onDateChange = this.onDateChange.bind(this);
		this.onClientChange = this.onClientChange.bind(this);
		this.onBrandChange = this.onBrandChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);

		this.onAttachmentsChange = this.onAttachmentsChange.bind(this);

		this.showProjectOrderInfo = this.showProjectOrderInfo.bind(this);

		this.addNewInvoiceConceptRow = this.addNewInvoiceConceptRow.bind(this);
		this.onInvoiceConceptRowChange = this.onInvoiceConceptRowChange.bind(this);
		this.onInvoiceConceptRowRemove = this.onInvoiceConceptRowRemove.bind(this);

		this.onCompanyCustomerAdded = this.onCompanyCustomerAdded.bind(this);
		this.onCompanyBrandAdded = this.onCompanyBrandAdded.bind(this);

		this.fieldHeaderSettings = [
			{ id: 'dragicon', labelId: null },
			{ id: 'description', labelId: 'invoice.header.description' },
			{ id: 'units', labelId: 'invoice.header.units', center: true },
			{ id: 'unitprice', labelId: 'invoice.header.unitprice', center: true },
			{ id: 'btw', labelId: 'invoice.header.btw', center: true },
			{ id: 'amount', labelId: 'invoice.header.amount', right: true },
			{ id: 'remove', labelId: 'invoice.header.remove' }
		];
	}

	/**
	 *
	 */
	componentWillMount() {
		this.state.companyLatestInvoiceNumberStore =
			this.context.applicationStore.companyLatestInvoiceNumberStore;
		Signals.CompanyCustomerAdded.add(this.onCompanyCustomerAdded);
		Signals.CompanyBrandAdded.add(this.onCompanyBrandAdded);
		// Log the page view
		console.log('InvoiceConcept.componentWillMount');
		this._initialize();
	}

	/**
	 *
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(_nextProps, _nextContext) {
		this._initialize();
	}

	/**
	 *
	 */
	componentWillUnmount() {
		Signals.CompanyCustomerAdded.remove(this.onCompanyCustomerAdded);
		Signals.CompanyBrandAdded.remove(this.onCompanyBrandAdded);
	}

	/**
	 *
	 * @returns {*}
	 */
	render() {
		// Check if user logged in, and we have company
		const company = this.context.applicationStore.getSelectedCompany();
		const user = this.context.applicationStore.user;

		const tableBodyClasses = classNames({
			table__body: true,
			'table__body--dragging': !!this.state.dragging
		});

		//
		if (!this.invoiceConceptModel || !company || !user) {
			return null;
		}

		// Invoice Number suggestions and defaults
		const latestInvoiceNumber = this.state.companyLatestInvoiceNumberStore.getLatestInvoiceNumber(
			this.invoiceConceptModel.date.getFullYear()
		);
		const suggestedInvoiceNumber = latestInvoiceNumber
			? latestInvoiceNumber.suggestedNextInvoiceNr
			: '';

		// Set, override invoice nr
		if (
			!this.invoiceConceptModel.id &&
			!this.state.invoiceNrChangedManually &&
			latestInvoiceNumber
		) {
			this.invoiceConceptModel.invoiceNr = suggestedInvoiceNumber;
		}

		const inclVat = this.invoiceConceptModel.getInclVat();
		const { intl } = this.props;
		const { errors } = this.state;

		return (
			<Page pageName="invoice-concept grid">
				{PropertiesController.getProperty(PropertiesController.FEATURE_DEMO, this.context) ? (
					<AlertMessage
						className="invoice-concept__demo-warning"
						title={this.props.intl.formatMessage({ id: 'demo.invoiceconcept.warning' })}
						warning
					/>
				) : null}

				<FormErrorContext.Provider value={{ errors }}>
					<form
						ref={(el) => {
							this.el = el;
						}}
						className="invoice-concept__panel panel grid col--12 box-shadow border border--dark"
						onSubmit={this.onSubmit}>
						<div className="invoice-concept__options-top padding-small border--bottom border--dark grid grid--spread col--12">
							{this._getOptionButtons()}
						</div>

						<div className="invoice-concept__settings col--12 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--6">
									<label>
										<FormattedMessage id="invoice.settings.brand" />
									</label>
									{this._getCompanyBrandSearch()}
								</FormField>
								<FormField className="col--6">
									<label>
										<FormattedMessage id="invoice.settings.client" />
									</label>
									{this._getCompanyCustomerSearch()}
								</FormField>
								<FormField className="col--6 ">
									<label>
										<FormattedMessage id="invoice.settings.invoicenr" /> (
										{this._getLastUsedInvoiceNrPlaceholder()})
									</label>
									<input
										name="invoiceNr"
										type="text"
										value={this.invoiceConceptModel.invoiceNr}
										onChange={this.onInputChange}
										required
										placeholder={this._getLastUsedInvoiceNrPlaceholder()}
									/>
								</FormField>

								<FormField className="col--6 ">
									<label>
										<FormattedMessage id="invoice.settings.projectcode" />
										<InfoIcon onClick={this.showProjectOrderInfo} />
									</label>
									<input
										name="projectcode"
										type="text"
										value={this.invoiceConceptModel.projectcode}
										onChange={this.onInputChange}
									/>
								</FormField>

								<FormField className="col--3 form-field__date-picker invoice-concept__form-field-stretch">
									<label>
										<FormattedMessage id="invoice.settings.issuedate" />
									</label>
									<DateInput
										className="invoice-concept__issue-date"
										name="date"
										autoComplete="off"
										value={this.invoiceConceptModel.date}
										onChange={this.onDateChange}
									/>
								</FormField>

								<FormField className="col--3 invoice-concept__form-field-stretch">
									<label>
										<FormattedMessage id="invoice.settings.expirationterm" />
									</label>

									<div className="col--12 grid grid--no-wrap">
										<input
											type="number"
											className="grid-grow-1 has-suffix"
											name="expirationDays"
											value={this.invoiceConceptModel.expirationDays}
											onChange={this.onInputChange}
											required
											min={1}
											max={120}
											step={1}
										/>

										<div className="no-shrink form-field__input-suffix">
											<FormattedMessage id="invoice.payment.label.days" />
										</div>
									</div>
								</FormField>

								<FormField className="col--6 invoice-concept__form-field-stretch">
									<label>
										<FormattedMessage id="invoice.settings.language" />
									</label>
									<DropDown
										value={this.invoiceConceptModel.locale}
										className="col--12"
										name="locale"
										onChange={this.onInputChange}>
										{this._getLocaleOptions()}
									</DropDown>
								</FormField>
							</FormGroup>

							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="invoice.subject.label" />
									</label>
									<input
										name="subject"
										value={this.invoiceConceptModel.subject}
										type="text"
										placeholder={intl.formatMessage({ id: 'invoice.subject.placeholder' })}
										onChange={this.onInputChange}
										maxLength={255}
										required
									/>
								</FormField>
							</FormGroup>
						</div>

						<div className="invoice-concept__rows-wrapper">
							<div className="invoice-concept__options-middle grid grid--right border--top">
								<Switch
									checked={inclVat}
									name="inclVat"
									useState={false}
									label={
										inclVat
											? intl.formatMessage({ id: 'invoice.settings.label.inclvat' })
											: intl.formatMessage({ id: 'invoice.settings.label.exclvat' })
									}
									onChange={this.onInputChange}
								/>
							</div>

							<table className="invoice-concept__rows col--12">
								<thead className="table__headers">
									<tr>{this._getTableHeaders()}</tr>
								</thead>
								<tbody className={tableBodyClasses} onDragOver={this.onDragOver}>
									{this._getInvoiceConceptRows()}
								</tbody>
								<tfoot>
									{/* subtotal */}
									<InvoiceConceptSummaryRow
										label={intl.formatMessage({ id: 'invoice.summary.subtotal' })}
										amount={this.invoiceConceptModel.getSubtotal()}
									/>

									{/* btw laag */}
									<InvoiceConceptSummaryRow
										hideWhenZero={!this.invoiceConceptModel.containsVATType(BTW.LAAG)}
										label={intl.formatMessage(
											{ id: 'invoice.summary.btw' },
											{
												percentage: intl.formatMessage({
													id: BTW.LAAG.translationId(this.invoiceConceptModel.date)
												})
											}
										)}
										amount={this.invoiceConceptModel.getBTWTotal(BTW.LAAG.name)}
									/>
									{/* btw hoog */}
									<InvoiceConceptSummaryRow
										hideWhenZero={!this.invoiceConceptModel.containsVATType(BTW.HOOG)}
										label={intl.formatMessage(
											{ id: 'invoice.summary.btw' },
											{ percentage: intl.formatMessage({ id: BTW.HOOG.translationId() }) }
										)}
										amount={this.invoiceConceptModel.getBTWTotal(BTW.HOOG.name)}
									/>

									{this._getVATReversed()}
									{this._getVATExempt()}

									<InvoiceConceptSummaryRow
										label={intl.formatMessage({ id: 'invoice.summary.total' })}
										amount={this.invoiceConceptModel.getTotal()}
									/>
								</tfoot>
							</table>
						</div>

						{/* Notes */}
						<div className="invoice-concept__extra col--12 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="invoice.notes.label" />
									</label>
									<textarea
										name="notes"
										placeholder={intl.formatMessage({ id: 'invoice.notes.placeholder' })}
										className="invoice-concept__notes col--12"
										value={this.invoiceConceptModel.notes}
										onChange={this.onInputChange}
									/>
								</FormField>
							</FormGroup>
						</div>

						{/* Attachments */}
						<div className="invoice-concept__attachments col--7 grid padding">
							<FormGroup className="col--12">
								<FormField className="col--12">
									<label>
										<FormattedMessage id="invoice.attachments.label" />
									</label>
									<FileUploadList
										fileAssets={this.invoiceConceptModel.assetAttachments}
										maxTotalFileSize={20971520} // 20Mb
										onChange={this.onAttachmentsChange}
									/>
								</FormField>
							</FormGroup>
						</div>

						{/* Options (Cancel/Save) */}
						<div className="invoice-concept__options-bottom padding-small border--top border--dark grid grid--spread col--12">
							{this._getOptionButtons()}
						</div>
					</form>
				</FormErrorContext.Provider>
			</Page>
		);
	}

	/**
	 *
	 * @param e
	 * @param date
	 */
	onDateChange(e, date) {
		this.invoiceConceptModel.date = date;
		this.forceUpdate();
	}

	/**
	 *
	 * @param companyCustomer
	 */
	onClientChange(companyCustomer) {
		this.invoiceConceptModel.companyCustomer = companyCustomer;
		this.forceUpdate();
	}

	/**
	 *
	 * @param companyBrand
	 */
	onBrandChange(companyBrand) {
		const { applicationStore } = this.context;
		if (!companyBrand) {
			const company = applicationStore.getSelectedCompany();
			companyBrand = company.defaultCompanyBrand;
		}
		CookieController.setCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID, companyBrand.id);
		this.invoiceConceptModel.companyBrand = companyBrand;

		this.forceUpdate();
	}

	/**
	 *
	 * @param e
	 */
	onInputChange(e) {
		switch (e.target.name) {
			case 'invoiceNr':
				this.setState({ invoiceNrChangedManually: true });
				this.invoiceConceptModel[e.target.name] = e.target.value;
				break;
			case 'expirationDays':
				// eslint-disable-next-line no-case-declarations
				const days = parseInt(e.target.value, 10);
				this.invoiceConceptModel[e.target.name] = !isNaN(days) ? days : PaymentTerm.FOURTEEN_DAYS;
				break;
			case 'locale':
				this.invoiceConceptModel.locale = e.target.value;
				this._updateNotes();
				break;
			case 'inclVat':
				// toggle incl/excl vat
				this.invoiceConceptModel.setInclVat(!e.target.checked);
				break;
			default:
				this.invoiceConceptModel[e.target.name] = e.target.value;
		}

		this.forceUpdate();
	}

	/**
	 *
	 */
	onCancel() {
		const company = this.context.applicationStore.getSelectedCompany();
		if (company) {
			if (this.invoiceConceptModel.id) {
				Signals.RequestRoute.dispatch(
					Routes.COMPANY_INVOICES_INVOICE.getPath({
						id: company.id,
						invoiceId: this.invoiceConceptModel.id
					})
				);
			} else {
				Signals.RequestRoute.dispatch(Routes.COMPANY_INVOICES_ALL.getPath({ id: company.id }));
			}
		}
	}

	/**
	 *
	 * @param e
	 */
	onSubmit(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.state.submitting) {
			return;
		}

		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		this.doSubmit();
	}

	/**
	 *
	 * @param error
	 */
	onError(error) {
		if (error.response && error.response.body) {
			this.setState({ errors: error.response.body });
		} else {
			Signals.Error.dispatch(error);
		}

		this.setState({ submitting: false });
	}

	/**
	 *
	 */
	onDragStart(invoiceConceptRowModel, event) {
		const draggingContainer = document.querySelector('.invoice-concept__rows tbody');
		const draggingElements = draggingContainer.querySelectorAll('.invoice-concept-row');
		const elementRect = event.target.getBoundingClientRect();
		const elementCenterY = elementRect.y + elementRect.height * 0.5;
		this.dragOffsetY = event.clientY - elementCenterY;

		this.setState({
			dragging: invoiceConceptRowModel,
			draggingContainer,
			draggingElements
		});

		this.dragUpdateInterval = setInterval(this.onDragUpdate, 31);
	}

	/**
	 *
	 * @param invoiceConceptRowModel
	 */
	onDragEnd(_invoiceConceptRowModel) {
		clearInterval(this.dragUpdateInterval);
		this.dragClientY = null;
		this.setState({ dragging: null, draggingElements: null, draggingContainer: null });
	}

	/**
	 *
	 */
	onDragUpdate() {
		const { dragging, draggingElements } = this.state;

		if (dragging && this.dragClientY) {
			const draggingUid = `${dragging._uid}`;
			const positionY = this.dragClientY - this.dragOffsetY;
			const before = [];
			const element = [];
			const after = [];

			// Determine order
			draggingElements.forEach((rowElement) => {
				const rect = rowElement.getBoundingClientRect();

				if (rowElement.dataset.uid !== draggingUid) {
					if (positionY <= rect.y + rect.height * 0.5) {
						after.push(rowElement);
					} else {
						before.push(rowElement);
					}
				} else {
					element.push(rowElement);
				}
			});

			// Apply order
			const ordered = before.concat(element).concat(after);
			const result = [];
			ordered.forEach((rowElement, index) => {
				const uid = rowElement.dataset.uid;
				const invoiceConceptRowModel = this.invoiceConceptModel.getConceptRowByUid(
					parseInt(uid, 10)
				);
				invoiceConceptRowModel.sortOrder = index;
				result.push(invoiceConceptRowModel);
			});

			// Update invoiceConceptRows order
			this.invoiceConceptModel.invoiceConceptRows = result;
			this.forceUpdate();
		}
	}

	/**
	 *
	 * @param event
	 */
	onDragOver(event) {
		event.preventDefault();
		this.dragClientY = event.clientY;
	}

	/**
	 *
	 * @param id
	 */
	onCompanyCustomerAdded(id) {
		this.invoiceConceptModel.companyCustomer =
			this.context.applicationStore.companyCustomerStore.find(parseInt(id, 10));
		this.forceUpdate();
	}

	/**
	 *
	 * @param id
	 */
	onCompanyBrandAdded(id) {
		this.invoiceConceptModel.companyBrand = this.context.applicationStore.companyBrandStore.find(
			parseInt(id, 10)
		);
		this.forceUpdate();
	}

	/**
	 *
	 */
	onInvoiceConceptRowChange() {
		this.forceUpdate();
	}

	/**
	 *
	 * @param invoiceConceptRowModel
	 */
	onInvoiceConceptRowRemove(invoiceConceptRowModel) {
		this.invoiceConceptModel.removeConceptRow(invoiceConceptRowModel);
		this.forceUpdate();
	}

	/**
	 *
	 */
	doSubmit() {
		// Blur active element
		if ('activeElement' in document) {
			document.activeElement.blur();
		}

		// Validate
		const currentDate = new Date(Date.now());
		const company = this.context.applicationStore.getSelectedCompany();
		const year = this.invoiceConceptModel.date ? this.invoiceConceptModel.date.getFullYear() : null;
		const financialYear = company.getFinancialYearByYear(year);

		// Check if date is not in current year or later
		if (year && year < currentDate.getFullYear()) {
			if (!financialYear || financialYear.isClosed()) {
				const translationTitleId = 'invoice.wrongbookyear.alert.title';
				const translationId = 'invoice.wrongbookyear.alert.description';
				Signals.ShowModal.dispatch(
					<ModalAlert
						title={this.props.intl.formatMessage({ id: translationTitleId })}
						body={this.props.intl.formatMessage({ id: translationId })}
					/>
				);
				return;
			}
		}

		// Prepare requests
		this.setState({ errors: null, submitting: true });
		const defaults = {
			senderEmail: this.context.applicationStore.user.email,
			notes: ''
		};

		const data = this.invoiceConceptModel.toJSON(defaults);
		const saveCommand = new SaveInvoiceConceptCommand(
			this.context.applicationStore,
			this.invoiceConceptModel,
			company,
			data
		);
		saveCommand.execute(() => {
			this.setState({ submitting: false });
		}, this.onError);
	}

	/**
	 *
	 * @param fileAssets FileAssets
	 */
	onAttachmentsChange(fileAssets) {
		this.invoiceConceptModel.assetAttachments = fileAssets;
	}

	/**
	 *
	 */
	showProjectOrderInfo() {
		const translationTitleId = 'info.projectcode.title';
		const translationId = 'info.projectcode.body';
		Signals.ShowModal.dispatch(
			<ModalAlert
				title={this.props.intl.formatMessage({ id: translationTitleId })}
				body={this.props.intl.formatMessage({ id: translationId })}
			/>
		);
	}

	/**
	 *
	 */
	addNewInvoiceConceptRow() {
		this.invoiceConceptModel.addNewConceptRow();
		this.forceUpdate();
	}

	/**
	 *
	 * @private
	 */
	_getCompanyCustomerSearch() {
		const { intl } = this.props;
		const { applicationStore } = this.context;
		let showChangeOnly = false;

		const companyCustomerId = this.invoiceConceptModel.getCompanyCustomerId();
		const currentCompanyCustomer = this.invoiceConceptModel.companyCustomer;

		// If companyCustomerId is found on invoiceconcept, compare to stored version
		if (companyCustomerId) {
			const savedCompanyCustomer = applicationStore.companyCustomerStore.find(companyCustomerId);

			if (!savedCompanyCustomer || !savedCompanyCustomer.isEqual(currentCompanyCustomer)) {
				showChangeOnly = true;
			}
		}

		//
		return (
			<CompanyCustomerSearch
				name="companyCustomer"
				value={this.invoiceConceptModel.getCompanyCustomerId()}
				initialCompanyCustomer={currentCompanyCustomer}
				placeholder={intl.formatMessage({ id: 'company.customerselect.placeholder' })}
				changeOnly={showChangeOnly}
				onChange={this.onClientChange}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getCompanyBrandSearch() {
		const { intl } = this.props;
		const { applicationStore } = this.context;
		const company = applicationStore.getSelectedCompany();

		let currentCompanyBrandId = this.invoiceConceptModel.getCompanyBrandId()
			? this.invoiceConceptModel.getCompanyBrandId()
			: parseInt(CookieController.getCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID), 10);
		const currentCompanyBrand = this.invoiceConceptModel.companyBrand;
		let showChangeOnly = false;

		// If companybrand is found on offer, compare to stored version
		if (currentCompanyBrandId >= 0) {
			const savedCompanyBrand = applicationStore.companyBrandStore.find(currentCompanyBrandId);
			if (!savedCompanyBrand || !savedCompanyBrand.isEqual(currentCompanyBrand)) {
				showChangeOnly = true;
			}
			// Default is used, compare if same as current default
		} else if (currentCompanyBrandId === -1 && currentCompanyBrand) {
			showChangeOnly = !currentCompanyBrand.isEqual(company.defaultCompanyBrand);
			// No companybrand store, set -1 to select default
		} else {
			currentCompanyBrandId = -1; // set default
			this.invoiceConceptModel.companyBrand = company.defaultCompanyBrand;
		}

		//
		return (
			<CompanyBrandSearch
				name="companyBrand"
				value={currentCompanyBrandId}
				initialCompanyBrand={currentCompanyBrand}
				placeholder={intl.formatMessage({ id: 'company.brandselect.placeholder' })}
				includeDefault
				changeOnly={showChangeOnly}
				onChange={this.onBrandChange}
			/>
		);
	}

	/**
	 *
	 * @return {*}
	 * @private
	 */
	_getInvoiceConceptRows() {
		// Create invoices concept rows
		const rows = this.invoiceConceptModel.invoiceConceptRows.map(
			(invoiceConceptRowModel, index) => {
				invoiceConceptRowModel.sortOrder = index;
				return (
					<InvoiceConceptRow
						key={`i-${invoiceConceptRowModel._uid}-${invoiceConceptRowModel.id}`}
						invoiceConceptRowModel={invoiceConceptRowModel}
						invoiceConceptModel={this.invoiceConceptModel}
						onChange={this.onInvoiceConceptRowChange}
						onRemove={this.onInvoiceConceptRowRemove}
						onDragStart={this.onDragStart}
						onDragEnd={this.onDragEnd}
					/>
				);
			}
		);

		// Add new row
		rows.push(
			<tr
				key="add-invoice-concept-row"
				className="invoice-concept__add-invoice-concept-row border--top border--dark"
				onClick={this.addNewInvoiceConceptRow}>
				<td
					className="invoice-concept-row__column invoice-concept-row__add icon icon--left icon--add-black"
					colSpan={7}>
					<FormattedMessage id="invoice.add.row" />
				</td>
			</tr>
		);

		return rows;
	}

	/**
	 *
	 * @return {any[]}
	 * @private
	 */
	_getTableHeaders() {
		let totalUnits = 0;
		if (this.invoiceConceptModel) {
			this.invoiceConceptModel.invoiceConceptRows.forEach((invoiceConceptRow) => {
				totalUnits += invoiceConceptRow.units;
			});
		}
		const values = { totalUnits: ` (${totalUnits})` };

		// Create table headers
		return this.fieldHeaderSettings.map((fieldData, index) => {
			if (fieldData.hide) return null;

			return (
				<td
					key={`ih-${index}`}
					className={`invoice-concept__header--${fieldData.id.toLowerCase()} ${classNames({
						table__header: true,
						'table__header--center': fieldData.center,
						'table__header--right': fieldData.right,
						'table__header--no-sort': fieldData.noSort
					})}`}>
					{fieldData.labelId ? <FormattedMessage id={fieldData.labelId} values={values} /> : ''}
				</td>
			);
		});
	}

	/**
	 *
	 * @return {*[]}
	 * @private
	 */
	_getLocaleOptions() {
		// Locale options
		return localesArray.map((locale, index) => {
			return (
				<option key={`locale-${index}`} value={locale}>
					{this.props.intl.formatMessage({ id: `locale.${locale}` })}
				</option>
			);
		});
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATExempt() {
		if (this.invoiceConceptModel.containsVATType(BTW.VRIJGESTELD)) {
			return (
				<InvoiceConceptSummaryRow
					label={this.props.intl.formatMessage({ id: 'invoice.vat.exempt.summary' })}
					amount={null}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getVATReversed() {
		if (this.invoiceConceptModel.containsVATType(BTW.VERLEGD)) {
			return (
				<InvoiceConceptSummaryRow
					label={this.props.intl.formatMessage({ id: 'invoice.vat.reversed.summary' })}
					amount={0}
				/>
			);
		}

		return null;
	}

	/**
	 *
	 * @returns {Array}
	 * @private
	 */
	_getOptionButtons() {
		const buttons = [];

		buttons.push(
			<button
				key="btn-cancel"
				className="button--tertiary button--no-shadow"
				onClick={this.onCancel}
				type="button">
				<FormattedMessage id="label.cancel" />
			</button>
		);

		buttons.push(
			<button
				key="btn-submit"
				className="button--primary"
				type="submit"
				disabled={
					!this.invoiceConceptModel.canEdit() ||
					PropertiesController.getProperty(PropertiesController.FEATURE_DEMO, this.context)
				}>
				<FormattedMessage id="invoice.prepare" />
			</button>
		);

		return buttons;
	}

	/**
	 *
	 * @returns {*}
	 * @private
	 */
	_getLastUsedInvoiceNrPlaceholder() {
		// Invoice Number suggestions and defaults
		const latestInvoiceNumber = this.state.companyLatestInvoiceNumberStore.getLatestInvoiceNumber(
			this.invoiceConceptModel.date.getFullYear()
		);

		if (latestInvoiceNumber && latestInvoiceNumber.latestInvoiceNr) {
			return this.props.intl.formatMessage(
				{ id: 'invoice.invoicenr.placeholder' },
				{ invoiceNr: latestInvoiceNumber.latestInvoiceNr }
			);
		}
		return this.props.intl.formatMessage(
			{ id: 'invoice.invoicenr.kdbformat' },
			{ year: new Date().getFullYear() }
		);
	}

	/**
	 * Try to get or create invoiceConceptModel based on route. Fetch all invoices if necessary
	 *
	 * @private
	 */
	_initialize(skipFetch = false) {
		// Log start
		console.log('InvoiceConcept._initialize');
		//
		const company = this.context.applicationStore.getSelectedCompany();
		// Log the company
		console.log('InvoiceConcept._initialize.company', company);
		if (!company) {
			return;
		}

		console.log('InvoiceConcept._initialize.fetch.latestInvoiceNumber');
		const command = new GetLatestInvoiceNumberCommand(
			this.state.companyLatestInvoiceNumberStore,
			company
		);
		command.execute();

		// NEW: Create a new invoice if 'new' is found
		// Log the route param
		console.log(
			'InvoiceConcept._initialize.route.params',
			this.context.applicationStore.currentRouteParams
		);
		console.log(
			'InvoiceConcept._initialize.invoicesConceptStore',
			this.context.applicationStore.invoicesConceptStore
		);
		if (this.context.applicationStore.currentRouteParams.invoiceId === 'new') {
			this._createNewInvoiceConcept();

			// NEW FROM OFFER: Create a new invoice based on an OfferModel
		} else if (this.context.applicationStore.currentRoute === Routes.COMPANY_INVOICES_OFFER) {
			console.log(
				'InvoiceConcept._initialize.offer',
				this.context.applicationStore.currentRouteParams.offerId
			);
			const command = new GetOffersCommand(this.context.applicationStore.offersStore, company.id);
			command.execute().then(() => {
				console.log(
					'InvoiceConcept._initialize.offer.store',
					this.context.applicationStore.offersStore
				);
				const offerModel = this.context.applicationStore.offersStore.getOfferById(
					parseInt(this.context.applicationStore.currentRouteParams.offerId, 10)
				);
				if (offerModel) {
					this._createNewInvoiceConceptFromOffer(offerModel);
				} else {
					this._createNewInvoiceConcept();
				}
				this.forceUpdate();
			});
			console.log('InvoiceConcept._initialize.offer.invoiceConceptModel', this.invoiceConceptModel);

			// DUPLICATE: Check if the supplied invoiceId needs to be duplicated
		} else if (this.context.applicationStore.currentRoute === Routes.COMPANY_INVOICES_DUPLICATE) {
			/**
			 * If the action is duplicate in case of the deeplink situation fetch archived invoices.
			 * @type {GetArchivedInvoicesConceptCommand}
			 */
			const command = new GetArchivedInvoicesConceptCommand(
				this.context.applicationStore.invoicesConceptArchiveStore,
				company.id
			);
			command.execute();
			this._createDuplicateInvoiceConcept();

			// EDIT: Check if the supplied invoiceId needs to be duplicated
		} else if (!isNaN(parseInt(this.context.applicationStore.currentRouteParams.invoiceId, 10))) {
			const invoiceId = parseInt(this.context.applicationStore.currentRouteParams.invoiceId, 10);
			this.invoiceConceptModel =
				this.context.applicationStore.invoicesConceptStore.getInvoiceConceptById(invoiceId);
		}

		// NONE FOUND OR CREATED: If no invoiceConceptModel exists, force fetch of invoiceConcepts from server before trying again
		if (!this.invoiceConceptModel) {
			// Log
			console.log('InvoiceConcept._initialize.no.invoiceConceptModel');
			if (!skipFetch) {
				const command = new GetInvoicesConceptCommand(
					this.context.applicationStore.invoicesConceptStore,
					company.id
				);
				// Log fetch
				console.log('InvoiceConcept._initialize.fetch.invoicesConcepts');
				command.execute(() => {
					this._initialize(true);
				});
			} else {
				console.log('invoiceConcept not found');
			}
			console.log('InvoiceConcept._initialize.return');
			return;
		}

		// Set default values
		console.log('InvoiceConcept._initialize.applyDefaults');
		this._applyDefaults(company.invoicesSettings);

		// Make sure we always have a minimum of 1 row
		while (this.invoiceConceptModel.invoiceConceptRows.length < 1) {
			this.addNewInvoiceConceptRow();
		}

		// Update rendering
		this.forceUpdate();

		// Autofocus on selected field
		this._autoFocus();
	}

	/**
	 *
	 * @private
	 */
	_createNewInvoiceConcept() {
		const lastUsedInvoice = this.context.applicationStore.invoicesConceptStore.getLastUsedInvoice();
		this.invoiceConceptModel = new InvoiceConceptModel();

		const { applicationStore } = this.context;
		const companyBrand = applicationStore.companyBrandStore.find(
			parseInt(CookieController.getCookie(COOKIE_TYPES.SELECTED_COMPANY_BRAND_ID), 10)
		);
		console.log('InvoiceConcept._createNewInvoiceConcept.companyBrand', companyBrand);
		if (companyBrand) {
			this.invoiceConceptModel.companyBrand = companyBrand;
		}

		// Copy expirationDays from last invoice, otherwise use default
		this.invoiceConceptModel.expirationDays = lastUsedInvoice
			? lastUsedInvoice.expirationDays
			: PaymentTerm.FOURTEEN_DAYS.days;
	}

	/**
	 *
	 * @private
	 */
	_createNewInvoiceConceptFromOffer(offerModel) {
		const lastUsedInvoice = this.context.applicationStore.invoicesConceptStore.getLastUsedInvoice();
		this.invoiceConceptModel = new InvoiceConceptModel();
		this.invoiceConceptModel.copyFromOfferModel(offerModel);

		// Copy expirationDays from last invoice, otherwise use default
		this.invoiceConceptModel.expirationDays = lastUsedInvoice
			? lastUsedInvoice.expirationDays
			: PaymentTerm.FOURTEEN_DAYS.days;
	}

	/**
	 *
	 * @private
	 */
	_createDuplicateInvoiceConcept() {
		const invoiceId = parseInt(this.context.applicationStore.currentRouteParams.invoiceId, 10);
		// TODO to make a copy of a filed invoice, the invoicesConceptArchiveStore has to be used to retrieve the invoice.
		//  We should read this information from the store instead of searching on both stores
		const toClone =
			this.context.applicationStore.invoicesConceptStore.getInvoiceConceptById(invoiceId) ||
			this.context.applicationStore.invoicesConceptArchiveStore.getInvoiceConceptById(invoiceId);

		if (toClone) {
			this.invoiceConceptModel = toClone.clone(false, true);
		}
	}

	/**
	 *
	 * @private
	 */
	_applyDefaults(_invoicesSettings) {
		// Set, override locale based on users preference if invoice is new
		if (!this.invoiceConceptModel.locale) {
			this.invoiceConceptModel.locale = this.context.applicationStore.user.isEnglish()
				? Locale.en_US
				: Locale.nl_NL;
		}

		// Set default notes, based on locale
		this._updateNotes();
	}

	/**
	 *
	 * @private
	 */
	_updateNotes() {
		const company = this.context.applicationStore.getSelectedCompany();
		const invoicesSettings = company.invoicesSettings;

		// Get default string or convert default translation object to string
		const defaultEN =
			typeof invoicesSettings.defaultNotesEN === 'object'
				? this.props.intl.formatMessage(invoicesSettings.defaultNotesEN)
				: invoicesSettings.defaultNotesEN;
		const defaultNL =
			typeof invoicesSettings.defaultNotes === 'object'
				? this.props.intl.formatMessage(invoicesSettings.defaultNotes)
				: invoicesSettings.defaultNotes;

		// Is notes one of the default values? Or is notes not set?
		if (
			!this.invoiceConceptModel.notes ||
			this.invoiceConceptModel.notes === defaultEN ||
			this.invoiceConceptModel.notes === defaultNL
		) {
			// Set notes based on locale
			if (this.invoiceConceptModel.locale === Locale.en_US) {
				this.invoiceConceptModel.notes = defaultEN;
			} else {
				this.invoiceConceptModel.notes = defaultNL;
			}
		}
	}

	/**
	 *
	 */
	_autoFocus() {
		// Autofocus on autofocusField
		const fieldToFocus = this.context.applicationStore.invoicesConceptStore.autofocusField;
		if (fieldToFocus) {
			setTimeout(() => {
				if (this.el && fieldToFocus) {
					const field = this.el.querySelector(`[name="${fieldToFocus}"]`);

					try {
						field.focus();
						const cursorPosition = field.value ? field.value.length : 0;
						field.setSelectionRange(cursorPosition, cursorPosition);
					} catch (e) {
						// Ignore
					}
				}
			}, 100);
		}
	}
}

InvoiceConcept.contextType = ApplicationContext;

InvoiceConcept.propTypes = {
	intl: PropTypes.object
};

export default injectIntl(InvoiceConcept);
