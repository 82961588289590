import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Routes, RouteUtil } from '../../../data/Routes';
import Signals from '../../../signals/Signals';

import MainMenu from '../MainMenu/MainMenu';
import SubMenu from '../SubMenu/SubMenu';
import BookKeeperInfo from '../BookKeeperInfo/BookKeeperInfo';
import Profiles from '../Profiles/Profiles';
import ProfileItem from '../Profiles/ProfileItem';
import NotificationButton from '../NotificationButton/NotificationButton';
import { ApplicationContext } from '../../../ApplicationContext';

/**
 *
 */
@observer
class Header extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		this.bookKeeper = null;
		this.profiles = null;

		this.state = {
			bookkeeperOpened: false,
			profilesOpened: false
		};
	}

	/**
	 *
	 * @param prevProps
	 */
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		const path = this.props.location.pathname;
		const route = RouteUtil.getRoute(path);

		const headerClassName = !(
			RouteUtil.isCompanyPath(path) ||
			RouteUtil.isUserPath(path) ||
			RouteUtil.isDocuments(path) ||
			RouteUtil.isMessages(path)
		)
			? 'header--hide'
			: 'header--show';

		// Create selected ProfileItem
		let selectedProfile = null;
		const selectedCompany = this.context.applicationStore.getSelectedCompany();

		const firstCompany =
			this.context.applicationStore.user &&
			this.context.applicationStore.user.companies &&
			this.context.applicationStore.user.companies.length > 0
				? this.context.applicationStore.user.companies[0]
				: null;

		// If no company selected or route is public, return null
		if (!firstCompany || (route && route.isPublic)) {
			return null;
		}

		// Do not render header on terms policy page
		if (!RouteUtil.shouldHeaderRender(path)) {
			return null;
		}

		const user = this.context.applicationStore.user;
		if (RouteUtil.isCompanyPath(path) && selectedCompany) {
			selectedProfile = user.getCompanyById(selectedCompany.id);
		} else {
			selectedProfile = user;
		}

		const profilesClasses = classNames({
			'icon--chevron-down-smoke': !this.state.profilesOpened,
			'icon--chevron-up-smoke': this.state.profilesOpened
		});

		return (
			<div className="header__wrapper">
				<header className={`header ${headerClassName}`}>
					<div className="header__container-left">
						<Link
							className="kdb-logo walkthrough-step-dashboard"
							to={Routes.COMPANY_DASHBOARD.getPath({
								id: selectedCompany ? selectedCompany.id : firstCompany.id
							})}
						/>
						<MainMenu />
					</div>

					<div className="header__container-right">
						<div
							ref={(bookKeeper) => {
								this.bookKeeper = bookKeeper;
							}}
							className={`bookkeeper-button ${
								this.state.bookKeeperActive ? 'bookkeeper-button--selected' : ''
							}`}
							onClick={this.onBookKeeperClick.bind(this)}>
							<svg viewBox="0 0 29.7 29.7" stroke="currentColor">
								<g>
									<path
										className="st0"
										d="M23.6,29.2c-1.2,0-2.6-0.3-4.2-0.9C15.9,27,12.1,24.5,8.7,21c-3.4-3.4-6-7.1-7.3-10.6c-1.2-3.2-1.2-5.8,0-7
										C1.6,3.2,1.8,3,1.9,2.8c1.2-1.1,2.4-2.4,4-2.3C7,0.5,8,1.3,9.1,2.6c3.3,4.1,1.8,5.5,0.1,7.2L9,10.2c-0.3,0.3-0.8,1.5,4.2,6.5
										c1.6,1.6,3,2.8,4.1,3.5c0.7,0.5,1.9,1.2,2.4,0.6l0.3-0.3c1.6-1.7,3.1-3.2,7.2,0.1c1.4,1.1,2,2.2,2.1,3.3c0.1,1.6-1.3,2.9-2.4,4
										c-0.2,0.2-0.4,0.4-0.6,0.5C25.7,28.9,24.8,29.2,23.6,29.2z M5.7,1.5c-1.2,0-2.2,1.1-3.1,2C2.4,3.7,2.2,3.9,2.1,4.1
										c-0.9,0.9-0.8,3.2,0.3,6c1.3,3.4,3.8,7.1,7.1,10.3s7,5.8,10.3,7.1c2.7,1.1,5,1.2,5.9,0.2c0.2-0.2,0.4-0.4,0.6-0.6c1-1,2.1-2,2-3.2
										c0-0.8-0.6-1.7-1.7-2.6c-3.4-2.7-4.3-1.7-5.9-0.2l-0.3,0.3c-0.8,0.8-1.9,0.6-3.7-0.5c-1.2-0.8-2.6-1.9-4.2-3.7
										c-4.1-4-5.4-6.5-4.2-7.7l0.4-0.3c1.5-1.6,2.5-2.6-0.2-6C7.5,2.1,6.6,1.5,5.7,1.5C5.8,1.5,5.7,1.5,5.7,1.5z"
									/>
								</g>
							</svg>
						</div>

						<NotificationButton todoStore={this.context.applicationStore.todoStore} />

						<div
							ref={(profiles) => {
								this.profiles = profiles;
							}}
							className={`profile-select icon icon--right ${profilesClasses}`}
							onClick={this.onProfilesClick.bind(this)}>
							<ProfileItem
								key={`spid-${path}`}
								className="header__profile-item"
								todoPosition="left"
								todoCount={0}
								userCompany={selectedProfile}
							/>
						</div>
					</div>
				</header>

				<SubMenu />
			</div>
		);
	}

	/**
	 *
	 */
	onRouteChanged() {}

	/**
	 *
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods
	onLogoClick() {
		Signals.RequestRoute.dispatch(Routes.HOME);
	}

	/**
	 *
	 */
	onBookKeeperClick() {
		if (!this.context.applicationStore.showToolTip) {
			this.setState({ bookKeeperActive: true });
			Signals.ShowToolTip.dispatch(
				<BookKeeperInfo user={this.context.applicationStore.user} />,
				this.bookKeeper,
				16,
				0
			);
			Signals.HideToolTip.addOnce(() => {
				this.setState({ bookKeeperActive: false });
			});
		}
	}

	/**
	 *
	 */
	onProfilesClick() {
		if (!this.context.applicationStore.showToolTip) {
			Signals.ShowToolTip.dispatch(<Profiles />, this.profiles, 12, 0);
			this.setState({ profilesOpened: true });
			Signals.HideToolTip.addOnce(() => {
				this.setState({ profilesOpened: false });
			});
		}

		// Closing of tooltip is handled by ToolTip itself
	}
}

Header.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

Header.defaultProps = {};

export default withRouter(Header);

Header.contextType = ApplicationContext;
