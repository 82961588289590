import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Signals from '../../../signals/Signals';
import { RouteUtil } from '../../../data/Routes';

import BookKeepingSubMenu from './BookKeepingSubMenu/BookKeepingSubMenu';
import InvoicesSubMenu from './InvoicesSubMenu/InvoicesSubMenu';
import DocumentsSubMenu from './DocumentsSubMenu/DocumentsSubMenu';
import OffersSubMenu from './OffersSubMenu/OffersSubMenu';
import { ApplicationContext } from '../../../ApplicationContext';
import CompanySettingsSubMenu from './SettingsSubMenu/CompanySettingsSubMenu';
import UserSettingsSubMenu from './SettingsSubMenu/UserSettingsSubMenu';

const SUBMENU_TYPE_BOOKKEEPING = '/bookkeeping';
const SUBMENU_TYPE_INVOICES = '/invoices';
const SUBMENU_TYPE_OFFERS = '/quotes';
const SUBMENU_TYPE_DOCUMENTS = '/documents';
const SUBMENU_TYPE_COMPANY_SETTINGS = '/company/settings';
const SUBMENU_TYPE_USER_SETTINGS = '/user/settings';

/**
 *
 */
class SubMenu extends React.Component {
	/**
	 *
	 */
	constructor(props) {
		super(props);

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.menu = null;
		this.state = {
			type: null
		};

		this.onYearChanged = this.onYearChanged.bind(this);
	}

	/**
	 *
	 * @return {*}
	 */
	render() {
		if (this.context.applicationStore.isLoggedIn) {
			const company = this.context.applicationStore.getSelectedCompany();

			if (!company) return null;

			const path = this.props.location.pathname;
			let submenuType = null;

			// Company settings submenu, ignore for profile
			if (path.indexOf(SUBMENU_TYPE_COMPANY_SETTINGS) >= 0 && path.indexOf('profiles') < 0) {
				// submenuType = SUBMENU_TYPE_COMPANY_SETTINGS
				// User settings submenu
			} else if (path.indexOf(SUBMENU_TYPE_USER_SETTINGS) >= 0) {
				submenuType = SUBMENU_TYPE_USER_SETTINGS;

				// Company bookkeeping submenu
			} else if (path.indexOf(SUBMENU_TYPE_BOOKKEEPING) >= 0) {
				submenuType = SUBMENU_TYPE_BOOKKEEPING;

				// Company invoices submenu
			} else if (path.indexOf(SUBMENU_TYPE_INVOICES) >= 0) {
				submenuType = SUBMENU_TYPE_INVOICES;

				// Offers submenu
			} else if (path.indexOf(SUBMENU_TYPE_OFFERS) >= 0) {
				submenuType = SUBMENU_TYPE_OFFERS;

				// Documents submenu
			} else if (path.indexOf(SUBMENU_TYPE_DOCUMENTS) >= 0) {
				submenuType = SUBMENU_TYPE_DOCUMENTS;
			}

			// Determine whether to show or hide, fallback to state remembered type if hiding
			const show = submenuType != null;
			if (!show) {
				submenuType = this.state.type;
			} else {
				this.state.type = submenuType;
			}

			// Determine content to show
			let submenuContent = null;
			if (submenuType === SUBMENU_TYPE_BOOKKEEPING) {
				submenuContent = <BookKeepingSubMenu />;
			}

			if (submenuType === SUBMENU_TYPE_INVOICES) {
				submenuContent = <InvoicesSubMenu />;
			}

			if (submenuType === SUBMENU_TYPE_DOCUMENTS) {
				submenuContent = <DocumentsSubMenu />;
			}

			if (submenuType === SUBMENU_TYPE_OFFERS) {
				submenuContent = <OffersSubMenu />;
			}

			if (submenuType === SUBMENU_TYPE_COMPANY_SETTINGS) {
				submenuContent = <CompanySettingsSubMenu />;
			}

			if (submenuType === SUBMENU_TYPE_USER_SETTINGS) {
				submenuContent = <UserSettingsSubMenu />;
			}

			// Build classnames for submenu
			const classes = classNames({
				'sub-menu--show': show,
				'sub-menu--hide': !show
			});

			return (
				<nav
					ref={(menu) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.menu = menu;
					}}
					className={`sub-menu ${classes}`}>
					{submenuContent}
				</nav>
			);
		}
		return null;
	}

	/**
	 *
	 * @param e
	 */
	onYearChanged(e) {
		const currentRoute = RouteUtil.getRoute(this.props.location.pathname);
		const selectedCompany = this.context.applicationStore.getSelectedCompany();
		Signals.RequestRoute.dispatch(
			currentRoute.getPath({ id: selectedCompany.id, year: e.target.value })
		);
	}
}

SubMenu.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	})
};

export default withRouter(SubMenu);

SubMenu.contextType = ApplicationContext;
